import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const workingHours: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fscheduling%2Fworkinghours-min.png?alt=media&token=a59c0696-e6be-473f-8bc3-fe78a2dcc057",
  title: "Working Hours",
  subtitle: "Plan a Perfect Day.",
  paragraph: "Set working hours for each team members with convenient breaks.",
  data: [
    {
      icon: "stopwatch", title: "Set your hours.", subtitle: "For each day."
    },
    {
      icon: "coffee", title: "Set Breaks.", subtitle: "Between your bookings."
    },
    {
      icon: "plane", title: "Block Holidays.", subtitle: "From your calendar."
    },
    {
      icon: "calendar", title: "Different Seasons.", subtitle: "Set different hours."
    },
    {
      icon: "table", title: "Flexible time slots.", subtitle: "1hr, 30m, 15m, 10m."
    },
    {
      icon: "cog", title: "Set up teams.", subtitle: "On their behalf."
    },
  ]
}