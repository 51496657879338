import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuditType } from '../../../../../../../data/enums/audit_type';
import { AuditModel } from '../../../../../../../data/models/audit_model';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  selector: 'app-contactaudit',
  template: `
    <ng-container *ngIf="audits$ | async as audits; else loadingOrError">
      <div *ngIf="!audits || audits.length == 0; else auditlist">
        <app-empty icon="user-alt" header="No Audit Yet"
          description="An Audit of all actions against your contacts will be shown here" ></app-empty>
      </div>
      <ng-template #auditlist>
        <div class="px-6 pt-6">
          <h1 class="wild-header-large">Audit</h1>
        </div>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 p-6">
          <div *ngFor="let audit of audits" class="relative rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm flex items-center space-x-3">
            <div class="flex-shrink-0">
                <div class="h-10 w-10 rounded-full border border-gray-200 flex items-center justify-center">
                  <fa-icon *ngIf="audit.type == AuditType.CREATED" class="text-md wild-text-primary" icon="plus"></fa-icon>
                  <fa-icon *ngIf="audit.type == AuditType.UPDATED" class="text-md wild-text-primary" icon="edit"></fa-icon>
                </div>
            </div>
            <div class="flex-1 min-w-0">
              <span  class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p class="text-sm font-medium text-gray-900">
                <span *ngIf="audit.type == AuditType.CREATED">Record created by:</span>
                <span *ngIf="audit.type == AuditType.UPDATED">Record updated by:</span>
                </p>
                <p class="text-sm text-gray-500 truncate">
                  <span>{{audit?.business_user?.firstName}} {{audit?.business_user?.surname}} at {{audit?.creation_date?.toDate() | date : 'medium'}}</span>
                </p>
              </span>
            </div>
          </div>
        </div>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  `
})
export class ContactAuditComponent implements OnInit {

  constructor(
    private contactsService: ContactsService) { }

  public error: ErrorModel = null;
  @Input() contact: BusinessContactModel;
  audits$: Observable<AuditModel>;
  AuditType = AuditType;

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.audits$ = null;
    this.audits$ = this.contactsService.getAudit(this.contact.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
