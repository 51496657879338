import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { businessappListData } from "./business_app_data_listdata";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { realTimeSafetyOverviewThreeScreenShots } from "../../shared/features/realtime_overview_three-shot";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { usecases } from "../../shared/usecases";

export const businessAppData: FeaturesData = {
  seoTitle: "App | Intelligent Booking Platform for Watersports",
  seoDescription: "Real Time Water Safety System to check people in and out of the Water quickly access emergency information",
  seoImage: businessappListData.image,
  seoKeyWords: "",
  page: WildNavigationType.BUSINESSAPP,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: businessappListData.title,
      subtitle: businessappListData.subtitle,
      paragraph: "One-Off's, Repeating, Rescheduling, it's all there",
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fbusinessapp%2Fapp-calendar.png?alt=media&token=cef33bf9-a570-4e39-9b94-7ce35fe9860a",
      title: "Calendar",
      subtitle: "Appointments on the Go",
      paragraph: "",
      data:[
        {
          icon: "calendar", title: "All Appointments.", subtitle: "Available to view."
        },
        {
          icon: "calendar", title: "See contacts.", subtitle: "Check attendees."
        },
        {
          icon: "users", title: "Linked accounts.", subtitle: "Contact parents easily."
        },
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.LEFTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fsafety-system-app-profiles.png?alt=media&token=0fbc7112-9c70-4277-9b95-e1ed9509b88f",
      title: "Contacts",
      subtitle: "Contacts on the Go",
      paragraph: "",
      data:[
        {
          icon: "users", title: "Search Contacts.", subtitle: "On the go."
        },
        {
          icon: "users", title: "Linked Accounts.", subtitle: "Contact parents easily."
        },
        {
          icon: "phone-square", title: "Quickly Contact.", subtitle: "All your contacts."
        },
        {
          icon: "mobile", title: "Wild Profile.", subtitle: "Check them out."
        },
        {
          icon: "calendar", title: "Upcoming.", subtitle: "Appointments."
        },
        {
          icon: "calendar", title: "Passed.", subtitle: "Event History."
        },
      ]
    },
    injectSharedComponent(realTimeSafetyOverviewThreeScreenShots, { type:FeatureComponentType.BIGIMAGEFEATURE,colour:FeatureColour.WHITE  }),
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "More Great App & Website Features",
      limit: 9,
      paragraph: "",
      data: [
        schedulingListDataShared,
        teamListDataShared,
        ordersFinanceListData,
        reportingListData,
        crmListData,
        realTimeSafetyListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

