import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BusinessPublicModel } from '../../data/models/business_public_model';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { FirestoreQueryType } from '../../data/enums/firestore_query_type';
import { SwimSpot } from '../../data/models/swimspot_model';

@Injectable({
    providedIn: 'root'
})
export class BusinessPublicService {

    collection: string = "business";

    constructor(
        private firestore: FirestoreService,
    ) { }

    getBusinessByUrl(url: string): Observable<BusinessPublicModel> {
        return this.firestore.getDocumentByQuery("business_public", [{
            value: url,
            type: FirestoreQueryType.WHERE,
            property: "url",
            operator: "==",
        }]);
    }
    swimspots(business: BusinessPublicModel): Observable<SwimSpot[]> {
        if (business?.swimspots == undefined || business?.swimspots == null || business?.swimspots?.length == 0)
            return of([]);
        return this.firestore.getListByQuery("swimspots", [{
            operator: "in",
            property: "id",
            type: FirestoreQueryType.WHERE,
            value: business?.swimspots
        }])
    }

}
