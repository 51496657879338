import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const rolesForTeamsData: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fteam%2Fteam.jpg?alt=media&token=0ebba2cf-622e-4a98-b50c-ee4d97ab33dc",
  title: "Role Based Access",
  subtitle: "Just the Important Stuff",
  paragraph: "Share the admin, loop in finance & configure your safety team.",
  data: [
    {
      icon: "cog", title: "Admins.", subtitle: "All access pass."
    },
    {
      icon: "water", title: "Safety.", subtitle: "For safety system."
    },
    {
      icon: "calendar", title: "Calendar.", subtitle: "For team schedulers."
    },
    {
      icon: "shopping-bag", title: "Orders.", subtitle: "Refunds & more."
    },
    {
      icon: "users", title: "Contacts.", subtitle: "Manage customer data."
    },
    {
      icon: "shopping-bag", title: "Finance.", subtitle: "For relevant employees."
    },
  ]
}