import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { ContactRepositoryService } from 'src/wild/modules/brochure/services/contact_repository_service/contact-repository.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  sent: boolean = false;
  loading: boolean = false;
  form = this._formBuilder.group({
    firstName: new UntypedFormControl('', [
      Validators.required
    ]),
    surname: new UntypedFormControl('', [
      Validators.required
    ]),
    email: new UntypedFormControl('', [
      Validators.required
    ]),
    phone: new UntypedFormControl('', []),
    subject: new UntypedFormControl('', [
      Validators.required
    ]),
    message: new UntypedFormControl('', [
      Validators.required
    ])
  });

  constructor(private _formBuilder: UntypedFormBuilder, private contactRepo: ContactRepositoryService, private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.update("Contact", "Drop us a message anytime", "swimming, open water, lake, river, beach, contact", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fwildlogo.png?alt=media&token=87b004dd-dd18-4815-a240-0f049dfc787b");

  }

  async onSubmit(data: any) {
    if (this.form.invalid)
      return;
    await this.update(data);
  }

  async update(data: any) {
    this.loading = true;
    await this.contactRepo.add(data)
      .then(() => {
        this.sent = true;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        //TODO - error handle notify me
      });
  }

}
