<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }"
  class="py-24 md:py-32 lg:py-40">
  <div class="mx-auto max-w-2xl sm:text-center mb-6 sm:mb-8 md:mb-12 px-6 lg:px-8">
    <app-titles [data]="data"></app-titles>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <ul role="list" [ngClass]="{'md:grid-cols-3': (data?.data?.length ?? 0) > 2}"
      class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2  lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li *ngFor="let data of data.data">
        <img *ngIf="!data.link" [ngClass]="{'cursor-pointer': data.page}" (click)="click(data)"
          class="aspect-[3/2] w-full rounded-2xl object-cover cursor-pointer" [src]="data.image" alt="">
        <a *ngIf="data.link" [href]="data.link">
          <img [ngClass]="{'cursor-pointer': data.page}"
            class="aspect-[3/2] w-full rounded-2xl object-cover cursor-pointer" [src]="data.image" alt="">
        </a>
        <div *ngIf="data.paragraph" class="mt-8 flex items-center gap-x-4 text-xs">
          <time class="text-gray-500">{{data.paragraph}}</time>
        </div>
        <h3 class="mt-6 text-lg font-semibold leading-8 text-gray-900">{{data.title}}</h3>
        <p class="text-base leading-7 text-gray-600">{{data.subtitle}}</p>
        <div *ngIf="data.link" role="list" class="mt-6">
          <a [href]="data.link" type="button"
            class="rounded bg-white py-1 px-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">read
            more</a>
        </div>
        <div *ngIf="data.page" role="list" class="mt-6">
          <button (click)="click(data)" type="button"
            class="rounded bg-white py-1 px-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">read
            more</button>
        </div>
      </li>
    </ul>
    <div *ngIf="data.ctas" class="mt-12">
      <app-ctas [data]="data"></app-ctas>
    </div>
  </div>
</div>