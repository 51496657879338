<app-wrapper header="Memberships">
    <div *ngIf="memberships$">
        <ng-container *ngIf="memberships$ | async as memberships; else loadingOrError">
            <div *ngIf="!memberships || memberships?.memberships?.length == 0; else membershipslist">
                <app-empty icon="infinity" header="No Memberships yet" ctatext="Search" (onClick)="search()"
                    [showcta]="!isAppUser"></app-empty>
            </div>
            <ng-template #membershipslist>
                <section>
                    <div class="rounded-md bg-blue-50 p-4 my-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between ">
                                <p class="text-sm text-blue-700">Your memberships will be presented at checkout when
                                    booking a relevant activity. Your membership discounts will be automatically applied.</p>
                                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                                </p>
                            </div>
                        </div>
                    </div>
                    <ul role="list" class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
                        <li style="border: 1px solid #dedede;" *ngFor="let membership of memberships.memberships"
                            class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div class="flex w-full items-center justify-between space-x-6 p-6">
                                <div class="flex-1 truncate">
                                    <a [href]="membership.fullBusinessUrl"><img  class="rounded-md w-16 h-16 mb-3 rounded-md shadow-md hover:shadow-lg" [src]="membership.businessLogo" /></a>
                                    <h3 [href]="membership.fullBusinessUrl" class="truncate text-md font-medium text-gray-900 leading-tight">{{membership.name}}</h3>
                                    <a [href]="membership.fullBusinessUrl" class="flex-shrink-0 font-normal text-xs text-gray-500 hover:underline cursor-pointer">{{membership.businessName}}</a>
                                    <p class="mt-1 truncate text-sm text-gray-500" *ngIf="membership.paidUpUntilDateReadable">Paid Until: {{membership.paidUpUntilDateReadable}}</p>
                                    <p class="mt-1 truncate text-sm text-gray-500" *ngIf="membership.nextPaymentDateReadable">Next Payment: {{membership.nextPaymentDateReadable}}</p>
                                    <p class="mt-1 truncate text-sm text-gray-500" *ngIf="membership.card">Card: {{membership.card}}</p>
                                    <p *ngIf="pastdue" class="mt-1 truncate text-sm text-gray-500">PAST DUE</p>
                                    <p class="mt-1 truncate text-sm text-gray-500">Status: {{membership.statusReadable}}</p>
                                    <div class="flex my-3 justify-end items-center p-1">
                                        <button *ngIf="membership.status == MembershipUserSubscriptionStatus.CANCELLED" (click)="deleteMembership(membership)" class="wild-btn-light"><span class="text-red-500">delete</span></button>
                                        <button *ngIf="!membership.hidebuttons" (click)="cancel(membership)" class="wild-btn-light"><span class="text-red-500">cancel</span></button>
                                        <button *ngIf="!membership.hidebuttons" (click)="update(membership)" class="wild-btn-light ml-3">Update Card</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-wrapper>
                    <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                        (onRetry)="load()">
                    </app-retry>
                </app-wrapper>
            </ng-container>
            <ng-template #loading>
                <app-loader [disablescreenheight]="true"></app-loader>
            </ng-template>
        </ng-template>
    </div>
</app-wrapper>