import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchResult } from '../../data/models/SearchResult';
import { SearchResults } from '../../data/models/SearchResults';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  selector: "app-homepageresults",
  template: `
  <style>
    /* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbars::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbars {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
  </style>
  <div class="relative mx-auto  md:max-w-7xl py-3">
  <div class="text-gray-900 text-xl mb-3 mt-8 font-medium">
    <span *ngIf="location">{{label}} </span>
    <!-- Removed near as it was to inaccurate on IP -->
    <!-- near {{location}} -->
    <span *ngIf="!location">{{label}}</span>
  </div>
  <div class="flex">
    <div class="flex items-center justify-center w-0 z-10">
      <div *ngIf="showleftarrow" (click)="spotsScrollleft()"
        class="cursor-pointer shadow-xl  mb-20 absolute hidden md:flex sm:left-5 xl:-left-7 rounded-full bg-white wild-text-primary border-2 border-gray-100 text-2xl h-11 w-11  items-center justify-center ml-2">
        <fa-icon icon="chevron-left"></fa-icon>
      </div>
    </div>
    <div class="w-full overflow-x-scroll relative z-5 hidescrollbars pb-5" #resultsbox>
      <ul class="flex flex-row space-x-3">
        <app-usersearchresult [fixed]="true" *ngFor="let result of results" [result]="result">
        </app-usersearchresult>
        <li *ngIf="moretext">
          <div (click)="more()" style="width:200px; height:200px"
            class="rounded-lg shadow-xl flex items-center justify-center cursor-pointer border-2 border-gray-100 wild-bg-primary text-white font-medium hover:font-bold">
            {{moretext}}
          </div>
        </li>
      </ul>
    </div>
    <div class="flex items-center justify-center w-0 z-10">
      <div (click)="spotsScrollRight()"
        class="cursor-pointer shadow-xl  mb-20 absolute hidden md:flex sm:right-5 xl:-right-7 rounded-full bg-white wild-text-primary text-2xl h-11 w-11 items-center justify-center mr-2">
        <fa-icon icon="chevron-right"></fa-icon>
      </div>
    </div>
  </div>
</div> `
})
export class HomePageResultsComponent implements OnInit {

  @Input() moretext: string;
  @Input() label: string;
  @Input() location: string;
  @Input() results: SearchResult[];
  @Output() moreClick = new EventEmitter();
  @ViewChild('resultsbox') resultsbox;
  showleftarrow = false;

  constructor(
    private navigation: NavigationService,
    private router: Router) { }

  ngOnInit(): void {
  }
  spotsScrollRight() {
    this.resultsbox.nativeElement.scrollLeft += 320;
    this.showleftarrow = true;
  }
  spotsScrollleft() {
    this.resultsbox.nativeElement.scrollLeft -= 320;
    if (this.resultsbox.nativeElement.scrollLeft == 0)
      this.showleftarrow = false;
  }

  more() {
    this.moreClick.emit();
  }



}
