import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { BusinessRoutingModule } from './business-routing.module';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessesComponent } from './pages/businesses/businesses.component';
import { DashboardComponent } from './pages/business_children/dashboard/dashboard.component';
import { ChangeBusinessComponent } from './pages/businesses/components/changebusiness/changebusiness.component';
import { TeamComponent } from './pages/business_children/team/team.component';
import { AddteammemberformComponent } from './pages/business_children/team/components/addteammemberform/addteammemberform.component';
import { EditteammemberformComponent } from './pages/business_children/team/components/editteammemberform/editteammemberform.component';
import { InviteComponent } from './pages/invite/invite.component';
import { BrandComponent } from './pages/business_children/brand/brand.component';
import { SettingsComponent } from './pages/business_children/settings/settings.component';
import { BusinessswimspotsComponent } from './pages/business_children/businessswimspots/businessswimspots.component';
import { SwimSpotModule } from '../swimspots/swimspots.module';
import { BusinesssearchresultsComponent } from './pages/business_children/businesssearchresults/businesssearchresults.component';
import { BusinessStatsComponent } from './pages/business_children/payouts/components/business_dashboard_stats';
import { BusinessChartsComponent } from './pages/business_children/dashboard/components/business_dashboard_charts';
import { BusinessTODOsComponent } from './pages/business_children/dashboard/components/businesstodos.component';
import { AreaComponent } from './pages/business_children/area/area.component';
import { LocationComponent } from './pages/business_children/location/location.component';
import { PayoutsComponent } from './pages/business_children/payouts/payouts.component';
import { ConfigurationComponent } from './pages/business_children/configuration/configuration.component';
import { StatusComponent } from './pages/business_children/status/status.component';
import { AddressFormComponent } from './pages/business_children/location/addressform.component';
import { ContactsModule } from './modules/contacts/contacts.module';
import { OrdersModule } from './modules/orders/orders.module';
import { AppointmentsModule } from './modules/appointments/appointments.module';
import { UpcomingComponent } from './pages/business_children/dashboard/components/upcoming.component';
import { QuickActionsComponent } from './pages/business_children/dashboard/components/quickactions.component';
import { ExpressLinkComponent } from './pages/business_children/payouts/components/express_link_component';
import { UpdateLocationComponent } from './components/updatelocation.component';
import { OnboardingV2omponent } from './pages/onboardingv2/onboardingv2.component';
import { BusinessLinkComponent } from './pages/business_children/dashboard/components/business_dashboard_link';
import { WelcomeEmailComponent } from './pages/business_children/welcomeemail/welcomeemail.component';
import { OnlineBookingComponent } from './pages/business_children/onlinebookings/onlinebookings.component';
import { TimeSlotIntervalFormComponent } from './pages/business_children/onlinebookings/components/timeslotintervalform.component';
import { CancellationRefundRulesComponent } from './pages/business_children/onlinebookings/components/refundpolicyform.component';
import { InviteContactFormComponent } from './modules/contacts/components/invitecontactform.component';
import { BusinessEnableBookingModeComponent } from './pages/business_children/dashboard/components/business_enable_booking_mode';
import { BusinessTODOsRecommendedComponent } from './pages/business_children/dashboard/components/businesstodosrecommended.component';
import { BusinessTODOsIndividualComponent } from './pages/business_children/dashboard/components/businesstodosindividual.component';
import { WaiversComponent } from './pages/business_children/waivers/waivers.component';
import { NgxEditorModule } from 'ngx-editor';
import { SubscribeLinks } from './pages/business_children/configuration/subscribelinks.component';
import { SupportComponent } from './pages/business_children/support/support.component';
import { SendMessageComponent } from './components/sendmessage/sendmessage.component';
import { EmailBundleLinks } from './pages/business_children/configuration/emailbundlelinks.component';

@NgModule({
    declarations: [
        //Pages
        BusinessComponent,
        DashboardComponent,
        TeamComponent,
        BusinessesComponent,
        InviteComponent,
        BrandComponent,
        SettingsComponent,
        BusinessswimspotsComponent,
        BusinesssearchresultsComponent,
        LocationComponent,
        StatusComponent,
        AreaComponent,
        ConfigurationComponent,
        PayoutsComponent,
        WaiversComponent,
        WelcomeEmailComponent,
        //Components 
        // NotesComponent,
        SubscribeLinks,
        EmailBundleLinks,
        BusinessChartsComponent,
        SupportComponent,
        BusinessTODOsComponent,
        BusinessLinkComponent,
        AddressFormComponent,
        ChangeBusinessComponent,
        AddteammemberformComponent,
        EditteammemberformComponent,
        BusinessStatsComponent,
        UpcomingComponent,
        QuickActionsComponent,
        ExpressLinkComponent,
        UpdateLocationComponent,
        OnboardingV2omponent,
        OnlineBookingComponent,
        TimeSlotIntervalFormComponent,
        CancellationRefundRulesComponent,
        InviteContactFormComponent,
        SendMessageComponent,
        // SentMessagesListComponent,
        BusinessEnableBookingModeComponent,
        BusinessTODOsRecommendedComponent,
        BusinessTODOsIndividualComponent
    ],
    imports: [
        SharedModule,
        NgxEditorModule,
        SwimSpotModule,
        ContactsModule,
        OrdersModule,
        AppointmentsModule,
        BusinessRoutingModule,
    ]
})
export class BusinessModule { } 
