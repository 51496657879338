import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/shared/services/modal/modal_service';

@Component({
  template: ` 
    <app-modaltopper header="Welcome to Wild Pro"></app-modaltopper>
    <div class="text-center my-4">
        <p class="mb-4">We're thrilled to have you onboard.</p>
        <p>Make sure you download the Wild App to get the most out of your membership</p>
    </div>
    <app-appdownloadlinks header="Get the App"></app-appdownloadlinks>
    <button (click)="hide()" class="wild-btn-primary w-full block text-center mt-4">Continue with Pro</button>

  `
})
export class SubscribeWelcomeModalComponent implements OnInit {

  constructor(
    private modalService: ModalService) {
  }
  ngOnInit(): void {
  }

  hide() {
    window.location.href = window.location.href;
  }

}
