import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { MailinglistRepositoryService } from 'src/wild/modules/brochure/services/maillist_repository_service/mailinglist-repository.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  sent: boolean = false;
  loading: boolean = false;
  form = this._formBuilder.group({
    email: new UntypedFormControl('', [
      Validators.required
    ])
  });

  constructor(private _formBuilder: UntypedFormBuilder, private maillistRepository: MailinglistRepositoryService, private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.update("Newsletter", "Keep up to date with Wild and the latest Wild Swimming news", "swimming, open water, lake, river, beach, contact", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fwildlogo.png?alt=media&token=87b004dd-dd18-4815-a240-0f049dfc787b");
  }

  async onSubmit(data: any) {
    if (this.form.invalid)
      return;
    await this.update(data);
  }

  async update(data: any) {
    this.loading = true;
    await this.maillistRepository.add(data.email)
      .then(() => {
        this.loading = false;
        this.sent = true;
      })
      .catch((error) => {
        this.loading = false;
        //TODO - error handle notify me
      });
  }

}
