import { WildNavigationType } from "../../../../data/enums/navigation_type"

export interface FeaturesData {
    seoTitle: string,
    seoDescription: string,
    seoImage: string,
    icon?: string,
    seoKeyWords: string,
    page: WildNavigationType,
    components: FeaturesComponentData[]
}

export enum FeatureComponentType {
    UNKNOWN,
    RIGHTIMAGEINFOSECTION,
    LEFTIMAGEINFOSECTION,
    FAQS,
    BIGIMAGEFEATURE,
    IMAGECLOUD,
    SIMPLEHERO,
    ANIMATEDHERO,
    TEXTANDINFO,
    LOGOCLOUD,
    TEXTANDCIRCLES
}

export enum FeatureColour {
    UNKNOWN,
    WHITE,
    GRAY
}

export enum CTAFUNCTION {
    UNKNOWN,
    BUSINESSONBOARDING,
    BUSINESSLOGIN
}

export interface FeatureCTAsData {
    primary?: FeaturesComponentCTAData,
    secondary?: FeaturesComponentCTAData,
    tertiary?: FeaturesComponentCTAData
}

export interface FeaturesComponentCTAData {
    text: string,
    page?: WildNavigationType,
    function?: CTAFUNCTION
}

export interface FeaturesComponentData {
    type?: FeatureComponentType,
    colour: FeatureColour,
    image?: string,
    title?: string,
    subtitle?: string,
    limit?: number,
    paragraph?: string,
    data?: FeaturesComponentListData[],
    ctas?: FeatureCTAsData
}

export interface FeaturesComponentListData {
    icon?: string,
    title?: string,
    subtitle?: string,
    paragraph?:string,
    open?: boolean,
    image?: string,
    page?: WildNavigationType,
    link?:string
}