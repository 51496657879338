import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { realTimeSafetyOverview } from "../../shared/features/realtime_overview";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { seoUseCaseDescription } from "../../shared/seo/seo_description_template_usecases";
import { seoUseCaseKeyWords } from "../../shared/seo/seo_keywords_template_usecases";
import { seoUseCaseTitle } from "../../shared/seo/seo_title_template_usecases";
import { openWaterVenueListData } from "./openWaterVenue_listdata";
import { calendarComponent } from "../../shared/features/calendar";
import { organisedContacts } from "../../shared/features/organisedcontacts";
import { realTimeSafetyListData } from "../../features/realtimesafety/realtimesafety_data_listdata";
import { schedulingListDataShared } from "../../features/scheduling/scheduling_data_listdata";
import { ordersFinanceListData } from "../../features/finance/orders_finances_data_listdata";

const type = "Open Water Venue";
export const openWaterVenueData:FeaturesData = { 
  seoTitle:seoUseCaseTitle(type),
  seoDescription:seoUseCaseDescription(type,"swimming, supping, skiing or sailing"),
  seoImage:openWaterVenueListData.image,
  seoKeyWords:seoUseCaseKeyWords(type,"swimming, supping, skiing or sailing lesson"),
  page:openWaterVenueListData.page,
    components:[ 
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.SIMPLEHERO,
        ctas:businessCTAs,
        title:`${type}s`,
        subtitle:openWaterVenueListData.title,
        paragraph:openWaterVenueListData.subtitle,
      },
      injectSharedComponent(realTimeSafetyOverview,
        {
        ctas:{primary:{text:"Find out more",page:WildNavigationType.REALTIMESAFETYSYSTEM}},
        type:FeatureComponentType.BIGIMAGEFEATURE,
        colour:FeatureColour.WHITE}),
      injectSharedComponent(calendarComponent, { 
          colour: FeatureColour.WHITE,
           type: FeatureComponentType.BIGIMAGEFEATURE,
           ctas:{
              secondary:{
              text:"Learn More",
              page:WildNavigationType.SCHEDULINGBOOKINGENGINE
            }
          } }),
        injectSharedComponent(
          organisedContacts, { 
            colour:FeatureColour.WHITE,
            ctas:{
              secondary:{
              text:"Learn More",
              page:WildNavigationType.BUSINESSCRM
            }}
          }),
        {
          colour:FeatureColour.WHITE,
          type:FeatureComponentType.FAQS,
          title:"",
          subtitle:"Frequently Asked Questions",
          data:[
            {
              title: "Can I sell season passes?",
              subtitle: "Yes, we call them class passes and you can bundle up services into packages. Like a course of 10 lessons for a small discount."
            },
            {
              title: "Can the system handle walk-ins?",
              subtitle: "Yes, customers can join your orgnanisation without the need to book but ensure you stay completely paperless."
            },
            {
              title: "Do you provide training",
              subtitle: "Yes, we can provide system training and water safety advice if you need that too."
            },
            {
              title: "Can I just use the safety system without the booking part?",
              subtitle: "Yes, our safety system is completely free to use and can be used indepentant of the booking platform."
            },
            ...commonBusinesFAQs
          ],
          ctas: businessCTAs,
        },
        {
          colour: FeatureColour.WHITE,
          type: FeatureComponentType.TEXTANDINFO,
          title: "Explore More",
          subtitle: "Other Great Features for Coaches",
          limit: 9,
          paragraph: "",
          data: [
            realTimeSafetyListData,
            schedulingListDataShared,
            ordersFinanceListData
          ],
          ctas: {
            secondary: {
              text: "See All",
              page: WildNavigationType.ALLFEATURES
            }
          }
        }
  ] } 
