import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRefundOrderLineDTO } from '../../../../../data/dtos/booking/UserRefundOrderLineDTO';
import { OrderStatus } from '../../../../../data/enums/order_status';
import { OrderModel } from '../../../../../data/models/order_model';
import { FirestoreQueryType } from '../../../../../data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { BusinessService } from '../business_service/business_service';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BusinessOrdersService {

    constructor(
        private http: HttpService,
        private firestore: FirestoreService,
        private businessService: BusinessService,
    ) { }

    list(): Observable<OrderModel[]> {
        var businessId = this.businessService.getActiveBusinessId();
        return this.firestore.getListByQuery("orders", [
            {
                type: FirestoreQueryType.WHERE,
                operator: "==",
                property: "businessId",
                value: businessId
            },
            {
                type: FirestoreQueryType.WHERE,
                operator: "in",
                property: "status",
                value: [OrderStatus.PAID, OrderStatus.PARTIALREFUND, OrderStatus.FULLYREFUNDED]
            },
            {
                type: FirestoreQueryType.ORDERBY,
                operator: "==",
                property: "creation_date",
                value: "desc"
            }
        ])
    }

    businessRefundOrderLine(dto: UserRefundOrderLineDTO): Promise<OrderModel> {
        return this.http.post(`${environment.api}/business/orders/refundorderline`, dto).toPromise()
    }

}

