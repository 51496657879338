<div [ngClass]="{'justify-start': left == true,'sm:justify-center': left != true}" class="mt-8 flex gap-x-4 ">
    <a *ngIf="data.ctas.primary" (click)="execute(data.ctas.primary)"
      class="cursor-pointer inline-block rounded-lg wild-bg-primary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-900 hover:bg-blue-900 hover:ring-blue-900">
      {{data.ctas.primary.text}}
      <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
    </a>
    <a *ngIf="data.ctas.secondary" (click)="execute(data.ctas.secondary)" 
      class="cursor-pointer inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
      {{data.ctas.secondary.text}}
      <span class="text-gray-400" aria-hidden="true">&rarr;</span>
    </a>
  </div> 
  <div [ngClass]="{'sm:text-center': left != true}" *ngIf="data.ctas.tertiary" class="mt-10">
    <a (click)="execute(data.ctas.tertiary)"
      class="text-gray-900 cursor-pointer hover:underline">
      {{data.ctas.tertiary.text}} <span aria-hidden="true">→</span>
    </a>
  </div>