import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AvailabilityForServiceDTO } from '../../data/dtos/serviceAvailability/AvailabilityForServiceDTO';
import { Days } from '../../data/enums/days';
import { Months } from '../../data/enums/months';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { BookingDayStatus } from '../enums/booking_day_status';
import { BookingDay } from '../models/booking_day';
import { BookingMonth } from '../models/booking_month';
import { BookingWeek } from '../models/booking_week';

@Injectable({
    providedIn: 'root'
})
export class BookingDateService {

    public availabilityForServiceDTO: AvailabilityForServiceDTO

    constructor(
        private datesService: DatesService
    ) { }

    //WHERE TO START

    weekToStart(nextAvailableDate: string): BookingWeek {
        return this.getBookingWeek(nextAvailableDate);
    }

    //WHERE TO START END

    //CONTROLS

    currentWeek(): BookingWeek {
        var today = this.today();
        return this.getBookingWeek(today);
    }

    currentMonth(): BookingMonth {
        var today = this.today();
        return this.getBookingMonth(today);
    }

    //CONTROLS

    //DAYS START

    today() {
        return this.startOfDay(moment.utc().toISOString());
    }

    ifLessThanTodayReturnToday(isoDate: string) {
        var today = this.today();
        if (moment.utc(isoDate).isBefore(moment.utc(today))) {
            return today;
        } else {
            return isoDate;
        }
    }

    startOfDay(isoDate: string) {
        return moment.utc(isoDate).startOf('day').toISOString();
    }

    getReadableDayOfWeek(isoDate: string): string {
        return moment.utc(isoDate).format('dddd');
    }

    getReadableDayOfWeekShort(isoDate: string): string {
        return moment.utc(isoDate).format('ddd');
    }

    getBookingDayStatusForDay(dayIsoDate: string): BookingDayStatus {
        var month = this.getMonth(dayIsoDate);
        var day = this.dayOfWeek(dayIsoDate);
        var isAvailableOnThisDay = false;
        if(this.availabilityForServiceDTO != undefined){
            const teamMember = this.availabilityForServiceDTO.teamMemberAvailability;
            const teamMemberAvailabilityForThisMonth = teamMember.months.find(teamMemberMonth => teamMemberMonth.month == month);
            if (teamMemberAvailabilityForThisMonth && teamMemberAvailabilityForThisMonth.days?.length > 0)
                isAvailableOnThisDay = teamMemberAvailabilityForThisMonth.days.some(teamMemberDay => teamMemberDay == day);
            var isBefore = moment.utc(dayIsoDate).isBefore(this.today());
            if (isAvailableOnThisDay && !isBefore) {
                return BookingDayStatus.ACTIVE;
            } else {
                return BookingDayStatus.INACTIVE;
            }
        }else{
            return BookingDayStatus.INACTIVE;
        }
    }

    getBookingDay(isoDate: string): BookingDay {
        var bookingDay: BookingDay = {
            date: isoDate,
            day: this.dayOfWeek(isoDate),
            month: this.getMonth(isoDate),
            status: this.getBookingDayStatusForDay(isoDate),
            readableDay: this.getReadableDayOfWeek(isoDate),
            readableDayShort: this.getReadableDayOfWeekShort(isoDate),
            dayOfMonth: this.datesService.getCalendarDayOfMonth(isoDate)

        }
        return bookingDay;
    }

    //DAYS END

    //WEEK START

    startOfNextWeek(isoDate: string) {
        var nextWeek = moment.utc(isoDate).add(1, 'week').toISOString();
        return this.startOfWeek(nextWeek);
    }

    startOfPreviousWeek(isoDate: string) {
        var previousWeek = moment.utc(isoDate).subtract(1, 'week').toISOString();
        return this.startOfWeek(previousWeek);
    }

    dayOfWeek(isoDate: string): Days {
        var day = moment.utc(isoDate).day(); // gives 4 for thursday + Sunday is 0
        if (day == 1)
            return Days.MONDAY;
        if (day == 2)
            return Days.TUESDAY;
        if (day == 3)
            return Days.WEDNESDAY;
        if (day == 4)
            return Days.THURSDAY;
        if (day == 5)
            return Days.FRIDAY;
        if (day == 6)
            return Days.SATURDAY;
        if (day == 0)
            return Days.SUNDAY;
    }

    startOfWeek(isoDate: string) {
        //week gets Sunday and isoWeek gets Monday
        var startOfWeek = moment.utc(isoDate).startOf('isoWeek').toISOString();
        return this.startOfDay(startOfWeek);
    }

    endOfWeek(isoDate: string) {
        //week gets Sunday and isoWeek gets Monday
        var endOfWeek = moment.utc(isoDate).endOf('isoWeek').toISOString();
        return this.startOfDay(endOfWeek);
    }

    arrayOfDaysInWeek(isoDate: string): BookingDay[] {
        var startOfWeekIsoDate = this.startOfWeek(isoDate);
        var monday = this.getBookingDay(moment.utc(startOfWeekIsoDate).toISOString());
        var tuesday = this.getBookingDay(moment.utc(startOfWeekIsoDate).add(1, 'days').toISOString());
        var wednesday = this.getBookingDay(moment.utc(startOfWeekIsoDate).add(2, 'days').toISOString());
        var thursday = this.getBookingDay(moment.utc(startOfWeekIsoDate).add(3, 'days').toISOString());
        var friday = this.getBookingDay(moment.utc(startOfWeekIsoDate).add(4, 'days').toISOString());
        var saturday = this.getBookingDay(moment.utc(startOfWeekIsoDate).add(5, 'days').toISOString());
        var sunday = this.getBookingDay(moment.utc(startOfWeekIsoDate).add(6, 'days').toISOString());
        return [
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday
        ]
    }

    getBookingWeek(isoDate: string): BookingWeek {
        var days = this.arrayOfDaysInWeek(isoDate);
        var startOfWeek = days[0].date;
        var endOfWeek = days[6].date;
        var startOfPreviousWeek = moment.utc(startOfWeek).subtract(7, 'days').toISOString();
        var startOfNextWeek = moment.utc(endOfWeek).add(1, 'days').toISOString();
        var description = this.getMonthYearDescription(isoDate)
        var bookingWeek: BookingWeek = {
            days: days,
            previousWeekStart: startOfPreviousWeek,
            currentWeek: startOfWeek,
            nextWeekStart: startOfNextWeek,
            description: description
        }
        return bookingWeek;
    }

    //WEEK END

    //MONTH START

    startOfNextMonth(isoDate: string) {
        var nextMonth = moment.utc(isoDate).add(1, 'month').toISOString();
        return this.startOfMonth(nextMonth);
    }

    startOfPreviousMonth(isoDate: string) {
        var previousMonth = moment.utc(isoDate).subtract(1, 'month').toISOString();
        return this.startOfMonth(previousMonth);
    }

    startOfMonth(isoDate: string) {
        var startOfMonth = moment.utc(isoDate).startOf('month').toISOString();
        return this.startOfDay(startOfMonth);
    }

    endOfMonth(isoDate: string) {
        var startOfMonth = moment.utc(isoDate).endOf('month').toISOString();
        return this.startOfDay(startOfMonth);
    }

    getMonth(isoDate: string): Months {
        var month = moment.utc(isoDate).month(); // jan=0, dec=11
        if (month == 0)
            return Months.JANUARY;
        if (month == 1)
            return Months.FEBRUARY;
        if (month == 2)
            return Months.MARCH;
        if (month == 3)
            return Months.APRIL;
        if (month == 4)
            return Months.MAY;
        if (month == 5)
            return Months.JUNE;
        if (month == 6)
            return Months.JULY;
        if (month == 7)
            return Months.AUGUST;
        if (month == 8)
            return Months.SEPTEMBER;
        if (month == 9)
            return Months.OCTOBER;
        if (month == 10)
            return Months.NOVEMBER;
        if (month == 11)
            return Months.DECEMBER;
    }

    getBookingMonth(isoDate: string): BookingMonth {
        var month = this.getMonth(isoDate);
        var startOfMonth = this.startOfMonth(isoDate);
        var firstMondayOfMonth = this.startOfWeek(startOfMonth);
        var description = this.getMonthYearDescription(isoDate);
        var nextMonthStart = moment.utc(startOfMonth).add(1, "month").toISOString();
        var previousMonthStart = moment.utc(startOfMonth).subtract(1, "month").toISOString();
        var weeks: BookingWeek[] = [];
        weeks.push(this.getBookingWeek(firstMondayOfMonth));
        weeks.push(this.getBookingWeek(moment.utc(firstMondayOfMonth).add(1, 'week').toISOString()));
        weeks.push(this.getBookingWeek(moment.utc(firstMondayOfMonth).add(2, 'week').toISOString()));
        weeks.push(this.getBookingWeek(moment.utc(firstMondayOfMonth).add(3, 'week').toISOString()));
        //5th week
        var potentialFifthWeek = this.getBookingWeek(moment.utc(firstMondayOfMonth).add(4, 'week').toISOString());
        var firstDayOfFifthWeek = potentialFifthWeek.days[0].date;
        var monthOfFifthWeek = this.getMonth(firstDayOfFifthWeek);
        if (month == monthOfFifthWeek)
            weeks.push(potentialFifthWeek);
        //6th week
        var potentialSixthWeek = this.getBookingWeek(moment.utc(firstMondayOfMonth).add(5, 'week').toISOString());
        var firstDayOfSixthWeek = potentialSixthWeek.days[0].date;
        var monthOfSixthWeek = this.getMonth(firstDayOfSixthWeek);
        if (month == monthOfSixthWeek)
            weeks.push(potentialSixthWeek);
        var bookingMonth: BookingMonth = {
            description: description,
            nextMonthStart: nextMonthStart,
            currentMonth: startOfMonth,
            previousMonthStart: previousMonthStart,
            month: month,
            weeks: weeks
        }
        return bookingMonth;
    }

    //MONTH END


    //DESCRIPTIVE STUFF

    getMonthYearDescription(isoDate: string) {
        //6 JUN
        return moment.utc(isoDate).format("MMMM YYYY")
    }

    getDateDescriptionShort(isoDate: string) {
        //6 JUN
        return moment.utc(isoDate).format("D MMM")
    }

    getDateDescriptionLong(isoDate: string) {
        //6 JUNE
        return moment.utc(isoDate).format("D MMMM")
    }

    getMonthDescriptionLong(isoDate: string) {
        //JUNE
        return moment.utc(isoDate).format("MMMM")
    }

    //DESCRIPTIVE STUFF

}
