<app-wrapper header="Waiver Configuration" [fullwidth]="true"
    paragraph="By enabling waivers, all contacts and linked contacts will receive your waiver and will be requested to sign it. All signed waivers will be audited against your contacts. ">
    <ng-container *ngIf="waiver$ | async as waiver; else loadingOrError">
        <div *ngIf="!waiver; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
            <div class="flex flex-col md:flex-row">
                <div class="md:w-2/4 lg:w-3/5">
                    <div class="bg-white p-4 shadow sm:rounded-lg w-full mb-6">
                        <h2 class="text-sm font-medium text-gray-900 mb-4">Waiver Content</h2>
                        <div class="rounded-md bg-blue-50 p-4 mb-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div class="ml-3 flex-1 md:flex md:justify-between">
                                    <p class="text-sm text-blue-700">Fill out the content you would like to be signed.
                                        Signed copies of this waiver will be stored against your contacts.</p>
                                    <p class="mt-3 text-sm md:mt-0 md:ml-6"></p>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="contentform">
                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
                                <ngx-editor [editor]="editor" formControlName="html"
                                    [placeholder]="'Add your waiver content here...'"></ngx-editor>
                            </div>
                        </form>
                        <div class="flex items-center justify-end my-6">
                            <button (click)="updateContent()" class="wild-btn-primary w-40">
                                <span *ngIf="!loading">Update Waiver</span>
                                <div *ngIf="loading" class="loader"></div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="md:w-2/4 lg:w-2/5 md:pl-4 mt-4 md:mt-0">
                    <div class="rounded-lg shadow bg-white p-4 mb-6">
                        <h2 class="text-sm font-medium text-gray-900 mb-4">Status</h2>
                        <div class="rounded-md bg-blue-50 p-4 mb-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div class="ml-3 flex-1 md:flex md:justify-between">
                                    <p class="text-sm text-blue-700">When your Waiver is enabled, contacts will be sent
                                        a waiver to sign upon joining & booking.</p>
                                    <p class="mt-3 text-sm md:mt-0 md:ml-6">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <app-form [form]="statusform" [fullwidthbutton]="false" ctaText="Update" disableDivide="true"
                            (onSubmit)="onSubmit($event)" [loading]="loading">
                            <fieldset class="flex flex-col space-y-3 mt-2">
                                <app-checkbox [form]="statusform" label="Waiver Enabled" name="enabled"
                                    [value]="waiver.enabled"></app-checkbox>
                                <app-checkbox [form]="statusform"
                                    label="I acknowledge that this waiver is produced by my organisation and take full responsibility for the contents within. "
                                    name="acknowledged" [value]="waiver.acknowledged"></app-checkbox>
                            </fieldset>
                        </app-form>
                    </div>
                    <div class="rounded-lg shadow bg-white p-4 mb-6">
                        <h2 class="text-sm font-medium text-gray-900 mb-4">Version</h2>
                        <div class="rounded-md bg-blue-50 p-4 mb-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div class="ml-3 flex-1 md:flex md:justify-between">
                                    <p class="text-sm text-blue-700">By selecting force resign, you will generate a new
                                        Waiver version. Contacts that haven't signed this latest version will be sent a
                                        new waiver to sign when they next book.</p>
                                    <p class="mt-3 text-sm md:mt-0 md:ml-6"></p>
                                </div>
                            </div>
                        </div>
                        <h2 class="text-sm mb-4">You are currently on waiver Version {{waiver.version}}</h2>
                        <div class="flex items-center justify-end my-6">
                            <button (click)="newversion()" class="wild-btn-primary w-40">
                                <span *ngIf="!loading">Force Resign</span>
                                <div *ngIf="loading" class="loader"></div>
                            </button>
                        </div>
                    </div>
                    <div class="rounded-lg shadow bg-white p-4 mb-6">
                        <h2 class="text-sm font-medium text-gray-900 mb-4">Audit</h2>
                        <p class="text-xs mb-2" *ngIf="waiver.readabledate"> Last Updated: {{waiver.readabledate}}
                        </p>
                        <p class="text-xs mb-4" *ngIf="waiver.readabledate"> Waiver Last Updated By:
                            {{waiver.created_by}}</p>
                    </div>
                    <br />
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>