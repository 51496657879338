<div *ngIf="!hasUsername()">
    <app-empty icon="user" header="No Username" description="Choose a username to activate your profile"
        ctatext="Choose Username" [showcta]="true" (onClick)="username()"></app-empty>
</div>
<div *ngIf="hasUsername()">
    <ng-container *ngIf="user$ | async as user; else loadingOrError">
        <div *ngIf="!user; else userprofile">
            User Not Found
        </div>
        <ng-template #userprofile>
            <app-wrapper [header]="'Profile'" [paragraph]="user?.username ? '@' + user?.username : 'username not set'">
                <div class="h-32 w-full lg:h-48 rounded-lg wild-bg-primary">
                    <!-- <img class="h-32 w-full object-cover lg:h-48 rounded-lg"
                        [src]="'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fbanner2.jpg?alt=media&token=8ff95c04-7f85-4b2f-9b75-d558e09e01cd'"
                        alt=""> -->
                </div>
                <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div (click)="updateProfilePhoto()" class="cursor-pointer flex relative">
                            <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                                (load)="imageLoaded($event, user.profilePicture)" data-max-retry="5" data-retry="0"
                                (error)="waitAndReload($event, user.profilePicture,'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d')"
                                [src]="user.profilePicture != null ? imageUrl + user.profilePicture.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                                alt="">
                            <div *ngIf="user.profilePicture?.fetching"
                                class="z-50 absolute top-0 left-0 w-full h-full bg-white opacity-30">
                                <app-loader [disablescreenheight]="true"></app-loader>
                            </div>
                            <div class="absolute bottom-0 w-full flex items-center justify-center text-center">
                                <fa-icon class="text-lg text-white" icon="edit"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                            <dt class="text-sm font-medium text-gray-500 truncate">
                                Swim Spots
                            </dt>
                            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                                {{user.numberOfSwimSpots ?? 0}}
                            </dd>
                        </div>
                        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                            <dt class="text-sm font-medium text-gray-500 truncate">
                                Contributions
                            </dt>
                            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                                {{user.numberOfContributions ?? 0}}
                            </dd>
                        </div>
                        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                            <dt class="text-sm font-medium text-gray-500 truncate">
                                Reviews
                            </dt>
                            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                                {{user.numberOfReviews ?? 0}}
                            </dd>
                        </div>


                    </dl>
                </div>
                <br />
                <app-appdownloadlinks header="Access your full profile on the FREE Wild app"></app-appdownloadlinks>
            </app-wrapper>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
</div>