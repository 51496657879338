<div *ngIf="!tabsId">
  Please send tabsId
</div>
<div *ngIf="tabsId && tabs && tabs.length > 1">
  <div class="sm:hidden">
    <h4 class="wild-paragraph mb-1">Change Tab</h4>
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select [ngModel]="activetabname" #tabselect (change)="onChange($event, tabselect.value)" id="tabs" name="tabs"
      class="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md">
      <option *ngFor="let tab of registeredTabs" [value]="tab.name || tab.count == 0">{{tab.name}} <span
          *ngIf="tab.count || tab.count == 0">({{tab.count}})</span></option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <a (click)="onClick(tab)" *ngFor="let tab of registeredTabs"
          [ngClass]="{'border-blue-500 text-blue-600':tab.active,'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':!tab.active}"
          class="cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm">
          <fa-icon *ngIf="tab.icon"
            [ngClass]="{'text-blue-500':tab.active,'text-gray-400 group-hover:text-gray-500':!tab.active}"
            class="h-5 w-5 text-gray-400 group-hover:text-gray-500" [icon]="tab.icon"></fa-icon>
          <span>{{tab.name}} <span *ngIf="tab.count || tab.count == 0">({{tab.count}})</span></span>
        </a>
      </nav>
    </div>
  </div>
</div>