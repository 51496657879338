import { WildNavigationType } from "../../../../../data/enums/navigation_type";
import { bookingExperienceListData } from "../features/bookingexperience/booking_experience_data_listdata";
import { businessappListData } from "../features/businessapp/business_app_data_listdata";
import { communicationsListData } from "../features/communications/communications_data_listdata";
import { crmListData } from "../features/customers/customer_management_listdata";
import { ordersFinanceListData } from "../features/finance/orders_finances_data_listdata";
import { realTimeSafetyListData } from "../features/realtimesafety/realtimesafety_data_listdata";
import { reportingListData } from "../features/reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../features/scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../features/team/team_data_listdata";
import { FeatureColour, FeatureComponentType, FeaturesData } from "../FeaturesData";
import { injectSharedComponent } from "../helpers/inject_shared_component";
import { businessCTAs } from "../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../shared/faqs/common_business_faqs";
import { usecases } from "../shared/usecases";
import { whyyouwilllove } from "../shared/whyyouwilllove";

export const forBusinessData: FeaturesData = {
  seoTitle: "Management & Booking Software for Watersports",
  seoDescription: "Booking software for your Open Water Venue, Surf School, SUP school, Sailing school & Swim School. Save money & time | #wildopenwater",
  seoImage: "",
  seoKeyWords: "Booking software, Booking software for Water Sports, Surf School Software, Open Water Coach Software, Swim School Software",
  page: WildNavigationType.UNKNOWN,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.ANIMATEDHERO,
      ctas: businessCTAs,
      title: "Made For",
      subtitle: "The #1 Booking Platform for Water Sports",
      paragraph: "Simple, flexible & powerful software for your business.",
      data: [
        {
          title: "venues."
        },
        {
          title: "schools."
        },
        {
          title: "coaches."
        },
        {
          title: "centers."
        },
      ]
    },
    whyyouwilllove,
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.BIGIMAGEFEATURE,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fscheduling%2Fcalendar.png?alt=media&token=75933245-a861-4e0a-a899-8ad565a1b8f5",
      title: "Easy & Powerful",
      subtitle: "Grow Your Business with Wild",
      paragraph: "Our mission is to grow & support your business!",
      data: [
        {
          icon: "mouse-pointer", title: "Drag & Drop.", subtitle: "Easy Scheduling."
        },
        {
          icon: "infinity", title: "Recurring.", subtitle: "Programmable Schedules."
        },
        {
          icon: "stopwatch", title: "Working Hours.", subtitle: "Calendar based bookings."
        },
        {
          icon: "calendar", title: "Automatic Breaks.", subtitle: "Gaps between bookings."
        },
        {
          icon: "table", title: "Timetables.", subtitle: "Sync pool or tide times."
        },
        {
          icon: "mouse-pointer", title: "Reschedule.", subtitle: "Automatic Customer Comms."
        },
      ]
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE, ctas: null }),
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Features",
      subtitle: "Everything You Need.",
      limit: 9,
      paragraph: "",
      data: [
        realTimeSafetyListData,
        bookingExperienceListData,
        communicationsListData,
        schedulingListDataShared,
        teamListDataShared,
        businessappListData,
        ordersFinanceListData,
        reportingListData,
        crmListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs
    },
  ]
} 