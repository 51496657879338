import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WildNavigationType } from '../../data/enums/navigation_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { StorageService } from 'src/shared/services/storage/storage.service';
@Component({
  template: `
  <app-loader></app-loader>
  `,
})
export class CustomTokenAuthAndRedirectComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private route: ActivatedRoute, private navigation: NavigationService, private storage: StorageService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      var type: string = params.nav;
      var notapp: string = params.notapp;
      if (type == null || type == undefined || type == "") {
        this.navigation.navigate(WildNavigationType.NOTFOUND);
      } else {
        if (notapp != "yes")
          this.storage.setCookie("appuser", "1", 1)
        //IMPORTANT - leave this timeout in... the loader is shown for this second and allows authentication to set in
        setTimeout(() => {
          this.navigation.navigate(parseInt(type), params);
        }, 1000)
      }
    });
  }

}
