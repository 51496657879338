import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorType } from '../../../../../data/enums/error_type';
import { ErrorModel } from '../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { SearchService } from 'src/shared/services/search/search_service';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { SearchResultType } from '../../SearchResultType';
import { SelectedSearchResult } from '../../selected_search_result';

@Component({
  selector: 'app-searchresults-swimspots',
  templateUrl: './searchresults-swimspots.component.html',
  styleUrls: ['./searchresults-swimspots.component.scss']
})
export class SearchresultsSwimspotsComponent implements OnInit {

  @Output() resultSelected = new EventEmitter<SelectedSearchResult>();

  constructor(
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private storage: StorageService,
    private navigation: NavigationService,
    private router: Router
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.loadResults();
      }
    });
  }

  error: ErrorModel;
  latitude: string;
  longitude: string;
  SearchResultType = SearchResultType;
  query;

  swimspots$: Observable<any>;

  ngOnInit(): void {
    this.loadResults();
  }

  addSwimSpot() {
    this.storage.setCookie("swimspotlat", this.latitude, 1);
    this.storage.setCookie("swimspotlng", this.longitude, 1);
    this.navigation.navigate(WildNavigationType.USERADDSWIMSPOT, {});
  }

  loadResults() {
    this.swimspots$ = null;
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.latitude = params.latitude;
      this.longitude = params.longitude;
      this.query = params.query;
      if (this.latitude == null || this.latitude == undefined || this.latitude == "") {
        this.error = {
          code: ErrorType.MISSINGLATITUDEONSEARCH,
          message: "Latitude not found",
          description: "Please try searching again and select a location"
        }
        return;
      }
      if (this.longitude == null || this.longitude == undefined || this.longitude == "") {
        this.error = {
          code: ErrorType.MISSINGLONGITUDEONSEARCH,
          message: "Longitude not found",
          description: "Please try searching again and select a location"
        }
        return;
      }
      if (this.query == null || this.query == undefined || this.query == "") {
        this.error = {
          code: ErrorType.MISSINGQUERYONSEARCH,
          message: "Query not found",
          description: "Please try searching again and select a location"
        }
        return;
      }
      this.swimspots$ = this.searchService.nearestswimspots(params.latitude, params.longitude).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    });
  }

  selected(result: SelectedSearchResult) {
    this.resultSelected.emit(result);
  }

}
