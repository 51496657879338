import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '../../../../../data/models/location_model';
import { FirestoreQueryType } from '../../../../../data/enums/firestore_query_type';
import { LocationType } from '../../../../../data/enums/location_type_enum';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class DirectoryRepositoryService {

  constructor(private firestore: FirestoreService) { }

  getCountries(): Observable<Location[]> {
    return this.firestore.getListByQuery("locations", [
      {
        operator: "==",
        property: "type",
        type: FirestoreQueryType.WHERE,
        value: LocationType.COUNTRY
      },
      {
        operator: "==",
        property: "name",
        type: FirestoreQueryType.ORDERBY,
        value: "asc"
      }
    ])
  }
}

