export enum Currency {
    UNKNOWN,
    //Pounds
    GBP,
    //Euros
    EUR,
    //US Dollars
    USD,
    //Australian dollar
    AUD,
    //Brazilian Real
    BRL,
    //Bulgarian Lev
    BGN,
    //Canadian Dollar
    CAD,
    //Czech Republic Koruna
    CZK,
    //Danish Krone
    DKK,
    //Hungarian Forint
    HUF,
    //Hong Kong Dollar
    HKD,
    //Indian Rupee
    INR,
    //Indonesian Rupiah
    IDR,
    //Japanese Yen
    JPY,
    //Swiss Franc
    CHF,
    //Malaysian Ringgit
    MYR,
    //Mexican Peso	
    MXN,
    //New Zealand Dollar	
    NZD,
    //Norwegian Krone
    NOK,
    //Polish Zloty
    PLN,
    //Romanian Leu
    RON,
    //Singapore Dollar
    SGD,
    //Swedish Krona
    SEK,
    //Thai Baht
    THB,
    //United Arab Emirates Dirham
    AED,
}	