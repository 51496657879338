import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { PersonalModel } from '../../../../../../../data/models/personal_model';
import { PhoneModel } from '../../../../../../../data/models/phone_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Update Name" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
          <app-input [form]="form" type="text" name="firstName" description="First Name" [span]=6></app-input>
          <app-input [form]="form" type="text" name="surname" description="Surname" [span]=6></app-input>
          <app-input [form]="form" type="text" name="pronouns" description="Pronouns" [span]=6></app-input>
      </app-inputgroup>
  </app-form> 
  `
})
export class ContactNameFormComponent implements OnInit {

  @Input() contact: BusinessContactModel;
  @Output() onComplete = new EventEmitter<PersonalModel>();

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: new UntypedFormControl(this.contact?.personal?.firstName ?? '', [Validators.required]),
      surname: new UntypedFormControl(this.contact?.personal?.surname ?? '', [Validators.required]),
      pronouns: new UntypedFormControl(this.contact?.personal?.pronouns ?? '', []),
    });
  }

  onSubmit(data: PersonalModel) {
    this.loading = true;
    this.contactService.updateName(this.contact.id, data)
      .then(() => {
        this.next(data);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Name", message, NotifyType.ERROR);
  }

  next(personal: PersonalModel) {
    this.notifyService.notify("Name Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.onComplete.emit(personal);
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
