import { WildNavigationType } from "src/data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../FeaturesData";
import { bookingExperienceListData } from "../features/bookingexperience/booking_experience_data_listdata";
import { businessappListData } from "../features/businessapp/business_app_data_listdata";
import { communicationsListData } from "../features/communications/communications_data_listdata";
import { crmListData } from "../features/customers/customer_management_listdata";
import { ordersFinanceListData } from "../features/finance/orders_finances_data_listdata";
import { realTimeSafetyListData } from "../features/realtimesafety/realtimesafety_data_listdata";
import { reportingListData } from "../features/reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../features/scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../features/team/team_data_listdata";
import { injectSharedComponent } from "../helpers/inject_shared_component";
import { businessCTAs } from "../shared/ctas/business_ctas";
import { usecases } from "../shared/usecases";
import { pricingListData } from "./pricing_data_listdata";
import { commonBusinesFAQs } from "../shared/faqs/common_business_faqs";


export const pricingData: FeaturesData = {
  seoTitle: "Free Watersports Software for Businesses",
  seoDescription: "Free Watersports Booking Software for Businesses. Manage your business with ease with built in water safety, scheduling, team management, CRM, communications, finance, reporting and more.",
  seoImage: pricingListData.image,
  seoKeyWords: "",
  page: pricingListData.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: pricingListData.title,
      subtitle: pricingListData.subtitle,
      paragraph: "Bookers pay a small fee to use Wild. This allows us to provide software to you free of charge.",
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Features",
      subtitle: "What you get for FREE.",
      limit: 9,
      paragraph: "",
      data: [
        realTimeSafetyListData,
        bookingExperienceListData,
        communicationsListData,
        schedulingListDataShared,
        teamListDataShared,
        businessappListData,
        ordersFinanceListData,
        reportingListData,
        crmListData
      ],
      ctas: {
        secondary: {
          text: "See More",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "What do bookers pay?",
          subtitle: "It costs £1 to book for customers or they pay 50p per month and they can book for free all year."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

