import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { AddswimspotComponent } from './components/addswimspot/addswimspot.component';
import { AddSwimspotdetailsComponent } from './components/addswimspot/components/addswimspotdetails/addswimspotdetails.component';
import { AddSwimSpotImagesComponent } from './components/addswimspot/components/addswimspotimages.component';
import { SwimspotAreaComponent } from './components/addswimspot/components/swimspotarea/swimspotarea.component';
import { SwimspotlocationComponent } from './components/addswimspot/components/swimspotlocation/swimspotlocation.component';
import { AlertsComponent } from './components/swimspotdetails/components/alerts.component';
import { OceanForecastComponent } from './components/swimspotdetails/components/oceanForecast/oceanforecast.component';
import { ReviewsComponent } from './components/swimspotdetails/components/reviews.component';
import { SwimSpotMapComponent } from './components/swimspotdetails/components/swimspotmap.component';
import { SwimSpotPostsComponent } from './components/swimspotdetails/components/swimspotposts.component';
import { SwimSpotTagsComponent } from './components/swimspotdetails/components/tags.component';
import { TideTableComponent } from './components/swimspotdetails/components/tidetable.component';
import { SwimspotdetailsComponent } from './components/swimspotdetails/swimspotdetails.component';
import { GalleryModule } from 'ng-gallery';
import { SocialBarComponent } from './components/swimspotdetails/components/socialbar.component';

@NgModule({
    declarations: [
        AddswimspotComponent,
        SwimspotdetailsComponent,
        SwimspotlocationComponent,
        SwimSpotPostsComponent,
        SwimSpotTagsComponent,
        OceanForecastComponent,
        AlertsComponent,
        ReviewsComponent,
        TideTableComponent,
        SwimSpotMapComponent,
        AddSwimspotdetailsComponent,
        AddSwimSpotImagesComponent,
        SwimspotAreaComponent,
        SocialBarComponent
    ],
    exports: [
        AddswimspotComponent
    ],
    imports: [
        SharedModule,
        GalleryModule
    ]
})
export class SwimSpotModule { }
