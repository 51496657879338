import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { ConfigurationService } from '../../../services/configuration/configuration_service';
import { SubscriptionLinksDTO } from '../../../../../../data/dtos/business/configuration/SubscriptionLinksDTO';
import { BusinessSubscriptionService } from '../../../services/business_subscription/business_subscription_service';
import { SubscribeDTO } from '../../../../../../data/dtos/business/configuration/SubscribeDTO';
import { SubscriptionType } from '../../../../../../data/enums/subscription_type';
import { BusinessEmailBundleService } from '../../../services/business_email_bundle/business_email_bundle_service';
import { EmailBundleLinksDTO } from 'src/data/dtos/business/configuration/EmailBundleLinksDTO';

@Component({
  template: `
  <div *ngIf="links$">
    <ng-container *ngIf="links$ | async as links; else loadingOrError">
    <app-modaltopper [header]="'Buy Email Credits'"></app-modaltopper>
       <div class="flex justify-evenly my-6 flex-col sm:flex-row">
          <div class="text-center w-48 mb-4 sm:mb-0">
            <div class="w-full flex items-center justify-center h-8 mb-4">
              &nbsp;
            </div>
            <p class="text-6xl font-semibold">{{links.low.price}}</p>
            <h3 class="mt-1 text-sm font-medium text-gray-900 mb-4">{{links.low.name}}</h3>
            <a [href]="links.low.url" class="wild-btn-primary w-full mt-3">Buy</a>
          </div>
          <div class="text-center w-48 mb-4 sm:mb-0">
            <div class="w-full flex items-center justify-center h-8 mb-4">
              <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">25% Extra Free</span>
            </div>
            <p class="text-6xl font-semibold">{{links.medium.price}}</p>
            <h3 class="mt-1 text-sm font-medium text-gray-900 mb-4">{{links.medium.name}}</h3>              
            <a [href]="links.medium.url" class="wild-btn-primary w-full mt-3">Buy</a>
          </div>
          <div class="text-center w-48 mb-4 sm:mb-0">
            <div class="w-full flex items-center justify-center h-8 mb-4">
              <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Best Value</span>
            </div>
            <p class="text-6xl font-semibold">{{links.high.price}}</p>
            <h3 class="mt-1 text-sm font-medium text-gray-900 mb-4">{{links.high.name}}</h3>              
            <a [href]="links.high.url" class="wild-btn-primary w-full mt-3">Buy</a>
          </div>
       </div> 
       <p class="text-xs text-gray-500 text-center w-full">Email Credit is applied to your account immediately</p>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `
})
export class EmailBundleLinks implements OnInit {

  @Input() type: SubscriptionType;
  @Output() updated = new EventEmitter();
  imagesUrl = environment.imagesUrl;
  error: ErrorModel;
  links$: Observable<EmailBundleLinksDTO>;

  constructor(
    private configurationService: ConfigurationService,
    private businessEmailBundleService: BusinessEmailBundleService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.links$ = null
    var dto: SubscribeDTO = {
      subscription: this.type
    }
    this.links$ = this.businessEmailBundleService.getEmailBundleLinks().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
