import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class ActivityContactsService {

    constructor(
        private http: HttpService
    ) { }

    async add(activityId: string, contactId: string): Promise<void> {
        await this.http.post(`${environment.api}/business/activities/${activityId}/contact/${contactId}`, {}).toPromise();
    }

    async del(activityId: string, contactId: string): Promise<void> {
        await this.http.delete(`${environment.api}/business/activities/${activityId}/contact/${contactId}`).toPromise();
    }

    async attended(activityId: string, contactId: string): Promise<void> {
        await this.http.post(`${environment.api}/business/activities/${activityId}/contact/${contactId}/attended`,{}).toPromise();
    }
    async didnotattend(activityId: string, contactId: string): Promise<void> {
        await this.http.post(`${environment.api}/business/activities/${activityId}/contact/${contactId}/didNotAttend`,{}).toPromise();
    }
    async removeAttendanceStatus(activityId: string, contactId: string): Promise<void> {
        await this.http.post(`${environment.api}/business/activities/${activityId}/contact/${contactId}/removeAttendanceStatus`,{}).toPromise();
    }

}
