import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinessMode } from '../../../../../data/enums/business_mode';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { FeatureComponentType, FeaturesComponentData } from '../../feature_data/FeaturesData';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html'
}) 
export class FeaturesComponent implements OnInit { 
  constructor( 
    private route:ActivatedRoute, 
    private seo: SeoService) { }

  components:FeaturesComponentData[];
  FeatureComponentType = FeatureComponentType;

  ngOnInit(): void {
    this.components = this.route.snapshot.data['components'];
    this.seo.update(this.route.snapshot.data['seoTitle'], this.route.snapshot.data['seoDescription'], this.route.snapshot.data['seoKeyWords'], this.route.snapshot.data['seoImage']);
  }
}
