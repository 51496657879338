import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BusinessPublicModel } from '../../../data/models/business_public_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BookingService } from '../../services/booking_service';
import { CreateCheckoutDTO } from '../../../data/dtos/booking/CreateCheckoutDTO';
import { RemoveOrderLineDTO } from '../../../data/dtos/booking/RemoveOrderLineDTO';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { UserOrderDetailsDTO } from '../../../data/dtos/booking/UserOrderDetailsDTO';
import { UserOrderLineDetailsDTO } from '../../../data/dtos/booking/UserOrderLineDetailsDTO';
import { Location } from '@angular/common'
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApplyUserCouponDTO } from '../../../data/dtos/coupons/ApplyUserCouponDTO';
import { CheckOutForFreeDTO } from '../../../data/dtos/booking/CheckOutForFreeDTO';
import { RemoveUserCouponsFromCheckoutDTO } from '../../../data/dtos/coupons/RemoveUserCouponsFromCheckoutDTO';
import { CheckoutType } from '../../../data/enums/checkout_type';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
})
export class CheckoutComponent implements OnInit {

  business: BusinessPublicModel;
  @ViewChild('paymentform') paymentForm: ElementRef;
  @ViewChild('paymentelement') paymentElement: ElementRef;
  processingPayment: boolean;
  imagesUrl = environment.imagesUrl;
  businessId: string;
  checkoutForFree: boolean = false;
  order: UserOrderDetailsDTO;
  token: string;
  loadingPage: boolean = true;
  loadingStripe: boolean = true;
  expiredMessage: string;
  isAppUser: boolean = false;
  isWidgetUser: boolean = false;
  form: UntypedFormGroup;
  loadingVoucherCodeForm: boolean;
  termsUrl: string;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private router: Router,
    private appComms: CommunitcateWithAppService,
    private auth: AuthenticationService,
    private notify: NotifyService,
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
    private navigate: NavigationService,
    private location: Location
  ) {
    this.form = this.fb.group({
      code: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    });
    this.isAppUser = this.auth.isAppUser;
    this.isWidgetUser = this.auth.isWidgetUser;
    this.load();
  }

  goToCart() {
    this.navigate.navigate(WildNavigationType.BOOKINGBASKET)
  }

  goToUserDetails() {
    this.navigate.navigate(WildNavigationType.BOOKINGUSERDETAILS)
  }
  back() {
    this.location.back()
  }

  load() {
    this.loadingPage = true;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.businessId = params.businessId;
      var type = CheckoutType.NORMAL;
      if (this.isAppUser) {
        type = CheckoutType.APP;
      } else if (this.isWidgetUser) {
        type = CheckoutType.WIDGET;
      }
      var createCheckoutDTO: CreateCheckoutDTO = {
        businessId: this.businessId,
        type: type
      }
      this.bookingService.createCheckoutV2(createCheckoutDTO)
        .then((checkoutDetails) => {
          this.order = checkoutDetails.order;
          if (this.order.allHaveAttendees != true) {
            this.router.navigate(["details"], { queryParams: { businessId: this.order.businessId } });
          } else {
            this.token = checkoutDetails.token;
            this.startTimers(checkoutDetails.order);
            if (this.order.free == true) {
              this.checkoutForFree = true;
            } else {
              this.initStripe(type);
            }
          }
        })
        .catch((error) => {
          //This will most likely error if they have remove all items from cart. This allows them to go again is that's not the case
          this.navigate.navigate(WildNavigationType.BOOKINGBASKET);
        }).finally(() => {
          this.loadingPage = false;
        });
    });
  }

  removeOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: orderLine.removeFromCartMessageDescription,
      header: orderLine.removeFromCartMessageHeader,
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemoveOrderLine(order, orderLine);
      }
    }
    this.modalService.show(settings);
  }

  async processRemoveOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      header: "Removing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    var removeOrderLineDTO: RemoveOrderLineDTO = {
      businessId: order.businessId,
      orderLineId: orderLine.id
    }
    await this.bookingService.removeOrderLine(removeOrderLineDTO)
      .then(async () => {
        setTimeout(() => {
          //Needs a little delay here - causes stripe error when reloading elements to fast. SHIT!
          window.location.href = window.location.href;
        }, 500)
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        // this.modalService.hide(); 
      });
  }


  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  ngOnInit(): void {
    this.termsUrl = environment.domain + "/terms"
    if (this.isAppUser) {
      this.expiredMessage = "go to services";
    } else if (this.isWidgetUser) {
      this.expiredMessage = "go back";
    } else {
      this.expiredMessage = "go to search";
    }
  }

  expiredCTA() {
    if (this.isAppUser) {
      this.appComms.backToStartOnApp();
    } else if (this.isWidgetUser) {
      this.back();
    } else {
      this.search();
    }
  }

  applyVoucherCode(code: string) {
    this.loadingVoucherCodeForm = true;
    var dto: ApplyUserCouponDTO = {
      businessId: this.businessId,
      code: code
    }
    this.bookingService.applyVoucher(dto)
      .then(async () => {
        setTimeout(() => {
          //Needs a little delay here - causes stripe error when reloading elements to fast. SHIT!
          window.location.href = window.location.href;
        }, 500)
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loadingVoucherCodeForm = false;
      })
  }


  async removeVouchers() {
    var settings: ModalModel = {
      header: "Removing Voucher",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    var dto: RemoveUserCouponsFromCheckoutDTO = {
      businessId: this.businessId,
    }
    await this.bookingService.removevouchers(dto)
      .then(async () => {
        setTimeout(() => {
          //Needs a little delay here - causes stripe error when reloading elements to fast. SHIT!
          window.location.href = window.location.href;
        }, 500)
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      });
  }

  submitVoucherCode(data: any) {
    this.applyVoucherCode(data.code);
  }

  confirmFreeOrder() {
    if (this.processingPayment == true)
      return;
    this.processingPayment = true;
    var dto: CheckOutForFreeDTO = {
      businessId: this.businessId
    }
    this.bookingService.checkoutforfree(dto)
      .then(async () => {
        this.navigate.navigate(WildNavigationType.ORDERCONFIRMATION, {
          "orderId": this.order.id
        })
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.processingPayment = false;
      })
  }


  startTimers(order: UserOrderDetailsDTO) {
    this.bookingService.startTimerForUserShoppingCart(order);
  }

  async initStripe(type: CheckoutType) {
    var redirectUrl = environment.domain + '/confirmation';
    if (type == CheckoutType.WIDGET)
      redirectUrl = environment.domain + `/customtokenauth?token=${this.token}&nav=${WildNavigationType.ORDERCONFIRMATION}&notapp=yes`;
    //TODO - https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements    
    const stripe = await loadStripe(environment.stripe);
    const options = {
      clientSecret: this.order.clientSecret,
      // appearance: {/*...*/},
    };
    const elements = stripe.elements(options);
    const paymentElement = elements.create('payment');
    paymentElement.mount(this.paymentElement.nativeElement);
    // paymentElement.on('change', function (event) {
    //   if (event.complete) {
    //     // enable payment button
    //   }
    // });
    //START SUBMIT PAYMENT
    this.paymentForm.nativeElement.addEventListener('submit', async (event) => {
      if (this.processingPayment == true)
        return;
      this.processingPayment = true;
      this.bookingService.reset(this.businessId)
        .then(async () => {
          event.preventDefault();
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: redirectUrl,

            },
          });
          if (error) {
            console.log(error);
            // v3:1 Unsafe attempt to initiate navigation for frame with URL 'http://localhost:4200/confirmation?payment_intent=pi_3LBFnxJNp9ZMedMi1RjrF9Ax&payment_intent_client_secret=pi_3LBFnxJNp9ZMedMi1RjrF9Ax_secret_Ptakw4l4M1ucsVHAGGLaWBo4z&redirect_status=succeeded' from frame with URL 'http://localhost:4200/checkout?businessId=981f0223-7467-4e8c-a3de-112380f8c458'. The frame attempting navigation of the top-level window is sandboxed, but the flag of 'allow-top-navigation' or 'allow-top-navigation-by-user-activation' is not set.
            this.handleError(error.message);
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
          this.processingPayment = false;
        })
        .catch((error) => {
          this.handlerError(error.message);
          this.processingPayment = false;
        });
    });
    //END SUBMIT PAYMENT
    setTimeout(() => {
      this.loadingStripe = false;
    }, 200);
  }

  handleError(message: string) {
    this.notify.notify("Payment Error", message, NotifyType.ERROR);
  }

  search() {
    this.navigate.navigate(WildNavigationType.SEARCH);
  }

}
