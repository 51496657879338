import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BusinessUserStatus } from '../../../../../data/enums/business_user_status';
import { InviteStatus } from '../../../../../data/models/invite_status';
import { ErrorModel } from '../../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { TeamService } from '../../services/team_service/team_service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  businessId: string;
  userId: string;
  inviteStatus$: Observable<InviteStatus>;
  BusinessUserStatus = BusinessUserStatus;
  loading: boolean;

  constructor(
    private teamService: TeamService,
    private notify: NotifyService,
    private auth: AuthenticationService,
    private modal: ModalService,
    private navigation: NavigationService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.businessId = params.businessId;
      this.userId = params.userId;
      this.inviteStatus$ = this.teamService.getInviteStatus(this.userId, this.businessId).pipe(
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    });
  }

  public error: ErrorModel = null;


  ngOnInit(): void {
  }

  retry() {
    this.navigation.refreshPage(WildNavigationType.INVITE);
  }

  async accept(inviteStatus: InviteStatus) {
    if (this.auth.isLoggedIn()) {
      this.loading = true;
      await this.teamService.acceptInvite(this.userId, this.businessId)
        .then(() => {
          this.next();
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
        .finally(() => {
          this.reset();
        })
    } else {
      var settings: ModalModel = {
        header: "Please login or register",
        description: "Login or create an account to accept this invitation",
        ctaonetext: "Continue",
        ctaonetextclick: () => {
          this.navigation.navigateWithReturnUrl(WildNavigationType.LOGIN);
        },
        ctatwotext: "Close",
        design: ModalDesign.CENTRAL,
        type: ModalType.WARNING
      }
      this.modal.show(settings);
    }
  }

  next() {
    this.navigation.navigate(WildNavigationType.GROUPS);
    this.notify.notify("Invite Accepted", "You have successfully accepted this invite", NotifyType.SUCCESS);
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  reset() {
    this.loading = false;
  }

}
