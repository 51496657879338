import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { BusinessService } from '../services/business_service/business_service';

@Component({
  template: 'OVERRIDE'
})
export class BusinessOverrideComponent implements OnInit {

  constructor(
    private navigation: NavigationService,
    private route: ActivatedRoute, private businessService: BusinessService,
  ) { }

  ngOnInit(): void {
    try {
      this.route.queryParams
        .subscribe(params => {
          if (params.setBusinessId != undefined && params.setBusinessId != "") {
            this.businessService.setActiveBusiness(params.setBusinessId, "Override", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fwild%20logo%20small.png?alt=media&token=5db7aaeb-d57d-455b-bb0b-966738cfde41", ['admin'], "overrideurl");
          }
          this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
        })
    } catch (error) {

    }
  }

}
