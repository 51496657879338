import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddBusinessNoteDTO } from '../../../../data/dtos/business/AddBusinessNoteDTO';
import { BusinessNoteDTO } from '../../../../data/dtos/business/BusinessNoteDTO';
import { SourceDocumentType } from '../../../../data/enums/source_document_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UtilService } from 'src/shared/services/util/util_service';
import { NotesService } from '../services/notes_service/notes_service';

@Component({
    template: `
        <app-form [form]="form" [fullwidthbutton]="false" ctaText="Submit" disableDivide="true" (onSubmit)="onSubmit($event)"
            [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
            <app-inputgroup>
                <app-textarea validation="Required field, Max of 500 characters" [form]="form" type="text" name="note" description="Note" [span]=6 [value]="note?.note ?? ''"></app-textarea> 
            </app-inputgroup>
        </app-form> 
  `
})
export class AddEditNoteComponent implements OnInit {

    @Input() note: BusinessNoteDTO;
    @Input() sourceDocumentId: string;
    @Input() sourceDocumentType: SourceDocumentType;
    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter();

    constructor(
        private fb: UntypedFormBuilder,
        private notesService: NotesService,
        private util: UtilService,
        private modalService: ModalService,
        private notifyService: NotifyService) { }

    form!: UntypedFormGroup;
    loading: boolean;

    ngOnInit(): void {
        this.form = this.fb.group({
            note: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)])
        });
    }

    onSubmit(data) {
        this.loading = true;
        var dto: AddBusinessNoteDTO = {
            note: data.note,
        }
        if (this.note == null) {
            this.notesService.add(this.sourceDocumentType, this.sourceDocumentId, dto)
                .then(async () => {
                    this.hide();
                    this.added.emit();
                })
                .catch((error) => {
                    this.handlerError(error.message);
                })
                .finally(() => {
                    this.resetForm();
                })
        } else {
            this.notesService.update(this.note.id, dto)
                .then(async () => {
                    this.hide();
                    this.edited.emit();
                })
                .catch((error) => {
                    this.handlerError(error.message);
                })
                .finally(() => {
                    this.resetForm();
                })
        }
    }

    handlerError(message: string) {
        this.notifyService.notify("Note Error", message, NotifyType.ERROR);
    }

    hide() {
        this.modalService.hide();
    }

    resetForm() {
        this.loading = false;
    }

}
