import { Component } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BusinessMode } from '../../../../../../../data/enums/business_mode';
import { BusinessPublicModel } from '../../../../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessModeService } from 'src/wild/modules/business/services/business_mode/business_mode_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';



@Component({
  selector: 'app-businessenablebookingmode',
  template: `
 
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
          <div class="m-4" *ngIf="business.mode == BusinessMode.ADVERTISE">
            <div  class="rounded-md bg-blue-50 p-4">
              <div  class="flex">
              <div class="flex-shrink-0">
                  <!-- Heroicon name: mini/information-circle -->
                  <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm text-blue-700">Start taking bookings today by switching to booking mode.</p>
                  <p class="mt-3 text-sm md:mt-0 md:ml-6">
                    <a (click)="goToBookingMode()"  class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600 cursor-pointer">
                      Enable Booking Mode
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="retry()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
        </ng-template>
    </ng-template>
 
  `,
})



export class BusinessEnableBookingModeComponent {

  business$: Observable<BusinessPublicModel>;
  business: BusinessPublicModel;
  error: ErrorModel;
  BusinessMode = BusinessMode;
  imageUrl: string;
  domain;

  constructor(
    private notify: NotifyService,
    private modalService: ModalService,
    private businessModeService: BusinessModeService,
    private businessService: BusinessService) { }

  ngOnInit(): void {
    this.domain = environment.domain;
    this.imageUrl = environment.imagesUrl;
    this.load();
  }

  load() {
    this.business$ = null;
    this.business$ = this.businessService.getPublicBusiness().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    )
  }

  goToBookingMode() {
    this.businessModeService.system()
      .then(async () => {
        this.notify.notify("Switched", "You can now manage bookings through Wild", NotifyType.SUCCESS);
        var settings: ModalModel = {
          header: "Booking Mode Enabled",
          description: "The page will now refresh and guide you through getting set up",
          design: ModalDesign.CENTRAL,
          type: ModalType.SUCCESS,
          ctaonetext: "Lets do it!",
          ctaonetextclick: () => {
            window.location.href = window.location.href;
          },
          hidectatwo: true
        }
        this.modalService.show(settings);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
      })
  }
  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }
}
