import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BusinessTODOModel } from '../../../../../../../data/dtos/business/BusinessTODOModel';
import { BusinessTODO } from '../../../../../../../data/enums/businessTODO';
import { Activity } from '../../../../../../../data/models/activity_model';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { AppointmentDetailsComponent } from 'src/wild/modules/business/modules/appointments/components/appointmentdetails.component';
import { ContactdetailsComponent } from 'src/wild/modules/business/modules/contacts/components/contactdetails/contactdetails.component';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { DashboardService } from 'src/wild/modules/business/services/dashboard_service';

@Component({
    selector: 'app-upcoming',
    template: ` 
        <ng-container *ngIf="activities$ | async as activities; else loadingOrError">
            <div *ngIf="!activities ; else loaded">
                <!-- <p class="text-gray-600 text-md mt-2">Your upcoming activities will appear here</p> -->
            </div>
            <ng-template #loaded>
            <div class="rounded-lg shadow bg-white p-4">
                <h2 class="text-sm font-medium text-gray-900 mb-3">Your Upcoming Activities</h2>
                <p class="text-xs font-light text-gray-500 mb-3" *ngIf="activities.length == 0">Check back later</p>
                <div class="bg-white  overflow-hidden">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li *ngFor="let activity of activities">
                        <a (click)="openActivity(activity)" class="cursor-pointer block hover:bg-gray-50">
                            <div class="px-4 py-4 flex items-center sm:px-6">
                            <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div class="truncate">
                                    <div class="flex text-sm">
                                        <p class="font-medium wild-text-primary truncate">{{activity.name}}<span *ngIf="activity?.usersInCart?.length > 0" class="ml-4 hidden sm:inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"> Booking in Progress </span></p>
                                        <p *ngIf="activity?.contactdetails?.length == 1" class="ml-1 flex-shrink-0 font-normal text-gray-500"> with {{activity.contactdetails[0]?.contact?.personal?.firstName}}  {{activity.contactdetails[0]?.contact?.personal?.surname}}</p>
                                        <p *ngIf="activity?.contactdetails?.length > 1" class="ml-1 flex-shrink-0 font-normal text-gray-500"> with {{activity.contactdetails?.length}} contacts</p>
                                    </div>
                                    <div class="mt-2 flex">
                                        <div class="flex items-center text-sm text-gray-500">
                                        <!-- Heroicon name: solid/calendar -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                        </svg>
                                        <p>
                                            Starts at
                                            <time datetime="2020-01-07">{{activity.startDateIso | date:'MMM d, y, h:mm'}}</time>
                                        </p>
                                        </div>
                                    </div>
                                    <div class="sm:hidden mt-3" *ngIf="activity?.usersInCart?.length > 0">
                                        <span  class=" inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"> Booking in Progress </span>
                                    </div>
                                </div>
                                <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                <div class="flex overflow-hidden -space-x-1">
                                    <img  *ngFor="let contact of activity.contactdetails | slice:0:10; let i=index" class="cursor-pointer inline-block h-6 w-6 rounded-full ring-2 ring-white" [src]="contact?.contact?.user_profile?.profilePicture != null ? imagesUrl + contact?.contact?.user_profile?.profilePicture?.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'">
                                </div>
                                </div>
                            </div>
                            <div class="ml-5 flex-shrink-0">
                                <!-- Heroicon name: solid/chevron-right -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            </div>
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader [disablescreenheight]="true"></app-loader>
            </ng-template>
        </ng-template>
  `
})
export class UpcomingComponent implements OnInit {

    public error: ErrorModel = null;
    activities$: Observable<Activity[]>;
    BusinessTODO = BusinessTODO;
    imagesUrl = environment.imagesUrl;

    constructor(
        private notifyService: NotifyService,
        private modalService: ModalService,
        private panelService: PanelService,
        private dashboardService: DashboardService,
        private navigation: NavigationService) { }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.activities$ = null;
        this.activities$ = this.dashboardService.upcoming().pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    handlerError(message: string) {
        this.notifyService.notify("Upcoming", message, NotifyType.ERROR);
    }

    openActivity(activity: Activity) {
        var settings: PanelModel = {
            header: "Activity Details",
            component: AppointmentDetailsComponent,
            componentInputs: [
                {
                    inputName: "id",
                    value: activity.id
                },
            ],
            componentOutputs: [
                {
                    outputName: "updated",
                    func: () => {
                        this.load();
                    }
                }
            ]
        }
        this.panelService.show(settings);
    }


}
