<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }" class="overflow-hidden py-24 sm:py-32 ">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div class="lg:pr-8 lg:pt-4">  
        <div class="lg:max-w-lg">
          <app-titles [left]="true" [data]="data"></app-titles>
          <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            <div *ngFor="let data of data.data;let i=index" class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                <fa-icon [class]="brochureSiteHelperService.getTextColourClassBasedOnIndex(i)" class="h-5 w-5 absolute top-1 left-1" [icon]="data.icon"></fa-icon>
               {{data.title}}
              </dt> 
              <dd class="inline">{{data.subtitle}}</dd>
            </div>
            <div *ngIf="data.ctas" class="mt-12">
              <app-ctas [left]="true" [data]="data"></app-ctas>
            </div>
          </dl>
        </div>
      </div>
      <img [src]="data.image" alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" width="2432" height="1442">
    </div>
  </div>
</div>
