import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { StepsModel } from './steps_model';
import { StepType } from './step_type';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  @Input() steps: StepsModel[];
  StepType = StepType;
  @Input() next: Observable<void>;
  @Input() previous: Observable<void>;

  private nextSubscription: Subscription;
  private previousSubscription: Subscription;

  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          var hash = new URL(document.URL).hash;
          var active = this.steps.find(step => step.type == StepType.ACTIVE);
          if (hash.replace("#", "") != active.header && active.allowGoingBack == true) {
            this.goToPrevious();
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.nextSubscription = this.next.subscribe(() => this.goToNext());
    this.previousSubscription = this.previous.subscribe(() => this.goToPrevious());
    this.setCurrentHash();
  }

  getCurrentStepNumner(): number {
    var start = 0;
    for (let i = 0; i < this.steps.length; i++) {
      const element = this.steps[i];
      start += 1;
      if (element.type == StepType.ACTIVE) {
        return start;
      }
    }
    return start;
  }

  ngOnDestroy() {
    this.nextSubscription.unsubscribe();
    this.previousSubscription.unsubscribe();
  }

  goToNext() {
    var setNextToActive = false;
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].type == StepType.ACTIVE)
        this.steps[i].type = StepType.COMPLETE;
      if (this.steps[i].type == StepType.TODO && setNextToActive == false) {
        this.steps[i].type = StepType.ACTIVE;
        setNextToActive = true;
      }
    }
    this.setCurrentHash();
  }

  goToPrevious() {
    var setFurthestAlongToComplete = false;
    var setFurthestAlongToInactive = false;
    for (var i = this.steps.length - 1; i >= 0; i--) {
      const element = this.steps[i];
      if (i == 1 && element.type == StepType.TODO) {
        setFurthestAlongToComplete = true;
        setFurthestAlongToInactive = true;
      }
      if (i == (this.steps.length - 1) && element.type == StepType.COMPLETE) {
        element.type = StepType.ACTIVE;
        setFurthestAlongToComplete = true;
        setFurthestAlongToInactive = true;
      }
      if (element.type == StepType.COMPLETE && setFurthestAlongToComplete == false) {
        element.type = StepType.ACTIVE;
        setFurthestAlongToComplete = true;
      }
      if (element.type == StepType.ACTIVE && setFurthestAlongToInactive == false) {
        element.type = StepType.TODO;
        setFurthestAlongToInactive = true;
      }
    }
    this.setCurrentHash();
  }

  setCurrentHash() {
    var active = this.steps.find(step => step.type == StepType.ACTIVE);
    // document.URL is the current url
    var url_ob = new URL(document.URL);
    url_ob.hash = '#' + active.header;
    // new url
    var new_url = url_ob.href;
    // change the current url
    document.location.href = new_url;
  }

}
