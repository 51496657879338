import { Injectable } from '@angular/core';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { SubscribeDTO } from '../../../../../data/dtos/business/configuration/SubscribeDTO';
import { SubscriptionLinksDTO } from '../../../../../data/dtos/business/configuration/SubscriptionLinksDTO';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BusinessSubscriptionService {

    constructor(
        private http: HttpService,
    ) { }

    getSubscriptionUrl(dto: SubscribeDTO): Observable<SubscriptionLinksDTO> {
        return this.http.post(`${environment.api}/business/configuration/subscribeurl`, dto);
    }
    cancel() {
        return this.http.post(`${environment.api}/business/configuration/cancelsubscription`, {}).toPromise();
    }
}
