import { Component, Input} from '@angular/core';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { FeatureColour, FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-logocloud',
  templateUrl: './logocloud.component.html'
}) 
export class LogoCloudComponent {
  constructor(private navigate:NavigationService){}
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
}
