import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { MessageType } from '../../../../data/enums/message_type';
import { MessageModel } from '../../../../data/models/message_model';
import { ErrorModel } from '../../../../data/models/error_model';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-notificationslist',
  template: `
  <div *ngIf="messages$">
      <ng-container *ngIf="messages$ | async as messages; else loadingOrError">
      <div *ngIf="!messages || messages.length == 0; else messageslist">
      <app-empty  icon="bell" header="No Notifications" description="All your updates will appear here"></app-empty>    
      </div>
      <ng-template #messageslist>
      <div class="relative grid gap-6 bg-white py-6 sm:gap-8 ">
            <a (click)="open(message)" *ngFor="let message of messages"
                class="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                <img *ngIf="message?.image" alt='img' class='rounded-sm h-10 w-10' [src]="message?.image" />
                <div *ngIf="!message?.image" class='rounded-sm h-10 w-10'>&nbsp;</div>
                <div class="ml-4">
                    <p class="text-base font-medium text-gray-900">
                        {{message.message}}
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      {{message.body}}
                    </p>
                </div>
            </a>
        </div>
  </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
          </app-retry>
      </ng-container>
      <ng-template #loading>
          <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `,
})
export class NotificationsListComponent implements OnInit {
  messages$: Observable<MessageModel[]>;
  error: ErrorModel;
  MessageType = MessageType;
  imagesUrl = environment.imagesUrl;

  constructor(
    private userService: UserService,
    private swimspotDate: SwimspotsdataService,
    private modalService: ModalService,
    private navigate: NavigationService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async open(message: MessageModel) {
    if (message.type == MessageType.SWIMSPOTPOST || message.type == MessageType.SWIMSPOTPOSTCOMMENT || message.type == MessageType.SWIMSPOTALERT || message.type == MessageType.SWIMSPOTREVIEW) {
      var settings: ModalModel = {
        header: "Opening",
        description: "Please wait",
        design: ModalDesign.LOADING
      }
      this.modalService.show(settings);
      var swimspot = await this.swimspotDate.read(message.sourceDocumentId).toPromise();
      this.navigate.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, swimspot.canonical);
      this.modalService.hide();
    } else {
      var settings: ModalModel = {
        header: "Open On The App",
        description: "Please use the Wild app to open this notification",
        type: ModalType.INFO,
        design: ModalDesign.CENTRAL
      }
      this.modalService.show(settings);
    }
  }

  load() {
    this.messages$ = null;
    this.messages$ = this.userService.getMessages().pipe(
      take(1),
      tap((messages) => this.resetNotificationCount()),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  async resetNotificationCount() {
    await this.userService.resetNotificationCount();
  }
}
