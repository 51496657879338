import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { CountryCodes } from '../../../../../../data/enums/country_codes';
import { AddressModel } from '../../../../../../data/models/address_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { AddressService } from '../../../services/address/address_service';

@Component({
  template: `  
  <app-modaltopper header="Address" parapraph="Add your address details below"></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" cancelText="Close"
                (onSubmit)="onSubmit($event)" [loading]="loading" (onCancel)="hide()">
      <app-inputgroup>
          <app-input [form]="form" type="text" name="lineone" description="Line One" [span]=6
              [value]="address?.lineone"></app-input>
          <app-input [form]="form" type="text" name="linetwo" description="Line Two" [span]=6
              [value]="address?.linetwo"></app-input>
          <app-input [form]="form" type="text" name="city" description="City" [span]=6
              [value]="address?.city"></app-input>
          <app-input [form]="form" type="text" name="county" description="County" [span]=6
              [value]="address?.county"></app-input>
          <app-input [form]="form" type="text" name="postcode" description="Postcode" [span]=6
              [value]="address?.postcode"></app-input>
          <app-select [options]="countryOptions" [form]="form" name="country" description="Country" [span]=6
              [value]="address?.country">
          </app-select>
      </app-inputgroup>
  </app-form>
  `
})
export class AddressFormComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private addressService: AddressService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  @Input() address: AddressModel;
  @Output() updated = new EventEmitter<void>();
  form!: UntypedFormGroup;
  loading: boolean;

  countryOptions: SelectModel[] = [
    {
      name: "United Kingdom",
      value: CountryCodes.GBGBR
    }
  ]


  ngOnInit(): void {
    this.form = this.fb.group({
      lineone: new UntypedFormControl('', [Validators.required]),
      linetwo: new UntypedFormControl('', []),
      city: new UntypedFormControl('', [Validators.required]),
      county: new UntypedFormControl('', [Validators.required]),
      postcode: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
    });
  }

  onSubmit(data) {
    this.loading = true;
    if (this.address == null) {
      this.addressService.add(data)
        .then(() => {
          this.next();
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
        .finally(() => {
          this.resetForm();
        })
    } else {
      this.addressService.update(this.address.id, data)
        .then(() => {
          this.next();
        })
        .catch((error) => {
          this.handlerError(error.message);
        })
        .finally(() => {
          this.resetForm();
        })
    }

  }

  handlerError(message: string) {
    this.notifyService.notify("Address", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Addresses Updated", "Perfect", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
