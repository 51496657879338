import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';
import { LinkedAccountDTO } from '../../../../data/dtos/linkedaccounts/LinkedAccountDTO';

@Injectable({
  providedIn: 'root'
})
export class LinkedAccountsService {

  constructor(
    private http: HttpService
  ) { }

  list(): Observable<LinkedAccountDTO[]> {
    return this.http.get(`${environment.api}/user/linkedaccounts`);
  }

  get(id: string): Observable<LinkedAccountDTO> {
    return this.http.get(`${environment.api}/user/linkedaccounts/${id}`);
  }

  deleteRecord(id: string) {
    return this.http.delete(`${environment.api}/user/linkedaccounts/${id}`).toPromise();;
  }

  add(dto: LinkedAccountDTO): Promise<LinkedAccountDTO> {
    return this.http.post(`${environment.api}/user/linkedaccounts/v2`, dto).toPromise();
  }

  update(id: string, dto: LinkedAccountDTO): Promise<LinkedAccountDTO> {
    return this.http.put(`${environment.api}/user/linkedaccounts/v2/${id}`, dto).toPromise();
  }

}

