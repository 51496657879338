import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { isSameDay, isSameMonth, } from 'date-fns';
import { catchError, Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { BusinessActivitiesService } from '../services/businessactivities_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { AppointmentDetailsComponent } from '../components/appointmentdetails.component';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { AppointmentEditFormComponent } from '../components/appointmenteditform.component';
import { ActivityStatus } from '../../../../../../data/enums/activity_status';
import { Activity } from '../../../../../../data/models/activity_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ConvertActivityDTO } from '../../../../../../data/dtos/business/activities/ConvertActivityDTO';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NewAppointmentFormComponent } from '../components/newappointmentform.component';
import { CalendarTeamMemberSelection } from '../components/calanedarteammemberselection.component';
import { BusinessUserModel } from '../../../../../../data/models/business_user_model';
import { BusinessService } from '../../../services/business_service/business_service';
import { environment } from 'src/environments/environment';
import { TeamService } from '../../../services/team_service/team_service';
import { BusinessActivityDetailsDTO } from '../../../../../../data/dtos/business/activities/BusinessActivityDetailsDTO';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./appointments.component.scss'],
  templateUrl: './appointments.component.html',
})
export class AppointmentsComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private teamService: TeamService,
    private notifyService: NotifyService,
    private panelService: PanelService,
    private businessService: BusinessService,
    private activityService: BusinessActivitiesService
  ) { }

  loading: boolean = true;
  events: CalendarEvent[] = [];
  teamMembers: BusinessUserModel[] = [];
  preLoadedTeamMembers: BusinessUserModel[] = [];
  services: BusinessService[] = [];
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  startDate: Date = null;
  detailedTableViewEnabled: boolean = false;
  detailedTableViewCompact: boolean = false;
  detailedactivities: BusinessActivityDetailsDTO[] = [];
  endDate: Date = null;
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh = new Subject<void>();

  activeDayIsOpen: boolean = true;

  async ngOnInit(): Promise<void> {
    this.preLoadedTeamMembers = await this.teamService.getActiveTeamMembers().toPromise();
    this.loadActivities();
  }

  toggleDetailedCompactView() {
    this.detailedTableViewCompact = !this.detailedTableViewCompact;
  }

  toggleDetailedTableView() {
    if (this.detailedTableViewEnabled == false) {
      //Set to true after data loading
      this.loadDetailedTableView();
    } else {
      this.resetDetailedTableView();
    }
  }

  resetDetailedTableView() {
    this.detailedTableViewCompact = false;
    this.detailedTableViewEnabled = false;
  }

  loadDetailedTableView() {
    this.loading = true;
    setTimeout(async () => {
      this.activityService.getActivitiesDetails(this.startDate, this.endDate, this.teamMembers).then((activities) => {
        this.loading = false;
        this.detailedactivities = activities;
        this.refresh.next();
        this.detailedTableViewEnabled = true;
      }).catch((err: any) => {
        console.log(err)
      }).finally(() => {
        this.loading = false;
      })
    }, 50)
  }



  async loadActivities() {
    //This delay is really important
    this.loading = true;
    setTimeout(async () => {
      this.activityService.getCalendarEvents(this.startDate, this.endDate, this.teamMembers, this.preLoadedTeamMembers).then((events) => {
        this.events = events;
        this.refresh.next();
      }).catch((err: any) => {
        console.log(err)
      }).finally(() => {
        this.loading = false;
      })
    }, 50)
  }

  get isToday(): boolean {
    return isSameDay(this.viewDate, new Date());
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  imagesUrl = environment.imagesUrl;

  getInitials(firstName: string, surname: string) {
    var initials = "";
    if (firstName != undefined)
      initials = firstName?.substring(0, 1) ?? "";
    if (surname != undefined)
      initials = initials + surname?.substring(0, 1) ?? "";
    return initials.toUpperCase();
  }

  chooseTeamMembers() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      component: CalendarTeamMemberSelection,
      componentInputs: [{
        inputName: "preLoadedTeamMembers",
        value: this.preLoadedTeamMembers
      }, {
        inputName: "inputTeamMembers",
        value: this.teamMembers
      }],
      componentOutputs: [{
        outputName: "outputTeamMembers",
        func: (outputTeamMembers: BusinessUserModel[]) => {
          this.teamMembers = outputTeamMembers;
          this.loadActivities();
        }
      }]
    }
    this.modalService.show(settings);
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.resetDetailedTableView();
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    if (event?.meta?.status == ActivityStatus.GROUPUNBOOKED) {
      this.convertToActivity(event.meta);
    } else {
      var settings: ModalModel = {
        design: ModalDesign.COMPONENT,
        component: AppointmentEditFormComponent,
        componentInputs: [{
          inputName: "activity",
          value: event.meta
        },
        {
          inputName: "newstart",
          value: newStart
        },
        {
          inputName: "newend",
          value: newEnd
        }],
        componentOutputs: [{
          outputName: "updated",
          func: () => {
            this.loadActivities();
          }
        }]
      }
      this.modalService.show(settings);
    }
  }

  convertToActivity(activity: Activity) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "To start editing this record, we need to generate an activity, happy to do that?",
      header: "Convert to Activity?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processConversion(activity);
      }
    }
    this.modalService.show(settings);
  }



  processConversion(activity: Activity) {
    var settings: ModalModel = {
      header: "Converting",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var dto: ConvertActivityDTO = {
      date: activity.startDateIso,
      scheduleId: activity.scheduleId,
      serviceId: activity.serviceId
    }
    this.activityService.convert(activity.id, dto)
      .then(async () => {
        this.notifyService.notify("Converted", "You can now edit this activity", NotifyType.SUCCESS);
        this.loadActivities();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }


  new() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: NewAppointmentFormComponent,
      close: true,
      componentInputs: [],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.loadActivities();
        }
      }]
    }
    this.modalService.show(settings);
  }

  eventClicked(event) {
    this.handleEvent('Click', event);
  }

  hourSegmentClicked(event) {
    var startDate = event.date;
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: NewAppointmentFormComponent,
      close: true,
      componentInputs: [{
        inputName: "newstart",
        value: startDate
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.loadActivities();
        }
      }]
    }
    this.modalService.show(settings);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action == "Click") {
      if (event?.meta?.status == ActivityStatus.GROUPUNBOOKED) {
        this.convertToActivity(event.meta);
      } else {
        this.openActivity(event);
      }
    }
    //Drag and resize handled in eventTimesChanged method
    //Edit
    //Click
    //Delete
    //Dropped or resized
    //Segment Clicked - this is most likely for adding activity - the end will be a date
    // console.log("action", action);
    // console.log("Event", event);
  }

  scrollCalDown() {
    try {
      const collection = document.getElementsByClassName("cal-time-events");
      collection[0].scroll(0, 415);
    } catch (error) {

    }
  }

  isAllowedToSchedule() {
    return this.businessService.isInRole("owner") || this.businessService.isInRole("admin")
  }

  isAllowedToSeeAllTeamMembers() {
    return this.businessService.isInRole("owner") || this.businessService.isInRole("admin")
  }

  openActivity(event: CalendarEvent) {
    var settings: PanelModel = {
      header: "Activity Details",
      component: AppointmentDetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: event.meta.id,
        }
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: () => {
            this.loadActivities();
          }
        }
      ]
    }
    this.panelService.show(settings);
  }



  openActivityFromDetailedView(activity: BusinessActivityDetailsDTO) {
    var settings: PanelModel = {
      header: "Activity Details",
      component: AppointmentDetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: activity.activity.id,
        }
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: () => {
            this.loadDetailedTableView();
          }
        }
      ]
    }
    this.panelService.show(settings);
  }


  setView(view: CalendarView) {
    this.resetDetailedTableView();
    this.view = view;
    this.loadActivities();
  }

  todayClick() {
    this.resetDetailedTableView();
    this.loadActivities();
  }

  closeOpenMonthViewDay() {
    this.resetDetailedTableView();
    this.activeDayIsOpen = false;
    this.loadActivities();
  }

  beforeViewRender(event): void {
    this.startDate = event.period.start;
    this.endDate = event.period.end;
    this.scrollCalDown();
  }
}
