import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-detailssection',
  template: `
  <section aria-labelledby="applicant-information-title">
          <div class="bg-white shadow sm:rounded-lg">
            <div *ngIf="header && description" class="px-4 py-5 sm:px-6">
              <h2 class="text-sm font-medium text-gray-900">{{header}}</h2>
              <p class="mt-1 text-sm text-gray-500">{{description}}</p>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <ng-content></ng-content>
            </div>
          </div>
        </section>
  `,
})
export class DetailsSectionComponent {
  @Input() header: string;
  @Input() description: string;
}
