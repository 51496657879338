import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SelectModel } from 'src/shared/components/select/select_model';
import { ContactSearchResultDTO } from '../../../../../../data/dtos/business/search/contact/ContactSearchResultDTO';
import { ContactSearchResultsDTO } from '../../../../../../data/dtos/business/search/contact/ContactSearchResultsDTO';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactSearchService } from '../../contacts/services/contactsearch/contactsearch_service';
import { ActivityContactsService } from '../services/activitycontacts_service';

@Component({
  template: `
  <app-modaltopper [header]="'Add Contact'"></app-modaltopper>
  <app-form [hidebuttons]="true" [form]="form" [fullwidthbutton]="false" disableDivide="true">
      <app-inputgroup>
        <app-input [disableAutoComplete]="true" [form]="form" type="search" name="search" description="Search" [span]=6 placeholder="Name, email, phone"></app-input>
      </app-inputgroup>
  </app-form> 
  <div *ngIf="contacts$">
    <ng-container *ngIf="contacts$ | async as contacts; else loadingOrError">
      <div *ngIf="loading">
        <app-loader [disablescreenheight]="true"></app-loader>
      </div>
      <div *ngIf="!loading">
        <div *ngIf="!search && (!contacts || contacts?.results?.length == 0); else contactsstable">
          <app-empty icon="users" header="No Results"
            description="Please refine your search">
          </app-empty>
        </div>
        <ng-template #contactsstable>
          <app-empty *ngIf="contacts?.results?.length == 0" icon="users" header="No Results" 
            description="Please refine your search">
          </app-empty>
          <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
            <app-listitem *ngFor="let contact of contacts.results" (onClick)="select(contact)"
                [image]="contact?.userimage"
                [firstName]="contact?.firstName" [surname]="contact?.surname"
                [subline]="contact?.email"
                >
            </app-listitem>
          </ul>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `
})
export class AppointmentAddContactComponent implements OnInit {

  @Input() id: string;
  @Output() updated = new EventEmitter();
  imagesUrl = environment.imagesUrl;

  constructor(
    private fb: UntypedFormBuilder,
    private contactSearchService: ContactSearchService,
    private activityContactService: ActivityContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  hourOptions: SelectModel[] = [];
  minuteOptions: SelectModel[] = [];
  startDate: string;
  endDate: string;
  startHour: number;
  startMinutes: number;
  contacts$: Observable<ContactSearchResultsDTO>;
  endHour: number;
  endMinutes: number;
  error: ErrorModel;

  ngOnInit(): void {
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value?.search);
      });
    }, 1000);
  }

  select(contact: ContactSearchResultDTO) {
    if (this.loading == true)
      return;
    this.loading = true;
    this.activityContactService.add(this.id, contact.id)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  search(search: string) {
    if (search == "" || search == undefined)
      return;
    this.contacts$ = null;
    this.contacts$ = this.contactSearchService.search(search, 1, 5).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  handlerError(message: string) {
    this.notifyService.notify("Update", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Added Contact", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  cancel() {
    this.modalService.hide();
  }

}
