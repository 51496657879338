import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { financetab } from "../../shared/features/financetab";
import { usecases } from "../../shared/usecases";
import { bookingExperienceListData } from "../bookingexperience/booking_experience_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { reportingListData } from "./reporting_data_listdata";

export const reportingData: FeaturesData = {
  seoTitle: "Intelligent Reporting Platform for Watersports",
  seoDescription: "Analyse your water sports business performance with Wild reporting directly from your dashboard",
  seoImage: reportingListData.image,
  seoKeyWords: "",
  page: reportingListData.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: reportingListData.title,
      subtitle: reportingListData.subtitle,
      paragraph: "Analyse finances, new customers and orders",
    },
    injectSharedComponent(financetab, { type:FeatureComponentType.BIGIMAGEFEATURE,colour:FeatureColour.WHITE  }),
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Freporting%2Freportings-mins.png?alt=media&token=c90215ff-811b-421c-86a2-b309a20e62a9",
      title: "Dashboard Reports",
      subtitle: "Analyse Order Revenue & New Customers",
      paragraph: "",
      data:[
        {
          icon: "users", title: "Customers.", subtitle: "Monitor growing business."
        },
        {
          icon: "shopping-bag", title: "Orders.", subtitle: "Number of transactions."
        },
        {
          icon: "line-chart", title: "Revenue.", subtitle: "Monitor incoming orders."
        },
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.BIGIMAGEFEATURE,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Freporting%2Freports-pointing.jpg?alt=media&token=63eac37d-e685-4ca3-b010-c3f0ee797ff7",
      title: "Visibilty",
      subtitle: "Share Reports & Colloborate",
      paragraph: "Use team member roles to share & restrict reporting access"
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "Are reports real-time?",
          subtitle: "Yes, all reporting data is generated in real time giving you up to and accurate insights throughout the day."
        },
        {
          title: "Can you lock down reports to certain team members?",
          subtitle: "Yes, reports will only be made available to administrators and owners in the business."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "Simple Reporting. That's Not All.",
      limit: 9,
      paragraph: "",
      data: [
        bookingExperienceListData,
        schedulingListDataShared,
        teamListDataShared,
        realTimeSafetyListData,
        ordersFinanceListData,
        crmListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

