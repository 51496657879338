import { Component, OnInit } from '@angular/core';
import { SearchResultType } from 'src/shared/components/searchresults/SearchResultType';
import { SelectedSearchResult } from 'src/shared/components/searchresults/selected_search_result';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-businesssearchresults',
  templateUrl: './businesssearchresults.component.html',
  styleUrls: ['./businesssearchresults.component.scss']
})
export class BusinesssearchresultsComponent implements OnInit {

  constructor(private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  resultSelected(result: SelectedSearchResult) {
    if (result.type == SearchResultType.SWIMSPOT) {
      this.navigation.navigate(WildNavigationType.BUSINESSSWIMSPOTDETAILS, {}, result.id);
    }
  }

}
