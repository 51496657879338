import { Component } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BusinessPublicModel } from '../../../../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';



@Component({
  selector: 'app-businesslink',
  template: `
  <div class="rounded-lg shadow bg-white p-4">
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
        <h2 class="text-sm font-medium text-gray-900">{{business.name}}</h2>
        <div class="flex items-center">  
            <a target="_blank" [href]="domain + '/' + business.url" class="mr-4 underline text-sm text-blue-400">{{domain}}/{{business.url}}</a>    
            <button (click)="copy(domain + '/' + business.url)" class="wild-btn-light p-2 flex items-center justify-center">
              <span class='mr-2 hidden lg:block'>copy</span>
              <fa-icon icon="copy"></fa-icon>
            </button>
          </div>
          <h2 class="text-xs font-medium text-gray-900 my-2">Share</h2>
          <app-wildsharelinks [url]="domain + '/' + business.url" [title]="business.name" [description]="business.description" [image]="business.uploadedprofilepic != null && business.uploadedprofilepic != '' ? imageUrl + business.uploadedprofilepic.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"></app-wildsharelinks>

        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="retry()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
    </div>
  `,
})



export class BusinessLinkComponent {

  business$: Observable<BusinessPublicModel>;
  business: BusinessPublicModel;
  error: ErrorModel;
  imageUrl: string;
  domain;

  constructor(
    private notify: NotifyService,
    private businessService: BusinessService) { }

  ngOnInit(): void {
    this.domain = environment.domain;
    this.imageUrl = environment.imagesUrl;
    this.load();
  }

  load() {
    this.business$ = null;
    this.business$ = this.businessService.getPublicBusiness().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    )
  }

  copy(url: string) {
    navigator.clipboard.writeText(url);
    this.notify.notify("COPIED", "URL copied to clipboard", NotifyType.SUCCESS);
  }
}
