import { Injectable } from '@angular/core';
import { FirestoreQueryType } from '../../../../../data/enums/firestore_query_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class BeaconRepositoryService {

  constructor(private firestore: FirestoreService, private auth: AuthenticationService) { }

  get(id: string) {
    return this.firestore.getCollectionGroupListByQuery("beacon", [{
      property: "id",
      operator: "==",
      type: FirestoreQueryType.WHERE,
      value: id
    }]);
  }
}

