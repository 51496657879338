import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { teamManagement } from "../../shared/features/teammanagement";
import { rolesForTeamsData } from "../../shared/features/roles_for_teams_data";
import { teamListDataShared } from "./team_data_listdata";
import { calendarComponent } from "../../shared/features/calendar";
import { bookingExperienceListData } from "../bookingexperience/booking_experience_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { usecases } from "../../shared/usecases";

export const teamData: FeaturesData = {
  seoTitle: "Team | Intelligent Booking Platform for Watersports",
  seoDescription: "Real Time Water Safety System to check people in and out of the Water quickly access emergency information",
  seoImage: teamListDataShared.image,
  seoKeyWords: "",
  page: teamListDataShared.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: teamListDataShared.title,
      subtitle: teamListDataShared.subtitle,
      paragraph: "Invite unlimited team members & restrict acess with roles",
    },
    injectSharedComponent(teamManagement, { colour: FeatureColour.WHITE }),
    injectSharedComponent(rolesForTeamsData, { colour: FeatureColour.GRAY,type:FeatureComponentType.LEFTIMAGEINFOSECTION }),
    injectSharedComponent(calendarComponent, { colour: FeatureColour.WHITE, type: FeatureComponentType.RIGHTIMAGEINFOSECTION }),
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "How many team members can i have?",
          subtitle: "Unlimited, as many as you need."
        },
        {
          title: "Do roles work on the web and the app?",
          subtitle: "Yes, your roles will apply across all devices instantly."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "Check Out More Team Features",
      limit: 9,
      paragraph: "",
      data: [
        bookingExperienceListData,
        schedulingListDataShared,
        realTimeSafetyListData,
        ordersFinanceListData,
        reportingListData,
        crmListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

