import { Component, Input} from '@angular/core';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { FeatureColour, FeaturesComponentData, FeaturesComponentListData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({ 
  selector: 'app-imagecloud',
  templateUrl: './imagecloud.component.html' 
})  
export class ImageCloudComponent{
  constructor(private navigate:NavigationService){}
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
  click(data:FeaturesComponentListData){
    if(data.page != undefined)
    this.navigate.navigate(data.page);
  }
}
 