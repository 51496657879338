<div>
  <div class="">
    <h2 class="mt-2 wild-header">{{header}}</h2>
    <p class="mt-1 wild-paragraph">
      {{description}}
    </p>
  </div>
  <form [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" class="mt-6 flex">
    <input type="search" [formControlName]="inputname" [name]="inputname" [id]="inputname" [type]="inputtype"
      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      [placeholder]="placeholder">
    <div *ngIf="form.get(inputname)?.errors && form.get(inputname)?.touched">
      <div class="font-light text-xs text-red-600 py-1" *ngIf="form.get(inputname)?.errors?.required">
        {{inputdescription}} is required
      </div>
    </div>
    <button type="submit" class="ml-4 flex-shrink-0 wild-btn-primary" style="min-width: 125px;">
      <span *ngIf="!loading">{{ctaText}}</span>
      <div *ngIf="loading" class="loader"></div>
    </button>
  </form>
</div>