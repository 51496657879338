import { Component } from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { AppPromptService } from 'src/shared/services/appprompt/appprompt_service';


@Component({
  selector: 'app-socialbar',
  template: `
<div class="flex justify-start items-center mt-4">
    <button (click)="prompt()" type="button" class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <fa-icon class="wild-text-primary" icon="thumbs-up"></fa-icon>
        <span class="hidden truncate sm:ml-2 sm:block wild-text-primary">
          Like
        </span>
    </button>
    <button (click)="prompt()" type="button" class="ml-3 relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <fa-icon icon="comment"></fa-icon>
        <span class="hidden truncate sm:ml-2 sm:block">
          Comments
        </span>
    </button>
  </div>
  `,
})
export class SocialBarComponent {


  constructor(
    private authService: AuthenticationService,
    private promptService: AppPromptService
  ) { }

  prompt() {
    if (this.authService.isAuthedIfNotShowPopUp()) {
      this.promptService.prompt();
    }
  }

}
