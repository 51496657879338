export enum BeaufortScale {
    UNKNOWN,
    CALM,
    LIGHTAIR,
    LIGHTBREEZE,
    GENTLEBREEZE,
    MODERATEBREEZE,
    FRESHBREEZE,
    STRONGBREEZE,
    NEARGALE,
    GALE,
    STRONGGALE,
    STORM,
    VIOLENTSTORM,
    HURRICANE
}