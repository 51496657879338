import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { PhoneModel } from '../../../../../../../data/models/phone_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Update Phone Number" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
          <app-select [options]="codeOptions" [form]="form" name="code" description="Code" [span]=2>
            </app-select>
          <app-input [form]="form" type="text" name="number" description="Phone number" [span]=4></app-input>
      </app-inputgroup>
  </app-form> 
  `
})
export class ContactPhoneNumberFormComponent implements OnInit {

  @Input() contact: BusinessContactModel;
  codeOptions: SelectModel[] = [
    {
      name: "+44",
      value: "+44"
    }
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      code: new UntypedFormControl(this.contact?.phone?.code ?? '+44', [Validators.required]),
      number: new UntypedFormControl(this.contact?.phone?.number ?? '', [Validators.required])
    });
  }

  onSubmit(data: PhoneModel) {
    this.loading = true;
    this.contactService.updatePhoneNumber(this.contact.id, data)
      .then(() => {
        this.next(data);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Phone Number", message, NotifyType.ERROR);
  }

  next(phone: PhoneModel) {
    this.notifyService.notify("Phone Number Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.contact.phone = phone;
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
