import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilemanagerComponent } from 'src/shared/components/filemanager/filemanager.component';
import { SourceDocumentType } from '../../../../../../data/enums/source_document_type';
import { ImageModel } from '../../../../../../data/models/images_model';
import { UserProfileModel } from '../../../../../../data/models/user_profile_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { UserService } from '../../../services/user_service';
import { ImagesService } from 'src/shared/services/images/images_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss']
})
export class UserprofileComponent implements OnInit {

  public error: ErrorModel = null;
  imageUrl = environment.imagesUrl;
  user$: Observable<UserProfileModel>;

  constructor(
    private modalService: ModalService,
    private auth: AuthenticationService,
    private imagesService: ImagesService,
    private userService: UserService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.load();
  }

  hasUsername(): boolean {
    var hasUsername = this.auth.hasUsername();
    return hasUsername;
  }

  username() {
    this.navigation.navigate(WildNavigationType.USERNAMEPAGE)
  }

  load() {
    this.user$ = null
    this.user$ = this.userService.getUserProfile().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  updateProfilePhoto() {
    var userId = this.auth.getLoggedInCookie();
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.COMPONENT,
      component: FilemanagerComponent,
      componentInputs: [{
        inputName: "sourceDocumentId",
        value: userId
      },
      {
        inputName: "sourceDocumentImageType",
        value: SourceDocumentType.USERPROFILE
      },
      {
        inputName: "limit",
        value: 1
      },
      {
        inputName: "uploadOnly",
        value: true
      }],
      componentOutputs: [
        {
          outputName: "onComplete",
          func: async ($event: any) => {
            await this.uploaded($event);
          }
        }
      ],
      close: true,
      large: true
    }
    this.modalService.show(settings);
  }

  async uploaded(images: ImageModel[]) {
    var image = images[0];
    this.auth.updateProfilePicCookie(image.url);
    await this.userService.updateProfilePicture(image);
    this.load();
  }

  waitAndReload(event, image) {
    this.imagesService.waitAndReload(event, image);
  }

  imageLoaded(event, image) {
    this.imagesService.imageLoaded(event, image);
  }

}
