import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlogPostDTO } from '../../../../../data/dtos/blog/BlogPostDTO';
import { SignedWaiverDetailsDTO } from '../../../../../data/dtos/waiver/SignedWaiverDetailsDTO';
import { WaiverForSigningDTO } from '../../../../../data/dtos/waiver/WaiverForSigningDTO';
import { HttpService } from 'src/shared/services/http/http_service';
@Injectable({
  providedIn: 'root'
})
export class UserWaiverService {

  constructor(private http: HttpService) { }

  getWaiverForSigning(businessId: string, contactId: string): Observable<WaiverForSigningDTO> {
    return this.http.get(`${environment.api}/user/waivers/${businessId}/${contactId}`, true)
  }

  async signwaiver(businessId: string, contactId: string, dto: SignedWaiverDetailsDTO) {
    await this.http.post(`${environment.api}/user/waivers/${businessId}/${contactId}`, dto, true).toPromise()
  }
}

