import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { UpdateRefundTimeRangeDTO } from '../../../../../../../data/dtos/business/configuration/UpdateRefundTimeRangeDTO';
import { UpdateTimeSlotTypeDTO } from '../../../../../../../data/dtos/business/configuration/UpdateTimeSlotTypeDTO';
import { UpdateServicePriceDTO } from '../../../../../../../data/dtos/business/services/updateServicePriceDTO';
import { BusinessTimeSlotType } from '../../../../../../../data/enums/business_timeslot_type';
import { PriceType } from '../../../../../../../data/enums/price_type';
import { BusinessModel } from '../../../../../../../data/models/business_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ConfigurationService } from 'src/wild/modules/business/services/configuration/configuration_service';

@Component({
  template: `
  <app-modaltopper header="Cancellation Rule" parapraph="Set the time clients can cancel up to"></app-modaltopper>
      <app-form [form]="form" [fullwidthbutton]="false" ctaText="Change" disableDivide="true" (onSubmit)="onSubmit($event)"
          [loading]="loading" cancelText="Cancel" (onCancel)="hide()">              
          <app-inputgroup>
            <app-select [options]="timeslotOptions" [form]="form" name="hours" description="Hours" [span]=6>
            </app-select>
          </app-inputgroup>
      </app-form> 
  `
})
export class CancellationRefundRulesComponent implements OnInit {

  @Output() updated = new EventEmitter();
  @Input() business: BusinessModel;

  constructor(
    private fb: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  timeslotOptions: SelectModel[] = [
    {
      name: "Up to 15 minutes before start time (very flexible)",
      value: 0.25
    },
    {
      name: "Up to 30 minutes before start time",
      value: 0.5
    },
    {
      name: "Up to 1 hour before start time",
      value: 1
    },
    {
      name: "Up to 2 hours before start time",
      value: 2
    },
    {
      name: "Up to 3 hours before start time",
      value: 3
    },
    {
      name: "Up to 4 hours before start time",
      value: 4
    },
    {
      name: "Up to 5 hours before start time",
      value: 5
    },
    {
      name: "Up to 6 hours before start time",
      value: 6
    },
    {
      name: "Up to 7 hours before start time",
      value: 7
    },
    {
      name: "Up to 8 hours before start time",
      value: 8
    },
    {
      name: "Up to 9 hours before start time",
      value: 9
    },
    {
      name: "Up to 10 hours before start time",
      value: 10
    },
    {
      name: "Up to 11 hours before start time",
      value: 11
    },
    {
      name: "Up to 12 hours before start time",
      value: 12
    },
    {
      name: "Up to 13 hours before start time",
      value: 13
    },
    {
      name: "Up to 14 hours before start time",
      value: 14
    },
    {
      name: "Up to 15 hours before start time",
      value: 15
    },
    {
      name: "Up to 16 hours before start time",
      value: 16
    },
    {
      name: "Up to 17 hours before start time",
      value: 17
    },
    {
      name: "Up to 18 hours before start time",
      value: 18
    },
    {
      name: "Up to 19 hours before start time",
      value: 19
    },
    {
      name: "Up to 20 hours before start time",
      value: 20
    },
    {
      name: "Up to 21 hours before start time",
      value: 21
    },
    {
      name: "Up to 22 hours before start time",
      value: 22
    },
    {
      name: "Up to 23 hours before start time",
      value: 23
    },
    {
      name: "Up to 1 day before start time",
      value: 24
    },
    {
      name: "Up to 2 days before start time",
      value: 48
    },
    {
      name: "Up to 3 days before start time",
      value: 72
    },
    {
      name: "Up to 4 days before start time",
      value: 96
    },
    {
      name: "Up to 5 days before start time",
      value: 120
    },
    {
      name: "Up to 6 days before start time",
      value: 144
    },
    {
      name: "Up to 1 week before start time",
      value: 168
    },
    {
      name: "Up to 8 days before start time",
      value: 192
    },
    {
      name: "Up to 9 days before start time",
      value: 216
    },
    {
      name: "Up to 10 days before start time",
      value: 240
    },
    {
      name: "Up to 11 days before start time",
      value: 264
    },
    {
      name: "Up to 12 days before start time",
      value: 288
    },
    {
      name: "Up to 13 days before start time",
      value: 312
    },
    {
      name: "Up to 2 weeks before start time",
      value: 336
    },
    {
      name: "Never - Customers have to contact us to cancel",
      value: 999999
    },
  ]

  ngOnInit(): void {
    this.form = this.fb.group({
      hours: new UntypedFormControl(this.business.refundTime ?? '', [Validators.required])
    });
  }

  onSubmit(data) {
    this.loading = true;
    var dto: UpdateRefundTimeRangeDTO = {
      hours: data.hours
    }
    this.configurationService.updateRefundTime(dto)
      .then(async () => {
        await this.next(data.hours);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Cancellation Rules", message, NotifyType.ERROR);
  }

  async next(hours) {
    this.business.refundTime = hours;
    this.notifyService.notify("Updated", "Cancellation Rules Configured", NotifyType.SUCCESS);
    this.updated.emit();
    this.modalService.hide();
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }


}
