import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NewActivityDTO } from '../../../../../../data/dtos/business/activities/NewActivityDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class NewActivityService {

    constructor(
        private http: HttpService
    ) { }

    async create(dto: NewActivityDTO): Promise<void> {
        return await this.http.post(`${environment.api}/business/activities`, dto).toPromise();
    }

}
