
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SearchResultType } from '../SearchResultType';
import { SelectedSearchResult } from '../selected_search_result';


@Component({
  selector: '[app-searchresults-result]',
  template: `
  <li (click)="click()" class="cursor-pointer">
    <div class="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
    <div class="h-0 aspect-w-2 aspect-h-2 sm:aspect-w-2 sm:aspect-h-2">
        <img class="object-cover shadow-lg rounded-lg" [src]="image" alt="">
    </div>
    <div class="sm:col-span-2">
        <div class="space-y-4">
        <div class="text-lg leading-6 font-medium space-y-1">
            <h3>{{header}}</h3>
            <p class="text-blue-600">{{subline}}</p>
            <p class="wild-paragraph">{{sublinetwo}}</p>
        </div>
        <div class="text-lg">
            <p class="text-gray-500">{{description}}</p>
        </div>
        <div class="">
            <button  class="wild-btn-primary">Open</button>
        </div>
        </div>
    </div>
    </div>
</li>
  `,
})
export class SearchresultsResultComponent implements OnInit {
  @Input() id: string;
  @Input() canonical: string;
  @Input() type: SearchResultType;
  @Input() header: string;
  @Input() subline: string;
  @Input() sublinetwo: string;
  @Input() image: string;
  @Output() onClick = new EventEmitter<SelectedSearchResult>();
  @Input() description: string;

  // @Input() cta:boolean = false;
  // @Output() onClick = new EventEmitter<any>();
  // @Input() colorClass:string = "";
  // @HostBinding('class') class = 'px-6 py-4 whitespace-nowrap text-sm';
  // @Input() bold:boolean = false;
  //[ngClass]="{ 'font-medium text-gray-900':bold}" 

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit({
      id: this.id,
      canonical: this.canonical,
      type: this.type
    });
  }

  // click(){
  //   this.onClick.emit();
  // }

}
