import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { BusinessContactSignedWaiversDTO } from '../../../../../../../data/dtos/waiver/BusinessContactSignedWaiversDTO';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ViewContactWaiverComponent } from './viewcontactwaiver.component';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../data/enums/navigation_type';
import { ResendWaiverComponent } from './resendwaiver.component';
import { BusinessContactWaiverStatus } from '../../../../../../../data/enums/BusinessContactWaiverStatus';

@Component({
  selector: 'app-contactwaivers',
  template: `
    <div class="flex items-center justify-end px-6 pt-2">
      <button *ngIf="waiverEnabled == true" class="wild-btn-light mb-4"
          (click)="resend()">Send Waiver</button>
        <span *ngIf="waiverEnabled != true && (isOwner() || isAdmin())" class="text-xs italic text-red-500 mb-4">Business Waivers are disabled. enable them from your <span (click)="goToSettings()" class="font-bold cursor-pointer hover:underline">business settings</span>. Once enabled, all contacts will be sent a waiver when joining or booking at your business.</span>
    </div>
    <ng-container *ngIf="waivers | async as waivers; else loadingOrError">
      <div *ngIf="!waivers || waivers.length == 0; else list">
        <app-empty icon="signature" header="No waivers Yet" ></app-empty>
      </div>
      <ng-template #list>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 px-6">
          <div (click)="open(waiver)" *ngFor="let waiver of waivers" class="relative rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm hover:bg-gray-50 cursor-pointer md:flex" >
          <div class="flex-1 min-w-0">
              <span  class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p class="text-xs text-gray-300 truncate">
                  <span>Version: {{waiver.vesrion.toString()}}</span>
                </p> 
                <p class="text-sm font-medium text-gray-900">
                  Signed on {{waiver.readableDate}}
                </p>
                <p class="text-sm text-gray-500 truncate">
                  <span>Signed By {{waiver.printedName}}</span>
                </p>
                <p *ngIf="waiver.status == BusinessContactWaiverStatus.VOIDED" class="text-xs text-red-500 truncate">
                  <span>voided</span>
                </p>
              </span>
            </div>
            <div class="mt-4 sm:mt-0">
              <img class="w-12 h-12" [src]="waiver.signatureBaseSixtyFour" alt="sig" />
            </div>
          </div>
        </div>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  `
})
export class ContactWaiversComponent implements OnInit {

  constructor(
    private businessService: BusinessService,
    private notifyService: NotifyService,
    private modalService: ModalService,
    private panelService: PanelService,
    private navigationsService: NavigationService,
    private contactsService: ContactsService) { }

  public error: ErrorModel = null;
  @Input() id: string;
  @Input() waiverEnabled: boolean;
  BusinessContactWaiverStatus = BusinessContactWaiverStatus;
  waivers: Observable<BusinessContactSignedWaiversDTO[]>;

  ngOnInit(): void {
    this.load();
  }

  goToSettings() {
    this.navigationsService.navigate(WildNavigationType.WAIVERSCONFIG);
    this.panelService.hide();
  }

  isOwner() {
    return this.businessService.isInRole("owner")
  }

  isAdmin() {
    return this.businessService.isInRole("admin")
  }

  hasAccess(): boolean {
    return this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("waivers")
  }

  load() {
    this.waivers = null;
    this.waivers = this.contactsService.getWaivers(this.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  resend() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      component: ResendWaiverComponent,
      componentInputs: [{
        inputName: "id",
        value: this.id
      }]
    }
    this.modalService.show(settings);
  }

  open(waiver: BusinessContactSignedWaiversDTO) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      large: true,
      component: ViewContactWaiverComponent,
      componentInputs: [{
        inputName: "waiver",
        value: waiver
      }]
    }
    this.modalService.show(settings);
  }

}
