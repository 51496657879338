import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImageModel } from '../../../data/models/images_model';
import { ImagesService } from 'src/shared/services/images/images_service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() images: ImageModel[];
  imagesUrl = environment.imagesUrl;

  constructor(private imagesService: ImagesService) { }

  ngOnInit(): void {
  }

  waitAndReload(event, image) {
    this.imagesService.waitAndReload(event, image);
  }

  imageLoaded(event, image) {
    this.imagesService.imageLoaded(event, image);
  }

}
