import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}