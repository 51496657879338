<div *ngIf="showAnimation">
    <app-animation width="200px" height="200px" file="confirmation" [fadeout]="true" (complete)="goToNextPage()">
    </app-animation>
</div>
<div *ngIf="!showAnimation">
    <app-form [form]="form" fullwidthbutton="true" ctaText="Next" disableDivide="true" [fullwidthbutton]="false"
        (onSubmit)="onSubmit($event)" [loading]="loading" cancelText="Previous" (onCancel)="previous()">
        <app-inputgroup>
            <app-input [form]="form" type="text" name="name" description="Name" [span]=3></app-input>
            <app-select [options]="typeOptions" [form]="form" name="type" description="Type" [span]=3></app-select>
        </app-inputgroup>
        <app-inputgroup>
            <app-textarea [form]="form" type="text" name="description" description="Description" [span]=6
                placeholder="What is the length & depth of this swim? Is it family friendly / dog friendly? Are there any safety hazards? Provide as much detail as you can.">
            </app-textarea>
        </app-inputgroup>
        <app-inputgroup>
            <app-textarea [form]="form" type="text" name="gettingthere" description="Getting There" [span]=6
                placeholder="Include information on parking or transport, distance of the walk & the terrain you need to travel across.">
            </app-textarea>
        </app-inputgroup>
        <app-inputgroup>
            <app-textarea [form]="form" type="text" name="accessibilityinformation" description="Accessibility" [span]=6
                placeholder="How easy is it to access the water, are there any safety issues or hazards?">
            </app-textarea>
        </app-inputgroup>
        <app-formtags [form]="form" name="tags" description="Tags (choose at least one)" [options]="tags">
        </app-formtags>
    </app-form>
    <div class="flex items-center justify-start w-full mt-3">
        <div>
            <span class="text-sm text-gray-400">{{lat}}, {{lng}}</span>
        </div>
    </div>
</div>