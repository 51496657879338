import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BusinessTODOModel } from '../../../../../../../data/dtos/business/BusinessTODOModel';
import { BusinessTODO } from '../../../../../../../data/enums/businessTODO';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../data/enums/navigation_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
    selector: 'app-businesstodosindividual',
    template: ` 
        <ng-container *ngIf="todos$ | async as todos; else loadingOrError">
            <div *ngIf="!todos || todos.length == 0; else todoslist"></div>
            <ng-template #todoslist>
            <div class="rounded-lg shadow bg-white p-4 mt-4">
        <h2 class="text-sm font-medium text-gray-900">Complete your Profile</h2>
                <div *ngIf="todos.length > 0">
                    <app-todowrapper>
                        <li *ngFor="let todo of todos" app-todolistitem (onClick)="action(todo.type)" [header]="todo.name"
                            [description]="todo.description"
                            [icon]="
                            todo.type == BusinessTODO.NEEDTOADDANAME ? 'user-alt' : 
                            todo.type == BusinessTODO.USERPROFILEPHOTO ? 'user-alt' : 
                            'circle'" 
                            [bgClass]="
                            todo.type == BusinessTODO.NEEDTOADDANAME ? 'bg-blue-200' : 
                            todo.type == BusinessTODO.USERPROFILEPHOTO ? 'bg-blue-200' : 
                            'bg-gray-200'" 
                            [iconClass]="
                            todo.type == BusinessTODO.NEEDTOADDANAME ? 'text-blue-800' : 
                            todo.type == BusinessTODO.USERPROFILEPHOTO ? 'text-blue-800' : 
                            'text-gray-800'" 
                             [ctatext]="
                             todo.type == BusinessTODO.NEEDTOADDANAME ? 'Update' : 
                             todo.type == BusinessTODO.USERPROFILEPHOTO ? 'Add' : 
                             null"></li>
                    </app-todowrapper>    
                    <br />            
                </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <!-- Removed loader to prevent showing every time dashboard is accessed -->
                <!-- <app-loader [disablescreenheight]="true"></app-loader> -->
            </ng-template>
        </ng-template>
  
        
  `
})
export class BusinessTODOsIndividualComponent implements OnInit {

    public error: ErrorModel = null;
    todos$: Observable<BusinessTODOModel[]>;
    BusinessTODO = BusinessTODO;
    isAppUser: boolean = false;

    constructor(
        private auth: AuthenticationService,
        private businessService: BusinessService,
        private navigation: NavigationService) { }

    ngOnInit(): void {
        this.isAppUser = this.auth.isAppUser;
        this.load();
    }

    load() {
        this.todos$ = null;
        this.todos$ = this.businessService.getTODOsIndividual().pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    action(type: BusinessTODO) {
        if (type == BusinessTODO.NEEDTOADDANAME) {
            this.navigation.navigate(WildNavigationType.BUSINESSUPDATEMYDETAILS, { onboarding: 1 }, this.auth.userId)
        } else if (type == BusinessTODO.USERPROFILEPHOTO) {
            this.navigation.navigate(WildNavigationType.BUSINESSUPDATEMYDETAILS, { onboarding: 1 }, this.auth.userId)
        }
    }

}
