import { animation } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, BMEnterFrameEvent } from 'ngx-lottie';

@Component({
  selector: 'app-animation',
  template: `<div *ngIf="options && file && file != ''" class="flex flex-col justify-center items-center">
    <div #wrapper class="transition duration-500 ease-in-out transform" [ngClass]="{'-translate-y-1 scale-0':hide}">
      <ng-lottie  
      [width]="width"
      [height]="height"
      [options]="options" 
      (animationCreated)="animationCreated($event)"
      (enterFrame)="enterFrame($event)"
      (complete)="animationComplete($event)">
    </ng-lottie>
    </div>
  </div>`
})
export class AnimationComponent implements OnInit {

  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;
  @Output() complete = new EventEmitter<any>();
  @Input() file:string;
  @Input() width:string;
  @Input() height:string;
  @Input() fadeout:boolean;
  @Input() delay:number;
  @Input() pauseAtFrame:number
  @Input() loop:boolean;

  AnimationOptions

  constructor() { }

  options:AnimationOptions;
  animationItem: AnimationItem;

  ngOnInit(): void {
    this.options = {
      loop: this.loop == null ? false : this.loop,
      autoplay:false,
      path: '/assets/animations/'+  this.file + '.json',
    };
  }

  hide: boolean = false;

  animationComplete(animationItem: AnimationItem): void {
    if(this.fadeout){
      this.hide = true;
      //This is used to force the fade out
      let el: HTMLElement = this.wrapper.nativeElement;
      el.click();
    }
    this.complete.emit();
  }
  
  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    if(this.delay){
      setTimeout(()=>{
        animationItem.play();
      },this.delay)
    }else{
      animationItem.play();
    }
  }
  enterFrame(animationItem: BMEnterFrameEvent): void {
    if(this.pauseAtFrame && this.pauseAtFrame > 0 && animationItem.currentTime > this.pauseAtFrame){
      this.animationItem.pause();
    }
  }

}
