import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserRefundOrderLineDTO } from '../../../../../../data/dtos/booking/UserRefundOrderLineDTO';
import { OrderLineStatus } from '../../../../../../data/enums/OrderLineStatus';
import { OrderStatus } from '../../../../../../data/enums/order_status';
import { BusinessPublicModel } from '../../../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UserOrdersService } from '../../../services/userorders_service';
import { UserOrderLineDetailsDTO } from '../../../../../../data/dtos/booking/UserOrderLineDetailsDTO';
import { UserOrderDetailsDTO } from '../../../../../../data/dtos/booking/UserOrderDetailsDTO';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  templateUrl: './userorders.component.html',
})
export class UserOrdersomponent implements OnInit {

  business: BusinessPublicModel;
  OrderStatus = OrderStatus;
  OrderLineStatus = OrderLineStatus;
  hideshowmore: boolean;
  orders: UserOrderDetailsDTO[] = [];
  imagesUrl = environment.imagesUrl;
  error: ErrorModel;
  isAppUser: boolean = false;


  constructor(
    private notifyService: NotifyService,
    private auth: AuthenticationService,
    private userOrdersService: UserOrdersService,
    private navigate: NavigationService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.load("");
  }


  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  loadMore() {
    this.load(this.orders[this.orders.length - 1].id);
  }

  load(startAfter: string) {
    if (startAfter == "")
      this.orders = [];
    var settings: ModalModel = {
      design: ModalDesign.LOADING,
      header: "Loading..."
    }
    this.modalService.show(settings);
    this.userOrdersService.list(startAfter).toPromise().then((orders) => {
      if (orders.orders.length == 0)
        this.hideshowmore = true;
      this.orders = this.orders.concat(orders.orders);
    }).catch((error) => {
      this.handlerError(error.message);
    }).finally(() => {
      this.modalService.hide();
    })
  }

  search() {
    this.navigate.navigate(WildNavigationType.SEARCH)
  }

  cancel(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to cancel this booking?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.procecssCancellation(order, orderLine);
      }
    }
    this.modalService.show(settings);
  }
  async procecssCancellation(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      header: "Cancelling",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    var dto: UserRefundOrderLineDTO = {
      businessId: order.businessId,
      orderId: order.id,
      orderLineId: orderLine.id
    }
    this.userOrdersService.userRefundOrderLine(dto)
      .then(async () => {
        var settings: ModalModel = {
          header: "Cancelled",
          description: "Booking has been cancelled",
          design: ModalDesign.CENTRAL,
          type: ModalType.SUCCESS,
          ctaonetext: "Close",
          hidectatwo: true,
          ctaonetextclick: () => {
            this.load("");
          },
        }
        this.modalService.show(settings);
      })
      .catch((error) => {
        var settings: ModalModel = {
          header: "Order Cancellation Unsuccessful",
          description: error.message,
          design: ModalDesign.CENTRAL,
          type: ModalType.ERROR,
          ctaonetext: "Close",
          hidectatwo: true,
          ctaonetextclick: () => {

          },
        }
        this.modalService.show(settings);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  openActivities(activityId: string) {
    this.navigate.navigate(WildNavigationType.USERACTIVITIES)
  }

  openActivity(activityId: string) {
    this.navigate.navigate(WildNavigationType.USERACTIVITYDETAILS, {}, activityId);
  }

  openCoupons() {
    this.navigate.navigate(WildNavigationType.USERVOUCHERS, {});
  }

}
