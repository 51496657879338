import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactStatus } from '../../../../../../../data/enums/contact_status';
import { Gender } from '../../../../../../../data/enums/gender_type';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { PersonalModel } from '../../../../../../../data/models/personal_model';
import { PhoneModel } from '../../../../../../../data/models/phone_model';
import { FirestoreQueryType } from '../../../../../../../data/enums/firestore_query_type';
import { FirestoreQuery } from '../../../../../../../data/models/firestore_query_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { BusinessService } from '../../../../services/business_service/business_service';
import { OrderModel } from '../../../../../../../data/models/order_model';
import { Activity } from '../../../../../../../data/models/activity_model';
import { OrderStatus } from '../../../../../../../data/enums/order_status';
import { InviteContactDTO } from '../../../../../../../data/dtos/business/contact/InviteContactDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { BusinessContactWrapperDTO } from '../../../../../../../data/dtos/contacts/BusinessContactWrapperDTO';
import { BusinessContactSignedWaiversDTO } from '../../../../../../../data/dtos/waiver/BusinessContactSignedWaiversDTO';


@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    constructor(
        private http: HttpService,
        private businessService: BusinessService,
        private firestore: FirestoreService,
        private authService: AuthenticationService
    ) { }

    getContactWrapper(contactId: string): Observable<BusinessContactWrapperDTO> {
        return this.http.get(`${environment.api}/business/contact/${contactId}`);
    }

    getWaivers(contactId: string): Observable<BusinessContactSignedWaiversDTO[]> {
        return this.http.get(`${environment.api}/business/contact/${contactId}/waivers`);
    }

    getContacts(search: string = ""): Observable<BusinessContactModel> {
        // const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        // console.log("last", lastVisible);
        var businessId = this.businessService.businessId;
        var queries: FirestoreQuery[] = [
            {
                type: FirestoreQueryType.WHERE,
                value: ContactStatus.LIVE,
                property: "status",
                operator: "=="
            },
            {
                type: FirestoreQueryType.ORDERBY,
                value: "asc",
                property: "personal.firstName",
                operator: "=="
            },
            {
                type: FirestoreQueryType.ORDERBY,
                value: "asc",
                property: "personal.surname",
                operator: "=="
            },
            {
                type: FirestoreQueryType.LIMIT,
                value: 10,
                property: "",
                operator: ""
            }]
        if (search != "") {
            queries.push({
                type: FirestoreQueryType.WHERE,
                value: search,
                property: "searchable",
                operator: "array-contains"
            })
        }
        return this.firestore.getListFromSubcollectionByQuery("business", businessId, "business_contacts", queries)
    }

    async inviteContact(dto: InviteContactDTO) {
        await this.http.post(`${environment.api}/business/contact/invitecontact`, dto).toPromise();
    }

    async sendWaiver(id: string) {
        await this.http.post(`${environment.api}/business/contact/sendwaiver/${id}`, {}).toPromise();
    }

    async voidWaiver(id: string) {
        await this.http.post(`${environment.api}/business/contact/voidwaiver/${id}`, {}).toPromise();
    }


    getAudit(contactId: string): Observable<any> {
        var businessId = this.businessService.businessId;
        return this.firestore.getListFromSubSubcollectionByQuery("business", businessId, "business_contacts", contactId, "audit", [
            {
                property: "creation_date",
                type: FirestoreQueryType.ORDERBY,
                value: "desc",
                operator: ""

            }
        ])
    }

    getOrders(contactId: string): Observable<OrderModel[]> {
        var businessId = this.businessService.businessId;
        return this.firestore.getListByQuery("orders", [
            {
                property: "contactId",
                type: FirestoreQueryType.WHERE,
                value: contactId,
                operator: "=="

            },
            {
                property: "status",
                type: FirestoreQueryType.WHERE,
                value: [OrderStatus.PAID, OrderStatus.PARTIALREFUND, OrderStatus.FULLYREFUNDED],
                operator: "in"
            },
            {
                property: "businessId",
                type: FirestoreQueryType.WHERE,
                value: businessId,
                operator: "=="
            },
            {
                property: "creation_date",
                type: FirestoreQueryType.ORDERBY,
                value: "desc",
                operator: ""
            }
        ])
    }

    getActivities(contactId: string): Observable<Activity[]> {
        var businessId = this.businessService.businessId;
        return this.firestore.getListByQuery("activities", [
            {
                property: "contacts",
                type: FirestoreQueryType.WHERE,
                value: contactId,
                operator: "array-contains"

            },
            {
                property: "businessId",
                type: FirestoreQueryType.WHERE,
                value: businessId,
                operator: "=="
            },
            {
                property: "startDate",
                type: FirestoreQueryType.ORDERBY,
                value: "desc",
                operator: ""
            }
        ])
    }

    async create(personalData: any): Promise<string> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        return await this.firestore.createSubCollectionDocument("business", businessId, "business_contacts", {
            personal: personalData,
            updatedBy: userId,
            status: ContactStatus.LIVE
        });
    }

    async updatePhoneNumber(contactId: string, data: PhoneModel): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            phone: data,
            updatedBy: userId
        });
    }

    async updateName(contactId: string, personal: PersonalModel): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            personal: personal,
            updatedBy: userId
        });
    }

    async updateEmail(contactId: string, email: string): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            personal: {
                email: email
            },
            updatedBy: userId
        });
    }

    async updateGender(contactId: string, gender: Gender): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            gender: gender,
            updatedBy: userId
        });
    }

    async updateBirthday(contactId: string, dob: Date): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            dob: dob,
            updatedBy: userId
        });
    }

    async updateEmergencyContact(contactId: string, nextOfKin: PersonalModel, nextOfKinPhone: PhoneModel): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            emergency: {
                nextOfKin: nextOfKin,
                nextOfKinPhone: nextOfKinPhone
            },
            updatedBy: userId
        });
    }

    async updateMedicalInfo(contactId: string, medicalInfo: string): Promise<void> {
        var businessId = this.businessService.businessId;
        var userId = this.authService.getLoggedInCookie();
        await this.firestore.updateSubcollection(businessId, "business", "business_contacts", contactId, {
            emergency: {
                medicalInfo: medicalInfo
            },
            updatedBy: userId
        });
    }

}
