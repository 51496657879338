import { Component, Input, OnInit } from '@angular/core';
import { AvailableMembershipsDTO } from 'src/data/dtos/services/AvailableMembershipsDTO';
import { ErrorModel } from 'src/data/models/error_model';
import { SubscribeService } from '../services/subscribe_service';
import { AuthenticationService } from '../services/auth/auth_service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommunitcateWithAppService } from '../services/communicatewithapp/communicate_with_app_service';
import { set } from 'date-fns';

@Component({
  selector: "app-subscribe",
  template: ` 
  <div *ngIf="haschecked">
        <div (click)="subscribe()" class="cursor-pointer" *ngIf="!isLoggedIn() || (isLoggedIn() && !isSubscribed)">
          <div class="w-full text-white wild-bg-primary p-4 rounded-md">
            <h1 class="text-xl sm:text-2xl font-bold">Get Wild Pro</h1>
            <p class="sm:text-md text-sm">No bookings fees, watersports stats, weather and perks for 50p / month</p>
            <button class="wild-btn-primary border-1 border-white mt-2" (click)="subscribe()">Subscribe</button>
          </div>
        </div>
        <div *ngIf="isSubscribed">
            <!-- Uncomment this for testing - but the absense of a banner means subscribed -->
            <!-- <div class="w-full text-white wild-bg-primary p-4 rounded-md">
              <h1 class="text-2xl font-bold">Wild Pro Active</h1>
            </div> -->
        </div>
    </div>
  `
})
export class SubscribeComponent implements OnInit {

  @Input() membership: AvailableMembershipsDTO;
  url:any;
  loading:boolean = false;
  error:ErrorModel = null;
  isSubscribed:boolean = false;
  haschecked:boolean = false;

  constructor(
    private authService:AuthenticationService,
    private appService:CommunitcateWithAppService,
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private subscribeService: SubscribeService) {
  }

  ngOnInit(): void {
    if(!this.authService.isAppUser){
      if(!this.justSubscribed())
        this.setIsSubscribed();
    }
  }

  justSubscribed():boolean{
    const member = this.activatedRoute.snapshot.queryParams.member;
    if(member == 1 || member == ""){
      // ! Leave the timeout... it's for a intermittent issue showing success modal in the user account area
      setTimeout(() => {
        this.subscribeService.welcomeToWildPro();
      },1000);
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: { member:"processed"},
          queryParamsHandling: 'merge'
        });
      return true;
    }
    return false;
  }

  isLoggedIn():boolean{
   return this.authService.isLoggedIn();
  }
  subscribe(){
    if (this.authService.isAuthedIfNotShowPopUp()) {
      this.subscribeService.subscribeModal();
    }
  }

  async setIsSubscribed(){
    if(this.isLoggedIn()){
      const isSubscribed = await this.subscribeService.isSubscribed();
      this.isSubscribed = isSubscribed;
    }
    this.haschecked = true;
  }
  
 

}
