import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { Gender } from '../../../../../../../data/enums/gender_type';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { NextOfKinModel } from '../../../../../../../data/models/NextOfKinModel';
import { PersonalModel } from '../../../../../../../data/models/personal_model';
import { PhoneModel } from '../../../../../../../data/models/phone_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Update Emergency Contact" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
        <app-input [form]="form" type="text" name="firstName" description="First Name" [span]=6></app-input>
      </app-inputgroup> 
      <app-inputgroup>
        <app-input [form]="form" type="text" name="surname" description="Surname" [span]=6></app-input>
      </app-inputgroup>
      <app-inputgroup>
        <app-select [options]="codeOptions" [form]="form" name="code" description="Code" [span]=2>
            </app-select>
        <app-input [form]="form" type="text" name="number" description="Phone number" [span]=4></app-input>
      </app-inputgroup>
      <app-inputgroup>
        <app-input [form]="form" type="email" name="email" description="Email" [span]=6></app-input>
      </app-inputgroup>
  </app-form> 
  `
})
export class ContactEmergencyFormComponent implements OnInit {

  @Input() contact: BusinessContactModel;

  codeOptions: SelectModel[] = [
    {
      name: "+44",
      value: "+44"
    }
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: new UntypedFormControl(this.contact?.emergency?.nextOfKin?.firstName ?? '', [Validators.required]),
      surname: new UntypedFormControl(this.contact?.emergency?.nextOfKin?.surname ?? '', [Validators.required]),
      code: new UntypedFormControl(this.contact?.emergency?.nextOfKinPhone?.code ?? '', [Validators.required]),
      number: new UntypedFormControl(this.contact?.emergency?.nextOfKinPhone?.number ?? '', [Validators.required]),
      email: new UntypedFormControl(this.contact?.emergency?.nextOfKin?.email ?? '', []),
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    var nextOfKin: NextOfKinModel = {
      firstName: data.firstName,
      surname: data.surname,
      pronouns: "",
      email: data.email
    }
    var phone: PhoneModel = {
      code: data.code,
      number: data.number
    }
    this.contactService.updateEmergencyContact(this.contact.id, nextOfKin, phone)
      .then(() => {
        this.next(nextOfKin, phone);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Birthday", message, NotifyType.ERROR);
  }

  next(nextOfKin: NextOfKinModel, phone: PhoneModel) {
    this.notifyService.notify("Birthday Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.contact.emergency.nextOfKin = nextOfKin;
    this.contact.emergency.nextOfKinPhone = phone;
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
