<app-wrapper [header]="'Manage Wild Pro Membership'">
    <ng-container *ngIf="isSubcribed$ | async as isSubcribed; else loadingOrError">
        <div *ngIf="!isSubcribed || isSubcribed?.subscribed == false; else showsubsriptionresult">
          <app-empty icon="water" header="Not Wild Pro"
            description="Looks like you're not a pro" ctatext="Go Pro" (onClick)="gopro()" [showcta]="true">
          </app-empty>
        </div>
        <ng-template #showsubsriptionresult>
          <button (click)="manage()">Manage Membership</button>
        </ng-template>
      </ng-container>
      <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
          </app-retry>
        </ng-container>
        <ng-template #loading>
          <app-loader></app-loader>
        </ng-template>
      </ng-template>
</app-wrapper>