<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }"
  class="py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div *ngIf="data.title || data.subtitle" class="mx-auto max-w-2xl lg:text-center">
      <app-titles [data]="data"></app-titles>
    </div>
    <ul role="list"
      class="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-y-16 gap-x-8 text-center sm:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-6 ">
      <li (click)="click(datarecord)" *ngFor="let datarecord of data.data;let i=index">
        <img class="mx-auto h-24 w-24 rounded-full" [src]="datarecord.image" alt="">
        <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{{datarecord.title}}</h3>
        <p class="text-sm leading-6 text-gray-600">{{datarecord.subtitle}}</p>
      </li>
    </ul>
    <div *ngIf="data.ctas" class="mt-12">
      <app-ctas [data]="data"></app-ctas>
    </div>
  </div>
</div>