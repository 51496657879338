<div *ngIf="swimspots$">
    <ng-container *ngIf="swimspots$ | async as swimspots; else loadingOrError">
        <div *ngIf="!swimspots || swimspots.length == 0; else swimspotslist">
            <app-empty icon="swimmer" header="No Swim Spots" description="Any Swim Spot you add will appear here"
                [showcta]="true" ctatext="Search" (onClick)="search()">
            </app-empty>
        </div>
        <ng-template #swimspotslist>
            <app-wrapper header="My Swim Spots">
                <app-swimspotlistitemwrapper>
                    <li *ngFor="let swimspot of swimspots" [caption]="swimspot.name" [id]="swimspot.id"
                        (onClick)="openSwimSpot(swimspot.canonical)" [src]="
                    swimspot?.image?.image == null ?
                    'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839' :
                    iamgesUrl + swimspot?.image?.image + '_640x640.jpeg?alt=media'" app-swimspotlistitem>
                    </li>
                </app-swimspotlistitemwrapper>
            </app-wrapper>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-wrapper>
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </app-wrapper>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
    <app-appdownloadlinks header="Manage your Spots on the Wild App"></app-appdownloadlinks>
</div>