import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filemanagerscrollableshell',
  templateUrl: './filemanagerscrollableshell.component.html',
  styleUrls: ['./filemanagerscrollableshell.component.scss']
})
export class FilemanagerscrollableshellComponent implements OnInit {

  @Input() ctaonetext:string = "";
  @Input() ctatwotext:string = "";
  @Output() onClickOne = new EventEmitter<any>();
  @Output() onClickTwo = new EventEmitter<any>();
  @Input() loading:boolean = false; 

  constructor() { }

  ngOnInit(): void {
  }

  clickOne(){
    this.onClickOne.emit();
  }

  clickTwo(){
    this.onClickTwo.emit();
  }

}
