<app-businessenablebookingmode *ngIf="canSeeGoLiveList()"></app-businessenablebookingmode>
<app-businesstodos *ngIf="canSeeGoLiveList()"></app-businesstodos>
<div class="flex flex-col md:flex-row p-4">
    <div class="md:w-2/4 lg:w-3/5">
        <div *ngIf="canSeeCharts()" class="hidden md:block">
            <app-businesscharts></app-businesscharts>
        </div>
        <app-upcoming></app-upcoming>
    </div>
    <div class="md:w-2/4 lg:w-2/5 md:pl-4 mt-4 md:mt-0">
        <app-businesslink></app-businesslink>
        <app-businesstodosrecommended *ngIf="canSeeGoLiveList()"></app-businesstodosrecommended>
        <app-businesstodosindividual></app-businesstodosindividual>
        <br />
    </div>
</div>