import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocationResultType } from '../../../../../data/enums/location_result_type';
import { WaterwayType } from '../../../../../data/enums/waterway_type';
import { ErrorModel } from '../../../../../data/models/error_model';
import { SearchResult } from '../../../../../data/models/SearchResult';
import { SearchResults } from '../../../../../data/models/SearchResults';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { userSearchService } from 'src/wild/services/search/search_service';

export interface Filter {
  name: string,
  expanded: boolean,
  expandedBasedOnTheseParamsBeingSet: string[],
  hideIfFiltersEnabled: string[],
  showIfFiltersEnabled: string[],
  options: FilterOption[]
}

export interface FilterOption {
  id: string,
  name: string,
  enabled: boolean
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchResults: SearchResults;
  searchResults$: Observable<SearchResults>;
  showSearch: boolean;
  searchLocation: string;
  WaterwayType = WaterwayType;
  error: ErrorModel;
  LocationResultType = LocationResultType;
  params: Params;
  imagesUrl = environment.imagesUrl;
  searchHasLoadedAfterFirstPageLoad = false;
  showFilters: boolean = false;
  showSortOptions: boolean = false;
  pageJustLoaded: boolean = true;

  filters: Filter[] = []

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private notify: NotifyService,
    private seo: SeoService,
    private searchService: userSearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (this.searchHasLoadedAfterFirstPageLoad)
          this.load();
      }
    });
  }

  form;

  ngOnInit(): void {
    this.seo.update("Wild | Search", "Discover thousands of spots and open water businesss.", "swimming, open water, lake, river, beach", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fwildlogo.png?alt=media&token=87b004dd-dd18-4815-a240-0f049dfc787b");
    this.form = this._formBuilder.group({
      search: new UntypedFormControl('', []),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 1000);
    this.load();
    this.searchHasLoadedAfterFirstPageLoad = true;
  }

  toggleFilter(option: FilterOption) {
    option.enabled = !option.enabled;
    var newParams = {
      ...this.params,
    }
    if (option.enabled == true) {
      newParams[option.id] = 1;
    } else {
      try {
        //IMPORTANT TO DELETE INSTEAD OF SET TO ZERO. THIS ALLOWS FOR CONDITIONAL FILTERS BASED ON URL PARAMS
        delete newParams[option.id];
      } catch (error) {

      }
    }
    this.navigation.navigate(WildNavigationType.SEARCH, newParams)
  }

  search(value: any) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.searchResults = null;
      this.showSearch = false;
      return;
    }
    this.searchService.global(value.search).pipe(take(1)).toPromise()
      .then((searchResults) => {
        this.searchResults = searchResults;
        this.showSearch = true;
      })
      .catch((error) => {
        this.handlerError(error);
      })
      .finally(() => {
        this.searchFinished();
      })
  }

  handlerError(error: ErrorModel) {
    this.notify.notify("Search Error", error.message, NotifyType.ERROR);
  }

  searchFinished() { }

  chooseResult(result: SearchResult) {
    this.searchResults = null;
    this.showSearch = false;
    if (result.type == LocationResultType.PLACE) {
      this.navigation.navigate(WildNavigationType.SEARCH, {
        query: result.name,
        latitude: result.latitude,
        longitude: result.longitude
      })
    } else if (result.type == LocationResultType.SWIMSPOT) {
      this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, result.canonical)
    } else if (result.type == LocationResultType.BUSINESS) {
      this.router.navigate([result.canonical]);
    }
  }

  setTitleOfSearchResults(searchResult: SearchResults) {
    this.searchLocation = searchResult.location;
  }

  load() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.params = params;
      this.setFilters();
      //IMPORTANT THAT pageJustLoaded IS AFTER THE setFilters function as it's used in there
      this.pageJustLoaded = false;
      var queryString = this.getQueryStringFromFilters();
      this.error = null;
      this.searchResults$ = this.searchService.nearby(params.query ?? "", params?.latitude ?? "", params?.longitude ?? "", queryString).pipe(
        take(1),
        tap((searchResult) => this.setTitleOfSearchResults(searchResult)),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    });
  }

  expandFiltersBasedOnWhatIsSet(filter: Filter): boolean {
    var expanded = false;
    for (let i = 0; i < filter.expandedBasedOnTheseParamsBeingSet.length; i++) {
      const filterCondition = filter.expandedBasedOnTheseParamsBeingSet[i];
      if (this.params[filterCondition] == 1)
        return true;
    }
    return expanded;
  }

  showFilter(filter: Filter): boolean {
    //Check show params - if this is set just return
    for (let i = 0; i < filter.showIfFiltersEnabled.length; i++) {
      const filterCondition = filter.showIfFiltersEnabled[i];
      if (this.params[filterCondition] == 1)
        return true;
    }
    //Then check if it should be hidden based on what the user has selected
    var show = true;
    for (let i = 0; i < filter.hideIfFiltersEnabled.length; i++) {
      const filterCondition = filter.hideIfFiltersEnabled[i];
      if (this.params[filterCondition] == 1)
        show = false;
    }
    return show;
  }

  setFilters() {
    //Show takes priority over hide
    var filters: Filter[] = [
      {
        name: "What you looking for?",
        expanded: true,
        expandedBasedOnTheseParamsBeingSet: [],
        hideIfFiltersEnabled: [],
        showIfFiltersEnabled: [],
        options: [
          {
            id: "business",
            name: "Businesses",
            enabled: this.params?.business == 1 ? true : false
          },
          {
            id: "spots",
            name: "Swim Spots",
            enabled: this.params?.spots == 1 ? true : false
          },
        ]
      },
      //SWIMSPOT FILTERS START
      {
        name: "Waterway Type",
        expanded: true,
        expandedBasedOnTheseParamsBeingSet: [],
        hideIfFiltersEnabled: [],
        showIfFiltersEnabled: ["spots", "business"],
        options: [
          {
            id: "lakes",
            name: "Lake",
            enabled: this.params?.lakes == 1 ? true : false
          },
          {
            id: "rivers",
            name: "Rivers",
            enabled: this.params?.rivers == 1 ? true : false
          },
          {
            id: "coastal",
            name: "Coastal",
            enabled: this.params?.coastal == 1 ? true : false
          }
        ]
      },
      {
        name: "Activity",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["longswim", "paddling", "skinnydip", "supping", "swimming", "waterskiing",
          "wakeboarding",
          "kneeboarding",
          "surfing",
          "bodyboarding",
          "bodysurfing",
          "canoeing",
          "kayaking",
          "windsurfing",
          "kiteboarding",
          "sailing",
          "skimboarding",
          "scubadiving",
          "snorkeling",
          "whitewaterrafting",
          "freediving",
          "canyoning",
          "hydrofoil",
          "yachting",
          "flyboarding",
          "rowing",
          "icediving",
          "triathlon",
          "swimrun",
          "walking",
          "iceswimming",
          "coasteering"],
        hideIfFiltersEnabled: [],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "swimming",
            name: "Swimming",
            enabled: this.params?.swimming == 1 ? true : false
          },
          {
            id: "longswim",
            name: "Long Swim",
            enabled: this.params?.longswim == 1 ? true : false
          },
          {
            id: "paddling",
            name: "Paddling / Dipping",
            enabled: this.params?.paddling == 1 ? true : false
          },
          {
            id: "skinnydip",
            name: "Skinny Dip",
            enabled: this.params?.skinnydip == 1 ? true : false
          },
          {
            id: "supping",
            name: "SUP",
            enabled: this.params?.supping == 1 ? true : false
          },
          {
            id: "surfing",
            name: "Surfing",
            enabled: this.params?.surfing == 1 ? true : false
          },
          {
            id: "bodysurfing",
            name: "Body Surfing",
            enabled: this.params?.bodysurfing == 1 ? true : false
          },
          {
            id: "bodyboarding",
            name: "Bodyboarding",
            enabled: this.params?.bodyboarding == 1 ? true : false
          },
          {
            id: "canoeing",
            name: "Canoeing",
            enabled: this.params?.canoeing == 1 ? true : false
          },
          {
            id: "kayaking",
            name: "Kayaking",
            enabled: this.params?.kayaking == 1 ? true : false
          },
          {
            id: "windsurfing",
            name: "Windsurfing",
            enabled: this.params?.windsurfing == 1 ? true : false
          },
          {
            id: "kiteboarding",
            name: "Kiteboarding",
            enabled: this.params?.kiteboarding == 1 ? true : false
          },
          {
            id: "sailing",
            name: "Sailing",
            enabled: this.params?.sailing == 1 ? true : false
          },
          {
            id: "skimboarding",
            name: "Skimboarding",
            enabled: this.params?.skimboarding == 1 ? true : false
          },
          {
            id: "scubadiving",
            name: "Scuba Diving",
            enabled: this.params?.scubadiving == 1 ? true : false
          },
          {
            id: "snorkeling",
            name: "Snorkeling",
            enabled: this.params?.snorkeling == 1 ? true : false
          },
          {
            id: "whitewaterrafting",
            name: "White Water Rafting",
            enabled: this.params?.whitewaterrafting == 1 ? true : false
          },
          {
            id: "freediving",
            name: "Free Diving",
            enabled: this.params?.freediving == 1 ? true : false
          },
          {
            id: "canyoning",
            name: "Canyoning",
            enabled: this.params?.canyoning == 1 ? true : false
          },
          {
            id: "hydrofoil",
            name: "Hydrofoil",
            enabled: this.params?.hydrofoil == 1 ? true : false
          },
          {
            id: "yachting",
            name: "Yachting",
            enabled: this.params?.yachting == 1 ? true : false
          },
          {
            id: "waterskiing",
            name: "Waterskiing",
            enabled: this.params?.waterskiing == 1 ? true : false
          },
          {
            id: "wakeboarding",
            name: "Wake Boarding",
            enabled: this.params?.wakeboarding == 1 ? true : false
          },
          {
            id: "kneeboarding",
            name: "Knee Boarding",
            enabled: this.params?.kneeboarding == 1 ? true : false
          },
          {
            id: "flyboarding",
            name: "Fly Boarding",
            enabled: this.params?.flyboarding == 1 ? true : false
          },
          {
            id: "rowing",
            name: "Rowing",
            enabled: this.params?.rowing == 1 ? true : false
          },
          {
            id: "icediving",
            name: "Ice Diving",
            enabled: this.params?.icediving == 1 ? true : false
          },
          {
            id: "triathlon",
            name: "Triathlon",
            enabled: this.params?.triathlon == 1 ? true : false
          },
          {
            id: "swimrun",
            name: "Swimrun",
            enabled: this.params?.swimrun == 1 ? true : false
          },
          {
            id: "iceswimming",
            name: "Ice Swimming",
            enabled: this.params?.iceswimming == 1 ? true : false
          },
          {
            id: "coasteering",
            name: "Coasteering",
            enabled: this.params?.coasteering == 1 ? true : false
          },
          {
            id: "walking",
            name: "Walking",
            enabled: this.params?.walking == 1 ? true : false
          },
          {
            id: "cycling",
            name: "Cycling",
            enabled: this.params?.cycling == 1 ? true : false
          },
        ]
      },
      //BUSINESS FILTERS START
      {
        name: "Services",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["onetwoonecoaching", "groupcoaching", "pooltoopenwater", "beginner", "adults", "children"],
        hideIfFiltersEnabled: ["spots"],
        showIfFiltersEnabled: ["business"],
        options: [
          {
            id: "onetwoonecoaching",
            name: "121 Coaching",
            enabled: this.params?.onetwoonecoaching == 1 ? true : false
          },
          {
            id: "groupcoaching",
            name: "Group Coaching",
            enabled: this.params?.groupcoaching == 1 ? true : false
          },
          {
            id: "pooltoopenwater",
            name: "Pool to Open Water",
            enabled: this.params?.pooltoopenwater == 1 ? true : false
          },
          {
            id: "beginner",
            name: "Beginner Lessons",
            enabled: this.params?.beginner == 1 ? true : false
          },
          {
            id: "adults",
            name: "Adult Lessons",
            enabled: this.params?.adults == 1 ? true : false
          },
          {
            id: "children",
            name: "Child Lessons",
            enabled: this.params?.children == 1 ? true : false
          },
        ]
      },
      //BUSINESS FILTERS END
      {
        name: "Venue Type",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["paytoswim", "lido", "pool"],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "paytoswim",
            name: "Pay to Swim",
            enabled: this.params?.paytoswim == 1 ? true : false
          },
          {
            id: "lido",
            name: "Lido",
            enabled: this.params?.lido == 1 ? true : false
          },
          {
            id: "pool",
            name: "Pool",
            enabled: this.params?.lido == 1 ? true : false
          },
        ]
      },
      {
        name: "Transportation",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["nearbyparking", "trainaccess", "cycling"],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "nearbyparking",
            name: "Nearby Parking",
            enabled: this.params?.nearbyparking == 1 ? true : false
          },
          {
            id: "trainaccess",
            name: "Train Access",
            enabled: this.params?.trainaccess == 1 ? true : false
          },
        ]
      },
      {
        name: "Scenery",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["mountainviews", "hasabeach", "pebbles", "cliffs", "bridges", "estuary", "weir", 'quarry'],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "mountainviews",
            name: "Mountain Views",
            enabled: this.params?.mountainviews == 1 ? true : false
          },
          {
            id: "hasabeach",
            name: "Beach",
            enabled: this.params?.hasabeach == 1 ? true : false
          },
          {
            id: "pebbles",
            name: "Pebble Beach",
            enabled: this.params?.pebbles == 1 ? true : false
          },
          {
            id: "cliffs",
            name: "Cliffs",
            enabled: this.params?.cliffs == 1 ? true : false
          },
          {
            id: "bridges",
            name: "Bridges",
            enabled: this.params?.bridges == 1 ? true : false
          },
          {
            id: "estuary",
            name: "Estuary",
            enabled: this.params?.estuary == 1 ? true : false
          },
          {
            id: "weir",
            name: "Weir",
            enabled: this.params?.weir == 1 ? true : false
          },
          {
            id: "quarry",
            name: "Quarry",
            enabled: this.params?.quarry == 1 ? true : false
          },
        ]
      },
      {
        name: "Experience",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["waterfalls", "jumping", "chutes", "pools", "swing", "caves", "sunrise", "sunset", "quietlocation", 'picnic', 'pubs'],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "waterfalls",
            name: "Waterfalls",
            enabled: this.params?.waterfalls == 1 ? true : false
          },
          {
            id: "jumping",
            name: "Jumping",
            enabled: this.params?.jumping == 1 ? true : false
          },
          {
            id: "chutes",
            name: "Chutes & Flumes",
            enabled: this.params?.chutes == 1 ? true : false
          },
          {
            id: "pools",
            name: "Pools",
            enabled: this.params?.pools == 1 ? true : false
          },
          {
            id: "swing",
            name: "Rope Swing",
            enabled: this.params?.swing == 1 ? true : false
          },
          {
            id: "caves",
            name: "Caves",
            enabled: this.params?.caves == 1 ? true : false
          },
          {
            id: "sunrise",
            name: "Sunrise",
            enabled: this.params?.sunrise == 1 ? true : false
          },
          {
            id: "sunset",
            name: "Sunset",
            enabled: this.params?.sunset == 1 ? true : false
          },
          {
            id: "quietlocation",
            name: "Quiet Location",
            enabled: this.params?.quietlocation == 1 ? true : false
          },
          {
            id: "picnic",
            name: "Picnic",
            enabled: this.params?.picnic == 1 ? true : false
          },
          {
            id: "pubs",
            name: "Near Pubs",
            enabled: this.params?.pubs == 1 ? true : false
          },
        ]
      },
      {
        name: "Access",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["privateproperty"],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "privateproperty",
            name: "Private Property",
            enabled: this.params?.privateproperty == 1 ? true : false
          },
        ]
      },
      {
        name: "With Company",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["dogs", "suitableforkids"],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "dogs",
            name: "Dog Friendly",
            enabled: this.params?.dogs == 1 ? true : false
          },
          {
            id: "suitableforkids",
            name: "Child Friendly",
            enabled: this.params?.suitableforkids == 1 ? true : false
          },
        ]
      },
      {
        name: "Accessibility",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["accessibility", "excludeboating", "excludebigdropoff", "easytowalkto", "excludeitismuddy", "excludelotsofreeds", "excluderockyground", "stepstogetin"],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "accessibility",
            name: "Marked as accessible",
            enabled: this.params?.accessibility == 1 ? true : false
          },
          {
            id: "excludeboating",
            name: "Exclude areas with boats",
            enabled: this.params?.excludeboating == 1 ? true : false
          },
          {
            id: "excludebigdropoff",
            name: "Exclude big drop offs",
            enabled: this.params?.excludebigdropoff == 1 ? true : false
          },
          {
            id: "easytowalkto",
            name: "Easy to walk to",
            enabled: this.params?.easytowalkto == 1 ? true : false
          },
          {
            id: "excludeitismuddy",
            name: "Exclude muddy",
            enabled: this.params?.excludeitismuddy == 1 ? true : false
          },
          {
            id: "excludelotsofreeds",
            name: "Exclude reedy areas",
            enabled: this.params?.excludelotsofreeds == 1 ? true : false
          },
          {
            id: "excluderockyground",
            name: "Exclude rocky areas",
            enabled: this.params?.excluderockyground == 1 ? true : false
          },
          {
            id: "stepstogetin",
            name: "Steps to get in",
            enabled: this.params?.stepstogetin == 1 ? true : false
          },
        ]
      },
      {
        name: "Facilities",
        expanded: false,
        expandedBasedOnTheseParamsBeingSet: ["camping", "toilets"],
        hideIfFiltersEnabled: ["business"],
        showIfFiltersEnabled: ["spots"],
        options: [
          {
            id: "camping",
            name: "Camping",
            enabled: this.params?.camping == 1 ? true : false
          },
          {
            id: "toilets",
            name: "Toilets",
            enabled: this.params?.toilets == 1 ? true : false
          },

        ]
      },
      //SWIMSPOT FILTERS END

    ];
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.expandedBasedOnTheseParamsBeingSet.length > 0) {
        filter.expanded = this.expandFiltersBasedOnWhatIsSet(filter);
      }
    }
    this.filters = filters;
  }

  getQueryStringFromFilters(): string {
    var queryString = "";
    for (let i = 0; i < this.filters.length; i++) {
      const filter = this.filters[i];
      for (let y = 0; y < filter.options.length; y++) {
        const option = filter.options[y];
        var value = option.enabled ? 1 : 0;
        var query = `&${option.id}=${value}`;
        queryString += query;
      }
    }
    return queryString;
  }

}
