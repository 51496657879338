import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { bookingOnDemand } from "../../shared/features/bookingondemand";
import { bookingRecurring } from "../../shared/features/bookingrecurring";
import { checkout } from "../../shared/features/checkout";
import { bookingExperienceListData } from "./booking_experience_data_listdata";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { usecases } from "../../shared/usecases";

export const bookingExperienceData: FeaturesData = {
  seoTitle: "Excellent Booking Experience for Watersports",
  seoDescription: "Seamless Booking Experience on app & web for your water sports customers. Available on any device and compatible with all credit cards, Apple Pay & Google Pay",
  seoImage: bookingExperienceListData.image,
  seoKeyWords: "",
  page: bookingExperienceListData.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: bookingExperienceListData.title,
      subtitle: bookingExperienceListData.subtitle,
      paragraph: "Easier for your customers. More sales for you.",
    },
    injectSharedComponent(bookingOnDemand, { colour: FeatureColour.WHITE }),
    injectSharedComponent(bookingRecurring, { colour: FeatureColour.GRAY }),
    injectSharedComponent(checkout, { colour: FeatureColour.WHITE }),
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "Can parents book for kids?",
          subtitle: "Yes, parents, friends and families can book for anyone. We call these 'linked accounts'. You will be able to easily see who is linked to who from your dashboard."
        },
        {
          title: "Are group bookings supported?",
          subtitle: "Yes, parents, friends and families can book for each other."
        },
        {
          title: "What devices does it work on?",
          subtitle: "Wild runs on the web and we have an app for iOS (Apple) & Android."
        },
        {
          title: "Are group bookings supported?",
          subtitle: "Yes, parents, friends and families can book for each other."
        },
        {
          title: "Can I integrate it into my website?",
          subtitle: "Yes, you can integrate Wild into your website using an iFrame or a widget."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "Easy Bookings & So Much More",
      limit: 9,
      paragraph: "",
      data: [
        schedulingListDataShared,
        teamListDataShared,
        ordersFinanceListData,
        reportingListData,
        crmListData,
        realTimeSafetyListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

