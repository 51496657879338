import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const calendarComponent: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fscheduling%2Fcalendar.png?alt=media&token=75933245-a861-4e0a-a899-8ad565a1b8f5",
  title: "Calendar",
  subtitle: "Drag. Drop. Done.",
  paragraph: "Access your team appointments on the Wild App & Website",
  data: [
    {
      icon: "calendar", title: "Click to Schedule", subtitle: "One click scheduling."
    },
    {
      icon: "mouse-pointer", title: "Drag & Drop.", subtitle: "Drag to reschedule."
    },
    {
      icon: "mobile", title: "App.", subtitle: "Access bookings on the app."
    },
    {
      icon: "users", title: "Team Filters.", subtitle: "Cut through the noise."
    },
    {
      icon: "infinity", title: "Recurring.", subtitle: "Create recurring events."
    },
    {
      icon: "edit", title: "Notes.", subtitle: "Record audited notes."
    },
  ]
}