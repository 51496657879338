import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cardlistnew',
  templateUrl: './cardlistnew.component.html',
  styleUrls: ['./cardlistnew.component.scss']
})
export class CardlistnewComponent implements OnInit {

  @Input() icon: string;
  @Input() text: string;
  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit()
  }

}
