import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { BookingService } from 'src/booking/services/booking_service';
import { ServicesPublicService } from 'src/booking/services/services_public_service';
import { environment } from 'src/environments/environment';
import { ServiceAndPricesDTO } from '../../../../data/dtos/services/ServiceAndPricesDTO';
import { BusinessServiceLocationType } from '../../../../data/enums/business_service_location_type';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { BusinessPublicModel } from '../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../data/models/error_model';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  template: `
    <app-wild-header [hidebusinesslinks]="true"></app-wild-header>
    <div class="max-w-2xl mx-auto pt-8 sm:pt-12  px-4  sm:px-6 lg:max-w-7xl lg:px-8">
        <nav class="flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex items-center">
                <a (click)="goToBusiness()" class="text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">{{business.name}}</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <!-- Heroicon name: mini/chevron-right -->
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-not-allowed" aria-current="page">{{service?.name ?? ""}}</a>
              </div>
            </li>
          </ol>
        </nav>
    </div>
    <ng-container *ngIf="service$ | async as service; else loadingOrError">
    <div *ngIf="!service; else serviceLoaded">
        service not found
    </div>
    <ng-template #serviceLoaded>
      <div class="bg-white">
        <div class="max-w-2xl mx-auto pb-8 sm:pb-12 pt-8 sm:pt-12  px-4  sm:px-6 lg:max-w-7xl lg:px-8">
      
        <div class="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            <!-- Image gallery -->
            <div class="flex flex-col-reverse">
              <!-- Image selector -->
              <div class="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                <div class="grid grid-cols-4 gap-6" aria-orientation="horizontal" role="tablist">
                  <button id="tabs-1-tab-1" class="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50" aria-controls="tabs-1-panel-1" role="tab" type="button">
                    <span class="sr-only">
                      Angled view
                    </span>
                    <span class="absolute inset-0 rounded-md overflow-hidden">
                      <img [src]="service.image != null ? imagesUrl + service.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"  alt="" class="w-full h-full object-center object-cover">
                    </span>
                    <!-- Selected: "ring-indigo-500", Not Selected: "ring-transparent" -->
                    <span class="ring-transparent absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none" aria-hidden="true"></span>
                  </button>

                  <!-- More images... -->
                </div>
              </div>

              <div class="w-full aspect-w-1 aspect-h-1">
                <!-- Tab panel, show/hide based on tab state. -->
                <div id="tabs-1-panel-1" aria-labelledby="tabs-1-tab-1" role="tabpanel" tabindex="0">
                  <img [src]="service.image != null ? imagesUrl + service.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'" alt="Angled front view with bag zipped and handles upright." class="w-full h-full object-center object-cover sm:rounded-lg">
                </div>

                <!-- More images... -->
              </div>
            </div>

            <!-- Product info -->
            <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">{{service.name}}</h1>
              <h1 class="text-sm font-light tracking-tight text-gray-900 ml-1">with {{business.name}}</h1>

              <div class="mt-3">
                <p class="text-3xl text-gray-900 block md:inline-block md:mr-1">{{service.formattedPrice}}</p>
                <p class="text-sm sm:text-md leading-none text-gray-800 block md:inline-block">per person</p>
              </div>

              <div class="mt-3">
                <p class="text-1xl wild-text-primary">{{service.duration}}</p>
              </div>

              <div class="mt-6">
                <h3 class="sr-only">Description</h3>

                <div class="text-base text-gray-700 space-y-6">
                  <p>{{service.description}}</p>
                </div>
              </div>

              <form class="mt-6">
                

                <div class="mt-10 flex sm:flex-col1">
                  <button (click)="book(service)" type="submit" class="max-w-xs flex-1 wild-bg-primary border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-900 sm:w-full">Book</button>
                </div>
              </form>

              <section aria-labelledby="details-heading" class="mt-12">
                <h2 id="details-heading" class="sr-only">Additional details</h2>

                <div class="border-t divide-y divide-gray-200">
                  <div *ngIf="service.itinerary" >
                    <h3 (click)="itineraryOpen = !itineraryOpen">
                      <!-- Expand/collapse question button -->
                      <button type="button" class="group relative w-full py-6 flex justify-between items-center text-left" aria-controls="disclosure-1" aria-expanded="false">
                        <!-- Open: "text-indigo-600", Closed: "text-gray-900" -->
                        <span class="text-gray-900 text-sm font-medium">
                          Itinerary
                        </span>
                        <span class="ml-6 flex items-center">
                          <svg [ngClass]="{'hidden': itineraryOpen,'block': !itineraryOpen}" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <svg [ngClass]="{'hidden': !itineraryOpen,'block': itineraryOpen}" class="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div [ngClass]="{'hidden': !itineraryOpen,'block': itineraryOpen}" class="pb-6 prose prose-sm" id="disclosure-1">
                      <p converttoclickablelinks>{{service.itinerary}}</p>
                    </div>
                  </div>
                  <div *ngIf="service.swimspot"  >
                    <h3 (click)="swimspotOpen = !swimspotOpen">
                      <!-- Expand/collapse question button -->
                      <button type="button" class="group relative w-full py-6 flex justify-between items-center text-left" aria-controls="disclosure-1" aria-expanded="false">
                        <!-- Open: "text-indigo-600", Closed: "text-gray-900" -->
                        <span class="text-gray-900 text-sm font-medium">
                          Swim Spot
                        </span>
                        <span class="ml-6 flex items-center">
                          <svg [ngClass]="{'hidden': swimspotOpen,'block': !swimspotOpen}" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <svg [ngClass]="{'hidden': !swimspotOpen,'block': swimspotOpen}" class="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div [ngClass]="{'hidden': !swimspotOpen,'block': swimspotOpen}" class="pb-6 prose prose-sm" id="disclosure-1">
                      <app-readmoreswimspot *ngIf="service.swimspot" [swimSpotId]="service.swimspot"></app-readmoreswimspot>
                    </div>
                  </div>
                  <div *ngIf="service.location && service?.location?.type == BusinessServiceLocationType.PHYSICAL">
                    <h3 (click)="meetingPointOpen = !meetingPointOpen">
                      <!-- Expand/collapse question button -->
                      <button type="button" class="group relative w-full py-6 flex justify-between items-center text-left" aria-controls="disclosure-1" aria-expanded="false">
                        <!-- Open: "text-indigo-600", Closed: "text-gray-900" -->
                        <span class="text-gray-900 text-sm font-medium">
                          Meeting Point
                        </span>
                        <span class="ml-6 flex items-center">
                          <svg  [ngClass]="{'hidden': meetingPointOpen,'block': !meetingPointOpen}" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <svg [ngClass]="{'hidden': !meetingPointOpen,'block': meetingPointOpen}" class="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div [ngClass]="{'hidden': !meetingPointOpen,'block': meetingPointOpen}" class="pb-6 prose prose-sm" id="disclosure-1">
                        <p class="mt-1 pb-6 prose prose-sm">
                          {{service?.location?.name}} - {{service?.location?.description}}
                        </p>
                        <div *ngIf="(mapsLoaded | async) && service?.location?.type == BusinessServiceLocationType.PHYSICAL" class="w-full shadow-lg rounded-lg bg-blue-500 overflow-hidden mt-3"
                        style="height:300px">
                        <google-map (mapClick)="updateMarker($event)" height="100%" width="100%" [options]="options">
                          <map-marker (mapDragend)="updateMarker($event)" *ngFor="let markerPosition of markerPositions"
                              [position]="markerPosition" [options]="markerOptions"></map-marker>
                        </google-map>
                        </div>
                    </div>
                  </div>
                  <div *ngIf="service.whatToBring" >
                    <h3 (click)="whatToBringOpen = !whatToBringOpen">
                      <!-- Expand/collapse question button -->
                      <button type="button" class="group relative w-full py-6 flex justify-between items-center text-left" aria-controls="disclosure-1" aria-expanded="false">
                        <!-- Open: "text-indigo-600", Closed: "text-gray-900" -->
                        <span class="text-gray-900 text-sm font-medium">
                          What to bring
                        </span>
                        <span class="ml-6 flex items-center">
                          <svg [ngClass]="{'hidden': whatToBringOpen,'block': !whatToBringOpen}" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <svg [ngClass]="{'hidden': !whatToBringOpen,'block': whatToBringOpen}" class="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div [ngClass]="{'hidden': !whatToBringOpen,'block': whatToBringOpen}" class="pb-6 prose prose-sm" id="disclosure-1">
                      <p converttoclickablelinks>{{service.whatToBring}}</p>
                    </div>
                  </div>
                  <div *ngIf="service.requirements">
                    <h3 (click)="requirementsOpen = !requirementsOpen">
                      <!-- Expand/collapse question button -->
                      <button type="button" class="group relative w-full py-6 flex justify-between items-center text-left" aria-controls="disclosure-1" aria-expanded="false">
                        <!-- Open: "text-indigo-600", Closed: "text-gray-900" -->
                        <span class="text-gray-900 text-sm font-medium">
                          Requirements
                        </span>
                        <span class="ml-6 flex items-center">
                          <svg  [ngClass]="{'hidden': requirementsOpen,'block': !requirementsOpen}" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <svg [ngClass]="{'hidden': !requirementsOpen,'block': requirementsOpen}" class="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div [ngClass]="{'hidden': !requirementsOpen,'block': requirementsOpen}" class="pb-6 prose prose-sm" id="disclosure-1">
                      <p converttoclickablelinks>{{service.requirements}}</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</ng-template>
  <app-footer></app-footer>
  `
})
export class BookingDetailsComponent implements OnInit {

  service$: Observable<ServiceAndPricesDTO>;
  error: ErrorModel;
  business: BusinessPublicModel;
  imagesUrl = environment.imagesUrl;
  serviceId: string;
  itineraryOpen: boolean = true;
  whatToBringOpen: boolean = false;
  requirementsOpen: boolean = false;
  swimspotOpen: boolean = false;
  meetingPointOpen: boolean = false;
  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptions!: google.maps.MarkerOptions;
  BusinessServiceLocationType = BusinessServiceLocationType;
  markerPositions!: google.maps.LatLngLiteral[];
  service: ServiceAndPricesDTO;

  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private navigate: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private maps: MapsService,
    private servicesPublicService: ServicesPublicService) {
    this.mapsLoaded = this.maps.loadMaps().pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  ngOnInit(): void {

    this.business = (this.route.snapshot.data as any)?.business;
    if (!this.business)
      this.navigate.navigate(WildNavigationType.NOTFOUND)
    this.activatedRoute.params.pipe(take(1)).subscribe((params: Params) => { this.serviceId = params?.id; this.load(); })
  }

  load() {
    this.service$ = null;
    this.service$ = this.servicesPublicService.getServicesAndPricesV2ForService(this.business.id, this.serviceId).pipe(
      take(1),
      tap((service) => {
        this.service = service;
        this.configureMap();
      }),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  goToBusiness() {
    this.router.navigate(["", this.business.url])
  }

  goHome() {
    this.router.navigate([""])
  }

  configureMap() {
    if (this.service?.location != null && this.service?.location?.type == BusinessServiceLocationType.PHYSICAL) {
      this.markerOptions = { draggable: false, };
      this.markerPositions = [{
        lat: Number.parseFloat(this.service?.location?.latitude),
        lng: Number.parseFloat(this.service?.location?.longitude)
      }];
      this.options = {
        center: {
          lat: Number.parseFloat(this.service?.location?.latitude),
          lng: Number.parseFloat(this.service?.location?.longitude)
        },
        zoom: 12,
      };
    }
  }

  book(service: ServiceAndPricesDTO) {
    this.bookingService.book(service);
  }

}
