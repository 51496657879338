import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: '[app-todolistitem]',
    template: ` 
        <div [ngClass]="{'hover:bg-gray-50 cursor-pointer':!completed}"  (click)="click()" class="py-4 flex items-center justify-between space-x-3 ">
            <div class="min-w-0 flex-1 flex items-center space-x-3">
                <div class="flex-shrink-0">
                    <span class="rounded-lg inline-flex p-2 ring-4 ring-white text-center" [class]="bgClass">
                        <fa-icon *ngIf="icon" class="h-5 w-5 text-md" [class]="iconClass" [icon]="icon"></fa-icon>
                        <span *ngIf="text" class="h-5 w-5 text-md" [class]="iconClass">{{text}}</span>
                    </span>
                </div>
                <div class="min-w-0 flex-1">
                    <p [ngClass]="{'line-through':completed}" class="text-sm font-medium text-gray-900 ">{{header}}</p>
                    <p [ngClass]="{'line-through':completed}" class="text-sm font-medium text-gray-500 ">{{description}}</p>
                </div>
            </div>
            <!-- <div *ngIf="ctatext" class="flex-shrink-0">
                <button  type="button"
                    class="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <svg class="-ml-1 mr-2 h-3 w-3 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                    <span class="text-sm font-medium text-gray-900"> {{ctatext}} </span>
                </button>
            </div> -->
        </div>
  `
})
export class ToDoListItemComponent implements OnInit {

    @Input() header: string;
    @Input() completed: boolean;
    @Input() description: string;
    @Input() ctatext: string;
    @Input() bgClass: string;
    @Input() iconClass: string;
    @Input() icon: string;
    @Input() text: string;
    @Output() onClick = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    click() {
        this.onClick.emit();
    }

}
