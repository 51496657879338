import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swimspotlistitemwrapper',
  template: `
  <div class="my-4">
  <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 xl:grid-cols-5">
    <ng-content></ng-content>
    </ul>
</div>`
})
export class SwimspotListWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
