<app-wrapper header="Dashboard">
    <app-header text="Quick Actions"></app-header>
    <app-actionswrapper>
        <div (onClick)="search()" app-actionslistitem header="Find a Swim Spot"
            description="Find a place to swim, check swim forecasts and tides and start planning your next adventure"
            icon="swimmer" bgClass="bg-green-200" iconClass="text-green-800">
        </div>
        <div (onClick)="addSwimSpot()" app-actionslistitem header="Add a Swim Spot"
            description="Unlock advanced forecasting for your Swim Spot and help others discover a great new location to swim at"
            icon="swimmer" bgClass="bg-green-200" iconClass="text-green-800">
        </div>
        <div (onClick)="app()" app-actionslistitem header="Get the app"
            description="Access your swim spots, tide times and swim forecasts through the convenience of the Wild app"
            icon="mobile" bgClass="bg-gray-200" iconClass="text-gray-800">
        </div>
        <div (onClick)="newsletter()" app-actionslistitem header="Join the Newsletter"
            description="Stay up to date with the latest features of the Wild platform. We'll drop you a message from time to time"
            icon="envelope" bgClass="bg-gray-200" iconClass="text-gray-800">
        </div>
    </app-actionswrapper>
</app-wrapper>