import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FavouritesStatus } from '../../../../data/enums/favourites_status';
import { Favourite } from '../../../../data/models/favourites_model';
import { FirestoreQueryType } from '../../../../data/enums/firestore_query_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  constructor(
    private auth: AuthenticationService,
    private firestore: FirestoreService
  ) { }

  list(): Observable<Favourite[]> {
    var userId = this.auth.getLoggedInCookie();
    return this.firestore.getListByQuery("favourites", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "createdBy",
        value: userId
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: FavouritesStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

}