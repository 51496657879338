<app-wild-header></app-wild-header>
<div class="isolate bg-white">
  <div class="absolute inset-x-0 -z-10 transform-gpu overflow-hidden blur-3xl ">
    <svg
      class="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
      viewBox="0 0 1155 678" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)" fill-opacity=".3"
        d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
      <defs>
        <linearGradient id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533" x1="1155.49" x2="-78.208" y1=".177" y2="474.645"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#ebf3ff"></stop>
          <stop offset="1" stop-color="#c0d9ff"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <main>
    <div class="relative px-6 lg:px-8">
      <div class="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
        <div>
          <!-- <div class="hidden sm:mb-8 sm:flex sm:justify-center">
            <div
              class="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              <span class="text-gray-600">
                Announcing our next round of funding. <a href="#" class="font-semibold text-indigo-600"><span
                    class="absolute inset-0" aria-hidden="true"></span>Read more <span
                    aria-hidden="true">&rarr;</span></a>
              </span>
            </div>
          </div> -->
          <div>
            <div class="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl relative overflow-hidden">
              <app-rotatingtext prefix="Built for"
                [strings]="['swimmers','dippers','paddlers','surfers','sailors','divers']"></app-rotatingtext>
            </div>
            <h1 class="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">Water Sports Community &
              Booking Platform
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-600 sm:text-center">Book Professionals, Track your water sports
              journey and discover & share beautiful blue spaces</p>
            <div class="mt-8 flex gap-x-4 sm:justify-center">
              <a (click)="goToSearchPage()"
                class="cursor-pointer inline-block rounded-lg wild-bg-primary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-900 hover:bg-blue-900 hover:ring-blue-900">
                Search Wild
                <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
              </a>
              <a (click)="becomeAnOrganiser()"
                class="cursor-pointer inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                For Business
                <span class="text-gray-400" aria-hidden="true">&rarr;</span>
              </a>
            </div>
            <div class="hidden sm:block sm:flex:col justify-center items-center mt-12 text-center">
              <p class="text-md text-gray-500 font-semibold">Scan me to join the community on the FREE Wild app</p>
              <p class="animated bounce inline-block mt-3">👇</p>
              <img class="h-32 w-32 mx-auto mt-4"
                src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/qrcodes%2Fowj.png?alt=media&token=bb2bd341-e5bc-4387-8f08-6f595d185c50" />
              <p class="text-md text-gray-500 font-extralight mt-3">Available on iOS & Android</p>
            </div>
            <div class="sm:hidden mt-10">
              <p class="text-sm text-gray-500 font-light">...or join the community on the FREE Wild app:</p>
              <div class="flex mt-6">
                <a class="mr-5" href="https://apps.apple.com/us/app/wild-open-water-swimming/id1576881664"
                  target="_blank">
                  <img class="h-11 mt-4 sm:mt-0 sm:mr-3"
                    src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fappstore3.png?alt=media&token=7781de9e-0c82-4005-b1a3-ab880f942057" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.swimwild.wild" target="_blank">
                  <img class="h-11 mt-4 sm:mt-0"
                    src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fplaystore3.png?alt=media&token=73ed962f-39d0-4146-a750-9dc80ca4183b" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- <app-logocloud></app-logocloud> -->
</div>

<div>
  <ng-container *ngIf="searchHome$ | async as searchHome; else loadingOrError">
    <div *ngIf="!searchHome; else searchResults">
      <!-- NO RESULTS - SHOW NOTHING -->
    </div>
    <ng-template #searchResults>
      <div class="mx-auto max-w-7xl px-4">
        <!-- Wild PRO Start    -->
        <app-subscribe></app-subscribe>
        <!-- Wild PRO End     -->
        <div *ngIf="searchHome.businesses?.length > 0" style="height: 350px;">
          <app-homepageresults [results]="searchHome.businesses" (moreClick)="goToSearchPageForBusinesses()"
            moretext="Discover More" label="Businesses" [location]="searchHome.location">
          </app-homepageresults>
        </div>
        <div style="height: 350px;">
          <app-homepageresults [results]="searchHome.spots" (moreClick)="goToSearchPageForSpots()"
            moretext="Discover More Spots" label="Spots" [location]="searchHome.location">
          </app-homepageresults>
        </div>
        <div style="height: 350px;">
          <app-homepageresults [results]="searchHome.coastal" (moreClick)="goToSearchPageForCoastalSpots()"
            moretext="Discover More Beaches" label="Beaches" [location]="searchHome.location">
          </app-homepageresults>
        </div>
        <div style="height: 350px;">
          <app-homepageresults [results]="searchHome.lakes" (moreClick)="goToSearchPageForLakeSpots()"
            moretext="Discover More Lakes" label="Lakes" [location]="searchHome.location">
          </app-homepageresults>
        </div>
        <div style="height: 350px;">
          <app-homepageresults [results]="searchHome.rivers" (moreClick)="goToSearchPageForRiverSpots()"
            moretext="Discover More Rivers" label="Rivers" [location]="searchHome.location">
          </app-homepageresults>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="loadHome()">
      </app-retry>
    </ng-container>
    <ng-template #loading>
      <div class="mx-auto max-w-7xl px-4 overflow-hidden">
        <app-searchresultsrowhimmer></app-searchresultsrowhimmer>
        <app-searchresultsrowhimmer></app-searchresultsrowhimmer>
        <app-searchresultsrowhimmer></app-searchresultsrowhimmer>
        <app-searchresultsrowhimmer></app-searchresultsrowhimmer>
        <app-searchresultsrowhimmer></app-searchresultsrowhimmer>
      </div>
    </ng-template>
  </ng-template>
</div>
<!-- SEARCH END -->
<!-- SOMETHING FOR EVERYONE START -->
<div class="py-12 overflow-hidden bg-white-50">
  <div class="relative max-w-xl px-4 mx-auto  md:max-w-7xl">
    <div class="w-full text-xl text-left mb-4 font-medium">Something for everyone</div>
    <ul role="list"
      class="grid grid-cols-2 gap-x-3 gap-y-8 sm:grid-cols-3 sm:gap-x-3 md:grid-cols-4 md:gap-x-3 xl:grid-cols-6 xl:gap-x-3">
      <li (click)="openHelper(helper)" *ngFor="let helper of helpers" class="relative">
        <div
          class="group w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden block relative">
          <img [src]="helper.image" alt=""
            class="wild-bg-primary object-bottom pointer-events-none group-hover:opacity-75">
          <button type="button" class="absolute inset-0 focus:outline-none">
            <span class="sr-only">View details for {{helper.name}}</span>
          </button>
          <p class="mt-2 block text-sm font-semibold text-white truncate pointer-events-none absolute mx-auto text-center"
            style="top:45%">
            {{helper.name}}</p>
        </div>
      </li>
    </ul>
  </div>
</div>
<!-- SOMETHING FOR EVERYONE END -->
<!-- REVIEWS START -->
<div>
  <div class="mx-auto max-w-2xl py-8 px-4 lg:max-w-7xl">
    <h2 class="w-full text-xl text-left mb-4 font-medium">What Wild Users Think</h2>
    <div class="mt-6 space-y-10 divide-y divide-gray-200 border-t border-b border-gray-200 pb-10">
      <div class="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div
          class="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
          <div class="flex items-center xl:col-span-1">
            <div class="flex items-center">
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <p class="ml-3 text-sm text-gray-700">5<span class="sr-only"> out of 5 stars</span></p>
          </div>

          <div class="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
            <h3 class="text-sm font-medium text-gray-900">Can&#039;t say enough good things</h3>

            <div class="mt-3 space-y-6 text-sm text-gray-500">
              <p> Fantastic app and by far the most useful for wild swimming.</p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
          <p class="font-medium text-gray-900">Sally Qajar</p>
          <time datetime="2021-01-06"
            class="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"></time>
        </div>
      </div>
      <div class="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div
          class="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
          <div class="flex items-center xl:col-span-1">
            <div class="flex items-center">
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <p class="ml-3 text-sm text-gray-700">5<span class="sr-only"> out of 5 stars</span></p>
          </div>

          <div class="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
            <h3 class="text-sm font-medium text-gray-900">Great app to track spots and swims</h3>
            <div class="mt-3 space-y-6 text-sm text-gray-500">
              <p>Includes all the weather data you might need, and logging/sharing capabilities. Developer is quick with
                comms and fixes! Highly recommend.</p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
          <p class="font-medium text-gray-900">Lyle Skains</p>
          <time datetime="2021-01-06"
            class="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"></time>
        </div>
      </div>
      <div class="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div
          class="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
          <div class="flex items-center xl:col-span-1">
            <div class="flex items-center">
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <p class="ml-3 text-sm text-gray-700">5<span class="sr-only"> out of 5 stars</span></p>
          </div>

          <div class="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
            <h3 class="text-sm font-medium text-gray-900">Useful and Organic</h3>
            <div class="mt-3 space-y-6 text-sm text-gray-500">
              <p>Just starting but incredibly useful and organic</p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
          <p class="font-medium text-gray-900">INMACULADA JIMENEZ LOPEZ</p>
          <time datetime="2021-01-06"
            class="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"></time>
        </div>
      </div>
      <div class="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div
          class="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
          <div class="flex items-center xl:col-span-1">
            <div class="flex items-center">
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <p class="ml-3 text-sm text-gray-700">5<span class="sr-only"> out of 5 stars</span></p>
          </div>

          <div class="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
            <h3 class="text-sm font-medium text-gray-900">Just what the wild swimming community has been waiting for
            </h3>
            <div class="mt-3 space-y-6 text-sm text-gray-500">
              <p>Locations by real swimmers with info by regular users. Everything you need to know in one app!
                Perfect🏊🏻🦭🏊🏻</p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
          <p class="font-medium text-gray-900">Lara Brown</p>
          <time datetime="2021-01-06"
            class="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"></time>
        </div>
      </div>
      <div class="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div
          class="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
          <div class="flex items-center xl:col-span-1">
            <div class="flex items-center">
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>

              <!-- Heroicon name: mini/star -->
              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <p class="ml-3 text-sm text-gray-700">5<span class="sr-only"> out of 5 stars</span></p>
          </div>

          <div class="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
            <h3 class="text-sm font-medium text-gray-900">Awesome</h3>
            <div class="mt-3 space-y-6 text-sm text-gray-500">
              <p>This is Awesome, so much info on wild Swimming.. love it</p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
          <p class="font-medium text-gray-900">Adventure Swim Coaching</p>
          <time datetime="2021-01-06"
            class="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"></time>
        </div>
      </div>

      <!-- More reviews... -->
    </div>
  </div>
</div>
<!-- REVIEWS END -->
<app-latestblog></app-latestblog>
<app-appdownloadlinks header="Join the community on the FREE Wild app"></app-appdownloadlinks>
<app-footer></app-footer>