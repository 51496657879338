import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const checkout: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcustomerbooking%2Fcheckout.png?alt=media&token=78bf4822-7a55-427d-acf9-a128b3ad76bb",
  title: "Checkout",
  subtitle: "Secure. Fast. Easy.",
  paragraph: "Simple experience across app & website.",
  data: [
    {
      icon: "lock", title: "Secure.", subtitle: "We use Stripe."
    },
    {
      icon: "mobile", title: "Apple Pay.", subtitle: "Ready to go."
    },
    {
      icon: "mobile", title: "Google Pay.", subtitle: "Ready to go."
    },
    {
      icon: "envelope", title: "Confirmations.", subtitle: "Sent Automatically."
    },
    {
      icon: "stopwatch", title: "Reminders.", subtitle: "Sent Automatically."
    },
    {
      icon: "cog", title: "Customer Orders.", subtitle: "They have an Account."
    },
  ]
}