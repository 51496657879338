import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GroupDetailsDTO } from '../../../../data/dtos/group/GroupDetailsDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class groupsService {

    constructor(
        private http: HttpService) { }

    get(id: string): Observable<GroupDetailsDTO> {
        return this.http.get(`${environment.api}/user/groups/p/${id}`);
    }


}

