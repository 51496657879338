import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form',
  template: `
  <div>
  <form [formGroup]="form" (ngSubmit)="submit(form.value)"
    [ngClass]="{'space-y-8 divide-y divide-gray-200':!disableDivide}">
    <div class="space-y-8" [ngClass]="{'space-y-8 divide-y divide-gray-200':!disableDivide}">
      <div>
        <div>
          <h3 *ngIf="header" class="wild-header leading-6 ">
            {{header}}
          </h3>
          <p *ngIf="description" class="wild-paragraph mt-1 ">
            {{description}}
          </p>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <div *ngIf="!hidebuttons" class="pt-5">
      <div class="flex sm:justify-end flex-col-reverse sm:flex-row ">
        <button *ngIf="cancelText" (click)="cancel()" type="button" class="w-full sm:w-auto wild-btn-light sm:mr-3 mt-3 sm:mt-0"
          style="min-width: 100px;">
          <span *ngIf="!loading">{{cancelText}}</span>
          <div *ngIf="loading" class="loader"></div>
        </button>
        <button [ngClass]="{'fixed bottom-5 right-5': floatingsave == true,'w-full':fullwidthbutton == true,'sm:w-auto':fullwidthbutton != true}" type="submit" class="wild-btn-primary w-full" style="min-width: 100px;">
          <span *ngIf="!loading">{{ctaText}}</span>
          <div *ngIf="loading" class="loader"></div>
        </button>
      </div>
    </div>
  </form>
</div>
`
})
export class FormComponent implements OnInit {

  @Input() header: string | undefined;
  @Input() hidebuttons: boolean;
  @Input() floatingsave: boolean;
  @Input() description: string | undefined;
  @Input() form!: UntypedFormGroup;
  @Input() fullwidthbutton!: boolean;
  @Input() disableDivide!: boolean;
  @Input() ctaText: String = "Save";
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<void>();
  @Input() loading: boolean;
  @Input() cancelText: string;

  constructor() { }

  ngOnInit(): void {
  }

  submit(data: any) {
    //This allows the errors to show when attempting to submit
    this.form["submitted"] = true;
    //Form is valid and prevent double submission
    if (this.form.valid && !this.loading)
      this.onSubmit.emit(data);
  }

  cancel() {
    if (!this.loading)
      this.onCancel.emit();
  }

}
