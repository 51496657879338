<div *ngIf="favourites$">
    <ng-container *ngIf="favourites$ | async as favourites; else loadingOrError">
        <div *ngIf="!favourites || favourites.length == 0; else favouriteslist">
            <app-empty icon="heart" header="No Favourites yet"
                description="Click on the heart on any swim spot page to add a favourite" ctatext="Search"
                [showcta]="true" (onClick)="search()"></app-empty>
        </div>
        <ng-template #favouriteslist>
            <app-wrapper header="Favourites">
                <app-swimspotlistitemwrapper>
                    <li *ngFor="let favourite of favourites" [id]="favourite.swimSpotId"
                        (onClick)="openSwimSpot(favourite?.swimSpot?.id)" [caption]="favourite?.swimSpot?.name" [src]="
                        favourite?.swimSpot?.image == null ?
                        'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839' :
                        imagesUrl + favourite?.swimSpot?.image?.image + '_640x640.jpeg?alt=media'"
                        app-swimspotlistitem>
                    </li>
                </app-swimspotlistitemwrapper>
                <app-appdownloadlinks header="Manage your Favourites on the Wild App"></app-appdownloadlinks>
            </app-wrapper>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-wrapper>
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </app-wrapper>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
    
</div>