<app-wild-header></app-wild-header>
<app-wildhero header="THE OPEN WATER JOURNAL" subline="Add your first entry today"
  image="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FHome%20Page.png?alt=media&token=ef86a829-145b-42a5-abb3-302477dcec37">
  <div class="my-6 mb-6">
    <div class="flex justify-center items-center flex-row">
      <a href="https://apps.apple.com/us/app/wild-open-water-swimming/id1576881664" target="_blank">
        <img class="h-10"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fappstore3.png?alt=media&token=7781de9e-0c82-4005-b1a3-ab880f942057" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.swimwild.wild" target="_blank">
        <img class="h-10 ml-4"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fplaystore3.png?alt=media&token=73ed962f-39d0-4146-a750-9dc80ca4183b" />
      </a>
    </div>
  </div>
  <br />
  <br />
  <br />
</app-wildhero>
<div class="py-16 overflow-hidden lg:py-24">
  <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
    <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784"
      fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20"
          patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
    </svg>

    <div class="relative">
      <!-- <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Join the
        community</h2> -->
      <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">Join the community of people sharing swim
        spots, experiences & local knowledge.</p>
      <!-- <app-appdownloadlinks header=""></app-appdownloadlinks> -->
    </div>
    <!-- FEATURE ONE -->
    <div class="relative mt-12 lg:mt-6 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="relative">
        <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Your Journal</h3>
        <p class="mt-3 text-lg text-gray-500">Record all sorts of open water activities like swims, dips, SUP, cold
          water
          immersion and more.</p>

        <dl class="mt-10 space-y-10">
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="swimmer"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Conditions 🌓 🌞 🌊 🌦</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Every entry into your journal pulls in loads of data about
              the lunar conditions, water temperature, wind, waves and even the suns glare. Giving you the perfect
              snapshot of each open water moment.</dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="clock"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">It's all about the feel 😶 🌊 😁</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Open water has the power to change our emotions. How did you
              feel before your swim? How do you feel afterwards? With each entry, quickly record how you felt before and
              after being in the water.</dd>
          </div>
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="clock"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Connecting With Others 🤗</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Local knowledge is paramount to staying safe out there.
              We've made it really easy to message other users about any and all activities (unless private) so you
              can get some local insights before swimming somewhere new. </dd>
          </div>
        </dl>
      </div>

      <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
        <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404"
          fill="none" viewBox="0 0 784 404">
          <defs>
            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20"
              patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
        </svg>
        <img class="relative mx-auto rounded-lg" width="490"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fscreenshot.png?alt=media&token=06b2c630-51f9-43d8-b791-6a87201a306d"
          alt="">
      </div>
    </div>

    <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784"
      fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20"
          patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
    </svg>
    <!-- FEATURE TWO -->
    <div class="relative mt-12 sm:mt-16 lg:mt-24">
      <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="lg:col-start-2">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">There's more</h3>


          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                  <fa-icon icon="stopwatch"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Garmin Ready ⌚
                </p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">We have partnered with Garmin so you can just connect your
                watch and see your journal come to life. We'll link you to spots, pull in the conditions automatically
                and send you a notification. </dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                  <fa-icon icon="list"></fa-icon>
                </div>
                <p class=" ml-16 text-lg leading-6 font-medium text-gray-900">Feeds 📲</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">Explore Wild global, your own community of swimmers or a
                feed for a specific location.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                  <fa-icon icon="lock"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Privacy 🔒</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">Create a private journal just for you, share a post or
                share every activity. It's all in your control. </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404"
            fill="none" viewBox="0 0 784 404" aria-hidden="true">
            <defs>
              <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20"
                patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
          </svg>
          <img class="relative mx-auto rounded-lg" width="490"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fowj2.png?alt=media&token=8966a7e3-9be0-415a-a550-1f86e0f36c21"
            alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<app-appdownloadlinks header="Start Your FREE Open Water Journal Today"></app-appdownloadlinks>
<app-footer></app-footer>