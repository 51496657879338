import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchresults-wrapper',
  template: `
  <div class=" pb-12 px-3 max-w-3xl py-12">
      <ul role="list" class="space-y-12">
          <ng-content></ng-content>
      </ul>
  </div>    
  `
})
export class SearchresultsWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
