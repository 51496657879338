import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { Currency } from '../../../data/enums/currency';
import { Days } from '../../../data/enums/days';
import { CurrencyService } from '../currency/currency_service';
import { CountryCodes } from '../../../data/enums/country_codes';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor(private currencyService: CurrencyService) { }

    getPhoneNumberCodes(): SelectModel[] {
        return [
            {
                "name": "🇦🇺 Australia +61",
                "value": "+61"
            },
            {
                "name": "🇦🇹 Austria +43",
                "value": "+43"
            },
            {
                "name": "🇧🇪 Belgium +32",
                "value": "+32"
            },
            {
                "name": "🇧🇷 Brazil +55",
                "value": "+55"
            },
            {
                "name": "🇧🇬 Bulgaria +359",
                "value": "+359"
            },
            {
                "name": "🇨🇦 Canada +1",
                "value": "+1"
            },
            {
                "name": "🇭🇷 Croatia +385",
                "value": "+385"
            },
            {
                "name": "🇨🇾 Cyprus +357",
                "value": "+357"
            },
            {
                "name": "🇨🇿 Czech Republic +420",
                "value": "+420"
            },
            {
                "name": "🇩🇰 Denmark +45",
                "value": "+45"
            },
            {
                "name": "🇪🇪 Estonia +372",
                "value": "+372"
            },
            {
                "name": "🇫🇮 Finland +358",
                "value": "+358"
            },
            {
                "name": "🇫🇷 France +33",
                "value": "+33"
            },
            {
                "name": "🇩🇪 Germany +49",
                "value": "+49"
            },
            {
                "name": "🇬🇮 Gibraltar +350",
                "value": "+350"
            },
            {
                "name": "🇬🇷 Greece +30",
                "value": "+30"
            },
            {
                "name": "🇭🇰 Hong Kong +852",
                "value": "+852"
            },
            {
                "name": "🇭🇺 Hungary +36",
                "value": "+36"
            },
            {
                "name": "🇮🇳 India +91",
                "value": "+91"
            },
            {
                "name": "🇮🇩 Indonesia +62",
                "value": "+62"
            },
            {
                "name": "🇮🇪 Ireland +353",
                "value": "+353"
            },
            {
                "name": "🇮🇹 Italy +39",
                "value": "+39"
            },
            {
                "name": "🇯🇵 Japan +81",
                "value": "+81"
            },
            {
                "name": "🇱🇻 Latvia +371",
                "value": "+371"
            },
            {
                "name": "🇱🇮 Liechtenstein +423",
                "value": "+423"
            },
            {
                "name": "🇱🇹 Lithuania +370",
                "value": "+370"
            },
            {
                "name": "🇱🇺 Luxembourg +352",
                "value": "+352"
            },
            {
                "name": "🇲🇾 Malaysia +60",
                "value": "+60"
            },
            {
                name: "🇲🇹 Malta +356",
                value: "+356"
            },
            {
                name: "🇲🇽 Mexico +52",
                value: "+52"
            },
            {
                name: "🇳🇱 Netherlands +31",
                value: "+31"
            },
            {
                name: "🇳🇿 New Zealand +64",
                value: "+64"
            },
            {
                name: "🇳🇴 Norway +47",
                value: "+47"
            },
            {
                name: "🇵🇱 Poland +48",
                value: "+48"
            },
            {
                name: "🇵🇹 Portugal +351",
                value: "+351"
            },
            {
                name: "🇷🇴 Romania +40",
                value: "+40"
            },
            {
                name: "🇸🇬 Singapore +65",
                value: "+65"
            },
            {
                name: "🇸🇰 Slovakia +421",
                value: "+421"
            },
            {
                name: "🇸🇮 Slovenia +386",
                value: "+386"
            },
            {
                name: "🇪🇸 Spain +34",
                value: "+34"
            },
            {
                name: "🇸🇪 Sweden +46",
                value: "+46"
            },
            {
                name: "🇨🇭 Switzerland +41",
                value: "+41"
            },
            {
                name: "🇹🇭 Thailand +66",
                value: "+66"
            },
            {
                name: "🇦🇪 United Arab Emirates +971",
                value: "+971"
            },
            {
                name: "🇬🇧 United Kingdom +44",
                value: "+44"
            },
            {
                name: "🇺🇸 United States +1",
                value: "+1"
            }
        ];
    }
    getCountries(): SelectModel[] {
        return [
            // {
            //     name: "🇦🇺 Australia",
            //     value: CountryCodes.AUAUS
            // },
            {
                name: "🇦🇹 Austria",
                value: CountryCodes.ATAUT
            },
            {
                name: "🇧🇪 Belgium",
                value: CountryCodes.BEBEL
            },
            // {
            //     name: "🇧🇷 Brazil",
            //     value: CountryCodes.BRBRA
            // },
            // {
            //     name: "🇧🇬 Bulgaria",
            //     value: CountryCodes.BGBGR
            // },
            // {
            //     name: "🇨🇦 Canada",
            //     value: CountryCodes.CACAN
            // },
            {
                name: "🇭🇷 Croatia",
                value: CountryCodes.HRHRV
            },
            {
                name: "🇨🇾 Cyprus",
                value: CountryCodes.CYCYP
            },
            // {
            //     name: "🇨🇿 Czech Republic",
            //     value: CountryCodes.CZCZE
            // },
            // {
            //     name: "🇩🇰 Denmark",
            //     value: CountryCodes.DKDNK
            // },
            {
                name: "🇪🇪 Estonia",
                value: CountryCodes.EEEST
            },
            {
                name: "🇫🇮 Finland",
                value: CountryCodes.FIFIN
            },
            {
                name: "🇫🇷 France",
                value: CountryCodes.FRFRA
            },
            {
                name: "🇩🇪 Germany",
                value: CountryCodes.DEDEU
            },
            {
                name: "🇬🇮 Gibraltar",
                value: CountryCodes.GIGIB
            },
            {
                name: "🇬🇷 Greece",
                value: CountryCodes.GRGRC
            },
            // {
            //     name: "🇭🇰 Hong Kong",
            //     value: CountryCodes.HKHKG
            // },
            // {
            //     name: "🇭🇺 Hungary",
            //     value: CountryCodes.HUHUN
            // },
            // {
            //     name: "🇮🇳 India",
            //     value: CountryCodes.ININD
            // },
            {
                name: "🇮🇪 Ireland",
                value: CountryCodes.IEIRL
            },
            // {
            //     name: "🇮🇩 Indonesia",
            //     value: CountryCodes.IDIDN
            // },
            {
                name: "🇮🇹 Italy",
                value: CountryCodes.ITITA
            },
            // {
            //     name: "🇯🇵 Japan",
            //     value: CountryCodes.JPJPN
            // },
            {
                name: "🇱🇻 Latvia",
                value: CountryCodes.LVLVA
            },
            // {
            //     name: "🇱🇮 Liechtenstein",
            //     value: CountryCodes.LILIE
            // },
            {
                name: "🇱🇹 Lithuania",
                value: CountryCodes.LTLTU
            },
            {
                name: "🇱🇺 Luxembourg",
                value: CountryCodes.LULUX
            },
            // {
            //     name: "🇲🇾 Malaysia",
            //     value: CountryCodes.MYMYS
            // },
            {
                name: "🇲🇹 Malta",
                value: CountryCodes.MTMLT
            },
            // {
            //     name: "🇲🇽 Mexico",
            //     value: CountryCodes.MXMEX
            // },
            {
                name: "🇳🇱 Netherlands",
                value: CountryCodes.NLNLD
            },
            // {
            //     name: "🇳🇿 New Zealand",
            //     value: CountryCodes.NZNZL
            // },
            // {
            //     name: "🇳🇴 Norway",
            //     value: CountryCodes.NONOR
            // },
            // {
            //     name: "🇵🇱 Poland",
            //     value: CountryCodes.PLPOL
            // },
            {
                name: "🇵🇹 Portugal",
                value: CountryCodes.PTPRT
            },
            // {
            //     name: "🇷🇴 Romania",
            //     value: CountryCodes.ROROU
            // },
            // {
            //     name: "🇸🇬 Singapore",
            //     value: CountryCodes.SGSGP
            // },
            {
                name: "🇸🇮 Slovenia",
                value: CountryCodes.SISVN
            },
            // {
            //     name: "🇸🇪 Sweden",
            //     value: CountryCodes.SESWE
            // },
            // {
            //     name: "🇨🇭 Switzerland",
            //     value: CountryCodes.CHCHE
            // },
            // {
            //     name: "🇹🇭 Thailand",
            //     value: CountryCodes.THTHA
            // },
            // {
            //     name: "🇦🇪 United Arab Emirates",
            //     value: CountryCodes.AEARE
            // },
            {
                name: "🇬🇧 United Kingdom",
                value: CountryCodes.GBGBR
            },
            {
                name: "🇺🇸 United States",
                value: CountryCodes.USUSA
            }
        ];
    }

    getRandomNumber(): number {
        return Math.floor((Math.random() * 100000000000) + 1);
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes == null || bytes == undefined || bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    isMobile() {
        var a = navigator.userAgent || navigator.vendor;
        return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    }

    getInitials(firstName: string, surname: string) {
        var initials = "";
        initials = firstName?.substring(0, 1) ?? "";
        initials = initials + surname?.substring(0, 1) ?? "";
        return initials.toUpperCase();
    }

    getDayDescription(day: Days): string {
        var description = "UNKNOWN DAY";
        switch (day) {
            case Days.MONDAY:
                description = "Monday";
                break;

            case Days.TUESDAY:
                description = "Tuesday";
                break;

            case Days.WEDNESDAY:
                description = "Wednesday";
                break;

            case Days.THURSDAY:
                description = "Thursday";
                break;

            case Days.FRIDAY:
                description = "Friday";
                break;

            case Days.SATURDAY:
                description = "Saturday";
                break;

            case Days.SUNDAY:
                description = "Sunday";
                break;
            default:
                break;
        }
        return description
    }

    getNumberOptions(limit: number, multiple: number): SelectModel[] {
        var numbers: SelectModel[] = [];
        for (let i = 0; i < limit; i++) {
            const element = i * multiple;
            numbers.push({
                name: element.toString(),
                value: element
            })
        }
        return numbers;
    }
    getFormattedPrice(price, currency: Currency): string {
        try {
            //compensates for table table - it sets it as string
            currency = parseInt(currency.toString());
        } catch (error) {

        }
        var symbol = this.currencyService.getSymbol(currency);
        var formattedPrice = this.currencyService.priceToReadable(price);
        return symbol + formattedPrice;
    }
    //     To match pattern or an empty string, use
    // ^$|pattern
    // Explanation
    // ^ and $ are the beginning and end of the string anchors respectively.
    // | is used to denote alternates, e.g. this|that.
    get phoneValidator(): ValidatorFn {
        return Validators.pattern(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/)
    }
    get whatsappValidator(): ValidatorFn {
        return Validators.pattern(/^(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/)
    }
    get websiteValidator(): ValidatorFn {
        return Validators.pattern(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/)
    }
    get youTubeChannelValidator(): ValidatorFn {
        return Validators.pattern(/^(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+$/)
    }
    get facebookPageValidator(): ValidatorFn {
        return Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)$/)
    }
    get instagramValidator(): ValidatorFn {
        return Validators.pattern(/^(?:(?:[\w][\.]{0,1})*[\w]){1,29}$/)
    }
    get twitterValidator(): ValidatorFn {
        return Validators.pattern(/^(?:(?:[\w][\.]{0,1})*[\w]){1,29}$/)
    }
    get noSpecialCharacters(): ValidatorFn {
        return Validators.pattern(/^[a-zA-Z0-9]{4,10}$/)
    }
    get noSpecialCharactersAllowSpaces(): ValidatorFn {
        return Validators.pattern(/^[A-Za-z0-9 ]+$/)
    }
    get lettersOnly(): ValidatorFn {
        return Validators.pattern(/^[a-zA-Z]*$/)
    }
    get lettersAndSpacesOnly(): ValidatorFn {
        return Validators.pattern(/^[a-zA-Z\s]*$/)
    }
    get numbersOnly(): ValidatorFn {
        return Validators.pattern(/^[0-9]*$/)
    }

    get priceValidator(): ValidatorFn {
        return Validators.pattern(/^\d+(\.\d{1,2})?$/)
    }

    twoDecimalPlace(num: number): string {
        return (num / 100).toFixed(2).toString()
    }
}
