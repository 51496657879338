<app-wrapper header="Business Settings" paragraph="Manage all your Wild settings in one place.">
    <app-actionswrapper>
        <div *ngIf="(isOwner() || isAdmin()) && !isAppUser" (onClick)="goToConfiguration()" app-actionslistitem
            header="Subscription & Configuration" description="Manage subscription and view business configuration"
            icon="cog" bgClass="bg-blue-200" iconClass="text-blue-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToBank()" app-actionslistitem header="Bank Details"
            description="On your dashboard, click on the 👤 icon and select 'Payout details'" icon="coins"
            bgClass="bg-blue-200" iconClass="text-blue-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToOnlineBookingSettings()" app-actionslistitem
            header="Online Booking Settings" description="Refund policy & time slot intervals" icon="calendar-week"
            bgClass="bg-blue-200" iconClass="text-blue-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" app-actionslistitem header="Team" (onClick)="goToTeam()"
            description="Add, edit and delete team members from your account" icon="users" bgClass="bg-blue-200"
            iconClass="text-blue-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" app-actionslistitem header="Waivers" (onClick)="goToWaivers()"
            description="Set up a waiver that each of your contacts has to sign to participate in your services"
            icon="signature" bgClass="bg-blue-200" iconClass="text-blue-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToArea()" app-actionslistitem header="Area"
            description="Configure the area you work in so people can find you" icon="map-marker-alt"
            bgClass="bg-green-200" iconClass="text-green-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToLocation()" app-actionslistitem header="Addresses"
            description="Set one or more addresses if you work from a particlar location" icon="map-pin"
            bgClass="bg-green-200" iconClass="text-green-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToBrand()" app-actionslistitem header="Brand"
            description="Add photos, change description and add links to promote your business" icon="palette"
            bgClass="bg-red-200" iconClass="text-red-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToServices()" app-actionslistitem header="Services"
            description="Add, edit and delete types of services available for bookings" icon="water"
            bgClass="bg-yellow-200" iconClass="text-yellow-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToCategories()" app-actionslistitem header="Categories"
            description="Categorise your services for easier bookings" icon="folder" bgClass="bg-yellow-200"
            iconClass="text-yellow-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToTimeTables()" app-actionslistitem header="Timetables"
            description="Sync on-demand bookings with timetables" icon="table" bgClass="bg-yellow-200"
            iconClass="text-yellow-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToDiscountCodes()" app-actionslistitem header="Discount Codes"
            description="Offer your customers fixed or percentage discounts" icon="tag" bgClass="bg-yellow-200"
            iconClass="text-yellow-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToSwimSpots()" app-actionslistitem header="Link to Spots"
            description="Connect your business to lakes, rivers and beaches" icon="swimmer" bgClass="bg-yellow-200"
            iconClass="text-yellow-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToWelcomeEmail()" app-actionslistitem header="Welcome Email"
            description="Configure the content of your welcome email sent to each new contact" icon="envelope"
            bgClass="bg-pink-200" iconClass="text-pink-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToPlugins()" app-actionslistitem header="Plugins"
            description="Wild Integrations" icon="plug" bgClass="bg-gray-200" iconClass="text-black-800">
        </div>
        <div *ngIf="isOwner() || isAdmin()" (onClick)="goToStatusPage()" app-actionslistitem header="Go Live"
            description="Change the status of your business from Draft to Live and Vice Versa" icon="rocket"
            bgClass="bg-gray-200" iconClass="text-black-800">
        </div>

    </app-actionswrapper>

</app-wrapper>