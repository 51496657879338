<app-wrapper header="Vouchers">
    <div *ngIf="coupons$">
        <ng-container *ngIf="coupons$ | async as coupons; else loadingOrError">
            <div *ngIf="!coupons || coupons?.coupons?.length == 0; else couponslist">
                <app-empty icon="ticket-alt" header="No Vouchers yet" ctatext="Search" (onClick)="search()"
                    [showcta]="!isAppUser"></app-empty>
            </div>
            <ng-template #couponslist>
                <section>
                    <div class="rounded-md bg-blue-50 p-4 my-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between ">
                                <p class="text-sm text-blue-700">Your Vouchers will be presented at checkout when
                                    booking a relevant activity. You will then be able to apply your vouchers until
                                    credit runs out.</p>
                                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                                    <!-- <a href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                                Details
                                <span aria-hidden="true"> &rarr;</span>
                              </a> -->
                                </p>
                            </div>
                        </div>
                    </div>
                    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                        <li style="border: 1px solid #dedede;" *ngFor="let coupon of coupons.coupons"
                            class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div class="flex w-full items-center justify-between space-x-6 p-6">
                                <div class="flex-1 truncate">
                                    <h3 class="truncate text-md font-medium text-gray-900 leading-tight">
                                        {{coupon.name}}
                                    </h3>
                                    <p class="flex-shrink-0 font-normal text-xs text-gray-500">{{coupon.businessName}}
                                    </p>
                                    <p class="mt-1 truncate text-sm text-gray-500">{{coupon.quantityused}} of
                                        {{coupon.quantityavailable}} used</p>
                                    <p class="mt-1 truncate text-sm text-gray-500">Valid until
                                        {{coupon.readableExpiryDate}}</p>
                                    <p class="mt-1 truncate text-sm text-gray-500">use code <b
                                            class="font-bold wild-text-primary">{{coupon.code}}</b> at checkout</p>
                                </div>
                                <!-- <img class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                                    alt=""> -->
                            </div>
                            <!-- <div>
                                <div class="-mt-px flex divide-x divide-gray-200">
                                    <div class="flex w-0 flex-1">
                                        <a href="mailto:janecooper@example.com"
                                            class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                                            <fa-icon icon="ticket-alt" class=" text-gray-500 text-md"></fa-icon>
                                            <span class="ml-3">Info</span>
                                        </a>
                                    </div>
                                    <div class="-ml-px flex w-0 flex-1">
                                        <a href="tel:+1-202-555-0170"
                                            class="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                                            <svg class="flex-shrink-0 h-6 w-6 wild-text-primary"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="2" stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                            </svg>
                                            <span class="ml-3">Use</span>
                                        </a>
                                    </div>
                                </div>
                            </div> -->
                        </li>
                    </ul>
                </section>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-wrapper>
                    <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                        (onRetry)="load()">
                    </app-retry>
                </app-wrapper>
            </ng-container>
            <ng-template #loading>
                <app-loader [disablescreenheight]="true"></app-loader>
            </ng-template>
        </ng-template>
    </div>
</app-wrapper>