import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';
import { SendMessageDTO } from 'src/data/dtos/messages/SendMessageDTO';
import { MessageListItemDTO } from 'src/data/dtos/messages/MessageListItemDTO';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SendMessageService {

    constructor(
        private http: HttpService
    ) { }

    async sendContactMessage(id:string, dto: SendMessageDTO): Promise<void> {
        return await this.http.post(`${environment.api}/business/contact/sendcontactmessage/${id}`, dto).toPromise();
    }
    async sendActivityMessage(id:string, dto: SendMessageDTO): Promise<void> {
        return await this.http.post(`${environment.api}/business/contact/sendactivitymessage/${id}`, dto).toPromise();
    }

     getContactMessages(id:string): Observable<MessageListItemDTO[]> {
        return this.http.get(`${environment.api}/business/contact/contactmessages/${id}`)
    }
     getActivityMessages(id:string): Observable<MessageListItemDTO[]> {
        return this.http.get(`${environment.api}/business/contact/contactactivitymessages/${id}`)
    }

}
