import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserRefundOrderLineDTO } from '../../../../data/dtos/booking/UserRefundOrderLineDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { UserOrdersDTO } from '../../../../data/dtos/booking/UserOrdersDTO';

@Injectable({
  providedIn: 'root'
})
export class UserOrdersService {

  constructor(
    private http: HttpService
  ) { }

  list(startAfter: string): Observable<UserOrdersDTO> {
    return this.http.get(`${environment.api}/user/booking/orders?startAfter=${startAfter}`);
  }

  userRefundOrderLine(dto: UserRefundOrderLineDTO): Promise<void> {
    return this.http.post(`${environment.api}/user/booking/refund`, dto).toPromise();
  }


}

