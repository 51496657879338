import { NgModule } from '@angular/core';
import { WildRoutingModule } from './wild-routing.module';
import { WildComponent } from './wild.component';
import { HomeComponent } from './pages/home/home.component';
import { environment } from 'src/environments/environment';
import { SharedModule } from 'src/shared/shared.module';
import { BrochureModule } from './modules/brochure/brochure.module';
import { SwimSpotModule } from './modules/swimspots/swimspots.module';
import { AuthModule } from './modules/auth/auth.module';
import { BusinessModule } from './modules/business/business.module';
import { UserModule } from './modules/user/user.module';
import { BookingModule } from '../booking/booking.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptorService } from 'src/shared/services/http/auth_interceptor';
import { CustomTokenAuthAndRedirectComponent } from './pages/customtokenauth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserIntegrationsModule } from 'src/userintegrations/userintegrations.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAnalytics } from '@angular/fire/analytics';
import { getAnalytics } from 'firebase/analytics';
import { provideStorage } from '@angular/fire/storage';
import { getStorage } from 'firebase/storage';
import { provideAuth } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';
import { LatestBlogComponent } from './components/latestblog/latestblog.component';
import { LogoutComponent } from './pages/logout.component';

@NgModule({
  declarations: [
    //pages
    WildComponent,
    LogoutComponent,
    HomeComponent,
    LatestBlogComponent,
    CustomTokenAuthAndRedirectComponent,

  ],
  //BROWSEWR ANIMATION NEEDS TO STAY IN ARRAY - IT CAUSES INJECTOR ERRORS WHEN USED IN THE SHARED MODULE
  //!IMPORTANT!!!! MAKE SURE BookingModule IS THE LAST IMPORTED MODULE SO BOOKINGS URLS KICK I.. IT"S LIKE A WILDCARD. 
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideStorage(() => getStorage()),
    BrochureModule,
    SwimSpotModule,
    BusinessModule,
    AuthModule,
    UserIntegrationsModule,
    UserModule,
    WildRoutingModule,
    BookingModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptorService,
    multi: true
  }],
  bootstrap: [WildComponent]
})
export class WildModule { }
