import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class DeleteActivityService {

    constructor(
        private http: HttpService
    ) { }

    async deleteActivity(activtiyId: string): Promise<void> {
        await this.http.delete(`${environment.api}/business/activities/${activtiyId}`).toPromise();
    }

}
