import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SourceDocumentType } from '../../../data/enums/source_document_type';
import { ImageModel } from '../../../data/models/images_model';
import { ErrorModel } from '../../../data/models/error_model';
import { ImagesService } from 'src/shared/services/images/images_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  selector: 'app-filemanagerfiles',
  templateUrl: './filemanagerfiles.component.html',
  styleUrls: ['./filemanagerfiles.component.scss']
})
export class FilemanagerfilesComponent implements OnInit {

  @Input() sourceDocumentId: string;
  @Input() sourceDocumentImageType: SourceDocumentType;
  @Input() limit: number;
  @Output() onComplete = new EventEmitter<ImageModel[]>();
  loading: boolean = false;
  @Input() selectedImages: ImageModel[] = [];

  required: boolean;

  constructor(private notify: NotifyService, private imageService: ImagesService, private modalService: ModalService, private imagesService: ImagesService) { }

  images$: Observable<ImageModel>;
  public error: ErrorModel = null;
  imageUrl = environment.imagesUrl;

  ngOnInit(): void {
    this.images$ = this.imageService.getImages(this.sourceDocumentImageType, this.sourceDocumentId)
      .pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
  }

  waitAndReload(event, image) {
    this.imagesService.waitAndReload(event, image);
  }

  imageLoaded(event, image) {
    this.imagesService.imageLoaded(event, image);
  }

  selectImage(image: ImageModel) {
    if (this.isInSelectedImages(image)) {
      this.selectedImages = this.selectedImages.filter(i => i.image != image.image)
    } else if (this.limit == 1) {
      this.selectedImages = [image]
    } else {
      if (this.selectedImages.length < this.limit) {
        this.selectedImages.push(image);
      } else {
        this.notify.notify("Max Reached", "Please select up to " + this.limit + " image(s)", NotifyType.WARNING);
      }
    }
  }

  isInSelectedImages(image: ImageModel): boolean {
    return this.selectedImages.some(i => i.image == image.image);
  }

  select() {
    if (this.selectedImages == null || this.selectedImages.length == 0) {
      this.notify.notify("Select Image", "Please select an image and try again", NotifyType.WARNING);
    } else {
      this.onComplete.emit(this.selectedImages);
      this.modalService.hide();
    }
  }

  cancel() {
    this.modalService.hide();
  }

}
