<div *ngIf="swimspots$">
    <ng-container *ngIf="swimspots$ | async as swimspots; else loadingOrError">
        <div *ngIf="!swimspots || !swimspots.swimspots || swimspots.swimspots.length == 0; else swimspotresults">
            no swimspots found
        </div>
        <ng-template #swimspotresults>
            <div class="bg-white w-full">
                <h1 class="wild-header px-3 pt-12">Swim Spots near {{query}}</h1>
                <h2 class="px-3 mt-4 text-xs">Can't find what your looking for?</h2>
                <p (click)="addSwimSpot()" class="wild-link px-3">Add a Swim Spot in {{query}}</p>
                <p class="text-xs text-gray-500 px-3">Unlock forecasting and a grow the community</p>
                <app-searchresults-wrapper>
                    <li [canonical]="swimspot.canonical" [type]="SearchResultType.SWIMSPOT" [id]="swimspot.id"
                        (onClick)="selected($event)" [header]="swimspot.name" [description]="swimspot.description"
                        [sublinetwo]="swimspot.distance + 'km' "
                        [subline]="swimspot.town != null ? swimspot.town : swimspot.area != null ? swimspot.area : swimspot.locality != null ? swimspot.locality : swimspot.county"
                        [image]="'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/images%2Fresized%2F' + swimspot.image + '_640x640.jpeg?alt=media'"
                        *ngFor="let swimspot of swimspots.swimspots" app-searchresults-result></li>
                </app-searchresults-wrapper>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="retry()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</div>
<div *ngIf="!swimspots$ && error">
    <app-retry [hideretry]="true" [code]="error.code" [description]="error.description" [header]="error.message">
    </app-retry>
</div>