import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private afs: Firestore,
    private firestore: FirestoreService,
  ) { }

  getWithWhere(collectionString: string, orderBy: string, property: string, propertyValue: string, limitNumber: number): any {
    return query(collection(this.afs, collectionString), where(property, "==", propertyValue), limit(limitNumber));

  }

  get(collectionString: string, orderByString: string): any {
    return query(collection(this.afs, collectionString), orderBy(orderByString, "desc"));
  }


  //Example list
  // list(): Observable<Waterway[]>{
  //   var collection = this.crud.get(this.collection, "name");  
  //   return collection.snapshotChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data() as Waterway;
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     }))
  //   );
  // }

  async create(collection: string, data: any) {
    await this.firestore.create(collection, data)
  }

  read(collection: string, id: string): any {
    return this.firestore.getDocumentById(collection, id);
  }

  async update(collection: string, data: any) {
    if (!data?.id)
      throw Error("No ID found for update")
    await this.firestore.update(data?.id, collection, data)
  }

  error(error: any) {
    var errorCode = error.code;
    var errorMessage = error.message;
    throw Error(errorCode + ": " + errorMessage);
  }

}
