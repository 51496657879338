import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { SendMessageService } from './send_message_service';
import { ErrorModel } from 'src/data/models/error_model';
import { SendMessageDTO } from 'src/data/dtos/messages/SendMessageDTO';

@Component({
  templateUrl: './sendmessage.component.html'
})
export class SendMessageComponent implements OnInit {

  @Input() header:string;
  @Input() id:string;
  @Input() contact:boolean;
  @Input() activity:boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private notifyService: NotifyService,
    private sendMessageService: SendMessageService,
    private modalService: ModalService,
  ) { }

  form!: UntypedFormGroup;
  loading: boolean;

  public error: ErrorModel = null;

  ngOnInit(): void {
    this.form = this.fb.group({
      subject: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]),
      title: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]),
      body: new UntypedFormControl('', [Validators.required, Validators.minLength(25), Validators.maxLength(2000)]),
    });
  }

  onSubmit(data) {
    this.loading = true;
    var dto: SendMessageDTO = {
      subject: data.subject,
      title: data.title,
      body: data.body,
    }
    if(this.contact == true){
      this.sendMessageService.sendContactMessage(this.id, dto)
      .then(async () => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
    }else if(this.activity == true){
      this.sendMessageService.sendActivityMessage(this.id, dto)
      .then(async () => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
    }else{
      this.resetForm();
      this.handlerError("Unknown Message Sending Type");
    }
  }

  handlerError(message: string) {
    this.notifyService.notify("Message(s)", message, NotifyType.ERROR);
  }

  async next() {
    this.notifyService.notify("Message(s) Sent", "", NotifyType.SUCCESS);
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}