export enum WeatherClassification {
    UNKNOWN,
    CLEARDAY,
    CLEARNIGHT,
    FEWCLOUDSDAY,
    FEWCLOUDSNIGHT,
    SCATTEREDCLOUDSDAY,
    SCATTEREDCLOUDSNIGHT,
    BROKENCLOUDSDAY,
    BROKENCLOUDSNIGHT,
    SHOWERRAINDAY,
    SHOWERRAINNIGHT,
    RAINDAY,
    RAINNIGHT,
    THUNDERSTORMDAY,
    THUNDERSTORMNIGHT,
    SNOWDAY,
    SNOWNIGHT,
    MISTDAY,
    MISTNIGHT,
}