import { Component, Input, OnInit} from '@angular/core';
import { FeatureColour, FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-animatedhero',
  templateUrl: './animatedhero.component.html'
})
export class AnimatedHeroComponent implements OnInit {
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
  rotatingText:string[]=[]; 
  ngOnInit(): void {
    for (let i = 0; i < this.data.data.length; i++) {
      const data = this.data.data[i];
      this.rotatingText.push(data.title);
    }
  } 
}
