import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const organisedContacts: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.BIGIMAGEFEATURE,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcrm%2Fcontact-list.png?alt=media&token=06461510-fe4c-41de-8700-7198e92b0387",
  title: "Organised Contacts",
  subtitle: "Built In CRM",
  paragraph: "",
  data:[
    {
      icon: "users", title: "Unlimited.", subtitle: "All your customers."
    },
    {
      icon: "first-aid", title: "Medical Profiles.", subtitle: "For all customers."
    },
    {
      icon: "edit", title: "Notes.", subtitle: "Unlimited audited notes."
    },
  ]
}