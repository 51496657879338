import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CountryCodes } from '../../../data/enums/country_codes';
import { BusinessPublicModel } from '../../../data/models/business_public_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { BusinessStatus } from '../../../data/enums/business_status';
import { ImagesService } from 'src/shared/services/images/images_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { BusinessMode } from '../../../data/enums/business_mode';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { UtilService } from 'src/shared/services/util/util_service';
import { BookingService } from 'src/booking/services/booking_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';

@Component({
  selector: 'app-bookinghome',
  templateUrl: './bookinghome.component.html'
})
export class BookinghomeComponent implements OnInit {

  business: BusinessPublicModel;
  BusinessStatus = BusinessStatus
  imageUrl = environment.imagesUrl;
  CountryCodes = CountryCodes;
  BusinessMode = BusinessMode;
  preview: boolean = false;
  activeBusinessId: string = "";
  isAppUser: boolean = false;
  isWidgetUser: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private imagesService: ImagesService,
    private seo: SeoService,
    private util: UtilService,
    private storageService: StorageService,
    private notifyService: NotifyService,
    private modalService: ModalService,
    private bookingService: BookingService,
    private businessService: BusinessService,
    private navigate: NavigationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isAppUser = this.auth.isAppUser;
    this.isWidgetUser = this.auth.isWidgetUser;
    this.activeBusinessId = this.businessService.businessId;
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params.preview == 1)
        this.preview = true;
    });
    this.business = (this.route.snapshot.data as any)?.business;
    if (this.business.bookingurl != undefined && this.business.bookingurl != "" && this.business.bookingurl.startsWith("www"))
      this.business.bookingurl = "https://" + this.business.bookingurl

    if (!this.business)
      this.navigate.navigate(WildNavigationType.NOTFOUND)
    this.loadSeo();
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      params;
      if (params.membershipsubscribed != undefined && params.membershipsubscribed != null && (params.membershipsubscribed == "1" || params.membershipsubscribed == 1)) {
        this.membershipSubscribed();
      }
    });
  }

  membershipSubscribed(){
    var settings: ModalModel = {
      header: "Membership Created",
      description: "Your membership is all set up and you can now book sessions with your new pricing.",
      design: ModalDesign.CENTRAL,
      type: ModalType.SUCCESS,
      ctaonetext: "Close",
      hidectatwo: true,
      ctaonetextclick: () => {
        
      },
    }
    this.modalService.show(settings);
  }


  loadSeo() {
    if (this.business) {
      if (this.business?.uploadedprofilepic != null) {
        var src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/images%2Fresized%2F" + this.business?.uploadedprofilepic?.image + "_1080x1080.jpeg?alt=media";
      } else {
        var src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839";
      }
      this.seo.update(this.business?.name ?? "", this.business?.description ?? "", this.business?.name ?? "" + ", swimspot, open water swimming", src);
    }
  }

  openMembersList() {
    if (this.auth.isAuthedIfNotShowPopUp()) {
      this.bookingService.openMembersList(this.business.id, this.business.name);
    }
  }



  openOrganiser() {
    this.navigate.navigate(WildNavigationType.DASHBOARDHOME);
  }

  openServices() {
    this.navigate.navigate(WildNavigationType.BUSINESSSERVICES);
  }

  openBrand() {
    this.navigate.navigate(WildNavigationType.BRAND);
  }

  openArea() {
    this.navigate.navigate(WildNavigationType.BUSINESSAREA);
  }

  openAddress() {
    this.navigate.navigate(WildNavigationType.BUSINESSLOCATION);
  }

  openSettings() {
    this.navigate.navigate(WildNavigationType.SETTINGS);
  }

  waitAndReloadBanner(event, image) {
    this.imagesService.waitAndReload(event, image);
  }

  imageLoadedBanner(event, image) {
    this.imagesService.imageLoaded(event, image);
  }

  waitAndReloadProfile(event, image) {
    this.imagesService.waitAndReload(event, image);
  }

  imageLoadedProfile(event, image) {
    this.imagesService.imageLoaded(event, image);
  }

}
