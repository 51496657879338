import { EventEmitter, Injectable } from '@angular/core';
import { TabModel } from 'src/shared/components/tabs/tab_model';

@Injectable({
	providedIn: 'root'
})
export class TabService {

    tabSets:TabSet[] = [];
    tabChanged: EventEmitter<string>;

	constructor() {}

    registerTabsAndEmitter(tabsId:string, tabs:TabModel[],tabChanged:EventEmitter<string>){
        this.tabSets.push({
            tabsId:tabsId,
            tabs:tabs
        })
        this.tabChanged = tabChanged;
    }

    unregisterTabs(tabsId:string){
        this.tabSets = this.tabSets.filter(function(tabSet) {
            return tabSet.tabsId !== tabsId
        })
    }

    changeTab(tabsId:string, id:string){
        var tabs = this.getTabs(tabsId);
        var activeTab = tabs.find(t => t.active == true);
        activeTab.active = false;
        var newTab = tabs.find(t => t.id == id);
        newTab.active = true;
        this.tabChanged.emit(id);
    }

    getTabs(tabsId:string):TabModel[]{
        return this.tabSets.find(t => t.tabsId == tabsId)?.tabs;
    }

}

interface TabSet{
    tabsId:string,
    tabs:TabModel[]
}
