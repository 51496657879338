import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { SwimSpotSearchComponent } from '../../../../../../shared/components/swimspotsearch.component';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { BusinessSwimSpotService } from '../../../services/businessswimspot/businessswimspot_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { environment } from 'src/environments/environment';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ActivatedRoute, Params } from '@angular/router';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  selector: 'app-businessswimspots',
  templateUrl: './businessswimspots.component.html',
  styleUrls: ['./businessswimspots.component.scss']
})
export class BusinessswimspotsComponent implements OnInit {

  public error: ErrorModel = null;
  swimspots$: Observable<any>;
  imagesUrl = environment.imagesUrl;
  onboarding: boolean = false;

  constructor(
    private modalService: ModalService,
    private navigation: NavigationService,
    private notifyService: NotifyService,
    private activatedRoute: ActivatedRoute,
    private businessSwimSpots: BusinessSwimSpotService) { }

  ngOnInit(): void {
    this.load();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => { if (params?.onboarding == 1) this.onboarding = true; })
  }

  load() {
    this.swimspots$ = null;
    this.swimspots$ = this.businessSwimSpots.get().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  new() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: SwimSpotSearchComponent,
      close: true,
      componentInputs: [],
      componentOutputs: [
        {
          outputName: "swimSpotId",
          func: async ($event: string) => {
            this.add($event);
          }
        }
      ]
    }
    this.modalService.show(settings);
  }

  openSwimSpot(swimspotId: string) {
    this.remove(swimspotId);
  }

  remove(swimSpotId: string) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: `Are you sure you want to remove this swim spot?`,
      header: "Remove Swim Spot?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemove(swimSpotId);
      }
    }
    this.modalService.show(settings);
  }

  backToDashboard() {
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
  }

  processRemove(swimSpotId: string) {
    var settings: ModalModel = {
      header: "Removing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);

    this.businessSwimSpots.remove(swimSpotId)
      .then(async () => {
        this.notifyService.notify("Removed", "Swim Spot Removed from Business", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  add(swimSpotId: string) {
    var settings: ModalModel = {
      header: "Adding Swim Spot",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    this.businessSwimSpots.add(swimSpotId)
      .then(async () => {
        this.notifyService.notify("Added Swim Spot", "Swim Spot added to business", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

}
