<app-wild-header></app-wild-header>
<div class="bg-white" style="min-height:80vh">
    <div>
        <!-- MOBILE FILTERS START -->
        <div *ngIf="showFilters" class="fixed inset-0 flex z-40 lg:hidden" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>
            <div
                class="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div class="px-4 flex items-center justify-between">
                    <h2 class="text-lg font-medium text-gray-900">Filters</h2>
                    <button (click)="showFilters = false" type="button"
                        class="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400">
                        <span class="sr-only">Close menu</span>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <form class="mt-4 border-t border-gray-200">
                    <div *ngFor="let filter of filters"
                        [ngClass]="{'border-t border-gray-200 px-4 py-6':showFilter(filter),'hidden':!showFilter(filter)}">
                        <h3 class="-mx-2 -my-3 flow-root">
                            <!-- Expand/collapse section button -->
                            <button (click)="filter.expanded = !filter.expanded" type="button"
                                class="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500"
                                aria-controls="filter-section-mobile-0" aria-expanded="false">
                                <span class="font-medium text-gray-900"> {{filter.name}} </span>
                                <span class="ml-6 flex items-center">

                                    <svg *ngIf="!filter.expanded" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <svg *ngIf="filter.expanded" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </span>
                            </button>
                        </h3>
                        <div *ngIf="filter.expanded" class="pt-6" id="filter-section-mobile-0">
                            <div class="space-y-6">
                                <div *ngFor="let option of filter.options" class="flex items-center">
                                    <input (click)="toggleFilter(option)" [id]="option.id" value="white" type="checkbox"
                                        [checked]="option.enabled"
                                        class="h-4 w-4 border-gray-300 rounded text-blue-900 focus:ring-blue-900">
                                    <label [for]="option.id" class="ml-3 min-w-0 flex-1 text-gray-500">
                                        {{option.name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- MOBILE FILTERS END -->
        <main class=" mx-auto px-4 sm:px-6 lg:px-8">
            <div
                class="relative z-10 flex flex-col sm:flex-row items-baseline justify-between pt-1 pb-1 border-b border-gray-200">
                <!-- SEARCH INPUT BOX START -->
                <div class="py-4 w-full textBoxSize">
                    <div *ngIf="showSearch" class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
                        aria-hidden="true"></div>
                    <div
                        class="border-2 border-blue-800 transform divide-y divide-gray-100 relative rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5 transition-all w-full z-20">
                        <div class="relative">
                            <svg class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd" />
                            </svg>
                            <!-- SEARCH INPUT BOX FORM START -->
                            <form [formGroup]="form">
                                <input autocomplete="off" formControlName="search" type="search" name="search"
                                    id="search" type="search"
                                    class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Search..." role="combobox" aria-expanded="false"
                                    aria-controls="options">
                            </form>
                            <!-- SEARCH INPUT BOX FORM END -->
                        </div>
                        <!-- SEARCH INPUT BOX RESULTS START -->
                        <ul *ngIf="showSearch && searchResults && searchResults?.results?.length > 0"
                            class="max-h-96 scroll-py-3 overflow-y-auto p-3 bg-white absolute z-10 rounded-lg"
                            style="top:52px" id="options" role="listbox">
                            <li (click)="chooseResult(result)" *ngFor="let result of searchResults?.results"
                                class="group flex cursor-pointer hover:bg-gray-50 select-none rounded-xl p-3 "
                                id="option-1" role="option" tabindex="-1">
                                <div *ngIf="result.type == LocationResultType.PLACE"
                                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg wild-bg-primary text-white">
                                    <fa-icon icon="map-pin">
                                    </fa-icon>
                                </div>
                                <div *ngIf="result.swimSpotType == WaterwayType.LAKE || result.swimSpotType == WaterwayType.COASTAL || result.swimSpotType == WaterwayType.RIVER || result.type ==  LocationResultType.BUSINESS"
                                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg wild-bg-primary text-white overflow-hidden">
                                    <img [src]="result?.image != null && result?.image != '' ? imagesUrl + result.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                                        alt="" class="object-cover pointer-events-none group-hover:opacity-75">
                                </div>
                                <div class="ml-4 flex-auto">
                                    <p class="text-sm font-medium text-gray-700">{{result.name}}</p>
                                    <p class="text-sm text-gray-500">
                                        <span
                                            *ngIf="result.type == LocationResultType.PLACE">{{result.locationDescription}}</span>
                                        <span *ngIf="result.swimSpotType == WaterwayType.LAKE"> Lake in
                                            {{result.locationDescription}}</span>
                                        <span *ngIf="result.swimSpotType == WaterwayType.COASTAL">Beach in
                                            {{result.locationDescription}}</span>
                                        <span *ngIf="result.swimSpotType == WaterwayType.RIVER">River in
                                            {{result.locationDescription}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <!-- SEARCH INPUT BOX RESULTS END -->
                        <!-- SEARCH INPUT BOX NO RESULTS START -->
                        <div *ngIf="showSearch && searchResults && searchResults?.results?.length == 0"
                            class="py-14 px-6 text-center text-sm sm:px-14">
                            <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p class="mt-4 font-semibold text-gray-900">No results found</p>
                            <p class="mt-2 text-gray-500">Please try a different search.</p>
                        </div>
                        <!-- SEARCH INPUT BOX NO RESULTS END -->
                    </div>
                </div>
                <!-- SEARCH INPUT BOX END -->
                <!-- SEARCH LOCATION TITLE FILETERS AND SORTING OPTIONS START -->
                <div class="flex-1 flex items-center justify-between md:justify-end w-full py-3 sm:py-0">
                    <!-- MOBILE TITLE START -->
                    <div class="sm:hidden w-1/2 sm:3/4 md:w-48 md-ml-12">
                        <h4 class="font-medium text-lg text-ellipsis truncate"><span
                                class="hidden sm:inline-block">Results near</span>
                            {{searchLocation}}</h4>
                    </div>
                    <!-- MOBILE TITLE END -->
                    <!-- SORTING OPTIONS START -->
                    <!-- <div class="relative inline-block text-left -m-2 ml-5 sm:ml-7">
                        <div>
                            <button (click)="showSortOptions = !showSortOptions" type="button"
                                class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                                id="menu-button" aria-expanded="false" aria-haspopup="true">
                                Sort
                                <svg class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                        <div *ngIf="showSortOptions"
                            class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="py-1" role="none">
                                <a href="#" class="font-medium text-gray-900 block px-4 py-2 text-sm" role="menuitem"
                                    tabindex="-1" id="menu-item-0"> Most Popular </a>

                                <a href="#" class="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                    id="menu-item-1"> Best Rating </a>

                                <a href="#" class="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                    id="menu-item-2"> Newest </a>

                                <a href="#" class="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                    id="menu-item-3"> Price: Low to High </a>

                                <a href="#" class="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                    id="menu-item-4"> Price: High to Low </a>
                            </div>
                        </div>
                    </div> -->
                    <!-- SORTING OPTIONS END -->
                    <!-- MAP LIST GRID TOGGLE START -->
                    <!-- <button type="button" class="p-2 -m-2 ml-5 sm:ml-7 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">View grid</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                    </button> -->
                    <!-- MAP LIST GRID TOGGLE END -->
                    <!-- FILTER TOGGLE START -->
                    <button (click)="showFilters = !showFilters" type="button"
                        class="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden">
                        <span class="sr-only">Filters</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                    <!-- FILTER TOGGLE END -->
                </div>
                <!-- SEARCH LOCATION TITLE FILETERS AND SORTING OPTIONS END -->
            </div>

            <section aria-labelledby="products-heading" class="pt-6 pb-24">
                <div class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                    <!-- DESKTOP FILTERS START -->
                    <form class="hidden lg:block">
                        <h3 class="text-sm font-medium text-gray-900 space-y-4 pb-6 border-b border-gray-200">Filters
                        </h3>
                        <div *ngFor="let filter of filters"
                            [ngClass]="{'border-b border-gray-200 py-6':showFilter(filter),'hidden':!showFilter(filter)}">
                            <h3 class="-my-3 flow-root">
                                <button (click)="filter.expanded = !filter.expanded" type="button"
                                    class="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                                    aria-controls="filter-section-0" aria-expanded="false">
                                    <span class="font-medium text-gray-900"> {{filter.name}} </span>
                                    <span class="ml-6 flex items-center">
                                        <svg *ngIf="!filter.expanded" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <svg *ngIf="filter.expanded" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </h3>
                            <div *ngIf="filter.expanded" class="pt-6" id="filter-section-0">
                                <div class="space-y-4">
                                    <div *ngFor="let option of filter.options" class="flex items-center">
                                        <input (click)="toggleFilter(option)" [id]="option.id" value="white"
                                            type="checkbox" [checked]="option.enabled"
                                            class="h-4 w-4 border-gray-300 rounded text-blue-900 focus:ring-blue-900">
                                        <label [for]="option.id"
                                            class="ml-3 text-sm text-gray-600">{{option.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- DESKTOP FILTERS END -->
                    <!-- RESULTS GRID START -->
                    <div class="lg:col-span-3">
                        <div *ngIf="searchResults$">
                            <ng-container *ngIf="searchResults$ | async as searchResults; else loadingOrError">
                                <div *ngIf="!searchResults || searchResults.found == 0; else results">
                                    <app-empty icon="search" header="No Results" description="Adjust your search">
                                    </app-empty>
                                </div>
                                <ng-template #results>
                                    <!-- DESKTOP TITLE START -->
                                    <div *ngIf="searchLocation" class="hidden sm:block mb-4">
                                        <h4 class="font-medium text-lg text-ellipsis truncate">Results near
                                            {{searchLocation}}</h4>
                                    </div>
                                    <!-- DESKTOP TITLE END -->
                                    <ul role="list"
                                        class="grid grid-cols-2 xs:grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-5 xl:gap-x-8">
                                        <app-usersearchresult *ngFor="let result of searchResults.results"
                                            [result]="result" (onClick)="chooseResult()">
                                        </app-usersearchresult>
                                    </ul>
                                </ng-template>
                            </ng-container>
                            <ng-template #loadingOrError>
                                <ng-container *ngIf="error; else loading">
                                    <app-wrapper>
                                        <app-retry [code]="error.code" [description]="error.description"
                                            [header]="error.message" (onRetry)="load()">
                                        </app-retry>
                                    </app-wrapper>
                                </ng-container>
                                <ng-template #loading>
                                    <app-loader [disablescreenheight]="true"></app-loader>
                                </ng-template>
                            </ng-template>
                        </div>
                    </div>
                    <!-- RESULTS GRID END -->
                </div>
            </section>
        </main>
    </div>
</div>

<app-footer></app-footer>