import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilemanagerComponent } from 'src/shared/components/filemanager/filemanager.component';
import { SourceDocumentType } from '../../../../../../data/enums/source_document_type';
import { ImageModel } from '../../../../../../data/models/images_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from '../../../services/business_service/business_service';
import { BusinessPublicModel } from '../../../../../../data/models/business_public_model';
import { UtilService } from 'src/shared/services/util/util_service';
import { ActivatedRoute, Params } from '@angular/router';
import { BusinessMode } from '../../../../../../data/enums/business_mode';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  constructor(
    private businessService: BusinessService,
    private fb: UntypedFormBuilder,
    private util: UtilService,
    private activatedRoute: ActivatedRoute,
    private notify: NotifyService,
    private modalService: ModalService,
    private navigation: NavigationService) { }

  @ViewChild('iframe') iframe: ElementRef<HTMLElement>;
  @ViewChild('formwrapper') formwrapper: ElementRef<HTMLElement>;

  eventsSubjectBannerImage: Subject<any> = new Subject<any>();
  eventsSubjectProfileImage: Subject<any> = new Subject<any>();
  domain: string;
  BusinessMode = BusinessMode;
  onboarding: boolean = false;
  business$: Observable<BusinessPublicModel>;
  tags = [
    "Swimming",
    "Ice Swimming",
    "SUP",
    "Water Skiing",
    "Wake Boarding",
    "Knee Boarding",
    "Surfing",
    "Body Boarding",
    "Body Surfing",
    "Canoeing",
    "Kayaking",
    "Wind Surfing",
    "Kite Boarding",
    "Sailing",
    "Skim Boarding",
    "Scuba Diving",
    "Snorkeling",
    "White Water Rafting",
    "Free Diving",
    "Canyoning",
    "Hydro foil",
    "Yachting",
    "Fly Boarding",
    "Rowing",
    "Ice Diving",
    "Swim Run",
    "Walking",
    "Coasteering",
    "Lakes",
    "Coastal",
    "Rivers",
    "Lido",
    "Pool",
    "121 Coaching",
    "Group Coaching",
    "Pool to Open Water",
    "Beginner",
    "Triathlon",
    "Adults",
    "Children",
  ];
  selectedTags = [];


  updateTags(business: BusinessPublicModel) {

    if (business.swimming == true)
      this.selectedTags.push("Swimming");

    if (business.iceswimming == true)
      this.selectedTags.push("Ice Swimming");

    if (business.supping == true)
      this.selectedTags.push("SUP");

    if (business.waterskiing == true)
      this.selectedTags.push("Water Skiing");

    if (business.wakeboarding == true)
      this.selectedTags.push("Wake Boarding");

    if (business.kneeboarding == true)
      this.selectedTags.push("Knee Boarding");

    if (business.surfing == true)
      this.selectedTags.push("Surfing");

    if (business.bodyboarding == true)
      this.selectedTags.push("Body Boarding");

    if (business.bodysurfing == true)
      this.selectedTags.push("Body Surfing");

    if (business.canoeing == true)
      this.selectedTags.push("Canoeing");

    if (business.kayaking == true)
      this.selectedTags.push("Kayaking");

    if (business.windsurfing == true)
      this.selectedTags.push("Wind Surfing");

    if (business.kiteboarding == true)
      this.selectedTags.push("Kite Boarding");

    if (business.sailing == true)
      this.selectedTags.push("Sailing");

    if (business.skimboarding == true)
      this.selectedTags.push("Skim Boarding");

    if (business.scubadiving == true)
      this.selectedTags.push("Scuba Diving");

    if (business.snorkeling == true)
      this.selectedTags.push("Snorkeling");

    if (business.whitewaterrafting == true)
      this.selectedTags.push("White Water Rafting");

    if (business.freediving == true)
      this.selectedTags.push("Free Diving");

    if (business.canyoning == true)
      this.selectedTags.push("Canyoning");

    if (business.hydrofoil == true)
      this.selectedTags.push("Hydro foil");

    if (business.yachting == true)
      this.selectedTags.push("Yachting");

    if (business.flyboarding == true)
      this.selectedTags.push("Fly Boarding");

    if (business.rowing == true)
      this.selectedTags.push("Rowing");

    if (business.icediving == true)
      this.selectedTags.push("Ice Diving");

    if (business.swimrun == true)
      this.selectedTags.push("Swim Run");

    if (business.walking == true)
      this.selectedTags.push("Walking");

    if (business.coasteering == true)
      this.selectedTags.push("Coasteering");

    if (business.lakes == true)
      this.selectedTags.push("Lakes");

    if (business.rivers == true)
      this.selectedTags.push("Rivers");

    if (business.coastal == true)
      this.selectedTags.push("Coastal");

    if (business.lido == true)
      this.selectedTags.push("Lido");

    if (business.pool == true)
      this.selectedTags.push("Pool");

    if (business.onetwoonecoaching == true)
      this.selectedTags.push("121 Coaching");
    if (business.groupcoaching == true)
      this.selectedTags.push("Group Coaching");

    if (business.pooltoopenwater == true)
      this.selectedTags.push("Pool to Open Water");

    if (business.beginner == true)
      this.selectedTags.push("Beginner");

    if (business.triathlon == true)
      this.selectedTags.push("Triathlon");

    if (business.adults == true)
      this.selectedTags.push("Adults");

    if (business.children == true)
      this.selectedTags.push("Children");

  }


  public error: ErrorModel = null;
  bannerOptions: ImageModel[] = environment.bannerOptions;
  profilepicOptions: ImageModel[] = environment.profilepicOptions;
  form: UntypedFormGroup;
  loading: boolean = false;
  iFrameQueryParam: string;

  ngOnInit(): void {
    this.load();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => { if (params?.onboarding == 1) this.onboarding = true; })
    this.domain = environment.domain;
    this.form = this.fb.group({
      url: new UntypedFormControl('', [Validators.required]),
      tags: new UntypedFormControl('', []),
      bookingurl: new UntypedFormControl('', [this.util.websiteValidator]),
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
      description: new UntypedFormControl('', [Validators.maxLength(2000)]),
      website: new UntypedFormControl('', [this.util.websiteValidator]),
      facebook: new UntypedFormControl('', [this.util.facebookPageValidator]),
      twitter: new UntypedFormControl('', [this.util.twitterValidator]),
      instagram: new UntypedFormControl('', [this.util.instagramValidator]),
      youtube: new UntypedFormControl('', [this.util.youTubeChannelValidator]),
      email: new UntypedFormControl('', [Validators.email]),
      phone: new UntypedFormControl('', [this.util.phoneValidator]),
      whatsapp: new UntypedFormControl('', [this.util.whatsappValidator]),
      uploadedbannerimage: new UntypedFormControl('', []),
      uploadedprofilepic: new UntypedFormControl('', []),
    });
    this.loadIframe();
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(1000)
      ).subscribe((result) => {
        if (this.form.status == "VALID")
          this.onSubmit(this.form.value);
      });
    }, 1000);
  }

  load() {
    this.business$ = null;
    this.business$ = this.businessService.getPublicBusiness().pipe(
      take(1),
      tap((business) => this.updateTags(business)),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    )
  }

  backToDashboard() {
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
  }

  loadIframe() {
    this.iFrameQueryParam = (new Date()).getTime().toString();
  }

  open(url: string) {
    window.open(url);
  }


  async onSubmit(data: any) {
    //Clear uploaded images if user has selected presets
    if (data.bannerimage != "" && data.bannerimage != null)
      data.uploadedbannerimage = null;
    if (data.profilepic != "" && data.profilepic != null)
      data.uploadedprofilepic = null;
    var processedData = data;



    //TAGS START
    processedData.swimming = data.tags.some(tag => tag == "Swimming"),
      processedData.iceswimming = data.tags.some(tag => tag == "Ice Swimming"),
      processedData.supping = data.tags.some(tag => tag == "SUP"),
      processedData.waterskiing = data.tags.some(tag => tag == "Water Skiing"),
      processedData.wakeboarding = data.tags.some(tag => tag == "Wake Boarding"),
      processedData.kneeboarding = data.tags.some(tag => tag == "Knee Boarding"),
      processedData.surfing = data.tags.some(tag => tag == "Surfing"),
      processedData.bodyboarding = data.tags.some(tag => tag == "Body Boarding"),
      processedData.bodysurfing = data.tags.some(tag => tag == "Body Surfing"),
      processedData.canoeing = data.tags.some(tag => tag == "Canoeing"),
      processedData.kayaking = data.tags.some(tag => tag == "Kayaking"),
      processedData.windsurfing = data.tags.some(tag => tag == "Wind Surfing"),
      processedData.kiteboarding = data.tags.some(tag => tag == "Kite Boarding"),
      processedData.sailing = data.tags.some(tag => tag == "Sailing"),
      processedData.skimboarding = data.tags.some(tag => tag == "Skim Boarding"),
      processedData.scubadiving = data.tags.some(tag => tag == "Scuba Diving"),
      processedData.snorkeling = data.tags.some(tag => tag == "Snorkeling"),
      processedData.whitewaterrafting = data.tags.some(tag => tag == "White Water Rafting"),
      processedData.freediving = data.tags.some(tag => tag == "Free Diving"),
      processedData.canyoning = data.tags.some(tag => tag == "Canyoning"),
      processedData.hydrofoil = data.tags.some(tag => tag == "Hydro foil"),
      processedData.yachting = data.tags.some(tag => tag == "Yachting"),
      processedData.flyboarding = data.tags.some(tag => tag == "Fly Boarding"),
      processedData.rowing = data.tags.some(tag => tag == "Rowing"),
      processedData.icediving = data.tags.some(tag => tag == "Ice Diving"),
      processedData.swimrun = data.tags.some(tag => tag == "Swim Run"),
      processedData.walking = data.tags.some(tag => tag == "Walking"),
      processedData.coasteering = data.tags.some(tag => tag == "Coasteering"),
      processedData.rivers = data.tags.some(tag => tag == "Rivers"),
      processedData.lakes = data.tags.some(tag => tag == "Lakes"),
      processedData.pool = data.tags.some(tag => tag == "Pool"),
      processedData.coastal = data.tags.some(tag => tag == "Coastal"),
      processedData.lido = data.tags.some(tag => tag == "Lido"),
      processedData.onetwoonecoaching = data.tags.some(tag => tag == "121 Coaching"),
      processedData.groupcoaching = data.tags.some(tag => tag == "Group Coaching"),
      processedData.pooltoopenwater = data.tags.some(tag => tag == "Pool to Open Water"),
      processedData.beginner = data.tags.some(tag => tag == "Beginner"),
      processedData.triathlon = data.tags.some(tag => tag == "Triathlon"),
      processedData.adults = data.tags.some(tag => tag == "Adults"),
      processedData.children = data.tags.some(tag => tag == "Children"),

      //TAGS END
      await this.updateBrand(data);
  }

  async updateBrand(data: any) {
    this.loading = true;
    await this.businessService.updateBrand(data)
      .then(() => {
        //TODO - update profile pic in cookie based on uploaded pic
        if (data.profilepic != undefined && data.profilepic != null && data.profilepic != "")
          this.businessService.updateActiveProfileImage(data.profilepic);
        if (data.uploadedprofilepic != undefined && data.uploadedprofilepic != null)
          this.businessService.updateActiveProfileImage('https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/images%2Fresized%2F' + data.uploadedprofilepic.image + '_100x100.jpeg?alt=media');
        if (data.name != undefined && data.name != null && data.name != "")
          this.businessService.updateActiveProfileName(data.name);
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  next() {
    this.notify.notify("Updated", "Brand has been updated", NotifyType.SUCCESS);
    this.loadIframe();

    // this.formwrapper.nativeElement.scrollTo(0,0);
    // this.iframe.nativeElement.scrollTo(0,0);
    // this.sideMenuService.scrollTop();
    // window.scrollTo(0,0);
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  resetForm() {
    this.loading = false;
  }

  uploadBannerPic(currentImage: ImageModel) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.COMPONENT,
      component: FilemanagerComponent,
      componentInputs: [{
        inputName: "sourceDocumentId",
        value: this.businessService.businessId
      },
      {
        inputName: "sourceDocumentImageType",
        value: SourceDocumentType.BUSINESS
      },
      {
        inputName: "limit",
        value: 1
      },
      {
        inputName: "uploadOnly",
        value: false
      }],
      componentOutputs: [
        {
          outputName: "onComplete",
          func: async ($event: any) => {
            await this.updateBannerImage($event);
          }
        }
      ],
      close: true,
      large: true
    }
    this.modalService.show(settings);
  }


  uploadProfilePic(currentImage: ImageModel) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.COMPONENT,
      component: FilemanagerComponent,
      componentInputs: [{
        inputName: "sourceDocumentId",
        value: this.businessService.businessId
      },
      {
        inputName: "sourceDocumentImageType",
        value: SourceDocumentType.BUSINESS
      },
      {
        inputName: "limit",
        value: 1
      },
      {
        inputName: "uploadOnly",
        value: false
      }],
      componentOutputs: [
        {
          outputName: "onComplete",
          func: async ($event: any) => {
            await this.updateProfileImage($event);
          }
        }
      ],
      close: true,
      large: true
    }
    this.modalService.show(settings);
  }


  async updateBannerImage(images: ImageModel[]) {
    if (images && images.length > 0) {
      var image = images[0];
      var updateData = {
        uploadedbannerimage: image,
        bannerimage: null
      }
      await this.updateBrand(updateData);
      this.eventsSubjectBannerImage.next(null);
      this.load();
    }
  }

  async updateProfileImage(images: ImageModel[]) {
    if (images && images.length > 0) {
      var image = images[0];
      var updateData = {
        uploadedprofilepic: image,
        profilepic: null
      }
      await this.updateBrand(updateData);
      this.eventsSubjectProfileImage.next(null);
      this.load();
    }
  }

  retry() {
    this.navigation.refreshPage(WildNavigationType.BRAND);
  }

}


interface BrandFormData {
  name: string;
  description: string;
  tags: string[];
  website: string;
  booking: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  email: string;
  phone: string;
  bannerimage: string;
  uploadedbannerimage?: ImageModel;
  profilepic: string;
  uploadedprofilepic?: ImageModel;
}