import { FeaturesData, FeatureColour, FeatureComponentType } from "../FeaturesData";
import { wildProListData } from "./wildpro_data_listdata";
import { customerCTAs } from "../shared/ctas/customer_subscribe_ctas";


export const wildproData: FeaturesData = {
  seoTitle: "Wild Pro",
  seoDescription: "Get a Wild Pro Subscription and take your watersports to the next level",
  seoImage: wildProListData.image,
  seoKeyWords: "",
  page: wildProListData.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: customerCTAs,
      title: wildProListData.title,
      subtitle: wildProListData.subtitle,
      paragraph: "Unlock Watersports Analysis, Weather Data, Perks, Discounts and No Booking Fees.",
    },
    // injectSharedComponent(financetab, { type:FeatureComponentType.RIGHTIMAGEINFOSECTION,colour:FeatureColour.WHITE  }),
    // {
    //   colour: FeatureColour.WHITE,
    //   type: FeatureComponentType.LEFTIMAGEINFOSECTION,
    //   image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Forder-management%2Frefunds-two.png?alt=media&token=8ec08e34-300b-46b8-8e27-636b1566df12",
    //   title: "Refunds",
    //   subtitle: "Just Click Refund",
    //   paragraph: "",
    //   data:[
    //     {
    //       icon: "shopping-bag", title: "Refunds.", subtitle: "Money goes straight back to customer."
    //     },
    //     {
    //       icon: "shopping-bag", title: "Fees.", subtitle: "All fees are refunded"
    //     },
    //     {
    //       icon: "lock", title: "Audited.", subtitle: "All refunds are audited."
    //     },
    //     {
    //       icon: "close", title: "Cancelled.", subtitle: "Appointments are automatically cancelled."
    //     },
    //     {
    //       icon: "cog", title: "Policy.", subtitle: "Configure your own refund rules."
    //     },
    //     {
    //       icon: "edit", title: "Notes.", subtitle: "Audited notes against all orders."
    //     },
    //   ]
    // },
    // {
    //   colour: FeatureColour.WHITE,
    //   type: FeatureComponentType.BIGIMAGEFEATURE,
    //   image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Forder-management%2Fhappy-customers-two.jpg?alt=media&token=5d89e74b-3e0b-4305-9cd9-9d768718a28c",
    //   title: "Customers can Cancel",
    //   subtitle: "...if you want",
    //   paragraph: "",
    //   data:[
    //     {
    //       icon: "users", title: "Configurable.", subtitle: "Refund policy."
    //     },
    //     {
    //       icon: "cog", title: "Restrict Refunds.", subtitle: "To X days before booking."
    //     },
    //     {
    //       icon: "calendar", title: "When Cancelled.", subtitle: "Availability is restored."
    //     },
    //   ]
    // },
    // {
    //   colour: FeatureColour.WHITE,
    //   type: FeatureComponentType.FAQS,
    //   title: "",
    //   subtitle: "Frequently Asked Questions",
    //   data: [
    //     {
    //       title: "Can I stop customers refunding?",
    //       subtitle: "Yes, just turn it off and customers can contact you to cancel."
    //     },
    //     {
    //       title: "Can I adjust when cancellation are allowed?",
    //       subtitle: "Yes, you can set how long before the start date someone can cancel."
    //     },
    //     {
    //       title: "How long does it take to can verified and paid?",
    //       subtitle: "Verification is instant and the payment process kicks in as some as you get your first order."
    //     },
    //     {
    //       title: "Do I still pay fees when I issue a refund?",
    //       subtitle: "No, if you issue a refund, all fees will be returned to you."
    //     },
    //     ...commonBusinesFAQs
    //   ],
    //   ctas: businessCTAs,
    // },
    // {
    //   colour: FeatureColour.WHITE,
    //   type: FeatureComponentType.TEXTANDINFO,
    //   title: "Explore More",
    //   subtitle: "Finances isn't the Only thing that's Easy on Wild",
    //   limit: 9,
    //   paragraph: "",
    //   data: [
    //     realTimeSafetyListData,
    //     crmListData,
    //     teamListDataShared,
    //     ordersFinanceListData,
    //     reportingListData,
    //     realTimeSafetyListData
    //   ],
    //   ctas: {
    //     secondary: {
    //       text: "See All",
    //       page: WildNavigationType.ALLFEATURES
    //     }
    //   }
    // },
    // injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

