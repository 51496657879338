import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { Activity } from '../../../../../../data/models/activity_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { AppointmentEditFormComponent } from './appointmenteditform.component';
import { BusinessActivitiesService } from '../services/businessactivities_service';
import { Observable, throwError } from 'rxjs';
import { tap, take, catchError } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { AppointmentAddContactComponent } from './appointmentaddcontact.component';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { AppointmentAddTeamMemberComponent } from './appointmentaddteammember.component';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { ActivityStatus } from '../../../../../../data/enums/activity_status';
import { AppointmentEditNameFormComponent } from './appointmenteditnameform.component';
import { UpdateActivityBookableStatusDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityBookableStatusDTO';
import { UpdateActivityService } from '../services/updateactivity_service';
import { AppointmentEditDescriptionFormComponent } from './appointmenteditdescriptionform.component';
import { BusinessServiceModel } from '../../../../../../data/models/business_service_model';
import { ServiceType } from '../../../../../../data/enums/service_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { BusinessServiceLocationType } from '../../../../../../data/enums/business_service_location_type';
import { UpdateLocationComponent } from '../../../components/updatelocation.component';
import { BusinessServiceLocationModel } from '../../../../../../data/models/business_service_location';
import { UpdateActivityLocationDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityLocationDTO';
import { DeleteActivityService } from '../services/deleteactivity_service';
import { BusinessActivityDetailsDTO } from '../../../../../../data/dtos/business/activities/BusinessActivityDetailsDTO';
import { AppointmentContactExportComponent } from './appointmentcontactexport.component';
import { AppointmentEditCapacityFormComponent } from './appointmenteditcapacityform.component';
import { SourceDocumentType } from '../../../../../../data/enums/source_document_type';
import { ChartOptions } from 'chart.js';
import { SendMessageComponent } from '../../../components/sendmessage/sendmessage.component';


enum AppointmentPages {
  DETAILS,
  SWIMMERS,
  TEAM,
  NOTES,
  MESSAGES
}

@Component({
  selector: 'app-appointmentdetails',
  templateUrl: './appointmentdetails.component.html'
})

export class AppointmentDetailsComponent implements OnInit {

   // Pie
   public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
    
  };
  public pieChartLabels = [ [ 'Attended'], [ 'DNA' ], 'No Status' ];
  public attendedColor = '#AED581'; // light green
  public dnaColor = '#EF9A9A'; // light red
  public noStatusColor = '#FFF59D'; // light yellow
  public pieChartDatasets = [ {
    data: [ 0,0,0 ],
    backgroundColor: [this.attendedColor,this.noStatusColor,this.noStatusColor]
  } ];
  public pieChartLegend = true;
  public pieChartPlugins = [];

  @Input() id: string;
  @Output() updated = new EventEmitter();
  imagesUrl = environment.imagesUrl;
  AppointmentPages = AppointmentPages;
  readableDate: string;
  readableEndDate: string;
  page: AppointmentPages = AppointmentPages.DETAILS;
  activity$: Observable<BusinessActivityDetailsDTO>;
  error: ErrorModel;
  activity: BusinessActivityDetailsDTO;
  SourceDocumentType = SourceDocumentType;
  ServiceType = ServiceType;
  showAttendanceCard: boolean = false;
  service: BusinessServiceModel;
  ActivityStatus = ActivityStatus;
  BusinessServiceLocationType = BusinessServiceLocationType;

  constructor(
    private notifyService: NotifyService,
    private panelService: PanelService,
    private modalService: ModalService,
    private updateActivityService: UpdateActivityService,
    private deleteActivityService: DeleteActivityService,
    private navigationService: NavigationService,
    private businessActivityService: BusinessActivitiesService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.activity$ = null;
    this.activity$ = this.businessActivityService.getActivityV2(this.id).pipe(
      tap((activity) => {
        this.activity = activity;
        this.pieChartDatasets
        this.pieChartDatasets = [ {
          data: [ activity.stats.attended,  activity.stats.didNotAttend, activity.stats.noStatus ],
          backgroundColor: [this.attendedColor,this.dnaColor,this.noStatusColor]
        } ];
        this.service = activity.service;
        this.readableDate = activity.formattedStartDate;
        this.readableEndDate = activity.formattedEndDate;
      }),
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  noteCountUpdated(count: number, activity: Activity) {
    activity.noteCount = count;
  }

  edit() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentEditFormComponent,
      componentInputs: [{
        inputName: "activity",
        value: this.activity.activity
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.updated.emit();
        }
      }]
    }
    this.modalService.show(settings);
  }

  message() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: SendMessageComponent,
      close: true,
      large: true,
      componentInputs: [{
        inputName: "id",
        value: this.activity.activity.id
      },
      {
        inputName: "activity",
        value: true
      }],
      // componentOutputs: [{
      //   outputName: "updated",
      //   func: () => {
      //     this.load();
      //     this.updated.emit();
      //   }
      // }]
    }
    this.modalService.show(settings);
  }

  editCapacity() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      component: AppointmentEditCapacityFormComponent,
      componentInputs: [{
        inputName: "activity",
        value: this.activity.activity
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.updated.emit();
        }
      }]
    }
    this.modalService.show(settings);
  }

  editName() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentEditNameFormComponent,
      componentInputs: [{
        inputName: "activity",
        value: this.activity.activity
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.updated.emit();
        }
      }]
    }
    this.modalService.show(settings);
  }

  editDescription() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentEditDescriptionFormComponent,
      componentInputs: [{
        inputName: "activity",
        value: this.activity.activity
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.updated.emit();
        }
      }]
    }
    this.modalService.show(settings);
  }

  async export() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentContactExportComponent,
      large: true,
      close: true,
      componentInputs: [{
        inputName: "id",
        value: this.activity.activity.id
      }],
    }
    this.modalService.show(settings);

  }

  changeContactStatus() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentAddContactComponent,
      close: true,
      componentInputs: [{
        inputName: "id",
        value: this.activity.activity.id
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.updated.emit();
        }
      }]
    }
    this.modalService.show(settings);
  }

  addContact() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentAddContactComponent,
      close: true,
      componentInputs: [{
        inputName: "id",
        value: this.activity.activity.id
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.updated.emit();
        }
      }]
    }
    this.modalService.show(settings);
  }




  addTeamMember() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AppointmentAddTeamMemberComponent,
      close: true,
      componentInputs: [{
        inputName: "id",
        value: this.activity.activity.id
      }],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
        }
      }]
    }
    this.modalService.show(settings);
  }




  openService() {
    this.navigationService.navigate(WildNavigationType.BUSINESSSERVICEDETAILS, {}, this.service.id);
  }

  changeBookableStatus() {
    var question = "";
    var bookable;
    if (this.activity.activity.bookable == true) {
      question = "Are you sure you want to DISABLE bookings on this activity?";
      bookable = false;
    } else {
      question = "Are you sure you want to ENABLE bookings on this activity?";
      bookable = true;
    }
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: question,
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.procecsschangeBookableStatus();
      }
    }
    this.modalService.show(settings);
  }
  async procecsschangeBookableStatus() {
    var bookable;
    if (this.activity.activity.bookable == true) {
      bookable = false;
    } else {
      bookable = true;
    }
    var settings: ModalModel = {
      header: "Processing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    var dto: UpdateActivityBookableStatusDTO = {
      bookable: bookable
    }
    this.updateActivityService.updateBookableStatus(this.activity.activity.id, dto)
      .then(async () => {
        this.notifyService.notify("Updated", "Bookable status updated", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  // DELETE ACTIVITY START
  deleteActivity() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Do you want to permanently delete this activity?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processDeletion();
      }
    }
    this.modalService.show(settings);
  }

  processDeletion() {
    this.deleteActivityService.deleteActivity(this.activity.activity.id)
      .then(async () => {
        this.notifyService.notify("Deleted", "Activity has been deleted", NotifyType.SUCCESS);
        this.panelService.hide();
        this.updated.emit();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  // DELETE ACTIVITY  END

  // LOCATION START

  editLocation(existingLocation: BusinessServiceLocationModel) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: UpdateLocationComponent,
      large: true,
      componentInputs: [{
        inputName: "location",
        value: existingLocation
      }],
      close: true,
      componentOutputs: [
        {
          outputName: "updatedLocation",
          func: async ($event: BusinessServiceLocationModel) => {
            console.log($event);
            this.updateLocation($event);
          }
        }
      ]
    }
    this.modalService.show(settings);
  }

  removeOverrideOnLocation() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Revert back to original meeting point from the service?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.updateLocation(null)
      }
    }
    this.modalService.show(settings);
  }

  async updateLocation(location: BusinessServiceLocationModel) {
    var settings: ModalModel = {
      header: "Updating Location",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    var dto: UpdateActivityLocationDTO = {
      location: location
    }
    this.updateActivityService.updateLocation(this.activity.activity.id, dto)
      .then(async () => {
        this.notifyService.notify("Updated Location", "Service Location Updated", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  // LOCATION END

  handlerError(message: string) {
    this.notifyService.notify("Update", message, NotifyType.ERROR);
  }

} 
