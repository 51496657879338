import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeoService } from 'src/shared/services/seo/seo.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  constructor(private seo: SeoService, private router: Router) { }

  ngOnInit(): void {
    this.seo.update("Wild for Business Demo", "Book a demo of Wild for business", "swimming, open water, lake, river, beach", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fwildlogo.png?alt=media&token=87b004dd-dd18-4815-a240-0f049dfc787b");
  }

}
