import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';
import { MapsService } from 'src/shared/services/maps/maps_service';


@Component({
  selector: '[app-swimspotmap]',
  template: `
  <div *ngIf="mapsLoaded | async" class="shadow-lg rounded-lg wild-bg-primary overflow-hidden mt-6 mb-8" style="height:500px">
    <google-map height="500px" width="100%" [options]="options">
      <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
        [options]="markerOptions"></map-marker>
    </google-map>
  </div> 
  `,
})
export class SwimSpotMapComponent implements OnInit {
  @Input() swimspot: SwimSpot;
  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptions!: google.maps.MarkerOptions;
  markerPositions!: google.maps.LatLngLiteral[];

  constructor(private maps: MapsService) {
    this.mapsLoaded = this.maps.loadMaps().pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );;
  }



  ngOnInit(): void {
    this.options = {
      mapTypeId: 'satellite',
      //TODO - after updating angular version and google maps version. Introduce map id instread of JSON styles
      // mapId: "ae664698752f3b45",
      // styles: [
      //   {
      //     "featureType": "landscape.natural.terrain",
      //     "stylers": [
      //       {
      //         "visibility": "on"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.business",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.government",
      //     "elementType": "labels",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.medical",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.park",
      //     "stylers": [
      //       {
      //         "visibility": "on"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.place_of_worship",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.school",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "poi.sports_complex",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "road",
      //     "stylers": [
      //       {
      //         "color": "#ffffff"
      //       },
      //       {
      //         "visibility": "simplified"
      //       },
      //       {
      //         "weight": 0.5
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "road",
      //     "elementType": "labels.icon",
      //     "stylers": [
      //       {
      //         "color": "#aaaaaa"
      //       },
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "road",
      //     "elementType": "labels.text",
      //     "stylers": [
      //       {
      //         "color": "#aaaaaa"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "transit.station.airport",
      //     "stylers": [
      //       {
      //         "visibility": "off"
      //       }
      //     ]
      //   },
      //   {
      //     "featureType": "water",
      //     "stylers": [
      //       {
      //         "color": "#031d44"
      //       }
      //     ]
      //   }
      // ],
      center: {
        lat: Number.parseFloat(this.swimspot.latitude),
        lng: Number.parseFloat(this.swimspot.longitude)
      },
      zoom: 14,
    };
    this.markerOptions = { draggable: true };
    this.markerPositions = [{
      lat: Number.parseFloat(this.swimspot.latitude),
      lng: Number.parseFloat(this.swimspot.longitude)
    }];
  }



}
