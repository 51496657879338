import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { StorageService } from 'src/shared/services/storage/storage.service';

@Component({
  selector: 'app-swimspotlocation',
  templateUrl: './swimspotlocation.component.html',
  styleUrls: ['./swimspotlocation.component.scss']
})
export class SwimspotlocationComponent implements OnInit {

  @Output() done = new EventEmitter<any>();
  @Output() back = new EventEmitter();
  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptions!: google.maps.MarkerOptions;
  markerPositions!: google.maps.LatLngLiteral[];
  latitude: string;
  longitude: string;

  constructor(
    private storage: StorageService,
    private notify: NotifyService,
    private maps: MapsService) {
    this.mapsLoaded = this.maps.loadMaps().pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );;
  }

  updateMarker(event: google.maps.MapMouseEvent) {
    var latlng = event.latLng.toJSON();
    this.markerPositions = [event.latLng.toJSON()];
    this.storage.setCookie("swimspotlat", latlng.lat.toString(), 1);
    this.storage.setCookie("swimspotlng", latlng.lng.toString(), 1);
  }

  ngOnInit(): void {
    this.markerOptions = { draggable: true, };
    if (this.lat != "") {
      this.options = {
        center: {
          lat: Number.parseFloat(this.lat),
          lng: Number.parseFloat(this.lng)
        },
        zoom: 12,
      };
      this.markerPositions = [{
        lat: Number.parseFloat(this.lat),
        lng: Number.parseFloat(this.lng)
      }];
    } else {
      this.loadFreshMap();
    }
  }

  loadFreshMap() {
    this.storage.deleteCookie("swimspotlat");
    this.storage.deleteCookie("swimspotlng");
    this.options = {
      center: {
        lat: Number.parseFloat("52.549736"),
        lng: Number.parseFloat("-1.851169")
      },
      zoom: 5,
    };
    this.markerPositions = [];
  }

  get lat(): string {
    return this.storage.getCookie("swimspotlat")
  }

  get lng(): string {
    return this.storage.getCookie("swimspotlng")
  }

  locationConfirmed() {
    if (this.lat == "" || this.lng == "") {
      this.notify.notify("Location Needed", "Please select a location before continuing", NotifyType.WARNING);
      return;
    }
    this.done.emit({
      lat: this.latitude,
      lng: this.longitude
    })
  }

  goBack() {
    this.storage.deleteCookie("swimspotlat");
    this.storage.deleteCookie("swimspotlng");
    this.back.emit();
  }





}
