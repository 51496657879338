import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const timetables: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fscheduling%2Ftimetables.png?alt=media&token=59ec54e0-1080-45ab-bc1a-f863f21d09e1",
  title: "Timetables",
  subtitle: "Sync Pool or Tide Time",
  paragraph: "Apply timetables to your schedule and your businesses availability adjusts automatically.",
  data: [
    {
      icon: "water", title: "Add Pools Times.", subtitle: "Bookings are adjusted."
    },
    {
      icon: "water", title: "Add Tide Times.", subtitle: "We'll adjust slots."
    },
    {
      icon: "table", title: "Automatically Synced.", subtitle: "With timetables."
    }
  ]
}