import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TabModel } from 'src/shared/components/tabs/tab_model';
import { BeaufortScale } from '../../../../../data/enums/beaufort_scale';
import { MoonPhases } from '../../../../../data/enums/moon_phases';
import { SwimSpotStatus } from '../../../../../data/enums/swimspot_status_enum';
import { TideType } from '../../../../../data/enums/tide_type';
import { WaterwayType } from '../../../../../data/enums/waterway_type';
import { WeatherClassification } from '../../../../../data/enums/weather_classification';
import { SwimSpot } from '../../../../../data/models/swimspot_model';
import { ErrorModel } from '../../../../../data/models/error_model';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { SideMenuService } from 'src/shared/services/sidemenu_service/sidemenu.service';
import { TabService } from 'src/shared/services/tab_service/tab_service';
import { SwimspotsdataService } from '../../services/swimspotdata_service/swimspotsdata.service';
import { SwimSpotWrapper } from '../../../../../data/models/swimspot_wrapper';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { SearchResults } from '../../../../../data/models/SearchResults';
import { SearchService } from 'src/shared/services/search/search_service';
import { userSearchService } from 'src/wild/services/search/search_service';
import { catchError, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SearchResult } from '../../../../../data/models/SearchResult';

@Component({
  selector: 'app-swimspotdetails',
  templateUrl: './swimspotdetails.component.html',
  styleUrls: ['./swimspotdetails.component.scss']
})
export class SwimspotdetailsComponent implements OnInit {

  swimspot: SwimSpot;
  swimspotWrapper: SwimSpotWrapper;
  nearby$: Observable<SearchResults>;
  businesses$: Observable<SearchResult[]>;
  images: GalleryItem[] = [];
  // swimspot$: Observable<SwimSpot>;
  public error: ErrorModel = null;
  MoonPhases = MoonPhases;
  WeatherClassification = WeatherClassification;
  BeaufortScale = BeaufortScale;
  showAllWeather: boolean;
  showAllTide: boolean;
  TideType = TideType;
  SwimSpotStatus = SwimSpotStatus;
  WaterwayType = WaterwayType;
  activeTabId: string = "reviews";
  @ViewChild('textslide') textslide: ElementRef;
  tabs: TabModel[] = [
    {
      id: "reviews",
      name: "Reviews",
      icon: "",
      active: true
    },
    {
      id: "posts",
      name: "Posts",
      icon: "",
      active: false
    },
    {
      id: "alerts",
      name: "Alerts",
      icon: "",
      active: false
    },

    // {
    //   id: "tides",
    //   name: "Tides",
    //   icon: "water",
    //   active: false
    // },
    // {
    //   id: "oceanforecast",
    //   name: "Conditions",
    //   icon: "water",
    //   active: false
    // },

  ]

  constructor(
    private route: ActivatedRoute,
    private seo: SeoService,
    private router: Router,
    private tabService: TabService,
    private searchService: userSearchService,
    private sideMenuService: SideMenuService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.load();
      }
    });

  }

  nearby() {
    this.nearby$ = null;
    this.nearby$ = this.searchService.nearTo(this.swimspot.latitude, this.swimspot.longitude, this.swimspot.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  businesses() {
    this.businesses$ = null;
    this.businesses$ = this.searchService.swimspotBusinesses(this.swimspot.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  updateTabCounts(swimspot) {
    this.tabs.find(tab => tab.id == "posts").count = swimspot?.numberOfPosts ?? 0;
    // this.tabs.find(tab => tab.id == "tides").count = swimspot?.tideChunks?.length ?? 0;
    this.tabs.find(tab => tab.id == "reviews").count = swimspot?.numberOfReviews ?? 0;
    this.tabs.find(tab => tab.id == "alerts").count = swimspot?.numberOfAlerts ?? 0;
    // this.tabs.find(tab => tab.id == "oceanforecast").count = swimspot?.oceanWeatherHourlySummariesInDayChunks?.length ?? 0;
  }

  ngOnInit() {
    this.swimspotWrapper = (this.route.snapshot.data as any)?.swimspotwrapper as SwimSpotWrapper;
    this.swimspot = this.swimspotWrapper.swimspot;
    this.nearby();
    this.businesses();
    for (let i = 0; i < this.swimspotWrapper.images.length; i++) {
      const thumb = this.swimspotWrapper.imagesthumbs[i];
      const image = this.swimspotWrapper.imageslarge[i];
      var imageItem = new ImageItem({ src: image, thumb: thumb });
      this.images.push(imageItem);
    }
    this.loadSeo();
    this.updateTabCounts(this.swimspot)

  }

  ngAfterViewInit() {
    this.startCarousel();   //<<<=====same as oldest way
  }

  loadSeo() {
    if (this.swimspotWrapper.swimspot?.image != null) {
      var src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/images%2Fresized%2F" + this.swimspotWrapper.swimspot.image?.image + "_1080x1080.jpeg?alt=media";
    } else {
      var src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839";
    }
    this.seo.update("Wild | " + this.swimspotWrapper.swimspot?.name ?? "", this.swimspotWrapper.swimspot?.description ?? "", this.swimspotWrapper.swimspot?.name ?? "" + ", swimspot, wild swimming", src);
  }

  load() {
    // this.swimspot$ = null;
    // var swimSpotId = this.route.snapshot.paramMap.get('id');
    // var canonical = this.route.snapshot.paramMap.get('canonical');
    // if (swimSpotId != null && swimSpotId != "" && swimSpotId != undefined) {
    //   this.swimspot$ = this.swimSpotService.read(swimSpotId).pipe(
    //     take(1),
    //     tap((swimspot) => this.updateTabCounts(swimspot)),
    //     catchError(err => {
    //       this.error = err;
    //       return throwError(err);
    //     })
    //   );
    // } else {
    //   this.swimspot$ = this.swimSpotService.getByCanonicalId(canonical).pipe(
    //     take(1),
    //     tap((swimspot) => this.updateTabCounts(swimspot)),
    //     catchError(err => {
    //       this.error = err;
    //       return throwError(err);
    //     })
    //   );
    // }
  }

  startCarousel() {
    try {
      const textSlide = this.textslide.nativeElement;
      const text = textSlide.querySelectorAll("p");
      // const prev = document.querySelector(".prev");
      // const next = document.querySelector(".next");

      let counter = 1;
      var size = text[0].clientWidth;

      //to set the slide one as the first slide instead of the clone
      textSlide.style.transform = `translateX(-${size * counter}px)`;

      //looping the slider every 2s
      setInterval(() => {
        //reset size to account for screen size changes
        size = text[0].clientWidth;
        if (counter >= text.length - 1) return;

        textSlide.style.transition = `transform 0.8s ease`;
        counter++;
        textSlide.style.transform = `translateX(-${size * counter}px)`;

      }, 2000);

      textSlide.addEventListener('transitionend', () => {
        if (text[counter].id === 'lastClone') {
          textSlide.style.transition = `none`;
          counter = text.length - 2;
          textSlide.style.transform = `translateX(-${size * counter}px)`;
        }

        if (text[counter].id === 'firstClone') {
          textSlide.style.transition = `none`;
          counter = text.length - counter;
          textSlide.style.transform = `translateX(-${size * counter}px)`;
        }
      });
    } catch (error) {

    }
  }



  get collapsed(): boolean {
    return this.sideMenuService.collapsed;
  }

  get sideMenuActive(): boolean {
    return this.sideMenuService.active;
  }

  updateActiveTabId(id: string) {
    this.activeTabId = id;
  }

}
