import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const realTimeSafetyOverview:FeaturesComponentData = {
    colour:FeatureColour.WHITE,
    type:FeatureComponentType.RIGHTIMAGEINFOSECTION,
    image:"https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Frealtime-dashboard-min.png?alt=media&token=58aaea6c-303a-4a26-9b74-c6fcc73f9100",
    title:"Visibility",
    subtitle:"Who's In the Water",
    paragraph:"Real-time view of who's in, what they're doing & who is due out soon.",
    data:[
      { 
        icon:"stopwatch",title:"Real Time.",subtitle:"Know who's in the water."
      },
      { 
        icon:"palette",title:"Colour Coded.",subtitle:"Time in, sport & due out."
      },
      { 
        icon:"wifi",title:"Safety Bands.",subtitle:"Hire, sell or without."
      }
    ]
  }