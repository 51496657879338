import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Review } from '../../../../../../data/models/review_model';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { SwimspotsdataService } from '../../../services/swimspotdata_service/swimspotsdata.service';
import { environment } from 'src/environments/environment';
import { AppPromptService } from 'src/shared/services/appprompt/appprompt_service';


@Component({
  selector: '[app-reviews]',
  template: `
<div *ngIf="reviews$">
      <ng-container *ngIf="reviews$ | async as reviews; else loadingOrError">
      <div *ngIf="!reviews || reviews.length == 0; else reviewslist">
      <app-empty (onClick)="prompt()" ctatext="Add Review"  icon="edit" header="No reviews yet"  [showcta]="true"></app-empty>    
      </div>
      <ng-template #reviewslist>
        <div class="bg-white">
          <div class="pt-4 flex items-center justify-end">
            <button (click)="prompt()" type="button" class="wild-btn-primary">
              Add Review
              <fa-icon class="ml-3" icon="edit"></fa-icon>
            </button>
          </div>
          <div class="mx-auto py-16 sm:py-24 lg:max-w-7xl lg:py-32 lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div class="lg:col-span-4">
                <h2 class="wild-header-large">Reviews</h2>
                <div class="mt-3 flex items-center">
                  <div>
                    <div class="flex items-center">
                      <svg [ngClass]="{'text-yellow-400':swimspot.averageRating >= 1,'text-gray-300':swimspot.averageRating < 1}" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg [ngClass]="{'text-yellow-400':swimspot.averageRating >= 2,'text-gray-300':swimspot.averageRating < 2}" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg [ngClass]="{'text-yellow-400':swimspot.averageRating >= 3,'text-gray-300':swimspot.averageRating < 3}" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg [ngClass]="{'text-yellow-400':swimspot.averageRating >= 4,'text-gray-300':swimspot.averageRating < 4}" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg [ngClass]="{'text-yellow-400':swimspot.averageRating >= 5,'text-gray-300':swimspot.averageRating < 5}" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
          
                    </div>
                  </div>
                  <p class="ml-2 text-sm text-gray-900" *ngIf="swimspot.numberOfReviews && swimspot.numberOfReviews > 0">Based on {{swimspot.numberOfReviews}} reviews</p>
                  <p class="ml-2 text-sm text-gray-900" *ngIf="!swimspot.numberOfReviews || swimspot.numberOfReviews == 0">No reviews yet</p>
                </div>
             
              <div class="mt-6">

                <dl class="space-y-3">
                  <div class="flex items-center text-sm">
                    <dt class="flex-1 flex items-center">
                      <p class="w-3 font-medium text-gray-900">5</p>
                      <div aria-hidden="true" class="ml-1 flex-1 flex items-center">
                        <!-- Heroicon name: solid/star -->
                        <svg class="flex-shrink-0 h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <div class="ml-3 relative flex-1">
                          <div class="h-3 bg-gray-100 border border-gray-200 rounded-full"></div>

                          <div [ngStyle]="{'width':'calc('+ swimspot.fiveStar +' / ' + swimspot.numberOfReviews + ' * 100%)'}" class="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"></div>
                        </div>
                      </div>
                    </dt>
                    <dd class="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">{{((swimspot.fiveStar/swimspot.numberOfReviews)*100) | number:0}}%</dd>
                  </div>

                  <div class="flex items-center text-sm">
                    <dt class="flex-1 flex items-center">
                      <p class="w-3 font-medium text-gray-900">4</p>
                      <div aria-hidden="true" class="ml-1 flex-1 flex items-center">
                        <!-- Heroicon name: solid/star -->
                        <svg class="flex-shrink-0 h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <div class="ml-3 relative flex-1">
                          <div class="h-3 bg-gray-100 border border-gray-200 rounded-full"></div>

                          <div   [ngStyle]="{'width':'calc('+ swimspot.fourStar +' / ' + swimspot.numberOfReviews + ' * 100%)'}" class="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"></div>
                        </div>
                      </div>
                    </dt>
                    <dd class="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">{{((swimspot.fourStar/swimspot.numberOfReviews)*100) | number:0}}%</dd>
                  </div>

                  <div class="flex items-center text-sm">
                    <dt class="flex-1 flex items-center">
                      <p class="w-3 font-medium text-gray-900">3</p>
                      <div aria-hidden="true" class="ml-1 flex-1 flex items-center">
                        <!-- Heroicon name: solid/star -->
                        <svg class="flex-shrink-0 h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <div class="ml-3 relative flex-1">
                          <div class="h-3 bg-gray-100 border border-gray-200 rounded-full"></div>

                          <div [ngStyle]="{'width':'calc('+ swimspot.threeStar +' / ' + swimspot.numberOfReviews + ' * 100%)'}" class="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"></div>
                        </div>
                      </div>
                    </dt>
                    <dd class="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">{{((swimspot.threeStar/swimspot.numberOfReviews)*100) | number:0}}%</dd>
                  </div>

                  <div class="flex items-center text-sm">
                    <dt class="flex-1 flex items-center">
                      <p class="w-3 font-medium text-gray-900">2</p>
                      <div aria-hidden="true" class="ml-1 flex-1 flex items-center">
                        <!-- Heroicon name: solid/star -->
                        <svg class="flex-shrink-0 h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <div class="ml-3 relative flex-1">
                          <div class="h-3 bg-gray-100 border border-gray-200 rounded-full"></div>

                          <div [ngStyle]="{'width':'calc('+ swimspot.twoStar +' / ' + swimspot.numberOfReviews + ' * 100%)'}" class="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"></div>
                        </div>
                      </div>
                    </dt>
                    <dd class="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">{{((swimspot.twoStar/swimspot.numberOfReviews)*100) | number:0}}%</dd>
                  </div>

                  <div class="flex items-center text-sm">
                    <dt class="flex-1 flex items-center">
                      <p class="w-3 font-medium text-gray-900">1</p>
                      <div aria-hidden="true" class="ml-1 flex-1 flex items-center">
                        <!-- Heroicon name: solid/star -->
                        <svg class="flex-shrink-0 h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <div class="ml-3 relative flex-1">
                          <div class="h-3 bg-gray-100 border border-gray-200 rounded-full"></div>

                          <div [ngStyle]="{'width':'calc('+ swimspot.oneStar +' / ' + swimspot.numberOfReviews + ' * 100%)'}" class="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"></div>
                        </div>
                      </div>
                    </dt>
                    <dd class="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">{{((swimspot.oneStar/swimspot.numberOfReviews)*100) | number:0}}%</dd>
                  </div>
                </dl>
              </div>

              <div class="mt-10">
                    <h3 class="text-lg font-medium text-gray-900">Share your thoughts</h3>
                    <p class="mt-1 text-sm text-gray-600">Share your experience with other swimmers</p>
                    <a (click)="prompt()" class="cursor-pointer mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full">Write a review</a>
               </div>
            </div>

            <div class="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-7">

              <div class="flow-root">
                <div class="-my-12 divide-y divide-gray-200">
                  <div *ngFor="let review of reviews" class="py-12">
                    <div class="flex items-center cursor-pointer" (click)="prompt()">
                      <img [src]="review.profilePicture == null ? 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d' :
                      imagesUrl + review.profilePicture.image + '_100x100.jpeg?alt=media' " alt="Profile" class="h-12 w-12 rounded-full">
                      <div class="ml-4">
                        <h4 class="text-sm font-bold text-gray-900">{{review?.username ?? 'processing...'}}</h4>
                        <div class="mt-1 flex items-center">
                          <!--
                            Heroicon name: solid/star

                            Active: "text-yellow-400", Default: "text-gray-300"
                          -->
                          <svg [ngClass]="{'text-yellow-400':review.rating >= 1,'text-gray-300':review.rating < 1}" class=" h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>

                          <!-- Heroicon name: solid/star -->
                          <svg [ngClass]="{'text-yellow-400':review.rating >= 2,'text-gray-300':review.rating < 2}" class="h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>

                          <!-- Heroicon name: solid/star -->
                          <svg [ngClass]="{'text-yellow-400':review.rating >= 3,'text-gray-300':review.rating < 3}" class="h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>

                          <!-- Heroicon name: solid/star -->
                          <svg [ngClass]="{'text-yellow-400':review.rating >= 4,'text-gray-300':review.rating < 4}" class="h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>

                          <!-- Heroicon name: solid/star -->
                          <svg [ngClass]="{'text-yellow-400':review.rating >= 5,'text-gray-300':review.rating < 5}" class="h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4 space-y-6 text-base italic text-gray-600">
                      <p>{{review?.comment ?? "No comment"}}</p>
                    </div>
                    <div class="mt-2" *ngIf="review.tags || review.experience">
                      <span class="wild-badge-primary" *ngFor="let tag of review.tags">{{tag}}</span>
                      <span class="wild-badge-primary" *ngFor="let experience of review.experience">{{experience}}</span>
                    </div>
                    <app-socialbar></app-socialbar>
                    
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>



      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
          </app-retry>
      </ng-container>
      <ng-template #loading>
          <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>

  `,
})
export class ReviewsComponent implements OnInit {
  @Input() swimspot: SwimSpot;
  reviews$: Observable<Review[]>;
  error: ErrorModel;
  imagesUrl = environment.imagesUrl;

  constructor(
    private authService: AuthenticationService,
    private promptService: AppPromptService,
    private swimspotService: SwimspotsdataService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.reviews$ = null;
    this.reviews$ = this.swimspotService.reviews(this.swimspot.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  prompt() {
    if (this.authService.isAuthedIfNotShowPopUp()) {
      this.promptService.prompt();
    }
  }


}
