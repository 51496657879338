import { Component, Input} from '@angular/core';
import { BusinessMode } from '../../../../data/enums/business_mode';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { CTAFUNCTION, FeatureColour, FeaturesComponentCTAData, FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-ctas',
  templateUrl: './ctas.component.html'
})
export class CTASComponent {
  constructor(
    private authentication:AuthenticationService,
    private navigate:NavigationService){}

  @Input() data: FeaturesComponentData;
  @Input() left: boolean;

  execute(data:FeaturesComponentCTAData){
    if(data.page){
      this.navigate.navigate(data.page);
    }else{
      if(data.function == CTAFUNCTION.BUSINESSONBOARDING) this.onboarding(BusinessMode.SYSTEM);
      if(data.function == CTAFUNCTION.BUSINESSLOGIN) this.businessLogin();
    }
  }

  businessLogin() {
    if (this.authentication.isLoggedIn()) {
      this.navigate.navigate(WildNavigationType.DASHBOARDHOME);
    } else {
      this.navigate.navigate(WildNavigationType.LOGIN, { "business": 1, "nav": WildNavigationType.DASHBOARDHOME });
    }
  } 

  onboarding(mode:BusinessMode) {
    if (this.authentication.isLoggedIn()) {
      this.navigate.navigate(WildNavigationType.ONBOARDINGV2, { "mode": mode });
    } else {
      this.navigate.navigate(WildNavigationType.REGISTER, { "business": 1, "nav": WildNavigationType.ONBOARDINGV2 });
    }
  }
}
