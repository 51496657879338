import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, take } from 'rxjs/operators';
import { SwimSpot } from '../../../../data/models/swimspot_model';
import { ErrorModel } from '../../../../data/models/error_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';
import { ErrorType } from '../../../../data/enums/error_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';

@Injectable({
  providedIn: 'root'
})
export class SwimSpotResolver implements Resolve<SwimSpot> {
  constructor(private data: SwimspotsdataService, private notify: NotifyService, private navigation: NavigationService) { }
  resolve(route: ActivatedRouteSnapshot,): Observable<any> {
    return this.data.getByCanonicalIdV2(route.params.canonical).pipe(
      concatMap((swimSpotWrapper) => {
        if (swimSpotWrapper != null)
          return of(swimSpotWrapper);
        this.navigation.navigate(WildNavigationType.NOTFOUND);
        return of(null);
      }),
      catchError((error: ErrorModel) => {
        if (error?.code == ErrorType.RECORDNOTFOUND) {
          this.notify.notify("Spot Not Found", error.message, NotifyType.ERROR);
        } else {
          this.notify.notify("Error fetching Spot", error.message, NotifyType.ERROR);
        }
        this.navigation.navigate(WildNavigationType.NOTFOUND);
        return of(null);
      }));
  }
}