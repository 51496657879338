import { FeaturesComponentData, FeatureColour, FeatureComponentType } from "../FeaturesData";


export const whyyouwilllove: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.TEXTANDCIRCLES,
  subtitle: "Why You'll Love Wild:",
  data: [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fmarketing.png?alt=media&token=1f7dad6f-369c-4602-86b3-ade4f84119a8",
      title: "Free Marketing", subtitle: "Thousands Online"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fsurf-school.jpg?alt=media&token=66b18208-5fef-43e0-981d-b97a658c7db1",
      title: "Easy Scheduling", subtitle: "Groups or 121's"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fcrm.png?alt=media&token=3732a3c9-f129-4206-b7fa-ccd123954326",
      title: "CRM", subtitle: "Customers Organised"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fnosubscription.png?alt=media&token=853012ce-0692-4684-b1fd-f670580731d0",
      title: "Flexible Pricing", subtitle: "For all Businesses"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fsafety.png?alt=media&token=fe8a6f1d-1b8f-44c6-93a6-ae6427601bfe",
      title: "Safety System", subtitle: "Built In"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fbusinessapp.png?alt=media&token=d1b9e9f9-0b75-432c-b370-27d9abf5d3a0",
      title: "Business App", subtitle: "On the go"
    },
  ]
}