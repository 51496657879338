import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';

@Component({
    selector: 'app-useraddswimspot',
    template: ` 
        <app-wild-header></app-wild-header>
       <app-addswimspot (onCreate)="added($event)"></app-addswimspot>>
  `
})
export class UserAddSwimSpotComponent implements OnInit {

    constructor(private navigation: NavigationService) { }

    ngOnInit(): void {
    }

    added(swimSpotId: string) {
        this.navigation.navigate(WildNavigationType.MYSWIMSPOTS, {});
    }

}
