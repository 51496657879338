import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BeaufortScale } from '../../../../../../data/enums/beaufort_scale';
import { WeatherClassification } from '../../../../../../data/enums/weather_classification';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';


@Component({
    selector: '[app-tags]',
    template: `
 <div class="pb-3">
    <span  *ngIf="swimspot.swimming" class="wild-badge-primary">
        Swimming
    </span>
    <span  *ngIf="swimspot.waterskiing" class="wild-badge-primary">
        Waterski
    </span>
    <span  *ngIf="swimspot.wakeboarding" class="wild-badge-primary">
        Wakeboard
    </span>
    <span  *ngIf="swimspot.kneeboarding" class="wild-badge-primary">
        Kneeboarding
    </span>
    <span  *ngIf="swimspot.surfing" class="wild-badge-primary">
        Surfing
    </span>
    <span  *ngIf="swimspot.bodyboarding" class="wild-badge-primary">
        Body Boarding
    </span>
    <span  *ngIf="swimspot.bodysurfing" class="wild-badge-primary">
        Body Surfing
    </span>
    <span  *ngIf="swimspot.canoeing" class="wild-badge-primary">
        Canoeing
    </span>
    <span  *ngIf="swimspot.kayaking" class="wild-badge-primary">
        Kayaking
    </span>
    <span  *ngIf="swimspot.windsurfing" class="wild-badge-primary">
        Windsurfing
    </span>
    <span  *ngIf="swimspot.kiteboarding" class="wild-badge-primary">
        Kiteboarding
    </span>
    <span  *ngIf="swimspot.sailing" class="wild-badge-primary">
        Sailing
    </span>
    <span  *ngIf="swimspot.skimboarding" class="wild-badge-primary">
        Skim Boarding
    </span>
    <span  *ngIf="swimspot.scubadiving" class="wild-badge-primary">
        Scuba Diving
    </span>
    <span  *ngIf="swimspot.snorkeling" class="wild-badge-primary">
        Snorkeling
    </span>
    <span  *ngIf="swimspot.whitewaterrafting" class="wild-badge-primary">
        Rafting
    </span>
    <span  *ngIf="swimspot.freediving" class="wild-badge-primary">
        Free Diving
    </span>
    <span  *ngIf="swimspot.canyoning" class="wild-badge-primary">
        Canyoning
    </span>
    <span  *ngIf="swimspot.hydrofoil" class="wild-badge-primary">
        Hydrofoil
    </span>
    <span  *ngIf="swimspot.yachting" class="wild-badge-primary">
        Yachting
    </span>
    <span  *ngIf="swimspot.flyboarding" class="wild-badge-primary">
        Flyboarding
    </span>
    <span  *ngIf="swimspot.rowing" class="wild-badge-primary">
        Rowing
    </span>
    <span  *ngIf="swimspot.icediving" class="wild-badge-primary">
        Ice Diving
    </span>
    <span  *ngIf="swimspot.triathlon" class="wild-badge-primary">
        Triathlon
    </span>
    <span  *ngIf="swimspot.swimrun" class="wild-badge-primary">
        Swim Run
    </span>
    <span  *ngIf="swimspot.walking" class="wild-badge-primary">
        Walking
    </span>
    <span  *ngIf="swimspot.iceswimming" class="wild-badge-primary">
        Ice Swimming
    </span>
    <span  *ngIf="swimspot.coasteering" class="wild-badge-primary">
       Coasteering
    </span>
      <span  *ngIf="swimspot.bigDropOff" class="wild-badge-primary">
          Big drop off
      </span>
      <span  *ngIf="swimspot.gentleSlope" class="wild-badge-primary">
          Gentle Slope
      </span>
      <span  *ngIf="swimspot.easyToWalkTo" class="wild-badge-primary">
          Easy Walk
      </span>
      <span  *ngIf="swimspot.hasABeach" class="wild-badge-primary">
          Beach
      </span>
      <span  *ngIf="swimspot.itIsMuddy" class="wild-badge-primary">
          Muddy
      </span>
      <span  *ngIf="swimspot.lotsOfReeds" class="wild-badge-primary">
          Reeds
      </span>
      <span  *ngIf="swimspot.mountainViews" class="wild-badge-primary">
          Mountains
      </span>
      <span  *ngIf="swimspot.nearbyParking" class="wild-badge-primary">
          Parking
      </span>
      <span  *ngIf="swimspot.quietLocation" class="wild-badge-primary">
          Quiet
      </span>
      <span  *ngIf="swimspot.rockyGround" class="wild-badge-primary">
          Rocky
      </span>
      <span  *ngIf="swimspot.stepsToGetIn" class="wild-badge-primary">
          Steps
      </span>
      <span  *ngIf="swimspot.paddling" class="wild-badge-primary">
          Paddle
      </span>
      <span  *ngIf="swimspot.skinnyDip" class="wild-badge-primary">
          Skinny Dip
      </span>
      <span  *ngIf="swimspot.picnic" class="wild-badge-primary">
          Picnic
      </span>
      <span  *ngIf="swimspot.trainAccess" class="wild-badge-primary">
          Train
      </span>
      <span  *ngIf="swimspot.cycling" class="wild-badge-primary">
          Cycle
      </span>
      <span  *ngIf="swimspot.boating" class="wild-badge-primary">
          Boat
      </span>
      <span  *ngIf="swimspot.supping" class="wild-badge-primary">
          SUP
      </span>
      <span  *ngIf="swimspot.pubs" class="wild-badge-primary">
          Pub
      </span>
      <span  *ngIf="swimspot.camping" class="wild-badge-primary">
          Camping
      </span>
      <span  *ngIf="swimspot.jumping" class="wild-badge-primary">
          Jumping
      </span>
      <span  *ngIf="swimspot.cliffs" class="wild-badge-primary">
          Cliffs
      </span>
      <span  *ngIf="swimspot.bridges" class="wild-badge-primary">
          Bridges
      </span>
      <span  *ngIf="swimspot.chutes" class="wild-badge-primary">
          Chutes
      </span>
      <span  *ngIf="swimspot.waterfalls" class="wild-badge-primary">
          Waterfalls
      </span>
      <span  *ngIf="swimspot.dogs" class="wild-badge-primary">
          Dog friendly
      </span>
      <span  *ngIf="swimspot.accessibility" class="wild-badge-primary">
          Accessible
      </span>
      <span  *ngIf="swimspot.payToSwim" class="wild-badge-primary">
          Pay to swim
      </span>
      <span  *ngIf="swimspot.longSwim" class="wild-badge-primary">
          Longswim
      </span>
      <span  *ngIf="swimspot.privateProperty" class="wild-badge-primary">
          Private Property
      </span>
      <span  *ngIf="swimspot.swing" class="wild-badge-primary">
          Swing
      </span>
      <span  *ngIf="swimspot.sunset" class="wild-badge-primary">
          Sunset
      </span>
      <span  *ngIf="swimspot.sunrise" class="wild-badge-primary">
          Sunrise
      </span>
      <span  *ngIf="swimspot.estuary" class="wild-badge-primary">
          Estuary
      </span>
      <span  *ngIf="swimspot.pools" class="wild-badge-primary">
          Pools
      </span>
      <span  *ngIf="swimspot.weir" class="wild-badge-primary">
          Weir
      </span>
      <span  *ngIf="swimspot.pebbles" class="wild-badge-primary">
          Pebbles
      </span>
      <span  *ngIf="swimspot.caves" class="wild-badge-primary">
          Caves
      </span>
      <span  *ngIf="swimspot.lido" class="wild-badge-primary">
          Lido
      </span>
      <span  *ngIf="swimspot.toilets" class="wild-badge-primary">
          Toilets
      </span>
      <span  *ngIf="swimspot.quarry" class="wild-badge-primary">
          Quarry
      </span>
  </div>
  `,
})
export class SwimSpotTagsComponent implements OnInit {
    @Input() swimspot: SwimSpot;
    BeaufortScale = BeaufortScale;
    WeatherClassification = WeatherClassification;

    constructor() { }

    ngOnInit(): void {
    }

}
