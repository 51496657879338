import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { recurringSchedulingOverview } from "../../shared/features/recurring_overview";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { workingHours } from "../../shared/features/workinghours";
import { timetables } from "../../shared/features/timetables";
import { calendarComponent } from "../../shared/features/calendar";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { schedulingListDataShared } from "./scheduling_data_listdata";
import { bookingExperienceListData } from "../bookingexperience/booking_experience_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { communicationsListData } from "../communications/communications_data_listdata";
import { usecases } from "../../shared/usecases";

export const schedulingDataShared: FeaturesData = {
  seoTitle: "Intelligent Booking Platform for Watersports",
  seoDescription: "Real Time Water Safety System to check people in and out of the Water quickly access emergency information",
  seoImage: schedulingListDataShared.image,
  seoKeyWords: "",
  page: schedulingListDataShared.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: schedulingListDataShared.title,
      subtitle: schedulingListDataShared.subtitle,
      paragraph: "One-Off's, Repeating, Rescheduling, it's all there",
    },
  ]
}

export const schedulingData: FeaturesData = {
  ...schedulingDataShared,
  ...{
    components:[
      ...schedulingDataShared.components,
      injectSharedComponent(calendarComponent, { colour: FeatureColour.WHITE, type: FeatureComponentType.BIGIMAGEFEATURE }),
      injectSharedComponent(workingHours, { colour: FeatureColour.WHITE, type: FeatureComponentType.BIGIMAGEFEATURE }),
      injectSharedComponent(recurringSchedulingOverview, { colour: FeatureColour.WHITE, type: FeatureComponentType.BIGIMAGEFEATURE }),
      injectSharedComponent(timetables, { colour: FeatureColour.WHITE, type: FeatureComponentType.BIGIMAGEFEATURE }),
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.FAQS,
        title: "",
        subtitle: "Frequently Asked Questions",
        data: [
          {
            title: "Can parents book for their kids?",
            subtitle: "Yes, parents great they're own profile and one for as many kids as they like. When you receive a child booking, you will have access to the child's information as well as the parenets contact details."
          },
          {
            title: "Can I reschedule bookings?",
            subtitle: "Yes, just can drag or edit any booking and your customers will be informed automatically."
          },
          {
            title: "How do I deal with Team Member holidays?",
            subtitle: "You simply add holidays to the calendar and booking availabiltiy will be adjusted automatically."
          },
          {
            title: "How many people can book into a single group booking?",
            subtitle: "You can book up to 100 people into a single group. If you need any more than this, please let us know and we can increase the limit."
          },
          {
            title: "Can I set up different working hours based on the seasons?",
            subtitle: "Yes, you can program different working hours for each month of the year."
          },
          ...commonBusinesFAQs
        ],
        ctas: businessCTAs,
      },
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.TEXTANDINFO,
        title: "Explore More",
        subtitle: "Scheduling is the Just Tip of the Iceburg",
        limit: 9,
        paragraph: "",
        data: [
          bookingExperienceListData,
          schedulingListDataShared,
          teamListDataShared,
          ordersFinanceListData,
          communicationsListData,
          crmListData
        ],
        ctas: {
          secondary: {
            text: "See All",
            page: WildNavigationType.ALLFEATURES
          }
        }
      },
      injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
    ]
  }
}