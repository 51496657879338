<app-wrapper header="Brand" paragraph="Manage your colour scheme, describe your company or group and add some style.">
    <button *ngIf="onboarding" (click)="backToDashboard()" type="button" class="wild-btn-primary w-52">
        <!-- Heroicon name: solid/plus -->
        <fa-icon icon="chevron-left"></fa-icon><span class="mr-1">&nbsp;</span> Back to Checklist
    </button>
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
            <main class="min-w-0 flex-1 lg:flex">
                <section #formwrapper aria-labelledby="primary-heading"
                    class=" min-w-0 flex-1 h-full lg:max-h-screen lg:overflow-y-scroll flex flex-col overflow-y-auto lg:order-first lg:pr-10 relative">

                    <app-form [form]="form" ctaText="Update Brand" disableDivide="true" (onSubmit)="onSubmit($event)"
                        [loading]="loading">
                        <app-inputgroup>
                            <app-input [readonly]="!business.url ? false : true" prefix="wildopenwater.com/"
                                [form]="form" type="text" name="url" description="URL" [span]=6 [value]="business.url">
                            </app-input>
                        </app-inputgroup>
                        <app-formtags [form]="form" name="tags" description="Tags" [options]="tags" [span]=6
                            [value]="selectedTags">
                        </app-formtags>
                        <p class="my-4 text-xs">Need more tags? Please <a
                                href="mailto:support@wildopenwater.com?subject=Need more tags" class="underline">let us
                                know</a>.</p>
                        <app-inputgroup *ngIf="business.mode == BusinessMode.ADVERTISE">
                            <app-input validation="Website url is invalid" [form]="form" type="text" name="bookingurl"
                                description="Booking URL" [span]=6 [value]="business.bookingurl"></app-input>
                        </app-inputgroup>
                        <app-inputgroup>
                            <app-input validation="Minimum of 4 characters" [form]="form" type="text" name="name"
                                description="Name" [span]=6 [value]="business.name"></app-input>
                            <app-textarea validation="Maximum of 2000 characters" [form]="form" type="text"
                                name="description" description="Bio" [span]=6 [value]="business.description">
                            </app-textarea>
                        </app-inputgroup>
                        <app-imageselect [valueChangeEvent]="eventsSubjectBannerImage.asObservable()"
                            (onNewClick)="uploadBannerPic(business.uploadedbannerimage)" newicon="upload"
                            newtext="upload" [form]="form" [options]="bannerOptions" name="uploadedbannerimage"
                            description="Banner Image" [value]="business.uploadedbannerimage"></app-imageselect>
                        <app-imageselect [valueChangeEvent]="eventsSubjectProfileImage.asObservable()"
                            (onNewClick)="uploadProfilePic(business.uploadedprofilepic)" newicon="upload"
                            newtext="upload" [form]="form" [options]="profilepicOptions" name="uploadedprofilepic"
                            description="Profile Picture" [value]="business.uploadedprofilepic"></app-imageselect>
                        <app-inputgroup>
                            <app-input validation="Website url is invalid" [form]="form" type="text" name="website"
                                placeholder="https://yourwebsite.com" description="Website URL" [span]=6
                                [value]="business.website"></app-input>
                            <app-input validation="Phone number invalid" [form]="form" type="text" name="phone"
                                placeholder="07777123456" description="Phone Number" [span]=6 [value]="business.phone">
                            </app-input>
                            <app-input validation="WhatsApp number invalid. Please include international code. (+44)"
                                [form]="form" type="text" name="whatsapp" placeholder="+447777123456"
                                description="WhatsApp Number" [span]=6 [value]="business.whatsapp">
                            </app-input>
                            <app-input validation="Email address invalid" [form]="form" type="text" name="email"
                                placeholder="name@email.com" description="Email Address" [span]=6
                                [value]="business.email"></app-input>
                            <app-input [form]="form" type="text" name="facebook"
                                placeholder="https://facebook.com/pagename" description="Facebook Page URL" [span]=6
                                [value]="business.facebook"></app-input>
                            <app-input validation="Invalid Instagram handle" prefix="@" [form]="form" type="text"
                                name="instagram" placeholder="yourinstagramusername" description="Instagram Username"
                                [span]=6 [value]="business.instagram"></app-input>
                            <app-input validation="Invalid Twitter handle" prefix="@" [form]="form" type="text"
                                name="twitter" placeholder="yourtwitterhander" description="Twitter Handle" [span]=6
                                [value]="business.twitter"></app-input>
                            <app-input validation="Invalid YouTube Channel URL" [form]="form" type="text" name="youtube"
                                placeholder="https://youtube.com/channel/channelname" description="YouTube URL" [span]=6
                                [value]="business.youtube"></app-input>
                        </app-inputgroup>
                    </app-form>
                </section>
                <!-- Secondary column (hidden on smaller screens) -->
                <aside class="hidden lg:block lg:flex-shrink-0 lg:order-last">
                    <div class="h-full relative flex flex-col w-96 overflow-y-auto pl-8 pt-8">
                        <div class="rounded-3xl border-black border-8 h-full bg-white" style="max-height: 750px;">
                            <iframe #iframe width="100%" height="100%"
                                class="w-full h-full max-h-screen pointer-events-none rounded-2xl "
                                [src]="domain + '/' + business.url + '?preview=1&iFrameQueryParam=' + iFrameQueryParam | safe"></iframe>
                        </div>
                    </div>
                </aside>
            </main>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="retry()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>