import { Component, Input} from '@angular/core';
import { FeatureColour, FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html' 
})   
export class FAQsComponent{
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
}
 