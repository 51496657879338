<app-wrapper>
    <div *ngIf="!hasUsername()">
        <app-empty icon="user" header="No Username" description="Choose a username to start adding spots"
            ctatext="Choose Username" [showcta]="true" (onClick)="username()"></app-empty>
    </div>
    <div *ngIf="hasUsername()">
        <app-steps [next]="nextSubject.asObservable()" [previous]="previousSubject.asObservable()" [steps]="steps">
        </app-steps>
        <app-swimspotarea *ngIf="activeStep == 'Area'" (done)="areaConfirmed($event)"></app-swimspotarea>
        <app-swimspotlocation *ngIf="activeStep == 'Location'" (done)="locationConfirmed($event)"
            (back)="goBackFromLocation($event)"></app-swimspotlocation>
        <app-addswimspotdetails (back)="goBackFromDetails()" (done)="detailsAdded($event)" [latlng]="latlng"
            *ngIf="activeStep == 'Details'">
        </app-addswimspotdetails>
        <app-addswimspotimages (onCreate)="created($event)" [swimSpotId]="swimSpotId" *ngIf="activeStep == 'Photos'">
        </app-addswimspotimages>
    </div>
</app-wrapper>