import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { NewActivityDTO } from '../../../../../../data/dtos/business/activities/NewActivityDTO';
import { ServiceStatus } from '../../../../../../data/enums/service_status';
import { ServiceType } from '../../../../../../data/enums/service_type';
import { BusinessServiceModel } from '../../../../../../data/models/business_service_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { UtilService } from 'src/shared/services/util/util_service';
import { ListServiceService } from '../../services/services/list/list_service_service';
import { NewActivityService } from '../services/newactivity_service';
import { AppointmentDetailsComponent } from './appointmentdetails.component';

@Component({
  template: `
  <app-modaltopper [header]="'New Activity'" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Create" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="cancel()">
      <app-inputgroup [ngClass]="{'hidden': allday}">
        <app-select (onChange)="serviceChanged($event)" [options]="serviceOptions" [form]="form" name="serviceId" [description]="loadingServicesOptions ? 'loading services...' :'Service'" [span]=6>
        </app-select>
      </app-inputgroup>
      <div [ngClass]="{'hidden': !selectedServiceToHideStuff}" >
        <span (click)="unselectService()" class="text-xs text-yellow-500 hover:underline cursor-pointer ml-1 mt-1">unselect service</span>
      </div>
      <app-inputgroup>
      <app-input validation="Letters and Numbers Only, Max 40 characters" [form]="form" type="text" name="name" description="Name" [span]=6></app-input> 
      </app-inputgroup>
      <app-inputgroup>
       <app-formdatepicker [span]=6 [form]="form" name="startDateIso" description="Start Date" [value]="startDate" ></app-formdatepicker>
      </app-inputgroup>
      <fieldset [ngClass]="{'hidden': selectedServiceToHideStuff}" class="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-2 mt-5">
          <app-checkbox [form]="form" label="All Day" name="allday" [value]="allday"></app-checkbox>
      </fieldset>
      <app-inputgroup [ngClass]="{'hidden': allday}">
        <app-select [options]="hourOptions" [form]="form" name="startHour" description="Hours" [span]=3 [value]="startHour">
        </app-select>
        <app-select [options]="minuteOptions" [form]="form" name="startMinutes" description="Minutes" [span]=3 [value]="startMinutes">
        </app-select>
      </app-inputgroup>
      <app-inputgroup [ngClass]="{'hidden': selectedServiceToHideStuff}" >
       <app-formdatepicker [span]=6 [form]="form" name="endDateIso" description="End Date" [value]="endDate" ></app-formdatepicker>
      </app-inputgroup>
      <app-inputgroup [ngClass]="{'hidden': allday || selectedServiceToHideStuff}">
        <app-select [options]="hourOptions" [form]="form" name="endHour" description="Hours" [span]=3 [value]="endHour">
        </app-select>
        <app-select [options]="minuteOptions" [form]="form" name="endMinutes" description="Minutes" [span]=3 [value]="endMinutes">
        </app-select>
      </app-inputgroup>
      <div  *ngIf="selectedServiceToHideStuff">
      <div class="rounded-md bg-blue-50 p-4 mt-3">
        <div class="flex">
          <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
          </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-blue-800">By the way</h3>
            <div class="mt-2 text-sm text-blue-700">
              <ul role="list" class="list-disc space-y-1 pl-5">
                <li> This service is <span *ngIf="selectedServiceToHideStuff.durationHours && selectedServiceToHideStuff.durationHours > 0">{{selectedServiceToHideStuff.durationHours}} hr<span *ngIf="selectedServiceToHideStuff.durationHours && selectedServiceToHideStuff.durationHours > 1">s</span><span *ngIf="selectedServiceToHideStuff.durationMinutes && selectedServiceToHideStuff.durationMinutes > 0">&nbsp;&&nbsp;</span></span><span *ngIf="selectedServiceToHideStuff.durationMinutes && selectedServiceToHideStuff.durationMinutes > 0">{{selectedServiceToHideStuff.durationMinutes}} minute<span *ngIf="selectedServiceToHideStuff.durationMinutes && selectedServiceToHideStuff.durationMinutes > 1">s</span></span>.</li>
                <li>You can adjust the duration once it's created.</li>
                <li>Team members can be added once it's created.</li>
                <li>This service is <b>
                  <span *ngIf="selectedServiceToHideStuff.status != ServiceStatus.LIVE && selectedServiceToHideStuff.status != ServiceStatus.PRIVATE">Not Live</span>
                  <span *ngIf="selectedServiceToHideStuff.status == ServiceStatus.PRIVATE">Private</span>
                  <span *ngIf="selectedServiceToHideStuff.status == ServiceStatus.LIVE">Live</span>
                </b></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
  </app-form> 
  `
})
export class NewAppointmentFormComponent implements OnInit {

  @Input() newstart: Date;
  @Input() newend: Date;
  @Output() updated = new EventEmitter<void>();
  ServiceStatus = ServiceStatus;

  constructor(
    private fb: UntypedFormBuilder,
    private utilService: UtilService,
    private newActivityService: NewActivityService,
    private panelService: PanelService,
    private serviceListService: ListServiceService,
    private dateService: DatesService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  hourOptions: SelectModel[] = [];
  minuteOptions: SelectModel[] = [];
  startDate: string = "";
  endDate: string = "";
  startHour: number = 9;
  startMinutes: number = 0;
  endHour: number = 10;
  endMinutes: number = 0;
  allday: boolean = false;
  loadingServicesOptions: boolean = false;
  serviceOptions: SelectModel[] = [];
  services: BusinessServiceModel[] = [];
  selectedServiceToHideStuff: BusinessServiceModel = null;

  serviceChanged(serviceId: string) {
    if (serviceId == "") {
      this.unselectService()
    } else {
      var activeService = this.services.find(s => s.id == serviceId);
      this.form.controls["name"].setValue(activeService.name);
      this.form.controls["allday"].setValue(false);
      this.selectedServiceToHideStuff = activeService;
    }
  }

  unselectService() {
    this.form.controls["serviceId"].setValue("");
    this.form.controls["name"].setValue("");
    this.form.controls["allday"].setValue(false);
    this.selectedServiceToHideStuff = null;
  }

  ngOnInit(): void {
    this.loadServicesOptionsList();
    if (this.newstart != null) {
      this.startDate = this.dateService.getIsoDateFromObjectNotInUTC(this.newstart);
      this.endDate = this.dateService.getIsoDateFromObjectNotInUTC(this.newstart);
      this.startHour = this.dateService.getHourFromDate(this.newstart);
      this.startMinutes = this.dateService.getMinuteFromDate(this.newstart);
      this.endHour = this.startHour + 1;
      this.endMinutes = 0;
    }
    this.hourOptions = this.utilService.getNumberOptions(24, 1);
    this.minuteOptions = this.utilService.getNumberOptions(12, 5);
    this.form = this.fb.group({
      serviceId: new UntypedFormControl('', []),
      name: new UntypedFormControl('', [Validators.required, Validators.max(40)]),
      startDateIso: new UntypedFormControl(this.startDate, [Validators.required]),
      allday: new UntypedFormControl(this.allday, [Validators.required]),
      startHour: new UntypedFormControl(this.startHour, [Validators.required]),
      startMinutes: new UntypedFormControl(this.startMinutes, [Validators.required]),
      endDateIso: new UntypedFormControl(this.endDate, []),
      endHour: new UntypedFormControl(this.endHour, []),
      endMinutes: new UntypedFormControl(this.endMinutes, []),
    });
    this.form.controls["allday"].valueChanges.pipe(
    ).subscribe((allday) => {
      this.allday = allday;
    });
  }



  loadServicesOptionsList() {
    this.loadingServicesOptions = true;
    this.serviceOptions.push({
      name: "not selected",
      value: ""
    })
    this.serviceListService.list().toPromise().then((services) => {
      this.services = services;
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        var typeDescription = "";
        if (service.type == ServiceType.CALENDARBASED)
          this.serviceOptions.push({
            name: service.name + " (On-Demand)",
            value: service.id
          })
        if (service.type == ServiceType.GROUP)
          this.serviceOptions.push({
            name: service.name + " (Scheduled)",
            value: service.id
          })
      }
    }).finally(() => {
      this.loadingServicesOptions = false;
    })
  }

  onSubmit(data: any) {
    this.loading = true;
    var dto: NewActivityDTO = {
      serviceId: data.allday == true ? "" : data.serviceId,
      name: data.name,
      allday: data.allday,
      startDate: this.dateService.addDays(data.startDateIso, 0),
      endDate: this.dateService.addHours(data.endDateIso, 0),
      startHour: data.startHour,
      startMinutes: data.startMinutes,
      endHour: data.endHour,
      endMinutes: data.endMinutes
    }
    this.newActivityService.create(dto)
      .then((idObject: any) => {
        this.next(idObject.id);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Add Activity", message, NotifyType.ERROR);
  }

  next(id: String) {
    this.modalService.hide();
    this.updated.emit();
    this.openActivity(id)
  }

  openActivity(id: String) {
    var settings: PanelModel = {
      header: "Activity Details",
      component: AppointmentDetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: id,
        }
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: () => {
            this.updated.emit();
          }
        }
      ]
    }
    this.panelService.show(settings);
  }

  cancel() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
