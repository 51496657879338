import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardlistwrapper',
  templateUrl: './cardlistwrapper.component.html',
  styleUrls: ['./cardlistwrapper.component.scss']
})
export class CardlistwrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
