import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: '[app-swimspotlistitem]',
  template: `
  <li class="relative">
      <div (click)="click()" class="cursor-pointer relative group block w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100  overflow-hidden">
        <img [src]="src" alt="" class="object-cover pointer-events-none group-hover:opacity-75">
        <div *ngIf="favourite || notfavourite" class="absolute top-0 w-full flex justify-end text-xl" style="color:#2E82FF"> 
          <span class="cursor-pointer p-1">
            <fa-icon *ngIf="notfavourite" [icon]="['far', 'heart']"></fa-icon>
            <fa-icon *ngIf="favourite" [icon]="['fas', 'heart']"></fa-icon>
          </span>
        </div>
      </div> 
      <p class="mt-2 block text-xs font-medium text-gray-800 truncate pointer-events-none">
          {{caption}}
      </p>
      <p class="block text-sm font-medium text-gray-500 pointer-events-none"></p>
    </li>
  `
})
export class SwimspotListItemComponent implements OnInit {

  @Input() src: string;
  @Input() caption: string;
  @Input() favourite: boolean;
  @Input() id: string;
  @Input() notfavourite: boolean;
  @Output() onClick = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit(this.id);
  }

}
