<h1 class="wild-header">New Team Member</h1>
<p class="wild-paragraph">Enter email address and select a role(s)</p>
<app-form [form]="form" ctaText="Invite" disableDivide="true" (onSubmit)="onSubmit($event)" [loading]="loading"
    (onCancel)="cancel()" cancelText="Cancel">
    <app-inputgroup>
        <app-input [form]="form" type="email" name="email" description="Email Address" [span]=6></app-input>
    </app-inputgroup>
    <label class="block wild-label mt-5">
        Roles
    </label>
    <fieldset class="flex flex-col space-y-3 mt-2">
        <app-checkbox [form]="form" label="Admin" name="admin"></app-checkbox>
        <app-checkbox [form]="form" label="Contacts" name="contacts"></app-checkbox>
        <app-checkbox [form]="form" label="Calendar" name="calendar"></app-checkbox>
        <app-checkbox [form]="form" label="Orders" name="orders"></app-checkbox>
        <app-checkbox [form]="form" label="Finance" name="finance"></app-checkbox>
        <app-checkbox [form]="form" label="Safety" name="safety"></app-checkbox>
        <app-checkbox [form]="form" label="Working Hours" name="workingHours"></app-checkbox>
    </fieldset>
</app-form>