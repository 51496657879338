import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessActivitiesService } from '../services/businessactivities_service';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { ContactdetailsComponent } from '../../contacts/components/contactdetails/contactdetails.component';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ActivityContactsService } from '../services/activitycontacts_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { BusinessContactWrapperDTO } from '../../../../../../data/dtos/contacts/BusinessContactWrapperDTO';


@Component({
  selector: 'app-appointmentdetailscontactlist',
  template: `
  <ng-container *ngIf="contacts$ | async as contacts; else loadingOrError">
    <div *ngIf="!contacts; else loaded"></div>
    <ng-template #loaded>
    <ul *ngIf="contacts?.length > 0" role="list"
        class="flex-1 divide-y divide-gray-200 overflow-y-auto">
        <app-listitem *ngFor="let contact of contacts" [notclickable]="true"
            [image]="contact?.contact.user_profile?.profilePicture?.image"
            [firstName]="contact?.contact?.personal?.firstName ?? 'unknown'" 
            [surname]="contact?.contact?.personal?.surname ?? 'unknown'" [subline]="contact?.contact.email ?? 'unknown'"
            [object]="contact" 
            ctaonetext="open" ctaoneicon="user-alt" (ctaoneclick)="openContact($event)" 
            ctatwotext="attended" ctatwoicon="check" (ctatwoclick)="attended($event)" ctatwoclass="text-green-400"
            ctathreetext="DNA" ctathreeicon="close" (ctathreeclick)="didNotAttend($event)" ctathreeclass="text-red-600"
            ctaendtext="Remove" ctaendicon="trash" (ctaendclick)="removeContact($event)" ctaendclass="text-red-400"
            [sublinetwo]="contact?.attended ? 'Attended' : contact?.didNotAttend ? 'DNA' : 'No Status'"
            [sublinetwoclass]="contact?.attended ? 'text-green-400' : contact?.didNotAttend ? 'text-red-600' : 'text-yellow-400'"
            >
        </app-listitem>
    </ul>
  </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <div class="p-6">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
    </ng-template>
</ng-template>
  `
})

export class AppointmentDetailsContactListComponent implements OnInit {

  @Input() id: string;
  contacts$: Observable<BusinessContactWrapperDTO[]>;
  error: ErrorModel;

  constructor(
    private modalService: ModalService,
    private panelService: PanelService,
    private activityContactService: ActivityContactsService,
    private notifyService: NotifyService,
    private businessActivityService: BusinessActivitiesService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.contacts$ = null;
    this.contacts$ = this.businessActivityService.getActivityContacts(this.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  removeContact(contact: BusinessContactWrapperDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: `Are you sure you want to remove ${contact?.contact?.personal?.firstName ?? "unknown"} ${contact?.contact?.personal?.surname ?? "unknown"}?`,
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemoveContact(contact);
      }
    }
    this.modalService.show(settings);
  }

  processRemoveContact(contact: BusinessContactWrapperDTO) {
    this.activityContactService.del(this.id, contact?.contact.id)
      .then(() => {
        this.notifyService.notify("Removed Contact", "Perfect!", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.notifyService.notify("Contact Removal", error.message, NotifyType.ERROR);
      });
  }

  openContact(contact: BusinessContactWrapperDTO) {
    var settings: PanelModel = {
      header: "Contact",
      component: ContactdetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: contact?.contact.id
        }
      ]
    }
    this.panelService.show(settings);
  }

  attended(contact: BusinessContactWrapperDTO) {
    if(contact.attended == true){
      this.notifyService.notify(`${contact?.contact?.personal.firstName ?? ""} attended!!!`, "", NotifyType.SUCCESS);
      return;
    }
    this.activityContactService.attended(this.id, contact?.contact.id)
      .then(() => {
        this.notifyService.notify(`${contact?.contact?.personal.firstName ?? ""} attended`, "", NotifyType.SUCCESS);
        contact.attended = true;
        contact.didNotAttend = false;
      })
      .catch((error) => {
        this.notifyService.notify("Contact Attendance", error.message, NotifyType.ERROR);
      });
  }

  didNotAttend(contact: BusinessContactWrapperDTO) {
    if(contact.didNotAttend == true){
      this.notifyService.notify(`${contact?.contact?.personal.firstName ?? ""} DNA!!!`, "", NotifyType.SUCCESS);
      return;
    }
    this.activityContactService.didnotattend(this.id, contact.contact.id)
      .then(() => {
        this.notifyService.notify(`${contact?.contact?.personal?.firstName ?? ""} DNA`, "", NotifyType.SUCCESS);
        contact.didNotAttend = true;
        contact.attended = false;
      })
      .catch((error) => {
        this.notifyService.notify("Contact Attendance", error.message, NotifyType.ERROR);
      });
  }

} 
