import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const realTimeSafetyOverviewThreeScreenShots:FeaturesComponentData = {
    colour:FeatureColour.WHITE,
    type:FeatureComponentType.RIGHTIMAGEINFOSECTION,
    image:"https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Frealtime%2Fthree-screen-shots-safety-system.png?alt=media&token=6202b062-8b90-461c-9ad7-0a934983193c",
    title:"Safety System",
    subtitle:"Monitor Who is In The Water",
    paragraph:"Real-time view of who's in, what they're doing & who is due out soon.",
    ctas:{
      secondary:{
        text:"Learn More",
        page:WildNavigationType.REALTIMESAFETYSYSTEM
      }
    }
  }