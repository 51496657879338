<app-wild-header></app-wild-header>
<div *ngIf="beacons$">
  <ng-container *ngIf="beacons$ | async as beacons; else loadingOrError">
    <div *ngIf="!beacons || beacons.length == 0; else beacon">
      <app-empty (click)="home()" ctatext="Home" icon="bell" header="Beacon not found"
        description="Beacon does not exist or has expired" [showcta]="true">
      </app-empty>
    </div>
    <ng-template #beacon>
      <div *ngFor="let beacon of beacons" class="relative bg-white p-6">
        <div class="lg:mx-auto lg:max-w-7xl">
          <div class="relative">
            <h1 class="text-2xl">Beacon</h1>
            <p class="text-md">The information below has been provided by {{beacon.userfullname}}. This beacon is to let
              you know they are swimming at {{beacon.swimSpotLight.name}} on {{beacon.swimDateIso | date:'EEEE, MMMM d,
              y,
              h:mm a'}}. Please make sure you check in with them and ensure they return safely</p>
            <h2 class="text-sm mt-6">Swimmer</h2>
            <p class="text-xl">{{beacon.userfullname}}</p>
            <h2 class="text-sm mt-6">Swim Date</h2>
            <p class="text-xl">{{beacon.swimDateIso | date:'EEEE, MMMM d, y, h:mm a'}}</p>
            <h2 class="text-sm mt-6">Swim Spot</h2>
            <p class="text-xl">{{beacon.swimSpotLight.name}}</p>
            <a (click)="openSwimSpot(beacon)" class="text-sm underline wild-link mt-1">Open Swim Spot</a>
            <h2 class="text-sm mt-6">what3words location</h2>
            <p class="text-xl"><span class="text-red-500 text-sm">///</span>{{beacon.swimSpotLight.wtwWords}}</p>
            <a target="_blank" [href]="beacon.swimSpotLight.wtwMap" class="text-sm underline wild-link mt-1">Open in
              what3words</a>
            <h2 class="text-sm mt-6">Latitude and Longitude</h2>
            <p class="text-xl ">{{beacon.swimSpotLight.latitude}},
              {{beacon.swimSpotLight.longitude}}
            </p>
            <a target="_blank"
              [href]="'https://maps.google.com/?q=' + beacon.swimSpotLight.latitude +',' + beacon.swimSpotLight.longitude"
              class="text-sm underline wild-link mt-1">Open in Google Maps</a>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
      </app-retry>
    </ng-container>
    <ng-template #loading>
      <app-loader [disablescreenheight]="true"></app-loader>
    </ng-template>
  </ng-template>
</div>
<app-footer></app-footer>