<app-wrapper header="Swim Spots"
    paragraph="Connect your business to lakes, rivers and beaches that you operate at so Wild can promote you"
    icon="plus" ctatext="Add Swim Spot" (onClick)="new()">
    <button *ngIf="onboarding" (click)="backToDashboard()" type="button" class="wild-btn-primary w-52 mb-3">
        <!-- Heroicon name: solid/plus -->
        <fa-icon icon="chevron-left"></fa-icon><span class="mr-1">&nbsp;</span> Back to Checklist
    </button>
    <ng-container *ngIf="swimspots$ | async as swimspots; else loadingOrError">
        <div *ngIf="!swimspots || swimspots?.spots.length == 0; else swimspotslist">
            <app-empty icon="swimmer" header="No Linked Swim Spots"
                description="Search for a Swim Spot or Create a new one and link it">
            </app-empty>
        </div>
        <ng-template #swimspotslist>
            <app-swimspotlistitemwrapper>
                <li *ngFor="let swimspot of swimspots.spots" [caption]="swimspot.name" [id]="swimspot.id"
                    (onClick)="openSwimSpot($event)" [src]="
                swimspot?.image?.image == null ?
                'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839' :
                imagesUrl + swimspot?.image?.image + '_640x640.jpeg?alt=media'" app-swimspotlistitem>
                </li>
            </app-swimspotlistitemwrapper>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>