import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cardlistitem',
  templateUrl: './cardlistitem.component.html',
  styleUrls: ['./cardlistitem.component.scss']
})
export class CardlistitemComponent implements OnInit {

  @Input() id: any;
  @Input() header: string;
  @Input() description: string;
  @Input() ctaonetext: string;
  @Input() ctaoneicon: string;
  @Input() image: string;
  @Output() onCtaOneClick = new EventEmitter<any>();
  @Input() ctatwotext: string;
  @Input() ctatwoicon: string;
  @Output() onCtaTwoClick = new EventEmitter<any>();
  @Input() pills: string[];
  @Input() ctaOneRed: boolean;
  @Input() catOneGreen: boolean;
  @Input() ctaTwoRed: boolean;
  @Input() ctaTwoGreen: boolean;
  @Input() flat: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  ctaOneClicked() {
    this.onCtaOneClick.emit(this.id);
  }

  ctaTwoClicked() {
    this.onCtaTwoClick.emit(this.id);
  }

}
