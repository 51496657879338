import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorType } from '../../data/enums/error_type';
import { ErrorModel } from '../../data/models/error_model';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { GarminService } from '../services/garmin_service';
@Component({
  template: `
  
  <div class="max-w-3xl mx-auto px-4 sm:px-6 md:px-8 mt-4">
    <div>
      <h1 class="text-3xl">Garmin Integration</h1>
    </div>
    <div *ngIf="loading">
      <app-loader></app-loader>
    </div>
    <div *ngIf="success">
      <h1 class="text-3xl mt-12">Your Garmin Account is Now Linked with Wild.</h1>
      <p>Your future activites will now sync with your account</p>
      <button (click)="close()" class="wild-btn-primary mt-6">Close</button>
    </div>
    <div *ngIf="error">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" [hideretry]="true">
      </app-retry>
      <button (click)="close()" class="wild-btn-primary mt-6">Close</button>
    </div>
  </div>
  `,
})
export class GarminIntegrationPageComponent implements OnInit {

  loading: boolean = false;
  success: boolean = false;
  error: ErrorModel = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private garminService: GarminService,
    private commWithApp: CommunitcateWithAppService
  ) {

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      let oauth_token = params['oauth_token'];
      let oauth_verifier = params['oauth_verifier'];
      if (oauth_token === undefined && oauth_verifier === undefined) {
        this.loading = true;
        this.garminService.getUrl().then((url: string) => {
          window.location.href = url["url"];
        })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          })
      } else {
        //String checks on null are important here. Leave them alone!!!
        if (oauth_token == null || oauth_verifier == null || oauth_token == "null" || oauth_verifier == "null") {
          this.error = {
            code: ErrorType.USERDIDNOTGRANTACCESSTOGARMINONTHEFRONTEND,
            description: "If this was a mistake, please repeat the integration process and try again. ",
            message: "You did not grant Wild Access to Garmin"
          }
        } else {
          this.exchange(oauth_token, oauth_verifier);
        }
      }
    });
  }

  async exchange(oauth_token, oauth_verifier) {
    this.loading = true;
    this.garminService.exchange(oauth_token, oauth_verifier)
      .then(() => {
        this.success = true;
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      })
  }

  close() {
    this.commWithApp.pop();
  }

}
