import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { SubscribeService } from 'src/shared/services/subscribe_service';

@Component({
  templateUrl: './managemembership.component.html'
})
export class ManageMembershipComponent implements OnInit {

  public error: ErrorModel = null;
  imageUrl = environment.imagesUrl;
  isSubcribed$: Observable<any>;

  constructor(
    private modalService: ModalService,
    private subscribeService:SubscribeService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.isSubcribed$ = null
    this.isSubcribed$ = this.subscribeService.isSubscribeObservable().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  manage(){
    this.subscribeService.manageSubscriptionModal();
  }

  gopro(){
    this.subscribeService.subscribeModal();
  }

}
