import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { UserMembershipsDTO } from 'src/data/dtos/coupons/UserMembershipsDTO';
import { UserMembershipsService } from '../../../services/usermemberships_service';
import { UserMembershipDTO } from 'src/data/dtos/coupons/UserMembershipDTO';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MembershipStatus } from 'src/data/enums/memberships/business/membership_status';
import { MembershipUserSubscriptionStatus } from 'src/data/enums/memberships/business/membership_user_subscription_status';

@Component({
  templateUrl: './usermemberships.component.html',
})
export class UserMembershipsComponent implements OnInit {

  memberships$: Observable<UserMembershipsDTO>;
  error: ErrorModel;
  MembershipUserSubscriptionStatus = MembershipUserSubscriptionStatus;
  isAppUser: boolean = false;
  imagesUrl = environment.imagesUrl;

  constructor(
    private userMembershipsService: UserMembershipsService,
    private location: Location,
    private modalService: ModalService,
    private activatedRoute:ActivatedRoute,
    private auth: AuthenticationService,
    private navigation: NavigationService) { }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      params;
      if (params.cardupdated != undefined && params.cardupdated != null && (params.cardupdated == "1" || params.cardupdated == 1)) {
        this.showcardupdated();
      } else {
        this.load();
      }
    });
  }

  load() {
    this.memberships$ = null;
    this.memberships$ = this.userMembershipsService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  removeQueryParams() {
    const newUrl = '/user/memberships';
    this.location.replaceState(newUrl);
  }

  showcardupdated(){
    var settings: ModalModel = {
      header: "Card Updated",
      description: "Your payment card has been updated successfully. Please allow a couple of minutes for this to reflect on your membership.",
      design: ModalDesign.CENTRAL,
      type: ModalType.SUCCESS,
      ctaonetext: "Close",
      hidectatwo: true,
      ctaonetextclick: () => {
        this.load();
        this.removeQueryParams();
      },
    }
    this.modalService.show(settings);
  }

  cancel(membership:UserMembershipDTO){
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to cancel this membership?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processCancellation(membership);
      }
    }
    this.modalService.show(settings);
  }

  processCancellation(membership:UserMembershipDTO){
    var settings: ModalModel = {
      header: "Cancelling",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    this.userMembershipsService.cancel(membership.id).then((url:any)=>{
      var settings: ModalModel = {
        header: "Cancelled",
        description: "Membership has been cancelled",
        design: ModalDesign.CENTRAL,
        type: ModalType.SUCCESS,
        ctaonetext: "Close",
        hidectatwo: true,
        ctaonetextclick: () => {
          this.load();
        },
      }
      this.modalService.show(settings);
    }).catch((error) => {
      var settings: ModalModel = {
        header: "Membership Cancellation Unsuccessful",
        description: error.message,
        design: ModalDesign.CENTRAL,
        type: ModalType.ERROR,
        ctaonetext: "Close",
        hidectatwo: true,
        ctaonetextclick: () => {

        },
      }
      this.modalService.show(settings);
    })
    .finally(() => {
      this.modalService.hide();
    });
  }

  deleteMembership(membership:UserMembershipDTO){
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.ERROR,
      description: "Are you sure you want to delete this membership. It will immediately prevent you accessing any membership services?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processDeletion(membership);
      }
    }
    this.modalService.show(settings);
  }

  processDeletion(membership:UserMembershipDTO){
    var settings: ModalModel = {
      header: "Deleting",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    this.userMembershipsService.delete(membership.id).then((url:any)=>{
      var settings: ModalModel = {
        header: "Deleted",
        description: "Membership has been deleted",
        design: ModalDesign.CENTRAL,
        type: ModalType.SUCCESS,
        ctaonetext: "Close",
        hidectatwo: true,
        ctaonetextclick: () => {
          this.load();
        },
      }
      this.modalService.show(settings);
    }).catch((error) => {
      var settings: ModalModel = {
        header: "Membership Deletion Unsuccessful",
        description: error.message,
        design: ModalDesign.CENTRAL,
        type: ModalType.ERROR,
        ctaonetext: "Close",
        hidectatwo: true,
        ctaonetextclick: () => {

        },
      }
      this.modalService.show(settings);
    })
    .finally(() => {
      this.modalService.hide();
    });
  }

  update(membership:UserMembershipDTO){
    var settings: ModalModel = {
      header: "Loading...",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    this.userMembershipsService.updatePaymentDetailsLink(membership.id).then((url:any)=>{
      var settings: ModalModel = {
        disableBodyScroll: true,
        design: ModalDesign.CENTRAL,
        type: ModalType.WARNING,
        description: "Are you sure you want to update your payment details?",
        header: "Are you sure?",
        close: true,
        href: url.url,
        ctaonetext: "Yes",
        ctatwotext: "No",
        hidectatwo:false,
        ctaonetextclick: async () => {
          this.processCancellation(membership);
        },
        ctatwotextclick() {
          this.modalService.hide();
        },
      }
      this.modalService.show(settings);
    });
  }

}
