import { FeaturesComponentData, FeatureColour, FeatureComponentType } from "../FeaturesData";
import { openWaterCoachListData } from "../usecases/openwatercoach/openWaterCoach_listdata";
import { openWaterVenueListData } from "../usecases/openwatervenue/openWaterVenue_listdata";
import { sailingSchoolListData } from "../usecases/sailingschool/sailingschools_listdata";
import { supSchoolListData } from "../usecases/supschool/supschools_listdata";
import { surfSchoolListData } from "../usecases/surfschool/surfschools_listdata";
import { swimSchoolListData } from "../usecases/swimschool/swimschools_listdata";
import { businessCTAs } from "./ctas/business_ctas";


export const usecases: FeaturesComponentData = {
  colour: FeatureColour.GRAY,
  type: FeatureComponentType.IMAGECLOUD,
  title: "Use Cases",
  subtitle: "For All Water Based Businesses",
  paragraph: "",
  data: [
    openWaterCoachListData,
    openWaterVenueListData,
    sailingSchoolListData,
    supSchoolListData,
    swimSchoolListData,
    surfSchoolListData
  ],
  ctas: businessCTAs,
}