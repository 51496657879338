import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BusinessStatus } from '../../../../../../data/enums/business_status';
import { BusinessPublicModel } from '../../../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from '../../../services/business_service/business_service';
import { BusinessStatusService } from '../../../services/business_status/business_status_service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {

  public error: ErrorModel = null;
  business$: Observable<BusinessPublicModel>;
  BusinessStatus = BusinessStatus;
  onboarding: boolean = false;


  constructor(
    private modalService: ModalService,
    private businessService: BusinessService,
    private businessStatusService: BusinessStatusService,
    private notifyService: NotifyService,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => { if (params?.onboarding == 1) this.onboarding = true; })
    this.load();
  }

  load() {
    this.business$ = null
    this.business$ = this.businessService.getPublicBusiness().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  goLive() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to go live?",
      header: "Go Live!",
      close: true,
      ctaonetext: "Let's do it!",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.procecssGoLive();
      }
    }
    this.modalService.show(settings);
  }

  async procecssGoLive() {
    var settings: ModalModel = {
      header: "Going Live",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    this.businessStatusService.updateStatus(BusinessStatus.LIVE)
      .then(async () => {
        this.notifyService.notify("You're Live", "Your business status has been set to Live", NotifyType.SUCCESS);
        if (this.onboarding == true) {
          var settings: ModalModel = {
            header: "Your Business is Now Live",
            description: "Your business is now available for bookings and shown on the Wild Website & App. Head back to your dashboard and share your link, let's get some bookings!",
            design: ModalDesign.CENTRAL,
            type: ModalType.SUCCESS,
            ctaonetext: "Back to Dashboard!",
            hidectatwo: true,
            ctaonetextclick: () => {
              this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
            },
          }
          this.modalService.show(settings);
        } else {
          var settings: ModalModel = {
            header: "Your Business is Now Live",
            description: "Your business is now available for bookings and shown on the Wild Website & App. Now would be a great time to share your booking link.",
            design: ModalDesign.CENTRAL,
            type: ModalType.SUCCESS,
            ctaonetext: "Go to Dashboard!",
            hidectatwo: true,
            ctaonetextclick: () => {
              this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
            },
          }
          this.modalService.show(settings);
        }
      })
      .catch((error) => {
        var settings: ModalModel = {
          header: "Error Going Live",
          description: error.message,
          design: ModalDesign.CENTRAL,
          type: ModalType.ERROR,
          ctaonetext: "Back to Dashboard",
          ctaonetextclick: () => {
            this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
          },
          hidectatwo: true
        }
        this.modalService.show(settings);
      })
  }

  setToDraft() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Your business will no longer be available for bookings",
      header: "Set to Draft?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processSetToDraft();
      }
    }
    this.modalService.show(settings);
  }

  processSetToDraft() {
    var settings: ModalModel = {
      header: "Setting Draft",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    this.businessStatusService.updateStatus(BusinessStatus.DRAFT)
      .then(async () => {
        this.notifyService.notify("Set to draft", "You can set this back to live whenever you want", NotifyType.SUCCESS);
        var settings: ModalModel = {
          header: "Business Set to Draft",
          description: "Your business is now no longer available for bookings.",
          design: ModalDesign.CENTRAL,
          type: ModalType.WARNING,
          ctaonetext: "Close",
          ctaonetextclick: () => {
            this.load();
          },
          hidectatwo: true
        }
        this.modalService.show(settings);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
  }


  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

}