import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeoService } from 'src/shared/services/seo/seo.service';

@Component({
  selector: 'app-sos',
  templateUrl: './sos.component.html',
})
export class SOSComponent implements OnInit {

  constructor(private seo: SeoService, private router: Router) { }

  ngOnInit(): void {
    this.seo.update("Safe Outdoor Swimming", "Be more prepared for your swim by using the SOS safety system", "swimming, open water, lake, river, beach, Safety", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/sosbrochurepage%2Fsosbadges.png?alt=media&token=037cc36c-cf5e-42be-b486-bf59b4b79131");
  }
}
