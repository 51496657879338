import { Injectable } from '@angular/core';
import { Auth, authState, createUserWithEmailAndPassword, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup } from "@angular/fire/auth";
import { from, Observable, of } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { FirestoreService } from '../firestore/firestore.service';
import { UserProfileModel } from '../../../data/models/user_profile_model';
import { ModalModel } from '../modal/modal_model';
import { ModalDesign } from '../modal/modal_design';
import { ModalService } from '../modal/modal_service';
import { FirestoreQueryType } from '../../../data/enums/firestore_query_type';
import { UsernameComponent } from 'src/wild/modules/auth/components/username.component';
import { RegisterComponent } from 'src/wild/modules/auth/components/register.component';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { environment } from 'src/environments/environment';
import { delay, map, mapTo, switchMap } from 'rxjs/operators';
import { HttpService } from '../http/http_service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // user: User | undefined;

  constructor(
    private afAuth: Auth,
    private http: HttpService,
    private firestoreService: FirestoreService,
    private modalService: ModalService,
    private storage: StorageService
  ) { }


  get userId(): string {
    return this.storage.getCookie("loggedin");
  }

  //TODO - replace with firebase.USER typings
  checkUser(): Observable<any> {
    return authState(this.afAuth);
  }

  async getBearerToken() {
    var token = await this.afAuth.currentUser.getIdToken(true);
    if (token == undefined || token == null && token == "")
      return "";
    return token;
  }

  public getToken$(): Observable<any> {
    return from(this.afAuth.currentUser.getIdTokenResult(true));
  }

  loginWithCustomToken(token: string): Observable<any> {
    return from(signInWithCustomToken(this.afAuth, token)).pipe(delay(500), map(token => {
      this.setLoggedInCookie();
      return from(this.getProfileAndSetImageAndUsername()).pipe(map(x => {
        return this.checkUser();
      }));
    }));
  }


  get isAppUser(): boolean {
    if (this.storage.getCookie("appuser") == "1")
      return true;
    return false;
  }

  get isWidgetUser(): boolean {
    if (window["widgetuser"] == 1)
      return true;
    return false;
  }

  async loginWithEmailAndPassword(email: string, password: string) {
    await signInWithEmailAndPassword(this.afAuth, email, password)
      .then(() => {
        this.setLoggedInCookie();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }


  async getCustomToken(): Promise<string> {
    var token = await this.http.get(`${environment.api}/user/auth/customtoken`).toPromise();
    return token?.token ?? "";
  }

  //Used to navigate from widget to main site
  async getCustomTokenUrl(navType: WildNavigationType): Promise<string> {
    var token = await this.getCustomToken();
    var url = `${environment.domain}/customtokenauth?nav=${navType}&token=${token}`
    return url;
  }



  async createAccount(email: string, password: string) {
    await createUserWithEmailAndPassword(this.afAuth, email, password)
      .then(() => {
        this.setLoggedInCookie();
      })
      .catch((error) => {
        // if(error?.code == 'auth/email-already-in-use')
        this.handleError(error);
      });
  }

  async forgottenPassword(email: string) {
    await sendPasswordResetEmail(this.afAuth, email)
      .catch((error) => {
        this.handleError(error);
      });
  }

  async loginWithGoogle() {
    //TODO! Restore persistance
    // await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await signInWithPopup(this.afAuth, new GoogleAuthProvider())
      .then(() => {
        this.setLoggedInCookie();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async loginWithFacebook() {
    //TODO! Restore persistance
    // await this.afAuth.setPersistence(this.afAuth.setPersistence.LOCAL);
    await signInWithPopup(this.afAuth, new FacebookAuthProvider())
      .then(() => {
        this.setLoggedInCookie();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async loginWithApple() {
    //TODO! Restore persistance
    // await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      "redirect_uri": environment.appleRedirectUrl
    });
    redirectUri:
    // For web your redirect URI needs to be the host of the "current page",
    // while for Android you will be using the API server that redirects back into your app via a deep link
    //   Uri.parse(
    // 'https://wild-dev-1.firebaseapp.com/__/auth/handler',
    await signInWithPopup(this.afAuth, provider)
      .then(() => {
        this.setLoggedInCookie();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  isLoggedIn(): boolean {
    try {
      const loggedIn = this.storage.getCookie("loggedin");
      //IMPORTANT TO CHECK AFAUTH HERE TOO AS THERE ARE SOME CROSS SITE ISSUES
      if (loggedIn) {
        return true;
      } else {
        this.removeLoggedInCookies();
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  async logout() {
    await this.afAuth.signOut();
    this.removeLoggedInCookies();
  }

  removeLoggedInCookies() {
    this.storage.deleteCookie("loggedin");
    this.storage.deleteCookie("activeBusinessName");
    this.storage.deleteCookie("activeBusinessImage");
    this.storage.deleteCookie("activeBusinessUrl");
    this.storage.deleteCookie("activeBusinessId");
    this.storage.deleteCookie("profilePic");
    this.storage.deleteCookie("username");
  }

  getLoggedInCookie() {
    return this.storage.getCookie("loggedin");
  }

  setLoggedInCookie() {
    if (this.isWidgetUser == true) {
      this.storage.setCookie("loggedin", "1", 1000, false, true);
    } else {
      this.storage.setCookie("loggedin", "1", 1000);
    }

    if (this.afAuth?.currentUser?.uid != null && this.afAuth?.currentUser?.uid != "" && this.afAuth?.currentUser?.uid != undefined) {
      if (this.isWidgetUser == true) {
        this.storage.setCookie("loggedin", this.afAuth?.currentUser?.uid, 1000, false, true);
      } else {
        this.storage.setCookie("loggedin", this.afAuth?.currentUser?.uid, 1000);
      }
    }
  }

  async getProfileAndSetImageAndUsername(modallink: WildNavigationType = WildNavigationType.UNKNOWN) {
    await new Promise(r => setTimeout(r, 500));
    var userId = this.getLoggedInCookie();
    var profile = await this.firestoreService.getDocumentById("user_profiles", userId).toPromise() as UserProfileModel;
    if (profile != null && profile?.username != null) {
      var profilePic = profile.profilePicture?.image == null ? 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d' :
        environment.imagesUrl + profile.profilePicture.image + '_640x640.jpeg?alt=media'
      if (this.isWidgetUser == true) {
        this.storage.setCookie("profilePic", profilePic, 99999, false, true);
      } else {
        this.storage.setCookie("profilePic", profilePic, 99999);
      }

      if (profile.username != null || profile.username != undefined || profile.username != "") {
        if (this.isWidgetUser == true) {
          this.storage.setCookie("username", profile.username, 99999, false, true);
        } else {
          this.storage.setCookie("username", profile.username, 99999);
        }
      }
    }
  }

  hasUsername(): boolean {
    var username = this.storage.getCookie("username");
    if (username == null || username == undefined || username == "")
      return false;
    return true;
  }

  getProfilePic(): string {
    return this.storage.getCookie("profilePic", true);
  }

  getUsername(): string {
    return this.storage.getCookie("username");
  }

  isAuthedIfNotShowPopUp(): boolean {
    if (!this.isLoggedIn()) {
      var settings: ModalModel = {
        disableBodyScroll: true,
        design: ModalDesign.COMPONENT,
        component: RegisterComponent,
        componentInputs: [],
        componentOutputs: [],
        close: true,
        large: false,
        sideimage: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FLogin%20Page%20for%20Modal.png?alt=media&token=0d0e7e99-20f0-4a8d-adbd-425905328c4d"
      }
      this.modalService.show(settings);
      return false;
    } else {
      // if (!this.hasUsername()) {
      //   var settings: ModalModel = {
      //     disableBodyScroll: true,
      //     design: ModalDesign.COMPONENT,
      //     component: UsernameComponent,
      //     componentInputs: [],
      //     componentOutputs: [],
      //     close: false,
      //     large: false
      //   }
      //   this.modalService.show(settings);
      //   return false;
      // } else {
      return true;
      // }
    }
  }

  async updateUsername(username: string) {
    var userId = this.getLoggedInCookie();
    var existingusername = await this.firestoreService.getDocumentByQuery("user_profiles", [
      {
        operator: "==",
        property: "username",
        type: FirestoreQueryType.WHERE,
        value: username
      }
    ]).toPromise() as UserProfileModel;
    //Username does not exist or it's the current users
    if (existingusername == null || existingusername.id == userId) {
      await this.firestoreService.update(userId, "user_profiles", {
        "username": username
      });
      this.storage.setCookie("username", username, 99999);
      var profilePic = 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d';
      this.updateProfilePicCookie(profilePic);
    } else {
      throw Error("Username already exists");
    }
  }

  updateProfilePicCookie(profilePic: string) {
    this.storage.setCookie("profilePic", profilePic, 99999);
  }

  handleError(error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    var message = "Please try again";
    switch (errorCode) {
      case "auth/user-not-found":
        message = "User not found";
        break;
      case "auth/wrong-password":
        message = "Incorrect Password";
        break;
      case "auth/cancelled-popup-request":
        message = "Login Cancelled";
        break;
      case "auth/popup-closed-by-user":
        message = "Login Cancelled";
        break;
      case "auth/wrong-password":
        message = "Incorrect Password";
        break;
      case 'auth/weak-password':
        message = "Password should be at least 6 characters";
        break;
      case 'auth/email-already-in-use':
        message = "Email address already in use, please login instead";
        break;
      default:
        message = errorMessage;
        break;
    }
    throw Error(message);
  }



}
