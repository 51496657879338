import { ThisReceiver } from '@angular/compiler';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() form!: UntypedFormGroup;
  @Input() name!: string;
  @Input() description!: string;
  @Input() explanation!: string;
  @Input() span!: number;
  @Input() value!: boolean;
  notAnswered!: boolean;

  @HostBinding('class') get t() {
    return "p-0 border-0 bg-transparent sm:col-span-" + this.span;
  };

  setValue(value: boolean) {
    this.form.controls[this.name].setValue(value);
  }

  toggle() {
    if (this.form.controls[this.name].value == null) {
      this.setValue(true);
      this.notAnswered = false;
    } else {
      this.setValue(!this.form.controls[this.name].value);
    }
  }

  constructor() { }

  ngOnInit(): void {
    if (this.value)
      this.form.controls[this.name].setValue(this.value);
    if (this.form.controls[this.name].value == null) {
      this.notAnswered = true;
    } else {
      this.setValue(this.form.controls[this.name].value);
    }
  }
}
