import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  @Input() title: string;
  @Input() header: string;
  @Input() description: string;
  @Input() ctaText: string;
  @Output() click = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
