import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Favourite } from '../../../../../../../data/models/favourites_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { FavouritesService } from 'src/wild/modules/user/services/favourites_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../data/enums/navigation_type';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { AddCardComponent } from './addcard/addcard.component';
import { ModalService } from 'src/shared/services/modal/modal_service';

@Component({
  selector: '[app-paymentmethods]',
  template: `
  <app-wrapper header="Payment Methods" ctatext="Add Card" icon="plus" (onClick)="new()">
    test
  </app-wrapper>
  <div *ngIf="favourites$">
    <ng-container *ngIf="favourites$ | async as favourites; else loadingOrError">
        <div *ngIf="!favourites || favourites.length == 0; else favouriteslist">
            <app-empty icon="heart" header="No Favourites yet"
                description="Click on the heart on any swim spot page to add a favourite"></app-empty>
        </div>
        <ng-template #favouriteslist>
            <app-wrapper header="Favourites">
                <app-swimspotlistitemwrapper>
                    <li *ngFor="let favourite of favourites" [id]="favourite.swimSpotId"
                        (onClick)="openSwimSpot($event)" [caption]="favourite?.swimSpot?.name"
                        [src]="
                        favourite?.swimSpot?.image == null ?
                        'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839' :
                        'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/images%2Fresized%2F' + favourite?.swimSpot?.image?.image + '_640x640.jpeg?alt=media'"
                        app-swimspotlistitem>
                    </li>
                </app-swimspotlistitemwrapper>
            </app-wrapper>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-wrapper>
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </app-wrapper>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</div>
`,
})
export class PaymentMethodsComponent implements OnInit {

  favourites$: Observable<Favourite[]>;
  error: ErrorModel;

  constructor(
    private favouritesService: FavouritesService,
    private modalService: ModalService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.load();
  }

  new() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AddCardComponent,
      close: true
    }
    this.modalService.show(settings);
  }

  load() {
    this.favourites$ = null;
    this.favourites$ = this.favouritesService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  openSwimSpot(id) {
    this.navigation.navigate(WildNavigationType.USERSWIMSPOTDETAILS, {}, id);
  }

}
