<!-- This example requires Tailwind CSS v2.0+ -->
<div [ngClass]="{
    'hidden':!show,
    'block':show
}" class="fixed z-40 inset-0 overflow-y-auto " aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-0 sm:pt-4 px-0 sm:px-4 pb-0 sm:pb-20 text-center sm:block "
        style="    background: #090909b5">
        <div aria-hidden="true"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div [ngClass]="{
            'sm:max-w-lg':settings?.large != true && !settings?.sideimage,
            'sm:max-w-4xl':settings?.large == true && !settings?.sideimage,
            'max-w-4xl ':settings?.sideimage,
            'p-4 sm:p-6 pt-10':!settings?.sideimage
        }"
            class="min-h-screen sm:min-h-0 ease-out duration-300 inline-block align-bottom bg-white rounded-none sm:rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full">
            <div (click)="close()" *ngIf="settings?.close" class="sm:block absolute top-0 right-0 pt-2 pr-2 z-50">
                <button type="button"
                    [ngClass]="{'text-gray-400': !settings?.sideimage,'text-gray-400 md:text-white': settings?.sideimage }"
                    class="rounded-md  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ">
                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div *ngIf="settings?.design == ModalDesign.LOADING">
                <div class="mt-3 text-center sm:mt-0">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        {{settings?.header}}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            {{settings?.description}}
                        </p>
                    </div>
                </div>
                <div class="h-40 relative">
                    <app-loader [disablescreenheight]="true"></app-loader>
                </div>
            </div>
            <div *ngIf="settings?.design == ModalDesign.RIGHTALIGNEDBUTTONS">
                <div class="sm:flex sm:items-start">
                    <div [ngClass]="{
                        'bg-green-100':settings?.type == ModalType.SUCCESS,
                        'bg-yellow-100':settings?.type == ModalType.WARNING,
                        'bg-red-100':settings?.type == ModalType.ERROR,
                        'bg-blue-100':settings?.type == ModalType.INFO
                    }"
                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">

                        <svg *ngIf="settings?.type == ModalType.SUCCESS" class="h-6 w-6 text-green-600"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <svg *ngIf="settings?.type == ModalType.ERROR" class="h-6 w-6 text-red-600"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <svg *ngIf="settings?.type == ModalType.WARNING" class="h-6 w-6 text-yellow-400"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                        </svg>
                        <svg *ngIf="settings?.type == ModalType.INFO" class="h-6 w-6 text-blue-400"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                            {{settings?.header}}
                        </h3>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">
                                {{settings?.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="settings?.design == ModalDesign.CENTRAL">
                <div [ngClass]="{
                    'bg-green-100':settings?.type == ModalType.SUCCESS,
                    'bg-yellow-100':settings?.type == ModalType.WARNING,
                    'bg-red-100':settings?.type == ModalType.ERROR,
                    'bg-blue-100':settings?.type == ModalType.INFO
                }" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full">

                    <!-- Heroicon name: outline/check -->
                    <svg *ngIf="settings?.type == ModalType.SUCCESS" class="h-6 w-6 text-green-600"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <svg *ngIf="settings?.type == ModalType.ERROR" class="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <svg *ngIf="settings?.type == ModalType.WARNING" class="h-6 w-6 text-yellow-400"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd" />
                    </svg>
                    <svg *ngIf="settings?.type == ModalType.INFO" class="h-6 w-6 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        {{settings?.header}}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            {{settings?.description}}
                        </p>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'min-h-full flex flex-col sm:flex-row': settings?.sideimage}">
                <div class=" sm:px-6 lg:px-12"
                    [ngClass]="{'sm:w-1/2 flex flex-col justify-center lg:flex-none ': settings?.sideimage}">
                    <div [ngClass]="{'mx-auto w-full max-w-sm lg:w-96': settings?.sideimage}" #modalBody></div>
                </div>
                <div *ngIf="settings?.sideimage"
                    class="hidden sm:block relative w-0 sm:w-1/2 wild-bg-primary rounded-r-lg z-40"
                    style="min-height: 75vh;">
                    <img class="absolute inset-0 h-full w-full object-cover rounded-r-lg" [src]="settings?.sideimage"
                        alt="">
                </div>
            </div>
            <div *ngIf="settings?.href && settings?.design != ModalDesign.COMPONENT" class="mt-5 sm:mt-4">
                <a [href]="settings?.href" [target]="!settings?.target ? '_self' : settings?.target" type="button"
                    class="w-full wild-btn-primary">
                    {{settings?.ctaonetext}}
                </a>
            </div>
            <div *ngIf="!settings?.href && settings?.design != ModalDesign.COMPONENT"
                class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button (click)="ctaOneClick()" *ngIf="settings?.design == ModalDesign.CENTRAL"
                    class="w-full sm:ml-2 mb-3 sm:mb-0" [ngClass]="{ 
                        'wild-btn-primary':settings?.type == ModalType.SUCCESS || settings?.type == ModalType.INFO,
                        'wild-btn-warning':settings?.type == ModalType.WARNING,
                        'wild-btn-error':settings?.type == ModalType.ERROR
                }" type="button">
                    {{settings?.ctaonetext ?? 'Ok'}}
                </button>
                <button (click)="ctaTwoClick()" *ngIf="!settings?.hidectatwo && settings?.design == ModalDesign.CENTRAL"
                    type="button" class="wild-btn-light w-full">
                    {{settings?.ctatwotext ?? 'Cancel'}}
                </button>
                <button (click)="ctaOneClick()" [ngClass]="{
                    'wild-btn-primary':settings?.type == ModalType.SUCCESS || settings?.type == ModalType.INFO,
                    'wild-btn-warning':settings?.type == ModalType.WARNING,
                    'wild-btn-error':settings?.type == ModalType.ERROR
                }" *ngIf="settings?.design == ModalDesign.RIGHTALIGNEDBUTTONS" type="button"
                    class="sm:ml-3 w-full sm:w-auto">
                    {{settings?.ctaonetext ?? 'Ok'}}
                </button>
                <button (click)="ctaTwoClick()"
                    *ngIf="!settings?.hidectatwo && settings?.design == ModalDesign.RIGHTALIGNEDBUTTONS" type="button"
                    class="wild-btn-light mt-3 sm:mt-0 w-full sm:w-auto">
                    {{settings?.ctatwotext ?? 'Cancel'}}
                </button>
            </div>
        </div>
    </div>
</div>