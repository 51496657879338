import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../../FeaturesData";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { realTimeSafetyOverview } from "../../shared/features/realtime_overview";
import { usecases } from "../../shared/usecases";
import { bookingExperienceListData } from "../bookingexperience/booking_experience_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { realTimeSafetyListData } from "./realtimesafety_data_listdata";

export const realTimeSafetyData: FeaturesData = {
  
    seoTitle: "Intelligent Water Safety System",
    seoDescription: "Real Time Water Safety System to check people in and out of the Water & quickly access emergency information",
    seoImage: realTimeSafetyListData.image,
    seoKeyWords: "",
    page: realTimeSafetyListData.page,
    components: [
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.SIMPLEHERO,
        ctas: businessCTAs,
        title: realTimeSafetyListData.title,
        subtitle: realTimeSafetyListData.subtitle,
        paragraph: "Available on any device so you're all connected to the water",
      },
      injectSharedComponent(realTimeSafetyOverview, { type: FeatureComponentType.RIGHTIMAGEINFOSECTION, colour: FeatureColour.WHITE }),
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.BIGIMAGEFEATURE,
        image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Frealtime%2Fbands-pile-min.png?alt=media&token=1f12b5da-f505-4c22-9267-fc26e865cd81",
        title: "Safety Bands",
        subtitle: "Hire or Sell",
        paragraph: "Our flexible system allows you to use our safety bands on a hire or purchase model",
        data:[
          {
            icon: "ruler", title: "Adjustable.", subtitle: "All shapes and sizes."
          },
          {
            icon: "wifi", title: "High Frequency.", subtitle: "Fast scanning."
          }
        ]
      },
      {
        colour: FeatureColour.GRAY,
        type: FeatureComponentType.LEFTIMAGEINFOSECTION,
        image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fsafety-system-app-dashboard.png?alt=media&token=e8c1861a-c707-4397-95cb-ee8014c3cdb4",
        title: "Any Device",
        subtitle: "You're All Connected",
        paragraph: "Comptatible with all modern smart phones, laptops and desktop computers",
        data: [
          {
            icon: "plus", title: "Check-In.", subtitle: "It's really fast."
          },
          {
            icon: "close", title: "Check-Out.", subtitle: "Scan & done."
          },
          {
            icon: "first-aid", title: "Medical Profiles.", subtitle: "Just a click away."
          }
        ]
      },
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
        image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fsafety-system-app-scanner.png?alt=media&token=babe2fc6-2f39-465e-8f96-1f627c22e9d1",
        title: "NFC Tech",
        subtitle: "Reliable & Fast",
        paragraph: "Any phone that works with Apple Pay or Google Pay will work with our bands",
        data: [
          {
            icon: "mobile", title: "Apple.", subtitle: "Reliable & faster."
          },
          {
            icon: "mobile", title: "Android.", subtitle: "Works great too."
          },
          {
            icon: "wifi", title: "Fast Scanning.", subtitle: "Every 2 seconds." 
          }
        ]
      },
      {
        colour: FeatureColour.GRAY,
        type: FeatureComponentType.LEFTIMAGEINFOSECTION,
        image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fsafety-system-app-profiles.png?alt=media&token=0fbc7112-9c70-4277-9b95-e1ed9509b88f",
        title: "Safe",
        subtitle: "Important Information",
        paragraph: "Colour coding makes it easy to see who's been in too long.",
        data: [
          {
            icon: "phone-square", title: "Quick Contact.", subtitle: "Phone or message."
          },
          {
            icon: "users", title: "Parent & Child Profiles.", subtitle: "Just select."
          },
          {
            icon: "close", title: "Clear the Water.", subtitle: "In one click."
          }
        ]
      },
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.FAQS,
        title: "",
        subtitle: "Frequently Asked Questions",
        data: [
          {
            title: "How much does the Safety System cost to use?",
            subtitle: "Our real-time safety system is free to use. Safety is at the core of our mission so all our safety systems are available for free. We highly recommend purchasing our safety bands for the fastest and safest experience."
          },
          {
            title: "Can I use the Safety System without the booking system?",
            subtitle: "Yes, if you just want to take advantage of the safety system, you can register your business with Wild, people can simply join your business without booking and you're all set. It's still completely free to use."
          },
          {
            title: "Do you need any special equipment?",
            subtitle: "No, the safety system has been developed to work with any smart phone. As a general rule, if you have Apple or Google pay capabilities on your mobile phone, you're good to go. It works on the web dashboard too."
          },
          {
            title: "How can I get some safety bands?",
            subtitle: "You can purchase safety bands from us. Please contact us and we can give you a quote for the required amount."
          },
          {
            title: "Can I re-use bands?",
            subtitle: "Yes, you can pair bands for hire and they will automatically unpair when someone exits the water. When selling bands, the user will remain linked to the band upon checkout of the water. It's completely up to you how you run your organisation."
          },
          {
            title: "Do you have to use safety bands?",
            subtitle: "No, whilst safety bands are the fastest way to check people in and out, you can simply search your contacts and check them in manually."
          },
          ...commonBusinesFAQs
        ],
        ctas: businessCTAs,
      },
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.TEXTANDINFO,
        title: "Explore More",
        subtitle: "Way More than A Safety System",
        limit: 9,
        paragraph: "",
        data: [
          bookingExperienceListData,
          schedulingListDataShared,
          teamListDataShared,
          ordersFinanceListData,
          reportingListData,
          crmListData
        ],
        ctas: {
          secondary: {
            text: "See All",
            page: WildNavigationType.ALLFEATURES
          }
        }
      },
      injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
    ]
  
}