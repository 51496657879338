import { WildNavigationType } from "../../../../../data/enums/navigation_type";
import { FeatureColour, FeatureComponentType, FeaturesData } from "../FeaturesData";

const about = `Wild was formed to provide a free platform for open water enthuiasts to share information. Over the years it's evolved to provide state of the art technology solutions to businesses that operate on water.`;
export const aboutData:FeaturesData = { 
  seoTitle:"About Us | Passionate About Watersports & Technology",
  seoDescription:about,
  seoImage:"",
  seoKeyWords:"",
  page:WildNavigationType.ABOUT,
    components:[ 
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.SIMPLEHERO,
        title:"About",
        subtitle:"Built for Water Sports",
        paragraph:"It came from a passion for the water & technology"
      },
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.LOGOCLOUD,
      },
      {
        colour:FeatureColour.GRAY,
        type:FeatureComponentType.TEXTANDINFO,
        title:"Mission",
        subtitle:"Who We Are",
        paragraph:about
      },
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.IMAGECLOUD,
        title:"People",
        subtitle:"Founding Team",
        paragraph:"Meet the People Behind Wild",
        data:[{
          image:"https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fkeri-anne%20profile.png?alt=media&token=871895b5-e06a-46fb-9f23-5f576eb05891",
          title:"Keri-Anne Payne",
          subtitle:"Olympic medal holder and twice open water world champion, Keri-anne, believes that helping more people to get into the water safely, will help them swim with confidence and real joy."
        },{
          image:"https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile.jpg?alt=media&token=270e87da-5730-44d4-81cf-7a8d73b7a6d4",
          title:"Rob Gray",
          subtitle:"He is keen to give users of Wild a seamless experience, using the platform as a gateway to open water sports anywhere they choose to roam, safely and with the environment in mind."
        }]
      },
      {
        colour:FeatureColour.GRAY,
        type:FeatureComponentType.TEXTANDCIRCLES,
        title:"Values",
        subtitle:"What We're All About",
        limit:6,
        data:[
         {
          image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fabout%2Fdreambig.png?alt=media&token=4308f6d0-64dd-458c-8a0b-2fbac3f6d1af",
          title: "Your Business",
          subtitle: "Supporting it",
         },
         {
          image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fabout%2Fsafety.jpg?alt=media&token=1c42905b-6496-490a-9e08-1b3419fcbd02",
          title: "Safety",
          subtitle: "Improving it",
         },         
         {
          image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fabout%2Fenvironment.jpg?alt=media&token=9c27011a-0702-481a-bfec-eaa9b528e686",
          title: "Environment",
          subtitle: "Protecting it",
         },
         {
          image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fabout%2Fcommunity.jpg?alt=media&token=f106c0aa-bca1-40cc-a253-1b446664d8bf",
          title: "Community",
          subtitle: "Growing it",
         },
         {
          image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fabout%2Fpassion.jpg?alt=media&token=1d7735bd-e296-480e-9393-b60c995e1415",
          title: "Passion", 
          subtitle: "Following it",
         },
         {
          image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fabout%2Ftechnology.jpg?alt=media&token=abaef5af-0c15-418c-b019-49a1c1230e02",
          title: "Technology",
          subtitle: "Leveraging it",
         },
        ]
      },
  ] } 