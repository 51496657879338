import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { ContactActivitiesComponent } from './components/contactdetails/contactactivities.component';
import { ContactAuditComponent } from './components/contactdetails/contactaudit.component';
import { ContactdetailsComponent } from './components/contactdetails/contactdetails.component';
import { ContactDOBFormComponent } from './components/contactdetails/contactdobform.component';
import { ContactEmailFormComponent } from './components/contactdetails/contactemailform.component';
import { ContactEmergencyFormComponent } from './components/contactdetails/contactemergencyform.component';
import { ContactGenderFormComponent } from './components/contactdetails/contactgenderform.component';
import { ContactMedicalInfoFormComponent } from './components/contactdetails/contactmedicalinfoform.component';
import { ContactNameFormComponent } from './components/contactdetails/contactnameform.component';
import { ContactOrdersComponent } from './components/contactdetails/contactorders.component';
import { ContactPhoneNumberFormComponent } from './components/contactdetails/contactphonenumberform.component';
import { ContactProfileComponent } from './components/contactdetails/contactprofile.component';
import { NewContactFormComponent } from './components/newcontactform/newcontactform.component';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ContactsComponent } from './pages/contacts.component';
import { ContactWaiversComponent } from './components/contactdetails/contactwaivers.component';
import { ViewContactWaiverComponent } from './components/contactdetails/viewcontactwaiver.component';
import { ResendWaiverComponent } from './components/contactdetails/resendwaiver.component';


@NgModule({
    declarations: [
        //Pages 
        ContactsComponent,
        //Components
        NewContactFormComponent,
        ContactWaiversComponent,
        ContactOrdersComponent,
        ContactdetailsComponent,
        ContactProfileComponent,
        ContactPhoneNumberFormComponent,
        ContactEmailFormComponent,
        ContactGenderFormComponent,
        ResendWaiverComponent,
        ContactNameFormComponent,
        ContactActivitiesComponent,
        ContactDOBFormComponent, 
        ViewContactWaiverComponent,
        ContactEmergencyFormComponent,
        ContactMedicalInfoFormComponent,
        ContactAuditComponent,
    ],
    imports: [
        SharedModule,
        ContactsRoutingModule
    ]
})
export class ContactsModule { } 
