import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableHeader } from './tableheader_model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() headers: TableHeader[] | undefined;
  //E.G page one is 1 - page two is 10 - page three is 20 etc
  @Input() startingFrom: number;
  @Input() endingAt: number;
  @Input() currentPage: number;
  //Total number of records
  @Input() count: number;
  //Size of page so we can add this to startingFrom to get showing 10 - 20
  @Input() size: number;
  @Input() numberOfPages: number;
  @Output() pageChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void { }

  nextClick() {
    this.pageClick(this.currentPage + 1);
  }

  prevClick() {
    this.pageClick(this.currentPage - 1);
  }

  pageClick(page: number) {
    if (page == this.currentPage)
      return;
    this.pageChange.emit(page);
  }

  get pages(): number[] {
    return Array(this.numberOfPages).fill(0).map((x, i) => (i + 1));
  }

  get showPrev(): boolean {
    return this.currentPage > 1 && this.numberOfPages > 1;
  }

  get showNext(): boolean {
    return this.currentPage < this.numberOfPages;
  }
}
