import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { SourceDocumentType } from '../../../../../data/enums/source_document_type';
import { BusinessNoteDTO } from '../../../../../data/dtos/business/BusinessNoteDTO';
import { AddBusinessNoteDTO } from '../../../../../data/dtos/business/AddBusinessNoteDTO';

@Injectable({
    providedIn: 'root'
})
export class NotesService {

    constructor(
        private http: HttpService
    ) { }

    list(sourceDocumentType: SourceDocumentType, sourceDocumentId: string): Observable<BusinessNoteDTO[]> {
        return this.http.get(`${environment.api}/business/notes/${sourceDocumentType}/${sourceDocumentId}`);
    }

    async add(sourceDocumentType: SourceDocumentType, sourceDocumentId: string, dto: AddBusinessNoteDTO) {
        await this.http.post(`${environment.api}/business/notes/${sourceDocumentType}/${sourceDocumentId}`, dto).toPromise();
    }

    async update(noteId: string, dto: AddBusinessNoteDTO) {
        await this.http.post(`${environment.api}/business/notes/update/${noteId}`, dto).toPromise();
    }

    async remove(noteId: string) {
        await this.http.delete(`${environment.api}/business/notes/delete/${noteId}`).toPromise();
    }

}
