import { Component, Input } from '@angular/core';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { FeatureColour, FeaturesComponentData, FeaturesComponentListData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-textandcircles',
  templateUrl: './textandcircles.component.html'
})
export class TextAndCirclesComponent {
  constructor(private navigate: NavigationService) { }
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
  click(data: FeaturesComponentListData) {
    this.navigate.navigate(data.page);
  }
}
