import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { SideMenuService } from 'src/shared/services/sidemenu_service/sidemenu.service';
import { SideMenuItems } from './sidemenu_items_model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';
import { SearchService } from 'src/shared/services/search/search_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SearchResults } from '../../../data/models/SearchResults';
import { LocationResultType } from '../../../data/enums/location_result_type';
import { WaterwayType } from '../../../data/enums/waterway_type';
import { SearchResult } from '../../../data/models/SearchResult';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { GlobalSearchService } from 'src/wild/modules/business/services/globalsearch/globalsearch_service';
import { GlobalResultsDTO } from '../../../data/dtos/business/search/global/GlobalResultsDTO';
import { GlobalResultDTO } from '../../../data/dtos/business/search/global/GlobalResultDTO';
import { environment } from 'src/environments/environment';
import { GlobalSearchType } from '../../../data/enums/global_search_type';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { ContactdetailsComponent } from 'src/wild/modules/business/modules/contacts/components/contactdetails/contactdetails.component';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalService } from 'src/shared/services/modal/modal_service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})

export class SidemenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() items: SideMenuItems[];
  @Input() light: boolean;
  @Input() image: string;
  @Input() name: string;
  @Input() buttontext: string;
  @Output() onClick = new EventEmitter<void>();
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('notifications') notifications: ElementRef;


  menu: boolean = false;
  collapsed: boolean = true;
  menuAnimate: boolean = false;
  smallmenu: boolean = false;
  smallmenuAnimate: boolean = false;
  showNotifications: boolean = false;
  showNotificationsAnimate: boolean = false;
  form: UntypedFormGroup;
  searchResults: GlobalResultsDTO;
  GlobalSearchType = GlobalSearchType;
  showSearch: boolean;
  searchLoading: boolean;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;
  minSearchCharacters = 3;
  showMinCharactersNotReached: boolean;
  charactersTyped = 0;
  isAppUser: boolean = false;
  numberOfUnreadMessages: number = 0;
  imagesUrl = environment.imagesUrl;

  @ViewChild('scrollablecontainer') scrollablecontainer: ElementRef<HTMLElement>;

  constructor(
    private panelService: PanelService,
    private modalService: ModalService,
    private userService: UserService,
    private appComms: CommunitcateWithAppService,
    private globalSearch: GlobalSearchService,
    private navigationService: NavigationService,
    private businessService: BusinessService,
    private notify: NotifyService, private searchService: SearchService, private fb: UntypedFormBuilder, private sideMenuService: SideMenuService, private router: Router, private auth: AuthenticationService, private renderer: Renderer2, private storage: StorageService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        try {
          window.scrollTo(0, 0);
          this.sideMenuService.scrollTop();
        } catch (error) { }
      }
    });
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.closeMenu();
        this.closeSmallMenu();
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      // if (!this.dropdown.nativeElement.contains(e.target)) {
      //   this.closeSmallMenu();
      // }
      // if (!this.notifications.nativeElement.contains(e.target)) {
      //   this.closeNotifications();
      // }
      // if (!this.mobilemenucontainer.nativeElement.contains(e.target) && !this.mobilemenutoggle.nativeElement.contains(e.target)) {
      //   this.mobileMenu = false;
      // }
    });
  }
  ngOnDestroy(): void {
    this.sideMenuService.active = false;
  }

  navItemClicked(item: SideMenuItems) {
    if (item.onClick != undefined) {
      item.onClick();
    } else {
      this.router.navigate(item.route);
    }
  }

  logoClick() {
    this.navigationService.navigate(WildNavigationType.HOME);
  }

  ngAfterViewInit(): void {
    this.sideMenuService.setScrollableContainer(this.scrollablecontainer);
    this.sideMenuService.active = true;
  }

  listenForNotifications() {
    this.userService.listener().subscribe((user) => {
      this.numberOfUnreadMessages = user?.numberOfUnreadMessages ?? 0;
    })
  }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    if (this.isAppUser == true)
      this.items.push({
        description: "Back to app",
        route: ["", "business", "settings"],
        icon: "mobile",
        visible: true,
        onClick: () => {
          this.appComms.pop();
        }
      })
    this.listenForNotifications();
    // this.checkCollapsedCookiet();
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 1000);
  }

  open() {
    this.router.navigate([this.businessService.businessUrl]);
  }

  get profilePic(): string {
    return this.auth.getProfilePic();
  }

  async search(value) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      if (value.search.length >= this.minSearchCharacters) {
        this.showMinCharactersNotReached = false;
        this.searchLoading = true;
        await this.globalSearch.search(value.search).pipe(take(1)).toPromise()
          .then((searchResults) => {
            this.searchResults = searchResults;
          })
          .catch((error) => {
            this.handlerError(error);
          })
          .finally(() => {
            this.searchFinished();
          })
      } else {
        this.charactersTyped = value.search.length;
        this.searchLoading = false;
        this.showMinCharactersNotReached = true;
      }
    }
  }

  handlerError(message: string) {
    this.notify.notify("Search Error", message, NotifyType.ERROR);
  }

  searchFinished() {
    this.searchLoading = false;
  }

  clearSearch() {
    this.form.controls["search"].setValue("");
    this.searchResults = null;
  }

  selectSearchResult(result: GlobalResultDTO) {
    if (result.type == GlobalSearchType.CONTACT) {
      var settings: PanelModel = {
        header: "Contact",
        component: ContactdetailsComponent,
        componentInputs: [
          {
            inputName: "id",
            value: result.id
          }
        ]
      }
      this.panelService.show(settings);
    } else {
      this.navigationService.navigate(result.id);
    }
    this.clearSearch();
  }

  checkCollapsedCookiet() {
    if (this.storage.getCookie("collapsed") == "yes") {
      this.collapsed = true;
      this.sideMenuService.collapsed = true;
    }
  }

  toggleCollapse() {
    if (!this.collapsed) {
      this.collapsed = true;
      this.storage.setCookie("collapsed", "yes", 9999);
      this.sideMenuService.collapsed = true;
    } else {
      this.collapsed = false;
      this.storage.deleteCookie("collapsed");
      this.sideMenuService.collapsed = false;
    }
  }

  toggleMenu() {
    if (!this.menu) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  toggleSmallMenu() {
    if (!this.smallmenu) {
      this.openSmallMenu();
    } else {
      this.closeSmallMenu();
    }
  }

  toggleNotifications() {
    if (!this.showNotifications) {
      this.openNotifications();
    } else {
      this.closeNotifications();
    }
  }

  openMenu() {
    this.menu = true;
    setTimeout(() => {
      this.menuAnimate = true;
    }, 30)
  }

  closeMenu() {
    this.menuAnimate = false;
    setTimeout(() => {
      this.menu = false;
    }, 300)
  }

  openSmallMenu() {
    this.smallmenu = true;
    setTimeout(() => {
      this.smallmenuAnimate = true;
    }, 30)
  }

  closeSmallMenu() {
    this.smallmenuAnimate = false;
    setTimeout(() => {
      this.smallmenu = false;
    }, 300)
  }

  openNotifications() {
    this.showNotifications = true;
    setTimeout(() => {
      this.showNotificationsAnimate = true;
    }, 30)
  }

  closeNotifications() {
    this.showNotificationsAnimate = false;
    setTimeout(() => {
      this.showNotifications = false;
    }, 300)
  }

  click() {
    this.onClick.emit();
  }

  signOut() {
    this.closeSmallMenu();
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to sign out?",
      header: "Sign Out?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: () => {
        this.processSignOut();
      }
    }
    this.modalService.show(settings);
  }

  async processSignOut() {
    this.auth.logout();
    setTimeout(() => {
      window.location.href = "./"
    }, 500)
  }

}
