import { Component, OnInit, } from '@angular/core';
@Component({
  template: `
  <router-outlet></router-outlet>
  `,
})
export class UserIntegrationsWrapperPageComponent implements OnInit {
  constructor(

  ) {

  }

  ngOnInit(): void {

  }

}
