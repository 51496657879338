import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { AvailableMembershipsDTO } from 'src/data/dtos/services/AvailableMembershipsDTO';
import { BookingService } from 'src/booking/services/booking_service';
import { ErrorModel } from 'src/data/models/error_model';
import { SubscribeService } from '../services/subscribe_service';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: ` 
    <div *ngIf="error">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
          (onRetry)="load()">
      </app-retry>
    </div>
    <div *ngIf="!error && loading">
      <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <div *ngIf="!error && !loading">
      <div class="p-6 sm:p-0">
          <p class="text-xl font-bold mt-8 sm:mt-0">Subscribe</p>
          <p class="my-3">Take your watersports to the next level</p>
          <p class="my-3 sm:hidden">- No transaction fees</p>
          <p class="my-3 sm:hidden">- Watersports Analytics</p>
          <p class="my-3 sm:hidden">- Advanced Weather</p>
          <p class="my-3 sm:hidden">- Perks & Discounts</p>
          <div class="my-4">
            <a [href]="url.url" class="wild-btn-primary w-full block mb-4 text-center">Manage</a>
            <button (click)="hide()" class="wild-btn-light w-full block text-center">Close</button>
          </div>
      </div>
     
    </div>
  `
})
export class ManageSubscriptionModal implements OnInit {

  @Input() membership: AvailableMembershipsDTO;
  url:any;
  loading:boolean = false;
  error:ErrorModel = null;

  constructor(
    private subscribeService: SubscribeService,
    private route: ActivatedRoute,
    private modalService: ModalService) {
  }
  ngOnInit(): void {
    this.load();
  }

  load(){
    this.loading = true;
    this.error = null;
    var pathname = window.location.pathname;
    pathname = pathname.startsWith('/') ? pathname.substring(1) : pathname
    this.subscribeService.manageSubscriptionUrl(pathname)
    .then((url) => {
      this.url = url;
    })
    .catch((error) => {
        this.error = error;
    }).finally(() => {
      this.loading = false;
    });
  }

  hide() {
    this.modalService.hide();
  }

}
