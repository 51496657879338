import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessOrderDTO } from '../../../../../../../data/dtos/business/orders/businessOrderDTO';
import { OrderSearchResultsDTO } from '../../../../../../../data/dtos/business/search/order/OrderSearchResultsDTO';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { HttpService } from 'src/shared/services/http/http_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';

@Injectable({
    providedIn: 'root'
})
export class OrdersSearchService {

    constructor(
        private http: HttpService,
        private businessService: BusinessService,
        private firestore: FirestoreService
    ) { }

    search(search: string, page: number, size: number): Observable<OrderSearchResultsDTO> {
        return this.http.get(`${environment.api}/business/search/orders?q=${search}&page=${page}&size=${size}`);
    }

    getOrder(orderId: string): Observable<BusinessOrderDTO> {
        return this.http.get(`${environment.api}/business/orders/${orderId}`);
    }

}
