import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { FavouritesComponent } from './pages/user_children/favourites/favourites.component';
import { MyswimspotsComponent } from './pages/user_children/myswimspots/myswimspots.component';
import { UserComponent } from './pages/user/user.component';
import { UserRoutingModule } from './user-routing.module';
import { SwimSpotModule } from '../swimspots/swimspots.module';
import { UserdashboardComponent } from './pages/user_children/userdashboard/userdashboard.component';
import { UserprofileComponent } from './pages/user_children/userprofile/userprofile.component';
import { UserAddSwimSpotComponent } from './pages/user_children/useraddswimspot.component';
import { NotificationsComponent } from './pages/user_children/notifications/notifications.component';
import { PaymentMethodsComponent } from './pages/user_children/settings/components/paymentmethods.component';
import { SettingsComponent } from './pages/user_children/settings/settings.component';
import { AddCardComponent } from './pages/user_children/settings/components/addcard/addcard.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { UserOrdersomponent } from './pages/user_children/userorders/userorders.component';
import { UserDetailsComponent } from './pages/user_children/userdetails/userdetails.component';
import { UserActivitiesComponent } from './pages/user_children/useractivities/useractivities.component';
import { UserActivityDetailsComponent } from './pages/user_children/useractivitydetails/useractivitydetails.component';
import { UserCouponsComponent } from './pages/user_children/usercoupons/usercoupons.component';
import { LinkedAccountsComponent } from './pages/user_children/linkedaccounts/linkedaccounts.component';
import { UserActivityDetailsSharedComponent } from './components/useractivitydetailsshared/useractivitydetailsshared.component';
import { PubliclyAcccessiblyActivityDetailsComponent } from './pages/publiclyaccessibleactivity/publiclyaccessibleactivity.component';
import { UnsignedWaiversComponent } from './pages/user_children/unsignedwaivers/unsignedwaivers.component';
import { UserMembershipsComponent } from './pages/user_children/memberships/usermemberships.component';
import { ManageMembershipComponent } from './pages/user_children/managemembership/managemembership.component';

@NgModule({
    declarations: [
        //Pages
        UserComponent,
        UserDetailsComponent,
        UserdashboardComponent,
        LinkedAccountsComponent,
        UserMembershipsComponent,
        FavouritesComponent,
        MyswimspotsComponent,
        UserprofileComponent,
        UserAddSwimSpotComponent,
        ManageMembershipComponent,
        UserActivitiesComponent,
        PaymentMethodsComponent,
        UnsignedWaiversComponent,
        SettingsComponent,
        UserOrdersomponent,
        AddCardComponent,
        UserActivityDetailsComponent,
        UserCouponsComponent,
        PubliclyAcccessiblyActivityDetailsComponent,
        //components 
        NotificationsComponent,
        UserActivityDetailsSharedComponent,
    ],
    imports: [
        SharedModule,
        NgxStripeModule.forRoot(environment.stripe),
        SwimSpotModule,
        UserRoutingModule
    ]
})
export class UserModule { } 
