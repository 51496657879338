<app-wild-header></app-wild-header>
<ng-container *ngIf="group$ | async as group; else loadingOrError">
  <div *ngIf="!group; else searchResults">
    GROUP NOT FOUND
  </div>
  <ng-template #searchResults>
    <div class="mx-auto max-w-7xl px-4 text-center">
      <h3 class="text-gray-600 font-medium">You have been invited to join</h3>
      <h1 class=" mt-3 mb-5 text-2xl font-semibold">{{group.name}}</h1>
      <div *ngIf="group.image" class="w-32 h-32 mx-auto ">
        <div
          class="group block w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img alt="" class="object-cover pointer-events-none group-hover:opacity-75" [src]="group.image"><button
            type="button" class="absolute inset-0 focus:outline-none"></button>
        </div>
      </div>

      <h3 class="text-gray-600 font-medium mt-4">Step 1.</h3>
      <p>Download the Wild app</p>
      <div>
        <div class="flex  justify-evenly sm:justify-center items-center flex-row my-4">
          <a href="https://apps.apple.com/us/app/wild-open-water-swimming/id1576881664" target="_blank">
            <img class="h-10 sm:mr-3"
              src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fappstore3.png?alt=media&token=7781de9e-0c82-4005-b1a3-ab880f942057" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.swimwild.wild" target="_blank">
            <img class="h-10 sm:mt-0"
              src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fplaystore3.png?alt=media&token=73ed962f-39d0-4146-a750-9dc80ca4183b" />
          </a>
        </div>
      </div>
      <h3 class="text-gray-600 font-medium">Step 2.</h3>
      <p>Create an account</p>
      <h3 class="text-gray-600 font-medium mt-4">Step 3.</h3>
      <p>Click on your invite one more time</p>
      <hr class="mt-6" />
      <h3 class="text-gray-600 font-medium mt-8">About {{group.name}}</h3>
      <p>{{group.description}}</p>
    </div>
  </ng-template>
</ng-container>
<ng-template #loadingOrError>
  <ng-container *ngIf="error; else loading">
    <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
    </app-retry>
  </ng-container>
  <ng-template #loading>
    <div class="mx-auto max-w-7xl px-4 overflow-hidden">
      <app-loader></app-loader>
    </div>
  </ng-template>
</ng-template>