<app-wild-header></app-wild-header>
<ng-container *ngIf="locations$ | async as locations; else loadingOrError">
    <div *ngIf="!locations || locations.length == 0; else locationsloaded">
        <app-empty icon="map-pin" header="No Locations Found" [showcta]='true' ctatext="Start Over"
            (onClick)="goToDirectory()" description="There are no more locations to show">
        </app-empty>
    </div>
    <ng-template #locationsloaded>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="w-full flex justify-center">
            <div class="py-16 max-w-6xl">
                <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Countries</h2>
                <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    <li (click)="select(location)" *ngFor="let location of locations"
                        class="col-span-1 flex shadow-sm rounded-md cursor-pointer h-16">
                        <div
                            class="flex-shrink-0 flex items-center justify-center w-16 wild-bg-primary text-white text-sm font-medium rounded-l-md uppercase">
                            {{location.code}}</div>
                        <div
                            class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate hover:bg-gray-50">
                            <div class="flex-1 px-4 py-2 text-sm truncate">
                                <a class="text-gray-900 font-medium hover:text-gray-600">{{location.name}}</a>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
        <br />
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</ng-template>
<app-footer></app-footer>