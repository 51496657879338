import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessOrdersComponent } from './pages/businessorders.component';
const routes: Routes = [
  // {
  //   path: "",
  //   component: BusinessOrdersComponent,
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }