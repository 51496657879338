import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gender } from '../../../../data/enums/gender_type';
import { CommsPreferencesModel } from '../../../../data/models/comms_preferences_model';
import { EmergencyModel } from '../../../../data/models/emergency_model';
import { ImageModel } from '../../../../data/models/images_model';
import { MessageModel } from '../../../../data/models/message_model';
import { PersonalModel } from '../../../../data/models/personal_model';
import { PhoneModel } from '../../../../data/models/phone_model';
import { UserTODOModel } from '../../../../data/models/UserTODO_model';
import { UserModel } from '../../../../data/models/user_model';
import { UserProfileModel } from '../../../../data/models/user_profile_model';
import { FirestoreQueryType } from '../../../../data/enums/firestore_query_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private auth: AuthenticationService,
        private firestore: FirestoreService
    ) { }

    getUser(): Observable<UserModel> {
        var userId = this.auth.getLoggedInCookie();
        return this.getUserById(userId);
    }

    getUserById(userId: string): Observable<UserModel> {
        return this.firestore.getDocumentById("users", userId);
    }

    getUserProfile(): Observable<UserProfileModel> {
        var userId = this.auth.getLoggedInCookie();
        return this.getUserProfileById(userId);
    }

   

    getUserProfileById(userId: string): Observable<UserProfileModel> {
        return this.firestore.getDocumentById("user_profiles", userId);
    }

    updateCommsPreferences(comms_preferences: CommsPreferencesModel) {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.update(userId, "users", {
            "comms_preferences": comms_preferences
        });
    }

    disableMarketing() {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.update(userId, "users", {
            "comms_preferences.wild_general": false
        });
    }

    updateBookingProfile(personal: PersonalModel, phone: PhoneModel, gender: Gender, emergency: EmergencyModel, dob: string, sensitiveInfo:boolean, sensitiveInfoDetails:string) {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.update(userId, "users", {
            "dob": dob,
            "personal": personal,
            "phone": phone,
            "gender": gender,
            "emergency": emergency,
            "sensitiveInfo":sensitiveInfo,
            "sensitiveInfoDetails":sensitiveInfoDetails ?? ""
        });
    }

    async hasCompletedBookingProfile(): Promise<boolean> {
        var user = await this.getUser().toPromise();
        if(user?.personal?.firstName != undefined 
            && user?.personal?.firstName != "")
        return true;
        return false;
    }

    updateBasicBookingDetails(personal: PersonalModel, phone: PhoneModel) {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.update(userId, "users", {
            "personal": personal,
            "phone": phone
        });
    }

    getMessages(): Observable<MessageModel[]> {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.getListFromSubcollectionByQuery("users", userId, "messages", [
            {
                type: FirestoreQueryType.ORDERBY,
                value: "desc",
                property: "creation_date",
                operator: ""
            },
            {
                type: FirestoreQueryType.LIMIT,
                value: 20,
                property: "",
                operator: ""
            }
        ])
    }

    listener(): Observable<UserModel> {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.listener("users", userId);
    }

    async resetNotificationCount() {
        var userId = this.auth.getLoggedInCookie();
        await this.firestore.update(userId, "users", {
            "numberOfUnreadMessages": 0
        });
    }

    updateProfilePicture(image: ImageModel) {
        var userId = this.auth.getLoggedInCookie();
        return this.firestore.update(userId, "user_profiles", {
            "profilePicture": image
        });
    }

}