import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategorisedServicesDTO } from '../../data/dtos/services/CategorisedServicesDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { ScheduledServiceAndPricesWrapperDTO } from '../../data/dtos/services/ScheduledServiceAndPriceWrapperDTO';
import { ServiceAndPricesDTO } from '../../data/dtos/services/ServiceAndPricesDTO';
import { ServiceScheduleForCourseDTO } from '../../data/dtos/services/ServiceScheduleForCourseDTO';

@Injectable({
    providedIn: 'root'
})
export class ServicesPublicService {

    collection: string = "business";

    constructor(
        private http: HttpService
    ) { }


    getCategorisedServicesV2(businessId: string): Observable<CategorisedServicesDTO> {
        return this.http.get(`${environment.api}/user/services/getServicesAndPricesV2?businessId=${businessId}`);
    }

    getCategorisedServicesV2auth(businessId: string): Observable<CategorisedServicesDTO> {
        return this.http.get(`${environment.api}/user/services/getServicesAndPricesV2auth?businessId=${businessId}`);
    }

    getServicesAndPricesV2ForService(businessId: string, serviceId: string): Observable<ServiceAndPricesDTO> {
        return this.http.get(`${environment.api}/user/services/getServicesAndPricesV2/${serviceId}?businessId=${businessId}`);
    }

    getScheduledServices(businessId: string, startDate: string, endDate: string, serviceId: string): Observable<ScheduledServiceAndPricesWrapperDTO> {
        return this.http.get(`${environment.api}/user/services/getscheduleV2?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}&serviceId=${serviceId}`);
    }

    async getCourseSchedule({ businessId, serviceId, scheduleId }: { businessId: string; serviceId: string; scheduleId: string; }): Promise<ServiceScheduleForCourseDTO> {
        return await this.http.get(`${environment.api}/user/services/getScheduleForCourse?businessId=${businessId}&serviceId=${serviceId}&scheduleId=${scheduleId}`).toPromise();
    }

}
