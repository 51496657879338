<ng-container *ngIf=" contact$ | async as loadedcontact; else loadingOrError">
    <div *ngIf="!loadedcontact; else loaded">
        <app-empty icon="user" header="Contact not found" description=""></app-empty>
    </div>
    <ng-template #loaded>
        <div class="divide-y divide-gray-200">
            <app-panelheader [header]="
            (contact?.personal?.firstName + ' ' + 
            contact?.personal?.surname) +  
            (contact.personal?.pronouns ? ' (' + contact.personal?.pronouns + ')' : '')" [subheading]="contact?.user_profile ? '@' +
            contact.user_profile?.username : 'Linked Account'"
                [image]="contact?.user_profile?.profilePicture != null ? imageUrl + 
            contact?.user_profile?.profilePicture.image + 
            '_1080x1080.jpeg?alt=media' : 
            'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                (pageChanged)="pageChange($event)" [showrefreshbutton]="true" (onrefresh)="load()" ctaTwoText="Message Contact" (ctaTwoClick)="message()" >
            </app-panelheader>
            <div class="h-full flex flex-col bg-white overflow-y-scroll">
                <div class="mt-5 border-b border-gray-200">
                    <div class="px-6">
                        <nav class="-mb-px flex flex-col sm:flex-row sm:space-x-6">
                            <span (click)="page = ContactPages.PROFILE"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == ContactPages.PROFILE, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != ContactPages.PROFILE}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1  font-medium text-sm">Profile</span>

                            <span (click)="page = ContactPages.ACTIVITIES"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == ContactPages.ACTIVITIES, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != ContactPages.ACTIVITIES}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1  font-medium text-sm">Activities</span>

                            <span (click)="page = ContactPages.ORDERS"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == ContactPages.ORDERS, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != ContactPages.ORDERS}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1  font-medium text-sm">Orders</span>
                            <span (click)="page = ContactPages.WAIVERS"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == ContactPages.WAIVERS, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != ContactPages.WAIVERS}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1  font-medium text-sm">Waivers
                                ({{contact?.waiversCount ?? 0}})</span>
                            <span (click)="page = ContactPages.NOTES"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == ContactPages.NOTES, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != ContactPages.NOTES}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1  font-medium text-sm">Notes
                                ({{contact?.noteCount ?? 0}})</span>
                            <span (click)="page = ContactPages.MESSAGES"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == ContactPages.MESSAGES, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != ContactPages.MESSAGES}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 font-medium text-sm">Messages
                                ({{contact?.messagesCount ?? 0}})</span>
                        </nav>
                    </div>
                </div>
                <div class="py-5 sm:px-0">
                    <div *ngIf="page == ContactPages.AUDIT">
                        <app-contactaudit [contact]="contact"></app-contactaudit>
                    </div>
                    <div *ngIf="page == ContactPages.ORDERS">
                        <app-contactorders [id]="contact.id"></app-contactorders>
                    </div>
                    <div *ngIf="page == ContactPages.WAIVERS">
                        <app-contactwaivers [waiverEnabled]="loadedcontact.waiverEnabled"
                            [id]="contact.id"></app-contactwaivers>
                    </div>
                    <div *ngIf="page == ContactPages.PROFILE">
                        <div class="mx-4 mb-2" *ngFor="let banner of loadedcontact.banners">
                            <div [ngClass]="{'bg-red-50': banner.style == BusinessContactBannerStyle.DANGER,'bg-yellow-50': banner.style == BusinessContactBannerStyle.WARNING,'bg-blue-50': banner.style == BusinessContactBannerStyle.INFO,'bg-green-50': banner.style == BusinessContactBannerStyle.SUCCESS}"
                                class="rounded-md  p-6">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg *ngIf="banner.style == BusinessContactBannerStyle.WARNING"
                                            class="h-5 w-5  text-yellow-400" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <svg *ngIf="banner.style == BusinessContactBannerStyle.INFO"
                                            class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <svg *ngIf="banner.style == BusinessContactBannerStyle.SUCCESS"
                                            class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <svg *ngIf="banner.style == BusinessContactBannerStyle.DANGER"
                                            class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="ml-3 flex-1 md:flex md:justify-between">
                                        <p [ngClass]="{'text-red-700': banner.style == BusinessContactBannerStyle.DANGER,'text-yellow-700': banner.style == BusinessContactBannerStyle.WARNING,'text-blue-700': banner.style == BusinessContactBannerStyle.INFO,'text-green-700': banner.style == BusinessContactBannerStyle.SUCCESS}"
                                            class="text-sm "> <span class="font-semibold">{{banner.title}}.</span>
                                            {{banner.text}}</p>
                                        <p *ngIf="banner.type == BusinessContactBannerType.WAIVERNEEDSSIGNING"
                                            class="mt-3 text-sm md:mt-0 md:ml-6">
                                            <a (click)="resend()"
                                                class="cursor-pointer whitespace-nowrap font-medium text-red-700 hover:text-red-600">
                                                Send
                                                <span aria-hidden="true"> &rarr;</span>
                                            </a>
                                        </p>
                                        <p *ngIf="banner.type == BusinessContactBannerType.WAIVERSIGNEDWHENWAIVERSAREENABLED"
                                            class="mt-3 text-sm md:mt-0 md:ml-6">
                                            <a (click)="voidWaiver()"
                                                class="cursor-pointer whitespace-nowrap font-medium text-green-700 hover:text-green-600">
                                                Void
                                                <span aria-hidden="true"> &rarr;</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-contactprofile [contact]="contact"></app-contactprofile>
                    </div>
                    <div *ngIf="page == ContactPages.ACTIVITIES">
                        <app-contactactivities [id]="contact.id"></app-contactactivities>
                    </div>
                    <div *ngIf="page == ContactPages.NOTES">
                        <app-notes (count)="noteCountUpdated($event,contact)" [sourceDocumentId]="contact.id"
                            [sourceDocumentType]="SourceDocumentType.BUSINESSCONTACT"></app-notes>
                    </div>
                    <!-- MESSAGES  -->
                    <div *ngIf="page == ContactPages.MESSAGES">
                        <app-sentmessageslist [sourceDocumentId]="contact.id"
                            [sourceDocumentType]="SourceDocumentType.CONTACT"></app-sentmessageslist>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <div class="p-6">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</ng-template>