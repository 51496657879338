<div class="h-screen flex overflow-hidden bg-gray-50">
    <!-- MOBILE MENU START -->
    <div *ngIf="menu" class="fixed inset-0 flex z-40 md:hidden" role="dialog" aria-modal="true">
        <div (click)="toggleMenu()" [ngClass]="{'opacity-0':!menuAnimate,'opacity-100':menuAnimate}"
            class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300 "
            aria-hidden="true"></div>
        <div [ngClass]="{'-translate-x-full':!menuAnimate,'translate-x-0':menuAnimate}"
            class="wild-bg-primary relative flex-1 flex flex-col max-w-xs w-full transition ease-in-out duration-300 transform ">
            <div [ngClass]="{' opacity-0':!menuAnimate,'opacity-100':menuAnimate}"
                class="ease-in-out duration-300 absolute top-0 right-0 -mr-12 pt-2">
                <button (click)="toggleMenu()" type="button"
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div (click)="logoClick()" class="flex-shrink-0 flex items-center px-4 cursor-pointer">
                    <div>
                        <img class="h-14 w-auto"
                            src='https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2FWhite%20logo%20-%20no%20background%20small.png?alt=media&token=89ce00ec-7a07-40ac-a8ae-ce96d4241ed4'
                            alt="Wild">
                        <p *ngIf="!collapsed" class="uppercase wild-text-secondary relative left-1 bottom-2 text-sm">
                            <span *ngIf="light">Swimmer</span>
                            <span *ngIf="!light">Organiser</span>
                        </p>
                    </div>
                </div>
                <nav class="mt-5 px-2 space-y-1">
                    <a *ngFor="let item of items" (click)="navItemClicked(item)" [routerLinkActive]="'bg-blue-800'"
                        [routerLinkActiveOptions]="{exact: true}" [ngClass]="{'hidden':!item.visible}"
                        class="text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer">
                        <span class="text-white mr-3 flex-shrink-0 text-xl w-8">
                            <fa-icon class="w-8 h-8" [icon]="item.icon"></fa-icon>
                        </span>
                        <span>
                            {{item.description}}
                        </span>
                    </a>
                    <a (click)="signOut()"
                        class="text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer">
                        <span class="text-white mr-3 flex-shrink-0 text-xl w-8">
                            <fa-icon class="w-8 h-8" [icon]="'sign-out-alt'"></fa-icon>
                        </span>
                        <span>
                            Sign Out
                        </span>
                    </a>
                </nav>
            </div>
            <!-- MOBILE BOTTOM OF MENU START -->
            <div *ngIf="image && name && buttontext" class="flex-shrink-0 flex border-t  p-4 border-blue-800">
                <a (click)="click()" class="flex-shrink-0 group block cursor-pointer">
                    <div class="flex items-center">
                        <div>
                            <a class="cursor-pointer m-auto ">
                                <fa-icon class="text-2xl text-white" icon="exchange-alt"></fa-icon>
                            </a>
                        </div>
                        <div class="ml-3">
                            <p class="text-base font-medium text-white">
                                {{name}}
                            </p>
                            <p class="text-sm font-medium text-blue-200 group-hover:text-white">
                                Switch Business
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <!-- MOBILE BOTTOM OF MENU END -->
        </div>

        <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
        </div>
    </div>
    <!-- MOBILE MENU END -->
    <!-- DESKTOP SIDEBAR START  -->
    <div class="hidden  md:flex md:flex-shrink-0 wild-bg-primary">
        <div class="flex flex-col " [ngClass]="{'w-64':!collapsed, 'w-14':collapsed}">
            <div class="flex-1 flex flex-col min-h-0">
                <div class="flex-1 flex flex-col pt-1 pb-4 overflow-visible">
                    <div (click)="logoClick()" class="flex items-center flex-shrink-0 px-4 cursor-pointer h-14">
                        <div>
                            <img *ngIf="!collapsed" class="h-14 w-auto"
                                src='https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2FWhite%20logo%20-%20no%20background%20small.png?alt=media&token=89ce00ec-7a07-40ac-a8ae-ce96d4241ed4'
                                alt="Wild">
                            <img *ngIf="collapsed" class="h-10 w-auto"
                                src='https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fw-icon%20white%20small.png?alt=media&token=3934fa11-3ee9-4c3e-97bb-109e8aacae0b'
                                alt="Wild">
                            <p *ngIf="!collapsed"
                                class="uppercase wild-text-secondary text-sm relative left-1 bottom-2">
                                <span *ngIf="light">Swimmer</span>
                                <span *ngIf="!light">Organiser</span>
                            </p>
                        </div>
                    </div>
                    <nav class="mt-3 flex-1 px-2 space-y-1 relative">
                        <div *ngFor="let item of items" (click)="navItemClicked(item)"
                            [routerLinkActive]="'bg-blue-800'" [routerLinkActiveOptions]="{exact: true}"
                            [ngClass]="{'hidden':!item.visible,'justify-center':collapsed}"
                            class="cursor-pointer relative text-white hover:bg-blue-600 hover:bg-opacity-75  group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                            <span [ngClass]="{'w-8 mr-3':!collapsed}" class="text-white flex-shrink-0 text-xl ">
                                <fa-icon class="w-8 h-8" [icon]="item.icon"></fa-icon>
                            </span>
                            <div
                                class="absolute -right-36 top-2 items-center justify-center hidden mb-6 group-hover:flex z-50 ">
                                <div class="transform w-3 h-3 -mr-2 rotate-45 " style="background:#031d44"></div>
                                <span class="relative z-10 w-32">
                                    <span
                                        class="p-2 text-xs leading-none text-white whitespace-no-wrap shadow-lg bg-white font-semibold"
                                        style="border:1px solid #031d44;color:#031d44">{{item.description}}</span></span>
                            </div>
                        </div>
                        <div (click)="signOut()"
                            class="cursor-pointer relative text-white hover:bg-blue-600 hover:bg-opacity-75  group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                            <span [ngClass]="{'w-8 mr-3':!collapsed}" class="text-white flex-shrink-0 text-xl ">
                                <fa-icon class="w-8 h-8" [icon]="'sign-out-alt'"></fa-icon>
                            </span>
                            <div
                                class="absolute -right-36 top-2 items-center justify-center hidden mb-6 group-hover:flex z-50 ">
                                <div class="transform w-3 h-3 -mr-2 rotate-45 " style="background:#031d44"></div>
                                <span class="relative z-10 w-32">
                                    <span
                                        class="p-2 text-xs leading-none text-white whitespace-no-wrap shadow-lg bg-white font-semibold"
                                        style="border:1px solid #031d44;color:#031d44">Sign Out</span></span>
                            </div>
                        </div>
                    </nav>
                </div>
                <!-- DESKTOP BOTTOM OF MENU START -->
                <div style="min-height: 68px" *ngIf="image && name && buttontext"
                    class="flex-shrink-0 flex border-t items-center border-blue-800 px-1 py-2 relative group">
                    <a class="cursor-pointer m-auto " (click)="click()">
                        <fa-icon class="text-2xl text-white" icon="exchange-alt"></fa-icon>
                    </a>
                    <div
                        class="absolute -right-36 top-5 items-center justify-center hidden mb-6 group-hover:flex z-50 ">
                        <div class="transform w-3 h-3 -mr-2 rotate-45 " style="background:#031d44"></div>
                        <span class="relative z-10 w-32">
                            <span
                                class="p-2 text-xs leading-none text-white whitespace-no-wrap shadow-lg bg-white font-semibold"
                                style="border:1px solid #031d44;color:#031d44">Switch Business</span></span>
                    </div>
                </div>
                <!-- DESKTOP BOTTOM OF MENU END -->
            </div>
        </div>
    </div>
    <!-- DESKTOP SIDEBAR END  -->

    <!-- TOP MENU BAR START -->
    <div class="w-full flex flex-col">
        <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button (click)="toggleMenu()" type="button"
                class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
                </svg>
            </button>
            <div class="flex-1 px-4 flex justify-between">
                <!-- SEARCH START -->
                <div class="flex-1 flex">
                    <form class="w-full flex md:ml-0" [formGroup]="form">
                        <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                            <div class=" absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                    fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input autocomplete="off" formControlName="search" type="search" name="search" id="search"
                                class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                placeholder="Search Contacts and Quick Actions">
                        </div>
                    </form>
                </div>
                <!-- SEARCH END -->
                <div class="ml-4 flex items-center md:ml-6">
                    <!-- OPEN BUTTON START -->
                    <div *ngIf="!isAppUser" class="ml-3 relative">
                        <button (click)="open()" class="wild-btn-light"><span class="hidden md:inline-block">Open</span>
                            <fa-icon class="ml-0 md:ml-2" icon="external-link-alt"></fa-icon>
                        </button>
                    </div>
                    <!-- OPEN BUTTON END -->
                    <!-- NOTIFICATIONS START -->
                    <!-- <div #notifications class="relative">
                        <button (click)="toggleNotifications()" type="button"
                            class="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                            </svg>
                            <div class="bg-red-500 absolute -top-1 -left-1 text-xs rounded-full p-1 text-white h-4 w-4 flex justify-center items-center"
                                *ngIf="this.numberOfUnreadMessages > 0">
                                {{this.numberOfUnreadMessages}}</div>
                        </button>
                        <div *ngIf="showNotifications"
                            [ngClass]="{'opacity-0 translate-y-1':!showNotificationsAnimate,'opacity-100 translate-y-0':showNotificationsAnimate}"
                            class="transition ease-out duration-200 absolute z-10 -right-14 transform translate-x-1 mt-3 px-2 w-screen max-w-md sm:px-0">
                            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-y-scroll bg-white"
                                style="height:350px;">
                                <app-notificationslist></app-notificationslist>
                            </div>
                        </div>
                    </div> -->
                    <!-- NOTIFICATIONS END -->
                    <!-- PROFILE DROPDOWN START -->
                    <!-- <div #dropdown class="ml-3 relative">
                        <div>
                            <button (click)="toggleSmallMenu()" type="button"
                                class="bg-white border border-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                <img class="h-8 w-8 rounded-full"
                                    [src]="profilePic != null && profilePic != '' ? profilePic : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                                    alt="">
                            </button>
                        </div>
                        <div *ngIf="smallmenu"
                            [ngClass]="{'transform opacity-0 scale-95':!smallmenuAnimate,'transform opacity-100 scale-100':smallmenuAnimate}"
                            class="transition ease-out duration-100 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                            role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                            <a [routerLink]="['','user']" class="block px-4 py-2 text-sm text-gray-700" role="menuitem"
                                tabindex="-1" id="user-menu-item-0">Swimmer</a>
                            <a (click)="signOut()" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                        </div>
                    </div> -->
                    <!-- PROFILE DROPDOWN END -->
                </div>

            </div>
            <!-- SEARCH RESULTS START -->
            <div *ngIf="showSearch"
                class="px-2 sm:px-4 w-full bg-white absolute top-0 left-0 shadow-md z-50 mt-16 max-h-96">
                <div class="max-h-96 overflow-y-scroll pb-6">
                    <div *ngIf="searchLoading">
                        <app-loader disablescreenheight="true"></app-loader>
                    </div>
                    <div *ngIf="!searchLoading">
                        <div (click)="selectSearchResult(result)" *ngFor="let result of searchResults?.results"
                            class="flex w-full items-center justify-start h-16 hover:bg-gray-50 cursor-pointer border-t-2 border-gray-50"
                            aria-label="Result">
                            <a class="cursor-pointer group inline-flex items-center py-4 px-1 ">
                                <span *ngIf="result.type == GlobalSearchType.CONTACT"
                                    class="inline-block relative mr-3">
                                    <img class="h-6 w-6 rounded-full ring-4 ring-white"
                                        [src]="result.image != null && result.image  != '' ? imagesUrl + result.image  + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                                        alt="">
                                </span>
                                <span *ngIf="result.type != GlobalSearchType.CONTACT"
                                    class="rounded-lg inline-flex p-2 ring-4 ring-white text-center mr-3"
                                    [class]="'bg-'+result.colour+'-200' + ' ' + 'text-'+result.colour+'-800'">
                                    <fa-icon class="h-6 w-6 text-xl" [icon]="result.icon">
                                    </fa-icon>
                                </span>
                                <div>
                                    <div class="font-medium text-sm">
                                        {{result.result}}
                                    </div>
                                    <div class="text-xs">
                                        {{result.subline}}
                                    </div>
                                </div>

                            </a>
                        </div>
                    </div>
                </div>
                <div class="absolute bottom-0 left-0 w-full h-6 bg-gray-100 flex items-center px-2 justify-between">
                    <span class="wild-link text-xs sm:text-sm" (click)="clearSearch()">clear</span>
                    <span class="font-medium text-xs sm:text-sm text-gray-400">
                        <span *ngIf="!showMinCharactersNotReached && searchResults">{{searchResults.results.length}}
                            results</span>
                        <span *ngIf="showMinCharactersNotReached">type {{minSearchCharacters - charactersTyped}} more
                            character{{(minSearchCharacters - charactersTyped) > 1 ? 's':''}}</span>
                    </span>
                </div>
            </div>
            <!-- SEARCH RESULTS END -->
        </div>
        <!-- CONTENT START -->
        <main #scrollablecontainer class="flex-1 overflow-y-scroll">
            <ng-content></ng-content>
        </main>
        <!-- CONTENT END -->
    </div>
    <!-- TOP MENU BAR END -->
</div>