import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SwimSpotStatus } from '../../../../data/enums/swimspot_status_enum';
import { SwimSpot } from '../../../../data/models/swimspot_model';
import { FirestoreQueryType } from '../../../../data/enums/firestore_query_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class MySwimSpotsService {

  constructor(
    private auth: AuthenticationService,
    private firestore: FirestoreService
  ) { }

  list(): Observable<SwimSpot[]> {
    var userId = this.auth.getLoggedInCookie();
    return this.firestore.getListByQuery("swimspots", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "createdBy",
        value: userId
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "in",
        property: "status",
        value: [SwimSpotStatus.LIVE, SwimSpotStatus.INREVIEW]
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

}