import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { PaymentsService } from 'src/shared/services/payments/payments.service';
import { PaymentMethod } from '../../../../../../../../data/models/payment_method';

@Component({
  selector: 'app-addcard',
  templateUrl: './addcard.component.html',
  styleUrls: ['./addcard.component.scss'] 
})
export class AddCardComponent implements OnInit {

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      
      base: {
        iconColor: '#031D44',
        color: '#000000',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#fce883'},
        '::placeholder': {color: '#7d7d7d'}
      },
      invalid: {
        iconColor: '#eb2626',
        color: '#eb2626'
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  form: UntypedFormGroup;

  constructor(
    private notify:NotifyService,
    private paymentService:PaymentsService,
    private fb: UntypedFormBuilder,
    private modalService:ModalService,
    private stripeService: StripeService ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  createToken(data:any): void {
    this.loading = true;
    const name = data.name;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe(async (result) => {
        if (result.token) {
          // Use the token
          console.log(result.token.id);
          var paymentmethod:PaymentMethod = {
            brand:result?.token?.card?.brand ?? "",
            cardId:result?.token?.card?.id ?? "",
            country:result?.token?.card?.country ?? "",
            exp_month:result?.token?.card?.exp_month ?? 0,
            exp_year:result?.token?.card?.exp_year ?? 0,
            name:result?.token?.card?.name ?? "",
            last4:result?.token?.card?.last4 ?? "",
            token:result?.token?.id ?? "",
            creation_date:new Date,
            id:""
          }
          await this.paymentService.addCard(paymentmethod)
          .then(async () => {
            await this.next();
          })
          .catch((error) => {
            this.handlerError(error.message);
          })
          .finally(() => {
            this.resetForm();
          })
          this.loading = false;
        } else if (result.error) {
          this.handlerError(result.error.message);
          this.loading = false;
        }
      });
  }
 
  loading: boolean = false;

  cancel(){
    this.modalService.hide();
  }

  next() {
    this.notify.notify("Card Added", "A new card has been added to your account", NotifyType.SUCCESS);
    this.modalService.hide();
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  resetForm() {
    this.loading = false;
  }

}
