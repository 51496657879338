import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { FilemanagerComponent } from 'src/shared/components/filemanager/filemanager.component';
import { SourceDocumentType } from '../../../../../../data/enums/source_document_type';
import { ImageModel } from '../../../../../../data/models/images_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { SwimspotsdataService } from '../../../services/swimspotdata_service/swimspotsdata.service';


@Component({
    selector: 'app-addswimspotimages',
    template: ` 
     <div *ngIf="photos$">
      <ng-container *ngIf="photos$ | async as photos; else loadingOrError">
      <div *ngIf="!photos || photos.length == 0; else photosgallery">
        <app-empty  ctatwotext="Skip" (onClickTwo)="openSwimSpot()" (onClick)="upload()" ctatext="Upload" icon="images" header="Swim Spot In Review" description="We need at least one photo to approve a swim spot" [showcta]="true"></app-empty>    
      </div>
      <ng-template #photosgallery>
      <div class="mt-4 flex items-center justify-end">
        <button (click)="upload()" type="button" class="wild-btn-primary">
          Upload Another
          <fa-icon class="ml-3" icon="images"></fa-icon>
        </button>
      </div>
        <app-gallery [images]="photos"></app-gallery>
        <div class="flex items-center justify-end w-full mt-3">
            <div>
                <button (click)="openSwimSpot()" class="wild-btn-primary">Next</button>
            </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
          </app-retry>
      </ng-container>
      <ng-template #loading>
          <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `,
})
export class AddSwimSpotImagesComponent implements OnInit {
    @Input() swimSpotId: string;
    photos$: Observable<ImageModel[]>;
    error: ErrorModel;
    @Output() onCreate = new EventEmitter<string>();

    constructor(
        private authService: AuthenticationService,
        private swimspotService: SwimspotsdataService,
        private modalService: ModalService) { }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.photos$ = null;
        this.photos$ = this.swimspotService.photos(this.swimSpotId).pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    upload() {
        if (this.authService.isAuthedIfNotShowPopUp()) {
            var settings: ModalModel = {
                disableBodyScroll: true,
                design: ModalDesign.COMPONENT,
                component: FilemanagerComponent,
                componentInputs: [{
                    inputName: "sourceDocumentId",
                    value: this.swimSpotId
                },
                {
                    inputName: "sourceDocumentImageType",
                    value: SourceDocumentType.SWIMSPOT
                },
                {
                    inputName: "limit",
                    value: 5
                },
                {
                    inputName: "uploadOnly",
                    value: true
                }],
                componentOutputs: [
                    {
                        outputName: "onComplete",
                        func: async ($event: any) => {
                            await this.uploaded($event);
                        }
                    }
                ],
                close: true,
                large: true
            }
            this.modalService.show(settings);
        }
    }

    uploaded(images: ImageModel[]) {
        this.load();
    }

    openSwimSpot() {
        this.onCreate.emit(this.swimSpotId);
    }

}
