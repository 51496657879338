import { Component, OnInit } from '@angular/core';
import { SideMenuItems } from 'src/shared/components/sidemenu/sidemenu_items_model';
import { LocationResultType } from '../../../../../data/enums/location_result_type';
import { SearchResult } from '../../../../../data/models/SearchResult';
import { ErrorModel } from '../../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { BusinessService } from '../../services/business_service/business_service';
import { TeamService } from '../../services/team_service/team_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/shared/services/storage/storage.service';

@Component({
  selector: 'wild-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

  name: string = this.businessService.businessName;
  buttontext: string = this.businessService.businessRoles;
  image: string = this.businessService.businessImage;
  isAppUser: boolean = false;
  userId: string = "";

  businessMenuItems: SideMenuItems[] = []


  constructor(
    private route: ActivatedRoute,
    private storage: StorageService,
    private modalService: ModalService, private navigation: NavigationService, private teamService: TeamService, private businessService: BusinessService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.userId = this.auth.userId;
    this.businessMenuItems = [
      {
        description: "Dashboard",
        route: ["", "business"],
        icon: "home",
        visible: true
      },
      {
        description: "Contacts",
        route: ["", "business", "contacts"],
        icon: "users",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("contacts")
      },
      {
        description: "Calendar",
        route: ["", "business", "activities"],
        icon: "calendar-alt",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("calendar")
      },
      {
        description: "Orders",
        route: ["", "business", "orders"],
        icon: "shopping-bag",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("orders")
      },
      {
        description: "Finance",
        route: ["", "business", "finance"],
        icon: "coins",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("finance")
      },
      {
        description: "Water Safety",
        route: ["", "business", "watersafety"],
        icon: "water",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("safety")
      },
      {
        description: "Working Hours",
        route: ["", "business", "usersettings", "calendar", this.userId],
        icon: "calendar-week",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("workingHours")
      },
      {
        description: "Your Settings",
        route: ["", "business", "usersettings", "details", this.userId],
        icon: "user-cog",
        visible: true
      },
      {
        description: "Business Settings",
        route: ["", "business", "settings"],
        icon: "cog",
        visible: this.businessService.isInRole("owner") || this.businessService.isInRole("admin")
      },
      {
        description: "Help Center",
        route: ["", "business", "support"],
        icon: "question-circle",
        visible: true
      },

    ]
  }

  public error: ErrorModel = null;

  searchSelected(result: SearchResult) {
    if (result.type == LocationResultType.PLACE) {
      this.navigation.navigate(WildNavigationType.BUSINESSSEARCHRESULTS, { query: result.name, latitude: result.latitude, longitude: result.longitude });
    } else if (result.type == LocationResultType.SWIMSPOT) {
      this.navigation.navigate(WildNavigationType.BUSINESSSWIMSPOTDETAILS, {}, result.id);
    }
  }

  switch() {
    this.navigation.navigate(WildNavigationType.GROUPS);
  }


}
