<!-- This example requires Tailwind CSS v2.0+ -->
<div class="wild-bg-primary">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-base font-semibold wild-text-secondary tracking-wide uppercase text-5xl">{{prefix}}</h2>
        <p class="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">{{header}}</p>
        <p class="max-w-xl mt-5 mx-auto text-xl text-white">{{description}}</p>
      </div>
    </div>
  </div>
  