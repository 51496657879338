import { Component } from '@angular/core';


@Component({
  template: `
  <app-appdownloadlinks header="Join the community on the Wild app"></app-appdownloadlinks>
  `,
})
export class AppPromptComponent {

}
