import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PanelPage } from 'src/shared/components/panel/panel_page_model';
import { BusinessContactWrapperDTO } from '../../../../../../../data/dtos/contacts/BusinessContactWrapperDTO';
import { BusinessContactBannerType } from '../../../../../../../data/enums/contacts/BusinessContactBannerType';
import { Gender } from '../../../../../../../data/enums/gender_type';
import { SourceDocumentType } from '../../../../../../../data/enums/source_document_type';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ContactsService } from '../../services/contacts_service/contacts_service';
import { ResendWaiverComponent } from './resendwaiver.component';
import { BusinessContactBannerStyle } from '../../../../../../../data/enums/contacts/BusinessContactBannerStyle';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SendMessageComponent } from 'src/wild/modules/business/components/sendmessage/sendmessage.component';

enum ContactPages {
  PROFILE,
  MESSAGES,
  AUDIT,
  ORDERS,
  ACTIVITIES,
  NOTES,
  WAIVERS,
}



@Component({
  selector: 'app-contactdetails',
  templateUrl: './contactdetails.component.html'
})

export class ContactdetailsComponent implements OnInit {

  @Input() id: string;
  BusinessContactBannerType = BusinessContactBannerType;
  BusinessContactBannerStyle = BusinessContactBannerStyle;
  Gender = Gender;
  contact: BusinessContactModel;
  contact$: Observable<BusinessContactWrapperDTO>;
  imageUrl = environment.imagesUrl;
  pages: PanelPage[] = [
    {
      id: ContactPages.PROFILE,
      name: "Profile"
    },
    {
      id: ContactPages.ORDERS,
      name: "Orders"
    },
    {
      id: ContactPages.ACTIVITIES,
      name: "Activities"
    },
    {
      id: ContactPages.WAIVERS,
      name: "Waivers"
    },
    // {
    //   id: ContactPages.AUDIT,
    //   name: "Audit"
    // }
  ]
  page: ContactPages = ContactPages.PROFILE
  ContactPages = ContactPages;
  SourceDocumentType = SourceDocumentType;
  error: ErrorModel;

  constructor(
    private modalService: ModalService,
    private notifyService: NotifyService,
    private contactService: ContactsService) { }

  ngOnInit(): void {
    this.load();
  }

  message() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: SendMessageComponent,
      close: true,
      large: true,
      componentInputs: [{
        inputName: "id",
        value: this.contact.id
      },
      {
        inputName: "contact",
        value: true
      }],
      // componentOutputs: [{
      //   outputName: "updated",
      //   func: () => {
      //     this.load();
      //     this.updated.emit();
      //   }
      // }]
    }
    this.modalService.show(settings);
  }

  resend() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      component: ResendWaiverComponent,
      componentInputs: [{
        inputName: "id",
        value: this.id
      }]
    }
    this.modalService.show(settings);
  }

  noteCountUpdated(count: number, contact: BusinessContactModel) {
    contact.noteCount = count;
  }

  voidWaiver() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.ERROR,
      description: `Are you sure you want to void this waiver?`,
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.voidWaiverProcess();

      }
    }
    this.modalService.show(settings);
  }


  voidWaiverProcess() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.LOADING,
      header: "Voiding",
    }
    this.modalService.show(settings);
    this.contactService.voidWaiver(this.id)
      .then(() => {
        var settings: ModalModel = {
          disableBodyScroll: true,
          design: ModalDesign.CENTRAL,
          type: ModalType.INFO,
          description: `Waiver has been voided, you can send it to them again for signing.`,
          header: "Waiver Voided",
          close: true,
          hidectatwo: true,
          ctaonetext: "Ok"
        }
        this.modalService.show(settings);
        this.load();
      })
      .catch((error) => {
        this.notifyService.notify("Waiver Voiding Error", error.message, NotifyType.ERROR);
        this.modalService.hide();
      });
  }



  pageChange(page: PanelPage) {
    this.page = page.id;
  }

  load() {
    this.contact$ = null;
    this.contact$ = this.contactService.getContactWrapper(this.id).pipe(
      tap((contact) => {
        this.contact = contact.contact;
      }),
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
