import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BlogPostDTO } from '../../../../../data/dtos/blog/BlogPostDTO';
import { HttpService } from 'src/shared/services/http/http_service';
@Injectable({
  providedIn: 'root'
})
export class RSSSerrice {

  constructor(private http:HttpService) { }

  async feed():Promise<BlogPostDTO[]>{
      return await this.http.get(`${environment.api}/user/search/blog/latest`).toPromise();
  }

 
}

