import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChartOptions, LabelItem, } from 'chart.js';
import { SelectModel } from 'src/shared/components/select/select_model';
import { AttedanceChartDataDTO } from '../../../../../../../data/dtos/charts/AttedanceChartDataDTO';
import { AttedanceChartSort } from '../../../../../../../data/dtos/charts/AttedanceChartSort';
import { ChartTimeRange } from '../../../../../../../data/enums/chart_time_range';
import { ChartType } from '../../../../../../../data/enums/chart_type';
import { ServiceType } from '../../../../../../../data/enums/service_type';
import { BusinessServiceModel } from '../../../../../../../data/models/business_service_model';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { AppointmentDetailsComponent } from 'src/wild/modules/business/modules/appointments/components/appointmentdetails.component';
import { ListServiceService } from 'src/wild/modules/business/modules/services/services/list/list_service_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';



@Component({
  selector: 'app-businesscharts',
  template: `
  <div class="">
    <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow " aria-label="Tabs">
      <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
      <a (click)="changeType(ChartType.REVENUE)" class="cursor-pointer text-gray-900 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10">
        <span>Revenue</span>
        <span *ngIf="chartType == ChartType.REVENUE" class="wild-bg-primary absolute inset-x-0 bottom-0 h-0.5"></span>
      </a>

      <a (click)="changeType(ChartType.ORDERS)" class="cursor-pointer text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10">
        <span>Orders</span>
        <span *ngIf="chartType == ChartType.ORDERS" class="wild-bg-primary absolute inset-x-0 bottom-0 h-0.5"></span>
      </a>

      <a (click)="changeType(ChartType.CONTACTS)" class="cursor-pointer text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10">
        <span>Contacts</span>
        <span *ngIf="chartType == ChartType.CONTACTS" class="wild-bg-primary absolute inset-x-0 bottom-0 h-0.5"></span>
      </a>
      <a (click)="changeType(ChartType.ATTENDANCE)" class="cursor-pointer text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10">
        <span>Attendance</span>
        <span *ngIf="chartType == ChartType.ATTENDANCE" class="wild-bg-primary absolute inset-x-0 bottom-0 h-0.5"></span>
      </a>
    </nav>
  </div>
  <div class="rounded-lg shadow bg-white my-4 p-4">
    <div class="flex items-center justify-between">
      <div *ngIf="chartType != ChartType.ATTENDANCE" class="flex my-3">
        <span (click)="changeChartTime(ChartTimeRange.WEEK)" [ngClass]="{'bg-blue-100 text-blue-800': chartTime == ChartTimeRange.WEEK,'bg-white text-gray-800 hover:bg-gray-100 cursor-pointer': chartTime != ChartTimeRange.WEEK}" class="inline-flex items-center rounded-full  px-3 py-0.5 text-sm font-medium mr-3">Week</span>
        <span (click)="changeChartTime(ChartTimeRange.MONTH)" [ngClass]="{'bg-blue-100 text-blue-800': chartTime == ChartTimeRange.MONTH,'bg-white text-gray-800 hover:bg-gray-100 cursor-pointer': chartTime != ChartTimeRange.MONTH}" class="inline-flex items-center rounded-full  px-3 py-0.5 text-sm font-medium mr-3">Month</span>
        <span (click)="changeChartTime(ChartTimeRange.QUARTER)" [ngClass]="{'bg-blue-100 text-blue-800': chartTime == ChartTimeRange.QUARTER,'bg-white text-gray-800 hover:bg-gray-100 cursor-pointer': chartTime != ChartTimeRange.QUARTER}" class="inline-flex items-center rounded-full  px-3 py-0.5 text-sm font-medium mr-3">3 Months</span>
      </div>
      <span *ngIf="chartType != ChartType.ATTENDANCE && loading" class="text-xs text-gray-500" >...loading</span>
      <span *ngIf="chartType != ChartType.ATTENDANCE && !loading && total" class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-800">{{total}}</span>
      <div *ngIf="chartType == ChartType.ATTENDANCE"  class="my-3">
        <form [formGroup]="form" (ngSubmit)="submit(form.value)"  >
            <div class="flex items-end">
              <div class="mr-3">
                  <app-select (onChange)="serviceChanged($event)" [options]="serviceOptions" [form]="form" name="serviceId" [description]="loadingServicesOptions ? 'loading services...' :'Service'" [span]=6>
                  </app-select>
              </div>

              <div class="mr-3">
                  <app-formdatepicker (change)="startDateChanged($event)"  [span]=6 [form]="form" name="startDateIso" description="Start Date"  ></app-formdatepicker>
              </div>
              <div class="mr-3">
                  <app-formdatepicker (change)="endDateChanged($event)"[span]=6 [form]="form" name="endDateIso" description="End Date"  ></app-formdatepicker>
              </div>
              <div class="mr-3">
                  <app-select (onChange)="serviceChanged($event)" [options]="attednanceSortOrderSelectOptions" [form]="form" name="attedanceSort" [description]="'Sort'" [span]=6>
                  </app-select>
              </div>
            </div>
            <div class="flex items-center justify-start mt-3">
              <div class="mr-3">
                <app-checkbox [form]="form" label="Exclude Empty Activities" name="excludeempty"></app-checkbox>
              </div>
              <div class="mr-3">
                <app-checkbox [form]="form" label="Exclude Activities without Attendance Data" name="excludenoattedancedata"></app-checkbox>
              </div>
              <div>
                <button type="submit" class="wild-btn-primary w-full" style="max-width: 100px;">
                  <span *ngIf="!loading">Go</span>
                  <div *ngIf="loading" class="loader"></div>
                </button>
              </div>
            </div>
        </form>
      </div>
     
    </div>
    <div *ngIf="chartType != ChartType.ATTENDANCE" class="w-full">
      <canvas baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [type]="lineChartType" 
        [legend]="lineChartLegend"
        [plugins]="lineChartPlugins">
      </canvas> 
    </div>
    <div *ngIf="chartType == ChartType.ATTENDANCE" class="w-full">
        <app-empty *ngIf="!attendanceData" icon="users" header="No Attendance Data"
          description="Please Adjust Report Parameters">
        </app-empty>
        <div *ngIf="attendanceData">
          <!-- ATTENDANCE SUMMARY START -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="bg-white rounded-lg border border-gray-300 p-4">
              <h2 class="text-lg font-medium mb-4">Contact Behaviour Analysis</h2>
              <div class="grid grid-cols-2 gap-2">
                <div>
                  <p class="text-sm font-medium text-gray-500">Total Bookings</p>
                  <p class="text-lg font-medium">{{ attendanceData.totalcontacts | number:'1.0-0'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Unique Bookers</p>
                  <p class="text-lg font-medium">{{ attendanceData.uniquecontacts | number:'1.0-0'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Min Contacts on an Activity</p>
                  <p class="text-lg font-medium">{{ attendanceData.mincontacts == null ? '-' : attendanceData.mincontacts | number:'1.0-0'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Max Contacts on an Activity</p>
                  <p class="text-lg font-medium">{{ attendanceData.maxcontacts == null ? '-' : attendanceData.maxcontacts | number:'1.0-0'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Repeat Bookers</p>
                  <p class="text-lg font-medium">{{ attendanceData.repeatbookers == null ? '-' : attendanceData.repeatbookers | number:'1.2-2'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Bookings per Contact</p>
                  <p class="text-lg font-medium">{{ attendanceData.bookingsPerContact == null ? '-' : attendanceData.bookingsPerContact | number:'1.2-2'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Average Contacts per Activity</p>
                  <p class="text-lg font-medium">{{ attendanceData.averagecontacts == null ? '-' : attendanceData.averagecontacts | number:'1.2-2'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Activity Count</p>
                  <p class="text-lg font-medium">{{ attendanceData.records.length}}</p>
                </div>
              </div>
            </div>
            <div class="bg-white rounded-lg border border-gray-300 p-4 mt-4 md:mt-0">
              <h2 class="text-lg font-medium mb-4">Attendance Analysis</h2>
              <div class="grid grid-cols-2 gap-2">
                <div>
                  <p class="text-sm font-medium text-gray-500">Min Attended</p>
                  <p class="text-lg font-medium">{{ attendanceData.minattended == null ? '-' : attendanceData.minattended | number:'1.0-0'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Max Attended</p>
                  <p class="text-lg font-medium">{{ attendanceData.maxattended == null ? '-' : attendanceData.maxattended | number:'1.0-0'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Average Attendance (Count)</p>
                  <p class="text-lg font-medium">{{ attendanceData.averageattendedcount == null ? '-' : attendanceData.averageattendedcount | number:'1.2-2'}}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-500">Average Attendance (Percent)</p>
                  <p class="text-lg font-medium">{{ attendanceData.averageattendedpercent == null ? '-' : attendanceData.averageattendedpercent | percent}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- ATTENDANCE SUMMARY END -->
          <!-- ATTENDANCE LINES START -->
          <div class="mt-4 cursor-pointer flex items-center mb-4">
            <div class="w-1/4">
              <div class="text-xs text-gray-600 font-bold">Date | Contacts</div>
            </div>
            <div class="w-3/4">
              <div class="flex items-center w-full">
                <div class="text-xs text-gray-600 font-bold">Attendance</div>
              </div>
            </div>
          </div>
          <div (click)="openActivity(record.id)" *ngFor="let record of attendanceData.records" class="mt-4 cursor-pointer hover:bg-gray-100 flex items-center">
            <div class="w-1/4">
              <div class="text-xs text-gray-600">{{ record.datetime }} | {{ record.contacts }}</div>
            </div>
            <div class="w-3/4">
              <div class="flex items-center w-full">
              <div class="h-2 bg-green-500" [style.width.%]="record.attendedPercent * 100"></div>
              <div class="h-2 bg-red-500" [style.width.%]="record.dnaPercent * 100"></div>
              <div class="h-2 bg-yellow-500" [style.width.%]="record.nostatusPercent * 100"></div>
              </div>
            </div>
          </div>
          <!-- ATTENDANCE LINE END -->
        </div>
    </div>
  </div>
  `,
})



export class BusinessChartsComponent implements OnInit {
  constructor(
    private notifyService: NotifyService,
    private fb: UntypedFormBuilder,
    private serviceListService: ListServiceService,
    private panelService:PanelService,
    private businessService: BusinessService
  ) { }

  public error: ErrorModel = null;
  form!: UntypedFormGroup;
  loadingServicesOptions: boolean = false;
  serviceOptions: SelectModel[] = [];
  attednanceSortOrderSelectOptions: SelectModel[] = [
    {
      name: "Date Ascending",
      value: AttedanceChartSort.DATEASC
    },
    {
      name: "Date Descending",
      value: AttedanceChartSort.DATEDESC
    },
    {
      name: "Attended Ascending",
      value: AttedanceChartSort.ATTENDEDASC
    },
    {
      name: "Attended Descending",
      value: AttedanceChartSort.ATTENDEDDESC
    },
  ];
  activeService:BusinessServiceModel = null;
  attendanceData: AttedanceChartDataDTO = null;
  services: BusinessServiceModel[] = [];
  chartType: ChartType = ChartType.REVENUE;
  ChartType = ChartType;
  chartTime: ChartTimeRange = ChartTimeRange.WEEK;
  ChartTimeRange = ChartTimeRange;
  total: number;
  data: any;
  loading: boolean = false;
  lineChartData: any[] = [];
  lineChartLabels: LabelItem[] = [];
  lineChartOptions: ChartOptions = {
  responsive: true,
  borderColor: '#031d44',
  backgroundColor: '#9393cd33',
    // tension: 0.1,
    // tooltips: {
    //   callbacks: {
    //     label: (item) => `£${item.yLabel}`,
    //   },
    // },
  };

  serviceChanged(serviceId: string) {
    if (serviceId == "") {
      this.unselectService()
    } else {
      var activeService = this.services.find(s => s.id == serviceId);
      this.activeService = activeService;
    }
  }

  startDateChanged(date: string) {
    this.form.markAsDirty();
  }
  endDateChanged(date: string) {
    this.form.markAsDirty();
  }

  unselectService() {
    this.form.controls["serviceId"].setValue("");
    this.activeService = null;
  }

  openActivity(id:string) {
    var settings: PanelModel = {
        header: "Activity Details",
        component: AppointmentDetailsComponent,
        componentInputs: [
            {
                inputName: "id",
                value: id
            },
        ],
        componentOutputs: [
            {
                outputName: "updated",
                func: () => {
                    this.load();
                }
            }
        ]
    }
    this.panelService.show(settings);
}


  lineChartColors: any[] = [
    {
      borderColor: '#031d44',
      backgroundColor: '#9393cd33',
    },
  ];
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';

  charts: SelectModel[] = [{
    name: "Contacts",
    value: ChartType.CONTACTS
  },
  {
    name: "Orders",
    value: ChartType.ORDERS
  },
  {
    name: "Revenue",
    value: ChartType.REVENUE
  }];

  changeType(type: ChartType) {
    if (type == this.chartType)
      return;
    this.chartType = type;
    if(this.chartType != ChartType.ATTENDANCE){
      this.load();
    }
    
  }

  changeChartTime(chartTime: ChartTimeRange) {
    if (chartTime == this.chartTime)
      return;
    this.chartTime = chartTime;
    this.load();
  }

  ngOnInit(): void {
    this.loadServicesOptionsList();
    this.form = this.fb.group({
      serviceId: new UntypedFormControl('', [Validators.required]),
      attedanceSort: new UntypedFormControl('', [Validators.required]),
      startDateIso: new UntypedFormControl("", [Validators.required]),
      endDateIso: new UntypedFormControl("", [Validators.required]),
      excludeempty: new UntypedFormControl(true, [Validators.required]),
      excludenoattedancedata: new UntypedFormControl(false, [Validators.required]),
    });
    this.load();
  }

  loadServicesOptionsList() {
    this.loadingServicesOptions = true;
    this.serviceOptions.push({
      name: "not selected",
      value: ""
    })
    this.serviceListService.list().toPromise().then((services) => {
      this.services = services;
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        var typeDescription = "";
        if (service.type == ServiceType.CALENDARBASED)
          this.serviceOptions.push({
            name: service.name + " (On-Demand)",
            value: service.id
          })
        if (service.type == ServiceType.GROUP)
          this.serviceOptions.push({
            name: service.name + " (Scheduled)",
            value: service.id
          })
      }
    }).finally(() => {
      this.loadingServicesOptions = false;
    })
  }

  load() {
    this.loading = true;
    this.businessService.getChartData(this.chartType, this.chartTime).toPromise().then((data) => {
      this.lineChartLabels = data.labels;
      this.lineChartData = [
        { data: data.data, label: data.title, lineTension: 0.1 },
      ];
      this.total = data.total;
      this.loading = false;
    }).catch((error) => {
      this.handlerError(error.message);
    })
  }

  submit () {
    if(!this.form.valid)
      return;
    if(this.form.pristine)
          return;
    this.loading = true;
    const serviceId = this.form.controls["serviceId"].value;
    const startDate = this.form.controls["startDateIso"].value;
    const endDate = this.form.controls["endDateIso"].value;
    const sort = parseInt(this.form.controls["attedanceSort"].value);
    let excludeempty = this.form.controls["excludeempty"].value;
    excludeempty = excludeempty == true ? 1 : 0;
    let excludenoattedancedata = this.form.controls["excludenoattedancedata"].value;
    excludenoattedancedata = excludenoattedancedata == true ? 1 : 0;
    this.businessService.getAttedanceChartData(serviceId,startDate, endDate, sort, excludeempty, excludenoattedancedata).toPromise().then((data) => {
      this.attendanceData = data;
      this.loading = false;
      this.form.markAsPristine()
    }).catch((error) => {
      this.handlerError(error.message);
      this.loading = false;
    })
  }

  handlerError(message: string) {
    this.notifyService.notify("Chart Error", message, NotifyType.ERROR);
  }

}
