import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/shared/services/seo/seo.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.update("Privacy", "Wild privacy policy", "swimming, open water, lake, river, beach, contact, roadmap. terms", "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fwildlogo.png?alt=media&token=87b004dd-dd18-4815-a240-0f049dfc787b");
  }

}
