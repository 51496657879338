import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { BusinessContactModel } from '../../../../../../data/models/business_contact_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { ContactdetailsComponent } from '../components/contactdetails/contactdetails.component';
import { ContactSearchService } from '../services/contactsearch/contactsearch_service';
import { ContactSearchResultsDTO } from '../../../../../../data/dtos/business/search/contact/ContactSearchResultsDTO';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { environment } from 'src/environments/environment';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { InviteContactFormComponent } from '../components/invitecontactform.component';

@Component({
  selector: 'app-contacts',
  template: `
  <app-wrapper header="Contacts" paragraph="Manage your contacts" icon="envelope" ctatext="Invite" (onClick)="invite()">
  <div *ngIf="contacts$"> 
    <ng-container *ngIf="contacts$ | async as contacts; else loadingOrError">
      <div *ngIf="!search && (!contacts || contacts?.results?.length == 0); else contactsstable">
        <app-empty icon="users" header="No Contacts Yet"
          description="Contacts will appear when you get your first order">
        </app-empty>
      </div>
      <ng-template #contactsstable>
        <div class="mb-6 max-w-2xl">
          <app-inlineform placeholder="Search by name, email, username or phone" (onSubmit)="submit($event)"
            [form]="form" inputname="search" ctaText="Search"></app-inlineform>
        </div>
        <app-empty *ngIf="contacts?.results?.length == 0" icon="users" header="No Results" 
          description="Please refine your search">
        </app-empty>
        <div class="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" class="divide-y divide-gray-200">
            <li *ngFor="let contact of contacts.results">
              <a (click)="edit(contact)" class="block hover:bg-gray-50 cursor-pointer">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="flex min-w-0 flex-1 items-center">
                    <div class="flex-shrink-0">
                    <span *ngIf="(contact?.userimage == null || contact?.userimage == '')"
                          class="inline-flex items-center justify-center h-10 w-10 rounded-full wild-bg-primary">
                          <span
                              class="text-sm font-medium leading-none text-white">{{getInitials((contact?.firstName ?? "?"),(contact?.surname ?? "?"))}}</span>
                      </span>
                      <img *ngIf="(contact?.userimage != null && contact?.userimage != '')" class="h-12 w-12 rounded-full" [src]="(contact?.userimage != null && contact?.userimage != '') ? imagesUrl + 
                      contact?.userimage + 
            '_640x640.jpeg?alt=media' : 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'" alt="">
                    </div>
                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p class="truncate text-sm font-medium wild-text-primary">{{contact?.firstName + ' ' + contact?.surname}}</p>
                        <p class="mt-2 flex items-center text-sm text-gray-500">
                          <!-- Heroicon name: mini/envelope -->
                          <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                            <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                          </svg>
                          <span class="truncate">{{contact?.email}}</span>
                        </p>
                      </div>
                      <div class="hidden md:block">
                        <div>
                          <p class="text-sm text-gray-900">
                            Created on
                            <time datetime="2020-01-07">{{getReadableDate(contact?.creationdate)}}</time>
                          </p>
                          <p *ngIf="contact?.linkedaccount == true" class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: mini/check-circle -->
                            <fa-icon class="mr-2 wild-text-primary" icon="users"></fa-icon>
                            Linked Account
                          </p>
                          <p *ngIf="contact?.linkedaccount != true" class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: mini/check-circle -->
                            <fa-icon class="mr-2 wild-text-primary" icon="user"></fa-icon>
                            Wild Account
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <!-- Heroicon name: mini/chevron-right -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <app-table *ngIf="contacts?.results?.length > 0" (pageChange)="pageChange($event)" [startingFrom]="contacts.startingFrom" [currentPage]="contacts.currentPage" [count]="contacts.count"  [numberOfPages]="contacts.numberOfPages" [size]="contacts.size" [endingAt]="contacts.endingAt" >
       
  
        <!-- <tr *ngFor="let contact of contacts.results">
            <td app-table-cell [value]="contact?.firstName + ' ' + contact?.surname"></td>
            <td app-table-cell [value]="getReadableDate(contact?.creationdate)"></td>
            <td app-table-cell [value]="contact?.email"></td>
            <td app-table-cell [value]="contact?.linkedaccount == true ? 'linked account' : ''"></td>
            <td app-table-cell [cta]="true" (onClick)="edit(contact)" value="edit"></td>
          </tr> -->
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>
  `
})
export class ContactsComponent implements OnInit {

  constructor(
    private contactSearchService: ContactSearchService,
    private notify: NotifyService,
    private _formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private dateService: DatesService,
    private navigation: NavigationService,
    private router: Router,
    private modalService: ModalService,
    private panelService: PanelService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.load();
      }
    });
  }


  public error: ErrorModel = null;
  page: number;
  size: number;

  imagesUrl = environment.imagesUrl
  search: string;
  contacts$: Observable<ContactSearchResultsDTO>;
  loading: boolean = false;
  form = this._formBuilder.group({
    search: new UntypedFormControl('', [])
  });

  ngOnInit(): void {
    this.load();
  }

  invite() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: InviteContactFormComponent
    }
    this.modalService.show(settings);
  }

  getInitials(firstName: string, surname: string) {
    var initials = "";
    initials = firstName?.substring(0, 1) ?? "";
    initials = initials + surname?.substring(0, 1) ?? "";
    return initials.toUpperCase();
  }

  submit(data: any) {
    this.navigation.navigate(WildNavigationType.BUSINESSCONTACTS, { q: data.search })
    this.load();
  }

  getReadableDate(date: string): string {
    return this.dateService.getReadableDateTimeFromISO(date)
  }

  load() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.contacts$ = null;
      this.search = params.q?.toLowerCase() ?? "";
      this.page = params?.page ?? 1;
      this.size = params?.size ?? 10;
      this.form.controls["search"].setValue(this.search);
      this.contacts$ = this.contactSearchService.search(this.search, this.page, this.size).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    })
  }

  pageChange(pageNumber: number) {
    this.navigation.navigate(WildNavigationType.BUSINESSCONTACTS, { q: this.search, page: pageNumber, size: this.size })
  }

  edit(contact: BusinessContactModel) {
    var settings: PanelModel = {
      header: "Contact",
      component: ContactdetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: contact.id
        }
      ]
    }
    this.panelService.show(settings);
  }

}
