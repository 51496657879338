
import { Injectable } from '@angular/core';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SuuntoService {

    constructor(
        private http: HttpService
    ) { }

    getUrl(): Promise<any> {
        return this.http.get(`${environment.api}/webhook/suunto/auth`).toPromise();
    }

    exchange(code: string): Promise<void> {
        return this.http.post(`${environment.api}/webhook/suunto/exchange`, {
            code: code
        }).toPromise();
    }

}
