import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class BusinessSwimSpotService {

    constructor(
        private http: HttpService
    ) { }

    get(): Observable<any> {
        return this.http.get(`${environment.api}/business/swimspots`);
    }

    async add(id: string) {
        await this.http.post(`${environment.api}/business/swimspots/${id}`, {}).toPromise();
    }

    async remove(id: string) {
        await this.http.delete(`${environment.api}/business/swimspots/${id}`).toPromise();
    }

}
