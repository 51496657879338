import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from 'src/shared/resolvers/user.resolver';
import { NotAuthenticatedGuard } from '../auth/guards/not_authenticated.guard';
import { FavouritesComponent } from './pages/user_children/favourites/favourites.component';
import { MyswimspotsComponent } from './pages/user_children/myswimspots/myswimspots.component';
import { UserComponent } from './pages/user/user.component';
import { SwimspotdetailsComponent } from '../swimspots/components/swimspotdetails/swimspotdetails.component';
import { UserdashboardComponent } from './pages/user_children/userdashboard/userdashboard.component';
import { UserprofileComponent } from './pages/user_children/userprofile/userprofile.component';
import { UserAddSwimSpotComponent } from './pages/user_children/useraddswimspot.component';
import { NotificationsComponent } from './pages/user_children/notifications/notifications.component';
import { SettingsComponent } from './pages/user_children/settings/settings.component';
import { UserOrdersomponent } from './pages/user_children/userorders/userorders.component';
import { UserDetailsComponent } from './pages/user_children/userdetails/userdetails.component';
import { UserActivitiesComponent } from './pages/user_children/useractivities/useractivities.component';
import { UserActivityDetailsComponent } from './pages/user_children/useractivitydetails/useractivitydetails.component';
import { UserCouponsComponent } from './pages/user_children/usercoupons/usercoupons.component';
import { LinkedAccountsComponent } from './pages/user_children/linkedaccounts/linkedaccounts.component';
import { PubliclyAcccessiblyActivityDetailsComponent } from './pages/publiclyaccessibleactivity/publiclyaccessibleactivity.component';
import { UnsignedWaiversComponent } from './pages/user_children/unsignedwaivers/unsignedwaivers.component';
import { UserMembershipsComponent } from './pages/user_children/memberships/usermemberships.component';
import { ManageMembershipComponent } from './pages/user_children/managemembership/managemembership.component';
const routes: Routes = [
  {
    path: "addswimspot",
    canActivate: [NotAuthenticatedGuard],
    component: UserAddSwimSpotComponent,
  },
  {
    path: "activities/:activityId/:userId",
    component: PubliclyAcccessiblyActivityDetailsComponent,
  },
  {
    path: "user",
    component: UserComponent,
    canActivate: [NotAuthenticatedGuard],
    resolve: {
      user: UserResolver
    },
    children: [
      {
        path: "",
        component: UserdashboardComponent,
      },
      {
        path: "orders",
        component: UserOrdersomponent,
      },
      {
        path: "membership",
        component: ManageMembershipComponent,
      },
      {
        path: "activities",
        component: UserActivitiesComponent,
      },
      {
        path: "vouchers",
        component: UserCouponsComponent,
      },
      {
        path: "memberships",
        component: UserMembershipsComponent,
      },
      {
        path: "activities/:activityId",
        component: UserActivityDetailsComponent,
      },
      {
        path: "activities/:activityId/:userId",
        component: UserActivityDetailsComponent,
      },
      {
        path: "details",
        component: UserDetailsComponent,
      },
      {
        path: "profile",
        component: UserprofileComponent,
      },
      {
        path: "favourites",
        component: FavouritesComponent,
      },
      {
        path: "linkedaccounts",
        component: LinkedAccountsComponent,
      },
      {
        path: "waivers",
        component: UnsignedWaiversComponent,
      },
      {
        path: "myswimspots",
        component: MyswimspotsComponent,
      },
      {
        path: "notifications",
        component: NotificationsComponent,
      },
      {
        path: "settings",
        component: SettingsComponent,
      },
      {
        path: "swimspots/:id",
        component: SwimspotdetailsComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
