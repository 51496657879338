<app-wrapper header="Notifications">
    <ng-container *ngIf="user$ | async as user; else loadingOrError">
        <div *ngIf="!user; else userprefsform">
            User Not Found
        </div>
        <ng-template #userprefsform>
            <app-form [form]="form" fullwidthbutton="true" ctaText="Update" disableDivide="true"
                (onSubmit)="onSubmit($event)" [fullwidthbutton]="false" [loading]="loading">
                <h2 class="wild-header">Emails</h2>
                <app-inputgroup>
                    <app-toggle [form]="form" name="swimspots_newposts_email" description="Swim Spot Posts"
                        explanation="Get notified about new posts on your favourite Swim Spots" [span]=2
                        [value]="user?.comms_preferences?.swimspots_newposts_email ?? false"></app-toggle>
                    <app-toggle [form]="form" name="swimspots_newalerts_email" description="Swim Spot Alerts"
                        explanation="Get notified about new alerts on your favourite Swim Spots." [span]=2
                        [value]="user?.comms_preferences?.swimspots_newalerts_email ?? false"></app-toggle>
                    <app-toggle [form]="form" name="swimspots_newreviews_email" description="Swim Spot Reviews"
                        explanation="Get notified about new reviews on your favourite Swim Spots." [span]=2
                        [value]="user?.comms_preferences?.swimspots_newreviews_email ?? false"></app-toggle>
                </app-inputgroup>
                <h2 class="wild-header mt-7">Push Notifications</h2>
                <app-inputgroup>
                    <app-toggle [form]="form" name="swimspots_newposts_push" description="Swim Spot Posts"
                        explanation="Get notified about new posts on your favourite Swim Spots" [span]=2
                        [value]="user?.comms_preferences?.swimspots_newposts_push ?? false"></app-toggle>
                    <app-toggle [form]="form" name="swimspots_newalerts_push" description="Swim Spot Alerts"
                        explanation="Get notified about new alerts on your favourite Swim Spots." [span]=2
                        [value]="user?.comms_preferences?.swimspots_newalerts_push ?? false"></app-toggle>
                    <app-toggle [form]="form" name="swimspots_newreviews_push" description="Swim Spot Reviews"
                        explanation="Get notified about new reviews on your favourite Swim Spots." [span]=2
                        [value]="user?.comms_preferences?.swimspots_newreviews_push ?? false"></app-toggle>
                </app-inputgroup>
                <h2 class="wild-header mt-7">Newsletter</h2>
                <app-inputgroup>
                    <app-toggle [form]="form" name="wild_general" description="Newsletter"
                        explanation="Wild updates and newsletter" [span]=2
                        [value]="user?.comms_preferences?.wild_general ?? false"></app-toggle>
                </app-inputgroup>
            </app-form>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>