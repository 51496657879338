import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InviteContactDTO } from '../../../../../../data/dtos/business/contact/InviteContactDTO';
import { CategoryModel } from '../../../../../../data/models/category_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from '../services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Invite Contacts" parapraph="Invite up to 20 contacts at a time. Seperate contact email addresses with a comma and we'll share instructions on how to join your business. "></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Invite" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
          <app-textarea validation="Email address" placeholder="emailone@gmail.com, emailtwo@gmail.com, emailthree@gmail.com" [form]="form" type="text" name="emails" description="Email Addresses" [span]=6></app-textarea> 
      </app-inputgroup>
  </app-form> 
  `
})
export class InviteContactFormComponent implements OnInit {

  @Output() updated = new EventEmitter();
  @Input() category: CategoryModel;

  constructor(
    private fb: UntypedFormBuilder,
    private contactsService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      emails: new UntypedFormControl('', [Validators.required, Validators.maxLength(1000)]),
    });
  }

  onSubmit(data) {
    if(this.loading == true)
      return;
    this.loading = true;
    var dto: InviteContactDTO = {
      emails: data.emails
    }
    this.contactsService.inviteContact(dto)
      .then(async () => {
        await this.next(dto);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Invite Error", message, NotifyType.ERROR);
  }

  async next(dto: any) {
    this.notifyService.notify("Invites Sent", "", NotifyType.SUCCESS);
    this.modalService.hide();
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
