import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-logout',
  template:`
    <app-wild-header></app-wild-header>
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-indigo-600">Auth</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Authentication Error</h1>
        <p class="mt-6 text-base leading-7 text-gray-600">Log back in and you're good to go</p>
      </div>
    </main>

  `
})
export class LogoutComponent implements OnInit {

  constructor(
    private auth: AuthenticationService) { }
  ngOnInit(): void {
    this.auth.logout();
    // window.location.href = "/";
  }



}
