import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UpdateActivityBookableStatusDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityBookableStatusDTO';
import { UpdateActivityCapacityDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityCapacityDTO';
import { UpdateActivityDescriptionDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityDescriptionDTO';
import { UpdateActivityDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityDTO';
import { UpdateActivityLocationDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityLocationDTO';
import { UpdateActivityNameDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityNameDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class UpdateActivityService {

    constructor(
        private http: HttpService
    ) { }

    async updateDates(activtiyId: string, dto: UpdateActivityDTO): Promise<void> {
        await this.http.patch(`${environment.api}/business/activities/dates/${activtiyId}`, dto).toPromise();
    }
    async updateName(activtiyId: string, dto: UpdateActivityNameDTO): Promise<void> {
        await this.http.patch(`${environment.api}/business/activities/name/${activtiyId}`, dto).toPromise();
    }
    async updateCapacity(activityId: string, dto: UpdateActivityCapacityDTO): Promise<void> {
        await this.http.patch(`${environment.api}/business/activities/capacity/${activityId}`, dto).toPromise();
    }
    async updateDescription(activtiyId: string, dto: UpdateActivityDescriptionDTO): Promise<void> {
        await this.http.patch(`${environment.api}/business/activities/description/${activtiyId}`, dto).toPromise();
    }
    async updateBookableStatus(activtiyId: string, dto: UpdateActivityBookableStatusDTO): Promise<void> {
        await this.http.patch(`${environment.api}/business/activities/bookable/${activtiyId}`, dto).toPromise();
    }
    async updateLocation(activtiyId: string, dto: UpdateActivityLocationDTO): Promise<void> {
        await this.http.patch(`${environment.api}/business/activities/location/${activtiyId}`, dto).toPromise();
    }

}
