import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { BusinessUserStatus } from '../../../../../../../../data/enums/business_user_status';
import { BusinessUserModel } from '../../../../../../../../data/models/business_user_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { TeamService } from 'src/wild/modules/business/services/team_service/team_service';
import { EditTeamMemberDTO } from '../../../../../../../../data/dtos/team/EditTeamMemberDTO';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';

@Component({
  selector: 'app-editteammemberform',
  templateUrl: './editteammemberform.component.html'
})
export class EditteammemberformComponent implements OnInit {

  @Input() user: BusinessUserModel;

  form: UntypedFormGroup;
  loading: boolean = false;

  statusOptions: SelectModel[] = [
    {
      name: "Active",
      value: BusinessUserStatus.ACTIVE
    },
    {
      name: "Deactivated",
      value: BusinessUserStatus.DEACTIVATED
    },
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private navigation: NavigationService,
    private notify: NotifyService,
    private teamService: TeamService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      role: new UntypedFormControl(this.user.roles[0], [Validators.required]),
      admin: new UntypedFormControl(this.user.roles.some(r => r == "admin"), []),
      contacts: new UntypedFormControl(this.user.roles.some(r => r == "contacts"), []),
      calendar: new UntypedFormControl(this.user.roles.some(r => r == "calendar"), []),
      orders: new UntypedFormControl(this.user.roles.some(r => r == "orders"), []),
      finance: new UntypedFormControl(this.user.roles.some(r => r == "finance"), []),
      safety: new UntypedFormControl(this.user.roles.some(r => r == "safety"), []),
      workingHours: new UntypedFormControl(this.user.roles.some(r => r == "workingHours"), []),
      status: new UntypedFormControl(this.user.status, [Validators.required])
    });
  }


  async onSubmit(data: UpdateUserFormData) {
    var roles: string[] = [];
    if (data.admin == true)
      roles.push("admin");
    if (data.contacts == true)
      roles.push("contacts");
    if (data.calendar == true)
      roles.push("calendar");
    if (data.orders == true)
      roles.push("orders");
    if (data.finance == true)
      roles.push("finance");
    if (data.workingHours == true)
      roles.push("workingHours");
    if (data.safety == true)
      roles.push("safety");
    if (roles.length == 0) {
      this.notify.notify("Please choose a role", "Choose at least one role", NotifyType.ERROR);
      return;
    }
    if (data.status == BusinessUserStatus.DEACTIVATED) {
      var confirmDeletion = confirm("WARNING: Are you sure you want to deactivate this user? This is not recoverable and they will be removed from your business.")
      if (confirmDeletion != true)
        return;
    }
    this.loading = true;
    var dto: EditTeamMemberDTO = {
      status: data.status,
      roles: roles
    }
    await this.teamService.updateUser(this.user.id, dto)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })

  }

  next() {

    var settings: ModalModel = {
      design: ModalDesign.CENTRAL,
      type: ModalType.INFO,
      ctaonetextclick: () => {
        this.navigation.refreshPage(WildNavigationType.TEAM);
        this.notify.notify("Updated", "User has been updated", NotifyType.SUCCESS);
      },
      ctatwotextclick: () => {
        this.navigation.refreshPage(WildNavigationType.TEAM);
        this.notify.notify("Updated", "User has been updated", NotifyType.SUCCESS);
      },
      header: "FYI",
      description: "To avoid disruption if updating roles, please ask this user to log out and login to the system to refresh their access."

    }
    this.modalService.show(settings);
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  resetForm() {
    this.loading = false;
  }

  cancel() {
    this.modalService.hide();
  }

}

interface UpdateUserFormData {
  role: string,
  status: BusinessUserStatus,
  admin: boolean,
  contacts: boolean,
  safety: boolean,
  calendar: boolean,
  orders: boolean,
  finance: boolean,
  workingHours: boolean,
}
