import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ContactRepositoryService {

  constructor(private readonly firestore: FirestoreService) { }

  async add(data: any) {
    await this.firestore.create("contact", data)
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        throw Error(errorMessage);
      });
  }
}

