import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, } from '@angular/router';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { UtilService } from 'src/shared/services/util/util_service';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { LinkedAccountsService } from '../../../services/linkedaccounts_service';
import { UserModel } from '../../../../../../data/models/user_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { BookingProfileFormForLinkedAccountComponent } from 'src/shared/components/bookingprofileforlinkedaccount.component';
import { LinkedAccountDTO } from '../../../../../../data/dtos/linkedaccounts/LinkedAccountDTO';

@Component({
  template: `
   <app-wrapper header="Linked Accounts" paragraph="Manage your linked accounts" icon="plus"
  ctatext="Add" (onClick)="new()">
  <div *ngIf="linkedaccounts$">
    <ng-container *ngIf="linkedaccounts$ | async as linkedaccounts; else loadingOrError">
      <div *ngIf="!linkedaccounts || linkedaccounts?.length == 0; else linkedaccountsstable">
        <app-empty icon="users" header="No Records"
          description="All your linked accounts will appear here" ctatext="Add" (onClick)="new()" [showcta]="true">
        </app-empty>
      </div>
      <ng-template #linkedaccountsstable>
        <app-table *ngIf="linkedaccounts?.length > 0" [headers]="[
        {
          header:'Name'
        },
        {
          header:'Created'
        },
        {
          header:'edit'
        }]">
          <tr *ngFor="let account of linkedaccounts">
            <td app-table-cell [value]="account?.firstName + ' ' + account?.surname"></td>
            <td app-table-cell [value]="getReadableDate(account?.creationDateIso)"></td>
            <td app-table-cell [cta]="true" (onClick)="editLinkedAccount(account)" value="edit"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
  <app-appdownloadlinks header="Manage Linked Accounts on the Wild App"></app-appdownloadlinks>
</app-wrapper>
  `
})
export class LinkedAccountsComponent implements OnInit {

  constructor(
    private dateService: DatesService,
    private linkedAccountsService: LinkedAccountsService,
    private modalService: ModalService,
    private _formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private util: UtilService,
    private router: Router,
    private panelService: PanelService) {

  }
  public error: ErrorModel = null;
  linkedaccounts$: Observable<LinkedAccountDTO[]>;
  loading: boolean = false;

  ngOnInit(): void {
    this.load();
  }

  getReadableDate(date: string): string {
    return this.dateService.getReadableDateTimeFromISO(date)
  }

  new() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      large: true,
      component: BookingProfileFormForLinkedAccountComponent,
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.modalService.hide();
        }
      }]
    }
    this.modalService.show(settings);
  }

  editLinkedAccount(account: LinkedAccountDTO) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      large: true,
      component: BookingProfileFormForLinkedAccountComponent,
      componentInputs: [
        {
          inputName: "id",
          value: account.id ?? ""
        }
      ],
      componentOutputs: [{
        outputName: "updated",
        func: () => {
          this.load();
          this.modalService.hide();
        }
      }]
    }
    this.modalService.show(settings);
  }

  load() {
    this.linkedaccounts$ = this.linkedAccountsService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
