
<div class="bg-white pb-8">
        <ng-container *ngIf="categorisedServices$ | async as categorisedServices; else loadingOrError">
        <div *ngIf="!categorisedServices || categorisedServices.categories.length == 0; else categorisedServicesList">
            <app-empty icon="water" header="No services yet" description=""></app-empty>    
        </div>
        <ng-template #categorisedServicesList> 
            <!-- Wild PRO Start    -->
            <app-subscribe></app-subscribe>
            <!-- Wild PRO End     -->
            <!-- CATEGORY LOOP START    -->
            <div *ngFor="let category of categorisedServices.categories" >
                <h2 class="text-lg mt-6">{{category.category}}</h2>
                <p *ngIf="!category.serviceAndPrices || category.serviceAndPrices?.length == 0" class="text-xs italic text-gray-500">Not Available</p>
                <!-- SERVICES LOOP START    -->
                <div  *ngFor="let service of category.serviceAndPrices; let i = index" >
                    <!-- BOOK ROW START -->
                    <div class="flex w-full my-4 items-center">
                        <div class="hidden sm:flex-none sm:block ">
                                    <div (click)="readmore(service)" class="  cursor-pointer relative w-20 h-20 rounded-lg overflow-hidden">
                                        <img [src]="service.image != null ? imagesUrl + service.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"  alt="Service Image" class="w-full h-full object-center object-cover">
                                    </div>
                                </div>
                                <div class="flex-1 sm:px-4">
                                    <h3 class="text-md font-medium text-gray-900 line-clamp-2">{{service.name}}</h3>
                                    <div *ngIf="service.type == ServiceType.CALENDARBASED && service.capacity > 1" class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 my-2">
                                        Session for {{service.capacity}} people
                                    </div>
                                    <div *ngIf="service.type == ServiceType.CALENDARBASED && service.capacity == 1" class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 my-2">
                                        121 session
                                    </div>
                                    <p class="text-sm text-gray-700 line-clamp-2">{{service.description}}</p>
                                    <a  target="_parent" *ngIf="isWidgetUser" [href]="service.widgeturl"  class="text-xs sm:text-sm text-yellow-600 hover:underline cursor-pointer">find out more</a>
                                    <p *ngIf="!isWidgetUser" (click)="readmore(service)" class="text-xs sm:text-sm text-yellow-600 hover:underline cursor-pointer">find out more</p>
                                </div>
                                <div class="flex-none ml-3">
                                    <div  class="flex">
                                        <div class="text-right">
                                            <div class="text-md  text-black font-medium leading-tight">{{service.formattedPrice}}</div>
                                            <div (click)="membershipPriceInfo(service)" *ngIf="service.membershipDiscountAvailableSubjectToConditions" class="text-md  text-green-700 font-medium leading-tight bg-green-50 hover:bg-green-100 cursor-pointer p-1 flex justify-between items-center">
                                                <div class="flex-shrink-0 mr-1">
                                                    <svg class="h-4 w-4 text-green-400" viewBox="0 0 20 20" fill="currentColor"
                                                        aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div>{{service.formattedMemberPrice}}</div>
                                            </div>
                                            <div style="font-size: .5rem" class="text-gray-500 leading-none">per person</div>
                                            <div class="text-sm text-gray-500">{{service.duration}}</div>
                                        </div>
                                        <div class="ml-3">
                                            <a target="_parent" *ngIf="isWidgetUser" [href]="service.widgeturl" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">Book</a>
                                            <a *ngIf="!isWidgetUser"  (click)="book(service)" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">Book</a>
                                        </div>
                                    </div>
                            </div>
                    </div>
                     <!-- BOOK ROW END -->
                    <!-- DIVIDER START -->
                    <div *ngIf="i < (category.serviceAndPrices.length - 1)" class="relative my-4">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-200"></div>
                        </div>
                        <div class="relative flex justify-center">
                            <span class="bg-white px-2 text-gray-200">
                                <fa-icon icon="water"></fa-icon>
                            </span>
                        </div>
                    </div>
                    <!-- DIVIDER END -->
                </div> 
                <!-- SERVICES LOOP END  -->
            </div>
             <!-- CATEGORY LOOP END    -->
        <!-- COUPONS START -->
        <h2 *ngIf="categorisedServices.coupons && categorisedServices.coupons?.length > 0" class="text-lg">Vouchers</h2>
        <!-- LOOP START -->
        <div  *ngFor="let coupon of categorisedServices.coupons; let i = index" >
            <!-- BOOKING ROW START -->
            <div class="flex w-full my-4">
                <div class="hidden sm:flex-none sm:block ">
                        <div (click)="readmoreCoupons(coupon)" class="  cursor-pointer relative w-20 h-20 rounded-lg overflow-hidden">
                            <img [src]="coupon.image != null ? imagesUrl + coupon.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"  alt="Service Image" class="w-full h-full object-center object-cover">
                        </div>
                    </div>
                    <div class="flex-1 sm:px-4">
                        <h3 class="text-md font-medium text-gray-900 line-clamp-2">{{coupon.name}}</h3>
                        <p class="text-sm text-gray-700 line-clamp-2">{{coupon.description}}</p>
                        <p *ngIf="!isWidgetUser" (click)="readmoreCoupons(coupon)" class="text-xs sm:text-sm text-yellow-600 hover:underline cursor-pointer">find out more</p>
                    </div>
                    <div class="flex-none">
                        <div class="flex">
                            <div class="text-right">
                                <div class="text-md  text-black font-medium leading-tight">{{coupon.formattedPrice}}</div>
                            </div>
                            <div class="ml-3">
                            <a *ngIf="isWidgetUser" target="_parent" [href]="coupon.widgeturl" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">Buy</a>
                                <a *ngIf="!isWidgetUser" (click)="bookCoupon(coupon)" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">
                                <span *ngIf="!coupon.addingToCart">Buy</span>
                                <span *ngIf="coupon.addingToCart" class="loader"></span>
                                </a>
                            </div>
                        </div>
                </div>
            </div>
            <!-- BOOKING ROW END --> 
            <!-- DIVIDER START -->
            <div *ngIf="i < (categorisedServices.coupons?.length - 1)" class="relative my-4">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200"></div>
                    </div>
                    <div class="relative flex justify-center">
                        <span class="bg-white px-2 text-gray-200">
                            <fa-icon icon="water"></fa-icon>
                        </span>
                    </div>
                </div>
                <!-- DIVIDER END -->
            </div>
            <!-- LOOP END -->
        <!-- COUPONS END -->
        <!-- POWERED BY MESSAGE START -->
        <div *ngIf="isWidgetUser" class="my-12 flex items-center flex-col justify-center">
            <p class="text-md">powered by</p>
            <a target="_blank" href="https://wildopenwater.com"><img  class="h-16 mt-3" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fwild%20logo%20small.png?alt=media&token=5db7aaeb-d57d-455b-bb0b-966738cfde41"/></a>
        </div>
         <!-- POWERED BY MESSAGE END -->
        </ng-template> 
        </ng-container>  
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <div class="py-4 lg:py-0">
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>        
                </div>
            </ng-template>
        </ng-template>     
</div>