<div class="pb-6 min-h-screen">
  <span class="md:hidden"></span>
  <div [ngClass]="{'mt-4': header || ctatext,'max-w-7xl':fullwidth != true}"
    class="mx-auto px-4 sm:px-6 md:px-8 flex justify-between items-end md:items-center">
    <div class="max-w-5xl">
      <div *ngIf="breadcrumbs" class="h-10 flex items-center">
        <nav class="flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li *ngFor="let breadcrumb of breadcrumbs;let i = index">
              <div *ngIf="i == 0">
                <a (click)="breadCrumbClick(breadcrumb)" class="text-gray-300 hover:text-gray-500 cursor-pointer">
                  <fa-icon class="flex-shrink-0 h-5 w-5" *ngIf="breadcrumb.icon" [icon]="breadcrumb.icon"></fa-icon>
                </a>
              </div>
              <div *ngIf="i > 0" class="flex items-center">
                <fa-icon class="flex-shrink-0 h-5 w-5" *ngIf="breadcrumb.icon" [icon]="breadcrumb.icon"></fa-icon>
                <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span (click)="breadCrumbClick(breadcrumb)" class="ml-4 text-sm font-medium text-gray-300 "
                  [ngClass]="{'hover:text-gray-600 cursor-pointer': breadcrumb.page != WildNavigationType.UNKNOWN,'pointer-events-none': breadcrumb.page == WildNavigationType.UNKNOWN}">{{breadcrumb.label}}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <h1 *ngIf="header" class="text-2xl font-extrabold tracking-tight text-gray-900 ">{{header}}</h1>
      <p *ngIf="paragraph" class="wild-paragraph">{{paragraph}}</p>
    </div>
    <div *ngIf="icon" class="pl-2">
      <button (click)="click()" type="button" class="md:hidden wild-btn-icon-primary">
        <fa-icon class="h-6 w-6" [icon]="icon"></fa-icon>
      </button>
      <button (click)="click()" type="button" class="hidden md:block wild-btn-primary">
        {{ctatext}}
      </button>
    </div>
  </div>

  <div [ngClass]="{'max-w-7xl':fullwidth != true}" class="mx-auto px-4 sm:px-6 md:px-8 pt-5">
    <ng-content></ng-content>
  </div>
</div>