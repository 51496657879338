<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }" class="py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <app-titles [data]="data"></app-titles>
    </div>
  </div>
  <div class="relative overflow-hidden pt-16">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <img [src]="data.image" alt="App screenshot" class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442">
      <div class="relative" aria-hidden="true">
        <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
      </div>
    </div>  
  </div>
  <div *ngIf="data.data">
    <div [ngClass]="{'max-w-xl': data.data.length == 1, 'max-w-3xl': data.data.length == 2,'max-w-7xl': data.data.length > 2}" class="mx-auto mt-16  px-6 sm:mt-20 md:mt-24 lg:px-8">
      <dl [ngClass]="{'lg:grid-cols-3': data.data.length > 2}" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none  lg:gap-x-8 lg:gap-y-16">
        <div *ngFor="let data of data.data;let i=index" class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <fa-icon [class]="brochureSiteHelperService.getTextColourClassBasedOnIndex(i)" class="h-5 w-5 absolute top-0 left-1" [icon]="data.icon"></fa-icon>
            {{data.title}}
          </dt>
          <dd class="inline">{{data.subtitle}}</dd>
        </div>
      </dl>
    </div>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div *ngIf="data.ctas" class="mt-12">
      <app-ctas [data]="data"></app-ctas>
    </div>
  </div>
</div>
