import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';
import { UnsignedWaiversDTO } from '../../../../data/dtos/waiver/UnsignedWaivers';

@Injectable({
  providedIn: 'root'
})
export class UnsignedWaiversService {

  constructor(
    private http: HttpService
  ) { }

  list(): Observable<UnsignedWaiversDTO[]> {
    return this.http.get(`${environment.api}/user/waivers/unsigned`);
  }

}

