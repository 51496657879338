<app-wild-header></app-wild-header>
<div class="min-h-full pt-16 pb-12 flex flex-col bg-white">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 flex justify-center">
            <a href="/" class="inline-flex">
                <img class="h-12 w-auto"
                    src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FW%20only.png?alt=media&token=ef135907-5559-4aba-bec3-a80b5b4a1fa6"
                    alt="">
            </a>
        </div>
        <div class="py-16">
            <div class="text-center">
                <p class="text-sm font-semibold wild-text-primary uppercase tracking-wide">404 error</p>
                <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found.</h1>
                <p class="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
                <div class="mt-6">
                    <a [routerLink]="['']" class="wild-link">Go back home<span aria-hidden="true"> &rarr;</span></a>
                </div>
            </div>
        </div>
    </main>
</div>
<app-footer></app-footer>