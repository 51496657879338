<div class="mt-4" *ngIf="!swimspot.oceanWeatherHourlySummariesInDayChunks">
    <app-empty icon="water" header="No Ocean Forecast"
        description="Ocean forecasts are available on coastal locations within 24 hours of review"></app-empty>
</div>
<div style="overflow-x:scroll">
    <div style="min-width:750px" class="mt-4" *ngIf="swimspot.oceanWeatherHourlySummariesInDayChunks">

        <div class="mb-6" *ngFor="let chunk of swimspot.oceanWeatherHourlySummariesInDayChunks">
            <div class="md:flex items-center py-4">
                <!-- This example requires Tailwind CSS v2.0+ -->
                <div *ngIf="toggleReallyDetailed" class="flex items-center mb-4 md:mb-0">
                    <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                    <button (click)="toggleHourly(chunk)" type="button"
                        [ngClass]="{'wild-bg-primary':chunk.showHourly,'bg-gray-200':!chunk.showHourly}"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span [ngClass]="{'translate-x-5':chunk.showHourly,'translate-x-0':!chunk.showHourly}"
                            aria-hidden="true"
                            class=" pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                    <span class="ml-3" id="annual-billing-label">
                        <span class="text-sm font-medium text-gray-900">Show Hourly</span>
                        <span class="text-sm text-gray-500"></span>
                    </span>
                </div>
                <!-- This example requires Tailwind CSS v2.0+ -->
                <div class="flex items-center mb-4 md:ml-4 md:mb-0">
                    <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                    <button (click)="toggleDetailed(chunk)" type="button"
                        [ngClass]="{'wild-bg-primary':chunk.detailed,'bg-gray-200':!chunk.detailed}"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span [ngClass]="{'translate-x-5':chunk.detailed,'translate-x-0':!chunk.detailed}"
                            aria-hidden="true"
                            class=" pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                    <span class="ml-3" id="annual-billing-label">
                        <span class="text-sm font-medium text-gray-900">Detailed Charts</span>
                        <span class="text-sm text-gray-500"></span>
                    </span>
                </div>
                <!-- This example requires Tailwind CSS v2.0+ -->
                <div *ngIf="toggleReallyDetailed" class="flex items-center md:ml-4 ">
                    <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                    <button (click)="toggleReallyDetailed(chunk)" type="button"
                        [ngClass]="{'wild-bg-primary':chunk.reallyDetailed,'bg-gray-200':!chunk.reallyDetailed}"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span [ngClass]="{'translate-x-5':chunk.reallyDetailed,'translate-x-0':!chunk.reallyDetailed}"
                            aria-hidden="true"
                            class=" pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                    <span class="ml-3" id="annual-billing-label">
                        <span class="text-sm font-medium text-gray-900">Full Charts</span>
                        <span class="text-sm text-gray-500"></span>
                    </span>
                </div>
            </div>
            <div>
                <!-- Title Bar -->
                <div class="wild-bg-primary w-full p-2 ">
                    <h2 class="text-white text-lg">{{chunk.date | date : 'EEEE, MMMM d'}}</h2>
                </div>
                <!-- Headers -->
                <div class="flex w-full items-center bg-gray-500 text-white py-1">
                    <!-- Time -->
                    <div class="w-2/12 text-center ">
                        <p>Time</p>
                    </div>
                    <!-- Conditions -->
                    <!-- <div class="w-2/12 text-center ">
                <p>Conditions</p>
              </div> -->
                    <!-- Waves -->
                    <div class="w-3/12 text-center border-l-2 border-white">
                        <p>Waves</p>
                    </div>
                    <!-- Swell -->
                    <!-- <div class="w-2/12 text-center border-l-2 border-white">
                <p>Swell</p>
              </div> -->

                    <!-- Wind -->
                    <div class="w-3/12 text-center border-l-2 border-white">
                        <p>Wind</p>
                    </div>
                    <!-- Water Temperature -->
                    <div class="w-2/12 text-center border-l-2 border-white">
                        <p>Water</p>
                    </div>
                    <!-- Weather -->
                    <div class="w-2/12 text-center border-l-2 border-white">
                        <p>Air</p>
                    </div>
                </div>
                <!-- Sub-headers -->
                <div class="flex w-full bg-gray-700 text-white text-xs py-1">
                    <!-- Time -->
                    <div class="w-2/12 text-center ">
                        <p>Hours</p>
                    </div>
                    <!-- Conditions -->
                    <!-- <div class="w-2/12 text-center ">
                <p>Conditions</p>
              </div> -->
                    <!-- Waves -->
                    <div class="w-3/12 text-center flex">
                        <p class="w-1/3">Height</p>
                        <p class="w-1/3">Period</p>
                        <p class="w-1/3">Direction</p>
                    </div>
                    <!-- Swell -->
                    <!-- <div class="w-2/12 text-center border-l-2 border-white">
                <p>Swell</p>
              </div> -->

                    <!-- Wind -->
                    <div class="w-3/12 text-center flex">
                        <p class="w-1/3">Speed</p>
                        <p class="w-1/3">Gusts</p>
                        <p class="w-1/3">Direction</p>
                    </div>
                    <!-- Water Temperature -->
                    <div class="w-2/12 text-center">
                        <p>Temp</p>
                    </div>
                    <!-- Weather -->
                    <div class="w-2/12 text-center">
                        <p>Temp</p>
                    </div>
                </div>
                <!-- Data -->
                <div *ngFor="let data of chunk.data">
                    <div [ngClass]="{'border-b-2 border-gray-300':chunk.detailed}" class="flex w-full" *ngIf="
                    (chunk.showHourly) || 
                    (data?.startdatetime | date : 'shortTime') == '6:00 AM' ||
                    (data?.startdatetime | date : 'shortTime') == '12:00 PM' ||
                    (data?.startdatetime | date : 'shortTime') == '6:00 PM'">
                        <!-- Time -->
                        <div class="w-2/12 bg-gray-300 flex items-center justify-center">
                            <p class="text-grey-600 text-xs">{{data?.startdatetime | date : 'shortTime'}}</p>
                        </div>
                        <!-- Conditions -->
                        <!-- <div class="w-2/12 bg-green-500 flex items-center justify-center">
                    <p class="text-grey-600 text-xs">No chop</p>
                </div> -->
                        <!-- Waves -->
                        <div class="w-3/12 py-2">
                            <div *ngIf="chunk.detailed">
                                <p class="ml-4 text-xs text-gray-400">Combined Waves</p>
                            </div>
                            <div class="flex items-center justify-around">
                                <!-- Wave Height -->
                                <div class="text-center  w-1/3">
                                    <p><span class="font-semibold">{{(data?.waveHeight?.average * 3.28084) | number:
                                            '1.0-1'}}</span><span class="font-light text-xs">ft</span></p>
                                </div>
                                <!-- Wave Period -->
                                <div class="text-center  w-1/3">
                                    <p><span class="font-semibold">{{data?.wavePeriod?.average | number:
                                            '1.0-0'}}</span><span class="font-light text-xs">s</span></p>
                                </div>
                                <!-- Wave Direction -->
                                <div class="text-center  w-1/3">
                                    <div class="flex justify-center">
                                        <svg [ngStyle]="{'transform': 'rotate(' + (data?.waveDirection?.average + 180) + 'deg)'}"
                                            style="transition-timing-function: ease-out; transition-duration: 2s;transition-property: transform;"
                                            class="h-6 w-6" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icons">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="m42 30v28c0 1.105-.895 2-2 2h-16c-1.105 0-2-.895-2-2v-28z"
                                                            fill="#0a5078" />
                                                    </g>
                                                    <g>
                                                        <path d="m22 32h20v1h-20z" fill="#00325a" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="m49.993 32h-35.986c-1.649 0-2.59-1.884-1.598-3.202l17.993-23.923c.8-1.064 2.397-1.064 3.197 0l17.993 23.923c.991 1.318.051 3.202-1.599 3.202z"
                                                            fill="#00a0c8" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div *ngIf="chunk.detailed" class="text-xs">
                                        <span [ngSwitch]="data?.waveDirectionCardinalDirection">
                                            <span *ngSwitchCase="CardinalDirection.N">N</span>
                                            <span *ngSwitchCase="CardinalDirection.NNE">NNE</span>
                                            <span *ngSwitchCase="CardinalDirection.NE">NE</span>
                                            <span *ngSwitchCase="CardinalDirection.ENE">ENE</span>
                                            <span *ngSwitchCase="CardinalDirection.E">E</span>
                                            <span *ngSwitchCase="CardinalDirection.ESE">ESE</span>
                                            <span *ngSwitchCase="CardinalDirection.SE">SE</span>
                                            <span *ngSwitchCase="CardinalDirection.SSE">SSE</span>
                                            <span *ngSwitchCase="CardinalDirection.S">S</span>
                                            <span *ngSwitchCase="CardinalDirection.SSW">SSW</span>
                                            <span *ngSwitchCase="CardinalDirection.SW">SW</span>
                                            <span *ngSwitchCase="CardinalDirection.WSW">WSW</span>
                                            <span *ngSwitchCase="CardinalDirection.W">W</span>
                                            <span *ngSwitchCase="CardinalDirection.WNW">WNW</span>
                                            <span *ngSwitchCase="CardinalDirection.NW">NW</span>
                                            <span *ngSwitchCase="CardinalDirection.NNW">NNW</span>
                                            <span *ngSwitchDefault>--</span>
                                        </span>
                                    </div>
                                    <div *ngIf="chunk.reallyDetailed" class="mt-1" style="font-size: 0.6em;">
                                        <p>{{data?.waveDirection?.average | number: '1.0-0'}}°</p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="chunk.detailed">
                                <p class="ml-4 text-xs text-gray-400">Primary Swell</p>
                            </div>
                            <div *ngIf="chunk.detailed" class="flex items-center justify-around">
                                <!-- Wave Height -->
                                <div class="text-center  w-1/3">
                                    <p><span class="font-semibold">{{(data?.swellHeight?.average * 3.28084) | number:
                                            '1.0-1'}}</span><span class="font-light text-xs">ft</span></p>
                                </div>
                                <!-- Wave Period -->
                                <div class="text-center  w-1/3">
                                    <p><span class="font-semibold">{{data?.swellPeriod?.average | number:
                                            '1.0-0'}}</span><span class="font-light text-xs">s</span></p>
                                </div>
                                <!-- Wave Direction -->
                                <div class="text-center  w-1/3">
                                    <div class="flex justify-center">
                                        <svg [ngStyle]="{'transform': 'rotate(' + (data?.swellDirection?.average + 180) + 'deg)'}"
                                            style="transition-timing-function: ease-out; transition-duration: 2s;transition-property: transform;"
                                            class="h-6 w-6" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icons">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="m42 30v28c0 1.105-.895 2-2 2h-16c-1.105 0-2-.895-2-2v-28z"
                                                            fill="#0a5078" />
                                                    </g>
                                                    <g>
                                                        <path d="m22 32h20v1h-20z" fill="#00325a" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="m49.993 32h-35.986c-1.649 0-2.59-1.884-1.598-3.202l17.993-23.923c.8-1.064 2.397-1.064 3.197 0l17.993 23.923c.991 1.318.051 3.202-1.599 3.202z"
                                                            fill="#00a0c8" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div *ngIf="chunk.detailed" class="text-xs">
                                        <span [ngSwitch]="data?.swellDirectionCardinalDirection">
                                            <span *ngSwitchCase="CardinalDirection.N">N</span>
                                            <span *ngSwitchCase="CardinalDirection.NNE">NNE</span>
                                            <span *ngSwitchCase="CardinalDirection.NE">NE</span>
                                            <span *ngSwitchCase="CardinalDirection.ENE">ENE</span>
                                            <span *ngSwitchCase="CardinalDirection.E">E</span>
                                            <span *ngSwitchCase="CardinalDirection.ESE">ESE</span>
                                            <span *ngSwitchCase="CardinalDirection.SE">SE</span>
                                            <span *ngSwitchCase="CardinalDirection.SSE">SSE</span>
                                            <span *ngSwitchCase="CardinalDirection.S">S</span>
                                            <span *ngSwitchCase="CardinalDirection.SSW">SSW</span>
                                            <span *ngSwitchCase="CardinalDirection.SW">SW</span>
                                            <span *ngSwitchCase="CardinalDirection.WSW">WSW</span>
                                            <span *ngSwitchCase="CardinalDirection.W">W</span>
                                            <span *ngSwitchCase="CardinalDirection.WNW">WNW</span>
                                            <span *ngSwitchCase="CardinalDirection.NW">NW</span>
                                            <span *ngSwitchCase="CardinalDirection.NNW">NNW</span>
                                            <span *ngSwitchDefault>--</span>
                                        </span>
                                    </div>
                                    <div *ngIf="chunk.reallyDetailed" class="mt-1" style="font-size: 0.6em;">
                                        <p>{{data?.swellDirection?.average | number: '1.0-0'}}°</p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="chunk.detailed">
                                <p class="ml-4 text-xs text-gray-400">Wind Waves</p>
                            </div>
                            <div *ngIf="chunk.detailed" class="flex items-center justify-around">
                                <!-- Wave Height -->
                                <div class="text-center  w-1/3">
                                    <p><span class="font-semibold">{{(data?.windWaveHeight?.average * 3.28084) | number:
                                            '1.0-1'}}</span><span class="font-light text-xs">ft</span></p>
                                </div>
                                <!-- Wave Period -->
                                <div class="text-center  w-1/3">
                                    <p><span class="font-semibold">{{data?.windWavePeriod?.average | number:
                                            '1.0-0'}}</span><span class="font-light text-xs">s</span></p>
                                </div>
                                <!-- Wave Direction -->
                                <div class="text-center  w-1/3">
                                    <div class="flex justify-center">
                                        <svg [ngStyle]="{'transform': 'rotate(' + (data?.windWaveDirection?.average + 180) + 'deg)'}"
                                            style="transition-timing-function: ease-out; transition-duration: 2s;transition-property: transform;"
                                            class="h-6 w-6" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icons">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="m42 30v28c0 1.105-.895 2-2 2h-16c-1.105 0-2-.895-2-2v-28z"
                                                            fill="#0a5078" />
                                                    </g>
                                                    <g>
                                                        <path d="m22 32h20v1h-20z" fill="#00325a" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="m49.993 32h-35.986c-1.649 0-2.59-1.884-1.598-3.202l17.993-23.923c.8-1.064 2.397-1.064 3.197 0l17.993 23.923c.991 1.318.051 3.202-1.599 3.202z"
                                                            fill="#00a0c8" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-xs">
                                        <span [ngSwitch]="data?.windWaveDirectionCardinalDirection">
                                            <span *ngSwitchCase="CardinalDirection.N">N</span>
                                            <span *ngSwitchCase="CardinalDirection.NNE">NNE</span>
                                            <span *ngSwitchCase="CardinalDirection.NE">NE</span>
                                            <span *ngSwitchCase="CardinalDirection.ENE">ENE</span>
                                            <span *ngSwitchCase="CardinalDirection.E">E</span>
                                            <span *ngSwitchCase="CardinalDirection.ESE">ESE</span>
                                            <span *ngSwitchCase="CardinalDirection.SE">SE</span>
                                            <span *ngSwitchCase="CardinalDirection.SSE">SSE</span>
                                            <span *ngSwitchCase="CardinalDirection.S">S</span>
                                            <span *ngSwitchCase="CardinalDirection.SSW">SSW</span>
                                            <span *ngSwitchCase="CardinalDirection.SW">SW</span>
                                            <span *ngSwitchCase="CardinalDirection.WSW">WSW</span>
                                            <span *ngSwitchCase="CardinalDirection.W">W</span>
                                            <span *ngSwitchCase="CardinalDirection.WNW">WNW</span>
                                            <span *ngSwitchCase="CardinalDirection.NW">NW</span>
                                            <span *ngSwitchCase="CardinalDirection.NNW">NNW</span>
                                            <span *ngSwitchDefault>--</span>
                                        </span>
                                    </div>
                                    <div *ngIf="chunk.reallyDetailed" class="mt-1" style="font-size: 0.6em;">
                                        <p>{{data?.windWaveDirection?.average | number: '1.0-0'}}°</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Swell --- PADDING MANAGED HERE-->
                        <!-- <div class="w-2/12 flex items-center justify-evenly py-2">
                  <div class="text-center">
                    <p><span class="font-semibold">{{data?.swellHeight?.average}}</span><span class="font-light text-xs">m</span></p>
                  </div>
                  <div class="text-center">
                    <p><span class="font-semibold">{{data?.swellPeriod?.average}}</span><span class="font-light text-xs">s</span></p>
                  </div>
                  <div class="text-center">
                      <div>
                        <svg [ngStyle]="{'transform': 'rotate(' + data?.swellDirection?.average + 'deg)'}" style="transition-timing-function: ease-out; transition-duration: 2s;transition-property: transform;" 
      class="h-6 w-6" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g id="Icons"><g><g><path d="m42 30v28c0 1.105-.895 2-2 2h-16c-1.105 0-2-.895-2-2v-28z" fill="#0a5078"/></g><g><path d="m22 32h20v1h-20z" fill="#00325a"/></g><g><path d="m49.993 32h-35.986c-1.649 0-2.59-1.884-1.598-3.202l17.993-23.923c.8-1.064 2.397-1.064 3.197 0l17.993 23.923c.991 1.318.051 3.202-1.599 3.202z" fill="#00a0c8"/></g></g></g></svg>
                    </div>
   
                  </div>
                </div> -->

                        <!-- Wind -->
                        <div class="w-3/12 flex items-center justify-around">
                            <!-- Wind Speed -->
                            <div class="text-center  w-1/3">
                                <p><span class="font-semibold">{{(data?.windSpeed?.average * 2.23694) | number:
                                        '1.0-0'}}</span><span class="font-light text-xs">mph</span></p>
                            </div>
                            <!-- Wind Gusts -->
                            <div class="text-center  w-1/3">
                                <p><span class="font-semibold">{{(data?.gust?.max * 2.23694) | number:
                                        '1.0-0'}}</span><span class="font-light text-xs">mph</span></p>
                            </div>
                            <!-- Wind Direction -->
                            <div class="text-center  w-1/3">
                                <div class="flex justify-center">
                                    <svg [ngStyle]="{'transform': 'rotate(' + (data?.windDirection?.average + 180) + 'deg)'}"
                                        style="transition-timing-function: ease-out; transition-duration: 2s;transition-property: transform;"
                                        class="h-6 w-6" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Icons">
                                            <g>
                                                <g>
                                                    <path d="m42 30v28c0 1.105-.895 2-2 2h-16c-1.105 0-2-.895-2-2v-28z"
                                                        fill="#0a5078" />
                                                </g>
                                                <g>
                                                    <path d="m22 32h20v1h-20z" fill="#00325a" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="m49.993 32h-35.986c-1.649 0-2.59-1.884-1.598-3.202l17.993-23.923c.8-1.064 2.397-1.064 3.197 0l17.993 23.923c.991 1.318.051 3.202-1.599 3.202z"
                                                        fill="#00a0c8" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div *ngIf="chunk.detailed" class="text-xs">
                                    <span [ngSwitch]="data?.windDirectionCardinalDirection">
                                        <span *ngSwitchCase="CardinalDirection.N">N</span>
                                        <span *ngSwitchCase="CardinalDirection.NNE">NNE</span>
                                        <span *ngSwitchCase="CardinalDirection.NE">NE</span>
                                        <span *ngSwitchCase="CardinalDirection.ENE">ENE</span>
                                        <span *ngSwitchCase="CardinalDirection.E">E</span>
                                        <span *ngSwitchCase="CardinalDirection.ESE">ESE</span>
                                        <span *ngSwitchCase="CardinalDirection.SE">SE</span>
                                        <span *ngSwitchCase="CardinalDirection.SSE">SSE</span>
                                        <span *ngSwitchCase="CardinalDirection.S">S</span>
                                        <span *ngSwitchCase="CardinalDirection.SSW">SSW</span>
                                        <span *ngSwitchCase="CardinalDirection.SW">SW</span>
                                        <span *ngSwitchCase="CardinalDirection.WSW">WSW</span>
                                        <span *ngSwitchCase="CardinalDirection.W">W</span>
                                        <span *ngSwitchCase="CardinalDirection.WNW">WNW</span>
                                        <span *ngSwitchCase="CardinalDirection.NW">NW</span>
                                        <span *ngSwitchCase="CardinalDirection.NNW">NNW</span>
                                        <span *ngSwitchDefault>--</span>
                                    </span>
                                </div>
                                <div *ngIf="chunk.reallyDetailed" class="mt-1" style="font-size: 0.6em;">
                                    <p>{{data?.windDirection?.average | number: '1.0-0'}}°</p>
                                </div>
                            </div>
                        </div>
                        <!-- Water Temperature -->
                        <div class="w-2/12 text-center flex items-center justify-center">
                            <p><span class="font-semibold">{{data?.waterTemperature?.average | number:
                                    '1.0-0'}}</span><span class="font-light text-xs">°C</span></p>
                        </div>
                        <!-- Weather -->
                        <div class="w-2/12 flex items-center justify-around">
                            <!-- Air Temperature -->
                            <div class="text-center">
                                <p><span class="font-semibold">{{data?.airTemperature?.average | number:
                                        '1.0-0'}}</span><span class="font-light text-xs">°C</span></p>
                            </div>
                            <!-- Visibility -->
                            <!-- <div class="text-center">
                    <p><span class="font-semibold">{{data?.visibility?.average}}</span><span class="font-light text-xs">km</span></p>
                  </div> -->
                            <!-- Cloud Cover -->
                            <!-- <div class="text-center">
                    <p><span class="font-semibold">{{data?.cloudCover?.average}}</span><span class="font-light text-xs">%</span></p>
                  </div> -->
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>