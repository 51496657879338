export enum SourceDocumentType {
    SWIMSPOT,
    WATERWAY,
    UNKNOWN,
    USERPROFILE,
    WATERWAYMAINPIC,
    SWIMSPOTMAINPIC,
    BUSINESS,
    USER,
    CONTACT,
    BUSINESSTEAMMEMBER,
    EVENT,
    SWIMSPOTPOST,
    BUSINESSPOST,
    EVENTPOST,
    BADGE,
    SWIMSPOTCHAT,
    SWIMSPOTREVIEW,
    SWIMSPOTALERT,
    SWIMSPOTIMAGE,
    ACTIVITYPOST,
    ACTIVITYPOSTMAPIMAGE,
    GROUP,
    GROUPMAINPIC,
    BOOKEDACTIVITY,
    BUSINESSCONTACT,
    ORDER,
    ACTIVITY
}