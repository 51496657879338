import { Component, Input} from '@angular/core';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { FeatureColour, FeaturesComponentData, FeaturesComponentListData } from 'src/wild/modules/brochure/feature_data/FeaturesData';
import { BrochureSiteHelperService } from '../brochure_site_helper_service';

@Component({
  selector: 'app-textandinfo',
  templateUrl: './textandinfo.component.html'
})
export class TextAndInfoComponent {
  constructor(private navigate:NavigationService,private brochureSiteHelperService:BrochureSiteHelperService){}

  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
  click(data:FeaturesComponentListData){
    this.navigate.navigate(data.page);
  } 
}
