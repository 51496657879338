import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { SelectModel } from 'src/shared/components/select/select_model';
import { BusinessServiceLocationType } from '../../../../data/enums/business_service_location_type';
import { LocationResultType } from '../../../../data/enums/location_result_type';
import { WaterwayType } from '../../../../data/enums/waterway_type';
import { BusinessServiceLocationModel } from '../../../../data/models/business_service_location';
import { ErrorModel } from '../../../../data/models/error_model';
import { SearchResult } from '../../../../data/models/SearchResult';
import { SearchResults } from '../../../../data/models/SearchResults';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SearchService } from 'src/shared/services/search/search_service';
import { StorageService } from 'src/shared/services/storage/storage.service';

@Component({
  template: `
     <app-modaltopper [header]="" parapraph=""></app-modaltopper>
     <app-form [form]="detailsForm" [fullwidthbutton]="false" ctaText="Add" disableDivide="true" [hidebuttons]="true">
          <app-inputgroup>
          <app-select (onChange)="typeChanged()" [options]="typeOptions" [form]="detailsForm" name="type" description="Location Type" [span]=6>
            </app-select>
          </app-inputgroup>    
          <app-inputgroup>
              <app-input [form]="detailsForm" type="text" name="name" description="Name" [span]=6 placeholder="By the Beach Hut"></app-input> 
          </app-inputgroup>        
          <div class="relative mt-4">
            <div class="flex justify-between mb-1">
                <label class="block wild-label">
                    Search & Set Location
                </label>
            </div>
              <form class="w-full flex md:ml-0" [formGroup]="form">
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div class=" absolute inset-y-0 left-2 flex items-center pointer-events-none">
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                              aria-hidden="true">
                              <path fill-rule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                  clip-rule="evenodd" />
                          </svg>
                      </div>
                      <input autocomplete="off" formControlName="search" type="search" name="search" id="search"
                          style="border: 2px solid #031d44;"
                          class="block w-full h-full pl-8 pr-3 py-4 text-gray-900 placeholder-gray-500 focus:outline-none sm:text-sm rounded-md"
                          placeholder="Enter a Location or what3words address">
                  </div>
              </form>
              <div *ngIf="showSearch" class="px-2 sm:px-4 w-full bg-white shadow-md z-50 max-h-96 absolute left-0 top-14">
                  <div class="max-h-80 overflow-y-scroll pb-8">
                      <div *ngIf="searchLoading">
                          <app-loader disablescreenheight="true"></app-loader>
                      </div>
                      <div *ngIf="!searchLoading">
                          <div (click)="selectSearchResult(result)" *ngFor="let result of searchResults?.results"
                              class="flex w-full items-center justify-start h-12 hover:bg-gray-50 cursor-pointer border-t-2 border-gray-50"
                              aria-label="Result">
                              <a class="cursor-pointer group inline-flex items-start py-4 px-1 ">
                                  <fa-icon *ngIf="result.type == LocationResultType.PLACE"
                                      [ngClass]="{'text-blue-500':false,'text-gray-400 group-hover:text-blue-500':true}"
                                      class="font-medium text-sm mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      icon="map-marker-alt">
                                  </fa-icon>
                                  <span class="font-medium text-sm mr-2 h-5 w-5 text-red-500"
                                      *ngIf="result.type == 3">///</span>
                                  <div>
                                      <div class="font-medium text-sm">
                                          {{result.name}}
                                      </div>
                                      <div class="text-xs">
                                          <span *ngIf="result.type == LocationResultType.PLACE">
                                              <span *ngIf="result.locationDescription">{{result.locationDescription}}</span>
                                          </span>
                                          <span *ngIf="result.type == LocationResultType.WHAT3WORDS">
                                              <span *ngIf="result.locationDescription">{{result.locationDescription}}</span>
                                          </span>
                                          <span *ngIf="result.type == LocationResultType.SWIMSPOT">
                                              <span *ngIf="result.swimSpotType == WaterwayType.COASTAL">Coastal Swim Spot
                                                  in </span>
                                              <span *ngIf="result.swimSpotType == WaterwayType.LAKE">Lake in </span>
                                              <span *ngIf="result.swimSpotType == WaterwayType.RIVER">River in </span>
                                              {{result.region}}
                                          </span>
                                      </div>
                                  </div>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div class="absolute bottom-0 left-0 w-full h-6 bg-gray-100 flex items-center px-2 justify-between">
                      <span class="wild-link text-xs sm:text-sm" (click)="clearSearch()">clear</span>
                      <span class="font-medium text-xs sm:text-sm text-gray-400">
                          <span *ngIf="!showMinCharactersNotReached && searchResults">{{searchResults.results.length}}
                              results</span>
                          <span *ngIf="showMinCharactersNotReached">type {{minSearchCharacters - charactersTyped}} more
                              character{{(minSearchCharacters - charactersTyped) > 1 ? 's':''}}</span>
                      </span>
                  </div>
              </div>
          </div>
          <div *ngIf="mapsLoaded | async" class="w-full shadow-lg rounded-lg bg-blue-500 overflow-hidden mt-3"
              style="height:300px">
              <google-map (mapClick)="updateMarker($event)" height="100%" width="100%" [options]="options">
                  <map-marker (mapDragend)="updateMarker($event)" *ngFor="let markerPosition of markerPositions"
                      [position]="markerPosition" [options]="markerOptions"></map-marker>
              </google-map>
          </div>
             <app-inputgroup>
                  <app-textarea [form]="detailsForm" type="text" name="description" description="Description" [span]=6 placeholder="The hut is just up the slip way from the beach"></app-textarea> 
              </app-inputgroup>     
      
        </app-form> 
      <div class="flex sm:justify-end flex-col-reverse sm:flex-row mt-3">
        <button (click)="hide()" type="button" class="w-full sm:w-auto wild-btn-light sm:mr-3 mt-3 sm:mt-0"
            style="min-width: 100px;">
            <span>Close</span>
        </button>
        <button (click)="save()" class="wild-btn-primary w-full sm:w-auto" style="min-width: 100px;">
            <span>Save</span>
        </button>
      </div>
    <div class="mt-3">
          <span class="text-sm text-gray-400">{{latitude}}, {{longitude}}</span>
    </div>

  `
})
export class UpdateLocationComponent implements OnInit {

  @Input() location: BusinessServiceLocationModel;
  @Output() updatedLocation = new EventEmitter<BusinessServiceLocationModel>();
  error: ErrorModel;
  typeOptions: SelectModel[] = [
    {
      name: "Physical Location",
      value: BusinessServiceLocationType.PHYSICAL
    }
  ]
  detailsForm: UntypedFormGroup;
  form: UntypedFormGroup;
  latlngForm: UntypedFormGroup;
  searchResults: SearchResults;
  searchLoading: boolean;
  showMinCharactersNotReached: boolean;
  showSearch: boolean = false;
  minSearchCharacters: number = 3;
  charactersTyped: number = 0;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;
  useLatLng: boolean;


  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptions!: google.maps.MarkerOptions;
  markerPositions!: google.maps.LatLngLiteral[];
  latitude: string;
  longitude: string;
  onboarding: boolean = false;


  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageService,
    private notifyService: NotifyService,
    private modalService: ModalService,
    private notify: NotifyService,
    private navigate: NavigationService,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private maps: MapsService) {
    this.mapsLoaded = this.maps.loadMaps().pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  typeChanged() {

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => { if (params?.onboarding == 1) this.onboarding = true; })
    this.markerOptions = { draggable: true, };
    this.options = {
      center: {
        lat: Number.parseFloat(this.location?.latitude ?? "52.549736"),
        lng: Number.parseFloat(this.location?.longitude ?? "-1.851169")
      },
      zoom: 5,
    };
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    this.detailsForm = this.fb.group({
      type: new UntypedFormControl(this.location?.type ?? BusinessServiceLocationType.PHYSICAL, []),
      name: new UntypedFormControl(this.location?.name ?? "", []),
      description: new UntypedFormControl(this.location?.description ?? "", []),
      link: new UntypedFormControl(this.location?.link ?? "", []),
    });
    this.latlngForm = this.fb.group({
      latitude: new UntypedFormControl('', [Validators.required,]),
      longitude: new UntypedFormControl('', [Validators.required]),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 1000);
    if (this.location?.latitude != null && this.location?.latitude != undefined && this.location?.longitude != null && this.location?.longitude != undefined) {
      this.latitude = this.location?.latitude;
      this.longitude = this.location?.longitude;
      setTimeout(() => {
        this.updateLatLongOnMap(this.location?.latitude, this.location?.longitude)
      }, 300)
    }

  }


  onSubmitLatLng(data: any) {
    var latlng = data.latitude + "," + data.longitude;
    const regexExp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/gi;
    if (latlng.match(regexExp) != null) {
      this.storage.setCookie("swimspotlat", data.latitude, 1);
      this.storage.setCookie("swimspotlng", data.longitude, 1);
    } else {
      this.notify.notify("Invalid Coordinates", "Please check your latitude and longitude", NotifyType.ERROR);
    }
  }

  backToSearchForm() {
    this.useLatLng = false;
  }

  enterLatLng() {
    this.useLatLng = true;
  }

  async search(value) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      if (value.search.length >= this.minSearchCharacters) {
        this.showMinCharactersNotReached = false;
        this.searchLoading = true;
        await this.searchService.swimspotSearch(value.search).pipe(take(1)).toPromise()
          .then((searchResults) => {
            this.searchResults = searchResults;
          })
          .catch((error) => {
            this.handlerError(error);
          })
          .finally(() => {
            this.searchFinished();
          })
      } else {
        this.charactersTyped = value.search.length;
        this.searchLoading = false;
        this.showMinCharactersNotReached = true;
      }
    }
  }

  searchFinished() {
    this.searchLoading = false;
  }

  handlerError(message: string) {
    this.notify.notify("Search Error", message, NotifyType.ERROR);
  }

  clearSearch() {
    this.form.controls["search"].setValue("");
    this.searchResults = null;
  }

  async selectSearchResult(result: SearchResult) {
    if (result.type == LocationResultType.PLACE) {
      this.latitude = result.latitude;
      this.longitude = result.longitude;
      this.updateLatLongOnMap(result.latitude, result.longitude);
    }
    if (result.type == LocationResultType.WHAT3WORDS) {
      var coordinates = await this.searchService.getCoordinatesForW3w(result.name).toPromise();
      this.latitude = coordinates.latitude;
      this.longitude = coordinates.longitude;
      this.updateLatLongOnMap(coordinates.latitude, coordinates.longitude);
    }
    this.clearSearch();
  }

  //MAPSTUFF

  updateLatLongOnMap(latitude: string, longitude: string): void {
    this.options = {
      center: {
        lat: Number.parseFloat(latitude),
        lng: Number.parseFloat(longitude)
      },
      zoom: 12,
    };
    this.markerPositions = [{
      lat: Number.parseFloat(latitude),
      lng: Number.parseFloat(longitude)
    }];
  }

  updateMarker(event: google.maps.MapMouseEvent) {
    var latlng = event.latLng.toJSON();
    this.markerPositions = [event.latLng.toJSON()];
    this.latitude = latlng.lat.toString();
    this.longitude = latlng.lng.toString();
  }

  save() {
    if (this.latitude == null || this.latitude == "" || this.latitude == undefined || this.longitude == null || this.longitude == "" || this.longitude == undefined) {
      this.notifyService.notify("Please Choose a location", "We need some coordinates to set a location!", NotifyType.WARNING);
      return;
    }
    if (this.detailsForm.controls["type"].value == "" || this.detailsForm.controls["type"].value == BusinessServiceLocationType.UNKNOWN) {
      this.notifyService.notify("Type", "Please choose a location type", NotifyType.WARNING);
      return;
    }
    if (this.detailsForm.controls["name"].value == "") {
      this.notifyService.notify("Name", "Please provide a name", NotifyType.WARNING);
      return;
    }
    // if (this.detailsForm.controls["description"].value == "") {
    //   this.notifyService.notify("Description", "Please provide a description", NotifyType.WARNING);
    //   return;
    // }
    var modal: BusinessServiceLocationModel = {
      latitude: this.latitude,
      longitude: this.longitude,
      description: this.detailsForm.controls["description"].value ?? "",
      name: this.detailsForm.controls["name"].value,
      type: this.detailsForm.controls["type"].value,
      link: this.detailsForm.controls["link"].value,
    }
    this.updatedLocation.emit(modal);
    this.hide();
  }

  hide() {
    this.modalService.hide();
  }

}
