<app-wild-header></app-wild-header>
<app-headersection prefix="🌊" header="Safe Outdoor Swimming" description="SOS">
</app-headersection>
<!-- This example requires Tailwind CSS v2.0+ -->
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="py-16 bg-gray-50 overflow-hidden lg:py-24">
  <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
    <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784"
      fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20"
          patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
    </svg>

    <div class="relative">
      <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Keep Safe when
        Outdoor Swimming</h2>
      <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">SOS has been developed for any swimmer,
        jumping into any body of water, at any time of year. We want to help empower you to not only be prepared for the
        swim itself but for what you will need to consider before and after swimming. Allowing you to have a Safe
        Outdoor Swimming experience.</p>
      <app-appdownloadlinks header=""></app-appdownloadlinks>
    </div>
    <!-- FEATURE ONE -->
    <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="relative">
        <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Complete your own assessment</h3>
        <p class="mt-3 text-lg text-gray-500">There are two assessment options for you to choose when deciding if you
          should or shouldn't swim.</p>

        <dl class="mt-10 space-y-10">
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="swimmer"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">SOS Assessment</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Develop your knowledge and deepen your understanding of how
              to swim safely outdoors. This assessment will guide you to make your own decisions about swimming,
              considering weather conditions, how you are feeling and the specifics of your swim spot.</dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="clock"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Dynamic Risk Assessment</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Once you complete your SOS assessment you will unlock your
              dynamic assessment. This checklist will help you consider all the safety aspects of wild swimming from
              cold shock to hypothermia, from when you last ate to how stressed you are feeling and from the current
              forecast to specifics of your body of water. Helping you make the best choices.</dd>
          </div>
        </dl>
      </div>

      <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
        <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404"
          fill="none" viewBox="0 0 784 404">
          <defs>
            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20"
              patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
        </svg>
        <img class="relative mx-auto rounded-lg" width="490"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/content%2Fimages%2Femptylifeguardseatsmaller.png?alt=media&token=c6f23af0-beb6-43af-89eb-279322139310"
          alt="">
      </div>
    </div>

    <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784"
      fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20"
          patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
    </svg>
    <!-- FEATURE TWO -->
    <div class="relative mt-12 sm:mt-16 lg:mt-24">
      <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="lg:col-start-2">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">SOS</h3>
          <p class="mt-3 text-lg text-gray-500">SOS focuses on the three core elements of your swim.</p>

          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-purple-600 text-white">
                  <fa-icon icon="exclamation-triangle"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Safety
                </p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">The first section of SOS is Safety and is designed to
                provide you with essential knowledge and safety skills to help you have a safe outdoor swimming
                experience.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white"
                  style="background: orange;">
                  <fa-icon icon="user-alt"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Ourselves</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">The second section is SOS is about Ourselves. We, as
                swimmers, are the biggest factor when it comes to accidents or issues in the water. It's really
                important to check in with how you are feeling so you can have the best outdoor swimming experience.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                  <fa-icon icon="map-pin"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Site</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">The final part of SOS is Site and this is specific to the
                body of water you are swimming in. Remember that every body of water is different so these questions are
                just a guide, it's up to you to make the right judgement.</dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404"
            fill="none" viewBox="0 0 784 404" aria-hidden="true">
            <defs>
              <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20"
                patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
          </svg>
          <img class="relative mx-auto rounded-lg" width="490"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/content%2Fimages%2Fsosscreens.png?alt=media&token=4a8676c9-e6f4-4a5e-904e-bc8f492f9b4b"
            alt="">
        </div>
      </div>
    </div>
    <!-- FEATURE THREEE -->
    <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="relative">
        <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Safety Beacon</h3>
        <p class="mt-3 text-lg text-gray-500">Let your loved ones know where you are and when you are swimming.
          Integrated with What3Words, an information beacon will be sent out to your "beacon contacts".</p>

        <dl class="mt-10 space-y-10">
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="map-pin"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Where</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Broadcast multiple data points about your location including
              latitude,
              longitude, what3words, name, description and maps.</dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="clock"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">When</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">Include the exact time you are swimming. You can do this at
              the waters edge or ahead of time if you are going off grid.</dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                <fa-icon icon="wind"></fa-icon>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">What</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">What are the conditions like? All your "beacon contacts" will
              be able to monitor real time
              conditions from their unique beacon links. Whether they are a Wild user or NOT.</dd>
          </div>
        </dl>
      </div>

      <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
        <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404"
          fill="none" viewBox="0 0 784 404">
          <defs>
            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20"
              patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
        </svg>
        <img class="relative mx-auto rounded-lg" width="490"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/sosbrochurepage%2Fbeacon.png?alt=media&token=b05fdadf-39a0-4184-b428-02ec96124a16"
          alt="">
      </div>
    </div>
    <!-- FEATURE FOUR -->
    <div class="relative mt-12 sm:mt-16 lg:mt-24">
      <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="lg:col-start-2">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Badges</h3>
          <p class="mt-3 text-lg text-gray-500">Gain badges when you complete the SOS assessment for each type of
            environment ; Lake, Sea or River. Start collecting today!</p>

          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                  <fa-icon icon="tint"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Lake
                </p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">Covering lake specific topics like algae blooms, how the
                size of lakes effects your swim and loads of hazards to watch out for.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                  <fa-icon icon="umbrella-beach"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Sea</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">Learn about tides, rip currents, different wave types, how
                the conditions vary throughout the year and more.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md wild-bg-primary text-white">
                  <fa-icon icon="water"></fa-icon>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">River</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">How does the flow effect your swim? What about pollution?
                Learn all this and more with the SOS river assessment.</dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404"
            fill="none" viewBox="0 0 784 404" aria-hidden="true">
            <defs>
              <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20"
                patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
          </svg>
          <img class="relative mx-auto rounded-lg" width="490"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/sosbrochurepage%2Fsosbadges.png?alt=media&token=037cc36c-cf5e-42be-b486-bf59b4b79131"
            alt="">
        </div>
      </div>
    </div>


  </div>
</div>

<app-appdownloadlinks header="Download the app to start using SOS"></app-appdownloadlinks>
<app-footer></app-footer>