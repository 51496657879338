import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { MySwimSpotsService } from 'src/wild/modules/user/services/myswimspots_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-myswimspots',
  templateUrl: './myswimspots.component.html',
  styleUrls: ['./myswimspots.component.scss']
})
export class MyswimspotsComponent implements OnInit {

  swimspots$: Observable<SwimSpot[]>;
  error: ErrorModel;
  iamgesUrl = environment.imagesUrl;

  constructor(
    private navigation: NavigationService,
    private mySwimSpotsService: MySwimSpotsService) { }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.swimspots$ = null;
    this.swimspots$ = this.mySwimSpotsService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  openSwimSpot(id) {
    this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, id);
  }

}
