import { FeaturesComponentListData } from "../../FeaturesData";

export const commonBusinesFAQs: FeaturesComponentListData[] = [
    {
        title: "How much does wild cost?", subtitle: "Wild is free to use. We only charge a small fee to the booker unless they have a Wild Pro subscription for 50p / month. <br /><br />"
    },
    {
        title: "How do I get started?", subtitle: "There are only three steps. <br /><br /><ol><li>1. Create a free Account</li><li>2. Verify your Business via Stripe</li><li>3. Set up a service (something to sell) & you're ready to take bookings!</li></ol><br /><br />"
    },
    {
        title: "Can I get some help setting up?",
        subtitle: "Of course, we will happily spend a few hours with you and get you set up free of charge."
    },
]