<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200 bg-white">
          <thead class="bg-gray-50">
            <tr>
              <th *ngFor="let header of headers" scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{header.header}}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-content></ng-content>
          </tbody>
        </table>
        <!-- PAGINATION START -->
        <div *ngIf="count"
          class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <!--  MOBILE PAGINATION START -->
          <div class="flex-1 flex justify-between sm:hidden">
            <a *ngIf="showPrev" (click)="prevClick()"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Previous </a>
            <a *ngIf="showNext" (click)="nextClick()"
              class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Next </a>
          </div>
          <!-- MOBILE PAGINATION END  -->
          <!--  DESKTOP PAGINATION START -->
          <div *ngIf="count" class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{{startingFrom}}</span>
                to
                <span class="font-medium">{{endingAt}}</span>
                of
                <span class="font-medium">{{count}}</span>
                results
              </p>
            </div>
            <div>
              <nav *ngIf="pages && pages.length > 1" class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination">
                <a *ngIf="showPrev" (click)="prevClick()"
                  class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
                <a *ngFor="let page of pages" (click)="pageClick(page)" aria-current="page"
                  [ngClass]="{'z-10 bg-blue-50 border-blue-500 text-blue-600 ': page == currentPage,'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': page != currentPage}"
                  class="cursor-pointer relative inline-flex items-center px-4 py-2 border text-sm font-medium">{{page}}</a>
                <a *ngIf="showNext" (click)="nextClick()"
                  class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </nav>
            </div>
          </div>
          <!--  DESKTOP PAGINATION END -->
        </div>
        <!-- PAGINATION END -->
      </div>
    </div>
  </div>
</div>