import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { UnsignedWaiversService } from '../../../services/unsignedwaivers_service';
import { UnsignedWaiversDTO } from '../../../../../../data/dtos/waiver/UnsignedWaivers';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  template: `
   <app-wrapper header="Waivers to Sign" paragraph="Waivers you need to sign to participate in activities will appear here" >
  <div *ngIf="waivers$">
    <ng-container *ngIf="waivers$ | async as waivers; else loadingOrError">
      <div *ngIf="!waivers || waivers.waivers?.length == 0; else waiverstable">
        <app-empty icon="signature" header="No Waivers to Sign"
          description="Nothing to sign at the moment">
        </app-empty>
      </div>
      <ng-template #waiverstable>
        <ul role="list" class="divide-y divide-gray-100">
          <li *ngFor="let waiver of waivers.waivers" class="flex items-center justify-between gap-x-6 py-5">
            <div class="flex min-w-0 gap-x-4">
              <img class="h-14 w-14 flex-none rounded-md bg-gray-50" [src]="waiver.businessImage" alt="">
              <div class="min-w-0 flex-auto">
                <p class="text-sm font-semibold leading-6 text-gray-900">{{waiver.signatureRequiredWhoFor}} - {{waiver.businessName}}</p>
                <p class="mt-1 text-xs leading-5 text-gray-500">{{waiver.listDescription}}</p> 
              </div>
            </div>
            <div class="flex flex-col sm:flex-row">
              <a [href]="waiver.signWaiverUrl" class="rounded-full wild-bg-primary text-white px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-black text-center sm:mr-2">Sign </a>
              <a (click)="copy(waiver.signWaiverUrl)" class="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-2 sm:mt-0 text-center cursor-pointer">Copy</a>
            </div>
          </li>
        </ul>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>
  `
})
export class UnsignedWaiversComponent implements OnInit {

  constructor(
    private notify:NotifyService,
    private unqignedWaiversService: UnsignedWaiversService,) {

  }
  public error: ErrorModel = null;
  waivers$: Observable<UnsignedWaiversDTO[]>;
  loading: boolean = false;

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.waivers$ = this.unqignedWaiversService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  copy(url: string) {
    navigator.clipboard.writeText(url);
    this.notify.notify("Signing Link Copied", "URL copied to clipboard", NotifyType.SUCCESS);
  }

}
