<app-wrapper header="Activities" paragraph="Your upcoming bookings and activities">
    <ng-container
        *ngIf="{activities: activities$ | async,linkedaccounts: linkedaccounts$ | async} as data; else loadingOrError">
        <div class="flex flex-col md:flex-row justify-between items-center">
            <select (change)="selectLinkedAccount($event)"
                class="w-full  block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                <option value="me">
                    Activities for me
                </option>
                <option [selected]="linkedAccountId == account.id" *ngFor="let account of data.linkedaccounts"
                    [value]="account.id">
                    {{account.firstName}}
                    {{account.surname}}
                </option>
            </select>
        </div>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
    <div class="mt-5 border-b border-gray-200">
        <div class="px-0">
            <nav class="-mb-px flex space-x-6">
                <span (click)="selectTab(true)"
                    [ngClass]="{'border-gray-900 text-gray-900': showUpcoming, 'border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300':!showUpcoming}"
                    class="cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Upcoming</span>

                <span (click)="selectTab(false)"
                    [ngClass]="{'border-gray-900 text-gray-900': !showUpcoming, 'border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300': showUpcoming}"
                    class="cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Past</span>
            </nav>
        </div>
    </div>
    <div *ngIf="!activities || activities?.length == 0;">
        <app-empty icon="calendar-alt" header="No Activities" ctatext="Search" (onClick)="search()"
            [showcta]="!isAppUser">
        </app-empty>
    </div>
    <section *ngIf="activities" class="mt-4">
        <ul role="list" class="divide-y divide-gray-200">
            <li *ngFor="let activity of activities">
                <a (click)="openActivity(activity)" class="cursor-pointer block hover:bg-gray-50">
                    <div class="px-4 py-4 flex items-center sm:px-6">
                        <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div class="truncate">
                                <div class="flex text-sm">
                                    <p class="font-medium wild-text-primary truncate">{{activity.name}}</p>
                                    <p class="ml-1 flex-shrink-0 font-normal text-gray-500"> with
                                        {{activity.businessName}}</p>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="flex items-center text-sm text-gray-500">
                                        <!-- Heroicon name: solid/calendar -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <p>
                                            Starts on <time>{{activity.readableLocalStartDateTime}}</time>
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="flex items-center text-sm text-gray-500">
                                        <!-- Heroicon name: solid/calendar -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <p>
                                            Ends on <time>{{activity.readableLocalEndDateTime}}</time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                <div class="flex overflow-hidden -space-x-1">
                                    <img *ngFor="let teamMember of activity.teamMembers"
                                        class="cursor-pointer inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                        [src]="teamMember?.image != null ? imagesUrl + teamMember?.image?.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'">
                                </div>
                            </div>
                        </div>
                        <div class="ml-5 flex-shrink-0 text-xs text-gray-400">
                            <fa-icon icon="chevron-right"></fa-icon>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
        <div *ngIf="!hideshowmore && activities.length >= 5" class="flex items-center justify-center my-3">
            <button (click)="loadMore()" class="wild-btn-light">load more</button>
        </div>
    </section>
    <app-appdownloadlinks header="Check-In Faster with the Wild App"></app-appdownloadlinks>
</app-wrapper>