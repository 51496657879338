import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-listitem',
  template: `
     <li (click)="clicked()">
          <div class="relative group py-6 px-5 flex items-center">
              <a class="-m-1 flex-1 block p-1" [ngClass]="{'cursor-pointer': !notclickable}" >
                  <div *ngIf="!notclickable" class="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"></div>
                  <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <div class="flex-1 flex items-center min-w-0 relative">
                        <span class="flex-shrink-0 inline-block relative">
                            <span *ngIf="!image && !icon"
                                class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                <span
                                    class="text-sm font-medium leading-none text-white">{{getInitials(firstName,surname)}}</span>
                            </span>
                            <span *ngIf="icon"
                            [ngClass]="{'wild-bg-primary': iconhighlight == true,'bg-gray-500': iconhighlight != true}"
                                class="inline-flex items-center justify-center h-10 w-10 rounded-full ">
                                <fa-icon
                                    class="text-sm font-medium leading-none text-white" [icon]="icon"></fa-icon>
                            </span>
                            <span *ngIf="image" class="inline-block relative">
                              <img class="h-10 w-10 rounded-full"  [src]="imagesUrl + image + '_100x100.jpeg?alt=media'"  alt="">
                            </span>
                        </span>
                        <div class="ml-4 truncate">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                {{firstName + ' ' + surname}}</p>
                            <p class="text-sm text-gray-500 truncate">{{subline}}</p>
                            <p class="text-xs font-semibold truncate" *ngIf="sublinetwo" [class]="sublinetwoclass">{{sublinetwo}}</p>
                        </div>
                    </div>
                    <span class="relative z-0 inline-flex shadow-sm rounded-md py-2 ml-14 sm:py-0 sm:ml-0">
                      <button *ngIf="ctaonetext && ctaoneicon && ctaoneclick" (click)="ctaone()" [ngClass]="{'rounded-l-md': ctatwotext || ctathreetext || ctaendtext}" [class]="ctaoneclass" type="button" class="relative inline-flex items-center pr-4 sm:px-2 sm:py-2 sm:border sm:border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        <fa-icon class="hidden sm:inline-block w-5" [icon]="ctaoneicon"></fa-icon>
                        <span class="sm:hidden" >{{ctaonetext}}</span>
                      </button>
                      <button *ngIf="ctatwotext && ctatwoicon && ctatwoclick" (click)="ctatwo()" [class]="ctatwoclass"  type="button" class="-ml-px relative inline-flex items-center pr-4 sm:px-2 sm:py-2 sm:border sm:border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        <fa-icon class="hidden sm:inline-block w-5" [icon]="ctatwoicon"></fa-icon>
                        <span class="sm:hidden" >{{ctatwotext}}</span>
                      </button>
                      <button *ngIf="ctathreetext && ctathreeicon && ctathreeclick" (click)="ctathree()" [class]="ctathreeclass"  type="button" class="-ml-px relative inline-flex items-center pr-4 sm:px-2 sm:py-2 sm:border sm:border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        <fa-icon class="hidden sm:inline-block w-5" [icon]="ctathreeicon"></fa-icon>
                        <span class="sm:hidden" >{{ctathreetext}}</span>
                      </button>
                      <button *ngIf="ctaendtext && ctaendicon && ctaendclick" (click)="ctaend()" [class]="ctaendclass"  type="button" class="-ml-px relative inline-flex items-center pr-4 sm:px-2 sm:py-2 rounded-r-md sm:border sm:border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        <fa-icon class="hidden sm:inline-block w-5" [icon]="ctaendicon"></fa-icon>
                        <span class="sm:hidden" >{{ctaendtext}}</span>
                      </button>
                      <button *ngIf="showSelectBox" [ngClass]="{'wild-bg-primary': selected == true,'bg-gray-200':selected != true}"  type="button" class=" relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" role="switch" aria-checked="false">
                        <span aria-hidden="true" [ngClass]="{'translate-x-5': selected == true,'translate-x-0':selected != true}"  class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
                      </button>
                    </span>
                  </div>
              </a>
          </div>
      </li>
  `,
})
export class ListItemComponent {
  @Input() showSelectBox: boolean;
  @Input() selected: boolean;
  @Input() image: string;
  @Input() icon: string;
  @Input() iconhighlight: boolean;
  @Input() firstName: string;
  @Input() surname: string;
  @Input() subline: string;
  @Input() ctaonetext: string;
  @Input() ctaoneicon: string;
  @Input() ctaoneclass: string;
  @Output() ctaoneclick = new EventEmitter<any>()
  @Input() ctatwotext: string;
  @Input() ctatwoicon: string;
  @Input() ctatwoclass: string;
  @Output() ctatwoclick = new EventEmitter<any>()
  @Input() ctathreetext: string;
  @Input() ctathreeicon: string;
  @Input() ctathreeclass: string;
  @Output() ctathreeclick = new EventEmitter<any>()
  @Input() ctaendtext: string;
  @Input() ctaendicon: string;
  @Input() ctaendclass: string;
  @Input() notclickable: boolean;
  @Output() ctaendclick = new EventEmitter<any>()
  @Output() onClick = new EventEmitter<any>()
  @Input() object: any;
  imagesUrl = environment.imagesUrl;
  @Input() sublinetwo: string;
  @Input() sublinetwoclass: string;

  getInitials(firstName: string, surname: string) {
    var initials = "";
    initials = firstName?.substring(0, 1) ?? "";
    initials = initials + surname?.substring(0, 1) ?? "";
    return initials.toUpperCase();
  }

  clicked() {
    this.onClick.emit(this.object);
  }
  ctaone() {
    this.ctaoneclick.emit(this.object);
  }
  ctatwo() {
    this.ctatwoclick.emit(this.object);
  }
  ctathree() {
    this.ctathreeclick.emit(this.object);
  }
  ctaend() {
    this.ctaendclick.emit(this.object);
  }

}
