
<div class="mx-auto max-w-2xl lg:mx-0">
<h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Get Ready for Check-In with the Free Wild App</h2>
<p class="mt-6 text-lg leading-8 text-gray-600">Follow these 3 easy steps to access your QR code for fast check-in and easier bookings:</p>
</div>
<div class="hidden sm:block sm:flex:col justify-start mt-8">
    <p class="text-md text-gray-500 font-semibold">1. Scan this QR code with your smart phone camera and download the app:</p>
    <div class="flex items-center">
        <p class="animated bounce inline-block mt-3 text-6xl">🤳</p>
        <div class="w-12">&nbsp;</div>
        <img class="h-32 w-32 mt-4"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/qrcodes%2Fowj.png?alt=media&token=bb2bd341-e5bc-4387-8f08-6f595d185c50" />
        
    </div>
</div>
<div class="sm:hidden sm:flex:col justify-start mt-8">
    <p class="text-md text-gray-500 font-semibold">1. Download the app</p>
    <div class="flex mt-6">
        <a class="mr-5" href="https://apps.apple.com/us/app/wild-open-water-swimming/id1576881664"
        target="_blank">
        <img class="h-11 mt-4 sm:mt-0 sm:mr-3"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fappstore3.png?alt=media&token=7781de9e-0c82-4005-b1a3-ab880f942057" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.swimwild.wild" target="_blank">
        <img class="h-11 mt-4 sm:mt-0"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fplaystore3.png?alt=media&token=73ed962f-39d0-4146-a750-9dc80ca4183b" />
        </a>
    </div>
</div>
<div class=" mt-8">
    <p class="text-md text-gray-500 font-semibold">2. Login</p>
    <p class="mt-6 text-md">Use the the same login method.</p>
    <p class="mt-6 text-sm italic">For example, if you use email and password to access this website, don't choose Apple Sign In on the app, stick with email and password.</p>
</div>
<div class="mt-8">
    <p class="text-md text-gray-500 font-semibold">3. Click on the bookings tab</p>
    <img class="h-24 mt-4"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/clickbookingsv2.png?alt=media&token=f5520e89-d930-45b4-b101-fe7af3348eff" />
    <p class="mt-6 text-md">You will be able to access your QR codes for fast check-in and more from the Bookings tab.</p>
</div>
<p class="mt-6 text-lg leading-8 text-gray-600">Enjoy your session 🌊</p>