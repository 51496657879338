
import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AlertModel } from '../../../../../../data/models/alert_model';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { SwimspotsdataService } from '../../../services/swimspotdata_service/swimspotsdata.service';
import { AppPromptService } from 'src/shared/services/appprompt/appprompt_service';
import { environment } from 'src/environments/environment';


@Component({
  selector: '[app-alerts]',
  template: `
  <div *ngIf="alerts$">
      <ng-container *ngIf="alerts$ | async as alerts; else loadingOrError">
      <div *ngIf="!alerts || alerts.length == 0; else alertslist">
        <app-empty (click)="prompt()" ctatext="Raise Alert"  icon="bell" header="No alerts yet" [showcta]="true"></app-empty>    
      </div>
      <ng-template #alertslist>
    <div class="bg-white">
    <div class="pt-4 flex items-center justify-end">
            <button (click)="prompt()" type="button" class="wild-btn-primary">
              Raise Alert
              <fa-icon class="ml-3" icon="bell"></fa-icon>
            </button>
          </div>
  <div class=" mx-auto py-16 sm:py-24 lg:max-w-7xl lg:py-32 lg:grid lg:grid-cols-12 lg:gap-x-8">
    <div class="lg:col-span-4">
      <h2 class="text-2xl font-extrabold tracking-tight text-gray-900">Alerts</h2>
      <div class="mt-4">
        <h3 class="text-lg font-medium text-gray-900">Know something?</h3>
        <p class="mt-1 text-sm text-gray-600">Help swimmers by raising an alert</p>
        <a (click)="prompt()" class="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full">Raise Alert</a>
      </div>
    </div>
    <div class="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-7">
      <div class="flow-root">
        <div class="-my-12 divide-y divide-gray-200">
          <div *ngFor="let alert of alerts" class="py-12">
            <div class="flex items-center cursor-pointer" (click)="prompt()">
              <img [src]="alert.profilePicture == null ? 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d' :
              imagesUrl + alert.profilePicture.image + '_100x100.jpeg?alt=media' " alt="Profile" class="h-12 w-12 rounded-full">              <div class="ml-4">
                <h4 class="text-sm font-bold text-gray-900">{{alert?.username ?? 'processing...'}}</h4>
              </div>
            </div>
            <div class="mt-4 space-y-6 text-base italic text-gray-600">
              <p>{{alert?.comment ?? "No comment"}}</p>
            </div>
            <div class="mt-2" *ngIf="alert.tags">
              <span class="wild-badge-primary" *ngFor="let tag of alert.tags">{{tag}}</span>
            </div>
            <app-socialbar></app-socialbar>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
          </app-retry>
      </ng-container>
      <ng-template #loading>
          <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>

  `,
})
export class AlertsComponent implements OnInit {
  @Input() swimspot: SwimSpot;
  alerts$: Observable<AlertModel[]>;
  error: ErrorModel;
  imagesUrl = environment.imagesUrl;
  constructor(
    private swimspotService: SwimspotsdataService,
    private promptService: AppPromptService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  prompt() {
    this.promptService.prompt();
  }


  load() {
    this.alerts$ = null;
    this.alerts$ = this.swimspotService.alerts(this.swimspot.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }
}
