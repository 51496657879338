import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-inlineform',
  templateUrl: './inlineform.component.html',
  styleUrls: ['./inlineform.component.scss']
})
export class InlineFormComponent implements OnInit {

  @Input() header: string | undefined;
  @Input() description: string | undefined;
  @Input() form!: UntypedFormGroup;
  @Input() ctaText: String = "Save";
  @Output() onSubmit = new EventEmitter<any>();
  @Input() loading: boolean;
  @Input() inputname!: string;
  @Input() inputvalue!: any;
  @Input() inputdescription!: string;
  @Input() inputtype: string = "text";
  @Input() placeholder: string = "";

  constructor() { }

  ngOnInit(): void {
    if (this.inputvalue)
      this.form.controls[this.inputname].setValue(this.inputvalue);
  }

  submit(data: any) {
    if (!this.loading)
      this.onSubmit.emit(data);
  }

}
