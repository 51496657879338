import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { TeamMemberPublicDTO } from '../../../../data/dtos/serviceAvailability/teamMemberPublicDTO';
import { GetServiceAvailabilityForDayDTO } from '../../../../data/dtos/serviceAvailabilityForDay/GetServiceAvailabilityForDayDTO';
import { ServiceAvailabilityForDayDTO } from '../../../../data/dtos/serviceAvailabilityForDay/ServiceAvailabilityForDayDTO';
import { ServiceAvailabilityForDaySlotDTO } from '../../../../data/dtos/serviceAvailabilityForDay/ServiceAvailabilityForDaySlotDTO';
import { ServiceAndPricesDTO } from '../../../../data/dtos/services/ServiceAndPricesDTO';
import { ErrorModel } from '../../../../data/models/error_model';
import { AvailabilityPublicService } from '../../../services/availability_service';

@Component({
  selector: "app-serviceavailability",
  template: `
  <div style="min-height:250px">
  <ng-container *ngIf="availability$ | async as availability; else loadingOrError">
        <div *ngIf="!availability || availability?.slots.length == 0 ; else availabilityLoaded">
            <app-empty icon="calendar-alt" header="Fully Booked" description="Please try another date">
                </app-empty>
        </div>
        <ng-template #availabilityLoaded>
            <div class="grid grid-cols-4 gap-x-4 gap-y-4 mt-4">
              <div (click)="selectSlot(slot)" [ngClass]="{'bg-green-500 text-white':slot == selectedSlot}" class="flex items-center justify-center py-3 hover:bg-green-400 border border-gray-100 hover:text-white cursor-pointer"*ngFor="let slot of availability.slots">
                  <span>{{slot.description}}</span>
              </div>
            </div>
        </ng-template>
      </ng-container>  
      <ng-template #loadingOrError>
          <ng-container *ngIf="error; else loading">
              <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                  (onRetry)="load()">
              </app-retry>
          </ng-container>
          <ng-template #loading>
              <app-loader [disablescreenheight]="true"></app-loader>
          </ng-template>
      </ng-template>  
    </div>
  `
})
export class ServiceAvailabilityComponent implements OnInit {

  @Input() newDate: Observable<string>;
  @Input() service: ServiceAndPricesDTO;
  @Input() teamMember: TeamMemberPublicDTO;
  @Output() slotSelected = new EventEmitter<ServiceAvailabilityForDaySlotDTO>();
  selectedDate: string;
  selectedSlot: ServiceAvailabilityForDaySlotDTO;
  availability$: Observable<ServiceAvailabilityForDayDTO>;

  private dateSub: Subscription;
  error: ErrorModel;

  constructor(
    private availabilityService: AvailabilityPublicService,
  ) { }


  ngOnInit(): void {
    this.dateSub = this.newDate.subscribe((date) => this.loadAvailability(date));
  }

  ngOnDestroy() {
    this.dateSub.unsubscribe();
  }

  loadAvailability(isoDate: string) {
    this.selectedDate = isoDate;
    this.load();
  }

  selectSlot(slot: ServiceAvailabilityForDaySlotDTO) {
    this.selectedSlot = slot;
    this.slotSelected.emit(slot);
  }

  load() {
    var getServiceAvailabilityForDay: GetServiceAvailabilityForDayDTO = {
      date: this.selectedDate,
      businessId: this.service.businessId,
      serviceId: this.service.serviceId,
      teamMemberId: this.teamMember.id
    }
    this.availability$ = null;
    this.availability$ = this.availabilityService.getServiceAvailabilityForDay(getServiceAvailabilityForDay).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
