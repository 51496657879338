import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TabService } from 'src/shared/services/tab_service/tab_service';
import { TabModel } from './tab_model';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, OnDestroy {

  @Input() tabs:TabModel[];
  @Input() tabsId:string;
  @Output() tabChanged = new EventEmitter<string>();
  activetabname:string;

  constructor(
    private tabsService:TabService
  ) { }


  ngOnDestroy(): void {
    this.tabsService.unregisterTabs(this.tabsId);
  }

  ngOnInit(): void {
    this.tabsService.registerTabsAndEmitter(this.tabsId, this.tabs, this.tabChanged);
    this.setActiveTabName();
  }

  get registeredTabs() :TabModel[]{
    return this.tabsService.getTabs(this.tabsId);
  }

  onChange(event:any, tab:string){
    this.updateActiveTab(tab);
  }

  onClick(tab:TabModel){
    this.updateActiveTab(tab.name);
  }

  updateActiveTab(name:string){
    var activeTab = this.tabs.find(t => t.active == true);
    activeTab.active = false;
    var selectedTab = this.tabs.find(t => t.name == name);
    selectedTab.active = true;
    this.tabChanged.emit(selectedTab.id);
  }

  setActiveTabName(){
    var activeTab = this.tabs.find(t => t.active == true);
    this.activetabname = activeTab.name;
  }

}
