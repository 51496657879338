import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BeaufortScale } from '../../../../../../data/enums/beaufort_scale';
import { TideType } from '../../../../../../data/enums/tide_type';
import { WeatherClassification } from '../../../../../../data/enums/weather_classification';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';


@Component({
  selector: '[app-tidetable]',
  template: `
   <div class="mt-4" *ngIf="!swimspot.tideChunks">
    <app-empty icon="water" header="No tides" description="Tides are available on coastal locations within 24 hours of review"></app-empty>    
  </div>
  <div class="my-4" *ngFor="let chunk of swimspot.tideChunks">
    <div class="wild-bg-primary w-full p-4 ">
        <h2 class="text-white">{{chunk.date | date : 'EEEE, MMMM d'}}</h2>
    </div>
    <div class="flex w-full bg-gray-500 text-white py-1">
      <div class="w-1/3 text-center ">
          <p>Hi/Lo</p>
      </div>
      <div class="w-1/3 text-center border-l-2 border-white">
          <p>Time</p>
      </div>
      <div class="w-1/3 text-center border-l-2 border-white">
          <p>Height</p>
      </div>
    </div>
    <div class="py-4 flex items-center justify-around" [ngClass]="{'bg-blue-100':tide.type == TideType.HIGH}" *ngFor="let tide of chunk.data">
      <div class="w-1/3 text-center" *ngIf="tide.type == TideType.HIGH">High</div>
      <div class="w-1/3 text-center" *ngIf="tide.type == TideType.LOW">Low</div>
      <div class="w-1/3 text-center">{{tide.datetime | date: 'h:mm a'}}</div>
      <div class="w-1/3 text-center">{{(tide.height | number: '1.2-2') + 'm'}}</div>
    </div>
  </div>
  `,
})
export class TideTableComponent implements OnInit {
  @Input() swimspot: SwimSpot;
  BeaufortScale = BeaufortScale;
  WeatherClassification = WeatherClassification;
  TideType = TideType;

  constructor() { }

  ngOnInit(): void {
  }

}
