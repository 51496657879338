<ng-container *ngIf="activity$ | async as loadedactivity; else loadingOrError">
    <div *ngIf="!loadedactivity; else loaded">
        <app-empty icon="calendar-alt" header="Activity not found" description=""></app-empty>
    </div>
    <ng-template #loaded>
        <div class="divide-y divide-gray-200">
            <app-panelheader [image]="activity?.activity?.image != null ? imagesUrl + 
            activity?.activity?.image .image + 
            '_640x640.jpeg?alt=media' : null" [header]="activity?.activity?.name" [subheading]="readableDate"
                ctaOneText="Edit Dates" (ctaOneClick)="edit()"  ctaTwoText="Message Contacts" (ctaTwoClick)="message()" [showrefreshbutton]="true" (onrefresh)="load()">
            </app-panelheader>
            <div>
                <div class="flex items-center px-6 py-2">
                    <div class="w-4 h-4 rounded-full bg-green-400 mr-2"></div>
                    <span class="mr-4 text-xs">{{ activity?.stats?.attendedPercentage | percent }}<span class="hidden sm:inline-block">&nbsp;Attended</span></span>
                    <div class="w-4 h-4 rounded-full bg-red-400 mr-2"></div>
                    <span class="mr-4 text-xs">{{ activity?.stats?.didNotAttendPercentage | percent }}<span class="hidden sm:inline-block">&nbsp;DNA</span></span>
                    <div class="w-4 h-4 rounded-full bg-yellow-400 mr-2"></div>
                    <span class="text-xs">{{ activity?.stats?.noStatusPercentage | percent }}<span class="hidden sm:inline-block">&nbsp;No Status</span></span>
                    <span (click)="showAttendanceCard = !showAttendanceCard" class="ml-auto cursor-pointer hover:underline text-xs text-blue-500">{{!showAttendanceCard ? 'show': 'hide'}} chart</span>
                  </div>
                  <div *ngIf="showAttendanceCard" class="flex my-6 items-center justify-center">
                    <canvas baseChart
                    [type]="'pie'"
                    [datasets]="pieChartDatasets"
                    [labels]="pieChartLabels"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend">
                    </canvas>
                </div>
            </div>
            <div class="h-full flex flex-col bg-white overflow-y-scroll">
                <div class="mt-5 border-b border-gray-200">
                    <div class="px-6">
                        <nav class="-mb-px flex flex-col sm:flex-row sm:space-x-6">
                            <!-- Current: "border-blue-500 text-blue-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                            <span (click)="page = AppointmentPages.DETAILS"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == AppointmentPages.DETAILS, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != AppointmentPages.DETAILS}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1  font-medium text-sm">Details</span>

                            <span (click)="page = AppointmentPages.SWIMMERS"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == AppointmentPages.SWIMMERS, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != AppointmentPages.SWIMMERS}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 font-medium text-sm">Contacts
                                ({{activity?.activity?.contacts?.length}})</span>

                            <span (click)="page = AppointmentPages.TEAM"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == AppointmentPages.TEAM, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != AppointmentPages.TEAM}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 font-medium text-sm">Team
                                ({{activity?.activity?.team?.length}})</span>
                            <span (click)="page = AppointmentPages.NOTES"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == AppointmentPages.NOTES, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != AppointmentPages.NOTES}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 font-medium text-sm">Notes
                                ({{activity?.activity?.noteCount ?? 0}})</span>
                            <span (click)="page = AppointmentPages.MESSAGES"
                                [ngClass]="{'sm:border-blue-500 sm:border-b-2 text-blue-600': page == AppointmentPages.MESSAGES, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != AppointmentPages.MESSAGES}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 font-medium text-sm">Messages
                                ({{activity?.activity?.messagesCount ?? 0}})</span>
                        </nav>
                    </div>
                </div>
                <!-- DETAILS  -->
                <div *ngIf="page == AppointmentPages.DETAILS">
                    <app-panelrow description="Name" [hidebutton]="false" (onClick)="editName()">
                        {{activity?.activity?.name}}
                    </app-panelrow>
                    <app-panelrow description="Description" [hidebutton]="false" (onClick)="editDescription()">
                        <small *ngIf="!activity.activity.description" class="italic text-xs">no description</small>
                        <span *ngIf="activity.activity.description">{{activity?.activity?.description}}</span>
                    </app-panelrow>
                    <app-panelrow description="Starts At" [hidebutton]="true">
                        {{readableDate}}
                    </app-panelrow>
                    <app-panelrow description="Ends At" [hidebutton]="true">
                        {{readableEndDate}}
                    </app-panelrow>
                    <!-- LINKED SERVICE DETAILS START -->
                    <div *ngIf="service">
                        <app-panelrow *ngIf="service" description="Linked Service" [hidebutton]="false"
                            buttontext="open" (onClick)="openService()">
                            <span>
                                {{service.name}}
                            </span>
                        </app-panelrow>
                        <app-panelrow *ngIf="service.type == ServiceType.GROUP" description="Bookable"
                            [hidebutton]="false" (onClick)="changeBookableStatus()">
                            <span *ngIf="activity.activity.bookable == undefined">
                                <small class="italic text-xs text-red-500">not set</small>
                            </span>
                            <span class="text-yellow-500" *ngIf="activity.activity.bookable == false">
                                Not available for bookings
                            </span>
                            <span class="text-green-500" *ngIf="activity.activity.bookable == true">
                                Available for bookings <br /><span class="text-xs text-gray-300">(unless fully booked or
                                    elapsed)</span>
                            </span>
                        </app-panelrow>
                        <app-panelrow (onClick)="editCapacity()" *ngIf="service.type == ServiceType.GROUP"
                            description="Capacity" [hidebutton]="false">
                            <span *ngIf="activity.activity.capacity">
                                {{activity?.activity?.contacts?.length ?? 0}} / {{activity.activity.capacity}}
                                <span *ngIf="(activity.activity.contacts?.length ?? 0) == activity.capacity"
                                    class="text-green-500">
                                    fully booked</span>
                                <span *ngIf="(activity.activity.contacts?.length ?? 0) > activity.capacity"
                                    class="text-red-500">
                                    overbooked</span>
                            </span>
                            <span *ngIf="!activity.activity.capacity">
                                <small class="italic text-xs text-red-500">not set</small>
                            </span>
                        </app-panelrow>
                        <app-panelrow *ngIf="!activity.activity.locationoverride" description="Meeting Point"
                            [hidebutton]="false" buttontext="override" (onClick)="editLocation(service?.location)">
                            <span *ngIf="service?.location?.type == BusinessServiceLocationType.PHYSICAL">
                                <span>{{service?.location?.name}}</span>
                            </span>
                        </app-panelrow>
                        <app-panelrow *ngIf="activity.activity.locationoverride" description="Meeting Point" [hidebutton]="false"
                            buttontext="edit" (onClick)="editLocation(activity.locationoverride)">
                            <span *ngIf="activity?.activity?.locationoverride?.type == BusinessServiceLocationType.PHYSICAL">
                                <span>{{activity?.activity?.locationoverride?.name}}</span>
                                <br />
                                <span class="hover:underline cursor-pointer text-xs"
                                    (click)="removeOverrideOnLocation()">
                                    remove override</span>
                            </span>
                        </app-panelrow>
                        <!-- LINKED SERVICE DETAILS END -->
                        <app-panelrow description="Bookings in progress" [hidebutton]="true">
                            {{activity.activity.usersInCartCount ?? 0}}
                        </app-panelrow>
                    </div>
                    <app-panelrow description="Status" [hidebutton]="true">
                        <span *ngIf="activity.activity.status == ActivityStatus.LIVE">Live</span>
                        <span class="text-red-500" *ngIf="activity.activity.status == ActivityStatus.DELETED">Deleted</span>
                    </app-panelrow>
                    <div *ngIf="activity.activity.status != ActivityStatus.DELETED" class="mt-5 pb-6">
                        <span (click)="deleteActivity()"
                            class="text-red-500 hover:text-red-600 hover:underline cursor-pointer px-6">delete
                            activity</span>
                    </div>
                </div>
                <!-- CONTACTS  -->
                <div *ngIf="page == AppointmentPages.SWIMMERS">
                    <div class="flex items-center justify-end px-5 pt-5">
                        <button *ngIf="activity?.activity?.contacts?.length > 0" class="wild-btn-light mr-2"
                            (click)="export()">Export</button>
                        <button class="wild-btn-light" (click)="addContact()">Add</button>
                    </div>
                    <div *ngIf="!activity?.activity?.contacts || activity?.activity?.contacts?.length == 0">
                        <app-empty icon="user-alt" header="No Contacts on Activity" [showcta]='true'
                            ctatext="Add Contact" (onClick)="addContact()"
                            description="Contacts added to activities will be notified">
                        </app-empty>
                    </div>
                    <ul *ngIf="activity?.activity?.contacts?.length > 0" role="list"
                        class="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        <app-appointmentdetailscontactlist [id]="activity.activity.id"></app-appointmentdetailscontactlist>
                    </ul>
                </div>
                <!-- TEAM  -->
                <div *ngIf="page == AppointmentPages.TEAM">
                    <div class="flex items-center justify-end pt-5 pr-5">
                        <button class="wild-btn-light" (click)="addTeamMember()">Add</button>
                    </div>
                    <div *ngIf="!activity?.activity?.team || activity?.activity?.team?.length == 0">
                        <app-empty icon="user-alt" header="No Team Members on Activity" [showcta]='true'
                            ctatext="Add Team Member" (onClick)="addTeamMember()" description="">
                        </app-empty>
                    </div>
                    <ul *ngIf="activity?.activity?.team?.length > 0" role="list"
                        class="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        <app-appointmentteamlist [id]="activity.activity.id"></app-appointmentteamlist>
                    </ul>
                </div>
                <!-- NOTES  -->
                <div *ngIf="page == AppointmentPages.NOTES">
                    <app-notes (count)="noteCountUpdated($event,activity)" [sourceDocumentId]="activity.activity.id"
                        [sourceDocumentType]="SourceDocumentType.BOOKEDACTIVITY"></app-notes>
                </div>
                <!-- MESSAGES  -->
                <div *ngIf="page == AppointmentPages.MESSAGES">
                    <app-sentmessageslist [sourceDocumentId]="activity.activity.id" [sourceDocumentType]="SourceDocumentType.BOOKEDACTIVITY"></app-sentmessageslist>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <div class="p-6">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</ng-template>