
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetServiceDaysDTO } from '../../data/dtos/serviceAvailability/GetServiceDaysDTO';
import { AvailabilityForServiceDTO } from '../../data/dtos/serviceAvailability/AvailabilityForServiceDTO';
import { GetServiceAvailabilityForDayDTO } from '../../data/dtos/serviceAvailabilityForDay/GetServiceAvailabilityForDayDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityPublicService {

    constructor(
        private http: HttpService
    ) { }

    getServiceDays(dto: GetServiceDaysDTO): Observable<AvailabilityForServiceDTO> {
        return this.http.get(`${environment.api}/user/availability/getServiceDays?serviceId=${dto.serviceId}&businessId=${dto.businessId}&teamMemberId=${dto.teamMemberId}`);
    }

    getServiceAvailabilityForDay(dto: GetServiceAvailabilityForDayDTO): Observable<any> {
        return this.http.get(`${environment.api}/user/availability/getServiceAvailabilityForDay?serviceId=${dto.serviceId}&businessId=${dto.businessId}&teamMemberId=${dto.teamMemberId}&date=${dto.date}`);
    }
}
