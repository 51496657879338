<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }" class="isolate mb-22">
  <div class="absolute inset-x-0  -z-10 transform-gpu overflow-hidden blur-3xl ">
    <svg
      class="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
      viewBox="0 0 1155 678" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)" fill-opacity=".3"
        d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
      <defs>
        <linearGradient id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533" x1="1155.49" x2="-78.208" y1=".177" y2="474.645"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#ebf3ff"></stop>
          <stop offset="1" stop-color="#c0d9ff"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <main>
  <div class="relative px-6 lg:px-8 mb-10">
    <div class="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
      <div>
        <div>
        <div class="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl relative overflow-hidden">
            <app-rotatingtext [prefix]="data.title"
              [strings]="rotatingText"></app-rotatingtext>
          </div> 
          <h1 class="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">{{data.subtitle}}
          </h1>
          <p class="mt-6 text-lg leading-8 text-gray-600 sm:text-center">{{data.paragraph}}</p>
          <app-ctas [data]="data"></app-ctas>
        </div>
      </div>
    </div>
  </div>
</main>
</div>