<app-wrapper header="Select Business">
    <app-cardlistwrapper>
        <div *ngFor="let business of businesses">
            <app-cardlistitem [pills]="business.roles" ctaoneicon="folder-open" [id]="business.business.id"
                [header]="business.business.name" description="" ctaonetext="Open" (onCtaOneClick)="open($event)"
                [image]="business.business.uploadedprofilepic != null && business.business.uploadedprofilepic != '' ? imagesUrl + business.business.uploadedprofilepic.image + '_640x640.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'">
            </app-cardlistitem>
            <!-- ctatwotext="Delete" ctatwoicon="trash" (onCtaTwoClick)="deleteBusiness($event)" -->
        </div>
        <app-cardlistnew icon="plus" (onClick)="new()"></app-cardlistnew>
    </app-cardlistwrapper>
</app-wrapper>