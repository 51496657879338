import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-publiclyaccessibleactivity',
  templateUrl: './publiclyaccessibleactivity.component.html'
})
export class PubliclyAcccessiblyActivityDetailsComponent implements OnInit {

  activityId: string;
  userId: string;

  constructor(
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((params: Params) => {
      this.activityId = params.activityId;
      this.userId = params.userId;
    });
  }

}
