import { WildNavigationType } from "../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../FeaturesData";
import { injectSharedComponent } from "../helpers/inject_shared_component";
import { businessCTAs } from "../shared/ctas/business_ctas";
import { usecases } from "../shared/usecases";
import { bookingExperienceListData } from "./bookingexperience/booking_experience_data_listdata";
import { businessappListData } from "./businessapp/business_app_data_listdata";
import { communicationsListData } from "./communications/communications_data_listdata";
import { crmListData } from "./customers/customer_management_listdata";
import { ordersFinanceListData } from "./finance/orders_finances_data_listdata";
import { realTimeSafetyListData } from "./realtimesafety/realtimesafety_data_listdata";
import { reportingListData } from "./reporting/reporting_data_listdata";
import { schedulingListDataShared } from "./scheduling/scheduling_data_listdata";
import { teamListDataShared } from "./team/team_data_listdata";
import { waiversListData } from "./waivers/waivers_data_listdata";

export const allFeaturesData: FeaturesData = {
  seoTitle: "Wild Features",
  seoDescription: "Real Time Water Safety System to check people in and out of the Water quickly access emergency information",
  seoImage: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Frealtime-dashboard-min.png?alt=media&token=58aaea6c-303a-4a26-9b74-c6fcc73f9100",
  seoKeyWords: "",
  page: WildNavigationType.ALLFEATURES,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: "Wild Features",
      subtitle: "Here's Everything",
      paragraph: "Explore how Wild can support your business.",
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "How Wild Can Help You",
      subtitle: "Core Functionality",
      limit: 9,
      paragraph: "",
      data: [
        realTimeSafetyListData,
        bookingExperienceListData,
        waiversListData,
        communicationsListData,
        schedulingListDataShared,
        teamListDataShared,
        businessappListData,
        ordersFinanceListData,
        reportingListData,
        crmListData
      ]
    },
    // {
    //   colour: FeatureColour.WHITE,
    //   type: FeatureComponentType.TEXTANDCIRCLES,
    //   subtitle: "All Our Features",
    //   data: [
    //     {
    //       image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fmarketing.png?alt=media&token=1f7dad6f-369c-4602-86b3-ade4f84119a8",
    //       title: "Free Marketing", subtitle: "Connect to thousands of customers"
    //     },
    //     {
    //       image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fsurf-school.jpg?alt=media&token=66b18208-5fef-43e0-981d-b97a658c7db1",
    //       title: "Easy Scheduling", subtitle: "Family bookings, groups, Recurring & 121's"
    //     },
    //     {
    //       image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fcrm.png?alt=media&token=3732a3c9-f129-4206-b7fa-ccd123954326",
    //       title: "CRM", subtitle: "Customer Management & Safety Profiles"
    //     },
    //     {
    //       image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fsafety.png?alt=media&token=fe8a6f1d-1b8f-44c6-93a6-ae6427601bfe",
    //       title: "Safety", subtitle: "Free real-time water safety system"
    //     },
    //     {
    //       image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwhyyoulllovewild%2Fbusinessapp.png?alt=media&token=d1b9e9f9-0b75-432c-b370-27d9abf5d3a0",
    //       title: "Business App", subtitle: "Access bookings & cusomters on the go"
    //     },
    //   ],
    //   ctas: businessCTAs,
    // },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),
  ]
}

