import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-appdownloadlinks',
  template: `
  <div class="mx-auto max-w-5xl">    
    <h1 *ngIf="header" class="text-center text-xl md:text-2xl font-semibold mt-8 px-4">{{header}}</h1>
    <div class="hidden sm:block sm:flex:col justify-center items-center mt-3 text-center">
      <p class="text-md text-gray-500 font-semibold">Scan with your smart phone camera to download</p>
      <img class="h-32 w-32 mx-auto mt-4"
        src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/qrcodes%2Fowj.png?alt=media&token=bb2bd341-e5bc-4387-8f08-6f595d185c50" />
      <p class="text-md text-gray-500 font-extralight mt-3">Available on iOS & Android</p>
    </div>
    <div class=" sm:hidden flex  justify-evenly sm:justify-center items-center flex-row  my-2 sm:my-4 md:my-8">
      <a href="https://apps.apple.com/us/app/wild-open-water-swimming/id1576881664" target="_blank">
        <img class="h-10 mt-4 sm:mt-0 sm:mr-3"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fappstore3.png?alt=media&token=7781de9e-0c82-4005-b1a3-ab880f942057" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.swimwild.wild" target="_blank">
        <img class="h-10 mt-4 sm:mt-0"
          src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fplaystore3.png?alt=media&token=73ed962f-39d0-4146-a750-9dc80ca4183b" />
      </a>
    </div> 
  </div>
  `,
})
export class AppDownloadLinksComponent {
  @Input() header: string;
}
