import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UpdateActivityDTO } from '../../../../../../../data/dtos/business/activities/UpdateActivityDTO';
import { TopUpDTO } from '../../../../../../../data/dtos/business/configuration/TopUpDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class FinanceDashboardService {

    constructor(
        private http: HttpService
    ) { }

    getLink(): Observable<any> {
        return this.http.get(`${environment.api}/business/finance/dashboard`);
    }

    async topup(dto: TopUpDTO) {
        await this.http.post(`${environment.api}/business/finance/topup`, dto).toPromise();
    }

}
