import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../data/models/error_model';
import { MapsService } from 'src/shared/services/maps/maps_service';
@Component({
  selector: "app-pointonmap",
  template: `
     <div *ngIf="mapsLoaded | async" class="w-full shadow-lg rounded-lg bg-blue-500 overflow-hidden mt-3"
        style="height:300px">
        <google-map (mapClick)="updateMarker($event)" height="100%" width="100%" [options]="options">
            <map-marker (mapDragend)="updateMarker($event)" *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition" [options]="markerOptions"></map-marker>
        </google-map>
    </div>
  `
})
export class PointOnMapComponent implements OnInit {

  @Input() latitude: string;
  @Input() longitude: string;
  error: ErrorModel;
  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptions!: google.maps.MarkerOptions;
  markerPositions!: google.maps.LatLngLiteral[];

  constructor(
    private maps: MapsService) {
    this.mapsLoaded = this.maps.loadMaps().pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  ngOnInit(): void {
    this.updateLatLongOnMap(this.latitude, this.longitude)
  }

  updateLatLongOnMap(latitude: string, longitude: string): void {
    this.options = {
      center: {
        lat: Number.parseFloat(latitude),
        lng: Number.parseFloat(longitude)
      },
      mapTypeId: 'satellite',
      // roadmap displays the default road map view. This is the default map type.
      // satellite displays Google Earth satellite images.
      // hybrid displays a mixture of normal and satellite views.
      // terrain displays a physical map based on terrain information.
      zoom: 17,
    };
    this.markerPositions = [{
      lat: Number.parseFloat(latitude),
      lng: Number.parseFloat(longitude)
    }];
  }

}
