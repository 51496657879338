import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BookingDayStatus } from 'src/booking/enums/booking_day_status';
import { BookingDay } from 'src/booking/models/booking_day';
import { BookingMonth } from 'src/booking/models/booking_month';
import { BookingDateService } from 'src/booking/services/booking_date_service';
import { ServicesPublicService } from 'src/booking/services/services_public_service';
import { AddAttendeeToServiceDTO } from '../../../../data/dtos/booking/AddAttendeeToServiceDTO';
import { AddToCartDTO } from '../../../../data/dtos/booking/AddToCartDTO';
import { RemoveAllAttendeesFromServiceDTO } from '../../../../data/dtos/booking/RemoveAllAttendeesFromServiceDTO';
import { RemoveAttendeeFromServiceDTO } from '../../../../data/dtos/booking/RemoveAttendeeFromServiceDTO';
import { UserOrderLineServiceSummaryDTO } from '../../../../data/dtos/booking/UserOrderLineServiceSummaryDTO';
import { ScheduledServiceAndPricesDTO } from '../../../../data/dtos/services/ScheduledServiceAndPriceDTO';
import { ScheduledServiceAndPricesWrapperDTO } from '../../../../data/dtos/services/ScheduledServiceAndPriceWrapperDTO';
import { ServiceAndPricesDTO } from '../../../../data/dtos/services/ServiceAndPricesDTO';
import { ServicePriceDTO } from '../../../../data/dtos/services/ServicePriceDTO';
import { ServiceScheduleForCourseDTO } from '../../../../data/dtos/services/ServiceScheduleForCourseDTO';
import { ErrorModel } from '../../../../data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BookingService } from '../../../services/booking_service';
import { ContinueShoppingComponent } from './continueshopping.component';

@Component({
  templateUrl: './bookwrapperscheduled.component.html',
  styleUrls: ['./bookwrapperscheduled.component.scss'],
})
export class BookWrapperScheduledComponent implements OnInit {

  @Input() service: ServiceAndPricesDTO;
  orderlineServiceSummary$: Observable<UserOrderLineServiceSummaryDTO>;
  newDate: Subject<string> = new Subject<string>();
  selectedPrice: ServicePriceDTO = null;
  selectedSlot: ScheduledServiceAndPricesDTO;
  step = "team";
  loadingDates: boolean;
  addingToCart: boolean;
  addingAttendee: boolean;
  removingAttendee: boolean;
  removingAllAttendees: boolean;
  bookingMonth: BookingMonth;
  previousMonthStart: string;
  BookingDayStatus = BookingDayStatus;
  nextMonthStart: string;
  error: ErrorModel;
  services: ScheduledServiceAndPricesWrapperDTO;
  selectedCourseDates: ServiceScheduleForCourseDTO;
  daysInMonth: BookingDay[];
  selectedDate: string;

  constructor(
    private bookingService: BookingService,
    private bookingDateService: BookingDateService,
    private modalService: ModalService,
    private datesService: DatesService,
    private navigate: NavigationService,
    private servicesPublicService: ServicesPublicService,
    private notifyService: NotifyService
  ) { }

  ngOnInit(): void {
    this.monthView(this.bookingDateService.today());
    this.load();
    this.loadUserServiceSummary();
  }

  monthView(date: string) {
    this.bookingMonth = this.bookingDateService.getBookingMonth(date);
    this.previousMonthStart = this.bookingMonth.previousMonthStart;
    this.nextMonthStart = this.bookingMonth.nextMonthStart;
    var daysInMonth: BookingDay[] = [];
    for (let b = 0; b < this.bookingMonth.weeks.length; b++) {
      const weeks = this.bookingMonth.weeks[b];
      for (let w = 0; w < weeks.days.length; w++) {
        const day = weeks.days[w];
        daysInMonth.push(day)
      }
    }
    this.daysInMonth = daysInMonth;
  }


  loadUserServiceSummary() {
    this.orderlineServiceSummary$ = null;
    this.orderlineServiceSummary$ = this.bookingService.getserviceorderlinesummary(this.service.businessId, this.service.serviceId).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  next() {
    this.selectedDate = null;
    this.monthView(this.nextMonthStart);
    this.load();
  }

  prev() {
    this.selectedDate = null;
    this.monthView(this.previousMonthStart);
    this.load();
  }

  dateMatchesSelectedDate(slotDate: string) {
    return this.datesService.isSameDay(slotDate, this.selectedDate)
  }

  getReadableDateDayAndMonthLong(): string {
    return this.datesService.getReadableDateDayAndMonthLong(this.selectedDate);
  }

  getReadableDateDayAndMonthShort(): string {
    return this.datesService.getReadableDateDayAndMonthShort(this.selectedDate);
  }

  addAttendee() {
    if (this.addingAttendee || this.removingAttendee)
      return;
    this.addingAttendee = true;
    var dto: AddAttendeeToServiceDTO = {
      serviceId: this.service.serviceId,
      businessId: this.service.businessId
    }
    this.bookingService.addAttendeeToService(dto)
      .then(() => {
        this.loadUserServiceSummary();
      })
      .catch((error) => {
        this.handlerError(error.message);
      }).finally(() => {
        this.addingAttendee = false;
      });
  }

  removeAttendee() {
    if (this.addingAttendee || this.removingAttendee)
      return;
    this.removingAttendee = true;
    var dto: RemoveAttendeeFromServiceDTO = {
      serviceId: this.service.serviceId,
      businessId: this.service.businessId
    }
    this.bookingService.removeAttendeeFromService(dto)
      .then(() => {
        this.loadUserServiceSummary();
      })
      .catch((error) => {
        this.handlerError(error.message);
      }).finally(() => {
        this.removingAttendee = false;
      });
  }

  removeAllAttendee() {
    if (this.addingAttendee || this.removingAttendee || this.removingAllAttendees)
      return;
    this.removingAllAttendees = true;
    var dto: RemoveAllAttendeesFromServiceDTO = {
      serviceId: this.service.serviceId,
      businessId: this.service.businessId
    }
    this.bookingService.removeAllAttendeesFromService(dto)
      .then(() => {
        this.loadUserServiceSummary();
      })
      .catch((error) => {
        this.handlerError(error.message);
      }).finally(() => {
        this.removingAllAttendees = false;
      });
  }

  nextStep() {
    this.addedToCart(this.service.businessId);
  }

  load() {
    this.services = null;
    this.selectedDate = null;
    this.loadingDates = true;
    this.servicesPublicService.getScheduledServices(this.service.businessId, this.bookingMonth.currentMonth, this.bookingMonth.nextMonthStart, this.service.serviceId).toPromise()
      .then((services: any) => {
        this.services = services;
        if (this.services?.results?.length > 0) {
          this.selectedDate = this.services?.results[0].date;
          for (let s = 0; s < this.services?.results.length; s++) {
            const service = this.services.results[s];
            for (let d = 0; d < this.daysInMonth.length; d++) {
              const day = this.daysInMonth[d];
              if (this.datesService.isSameDay(day.date, service.date))
                day.status = BookingDayStatus.ACTIVE;
            }
          }
        }
      })
      .catch(err => {
        this.notifyService.notify("Availability", err?.message ?? "Could not load availability", NotifyType.ERROR);
      }).finally(() => {
        this.loadingDates = false;
      })
  }

  selectDate(date: string) {
    this.selectedSlot = null;
    this.selectedDate = date;
  }

  priceSelected(price: ServicePriceDTO) {
    this.selectedPrice = price;
    this.step = 'team';
  }

  dateSelected(isoDate: string) {
    this.selectedSlot = null;
    this.newDate.next(isoDate);
  }

  slotSelected(slot: ScheduledServiceAndPricesDTO) {
    if (this.selectedSlot?.date == slot.date) {
      this.selectedSlot = null;
      this.selectedCourseDates = null;
    } else {

      this.selectedSlot = slot;
      if (this.selectedSlot.courseScheduleId != undefined && this.selectedSlot.courseScheduleId != "") {
        this.addingToCart = true;
        this.servicesPublicService.getCourseSchedule({ businessId: this.selectedSlot.businessId, serviceId: this.selectedSlot.serviceId, scheduleId: this.selectedSlot.courseScheduleId }).then((data: ServiceScheduleForCourseDTO) => {
          this.selectedCourseDates = data;
        }).catch((error) => {
          this.handlerError(error.message);
        }).finally(() => {
          this.addingToCart = false;
        });
      } else {
        this.selectedCourseDates = null;
      }
    }
  }



  async addToCart() {
    if (!this.selectedSlot)
      return;
    if (this.addingToCart)
      return;
    this.addingToCart = true;
    var addToCart: AddToCartDTO = {
      couponId: null,
      quantity: 1,
      slot: null,
      scheduled: this.selectedSlot,
      businessId: this.selectedSlot.businessId
    }
    this.bookingService.addToCart(addToCart)
      .then(() => {
        this.loadUserServiceSummary();
      })
      .catch((error) => {
        this.handlerError(error.message);
      }).finally(() => {
        this.selectedCourseDates = null;
        this.selectedSlot = null;
        this.addingToCart = false;
      });
  }

  addedToCart(businessId: string) {
    window.scrollTo(0, 0);
    var settings: ModalModel = {
      close: true,
      large: false,
      design: ModalDesign.COMPONENT,
      component: ContinueShoppingComponent,
      componentInputs: [
        {
          inputName: "businessId",
          value: businessId
        }
      ]
    }
    this.modalService.show(settings);
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

}
