import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { ErrorModel } from '../../../../../data/models/error_model';
import { Location } from '../../../../../data/models/location_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { DirectoryRepositoryService } from '../../services/directory_repository_service/directory_repository_service';

@Component({
  templateUrl: './directory.component.html',
})
export class DirectoryComponent implements OnInit {

  error: ErrorModel;
  locations$: Observable<Location[]>;

  constructor(
    private directory: DirectoryRepositoryService,
    private navigation: NavigationService,
    private router: Router,
    private seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.locations$ = null;
    this.locations$ = this.directory.getCountries().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  goToDirectory() {
    this.navigation.navigate(WildNavigationType.DIRECTORY)
  }

  select(location: Location) {
    var parsed = location.name.replace(" ", "~");
    this.router.navigate(["directory", parsed])
  }

}
