import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { WaterwayType } from '../../../../../../../data/enums/waterway_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';

@Component({
  selector: 'app-addswimspotdetails',
  templateUrl: './addswimspotdetails.component.html',
  styleUrls: ['./addswimspotdetails.component.scss']
})
export class AddSwimspotdetailsComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private fb: UntypedFormBuilder,
    private swimSpotDataService: SwimspotsdataService,
    private auth: AuthenticationService,
    private notifyService: NotifyService) { }

  @Output() done = new EventEmitter<string>();
  @Output() back = new EventEmitter();
  form!: UntypedFormGroup;
  loading: boolean;
  swimSpotId: string;
  showAnimation: boolean;
  typeOptions: SelectModel[] = [
    {
      name: "Coastal",
      value: WaterwayType.COASTAL
    },
    {
      name: "Lake",
      value: WaterwayType.LAKE
    },
    {
      name: "River",
      value: WaterwayType.RIVER
    }
  ];

  ngOnInit(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl(0, [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      gettingthere: new UntypedFormControl('', [Validators.required]),
      accessibilityinformation: new UntypedFormControl('', [Validators.required]),
      tags: new UntypedFormControl('', [Validators.required]),
    });
  }

  tags = [
    "Swimming",
    "Ice Swimming",
    'SUP',
    "Triathlon",
    "Water Skiing",
    "Wake Boarding",
    "Knee Boarding",
    "Surfing",
    "Body Boarding",
    "Body Surfing",
    "Canoeing",
    "Kayaking",
    "Wind Surfing",
    "Kite Boarding",
    "Sailing",
    "Skim Boarding",
    "Scuba Diving",
    "Snorkeling",
    "White Water Rafting",
    "Free Diving",
    "Canyoning",
    "Hydro foil",
    "Yachting",
    "Fly Boarding",
    "Rowing",
    "Ice Diving",
    "Swim Run",
    "Walking",
    "Coasteering",
    'Big Drop Off',
    'Gentle Slope',
    'Easy to walk to',
    'Beach',
    'Muddy',
    'Reeds',
    'Mountain Views',
    'Nearby Parking',
    'Quiet Location',
    'Rocky',
    'Steps to get in',
    'Child Friendly',
    'Paddle',
    'Skinny Dip',
    'Picnic',
    'Train Access',
    'Cycling',
    'Boating',
    'Pub',
    'Camping',
    'Jumping',
    'Cliffs',
    'Bridges',
    'Chutes',
    'Waterfalls',
    'Dog Friendly',
    'Accessibility',
    'Pay to Swim',
    'Long Swim',
    'Private Property',
    'Swing',
    'Sunset',
    'Sunrise',
    'Estuary',
    'Pools',
    'Weir',
    'Pebbles',
    'Caves',
    'Lido',
    'Toilets',
    'Quarry'
  ]

  onSubmit(data: SwimSpotFormData) {
    if (this.tags == null || this.tags.length == 0) {
      this.notifyService.notify("No Tags", "Please select at least one tag", NotifyType.ERROR);
      return;
    }
    if (this.lat == "" || this.lng == "") {
      this.notifyService.notify("Location not detected", "Please go back and choose a location", NotifyType.ERROR);
      return;
    }


    var processData = {
      latitude: this.lat,
      longitude: this.lng,
      swimming: data.tags.some(tag => tag == "Swimming"),
      iceswimming: data.tags.some(tag => tag == "Ice Swimming"),
      waterskiing: data.tags.some(tag => tag == "Water Skiing"),
      wakeboarding: data.tags.some(tag => tag == "Wake Boarding"),
      kneeboarding: data.tags.some(tag => tag == "Knee Boarding"),
      surfing: data.tags.some(tag => tag == "Surfing"),
      bodyboarding: data.tags.some(tag => tag == "Body Boarding"),
      bodysurfing: data.tags.some(tag => tag == "Body Surfing"),
      canoeing: data.tags.some(tag => tag == "Canoeing"),
      kayaking: data.tags.some(tag => tag == "Kayaking"),
      windsurfing: data.tags.some(tag => tag == "Wind Surfing"),
      kiteboarding: data.tags.some(tag => tag == "Kite Boarding"),
      sailing: data.tags.some(tag => tag == "Sailing"),
      skimboarding: data.tags.some(tag => tag == "Skim Boarding"),
      scubadiving: data.tags.some(tag => tag == "Scuba Diving"),
      snorkeling: data.tags.some(tag => tag == "Snorkeling"),
      whitewaterrafting: data.tags.some(tag => tag == "White Water Rafting"),
      freediving: data.tags.some(tag => tag == "Free Diving"),
      canyoning: data.tags.some(tag => tag == "Canyoning"),
      hydrofoil: data.tags.some(tag => tag == "Hydro foil"),
      yachting: data.tags.some(tag => tag == "Yachting"),
      flyboarding: data.tags.some(tag => tag == "Fly Boarding"),
      rowing: data.tags.some(tag => tag == "Rowing"),
      icediving: data.tags.some(tag => tag == "Ice Diving"),
      swimrun: data.tags.some(tag => tag == "Swim Run"),
      walking: data.tags.some(tag => tag == "Walking"),
      triathlon: data.tags.some(tag => tag == "Triathlon"),
      coasteering: data.tags.some(tag => tag == "Coasteering"),
      bigDropOff: data.tags.some(tag => tag == "Big Drop Off"),
      gentleSlope: data.tags.some(tag => tag == "Gentle Slope"),
      easyToWalkTo: data.tags.some(tag => tag == "Easy to walk to"),
      hasABeach: data.tags.some(tag => tag == "Beach"),
      itIsMuddy: data.tags.some(tag => tag == "Muddy"),
      lotsOfReeds: data.tags.some(tag => tag == "Reeds"),
      mountainViews: data.tags.some(tag => tag == "Mountain Views"),
      nearbyParking: data.tags.some(tag => tag == "Nearby Parking"),
      quietLocation: data.tags.some(tag => tag == "Quiet Location"),
      rockyGround: data.tags.some(tag => tag == "Rocky"),
      stepsToGetIn: data.tags.some(tag => tag == "Steps to get in"),
      suitableForKids: data.tags.some(tag => tag == "Child Friendly"),
      paddling: data.tags.some(tag => tag == "Paddle"),
      skinnyDip: data.tags.some(tag => tag == "Skinny Dip"),
      picnic: data.tags.some(tag => tag == "Picnic"),
      trainAccess: data.tags.some(tag => tag == "Train Access"),
      cycling: data.tags.some(tag => tag == "Cycling"),
      boating: data.tags.some(tag => tag == "Boating"),
      supping: data.tags.some(tag => tag == "SUP"),
      pubs: data.tags.some(tag => tag == "Pub"),
      camping: data.tags.some(tag => tag == "Camping"),
      jumping: data.tags.some(tag => tag == "Jumping"),
      cliffs: data.tags.some(tag => tag == "Cliffs"),
      bridges: data.tags.some(tag => tag == "Bridges"),
      chutes: data.tags.some(tag => tag == "Chutes"),
      waterfalls: data.tags.some(tag => tag == "Waterfalls"),
      dogs: data.tags.some(tag => tag == "Dog Friendly"),
      accessibility: data.tags.some(tag => tag == "Accessibility"),
      payToSwim: data.tags.some(tag => tag == "Pay to Swim"),
      longSwim: data.tags.some(tag => tag == "Long Swim"),
      privateProperty: data.tags.some(tag => tag == "Private Property"),
      swing: data.tags.some(tag => tag == "Swing"),
      sunset: data.tags.some(tag => tag == "Sunset"),
      sunrise: data.tags.some(tag => tag == "Sunrise"),
      estuary: data.tags.some(tag => tag == "Estuary"),
      pools: data.tags.some(tag => tag == "Pools"),
      weir: data.tags.some(tag => tag == "Weir"),
      pebbles: data.tags.some(tag => tag == "Pebbles"),
      caves: data.tags.some(tag => tag == "Caves"),
      lido: data.tags.some(tag => tag == "Lido"),
      toilets: data.tags.some(tag => tag == "Toilets"),
      quarry: data.tags.some(tag => tag == "Quarry"),
      ...data
    }
    delete processData.tags;
    this.loading = true;
    this.swimSpotDataService.addSwimSpot(processData)
      .then(async (swimSpotId: string) => {
        this.swimSpotId = swimSpotId;
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  get lat(): string {
    return this.storage.getCookie("swimspotlat")
  }

  get lng(): string {
    return this.storage.getCookie("swimspotlng")
  }


  handlerError(message: string) {
    this.notifyService.notify("Swim Spot", message, NotifyType.ERROR);
  }

  async next() {
    this.notifyService.notify("SwimSpot Added", "Your Swim Spot has been added", NotifyType.SUCCESS);
    this.storage.deleteCookie("swimspotlat");
    this.storage.deleteCookie("swimspotlng");
    this.showAnimation = true;
  }

  goToNextPage() {
    this.done.emit(this.swimSpotId);
  }

  previous() {
    this.back.emit();
  }

  resetForm() {
    this.loading = false;
  }

}

interface SwimSpotFormData {
  name: string,
  type: WaterwayType,
  tags: string[],
  description: string
}
