import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessConfigurationDTO } from '../../../../../data/dtos/business/configuration/BusinessConfigurationDTO';
import { UpdateRefundTimeRangeDTO } from '../../../../../data/dtos/business/configuration/UpdateRefundTimeRangeDTO';
import { UpdateTimeSlotTypeDTO } from '../../../../../data/dtos/business/configuration/UpdateTimeSlotTypeDTO';
import { CurrentWaiverDTO } from '../../../../../data/dtos/business/waiver/CurrentWaiverDTO';
import { UpdateWaiverContentDTO } from '../../../../../data/dtos/business/waiver/UpdateWaiverContentDTO';
import { UpdateWaiverStatusDTO } from '../../../../../data/dtos/business/waiver/UpdateWaiverStatusDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(
        private http: HttpService
    ) { }

    getBusinessConfiguration(): Observable<BusinessConfigurationDTO> {
        return this.http.get(`${environment.api}/business/configuration`)
    }

    getCurrentWaiver(): Observable<CurrentWaiverDTO> {
        return this.http.get(`${environment.api}/business/configuration/waiver`);
    }

    async updateWaiverContent(dto: UpdateWaiverContentDTO) {
        await this.http.put(`${environment.api}/business/configuration/waiver/content`, dto).toPromise();
    }

    async updateWaiverStatus(dto: UpdateWaiverStatusDTO) {
        await this.http.put(`${environment.api}/business/configuration/waiver/status`, dto).toPromise();
    }

    async newWaiverVersion() {
        await this.http.put(`${environment.api}/business/configuration/waiver/newversion`, {}).toPromise();
    }

    async updateTimeSlot(dto: UpdateTimeSlotTypeDTO) {
        await this.http.post(`${environment.api}/business/configuration/timeslot`, dto).toPromise();
    }

    async updateRefundTime(dto: UpdateRefundTimeRangeDTO) {
        await this.http.post(`${environment.api}/business/configuration/refundtime`, dto).toPromise();
    }

}
