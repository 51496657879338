import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { communicationsListData } from "./communications_data_listdata";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { usecases } from "../../shared/usecases";

export const communicationsData: FeaturesData = {
  seoTitle: "Intelligent Communications for Watersports Businesses",
  seoDescription: "Communications are sent automatically to your customers for order confimations, reminders and rescheduling",
  seoImage: communicationsListData.image,
  seoKeyWords: "",
  page: communicationsListData.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: communicationsListData.title,
      subtitle: communicationsListData.subtitle,
      paragraph: "Order confirmations, reminders and cancellation comm's are all included",
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.BIGIMAGEFEATURE,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcommunications%2Fchecking-phone.jpg?alt=media&token=4a0becad-900c-4146-9264-5b546923b754",
      title: "Always in the Loop",
      subtitle: "It's Automatic",
      paragraph: "Order confirmations, reminders & rescheduling comm's are sent automatically",
      data: [
        {
          icon: "envelope", title: "Order Confirmations.", subtitle: "Sent automatically."
        },
        {
          icon: "envelope", title: "Cancellations.", subtitle: "Wild informs everyone."
        },
        {
          icon: "envelope", title: "Rescheduling.", subtitle: "Everyone is notified."
        }
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.BIGIMAGEFEATURE,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcommunications%2Freminders.png?alt=media&token=c5dce58e-1c2f-49a3-acd5-ddea3aa1f7d6",
      title: "Reminders",
      subtitle: "Reduce No-Shows",
      paragraph: "Reminders sent to all your customers.",
      data: [
        {
          icon: "shopping-bag", title: "24 Hour.", subtitle: "A heads up."
        },
        {
          icon: "stopwatch", title: "1 Hour.", subtitle: "Just before."
        },
        {
          icon: "mobile", title: "App & Web.", subtitle: "Account for customers."
        }
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "Can I set up a welcome email for new customers?",
          subtitle: "Yes, and it's a great idea to welcome new customers to your business. Just go to your settings and write a welcome message. You can even add an attachment if you want to."
        },
        {
          title: "How do I set customers know about cancellations?",
          subtitle: "As soon as you adjust someones appointment or booking, they will be sent an email about what has changed. It's all taken care of."
        },
        {
          title: "Can I adjust the emails my team are sent?",
          subtitle: "Yes, each team member can adjust the comm's they are sent from their user setting page."
        },
        {
          title: "Are emails sent for refunds?",
          subtitle: "Yes, both customer and business receive emails about refunds."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "More Great App & Website Features",
      limit: 9,
      paragraph: "",
      data: [
        schedulingListDataShared,
        teamListDataShared,
        ordersFinanceListData,
        reportingListData,
        crmListData,
        realTimeSafetyListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}

