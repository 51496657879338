import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-actionswrapper',
  template: `
  <div class="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" class="divide-y divide-gray-200">
       <ng-content></ng-content>
      </ul>
  </div>
  `
})
export class ActionsWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
