import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { crmListData } from "../../features/customers/customer_management_listdata";
import { ordersFinanceListData } from "../../features/finance/orders_finances_data_listdata";
import { schedulingListDataShared } from "../../features/scheduling/scheduling_data_listdata";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { calendarComponent } from "../../shared/features/calendar";
import { organisedContacts } from "../../shared/features/organisedcontacts";
import { timetables } from "../../shared/features/timetables";
import { seoUseCaseDescription } from "../../shared/seo/seo_description_template_usecases";
import { seoUseCaseKeyWords } from "../../shared/seo/seo_keywords_template_usecases";
import { seoUseCaseTitle } from "../../shared/seo/seo_title_template_usecases";
import { openWaterCoachListData } from "./openWaterCoach_listdata";

const type = "Open Water Coaching";
export const openWaterCoachData:FeaturesData = { 
  seoTitle:seoUseCaseTitle(type),
  seoDescription:seoUseCaseDescription(type,"open water swimming"),
  seoImage:openWaterCoachListData.image,
  seoKeyWords:seoUseCaseKeyWords(type,"Open Water Swimming Lesson"),
  page:openWaterCoachListData.page,
    components:[ 
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.SIMPLEHERO,
        ctas:businessCTAs,
        title:type,
        subtitle:openWaterCoachListData.subtitle,
        paragraph:"Run your business subscription free.",
      },
      injectSharedComponent(calendarComponent, { 
        colour: FeatureColour.WHITE,
         type: FeatureComponentType.BIGIMAGEFEATURE,
         ctas:{
            secondary:{
            text:"Learn More",
            page:WildNavigationType.SCHEDULINGBOOKINGENGINE
          }
        } }),
      injectSharedComponent(
        organisedContacts, { 
          colour:FeatureColour.WHITE,
          ctas:{
            secondary:{
            text:"Learn More",
            page:WildNavigationType.BUSINESSCRM
          }}
        }),
      injectSharedComponent(
        timetables, { 
          type:FeatureComponentType.RIGHTIMAGEINFOSECTION,
          colour:FeatureColour.WHITE,
          ctas:{
            secondary:{
            text:"Learn More",
            page:WildNavigationType.SCHEDULINGBOOKINGENGINE
          }}
        }),
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.FAQS,
        title:"",
        subtitle:"Frequently Asked Questions",
        data:[
          {
            title: "Can I sell courses?",
            subtitle: "Yes, we call them class passes and you can bundle up a series of services how ever you like."
          },
          {
            title: "Can I work alongside other coaches and businesses?",
            subtitle: "Yes, feel free to set up multiple coaches in your organistion and the system will scale with you."
          },
          ...commonBusinesFAQs
        ],
        ctas: businessCTAs,
      },
      {
        colour: FeatureColour.WHITE,
        type: FeatureComponentType.TEXTANDINFO,
        title: "Explore More",
        subtitle: "Other Great Features for Coaches",
        limit: 9,
        paragraph: "",
        data: [
          schedulingListDataShared,
          crmListData,
          ordersFinanceListData
        ],
        ctas: {
          secondary: {
            text: "See All",
            page: WildNavigationType.ALLFEATURES
          }
        }
      }
  ] } 
