<ng-container *ngIf="images$ | async as images; else loadingOrError">
    <div *ngIf="!images || images.length == 0; else imageslist">
        <app-filemanagerwrapper>
            <app-empty (onClick)="new()" header="No Images Yet" description="Get started by selecting the upload tab"
                icon="images">
            </app-empty>
        </app-filemanagerwrapper>
    </div>
    <ng-template #imageslist>
        <app-filemanagerscrollableshell ctaonetext="Select" ctatwotext="Cancel" (onClickOne)="select()"
            [loading]="loading" (onClickTwo)="cancel()">
            <ul role="list"
                class="px-1 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                <li (click)="selectImage(image)" *ngFor="let image of images;let i as index" class="relative">
                    <div *ngIf="image.fetching" class="z-50 absolute top-0 left-0 w-full h-full bg-white opacity-30">
                        <app-loader [disablescreenheight]="true"></app-loader>
                    </div>
                    <div *ngIf="image.failedtoload"
                        class="z-50 absolute top-0 left-0 w-full h-full flex items-end justify-center">
                        <small class="text-gray-300 font-semibold mb-3">could not load</small>
                    </div>
                    <div [ngClass]="{'ring-2 ring-offset-2 ring-offset-gray-100 ring-blue-500': isInSelectedImages(image)}"
                        class="relative group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100  overflow-hidden">
                        <img (load)="imageLoaded($event, image)" data-max-retry="5" data-retry="0"
                            (error)="waitAndReload($event, image)"
                            [src]="imageUrl + image.image + '_640x640.jpeg?alt=media' | safe" alt=""
                            class="object-cover pointer-events-none group-hover:opacity-75">
                        <button type="button" class="absolute inset-0 focus:outline-none">
                        </button>
                    </div>
                </li>
            </ul>
        </app-filemanagerscrollableshell>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="retry()">
        </app-retry>
    </ng-container>
    <ng-template #loading>
        <app-filemanagerwrapper>
            <app-loader [disablescreenheight]="true"></app-loader>
        </app-filemanagerwrapper>
    </ng-template>
</ng-template>