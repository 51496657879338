<div class="my-4">
  <div class="text-center text-gray-500" *ngIf="images ==null || images.length == 0">No images yet</div>
  <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
    <li *ngFor="let image of images" class="relative">
      <div *ngIf="image.fetching" class="z-50 absolute top-0 left-0 w-full h-full bg-white opacity-30">
        <app-loader [disablescreenheight]="true"></app-loader>
      </div>
      <div *ngIf="image.failedtoload" class="z-50 absolute top-0 left-0 w-full h-full flex items-end justify-center">
        <small class="text-gray-300 font-semibold mb-3">could not load</small>
      </div>
      <div class="relative group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100  overflow-hidden">
        <a target="_blank" [href]="imagesUrl + image.image + '_1080x1080.jpeg?alt=media'">
          <img (load)="imageLoaded($event, image)" data-max-retry="5" data-retry="0"
            (error)="waitAndReload($event, image)" [src]="imagesUrl + image.image + '_640x640.jpeg?alt=media' | safe"
            alt="" class="object-cover pointer-events-none group-hover:opacity-75">
        </a>
      </div>
      <p class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
        {{image.creation_date.toDate() | date}}</p>
      <p class="block text-sm font-medium text-gray-500 pointer-events-none"></p>
    </li>
  </ul>
</div>