<app-filemanagerwrapper>
    <div *ngIf="!imagesForUpload || imagesForUpload.length == 0" class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <form>
            <label class="block text-sm font-medium text-gray-700">
                Add photos
            </label>
            <div
                class="relative mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
                <input [accept]="accept" multiple type="file" id="file" #userPhoto (change)="onFileSelected($event)"
                    name="image" autocomplete="off" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer" />
                <div class="space-y-1 text-center">
                    <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                        aria-hidden="true">
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                        <label for="file-upload"
                            class="relative cursor-pointer bg-white rounded-md font-medium wild-text-primary hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                            <span *ngIf="limit == 1">Upload a file</span>
                            <span *ngIf="limit > 1">Upload up to {{limit}} files</span>
                            <input [accept]="accept" multiple id="file-upload" name="file-upload" type="file" #userPhoto
                                (change)="onFileSelected($event)" class="sr-only">
                        </label>
                        <p class="pl-1">or drag and drop</p>
                    </div>
                    <p class="text-xs text-gray-500">
                        {{acceptOnView}} up to {{maxfilesizeOnView}}
                    </p>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="imagesForUpload && imagesForUpload.length > 0">
        <app-filemanagerscrollableshell ctaonetext="Upload" ctatwotext="Cancel" (onClickOne)="upload()"
            [loading]="loading" (onClickTwo)="cancelUpload()">
            <ul role="list"
                class="px-1 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                <li *ngFor="let image of imagesForUpload;let i = index" class="relative">
                    <a *ngIf="image.uploadstatus == UploadStatus.UNKNOWN" (click)="removeImage(i)"
                        class="wild-btn-icon-light-sm cursor-pointer absolute top-2 right-2 z-30">
                        <fa-icon class="h-5 w-5" icon="times"></fa-icon>
                    </a>
                    <div *ngIf="image.uploadstatus == UploadStatus.STAGED"
                        class="z-50 absolute top-0 left-0 w-full h-full opacity-70 flex items-center justify-center">
                        <span class="text-xs font-semibold bg-white rounded-md wild-text-primary p-2">waiting...</span>
                    </div>
                    <div *ngIf="image.uploadstatus == UploadStatus.UPLOADING"
                        class="z-50 absolute top-0 left-0 w-full h-full bg-white opacity-30">
                        <app-loader [disablescreenheight]="true"></app-loader>
                    </div>
                    <div *ngIf="image.uploadstatus == UploadStatus.UPLOADED"
                        class="z-50 absolute top-0 left-0 w-full h-full opacity-70 flex items-center justify-center">
                        <span class="text-xs font-semibold bg-white rounded-md wild-text-primary p-2">uploaded</span>
                    </div>
                    <div
                        class="relative group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
                        <img [src]="image.src | safe" alt=""
                            class="object-cover pointer-events-none group-hover:opacity-75">
                        <button type="button" class="absolute inset-0 focus:outline-none">
                        </button>
                    </div>
                    <p class="mt-2 block text-xs font-medium text-gray-900 truncate pointer-events-none">{{image.name}}
                    </p>
                    <p class="block text-xs font-medium text-gray-500 pointer-events-none">{{image.size}}</p>
                </li>
                <li *ngIf="imagesForUpload.length < limit && !loading" class="relative mb-9">
                    <button type="button"
                        class="relative block h-full w-full border-2 border-gray-300 border-dashed rounded-lg px-12 py-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <input [accept]="accept" multiple type="file" id="file" #userPhoto
                            (change)="onFileSelected($event)" type="file" name="image" autocomplete="off"
                            class="absolute inset-0 w-full h-full opacity-0 cursor-pointer" />
                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none"
                            viewBox="0 0 48 48" aria-hidden="true">
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span class="mt-2 block text-sm font-medium text-gray-900">
                            add more
                        </span>
                    </button>
                </li>
            </ul>
        </app-filemanagerscrollableshell>
    </div>
</app-filemanagerwrapper>