<app-wrapper header="Activity Details" paragraph="Your Activity Details">
    <ng-container *ngIf="activity$ | async as activity; else loadingOrError">
        <div *ngIf="!activity; else activitiesloaded">
            <app-empty icon="calendar-alt" header="No Activity found" [showcta]="true" ctatext="Search"
                (onClick)="search()"></app-empty>
        </div>
        <ng-template #activitiesloaded>
            <div *ngIf="activity.bookingOnBehalfOfMessage" class="mb-5">
                <div class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800">
                    {{activity.bookingOnBehalfOfMessage}}
                </div>
            </div>
            <div class="wild-header-small">
                Check-In
            </div>
            <div class="my-6">
                <div class="w-full" *ngIf="contactId">
                    <qrcode colorDark="#031D44" colorLight="#ffffff"  [qrdata]="'activity_' + activity.id + '_' + contactId" [width]="256" [errorCorrectionLevel]="'M'" [margin]="0"></qrcode>
                </div>
            </div>
            <div class="wild-header-small">
                Business
            </div>
            <div class="wild-paragraph-prominent mb-3">{{activity.businessName}}</div>
            <div class="wild-header-small">
                Activity Name
            </div>
            <div class="wild-paragraph-prominent mb-3">{{activity.name}}</div>
            <div *ngIf="activity.description" class="wild-header-small">
                Description
            </div>
            <div *ngIf="activity.description" class="wild-paragraph-prominent  mb-3">{{activity.description}}</div>
            <div class="wild-header-small">
                Starts on
            </div>
            <div class="wild-paragraph-prominent  mb-3">{{activity.readableLocalStartDateTime}}</div>
            <div class="wild-header-small">
                Ends On
            </div>
            <div class="wild-paragraph-prominent  mb-3">{{activity.readableLocalEndDateTime}}</div>
            <div class="wild-header-small">
                Duration
            </div>
            <div class="wild-paragraph-prominent  mb-3">{{activity.duration}}</div>
            <div *ngIf="activity.itinerary" class="wild-header-small">
                Itinerary
            </div>
            <div *ngIf="activity.itinerary" class="wild-paragraph-prominent  mb-3">
                {{activity.itinerary}}</div>
            <div *ngIf="activity.whatToBring" class="wild-header-small">
                What to bring
            </div>
            <div *ngIf="activity.whatToBring" class="wild-paragraph-prominent  mb-3">
                {{activity.whatToBring}}</div>
            <div *ngIf="activity.requirements" class="wild-header-small">
                Requirements
            </div>
            <div *ngIf="activity.requirements" class="wild-paragraph-prominent  mb-3">
                {{activity.requirements}}</div>
            <div *ngIf="activity.swimspot" class="wild-header-small">
                Swim Spot
            </div>
            <app-readmoreswimspot *ngIf="activity.swimspot" [swimSpotId]="activity.swimspot">
            </app-readmoreswimspot>
            <div class="wild-header-small mt-3">
                Meeting Point
            </div>
            <div *ngIf="activity.location && activity?.location?.type == BusinessServiceLocationType.PHYSICAL">
                <p class="wild-paragraph-prominent mb-3">
                    {{activity?.location?.name}}
                </p>
                <div class="my-2">
                    <app-pointonmap [latitude]="activity?.location?.latitude"
                        [longitude]="activity?.location?.longitude"></app-pointonmap>
                </div>
                <p *ngIf="activity.location && activity?.location?.type == BusinessServiceLocationType.PHYSICAL"
                    class="wild-paragraph-prominent my-3">
                    {{activity?.location?.description}}
                </p>
            </div>
            <div class="wild-header-small mt-3">
                Team Members
            </div>
            <small class="text-xs text-gray-500" *ngIf="!activity.teamMembers || activity.teamMembers?.length == 0">Not
                Applicable</small>
            <div class=" mb-3" *ngFor="let teamMember of activity.teamMembers">
                <span class="flex-shrink-0 group block py-1">
                    <div class="flex items-center">
                        <div>
                            <img class="inline-block h-9 w-9 rounded-full"
                                [src]="teamMember?.image != null ? imagesUrl + teamMember?.image?.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                                alt="">
                        </div>
                        <div class="ml-3">
                            <p class="wild-paragraph-prominent">
                                {{teamMember?.firstName}} {{teamMember?.surname}}</p>
                        </div>
                    </div>
                </span>
            </div>
            <section *ngIf="!activity.bookingOnBehalfOfMessage" aria-labelledby="order-heading" class="mt-10 border-t border-b border-gray-200 py-6">
                <app-gettheapp></app-gettheapp>
            </section>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>