import { Injectable } from '@angular/core';
import { PaymentMethod } from '../../../data/models/payment_method';
import { AuthenticationService } from '../auth/auth_service';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
	providedIn: 'root'
})
export class PaymentsService {

	constructor(
        private firestore: FirestoreService,
        private auth: AuthenticationService,) {}

    async addCard(paymentMethod:PaymentMethod){
        var userId = this.auth.getLoggedInCookie();
        //Use function here
        // await this.firestore.createSubCollectionDocument("users",userId,"paymentmethods", paymentMethod);
    }
 
}
