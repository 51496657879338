import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { PayoutBalanceDTO } from '../../../../../../../data/dtos/business/PayoutBalanceDTO';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { PayoutsService } from 'src/wild/modules/business/services/payouts_service/payouts_service';
import { FinanceDashboardService } from '../services/finance_dashboard_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';


@Component({
  template: `
      <ng-container *ngIf=" link$ | async as link; else loadingOrError">
        <div *ngIf="!link; else loaded">
            <p>Could not get link, please try again</p>
        </div> 
        <ng-template #loaded>
          <app-modaltopper header="Finance Dashboard" paragraph="Your dashboard is powered by Stripe Express"></app-modaltopper>
          <div *ngIf="link?.url" class="flex flex-col justify-center items-center py-4">
              <a *ngIf="!isAppUser" (click)="hide()" class="wild-btn-primary w-40" [href]="link?.url" target="_blank">Open Dashboard</a>
              <a *ngIf="isAppUser" (click)="openInApp(link?.url)" class="wild-btn-primary w-40">Open Dashboard</a>
              <button (click)="hide()" class="wild-btn-light w-40 mt-3 ">Close</button>
          </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template> 
  `,
})
export class ExpressLinkComponent implements OnInit {

  constructor(private business: BusinessService, private payoutService: PayoutsService,
    private financeDashboardService: FinanceDashboardService,
    private modalService: ModalService,
    private auth: AuthenticationService,
    private appComms: CommunitcateWithAppService,
    private notifyService: NotifyService
  ) { }

  public error: ErrorModel = null;
  link$: Observable<any>;
  isAppUser: boolean = false;

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.load();
  }

  openInApp(url: string) {
    this.hide();
    this.appComms.url(url);
  }

  load() {
    this.link$ = null
    this.link$ = this.financeDashboardService.getLink().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  handlerError(message: string) {
    this.notifyService.notify("Dashboard", message, NotifyType.ERROR);
  }

  hide() {
    this.modalService.hide();
  }

}
