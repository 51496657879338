import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Currency } from '../../data/enums/currency';
import { CurrencyService } from '../services/currency/currency_service';

@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) { }
  transform(price, currency: Currency) {
    var symbol = this.currencyService.getSymbol(currency);
    var formattedPrice = this.currencyService.priceToReadable(price);
    return symbol + formattedPrice;
  }
} 