<!-- <app-loader [disablescreenheight]="true"></app-loader> -->

<app-wrapper header="Orders" paragraph="View and manage your orders here">
  <div *ngIf="!orders || orders?.length == 0">
    <app-empty icon="shopping-bag" header="No Orders yet" ctatext="Search" (onClick)="search()" [showcta]="!isAppUser">
    </app-empty>
  </div>
  <section *ngIf="orders && orders?.length > 0" class="mt-4">
    <div class="space-y-8">
      <div *ngFor="let order of orders"
        class="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border">
        <div class="py-2 px-4 sm:px-6 border-b border-gray-200 bg-gray-100 text-gray-900 rounded-t-lg">
          <p>{{order.business}}</p>
          <p class="text-xs font-medium">
            {{order.statusDescription}}
          </p>
        </div>
        <div class="flex items-center p-4 border-b border-gray-200 sm:p-6">
          <dl class="flex text-sm">
            <div class="w-auto">
              <dt class="font-medium text-gray-900">Reference</dt>
              <dd class="mt-1 text-gray-500">
                {{order.reference}}
              </dd>
            </div>
            <div class="ml-4">
              <dt class="font-medium text-gray-900">Order date</dt>
              <dd class="mt-1 text-gray-500">
                <time datetime="2021-07-06">{{order.date | date:"mediumDate"}}</time>
              </dd>
            </div>
            <div class="ml-4">
              <dt class="font-medium text-gray-900">Total Paid</dt>
              <dd class="mt-1 font-medium text-gray-900">
                {{order.formattedGrandTotal}}
              </dd>
            </div>
          </dl>
        </div>
        <ul role="list" class="divide-y divide-gray-200">
          <li *ngFor="let orderLine of order.orderLines" class="p-4 sm:p-6">
            <div class="flex items-center sm:items-start">
              <div class="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                <img
                  [src]="orderLine?.image != null ? imagesUrl + orderLine?.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                  alt="Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps."
                  class="w-full h-full object-center object-cover">
              </div>
              <div class="flex-1 ml-6 text-sm">
                <div class="font-medium text-gray-900 sm:flex sm:justify-between">
                  <h5>
                    {{orderLine?.description}}
                  </h5>
                  <p class="mt-2 sm:mt-0">
                    <span
                      [ngClass]="{'line-through': orderLine.status == OrderLineStatus.REFUNDED || orderLine.status == OrderLineStatus.PARTIALREFUND}">{{orderLine.formattedGrandTotal}}</span>
                    <span class="text-xs italic text-gray-500"
                      *ngIf="orderLine.status == OrderLineStatus.REFUNDED || orderLine.status == OrderLineStatus.PARTIALREFUND">
                      ({{orderLine.formattedRefundedTotal}}
                      refunded)</span>
                  </p>
                </div>
                <p class="hidden text-gray-500 sm:block">
                  {{orderLine?.priceDescription}}
                </p>
                <div *ngIf="orderLine.status != OrderLineStatus.PAID">
                  <p class="text-gray-600 text-md mt-2">cancelled</p>
                </div>
                <div *ngIf="orderLine.activity ||  orderLine.courseScheduleId"
                  class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 mt-2">
                  Booking for <span *ngIf="!orderLine.linkedAccount">&nbsp;myself</span>
                  <span *ngIf="orderLine.linkedAccount">&nbsp;{{orderLine.linkedAccount?.firstName}}
                    {{orderLine.linkedAccount?.surname}}</span>
                </div>
                <div>
                  <div *ngIf="orderLine.criteriaMetForMembershipDiscount" 
                  class="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800 mt-2">
                  Membership Discount Applied
                </div>
                </div>
                <div *ngIf="orderLine.status == OrderLineStatus.PAID && orderLine.activity">
                  <div class="font-medium text-gray-700 hover:text-gray-800 text-sm mt-2">
                    {{orderLine.activity.readableLocalStartDateOnly}}
                  </div>
                  <p class="text-gray-500 text-sm">{{orderLine.activity.readableLocalTimeRange}}</p>
                  <!-- <small class="text-xs italics"><span (click)="openActivity(orderLine.activity.id)"
                      class="cursor-pointer underline">Activity details</span></small> -->
                </div>
                <div *ngIf="orderLine.status == OrderLineStatus.PAID && orderLine.couponId">
                  <small *ngIf="!isAppUser" class="text-xs italics"><span (click)="openCoupons()"
                      class="cursor-pointer underline">Go to vouchers</span></small>
                </div>
                <div class="mb-2 mt-3" *ngFor="let coupon of orderLine.coupons">
                  <p
                    class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                    {{coupon?.name}} (-{{coupon?.formattedDiscountPrice}})</p>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:flex sm:justify-between">
              <div class="flex items-center">
                <svg *ngIf="orderLine?.status == OrderLineStatus.PAID" class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <svg *ngIf="orderLine?.status == OrderLineStatus.REFUNDED" class="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <p class="ml-2 text-sm font-medium text-gray-500">
                  <container-element [ngSwitch]="orderLine?.status">
                    <some-element *ngSwitchCase="OrderLineStatus.PAID">Paid</some-element>
                    <some-element *ngSwitchCase="OrderLineStatus.REFUNDED">Refunded</some-element>
                    <some-element *ngSwitchDefault>Unknown Status</some-element>
                  </container-element>
                </p>
              </div>
              <div *ngIf="orderLine.status == OrderLineStatus.PAID && (orderLine.activity || orderLine.courseScheduleId)"
                class="mt-6 border-t border-gray-200 pt-4 flex items-center space-x-4 divide-x divide-gray-200 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                <div class="flex-1 flex justify-center">
                  <a (click)="cancel(order, orderLine)"
                    class="wild-text-error whitespace-nowrap cursor-pointer">Cancel</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="!hideshowmore && orders.length >= 5" class="flex items-center justify-center my-3">
        <button (click)="loadMore()" class="wild-btn-light">load more</button>
      </div>
    </div>
  </section>
</app-wrapper>