import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { Gender } from '../../../../../../../data/enums/gender_type';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Update Gender" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
          <app-select [options]="codeOptions" [form]="form" name="gender" description="Gender" [span]=6>
            </app-select>
      </app-inputgroup>
  </app-form> 
  `
})
export class ContactGenderFormComponent implements OnInit {

  @Input() contact: BusinessContactModel;
  codeOptions: SelectModel[] = [
    {
      name: "Unknown",
      value: Gender.UNKNOWN
    },
    {
      name: "Male",
      value: Gender.MALE
    },
    {
      name: "Female",
      value: Gender.FEMALE
    },
    {
      name: "Rather not say",
      value: Gender.RATHERNOTSAY
    }
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      gender: new UntypedFormControl(this.contact?.gender ?? Gender.UNKNOWN, [Validators.required]),
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    this.contactService.updateGender(this.contact.id, data.gender)
      .then(() => {
        this.next(data.gender);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Gender", message, NotifyType.ERROR);
  }

  next(gender: Gender) {
    this.notifyService.notify("Gender Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.contact.gender = gender;
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
