import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImageModel } from '../../../../data/models/images_model';

@Component({
  selector: 'app-imageselect',
  templateUrl: './imageselect.component.html',
  styleUrls: ['./imageselect.component.scss']
})
export class ImageselectComponent implements OnInit {

  @Input() form!: any;
  @Input() options!: ImageModel[];
  @Input() name!: string;
  @Input() description!: string;
  @Input() value!: any;
  @Input() newicon!: string;
  @Input() newtext!: string;
  @Output() onNewClick = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Input() valueChangeEvent: Observable<any>;

  required: boolean;
  private valueChangeEventsSubscription: Subscription;
  imagesUrl = environment.imagesUrl;

  constructor() { }

  ngOnInit(): void {
    if (this.value)
      this.form.controls[this.name].setValue(this.value);
    const form_field = this.form.get(this.name);
    if (form_field.validator) {
      const validator = form_field.validator({} as AbstractControl);
      if (validator && validator.required)
        this.required = true;
    }
    this.valueChangeEventsSubscription = this.valueChangeEvent.subscribe((value) => this.updateValue(value));
  }

  updateValue(value: any) {
    this.value = value;
  }

  selectOption(value: any) {
    this.value = value;
    this.form.controls[this.name].setValue(this.value);
    this.onSelect.emit(value);
  }

  new() {
    this.onNewClick.emit();
  }

  ngOnDestroy() {
    this.valueChangeEventsSubscription.unsubscribe();
  }


}
