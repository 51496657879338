import { Component, Input, OnInit } from '@angular/core';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ActivityContactsService } from '../services/activitycontacts_service';
import { BusinessActivitiesService } from '../services/businessactivities_service';

@Component({
  template: `
  <app-modaltopper [header]="header"></app-modaltopper>
  <div class="mt-3">
    <ul role="list" class="flex flex-col md:flex-row items-center justify-evenly">
        <li class="border shadow border-gray-100 rounded-md p-2 w-64 my-3 md:mb-0">
          <h2 class="text-medium text-md text-center mt-2">CSV</h2>
          <div class="flex items-center justify-center py-2">
            <span class="inline-block relative">
              <img class="h-16 w-16 rounded-full" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fcsv.jpg?alt=media&token=f799bbd2-ea21-45ad-9bbb-5831b7caf7b7" alt="">
            </span>
          </div>
          <p class="text-xs text-gray-400 text-center ">Perfect for importing data into other software</p>
          <div class="w-full mt-3">
            <button (click)="csv()" class="wild-btn-primary w-full">Export</button>
          </div>
        </li>
        <!-- <li class="border shadow border-gray-100 rounded-md p-2 w-64 my-3 md:mb-0">
          <h2 class="text-medium text-md text-center mt-2">Scheduled</h2>
          <div class="flex items-center justify-center py-2">
            <span class="inline-block relative">
              <img class="h-16 w-16 rounded-full" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fgroup.jpg?alt=media&token=86c30cb9-28e4-4525-9fb9-e966ab206e37" alt="">
            </span>
          </div>
          <p class="text-xs text-gray-400 text-center">Single or group sessions on set days throughout the year</p>
          <div class="flex items-center justify-center py-3">
          <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> 3 min setep </span>
          </div>
          <div class="w-full">
            <button (click)="choose(ServiceType.GROUP)" class="wild-btn-primary w-full">Select</button>
          </div>
        </li>
        <li class="border shadow border-gray-100 rounded-md p-2  opacity-60 w-64 my-3 md:mb-0 pointer-events-none">
          <h2 class="text-medium text-md text-center mt-2">Event</h2>
          <div class="flex items-center justify-center py-2">
            <span class="inline-block relative">
              <img class="h-16 w-16 rounded-full" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fevent.jpg?alt=media&token=d2c5aa3a-295f-4ddb-bddb-6d014474d30a" alt="">
            </span>
          </div>
          <p class="text-xs text-gray-400 text-center">Events that requiure a bit more structure</p>
          <div class="flex items-center justify-center py-3">
          <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"> coming soon </span>
          </div>
          <div class="w-full flex items-center justify-center">
            <button class="wild-btn-light w-full">Select</button>
          </div>
        </li> -->
    </ul>
  </div>


  `
})
export class AppointmentContactExportComponent implements OnInit {

  @Input() id: string;

  constructor(
    private modalService: ModalService,
    private businessActivityService: BusinessActivitiesService,
    private notifyService: NotifyService) { }

  header = "Export Activity Contacts"
  loading: boolean;

  ngOnInit(): void {

  }

  csv() {
    this.modalService.hide();
    var settings: ModalModel = {
      design: ModalDesign.LOADING,
      header: "Exporting...",
      close: true
    }
    this.modalService.show(settings);

    this.businessActivityService.exportCSV(this.id)
      .then(async (response: any) => {
        this.success(response.url);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Export Error", message, NotifyType.ERROR);
  }



  async success(url: string) {
    window.location.assign(url)
    this.notifyService.notify("Export Generated", "", NotifyType.SUCCESS);
  }

}
