import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessPublicModel } from '../../../data/models/business_public_model';
import { OrderModel } from '../../../data/models/order_model';
import { ErrorModel } from '../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { Location } from '@angular/common'
import { BookingService } from 'src/booking/services/booking_service';
import { UserShoppingCartDTO } from '../../../data/dtos/booking/UserShoppingCartDTO';
import { LinkedAccountsService } from 'src/wild/modules/user/services/linkedaccounts_service';
import { LinkedAccountDTO } from '../../../data/dtos/linkedaccounts/LinkedAccountDTO';
import { BookingProfileFormForLinkedAccountComponent } from 'src/shared/components/bookingprofileforlinkedaccount.component';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { UpdateOrderLineAttendeeDTO } from '../../../data/dtos/booking/UpdateOrderLineAttendeeDTO';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BookingProfileFormComponent } from 'src/shared/components/bookingprofile.component';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { UserOrderLineDetailsDTO } from '../../../data/dtos/booking/UserOrderLineDetailsDTO';
import { UserModel } from '../../../data/models/user_model';
import { UserOrderDetailsDTO } from '../../../data/dtos/booking/UserOrderDetailsDTO';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { RemoveOrderLineDTO } from '../../../data/dtos/booking/RemoveOrderLineDTO';
@Component({
  templateUrl: './bookinguserdetailsforlinkedaccounts.component.html'
})
export class BookingUserDetailsForLinkedAccountsComponent implements OnInit {

  business: BusinessPublicModel;
  order$: Observable<OrderModel>;
  carts: UserShoppingCartDTO;
  error: ErrorModel;
  loadingPage: boolean;
  attendeeOrderlineCount: number = 0;
  businessId: string;
  linkedaccounts: LinkedAccountDTO[];
  imagesUrl = environment.imagesUrl;
  user: UserModel;
  goingToCheckout: boolean;

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotifyService,
    private linkedAccountService: LinkedAccountsService,
    private bookingService: BookingService,
    private userService: UserService,
    private navigate: NavigationService,
    private modalService: ModalService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.businessId = params.businessId;
    });
    this.load(true);
  }

  goToCart() {
    this.navigationService.navigate(WildNavigationType.BOOKINGBASKET)
  }

  goToCheckout() {
    if (this.goingToCheckout == true)
      return;
    this.goingToCheckout = true;
    this.bookingService.getBasketOrders(this.businessId).toPromise()
      .then((carts) => {
        var orderDeets: UserOrderDetailsDTO = carts.carts[0];
        if (orderDeets.promptForBookerDetailsWhenBookingOnBehalfOfOthers == true) {
          this.navigate.navigate(WildNavigationType.BOOKERDETAILS, {
            "businessId": this.businessId
          })
        } else {
          this.navigate.navigate(WildNavigationType.BOOKINGCHECKOUT, {
            "businessId": this.businessId
          })
        }
        this.goingToCheckout = false;
      })
      .catch((error) => {
        this.goingToCheckout = false;
      })
  }

  back() {
    this.location.back()
  }

  async updateAttendee(selectEvent: any, orderLineId: string) {
    var linkedAccountId = selectEvent.target.value;
    if (linkedAccountId != "") {
      if (linkedAccountId == "addnew") {
        this.newLinkedAccount(orderLineId);
        selectEvent.target.value = "";
      } else if (linkedAccountId == "me") {
        await this.setAttendee(true, "me", orderLineId);
        this.load();
      } else {
        await this.setAttendee(false, linkedAccountId, orderLineId);
        this.load();
      }
    }
  }

  removeOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: orderLine.removeFromCartMessageDescription,
      header: orderLine.removeFromCartMessageHeader,
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemoveOrderLine(order, orderLine);
      }
    }
    this.modalService.show(settings);
  }


  async processRemoveOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      header: "Removing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var removeOrderLineDTO: RemoveOrderLineDTO = {
      businessId: order.businessId,
      orderLineId: orderLine.id
    }
    this.bookingService.removeOrderLine(removeOrderLineDTO)
      .then(async () => {
        this.modalService.hide();
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      });
  }

  membershipPriceInfo(orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
        close: true,
        large: false,
        hidectatwo:true,
        ctaonetext:"OK",
        design: ModalDesign.CENTRAL,
        type:ModalType.SUCCESS,
        header: "Membership Price",
        description: `This is the price you will pay as part of your membership. A discounted price of ${orderLine.formattedMemberPrice} will be applied at checkout providing criteria is met. For details about the fair use policy of this membership, click OK and scroll to the top of the page.`,
    }
    this.modalService.show(settings);
}

notSelectedAPerson(orderLine: UserOrderLineDetailsDTO):boolean {
  return orderLine.bookingForMe != true && !orderLine?.linkedAccount;
}

selectedAndMemberCriteriaMet(orderLine: UserOrderLineDetailsDTO) {
  return this.notSelectedAPerson(orderLine) && orderLine.criteriaMetForMembershipDiscount;
}
selectedAndMemberCriteriaNotMet(orderLine: UserOrderLineDetailsDTO) {
  return (orderLine.bookingForMe == true || orderLine?.linkedAccount) && !orderLine.criteriaMetForMembershipDiscount;
}

  async setAttendee(me: boolean, linkedAccountId: string, orderLineId: string) {
    var settings: ModalModel = {
      design: ModalDesign.LOADING,
      header: "Reserving",
    }
    this.modalService.show(settings);
    var dto: UpdateOrderLineAttendeeDTO = {
      businessId: this.businessId,
      linkedAccountUserId: linkedAccountId,
      me: me,
      orderLineId: orderLineId
    }
    this.bookingService.updateAttendee(dto)
      .then(() => {
        this.modalService.hide();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  editAttendee(orderLine: UserOrderLineDetailsDTO) {
    if (orderLine.linkedAccount != null && orderLine.linkedAccount != undefined) {
      this.editLinkedAccount(orderLine.linkedAccount.id, orderLine.id)
    } else {
      this.editMe(orderLine.id);
    }
  }

  editMe(orderLineId: string) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      header: "Update your details",
      large: true,
      component: BookingProfileFormComponent,
      componentOutputs: [{
        outputName: "updated",
        func: async () => {
          this.modalService.hide();
          await this.setAttendee(true, "me", orderLineId);
          this.load();
        }
      }]
    }
    this.modalService.show(settings);
  }

  newLinkedAccount(orderLineId: string) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      large: true,
      component: BookingProfileFormForLinkedAccountComponent,
      componentOutputs: [{
        outputName: "updated",
        func: async (linkedAccount: LinkedAccountDTO) => {
          this.modalService.hide();
          await this.setAttendee(false, linkedAccount.id, orderLineId)
          this.load();
        }
      }]
    }
    this.modalService.show(settings);
  }

  editLinkedAccount(linkedAcccountId: string, orderLineId: string) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      large: true,
      component: BookingProfileFormForLinkedAccountComponent,
      componentInputs: [
        {
          inputName: "id",
          value: linkedAcccountId
        }
      ],
      componentOutputs: [{
        outputName: "updated",
        func: async () => {
          this.modalService.hide();
          await this.setAttendee(false, linkedAcccountId, orderLineId)
          this.load();
        }
      }]
    }
    this.modalService.show(settings);
  }

  load(firstLoad: boolean = false) {
    if (firstLoad) {
      this.loadingPage = true;
    } else {
      var settings: ModalModel = {
        design: ModalDesign.LOADING,
        header: "Loading",
      }
      this.modalService.show(settings);
    }
    this.userService.getUser().toPromise().then((user) => {
      this.user = user;
      this.linkedAccountService.list().toPromise().then((accounts) => {
        this.linkedaccounts = accounts;
        this.activatedRoute.queryParams.subscribe((params: Params) => {
          this.businessId = params.businessId;
          this.bookingService.getBasketOrders(this.businessId).toPromise()
            .then((carts) => {
              this.carts = null;
              this.carts = carts;
              try {
                this.attendeeOrderlineCount = this.carts.carts[0].orderLines.filter(o => o.serviceId != undefined && o.serviceId != null && o.serviceId != "").length;
              } catch (error) { }
              if (firstLoad) {
                this.loadingPage = false;
              } else {
                setTimeout(() => {
                  this.modalService.hide();
                }, 2000);
              }
            })
            .catch((error) => {
              // this.navigate.navigate(WildNavigationType.BOOKINGBASKET);
            })
        });
      })
    })
  }


}
