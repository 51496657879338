import { Component, Input} from '@angular/core';
import { FeatureColour, FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';
import { BrochureSiteHelperService } from '../brochure_site_helper_service';

@Component({ 
  selector: 'app-rightimagefeature',
  templateUrl: './rightimagefeature.component.html'
}) 
export class RightImageFeatureomponent{
  constructor(private brochureSiteHelperService:BrochureSiteHelperService){}
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
} 
