import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { PayoutBalanceDTO } from '../../../../../../../data/dtos/business/PayoutBalanceDTO';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { PayoutsService } from 'src/wild/modules/business/services/payouts_service/payouts_service';
import { FinanceDashboardService } from '../services/finance_dashboard_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ExpressLinkComponent } from './express_link_component';


@Component({
  selector: 'app-businessstats',
  template: `
      <ng-container *ngIf=" balance$ | async as balance; else loadingOrError">
        <div *ngIf="!balance; else loaded">
          <app-loader [disablescreenheight]="true"></app-loader>
        </div> 
        <ng-template #loaded>
        <div>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt class="text-sm font-medium text-gray-500 truncate">
                        Pending Balance
                    </dt>
                    <p class="text-xs">Not yet cleared</p>
                    <dd class="mt-1 text-3xl font-semibold text-gray-900">
                        {{balance.pending | price: balance.currency}}
                    </dd>
                </div>
                <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt class="text-sm font-medium text-gray-500 truncate">
                        Available Balance
                    </dt>
                    <p class="text-xs">Paid within 7 days</p>
                    <dd class="mt-1 text-3xl font-semibold text-gray-900">
                        {{balance.available | price: balance.currency}}
                    </dd>
                </div>
                <div (click)="openDashboard()" class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 cursor-pointer hover:bg-gray-50 relative">
                    <dt class="text-sm font-medium text-gray-500 truncate">
                        Finance Dashboard
                    </dt>
                    <p class="text-xs">Detailed Reports</p>
                    <dd class="mt-1 text-sm font-semibold wild-text-primary flex items-center ">
                        <span>Open</span>
                        <fa-icon class="ml-2" icon="external-link-alt"></fa-icon>
                    </dd>
                </div>
            </dl>
        </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template> 
  `,
})
export class BusinessStatsComponent implements OnInit {

  constructor(private business: BusinessService, private payoutService: PayoutsService,
    private financeDashboardService: FinanceDashboardService,
    private modalService: ModalService,
    private notifyService: NotifyService
  ) { }

  public error: ErrorModel = null;
  balance$: Observable<PayoutBalanceDTO>;

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.loadBalance();
  }
  loadBalance() {
    this.balance$ = null
    this.balance$ = this.payoutService.getBalance().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }
  openDashboard() {
    var settings: ModalModel = {
      close: true,
      design: ModalDesign.COMPONENT,
      component: ExpressLinkComponent
    }
    this.modalService.show(settings);
  }

  handlerError(message: string) {
    this.notifyService.notify("Dashboard", message, NotifyType.ERROR);
  }

}
