import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType, CTAFUNCTION } from "../../FeaturesData";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { calendarComponent } from "../../shared/features/calendar";
import { organisedContacts } from "../../shared/features/organisedcontacts";
import { timetables } from "../../shared/features/timetables";
import { workingHours } from "../../shared/features/workinghours";
import { seoUseCaseDescription } from "../../shared/seo/seo_description_template_usecases";
import { seoUseCaseKeyWords } from "../../shared/seo/seo_keywords_template_usecases";
import { seoUseCaseTitle } from "../../shared/seo/seo_title_template_usecases";
import { swimSchoolListData } from "./swimschools_listdata";

const type = "Swim School";
export const swimSchoolsData:FeaturesData = { 
    seoTitle:seoUseCaseTitle(type),
    seoDescription:seoUseCaseDescription(type,"swimming"),
    seoImage:swimSchoolListData.image,
    seoKeyWords:seoUseCaseKeyWords(type,"Swimming Lesson"),
    page:swimSchoolListData.page,
    components:[ 
      {
        colour:FeatureColour.WHITE,
        type:FeatureComponentType.SIMPLEHERO,
        ctas:businessCTAs,
        title:`${type}s`,
        subtitle:swimSchoolListData.subtitle,
        paragraph:"Navigate pool times, team working hours & family bookings with ease.",
      },

      injectSharedComponent(calendarComponent, { 
          colour: FeatureColour.WHITE,
           type: FeatureComponentType.BIGIMAGEFEATURE,
           ctas:{
              secondary:{
              text:"Learn More",
              page:WildNavigationType.SCHEDULINGBOOKINGENGINE
            }
          } }),
        injectSharedComponent(timetables,
          {
          ctas:{secondary:{text:"Find out more",page:WildNavigationType.SCHEDULINGBOOKINGENGINE}},
          type:FeatureComponentType.BIGIMAGEFEATURE,
          colour:FeatureColour.WHITE}),  
        injectSharedComponent(workingHours, 
          { 
            ctas:{secondary:{text:"Find out more",page:WildNavigationType.SCHEDULINGBOOKINGENGINE}},
            colour: FeatureColour.WHITE, type: FeatureComponentType.BIGIMAGEFEATURE }),
        injectSharedComponent(
          organisedContacts, { 
            colour:FeatureColour.WHITE,
            ctas:{
              secondary:{
              text:"Learn More",
              page:WildNavigationType.BUSINESSCRM
            }}
          }),
        {
          colour:FeatureColour.WHITE,
          type:FeatureComponentType.FAQS,
          title:"",
          subtitle:"Frequently Asked Questions",
          data:[
            {
              title: "Can I sell season passes?",
              subtitle: "Yes, we call them class passes and you can bundle up services into packages. Like a course of 10 lessons for a small discount."
            },
            {
              title: "How do I deal with Team Member holidays?",
              subtitle: "You simply add holidays to the calendar and booking availabiltiy will be adjusted automatically."
            },
            {
              title: "Can parents book for their kids?",
              subtitle: "Yes, parents great they're own profile and one for as many kids as they like. When you receive a child booking, you will have access to the child's information as well as the parenets contact details."
            },
            {
              title: "Do you provide training",
              subtitle: "Yes, we can provide system training and water safety advice if you need that too."
            },
          ...commonBusinesFAQs
        ],
        ctas: businessCTAs,
      },
  ] } 
