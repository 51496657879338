import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessContactSignedWaiversDTO } from '../../../../../../../data/dtos/waiver/BusinessContactSignedWaiversDTO';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { PhoneModel } from '../../../../../../../data/models/phone_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Waiver" parapraph=""></app-modaltopper>
  <h1 class="font-semibold text-sm mt-3">Signed By</h1>
  <p class="text-sm">{{waiver.printedName}}</p>
  <h1 class="font-semibold text-sm mt-3">Signed On:</h1>
  <p class="text-sm">{{waiver.readableDate}}</p>
  <h1 class="font-semibold text-sm mt-3">Signature:</h1>
  <div *ngIf="waiver.html" class="border border-gray-200 shadow-sm my-6 p-3 rounded-md">
    <img class="w-18 h-18" [src]="waiver.signatureBaseSixtyFour" alt="sig" />
  </div>
  <h1 class="font-semibold text-sm mt-3">Version</h1>
  <p class="text-sm">Version {{waiver.vesrion}} was signed</p>
  <h1 class="font-semibold text-sm mt-3">Exact Copy of what Was Signed:</h1>
  <div *ngIf="waiver.html" class="border border-gray-200 shadow-sm my-6 p-3 rounded-md" [innerHTML]="waiver.html"></div>
  <div class="my-6 flex items-center justify-center">
    <button (click)="close()" class="wild-btn-secondary">Close</button>
  </div>
  `
})
export class ViewContactWaiverComponent implements OnInit {

  @Input() waiver: BusinessContactSignedWaiversDTO;

  constructor(
    private modalService: ModalService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {

  }

  close() {
    this.modalService.hide();
  }


}
