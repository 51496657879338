import { Injectable } from '@angular/core';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { ActivityStatus } from '../../../../../../data/enums/activity_status';
import { Activity } from '../../../../../../data/models/activity_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service'; import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { ConvertActivityDTO } from '../../../../../../data/dtos/business/activities/ConvertActivityDTO';
import { Observable } from 'rxjs';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { BusinessActivityDetailsDTO } from '../../../../../../data/dtos/business/activities/BusinessActivityDetailsDTO';
import { BusinessUserModel } from '../../../../../../data/models/business_user_model';
import { BusinessContactWrapperDTO } from '../../../../../../data/dtos/contacts/BusinessContactWrapperDTO';

@Injectable({
    providedIn: 'root'
})
export class BusinessActivitiesService {

    constructor(
        private http: HttpService,
        private dates: DatesService,
        private notifyService: NotifyService,
    ) { }

    colors: any = {
        blue: {
            primary: '#031d44',
            secondary: '#dbeaf9',
        },
        transparent: {
            primary: 'transparent',
            secondary: 'transparent',
        },
        unbooked: {
            primary: 'rgb(235 235 235)',
            secondary: '#d3d3d3',
        },
    };

    getInitials(firstName: string, surname: string) {
        var initials = "";
        initials = firstName?.substring(0, 1) ?? "";
        initials = initials + surname?.substring(0, 1) ?? "";
        return initials.toUpperCase();
    }

    async getCalendarEvents(calendarStartDate: Date, calendarEndDate: Date, teamMembers: BusinessUserModel[], preLoadedTeamMembers: BusinessUserModel[]): Promise<CalendarEvent[]> {
        var events: CalendarEvent[] = [];
        try {
            var activities = await this.getActivities(calendarStartDate, calendarEndDate, teamMembers);
            var dayDiff = Math.abs(this.dates.diffInDays(calendarStartDate, calendarEndDate));
            for (let i = 0; i < activities.length; i++) {
                const activity = activities[i];
                var name = activity.name;
                if (activity?.contacts?.length == 1) {
                    name += ` with 1 person`;
                }
                if (activity?.contacts?.length == 0) {
                    name += ``
                }
                if (activity?.contacts?.length > 1) {
                    var contacts = activity.contacts.length;
                    name += ` with ${contacts} people`;
                }
                var imagesUrl = environment.imagesUrl;
                var teamHTML = "";
                if (dayDiff < 26) {
                    if (activity.allday == true) {
                        var teamHTML = `<div class="absolute bottom-1 right-1 flex">`;
                        var height = 5;
                    } else {
                        var teamHTML = `<div class="absolute bottom-1 left-1 flex">`;
                        var height = 7;
                    }

                    for (let y = 0; y < activity?.team.length; y++) {
                        const teamMemberModal = preLoadedTeamMembers.find(t => t.id == activity?.team[y]);
                        if (teamMemberModal?.image != null) {
                            var image = imagesUrl + teamMemberModal.image.image + '_100x100.jpeg?alt=media'
                            teamHTML += ` <span class="inline-flex items-center justify-center h-` + height + ` w-` + height + ` mr-1">
                                      <img class="h-` + height + ` w-` + height + ` rounded-full" src="` + image + `"  alt="">
                                    </span>`
                        } else {
                            teamHTML += `
                            <span class="inline-flex items-center justify-center h-`+ height + ` w-` + height + ` rounded-full bg-gray-500 mr-1">
                                <span class="text-xs font-medium leading-none text-white">
                                ` + this.getInitials(teamMemberModal?.firstName ?? "?", teamMemberModal?.surname ?? "?") + `
                                </span>
                            </span>`;
                        }
                    }
                }

                teamHTML += `</div>`;
                var actions: CalendarEventAction[] = [
                    {
                        label: teamHTML,
                        a11yLabel: 'Team',
                        onClick: ({ event }: { event: CalendarEvent }): void => {
                            // this.handleEvent('Edit', event);
                        },
                    },

                ];
                var activityStartDate = new Date(
                    this.dates.getYearUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic),
                    this.dates.getMonthUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic),
                    //important to use this as it can get the wrong day if not in UTC
                    this.dates.getCalendarDayOfMonthUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic),
                    this.dates.getHourUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic),
                    this.dates.getMinute(activity.startDateIso)
                );
                var activityEndDate = new Date(
                    this.dates.getYearUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic),
                    this.dates.getMonthUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic),
                    //important to use this as it can get the wrong day if not in UTC
                    this.dates.getCalendarDayOfMonthUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic),
                    this.dates.getHourUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic),
                    this.dates.getMinute(activity.endDateIso)
                );
                // DEBUGGING START
                // console.log(`START DATE FOR ${activity.name} START`)
                // console.log("IANA", activity.timezoneIANADynamic ?? "")
                // console.log("getMinute", this.dates.getMinute(activity.startDateIso))
                // console.log("getHourUsingTimeZone", this.dates.getHourUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic))
                // console.log("getCalendarDayOfMonthUsingTimeZone", this.dates.getCalendarDayOfMonthUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic))
                // console.log("getCalendarMonthUsingTimeZone", this.dates.getMonthUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic))
                // console.log("getYearUsingTimeZone", this.dates.getYearUsingTimeZone(activity.startDateIso, activity.timezoneIANADynamic))
                // console.log("activityStartDate", activityStartDate)
                // console.log(`START DATE FOR ${activity.name} END`)
                // console.log(`END DATE FOR ${activity.name} START`)
                // console.log("getMinute", this.dates.getMinute(activity.endDateIso))
                // console.log("getHourUsingTimeZone", this.dates.getHourUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic))
                // console.log("getCalendarDayOfMonthUsingTimeZone", this.dates.getCalendarDayOfMonthUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic))
                // console.log("getCalendarMonthUsingTimeZone", this.dates.getMonthUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic))
                // console.log("getYearUsingTimeZone", this.dates.getYearUsingTimeZone(activity.endDateIso, activity.timezoneIANADynamic))
                // console.log("activityEndDate", activityEndDate)
                // console.log(`END DATE FOR ${activity.name} END`)
                // DEBUGGING END
                var event: CalendarEvent =
                {
                    cssClass: "cursor-pointer",
                    start: activityStartDate,
                    end: activityEndDate,
                    title: name,
                    id: activity.id,
                    meta: activity,
                    color: activity.status == ActivityStatus.GROUPUNBOOKED ? this.colors.unbooked : this.colors.blue,
                    actions: actions,
                    allDay: activity?.allday ?? false,
                    resizable: {
                        beforeStart: activity.status == ActivityStatus.GROUPUNBOOKED ? false : true,
                        afterEnd: activity.status == ActivityStatus.GROUPUNBOOKED ? false : true,
                    },
                    draggable: activity.status == ActivityStatus.GROUPUNBOOKED ? false : true,
                }
                events.push(event);
            }
            return events;
        } catch (error) {
            this.notifyService.notify("Error Loading Activities", error?.message ?? "Something went wrong", NotifyType.ERROR);
        }

    }


    getActivity(id: string) {
        return this.http.get(`${environment.api}/business/activities/${id}`)
    }

    getActivityV2(id: string): Observable<BusinessActivityDetailsDTO> {
        return this.http.get(`${environment.api}/business/activities/v2/${id}`)
    }

    getActivitiesV2(date: Date): Observable<BusinessActivityDetailsDTO[]> {
        var iso = this.dates.getIsoDateFromObject(date);
        return this.http.get(`${environment.api}/business/activities/v2/details?date=${iso}`)
    }

    getActivityContacts(id: string): Observable<BusinessContactWrapperDTO[]> {
        return this.http.get(`${environment.api}/business/activities/${id}/contacts`)
    }

    getActivityTeam(id: string): Observable<BusinessUserModel[]> {
        return this.http.get(`${environment.api}/business/activities/${id}/team`)
    }

    exportCSV(id: string): Promise<any> {
        var url = `${environment.api}/business/activities/${id}/csv`;
        return this.http.get(url).toPromise()
    }

    async convert(activityId: string, dto: ConvertActivityDTO) {
        await this.http.post(`${environment.api}/business/activities/${activityId}/convert`, dto).toPromise();
    }

    async getActivities(startDate: Date, endDate: Date, teamMembers: BusinessUserModel[]): Promise<Activity[]> {
        var startDateIso = this.dates.getIsoDateFromObject(startDate);
        var endDateIso = this.dates.getIsoDateFromObject(endDate);
        var url = `${environment.api}/business/activities?startDate=${startDateIso}&endDate=${endDateIso}`;
        url = this.addTeamMemberIdsToUrl(url, teamMembers);
        var response = await this.http.get(url).toPromise();
        return response.results;
    }

    async getActivitiesDetails(startDate: Date, endDate: Date, teamMembers: BusinessUserModel[]): Promise<BusinessActivityDetailsDTO[]> {
        var startDateIso = this.dates.getIsoDateFromObject(startDate);
        var endDateIso = this.dates.getIsoDateFromObject(endDate);
        var url = `${environment.api}/business/activities/details?startDate=${startDateIso}&endDate=${endDateIso}`;
        url = this.addTeamMemberIdsToUrl(url, teamMembers);
        var response = await this.http.get(url).toPromise();
        return response.results;
    }

    addTeamMemberIdsToUrl(url: string, teamMembers: BusinessUserModel[]): string {
        var teamMemberIds = '';
        for (let i = 0; i < teamMembers.length; i++) {
            const teamMember = teamMembers[i];
            teamMemberIds += teamMember.id + ',';
        }
        if (teamMemberIds.length > 0)
            teamMemberIds = teamMemberIds.substring(0, teamMemberIds.length - 1)
        url += `&teamMemberIds=${teamMemberIds}`
        return url;
    }

}

