import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertStatus } from '../../../../../data/enums/alert_status';
import { CommentStatus } from '../../../../../data/enums/comment_status';
import { FavouritesStatus } from '../../../../../data/enums/favourites_status';
import { ImageStatus } from '../../../../../data/enums/image_status';
import { PostStatus } from '../../../../../data/enums/post_status';
import { ReviewStatus } from '../../../../../data/enums/review_status';
import { SourceDocumentType } from '../../../../../data/enums/source_document_type';
import { SwimSpotStatus } from '../../../../../data/enums/swimspot_status_enum';
import { AlertModel } from '../../../../../data/models/alert_model';
import { CommentModel } from '../../../../../data/models/comment_model';
import { Favourite } from '../../../../../data/models/favourites_model';
import { ImageModel } from '../../../../../data/models/images_model';
import { NewPostDTO } from '../../../../../data/models/newPost_dto_model';
import { PostModel } from '../../../../../data/models/post_model';
import { Review } from '../../../../../data/models/review_model';
import { SwimSpot } from '../../../../../data/models/swimspot_model';
import { FirestoreQueryType } from '../../../../../data/enums/firestore_query_type';
import { ErrorModel } from '../../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { SwimSpotWrapper } from '../../../../../data/models/swimspot_wrapper';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SwimspotsdataService {

  collection: string = "swimspots";

  constructor(
    private http: HttpService,
    private auth: AuthenticationService,
    private firestore: FirestoreService
  ) { }

  getByCanonicalId(canonical: string): Observable<SwimSpot> {
    return this.firestore.getDocumentByQuery("swimspots", [{
      property: "canonical",
      operator: "==",
      type: FirestoreQueryType.WHERE,
      value: canonical
    },
    {
      property: "status",
      operator: "==",
      type: FirestoreQueryType.WHERE,
      value: SwimSpotStatus.LIVE
    }]).pipe(
      catchError((error: ErrorModel) => {
        return throwError(error);
      }),
      map(a => {
        return a as SwimSpot;
      }))
  }

  getByCanonicalIdV2(canonical: string): Observable<SwimSpotWrapper> {
    //Removed auth as page needs user resolver and there is going to be no authenticated content needed
    // if (this.auth.isLoggedIn()) {
    // return this.http.get(`${environment.api}/user/swimspot/canonical/auth/${canonical}`);
    // } else {
    return this.http.get(`${environment.api}/user/swimspot/canonical/${canonical}`, true);
    // }
  }

  read(id: string): Observable<SwimSpot> {
    return this.firestore.getDocumentById(this.collection, id).pipe(map((a: any) => {
      return a as SwimSpot;
    }));
  }

  photos(id: string): Observable<ImageModel[]> {
    return this.firestore.getListByQuery("images", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "sourceDocumentId",
        value: id
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "sourceDocumentImageType",
        value: SourceDocumentType.SWIMSPOT
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: ImageStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

  reviews(id: string): Observable<Review[]> {
    return this.firestore.getListByQuery("reviews", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "swimSpotId",
        value: id
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: ReviewStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

  comments(id: string): Observable<CommentModel[]> {
    return this.firestore.getListFromSubcollectionByQuery("posts", id, "comments", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: CommentStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

  posts(id: string): Observable<PostModel[]> {
    return this.firestore.getListByQuery("posts", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "sourceDocumentId",
        value: id
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "sourceDocumentType",
        value: SourceDocumentType.SWIMSPOTPOST
      }, {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: PostStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creationDate",
        value: "desc"
      }
    ])
  }

  async addSwimSpot(swimSpotData: any): Promise<string> {
    var userId = this.auth.getLoggedInCookie();
    var data = {
      creation_date: new Date(),
      contributors: [userId],
      waterwayId: "",
      status: SwimSpotStatus.INREVIEW,
      createdBy: userId,
      ...swimSpotData
    }
    return await this.firestore.create("swimspots", data);
  }

  async addReview(swimSpotId: string, reviewFormData: any) {
    var userId = this.auth.getLoggedInCookie();
    await this.firestore.create("reviews", {
      swimSpotId: swimSpotId,
      status: ReviewStatus.LIVE,
      createdBy: userId,
      ...reviewFormData
    })
  }

  async addAlert(swimSpotId: string, alertFormData: any) {
    var userId = this.auth.getLoggedInCookie();
    await this.firestore.create("alerts", {
      swimSpotId: swimSpotId,
      creation_date: new Date(),
      status: AlertStatus.LIVE,
      createdBy: userId,
      ...alertFormData
    })
  }

  alerts(id: string): Observable<AlertModel[]> {
    return this.firestore.getListByQuery("alerts", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "swimSpotId",
        value: id
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: AlertStatus.LIVE
      },
      {
        type: FirestoreQueryType.ORDERBY,
        operator: "==",
        property: "creation_date",
        value: "desc"
      }
    ])
  }

  getFavourite(swimSpotId: string): Observable<Favourite> {
    var userId = this.auth.getLoggedInCookie();
    return this.firestore.getDocumentByQuery("favourites", [
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "swimSpotId",
        value: swimSpotId
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "createdBy",
        value: userId
      },
      {
        type: FirestoreQueryType.WHERE,
        operator: "==",
        property: "status",
        value: FavouritesStatus.LIVE
      },
    ])
  }

  async addToFavourites(swimSpotId: string, swimSpot: SwimSpot) {
    var userId = this.auth.getLoggedInCookie();
    await this.firestore.create("favourites", {
      swimSpotId: swimSpotId,
      creation_date: new Date(),
      status: FavouritesStatus.LIVE,
      createdBy: userId,
      swimSpot: swimSpot
    })
  }

  async removeFromFavourites(favouriteId: string) {
    await this.firestore.update(favouriteId, "favourites", {
      status: FavouritesStatus.DELETE
    })
  }


}
