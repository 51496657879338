<app-wild-header></app-wild-header>
<app-headersection prefix="👋" header="CONTACT" description="Drop us a message anytime"></app-headersection>
<!-- Header -->
<div class="bg-gray-50">
  <div class="py-24 lg:py-32">
    <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
      <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Get in touch</h1>
      <p class="mt-6 text-xl text-gray-500 max-w-3xl">If you need support, have feedback or a great idea for the app,
        we'd love to hear from you.</p>
    </div>
  </div>
</div>

<!-- Contact section -->
<section class="relative bg-white" aria-labelledby="contact-heading">
  <div class="absolute w-full h-1/2 bg-gray-50" aria-hidden="true"></div>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="relative bg-white shadow-xl">

      <div class="grid grid-cols-1 lg:grid-cols-3">
        <!-- Contact information -->
        <div class="relative overflow-hidden py-10 px-6 wild-bg-primary sm:px-10 xl:p-12">
          <!-- Decorative angle backgrounds -->
          <div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
            <svg class="absolute inset-0 w-full h-full" width="343" height="388" viewBox="0 0 343 388" fill="none"
              preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
              <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)"
                fill-opacity=".1" />
              <defs>
                <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#fff"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
            aria-hidden="true">
            <svg class="absolute inset-0 w-full h-full" width="359" height="339" viewBox="0 0 359 339" fill="none"
              preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
              <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)"
                fill-opacity=".1" />
              <defs>
                <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#fff"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block" aria-hidden="true">
            <svg class="absolute inset-0 w-full h-full" width="160" height="678" viewBox="0 0 160 678" fill="none"
              preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
              <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)"
                fill-opacity=".1" />
              <defs>
                <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#fff"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h3 class="text-lg font-medium text-white">Contact information</h3>
          <p class="mt-6 text-base text-blue-50 max-w-3xl">We'll get back to you within 48 hours.</p>
          <dl class="mt-8 space-y-6">
            <!-- <dd class="flex text-base text-blue-50">
              <svg class="flex-shrink-0 w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              <a class="ml-3" href="tel:07916087551">+447916087551</a>
            </dd> -->
            <dd class="flex text-base text-blue-50">
              <svg class="flex-shrink-0 w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <a class="ml-3" href="mailto:support@wildopenwater.com">support@wildopenwater.com</a>
            </dd>
          </dl>
          <ul class="mt-8 flex space-x-12" role="list">
            <li>
              <a class="text-white hover:text-blue-100" href="https://facebook.com/swimwildapp/" target="_blank">
                <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" target="_blank">
                  <path fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a class="text-white hover:text-blue-100" href="https://instagram.com/swimwildapp/">
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </li>
          </ul>
        </div>

        <!-- Contact form -->

        <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
          <h3 class="text-lg font-medium text-gray-900">Send us a message</h3>
          <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)"
            class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
              <label for="first-name" class="block text-sm font-medium text-gray-900">First name</label>
              <div class="mt-1">
                <input formControlName="firstName" id="firstName" type="text" name="firstName" autocomplete="given-name"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-900 focus:border-blue-900 border-gray-300 rounded-md">
              </div>
            </div>
            <div>
              <label for="last-name" class="block text-sm font-medium text-gray-900">Last name</label>
              <div class="mt-1">
                <input type="text" formControlName="surname" id="surname" autocomplete="family-name"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-900 focus:border-blue-900 border-gray-300 rounded-md">
              </div>
            </div>
            <div>
              <label for="email" class="block text-sm font-medium text-gray-900">Email</label>
              <div class="mt-1">
                <input formControlName="email" id="email" type="email" autocomplete="email"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-900 focus:border-blue-900 border-gray-300 rounded-md">
              </div>
            </div>
            <div>
              <div class="flex justify-between">
                <label for="phone" class="block text-sm font-medium text-gray-900">Phone</label>
                <span id="phone-optional" class="text-sm text-gray-500">Optional</span>
              </div>
              <div class="mt-1">
                <input type="text" formControlName="phone" id="phone" autocomplete="tel"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-900 focus:border-blue-900 border-gray-300 rounded-md"
                  aria-describedby="phone-optional">
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="subject" class="block text-sm font-medium text-gray-900">Subject</label>
              <div class="mt-1">
                <input formControlName="subject" id="subject" name="subject"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-900 focus:border-blue-900 border-gray-300 rounded-md">
              </div>
            </div>
            <div class="sm:col-span-2">
              <div class="flex justify-between">
                <label for="message" class="block text-sm font-medium text-gray-900">Message</label>
                <span id="message-max" class="text-sm text-gray-500">Max. 500 characters</span>
              </div>
              <div class="mt-1">
                <textarea formControlName="message" id="message" name="message" rows="4"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-900 focus:border-blue-900 border border-gray-300 rounded-md"
                  aria-describedby="message-max"></textarea>
              </div>
            </div>
            <div class="sm:col-span-2 sm:flex sm:justify-end">
              <app-loader *ngIf="loading"></app-loader>
              <button *ngIf="!loading && !sent" type="submit"
                class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white wild-bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:w-auto">
                Submit
              </button>
              <div *ngIf="sent">
                <p class="font-bold text-lg">Thanks! We'll be in touch as soon as we can.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
<!-- Contact grid -->
<!-- <section aria-labelledby="offices-heading">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
      <h2 id="offices-heading" class="text-3xl font-extrabold text-gray-900">Our offices</h2>
      <p class="mt-6 text-lg text-gray-500 max-w-3xl">Our team works remotely.</p>
      <div class="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
        <div>
          <h3 class="text-lg font-medium text-gray-900">Birmingham, UK</h3>
          <p class="mt-2 text-base text-gray-500">
            <span class="block">Sutton Coldfield</span>
            <span class="block">Birmingham, UK</span>
          </p>
        </div>
      </div>
    </div>
  </section> -->