import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { ErrorModel } from '../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { Location } from '@angular/common'
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { UserModel } from '../../../data/models/user_model';
import { catchError, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/shared/services/util/util_service';
import { SelectModel } from 'src/shared/components/select/select_model';
import { PersonalModel } from '../../../data/models/personal_model';
import { PhoneModel } from '../../../data/models/phone_model';

@Component({
  templateUrl: './bookerdetailsifmissing.component.html'
})
export class BookerDetailsIfMissing implements OnInit {

  error: ErrorModel;
  loading: boolean;
  businessId: string;
  form!: UntypedFormGroup;
  user$: Observable<UserModel>;

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotifyService,
    private fb: UntypedFormBuilder,
    private util: UtilService,
    private userService: UserService,
    private navigate: NavigationService,
    private location: Location
  ) { }

  load() {
    this.user$ = null
    this.user$ = this.userService.getUser().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  codeOptions: SelectModel[] = [
    {
      name: "+44",
      value: "+44"
    }
  ]

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.businessId = params.businessId;
    });
    this.load();
    this.form = this.fb.group({
      //personal
      firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      surname: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      pronouns: new UntypedFormControl('', []),
      //phone
      code: new UntypedFormControl('+44', [Validators.required]),
      number: new UntypedFormControl('', [Validators.required, this.util.phoneValidator]),

    });
  }

  goToCart() {
    this.navigationService.navigate(WildNavigationType.BOOKINGBASKET)
  }

  goToCheckout() {
    this.navigate.navigate(WildNavigationType.BOOKINGCHECKOUT, {
      "businessId": this.businessId
    })
  }

  back() {
    this.location.back()
  }

  onSubmit(data: any) {
    this.loading = true;
    var personal: PersonalModel = {
      pronouns: data.pronouns,
      firstName: data.firstName,
      surname: data.surname
    }
    var phone: PhoneModel = {
      code: data.code,
      number: data.number
    }
    this.userService.updateBasicBookingDetails(personal, phone)
      .then(() => {
        this.goToCheckout();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }


  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }




}
