<app-wild-header-checkout [businessId]="businessId" [hideCartIcon]="true" [hideCountdown]="true" backText="Back"
  (onBackClick)="back()">
</app-wild-header-checkout>
<div class="bg-white">
  <div class="flex items-center justify-center pt-4">
    <nav aria-label="Progress">
      <ol role="list" class="flex items-center">
        <li (click)="goToCart()" class="cursor-pointer relative pr-8 sm:pr-20">
          <!-- Completed Step -->
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full wild-bg-primary"></div>
          </div>
          <a class="relative w-8 h-8 flex items-center justify-center wild-bg-primary rounded-full hover:bg-indigo-900">
            <!-- Heroicon name: solid/check -->
            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Step 2</span>
          </a>
        </li>
        <li (click)="goToUserDetails()" class="cursor-pointer relative pr-8 sm:pr-20">
          <!-- Completed Step -->
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full wild-bg-primary"></div>
          </div>
          <a class="relative w-8 h-8 flex items-center justify-center wild-bg-primary rounded-full hover:bg-indigo-900">
            <!-- Heroicon name: solid/check -->
            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Step 2</span>
          </a>
        </li>
        <li class="relative">
          <!-- Current Step -->
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200"></div>
          </div>
          <a class="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-900 rounded-full"
            aria-current="step">
            <span class="h-2.5 w-2.5 wild-bg-primary rounded-full" aria-hidden="true"></span>
            <span class="sr-only">Step 3</span>
          </a>
        </li>

      </ol>
    </nav>
  </div>
  <div class="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <h1 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Checkout</h1>
    <div *ngIf="!order && loadingStripe">
      <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <!-- DO NOT USE NGIF HERE SO NATIVE ELEMENTS CAN BE TARGETED BY STRIPE -->
    <div [ngClass]="{'hidden': !order}">
      <h2 *ngIf="order?.business" class="text-xl font-semibold mb-1 mt-1">Booking with {{order?.business}}</h2>
      <div *ngIf="order?.expired">
        <h2 class="text-sm font-medium text-red-500">Order Expired</h2>
        <p (click)="expiredCTA()" class="text-gray-400 text-xs underline cursor-pointer">{{expiredMessage}}</p>
      </div>
      <p *ngIf="!order?.expired" class="">
        <span *ngIf="!order?.countdown" class="text-gray-400 text-xs">...loading</span>
        <span *ngIf="order?.countdown" class="text-gray-400 text-xs">reserved for</span>
        <span *ngIf="order?.countdown" class="text-sm font-medium text-red-500"> {{order?.countdown ?? ''}}</span>
      </p>

      <div [ngClass]="{'hidden': order?.expired}" class="mt-10">
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
          <!-- SECTION ON THE LEFT START -->
          <section aria-labelledby="cart-heading" class="lg:col-span-7">
            <section aria-labelledby="payment-heading" class="flex-auto md:pr-1">
              <!-- FREE checkout   START-->
              <div [ngClass]="{'block': checkoutForFree,'hidden': !checkoutForFree}">
                <div class="flex-grow mx-auto max-w-7xl w-full flex flex-col text-center">
                  <div class="flex-shrink-0 my-auto pt-8 pb-4 sm:pt-16 sm:pb-4">
                    <p class="text-sm font-semibold wild-text-primary uppercase tracking-wide">FREE</p>
                    <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">This Order is Free
                    </h1>
                    <p class="mt-2 text-base text-gray-500">Please confirm your booking below</p>
                  </div>
                </div>
                <div class="w-full items-center justify-center flex">
                  <button (click)="confirmFreeOrder()" class="wild-btn-primary w-full mt-6 mx-auto block"
                    style="max-width: 300px;">
                    <span *ngIf="!processingPayment">Confirm Booking</span>
                    <span *ngIf="processingPayment" class="loader mx-auto block"></span>
                  </button>
                </div>
              </div>
              <!-- FREE checkout END-->
              <!-- Checkout form  START-->
              <div [ngClass]="{'block': !checkoutForFree,'hidden': checkoutForFree}">
                <div *ngIf="loadingStripe">
                  <app-loader [disablescreenheight]="true"></app-loader>
                </div>
                <div class="mx-auto">
                  <form #paymentform>
                    <div style="min-height: 148px;" #paymentelement>
                      <!-- Elements will create form elements here -->
                    </div>
                    <div *ngIf="!loadingPage && !loadingStripe" class="w-full flex items-center justify-center">
                      <button class="wild-btn-primary w-full mt-6 mx-auto block" id="submit" style="max-width: 300px;">
                        <span *ngIf="!processingPayment">Pay {{order?.formattedGrandTotal}}</span>
                        <span *ngIf="processingPayment" class="loader mx-auto block"></span>
                      </button>
                    </div>
                    <span class="text-xs text-gray-400 text-center mx-auto block mt-5">by booking you accept these <a
                        class="underline font-medium" [href]="termsUrl" target="_blank">terms & conditions</a></span>
                    <p *ngIf="!loadingPage && !loadingStripe"
                      class="flex justify-center text-sm font-medium text-gray-500 mt-6">
                      <!-- Heroicon name: solid/lock-closed -->
                      <svg class="w-5 h-5 text-gray-400 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd" />
                      </svg>
                      Payment secured by Stripe
                    </p>
                  </form>
                </div>
              </div>
              <!-- Checkout form  END-->
            </section>
          </section>
          <!-- SECTION ON THE LEFT END -->
          <!-- ORDER SUMMARY START -->
          <section aria-labelledby="summary-heading"
            class="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
            <h2 id="summary-heading" class="text-lg font-medium text-gray-900">Order summary</h2>
            <div *ngIf="loadingPage">
              <app-loader [disablescreenheight]="true"></app-loader>
            </div>
            <div *ngIf="!loadingPage">
              <ul role="list" class="divide-y divide-gray-200 border-b border-gray-200">
                <li *ngFor="let orderLine of order?.orderLines" class="flex py-6 space-x-6">
                  <img
                    [src]="orderLine?.image != null ? imagesUrl + orderLine?.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                    alt="Off-white t-shirt with circular dot illustration on the front of mountain ridges that fade."
                    class="flex-none w-24 h-24 object-center object-cover bg-gray-200 rounded-md">
                  <div class="flex flex-col justify-between space-y-4">
                    <div class="text-sm font-medium space-y-1">
                      <h3 class="text-gray-900">{{orderLine?.description}}</h3>
                      <p class="text-gray-900">{{orderLine?.formattedGrandTotal}}</p>
                      <div class="mb-2" *ngFor="let coupon of orderLine.coupons">
                        <p
                          class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                          {{coupon?.name}} (-{{coupon?.formattedDiscountPrice}})</p>
                      </div>
                      <p class="text-gray-500">{{orderLine?.priceDescription}}</p>

                      <!-- <p class="text-gray-500">L</p> -->
                    </div>
                    <div *ngIf="orderLine.activity || orderLine.courseScheduleId"
                      class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 mt-2">
                      Booking for <span *ngIf="!orderLine.linkedAccount">&nbsp;myself</span>
                      <span *ngIf="orderLine.linkedAccount">&nbsp;{{orderLine.linkedAccount?.firstName}}
                        {{orderLine.linkedAccount?.surname}}</span>
                    </div>
                    <div>
                      <div *ngIf="orderLine.criteriaMetForMembershipDiscount" 
                      class="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800 mt-2">
                      Membership Discount Applied
                    </div>
                    </div>  
                    <div *ngIf="orderLine.activity">
                      <div class="font-medium text-gray-700 hover:text-gray-800 text-sm mt-1">
                        {{orderLine.activity.readableLocalStartDateOnly}}
                      </div>
                      <p class="text-gray-500 text-sm">{{orderLine.activity.readableLocalTimeRange}}</p>
                      <div class="mt-2" *ngFor="let teamMember of orderLine.activity.teamMembers">
                        <span class="flex-shrink-0 group block">
                          <div class="flex items-center">
                            <div>
                              <img class="inline-block h-9 w-9 rounded-full"
                                [src]="teamMember?.image != null ? imagesUrl + teamMember?.image?.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                                alt="">
                            </div>
                            <div class="ml-3">
                              <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                {{teamMember?.firstName}} {{teamMember?.surname}}</p>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="flex space-x-4">
                      <button (click)="removeOrderLine(order, orderLine)" type="button"
                        class="text-sm font-light text-red-500 cursor-pointer">Remove</button>
                      <!-- <div class="flex border-l border-gray-300 pl-4">
                        <button (click)="removeOrderLine(orderLine)" type="button"
                          class="text-sm font-medium wild-text-primary cursor-pointer">Remove</button>
                      </div> -->
                    </div>
                  </div>
                </li>
              </ul>
              <!-- REMOVE VOUCHER START -->
              <div class="text-right" *ngIf="order.numberOfCouponsUsed > 0">
                <span
                  class="mt-4 inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-green-100 text-green-700">
                  Voucher Applied
                  <button (click)="removeVouchers()" type="button"
                    class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-green-400 hover:bg-indigo-200 hover:text-green-500 focus:outline-none focus:bg-green-500 focus:text-white">
                    <span class="sr-only">Remove large option</span>
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                  </button>
                </span>
              </div>
              <!-- REMOVE VOUCHER END -->
              <!-- APPLY VOUCHER START -->
              <div *ngIf="order.numberOfCouponsUsed == 0">
                <form [formGroup]="form" (ngSubmit)="submitVoucherCode(form.value)" class="mt-10">
                  <label for="discount-code-mobile" class="block text-sm font-medium text-gray-700">Voucher code</label>
                  <div class="flex space-x-4 mt-1">
                    <input autocomplete="off" formControlName="code" name="code" id="code" type="text"
                      placeholder="wild123456"
                      class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    <button type="submit"
                      class="w-20 bg-gray-200 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">
                      <span *ngIf="!loadingVoucherCodeForm">Apply</span>
                      <span *ngIf="loadingVoucherCodeForm" class="loader"></span>
                    </button>
                  </div>
                </form>
                <div>
                  <app-availablevouchers (apply)="applyVoucherCode($event)" [businessId]="order.businessId">
                  </app-availablevouchers>
                </div>
              </div>
              <!-- APPLY VOUCHER END -->

              <dl class="mt-4 space-y-4">
                <div class="flex items-center justify-between">
                  <dt class="text-sm text-gray-600">
                    Subtotal
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    {{order?.formattedTotal}}
                  </dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm text-gray-600">
                    Tax
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    {{order?.formattedTaxes}}
                  </dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm text-gray-600">
                    Fees
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    {{order?.formattedFees}}
                  </dd>
                </div>
                <div *ngIf="order?.formattedDiscount" class="flex items-center justify-between">
                  <dt class="text-sm text-gray-600">
                    Discount
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    {{order?.formattedDiscount}}
                  </dd>
                </div>
                <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
                  <dt class="text-base font-medium text-gray-900">
                    Order total
                  </dt>
                  <dd class="text-base font-medium text-gray-900">
                    {{order?.formattedGrandTotal}}
                  </dd>
                </div>
              </dl>
            </div>
          </section>
          <!-- ORDER SUMMARY END -->
        </div>
      </div>
    </div>
  </div>
</div>