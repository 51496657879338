import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BookingService } from 'src/booking/services/booking_service';
import { environment } from 'src/environments/environment';
import { AddToCartDTO } from '../../../../data/dtos/booking/AddToCartDTO';
import { AvailableCouponsCouponDTO } from '../../../../data/dtos/services/AvailableCouponsCouponDTO';
import { CategorisedServicesDTO } from '../../../../data/dtos/services/CategorisedServicesDTO';
import { ServiceAndPricesDTO } from '../../../../data/dtos/services/ServiceAndPricesDTO';
import { ServiceType } from '../../../../data/enums/service_type';
import { BusinessPublicModel } from '../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ServicesPublicService } from '../../../services/services_public_service';
import { ContinueShoppingComponent } from './continueshopping.component';
import { CouponReadMoreComponent } from './couponreadmore.component';
import { AvailableMembershipsDTO } from 'src/data/dtos/services/AvailableMembershipsDTO';
import { UserService } from 'src/wild/modules/user/services/user_service';

@Component({
    selector: "app-availableservicesv2",
    templateUrl: './availableservicesv2.component.html'
})
export class AvailableServicesV2Component implements OnInit {

    categorisedServices$: Observable<CategorisedServicesDTO>;
    imagesUrl = environment.imagesUrl;
    showwhatsincluded = false;
    @Input() businessId: string;
    @Input() isWidgetUser: boolean;
    @Input() business: BusinessPublicModel;
    error: ErrorModel;
    ServiceType = ServiceType;

    constructor(
        private modalService: ModalService,
        private userService:UserService,
        private notifyService: NotifyService,
        private authService: AuthenticationService,
        private router: Router,
        private bookingService: BookingService,
        private servicesPublicService: ServicesPublicService) { }

    ngOnInit(): void {
        this.load();
        if (this.isWidgetUser)
            this.publishHeight();
    }


    bookCoupon(coupon: AvailableCouponsCouponDTO) {
        if (this.authService.isAuthedIfNotShowPopUp()) {
            if (coupon.addingToCart)
                return;
            coupon.addingToCart = true;
            var addToCart: AddToCartDTO = {
                couponId: coupon.id,
                quantity: 1,
                slot: null,
                scheduled: null,
                businessId: coupon.businessId
            }
            this.bookingService.addToCart(addToCart)
                .then(() => {
                    this.addedToCart(coupon.businessId);
                })
                .catch((error) => {
                    this.handlerError(error.message);
                }).finally(() => {
                    coupon.addingToCart = false;
                });
        }
    }


    handlerError(message: string) {
        this.notifyService.notify("Error", message, NotifyType.ERROR);
    }

    addedToCart(businessId: string) {
        window.scrollTo(0, 0);
        var settings: ModalModel = {
            close: true,
            large: false,
            design: ModalDesign.COMPONENT,
            component: ContinueShoppingComponent,
            componentInputs: [
                {
                    inputName: "businessId",
                    value: businessId
                }
            ]
        }
        this.modalService.show(settings);
    }


    load() {
        this.categorisedServices$ = null;
        if(this.authService.isLoggedIn()){
            this.categorisedServices$ = this.servicesPublicService.getCategorisedServicesV2auth(this.businessId).pipe(
                take(1),
                catchError(err => {
                    this.error = err;
                    return throwError(err);
                })
            );
        }else{
            this.categorisedServices$ = this.servicesPublicService.getCategorisedServicesV2(this.businessId).pipe(
                take(1),
                catchError(err => {
                    this.error = err;
                    return throwError(err);
                })
            );
        }
    }

    readmoreCoupons(coupon: AvailableCouponsCouponDTO) {
        var settings: ModalModel = {
            close: true,
            large: true,
            design: ModalDesign.COMPONENT,
            component: CouponReadMoreComponent,
            componentInputs: [
                {
                    inputName: "coupon",
                    value: coupon
                }
            ]
        }
        this.modalService.show(settings);
    }

    readmore(service: ServiceAndPricesDTO) {
        this.router.navigate([this.business.url, "service", service.serviceId])
    }

    book(service: ServiceAndPricesDTO) {
        this.bookingService.book(service);
    }

    heightChecks = 0;
    totalHeightChecks = 10;

    publishHeight() {
        try {
            var actualHeight = this.getBodyHeight();
            var currentHeight = this.getViewPortHeight();
            if (Math.abs(actualHeight - currentHeight) > 15) {
                window.parent.postMessage({ message: "wildheight", value: actualHeight.toString() }, "*");
            }
            this.heightChecks++;
            if (this.heightChecks <= this.totalHeightChecks) setTimeout(() => {
                this.publishHeight();
            }, 1000);
        } catch (error) {
            console.log("Failed to set height check");
            console.log(error);
        }
    }


    getBodyHeight() {

        var height,
            scrollHeight,
            offsetHeight;

        if (document.body.scrollHeight) {
            height = scrollHeight = document.body.scrollHeight;
        }

        if (document.body.offsetHeight) {
            height = offsetHeight = document.body.offsetHeight;
        }

        if (scrollHeight && offsetHeight) {
            height = Math.max(scrollHeight, offsetHeight);
        }

        return height;
    }

    getViewPortHeight() {

        var height = 0;

        if (window.innerHeight) {
            height = window.innerHeight - 18;
        } else if ((document.documentElement) && (document.documentElement.clientHeight)) {
            height = document.documentElement.clientHeight;
        } else if ((document.body) && (document.body.clientHeight)) {
            height = document.body.clientHeight;
        }

        return height;

    }


}
