import { Component, Input} from '@angular/core';
import { FeatureColour, FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-simplehero',
  templateUrl: './simplehero.component.html'
})
export class SimpleHeroComponent {
  FeatureColour = FeatureColour;
  @Input() data: FeaturesComponentData;
} 
