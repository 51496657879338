<div class="py-8 relative" style="height:380px;max-height: 65vh;overflow-y: scroll;">
    <div>
        <ng-content></ng-content>
    </div>
</div>
<div class="pt-5">
    <div class="flex sm:justify-end flex-col-reverse sm:flex-row ">
        <button (click)="clickTwo()" type="button" class="w-full sm:w-auto wild-btn-light sm:mr-3 mt-3 sm:mt-0"
            style="min-width: 100px;">
            <span *ngIf="!loading">{{ctatwotext}}</span>
            <div *ngIf="loading" class="loader"></div>
        </button>
        <button (click)="clickOne()" class="wild-btn-primary w-full sm:w-auto" style="min-width: 100px;">
            <span *ngIf="!loading">{{ctaonetext}}</span>
            <div *ngIf="loading" class="loader"></div>
        </button>
    </div>
</div>