import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const recurringSchedulingOverview: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fscheduling%2Frecurring-min.png?alt=media&token=826ba669-e4a1-4025-b5aa-b49e8291c75e",
  title: "Groups",
  subtitle: "Ad-hoc or Repeating",
  paragraph: "Take bookings for groups of any size. Program simple or complex schedules.",
  data: [
    {
      icon: "infinity", title: "Recurring.", subtitle: "Daily, weekly or complex."
    },
    {
      icon: "infinity", title: "Repeat forever.", subtitle: "Or until a date."
    },
    {
      icon: "calendar", title: "Ad-hoc events.", subtitle: "Not a problem."
    },
    {
      icon: "mouse-pointer", title: "Calendar Scheduling.", subtitle: "Click date & time."
    },
    {
      icon: "mouse-pointer", title: "Drag & drop.", subtitle: "To rearrange."
    },
    {
      icon: "link", title: "Short Links.", subtitle: "For easy sharing."
    }
  ]
}