import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserActivitiesDTO } from '../../../../../../data/dtos/booking/UserActivitiesDTO';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { UserActivityService } from '../../../services/useractivity_service';
import { UserActivityDetailsDTO } from '../../../../../../data/dtos/booking/UserActivityDetailsDTO';
import { BusinessServiceLocationType } from '../../../../../../data/enums/business_service_location_type';
import { LinkedAccountDTO } from '../../../../../../data/dtos/linkedaccounts/LinkedAccountDTO';
import { LinkedAccountsService } from '../../../services/linkedaccounts_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-useractivities',
  templateUrl: './useractivities.component.html',
  styleUrls: ['./useractivities.component.scss']
})
export class UserActivitiesComponent implements OnInit {

  error: ErrorModel;
  linkedAccountId: string = "";
  activities$: Observable<UserActivitiesDTO>;
  activities: UserActivityDetailsDTO[] = [];
  hideshowmore: boolean;
  linkedaccounts$: Observable<LinkedAccountDTO[]>;
  imagesUrl = environment.imagesUrl;
  showUpcoming: boolean = true;
  isAppUser: boolean = false;
  BusinessServiceLocationType = BusinessServiceLocationType;

  constructor(
    private activityService: UserActivityService,
    private modalService: ModalService,
    private router: Router,
    private auth: AuthenticationService,
    private notifyService: NotifyService,
    private linkedAccountsService: LinkedAccountsService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.load("");
  }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  selectTab(showUpcoming: boolean) {
    this.resetResults();
    if (showUpcoming != this.showUpcoming) {
      this.showUpcoming = showUpcoming;
      this.load("");
    }
  }

  resetResults() {
    this.activities = [];
    this.hideshowmore = false;
  }

  async selectLinkedAccount(selectEvent: any) {
    this.resetResults();
    var linkedAccountId = selectEvent.target.value;
    if (linkedAccountId == "me") {
      this.linkedAccountId = "";
    } else {
      this.linkedAccountId = linkedAccountId;
    }
    this.load("");
  }

  loadMore() {
    this.load(this.activities[this.activities.length - 1].id);
  }

  load(startAfter: string) {
    var when = "";
    if (this.showUpcoming == true) {
      when = "upcoming";
    } else {
      when = "past";
    }

    var settings: ModalModel = {
      design: ModalDesign.LOADING,
      header: "Loading..."
    }
    this.modalService.show(settings);
    this.activityService.list(when, this.linkedAccountId, startAfter).toPromise().then((activities) => {
      if (activities.activities.length == 0)
        this.hideshowmore = true;
      this.activities = this.activities.concat(activities.activities);
    }).catch((error) => {
      this.handlerError(error.message);
    }).finally(() => {
      this.modalService.hide();
    })
    this.linkedaccounts$ = this.linkedAccountsService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  openActivity(activity: UserActivityDetailsDTO) {
    if (this.linkedAccountId != "" && this.linkedAccountId != null && this.linkedAccountId != undefined) {
      this.router.navigate(["/user", "activities", activity.id, this.linkedAccountId]);
    } else {
      this.router.navigate(["/user", "activities", activity.id]);
    }
  }


}
