import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { BusinessService } from '../services/business_service/business_service';

@Injectable({
  providedIn: 'root'
})
export class HasActiveBusinessGuard implements CanActivate {
  constructor(
    private businessService: BusinessService,
    private navigation: NavigationService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var activeBuiness = this.businessService.hasActiveBusiness();
    if (!activeBuiness) {
      this.navigation.navigate(WildNavigationType.GROUPS);
      return false;
    } else {
      return true;
    }
  }

}
