import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from 'src/portal/resolvers/user.resolver';
import { SwimspotdetailsComponent } from '../swimspots/components/swimspotdetails/swimspotdetails.component';
import { SwimSpotResolver } from '../swimspots/resolvers/swimspot.resolver';
import { BeaconComponent } from './pages/beacon/beacon.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoComponent } from './pages/demo/demo.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SearchComponent } from './pages/search/search.component';
import { SOSComponent } from './pages/sos/sos.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CustomTokenResolver } from 'src/shared/resolvers/customtoken.resolver';
import { DirectoryComponent } from './pages/directory/directory.component';
import { OpenWaterJournalComponent } from './pages/openwaterjournal/openwaterjournal.component';
import { GroupInviteComponent } from './pages/groupinvite/groupinvite.component';
import { FeaturesComponent } from './pages/features/features.component';
import { forBusinessData } from './feature_data/brochure/forbusiness_data';
import { aboutData } from './feature_data/brochure/about_data';
import { swimSchoolsData } from './feature_data/usecases/swimschool/swimschools';
import { surfSchoolData } from './feature_data/usecases/surfschool/surfschools';
import { supSchoolData } from './feature_data/usecases/supschool/supschools';
import { openWaterVenueData } from './feature_data/usecases/openwatervenue/openWaterVenue';
import { openWaterCoachData } from './feature_data/usecases/openwatercoach/openWaterCoach';
import { schedulingData } from './feature_data/features/scheduling/scheduling_data';
import { allFeaturesData } from './feature_data/features/allfeatures_data';
import { teamData } from './feature_data/features/team/team_data';
import { businessAppData } from './feature_data/features/businessapp/business_app_data';
import { crmData } from './feature_data/features/customers/customer_management';
import { communicationsData } from './feature_data/features/communications/communications_data';
import { bookingExperienceData } from './feature_data/features/bookingexperience/booking_experience_data';
import { ordersFinanceData } from './feature_data/features/finance/orders_finances_data';
import { reportingData } from './feature_data/features/reporting/reporting_data';
import { sailingSchoolData } from './feature_data/usecases/sailingschool/sailingschools';
import { realTimeSafetyData } from './feature_data/features/realtimesafety/realtimesafety_data';
import { SignWaiverComponent } from './pages/signwaiver/signwaiver.component';
import { waiversData } from './feature_data/features/waivers/waivers_data';
import { pricingData } from './feature_data/pricing/pricing_data';
import { cicPageData } from './feature_data/cicpage/cicpage_data';
import { wildproData } from './feature_data/wildpro/wildpro_data';
const routes: Routes = [
  //Sign Waiver
  {
    path: "signwaiver/:businessId/:contactId",
    component: SignWaiverComponent,
  },
  //BUSINESS MAIN PAGE
  {
    path: "forbusiness",
    resolve: {
      customtoken: CustomTokenResolver,
    },
    data: forBusinessData,
    component: FeaturesComponent,
  },
  //BUSINESS USE CASES
  {
    path: "swim-school-management-software",
    data: swimSchoolsData,
    component: FeaturesComponent,
  },
  {
    path: "surf-school-management-software",
    data: surfSchoolData,
    component: FeaturesComponent,
  },
  {
    path: "sup-school-management-software",
    data: supSchoolData,
    component: FeaturesComponent,
  },
  {
    path: "sailing-school-management-software",
    data: sailingSchoolData,
    component: FeaturesComponent,
  },
  {
    path: "open-water-venue-management-software",
    data: openWaterVenueData,
    component: FeaturesComponent,
  },
  {
    path: "open-water-coach-management-software",
    data: openWaterCoachData,
    component: FeaturesComponent,
  },
  //BUSINESS FEATURES
  {
    path: "all-features",
    data: allFeaturesData,
    component: FeaturesComponent,
  },
  {
    path: "realtime-water-safety-system",
    data: realTimeSafetyData,
    component: FeaturesComponent,
  },
  {
    path: "water-safety-waivers-system",
    data: waiversData,
    component: FeaturesComponent,
  },
  {
    path: "scheduling-booking-system-for-water-sports",
    data: schedulingData,
    component: FeaturesComponent,
  },
  {
    path: "team-management-for-water-sports-business",
    data: teamData,
    component: FeaturesComponent,
  },
  {
    path: "crm-customer-relationship-management-water-sports",
    data: crmData,
    component: FeaturesComponent,
  },
  {
    path: "communications-with-customers",
    data: communicationsData,
    component: FeaturesComponent,
  },
  {
    path: "customer-booking-experience",
    data: bookingExperienceData,
    component: FeaturesComponent,
  },
  {
    path: "managing-finance-and-orders-water-sports",
    data: ordersFinanceData,
    component: FeaturesComponent,
  },
  {
    path: "reporting-water-sports",
    data: reportingData,
    component: FeaturesComponent,
  },
  {
    path: "business-app",
    data: businessAppData,
    component: FeaturesComponent,
  },
  //GENERIC PAGES
  {
    path: "about",
    data: aboutData,
    component: FeaturesComponent
  },
  {
    path: "newsletter",
    component: NewsletterComponent
  },
  {
    path: "directory",
    component: DirectoryComponent
  },
  {
    path: "openwaterjournal",
    component: OpenWaterJournalComponent
  },
  {
    path: "swimspots/:country/:county/:town",
    component: DirectoryComponent
  },
  {
    path: "pricing",
    data: pricingData,
    component: FeaturesComponent,
  },
  {
    path: "cic",
    data: cicPageData,
    component: FeaturesComponent,
  },
  {
    path: "wildpro",
    data: wildproData,
    component: FeaturesComponent,
  },
  {
    path: "terms",
    component: TermsComponent
  },
  {
    path: "contact",
    component: ContactComponent
  },
  {
    path: "privacy",
    component: PrivacyComponent
  },
  {
    path: "demo",
    component: DemoComponent
  },
  {
    path: "search",
    resolve: {
      user: UserResolver
    },
    component: SearchComponent,
  },
  {
    path: "safeoutdoorswimming",
    component: SOSComponent,
  },
  {
    path: "swimspot/:canonical",
    resolve: {
      swimspotwrapper: SwimSpotResolver
    },
    data: { header: true },
    component: SwimspotdetailsComponent,
  },
  {
    path: "beacon/:id",
    data: { header: true },
    component: BeaconComponent,
  },
  {
    path: "groupinvite/:id",
    component: GroupInviteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BrochureRoutingModule { }