import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { StepsModel } from 'src/shared/components/steps/steps_model';
import { StepType } from 'src/shared/components/steps/step_type';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { StorageService } from 'src/shared/services/storage/storage.service';

@Component({
  selector: 'app-addswimspot',
  templateUrl: './addswimspot.component.html',
  styleUrls: ['./addswimspot.component.scss']
})
export class AddswimspotComponent implements OnInit {

  @Output() onCreate = new EventEmitter<string>();

  latlng: any;
  swimSpotId: string;
  steps: StepsModel[] = [
    {
      header: "Area",
      type: StepType.ACTIVE
    },
    {
      header: "Location",
      type: StepType.TODO,
      allowGoingBack: true
    },
    {
      header: "Details",
      type: StepType.TODO,
      allowGoingBack: true
    },
    {
      header: "Photos",
      type: StepType.TODO
    },
    {
      header: "Complete",
      type: StepType.TODO
    }
  ]

  get activeStep(): string {
    return this.steps.find(s => s.type == StepType.ACTIVE).header;
  }

  nextSubject: Subject<void> = new Subject<void>();
  previousSubject: Subject<void> = new Subject<void>();

  constructor(
    private navigation: NavigationService,
    private router: Router, private storage: StorageService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    if (this.storage.getCookie("swimspotlat") != "" && this.storage.getCookie("swimspotlng") != "")
      setTimeout(() => {
        this.areaConfirmed();
      }, 50)
  }

  hasUsername(): boolean {
    var hasUsername = this.auth.hasUsername();
    return hasUsername;
  }

  username() {
    this.navigation.navigate(WildNavigationType.USERNAMEPAGE)
  }

  areaConfirmed() {
    this.nextSubject.next();
  }

  locationConfirmed() {
    this.nextSubject.next();
  }

  goBackFromDetails() {
    this.previousSubject.next();
  }

  goBackFromLocation() {
    this.previousSubject.next();
  }

  detailsAdded(swimSpotId: string) {
    this.swimSpotId = swimSpotId;
    this.nextSubject.next();
  }

  created(swimSpotId: string) {
    this.onCreate.emit(swimSpotId);
  }

}
