import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { BookingService } from 'src/booking/services/booking_service';
import { BookingProfileFormComponent } from 'src/shared/components/bookingprofile.component';
import { BookingProfileFormForLinkedAccountComponent } from 'src/shared/components/bookingprofileforlinkedaccount.component';
import { JoinBusinessDTO } from '../../../../data/dtos/booking/JoinBusinessDTO';
import { JoinMembershipStatusDTO } from '../../../../data/dtos/booking/JoinMemberShipStatusDTO';
import { LinkedAccountDTO } from '../../../../data/dtos/linkedaccounts/LinkedAccountDTO';
import { JoinMembershipStatus } from '../../../../data/enums/join_membership_status';
import { ErrorModel } from '../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  template: `
  <app-modaltopper [header]="'Join ' + businessName"></app-modaltopper>
  <!-- <app-form [hidebuttons]="true" [form]="form" [fullwidthbutton]="false" disableDivide="true">
      <app-inputgroup>
        <app-input [disableAutoComplete]="true" [form]="form" type="search" name="search" description="Search" [span]=6 placeholder="Name, email, phone"></app-input>
      </app-inputgroup>
  </app-form>  -->
  <div *ngIf="members$">
    <ng-container *ngIf="members$ | async as members; else loadingOrError">
      <div *ngIf="!members || members.length == 0; else memberstable">
        <app-empty icon="members" header="No Users Found">
        </app-empty>
      </div>
      <ng-template #memberstable>
      <div class="mt-6 flow-root">
        <ul role="list" class="-my-5 divide-y divide-gray-200">
          <li *ngFor="let member of members" class="py-4">
            <div class="flex items-center space-x-4">
              <div class="flex-shrink-0">
              <span *ngIf="member.initials"
                          class="inline-flex items-center justify-center h-8 w-8 rounded-full wild-bg-primary">
                          <span
                              class="text-sm font-medium leading-none text-white">{{member.initials}}</span>
                      </span>
                <img *ngIf="!member.initials" class="h-8 w-8 rounded-full" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d" alt="">
              </div>
              <div class="min-w-0 flex-1">
                <p class="truncate text-sm font-medium text-gray-900">{{member.name}}</p> 
                <p *ngIf="!member.profileCompleted" class="truncate text-sm text-yellow-500">update profile to join</p>
                <p (click)="member.mainAccountHolder == true ? editMe() : editLinkedAccount(member.id)" *ngIf="member.profileCompleted" class="truncate text-xs text-blue-500 hover:underline cursor-pointer">edit profile</p>
              </div>
              <div>
                <a *ngIf="member.status != JoinMembershipStatus.JOINED" (click)="join(member)" class="cursor-pointer inline-flex items-center rounded-full border border-gray-300 px-2.5 py-0.5 text-sm font-medium leading-5 text-white shadow-sm hover:bg-black wild-bg-primary">
                  <span *ngIf="!member.profileCompleted">Update</span>
                  <span *ngIf="member.profileCompleted">Join</span>
                </a>
                <span *ngIf="member.status == JoinMembershipStatus.JOINED" class="text-xs font-medium leading-5 text-gray-500">
                  member
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-6">
        <a (click)="newLinkedAccount()" class="cursor-pointer flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50">Add Linked Account</a>
      </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `
})
export class JoinMemberListComponent implements OnInit {

  @Input() businessId: string;
  @Input() businessName: string;
  error: ErrorModel;
  JoinMembershipStatus = JoinMembershipStatus;
  @Output() updated = new EventEmitter();

  constructor(
    private bookingService: BookingService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  loading: boolean;
  members$: Observable<JoinMembershipStatusDTO[]>;

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.members$ = null;
    this.members$ = this.bookingService.members(this.businessId).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  join(member: JoinMembershipStatusDTO) {
    if (member.mainAccountHolder == true && member.profileCompleted != true) {
      this.editMe();
    } else if (member.mainAccountHolder == false && member.profileCompleted != true) {
      this.editLinkedAccount(member.id)
    } else if (member.status == JoinMembershipStatus.JOINED) {
      var settings: ModalModel = {
        header: member.name + " is already a member of " + this.businessName,
        description: this.businessName + " can now easily manage your future activities and you can make quick and easy bookings.",
        design: ModalDesign.CENTRAL,
        type: ModalType.SUCCESS,
        ctaonetext: "Got It!",
        hidectatwo: true
      }
      this.modalService.show(settings);
    } else {
      var settings: ModalModel = {
        header: "Joining",
        description: "Please wait",
        design: ModalDesign.LOADING
      }
      this.modalService.show(settings);
      var dto: JoinBusinessDTO = {
        userId: member.id,
        businessId: this.businessId
      }
      this.bookingService.join(dto)
        .then(() => {
          this.notifyService.notify("Joined " + this.businessName, "You have now joined " + this.businessName, NotifyType.SUCCESS);
          var settings: ModalModel = {
            header: member.name + " is now a member of " + this.businessName,
            description: this.businessName + " can now easily add you to future activities and you can make quick and easy bookings.",
            design: ModalDesign.CENTRAL,
            type: ModalType.SUCCESS,
            ctaonetext: "Great!",
            hidectatwo: true
          }
          this.modalService.show(settings);
        })
        .catch((error) => {
          this.notifyService.notify("Error Joining " + this.businessName, error.message, NotifyType.ERROR);
          this.reOpenMembersList();
        }).finally(() => {

        });
    }
  }

  editMe() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      onclose: () => {
        this.reOpenMembersList();
      },
      header: "Update your details",
      large: true,
      component: BookingProfileFormComponent,
      componentOutputs: [{
        outputName: "updated",
        func: async () => {
          this.modalService.hide();
          this.reOpenMembersList()
        }
      }]
    }
    this.modalService.show(settings);
  }

  newLinkedAccount() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      large: true,
      onclose: () => {
        this.reOpenMembersList();
      },
      component: BookingProfileFormForLinkedAccountComponent,
      componentOutputs: [{
        outputName: "updated",
        func: async (linkedAccount: LinkedAccountDTO) => {
          this.modalService.hide();
          this.reOpenMembersList();
        }
      }]
    }
    this.modalService.show(settings);
  }

  editLinkedAccount(linkedAcccountId: string) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      close: true,
      onclose: () => {
        this.reOpenMembersList();
      },
      large: true,
      component: BookingProfileFormForLinkedAccountComponent,
      componentInputs: [
        {
          inputName: "id",
          value: linkedAcccountId
        }
      ],
      componentOutputs: [{
        outputName: "updated",
        func: async () => {
          this.modalService.hide();
          this.reOpenMembersList();
        }
      }]
    }
    this.modalService.show(settings);
  }

  reOpenMembersList() {
    this.bookingService.openMembersList(this.businessId, this.businessName);
  }


  handlerError(message: string) {
    this.notifyService.notify("Member", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Member", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  cancel() {
    this.modalService.hide();
  }

}
