<div class="mt-3">
  <div class="flex justify-between mb-2">
    <label [for]="name" class="block wild-label">
      {{description}}
    </label>
    <span *ngIf="!required" class="text-sm text-gray-500" id="email-optional">Optional</span>
  </div>
  <ul role="list"
    class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 gap-x-4 gap-y-8 sm:gap-x-6 xl:grid-cols-3 xl:gap-x-8 px-1 cursor-pointer">
    <li *ngFor="let option of options" class="relative">
      <div (click)="selectOption(option)"
        [ngClass]="{'ring-blue-500 ring-2 ring-offset-2 ring-offset-gray-100':value?.image == option?.image}"
        class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100  overflow-hidden">
        <img [src]="imagesUrl + option.image +  '_640x640.jpeg?alt=media'" alt=""
          class="object-cover pointer-events-none group-hover:opacity-75">
        <button type="button" class="absolute inset-0 focus:outline-none">
        </button>
      </div>
    </li>
    <li class="relative">
      <button *ngIf="newicon" (click)="new()" type="button"
        class=" relative block h-full w-full border-2 border-gray-300 border-dashed rounded-lg px-8 py-5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-indigo-500">
        <!-- <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none"
          viewBox="0 0 48 48" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
      </svg> -->
        <span class="mx-auto h-8 w-8 text-gray-400 text-4xl">
          <fa-icon [icon]="newicon"></fa-icon>
        </span>
        <span class="mt-2 block text-sm font-medium text-gray-900">
          {{newtext}}
        </span>
      </button>
    </li>
  </ul>
</div>