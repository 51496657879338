
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { ImageStatus } from '../../../data/enums/image_status';
import { SourceDocumentType } from '../../../data/enums/source_document_type';
import { ImageModel } from '../../../data/models/images_model';
import { ErrorType } from '../../../data/enums/error_type';
import { ErrorModel } from '../../../data/models/error_model';
import { AuthenticationService } from '../auth/auth_service';
import { FirestoreService } from '../firestore/firestore.service';
import { NotifyService } from '../notify_service/notify_service';
import { NotifyType } from '../notify_service/notify_type';
import { UtilService } from '../util/util_service';
import { FileUploadType } from './file_upload_type';
import { ImageForUpload } from './image_for_upload_model';
import { UploadStatus } from './upload_status';
import { Storage } from '@angular/fire/storage';
import { ref, uploadBytes } from 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private notify: NotifyService,
    private util: UtilService,
    private firestore: FirestoreService,
    private auth: AuthenticationService,
    private storage: Storage) { }

  timeout = 10000;

  async uploadImages(type: FileUploadType, images: ImageForUpload[], sourceDocumentId: string, sourceDocumentType: SourceDocumentType): Promise<ImageModel[]> {
    var imageModels: ImageModel[] = [];
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      image.uploadstatus = UploadStatus.STAGED
    }
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const file = image.image;
      const userId = this.auth.getLoggedInCookie();
      image.uploadstatus = UploadStatus.UPLOADING;
      if (!file?.name) {
        this.couldNotGetImageName();
      } else {
        var fileName = "";
        var randomNumner = this.util.getRandomNumber()
        switch (type) {
          case FileUploadType.BUSINESSBANNERIMAGE:
            fileName += "businessbannerimage"
            break;
          default:
            fileName += "unknowntype"
            break;
        }
        fileName += "_" + randomNumner.toString();
        var ext = file.name.split('.').pop();
        //It's VERY IMPORTANT TO INCLUDE AN EXTENSION HERE
        //IF IMAGES DO NOT SHOW THEN IT"S BECASUE IT CANNOT GET THE EXTENSION
        var locationToSaveFile = `images/${fileName + "." + ext}`;
        const fileRef = ref(this.storage, locationToSaveFile)

        const task = await uploadBytes(fileRef, file);

        // var response = await task.snapshotChanges().pipe(
        //   timeout(this.timeout)
        // ).toPromise()
        //   .catch((error: any) => {
        //     this.throwAsyncError(error);
        //   });
        // var downloadURL = await fileRef.getDownloadURL().toPromise()
        // .catch((error: any) => {
        //   this.throwAsyncError(error);
        // });
        var downloadURL = task.ref.fullPath;

        var imageModel: ImageModel = {
          id: "",
          caption: "",
          image: fileName,
          creation_date: new Date(),
          createdBy: userId,
          sourceDocumentId: sourceDocumentId,
          sourceDocumentImageType: sourceDocumentType,
          sourceDocumentParentId: "",
          sourceDocumentImageParentType: SourceDocumentType.UNKNOWN,
          status: ImageStatus.LIVE,
          notifiedCreatorAboutVerification: false,
          url: downloadURL,
          likedBy: [],
          likedByUsers: [],
          numberOfLikes: 0,
          numberOfComments: 0

        }
        await this.firestore.create("images", imageModel);
        imageModels.push(imageModel);
        image.uploadstatus = UploadStatus.UPLOADED;
      }
    }
    return imageModels;
  }

  private throwAsyncError(error: any) {
    var errorModel = this.getError(error);
    throw Error(errorModel.message);
  }

  private getError(err: any): ErrorModel {
    var message = "";
    var description = "";
    var code: ErrorType = ErrorType.UNKNOWN;
    var unKnownErrorMessage = "Unknown Upload Error";
    if (err == undefined || err == null || err == "" || err.message == undefined || err.message == "") {
      message = unKnownErrorMessage;
    } else {
      switch (true) {
        case err.message == "Timeout has occurred":
          code = ErrorType.TIMEOUT;
          message = "Network is running slow";
          description = "Please give it another go";
          break;
        default:
          code = ErrorType.UNKNOWN;
          message = unKnownErrorMessage;
          description = "";
          break;
      }
    }
    var error: ErrorModel = {
      code: code,
      message: message,
      description: description
    }
    console.log("pre-processed error", err);
    console.log("pre-processed error message", err.message);
    console.log("Processed error", error);
    return error;
  }

  couldNotGetImageName() {
    this.notify.notify("No Image Name", "Could not find name of image", NotifyType.ERROR);
  }

}
