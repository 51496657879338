import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { BreadCrumb } from 'src/shared/components/buildingblocks/wrapper/breadcrumb';
import { SelectModel } from 'src/shared/components/select/select_model';
import { CountryCodes } from '../../../../../../data/enums/country_codes';
import { Currency } from '../../../../../../data/enums/currency';
import { TimeZone } from '../../../../../../data/enums/timezone';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { BusinessConfigurationDTO } from '../../../../../../data/dtos/business/configuration/BusinessConfigurationDTO';
import { ConfigurationService } from '../../../services/configuration/configuration_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { BusinessSubscriptionService } from '../../../services/business_subscription/business_subscription_service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { EmailBundleLinks } from './emailbundlelinks.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html'
})
export class ConfigurationComponent implements OnInit {

  constructor(
    private notifyService: NotifyService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private modalService: ModalService,
    private businessSubscriptionService: BusinessSubscriptionService,
    private configurationService: ConfigurationService) { }

  form!: UntypedFormGroup;
  successfulSubscriptionCheck = 0;
  successfulSubscriptionCheckInterval = 3000;
  successfulSubscriptionCheckMax = 5;
  loading: boolean;
  TimeZone = TimeZone;
  Currency = Currency;
  CountryCodes = CountryCodes;
  showstripefees: boolean;
  emailRedThreshold: number = 30;
  emailAmberThreshold: number = 80;
  emailGreenThreshold: number = 250;
  breadcrumbs: BreadCrumb[] = [
    {
      icon: "cog",
      label: "",
      page: WildNavigationType.SETTINGS
    },
    {
      icon: "",
      label: "Settings",
      page: WildNavigationType.UNKNOWN
    }
  ]
  countryOptions: SelectModel[] = [
    {
      name: "United Kingdom",
      value: CountryCodes.GBGBR
    }
  ]

  public error: ErrorModel = null;
  configuration$: Observable<BusinessConfigurationDTO>;

  ngOnInit(): void {

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      params;
      if (params.showstripefees != undefined && params.showstripefees != null) {
        this.showstripefees = true;
      }
      if (params.subscribed != undefined && params.subscribed != null && (params.subscribed == "1" || params.subscribed == 1)) {
        this.checkForSuccessfulSubscription();
      }else if (params.emailbundle != undefined && params.emailbundle != null && (params.emailbundle == "1" || params.emailbundle == 1)) {
        this.checkForSuccessfulEmailBundle();
      } else {
        this.load();
      }
    });
  }
  
  emailBundleLinks() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: EmailBundleLinks,
      large: true,
      close: true,
    }
    this.modalService.show(settings);
  }

  removeQueryParams() {
    const newUrl = '/business/settings/configuration';
    this.location.replaceState(newUrl);
  }

  checkForSuccessfulSubscription() {
    var settings: ModalModel = {
      design: ModalDesign.LOADING,
      header: "Loading...",
      large: false,
    }
    this.modalService.show(settings);
    if (this.successfulSubscriptionCheck < this.successfulSubscriptionCheckMax) {
      this.successfulSubscriptionCheck = this.successfulSubscriptionCheck++;
      this.configurationService.getBusinessConfiguration().toPromise().then((result: BusinessConfigurationDTO) => {
        if (result.subscriptionActive == true) {
          var settings: ModalModel = {
            design: ModalDesign.CENTRAL,
            type: ModalType.SUCCESS,
            header: "New Plan Configured",
            description: "Welcome to your new plan.",
            hidectatwo: true,
            ctaonetext: "Great!",
            large: false,
          }
          this.modalService.show(settings);
          this.load();
          this.modalService.hide();
          this.removeQueryParams()
        } else {
          setTimeout(() => {
            this.checkForSuccessfulSubscription();
          }, this.successfulSubscriptionCheckInterval);
        }

      })
    } else {
      var settings: ModalModel = {
        design: ModalDesign.CENTRAL,
        type: ModalType.ERROR,
        header: "Could Not Find Sucscription",
        description: "Please refresh the page to see new subscription configuration",
        hidectatwo: true,
        ctaonetext: "Refresh Page",
        ctaonetextclick() {
          window.location.href = window.location.href;
        },
        large: false,
      }
      this.modalService.show(settings);
    }
  }

  checkForSuccessfulEmailBundle() {
    var settings: ModalModel = {
      design: ModalDesign.LOADING,
      header: "Applying Credit...",
      large: false,
    }
    this.modalService.show(settings);
    setTimeout(() => {
      this.modalService.hide();
      var settings: ModalModel = {
        design: ModalDesign.CENTRAL,
        type: ModalType.SUCCESS,
        header: "Email Bundle Configured",
        description: "Your New Email Credits Have Been Added.",
        hidectatwo: true,
        ctaonetext: "Great!",
        large: false,
      }
      this.modalService.show(settings);
      this.removeQueryParams()
      this.load();
    }, 10000);
  }


  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  cancelPlan() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.ERROR,
      description: "Are you sure you want to cancel your subscription.?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.procecssCancellation();
      }
    }
    this.modalService.show(settings);
  }
  async procecssCancellation() {
    var settings: ModalModel = {
      header: "Cancelling",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    this.businessSubscriptionService.cancel()
      .then(async () => {
        this.notifyService.notify("Cancelled", "", NotifyType.SUCCESS);
        var settings: ModalModel = {
          header: "Loading...",
          description: "Please wait",
          design: ModalDesign.LOADING
        }
        this.modalService.show(settings);
        setTimeout(() => {
          this.load();
          this.modalService.hide();
        }, 4000);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  load() {
    this.configuration$ = null
    this.configuration$ = this.configurationService.getBusinessConfiguration().pipe(
      take(1),
      tap((configuration) => {
        return configuration;
      }),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}