import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BusinessTODOModel } from '../../../../../../../data/dtos/business/BusinessTODOModel';
import { BusinessTODO } from '../../../../../../../data/enums/businessTODO';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
    selector: 'app-businesstodos',
    template: ` 
        <ng-container *ngIf="todos$ | async as todos; else loadingOrError">
            <div *ngIf="!todos || todos.length == 0; else todoslist"></div>
            <ng-template #todoslist>
            <div style="border: 2px solid #d3eaff;" class="rounded-lg shadow p-4 m-4 ">
                <h2 class="text-sm font-medium">Welcome to Wild for Business</h2>
                <p class="text-xs mt-2">Start taking bookings for your business in three easy steps.</p>
                <p class="text-xs mt-2">Need help? Send us an <a class="text-blue-500 hover:underline" href="mailto:support@wildopenwater.com">email</a> with your questions or <span (click)="arrangeDemo()" class="text-blue-500 hover:underline cursor-pointer">arrange a demo</span>.</p>
                <div *ngIf="todos.length > 0">
                    <app-todowrapper>
                        <li *ngFor="let todo of todos; let i = index" app-todolistitem [inverted]="true" (onClick)="action(todo)" [header]="todo.name"
                            [description]="todo.description" 
                            [completed]="todo.complete"
                            [icon]="
                            todo.complete == true ? 'check' : ''"
                            [text]="
                            todo.complete != true ? (i + 1) : 
                            ''" 
                            [bgClass]="
                            todo.complete == true ? 'bg-green-200' :
                            todo.type == BusinessTODO.UNVERIFIED ? 'wild-bg-primary' : 
                            todo.type == BusinessTODO.ADDSERVICE ? 'wild-bg-primary' : 
                            todo.type == BusinessTODO.GOLIVE ? 'wild-bg-primary' : 
                            todo.type == BusinessTODO.BOOKINGURL ? 'wild-bg-primary' : 
                            'bg-gray-200'" 
                            [iconClass]="
                            todo.complete == true ? 'text-green-800' :
                            todo.type == BusinessTODO.UNVERIFIED ? 'text-white' : 
                            todo.type == BusinessTODO.ADDSERVICE ? 'text-white' : 
                            todo.type == BusinessTODO.GOLIVE ? 'text-white' : 
                            todo.type == BusinessTODO.BOOKINGURL ? 'text-white' : 
                            'text-gray-800'" 
                             [ctatext]="
                             todo.type == BusinessTODO.UNVERIFIED ? 'Verify' : 
                             todo.type == BusinessTODO.ADDSERVICE ? 'Add' : 
                             todo.type == BusinessTODO.GOLIVE ? 'Launch' : 
                             todo.type == BusinessTODO.BOOKINGURL ? 'Add URL' : 
                             null"></li>
                    </app-todowrapper>    
                    <br />            
                </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <!-- Removed loader to prevent showing every time dashboard is accessed -->
                <!-- <app-loader [disablescreenheight]="true"></app-loader> -->
            </ng-template>
        </ng-template>
  
        
  `
})
export class BusinessTODOsComponent implements OnInit {

    public error: ErrorModel = null;
    todos$: Observable<BusinessTODOModel[]>;
    BusinessTODO = BusinessTODO;
    isAppUser: boolean = false;

    constructor(
        private auth: AuthenticationService,
        private notifyService: NotifyService,
        private modalService: ModalService,
        private businessService: BusinessService,
        private navigation: NavigationService) { }

    ngOnInit(): void {
        this.isAppUser = this.auth.isAppUser;
        this.load();
    }

    arrangeDemo() {
        this.navigation.navigate(WildNavigationType.DEMO);
    }

    load() {
        this.todos$ = null;
        this.todos$ = this.businessService.getTODOs().pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    action(todo: BusinessTODOModel) {
        if (todo.complete == true)
            return;
        if (todo.type == BusinessTODO.ADDSERVICE) {
            this.navigation.navigate(WildNavigationType.BUSINESSSERVICES, { onboarding: 1 })
        } else if (todo.type == BusinessTODO.GOLIVE) {
            this.navigation.navigate(WildNavigationType.BUSINESSSTATUS, { onboarding: 1 })
        } else if (todo.type == BusinessTODO.BOOKINGURL) {
            this.navigation.navigate(WildNavigationType.BRAND, { onboarding: 1 })
        } else if (todo.type == BusinessTODO.UNVERIFIED) {
            this.getVerificationLink()
        } else {
            this.handlerError("Unknown Action")
        }
    }

    async getVerificationLink() {
        var settings: ModalModel = {
            header: "Starting Verification",
            description: "Please wait",
            design: ModalDesign.LOADING
        }
        this.modalService.show(settings);
        this.businessService.getVerificationLink()
            .then(async (link: any) => {
                await this.openLink(link.link);
            })
            .catch((error) => {
                this.handlerError(error.message);
            })
            .finally(() => {
                this.modalService.hide();
            })
    }

    handlerError(message: string) {
        this.notifyService.notify("Verification", message, NotifyType.ERROR);
    }

    async openLink(url: string) {
        location.replace(url);
    }

}
