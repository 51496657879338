import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateActivityCapacityDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityCapacityDTO';
import { Activity } from '../../../../../../data/models/activity_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UpdateActivityService } from '../services/updateactivity_service';

@Component({
  template: `
  <app-modaltopper header="Edit Capacity" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Submit" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="cancel()">
      <app-inputgroup>
        <app-input validation="Max 200" [form]="form" type="text" name="capacity" description="Capacity" [span]=6 [value]="activity.capacity"></app-input> 
      </app-inputgroup>
  </app-form> 
  `
})
export class AppointmentEditCapacityFormComponent implements OnInit {

  @Input() activity: Activity;
  @Output() updated = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private updateActivityService: UpdateActivityService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      capacity: new UntypedFormControl('', [Validators.required, Validators.max(200)])
    });
  }

  onSubmit(data: any) {
    const convertedNumber = Number(data.capacity); // 1234
    if (Number.isNaN(convertedNumber)) {
      this.notifyService.notify("Please enter a number", "Whole number required", NotifyType.ERROR)
      return;
    }
    this.loading = true;
    var dto: UpdateActivityCapacityDTO = {
      capacity: convertedNumber
    }
    this.updateActivityService.updateCapacity(this.activity.id, dto)
      .then(() => {
        this.activity.capacity = data.capacity;
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Capacity", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  cancel() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
