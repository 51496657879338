<div *ngIf="inviteStatus$">
  <ng-container *ngIf="inviteStatus$ | async as inviteStatus; else loadingOrError">
    <div class="min-h-full pt-16 pb-12 flex flex-col">
      <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 flex justify-center">
          <a href="/" class="inline-flex">
            <img class="h-12 w-auto"
              src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/icons%2Fw-icon%20small.png?alt=media&token=800dfa52-b5d8-479a-9784-b565f493fba6"
              alt="">
          </a>
        </div>
        <div class="py-16">
          <div class="text-center">
            <p class="wild-text-primary uppercase tracking-wide">You are invited to join</p>
            <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {{inviteStatus.business.name}}</h1>
            <p class="mt-2 text-base text-gray-500">Click the link below to accept this invitation</p>
            <div class="mt-6">
              <a (click)="accept(inviteStatus)" class="wild-btn-primary" style="min-width: 125px;">
                <span *ngIf="!loading">Accept Invite</span>
                <div *ngIf="loading" class="loader"></div>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </ng-container>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
      <app-wrapper>
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="retry()">
        </app-retry>
      </app-wrapper>
    </ng-container>
    <ng-template #loading>
      <app-loader></app-loader>
    </ng-template>
  </ng-template>

</div>