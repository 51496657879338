import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-panelrow',
  template: `
  <dl class="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-0">
        <div class="sm:flex px-6 py-5">
            <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                {{description}}
            </dt>
            <dd
                class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2 flex justify-between items-start w-full">
                <p>
                    <ng-content></ng-content>
                </p>
                <button *ngIf="!hidebutton" class="pl-2 wild-link" (click)="click()">
                  <span *ngIf="buttontext">{{buttontext}}</span>
                  <span *ngIf="!buttontext">edit</span>
                </button>
            </dd>
        </div>
    </dl>
  `
})
export class PanelRowComponent {

  @Input() description: string;
  @Input() buttontext: string;
  @Input() hidebutton: boolean;
  @Output() onClick = new EventEmitter();

  click() {
    this.onClick.emit();
  }

}
