import { Injectable } from '@angular/core';
import { DocumentReference, Firestore } from '@angular/fire/firestore';
import { addDoc, collection, collectionGroup, doc, DocumentData, DocumentSnapshot, endAt, getDoc, getDocs, limit, onSnapshot, orderBy, Query, query, QueryFieldFilterConstraint, QuerySnapshot, setDoc, startAfter, startAt, where, WhereFilterOp } from 'firebase/firestore';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, take, timeout } from 'rxjs/operators';
import { ErrorType } from '../../../data/enums/error_type';
import { FirestoreQueryType } from '../../../data/enums/firestore_query_type';
import { ErrorModel } from '../../../data/models/error_model';
import { FirestoreQuery } from '../../../data/models/firestore_query_model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private readonly afs: Firestore,
  ) { }

  timeout = 10000;

  async create(collectionString: string, data: any, id: string = ""): Promise<string> {
    var doc = await addDoc(collection(this.afs, collectionString), data)
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
    if (doc !== undefined && doc !== null) {
      var ref = doc as DocumentReference;
      return ref.id;
    }
    return "";
  }

  async createSubCollectionDocument(collectionString: string, collectionId: string, subCollection: string, data: any): Promise<string> {
    var doc = await addDoc(collection(this.afs, `${collectionString}/${collectionId}/${subCollection}`), data)
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
    if (doc !== undefined && doc !== null) {
      var ref = doc as DocumentReference;
      return ref.id;
    }
    return "";
  }

  async createSubSubCollectionDocument(collectionString: string, collectionId: string, subCollection: string, subCollectionId: string, subSubCollection: string, data: any): Promise<string> {
    var doc = await addDoc(collection(this.afs, `${collectionString}/${collectionId}/${subCollection}/${subCollectionId}/${subSubCollection}`), data)
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
    if (doc !== undefined && doc !== null) {
      var ref = doc as DocumentReference;
      return ref.id;
    }
    return "";
  }

  async update(id: string, collectionString: string, data: any) {
    //! User setDoc instead of updateDoc so it's created if doesn't exist
    await setDoc(doc(this.afs, `${collectionString}/${id}`), data, { merge: true })
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
  }

  async updateSubcollection(id: string, collectionString: string, subCollection: string, subCollectionId: string, data: any) {
    await setDoc(doc(this.afs, `${collectionString}/${id}/${subCollection}/${subCollectionId}`), data, { merge: true })
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
  }

  async updateSubSubcollection(id: string, collectionString: string, subCollection: string, subCollectionId: string, subSubCollection: string, subSubCollectionId: string, data: any) {
    await setDoc(doc(this.afs, `${collectionString}/${id}/${subCollection}/${subCollectionId}/${subSubCollection}/${subSubCollectionId}`), data, { merge: true })
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
  }

  listener(collection: string, id: string): Observable<any> {
    return new Observable(observer => {
      return onSnapshot(doc(this.afs, `${collection}/${id}`),
        (snapshot => observer.next(snapshot == undefined ? null : snapshot.data())),
        (error => observer.error(error.message))
      );
    }).pipe(
      catchError((err: any) => {
        var error = this.getError(err);
        return throwError(error);
      }),
      map((a: any) => {
        if (a == null || a == undefined)
          return null;
        return a;
      })
    )
  }

  listenerByQueryForCollection(collectionString: string, queryArray: FirestoreQuery[]): Observable<any> {
    var newQuery = query(collection(this.afs, collectionString), ...this.getQueryContains(queryArray));
    return this.sharedListenerService(newQuery);
  }

  listenerByQueryForSubCollection(collectionString: string, collectionId: string, subCollection: string, queryArray: FirestoreQuery[]): Observable<any> {
    var newQuery = query(collection(this.afs, `${collectionString}/${collectionId}/${subCollection}`), ...this.getQueryContains(queryArray));
    return this.sharedListenerService(newQuery);
  }

  listenerByQueryForCollectionGroup(collectionString: string, queryArray: FirestoreQuery[]): Observable<any> {
    var newQuery = query(collectionGroup(this.afs, collectionString), ...this.getQueryContains(queryArray));
    return this.sharedListenerService(newQuery);
  }

  sharedListenerService(newQuery: Query<DocumentData>): Observable<any> {
    return new Observable(observer => {
      onSnapshot(newQuery,
        (snapshot => observer.next(snapshot)),
        (error => observer.error(error.message))
      );
    }).pipe(
      catchError((err: any) => {
        var error = this.getError(err);
        return throwError(error);
      }),
      map((a: QuerySnapshot<DocumentData>) => {
        if (a == null || a?.docs?.length == 0)
          return [];
        var array = [];
        for (let i = 0; i < a?.docs?.length; i++) {
          const element = a?.docs[i];
          array.push(
            {
              ...element.data(),
              id: element.id,
            }
          );
        }
        return array;
      })
    )
  }


  getDocumentById(collection: string, id: string): Observable<any> {
    try {
      return this.getDocument(doc(this.afs, `${collection}/${id}`));
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getSubCollectionDocumentById(collection: string, id: string, subCollection: string, subCollectionId: string): Observable<any> {
    try {
      return this.getDocument(doc(this.afs, `${collection}/${id}/${subCollection}/${subCollectionId}`));
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getDocumentByQuery(collectionString: string, queryArray: FirestoreQuery[]): Observable<any> {
    try {
      var newQuery = query(collection(this.afs, collectionString), ...this.getQueryContains(queryArray));
      return this.getDocumentByQueryCondition(newQuery);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getListByQuery(collectionString: string, queryArray: FirestoreQuery[]): Observable<any> {
    try {
      var newQuery = query(collection(this.afs, collectionString), ...this.getQueryContains(queryArray));
      return this.getListByQueryCondition(newQuery);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getCollectionGroupListByQuery(collectionString: string, queryArray: FirestoreQuery[]): Observable<any> {
    try {

      var newQuery = query(collectionGroup(this.afs, collectionString), ...this.getQueryContains(queryArray));
      return this.getListByQueryCondition(newQuery);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getListFromSubcollectionByQuery(collectionString: string, collectionId: string, subCollection: string, queryArray: FirestoreQuery[]): Observable<any> {
    try {
      var newQuery = query(collection(this.afs, `${collectionString}/${collectionId}/${subCollection}`), ...this.getQueryContains(queryArray));
      return this.getListByQueryCondition(newQuery);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getListFromSubSubcollectionByQuery(collectionString: string, collectionId: string, subCollection: string, subCollectionId: string, subSubCollection: string, queryArray: FirestoreQuery[]): Observable<any> {
    try {
      var newQuery = query(collection(this.afs, `${collectionString}/${collectionId}/${subCollection}/${subCollectionId}/${subSubCollection}`), ...this.getQueryContains(queryArray));
      return this.getListByQueryCondition(newQuery);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  //Returns null or a single document
  private getDocument(doc: DocumentReference<DocumentData>): Observable<any> {
    try {
      return from(getDoc(doc)).pipe(
        timeout(this.timeout),
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map((a: DocumentSnapshot<DocumentData>) => {
          if (a == null || a.data() == null)
            return null;
          const data = a.data();
          const id = a.id;
          return { id, ...data };
        })
      );
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  //Returns null or a single document
  private getDocumentByQueryCondition(queryCondition: Query): Observable<any> {
    try {
      return from(getDocs(queryCondition)).pipe(
        timeout(this.timeout),
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map((a: any) => {
          if (a == null || a.docs == null)
            return null;
          if (a.docs.length == 0)
            return null;
          const data = a.docs[0].data();
          const id = a.docs[0].id;
          return { id, ...data };
        })
      );
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }

  }

  //Returns [] or array or documents 
  private getListByQueryCondition(queryCondition: Query): Observable<any> {
    try {
      return from(getDocs(queryCondition)).pipe(
        timeout(this.timeout),
        catchError((err: any) => {
          var error = this.getError(err);

          return throwError(error);
        }),
        map((a: any) => {
          if (a == null || a.docs == null)
            return [];
          var array = [];
          for (let i = 0; i < a.docs.length; i++) {
            const element = a.docs[i];
            array.push(
              {
                ...element.data(),
                id: element.id,
              }
            );
          }
          return array;
        })
      );
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  private getError(err: any): ErrorModel {
    var message = "";
    var description = "";
    var code: ErrorType = ErrorType.UNKNOWN;
    var unKnownErrorMessage = "Unknown Error";
    if (err == undefined || err == null || err == "" || err.message == undefined || err.message == "") {
      message = unKnownErrorMessage;
    } else {
      switch (true) {
        case err.message == "Timeout has occurred":
          code = ErrorType.TIMEOUT;
          message = "Network is running slow";
          description = "Please give it another go";
          break;
        case err.message.indexOf("No document to update") != -1:
          code = ErrorType.NODOCUMENTTOUPDATE;
          message = "The record your are trying to update cannot be found";
          description = "Please give it another go";
          break;
        case err.message == "Missing or insufficient permissions.":
          code = ErrorType.ACCESSDENIED;
          message = "Access Denied";
          description = "You do not have access to this content, please contact an administrator";
          break;
        case err.message.includes("That index is currently building"):
          code = ErrorType.INDEXBEINGBUILT;
          message = "Index Building";
          description = "Index under construction";
          break;
        case err.message.includes("The query requires an index"):
          code = ErrorType.NEEDSINDEX;
          message = "Index Required";
          description = "This query requires an index";
          break;
        case err.message.includes("Query.where() called with invalid data"):
          code = ErrorType.INVALIDQUERY;
          message = "Invalid Query";
          description = "Invalid parameters sent in query";
          break;
        default:
          code = ErrorType.UNKNOWN;
          message = unKnownErrorMessage;
          description = "";
          break;
      }
    }
    var error: ErrorModel = {
      code: code,
      message: message,
      description: description
    }
    console.log("pre-processed error", err);
    console.log("pre-processed error message", err.message);
    console.log("Processed error", error);
    return error;
  }

  private throwAsyncError(error: any) {
    var errorModel = this.getError(error);
    throw Error(errorModel.message);
  }

  private getQueryContains(query: FirestoreQuery[]): QueryFieldFilterConstraint[] {
    try {
      var contraints: any[] = [];
      for (let i = 0; i < query.length; i++) {
        var condition = query[i];
        if (condition.type == FirestoreQueryType.WHERE)
          contraints.push(where(condition.property, (condition.operator as WhereFilterOp), condition.value))
        // updatedReference = updatedReference.where(condition.property, condition.operator, condition.value);
        if (condition.type == FirestoreQueryType.ORDERBY)
          contraints.push(orderBy(condition.property, condition.value))
        // updatedReference = updatedReference.orderBy(condition.property, condition.value);
        if (condition.type == FirestoreQueryType.LIMIT)
          contraints.push(limit(condition.value))
        // updatedReference = updatedReference.limit(condition.value)
        if (condition.type == FirestoreQueryType.ARRAYCONTAINS)
          contraints.push(where(condition.property, "array-contains", condition.value))
        // updatedReference = updatedReference.where(condition.property, "array-contains", condition.value)
        if (condition.type == FirestoreQueryType.STARTAT)
          contraints.push(startAt(condition.value))
        // updatedReference = updatedReference.startAt(condition.value)
        if (condition.type == FirestoreQueryType.STARTAFTER)
          contraints.push(startAfter(condition.value))
        // updatedReference = updatedReference.startAfter(condition.value)
        if (condition.type == FirestoreQueryType.ENDAT)
          contraints.push(endAt(condition.value))
        // updatedReference = updatedReference.endAt(condition.value)
      }
      return contraints;
    } catch (error) {
      throw Error(error.message);
    }

  }

}
