import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserCouponsDTO } from '../../../../data/dtos/coupons/UserCouponsDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { UserMembershipsDTO } from 'src/data/dtos/coupons/UserMembershipsDTO';

@Injectable({
  providedIn: 'root'
})
export class UserMembershipsService {

  constructor(
    private http: HttpService,
  ) { }

  list(): Observable<UserMembershipsDTO> {
    return this.http.get(`${environment.api}/user/booking/memberships`);
  }

  async updatePaymentDetailsLink(id:string): Promise<any> {
    return this.http.get(`${environment.api}/user/booking/membershippaymentdetails/${id}`).toPromise();
  }

  async cancel(id:string): Promise<any> {
    return this.http.delete(`${environment.api}/user/booking/memberships/${id}`).toPromise();
  }

  async delete(id:string): Promise<any> {
    return this.http.delete(`${environment.api}/user/booking/memberships/${id}/delete`).toPromise();
  }

}

