import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrochureSiteHelperService {

    getTextColourClassBasedOnIndex(index:number):string{
        return "wild-accent-colour";
        if(index == 0) return "wild-accent-colour";
        if(index == 1) return "text-orange-700";
        if(index == 2) return "text-teal-700";
        if(index == 3) return "text-orange-700";
        if(index == 4) return "text-purple-700";
        if(index == 5) return "text-yellow-700";
        if(index == 6) return "text-cyan-700";
        if(index == 7) return "text-black";
        if(index == 8) return "text-rose-700";
        return "wild-accent-colour";
    }

}
