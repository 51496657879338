import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: '[app-actionslistitem]',
    template: `
    <li>
                <a (click)="click()" class="cursor-pointer block hover:bg-gray-50">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="flex min-w-0 flex-1 items-center">
                            <div class="flex-shrink-0">
                                <span [class]="bgClass"
                                    class=" h-12 w-12 rounded-lg p-3 ring-4 ring-white text-center flex items-center justify-center">
                                    <fa-icon class="h-8 w-8 text-xl" class="text-black"
                                    [class]="iconClass" [icon]="icon"></fa-icon>
                                </span>
                            </div>
                            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <p class="truncate text-sm font-medium wild-text-primary">{{header}}</p>
                                    <p class="mt-2 flex items-center text-sm text-gray-500">
                                        <span class="truncate">{{description}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- Heroicon name: mini/chevron-right -->
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </a>
            </li>
  <!-- <div (click)="click()" class="cursor-pointer rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
        <div>
            <span class="rounded-lg inline-flex p-3 ring-4 ring-white text-center" [class]="bgClass">
                <fa-icon class="h-6 w-6 text-xl" [class]="iconClass" [icon]="icon"></fa-icon>
            </span>
        </div>
        <div class="mt-8">
            <h3 class="text-lg font-medium">
                <span class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    {{header}} <span *ngIf="comingsoon" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800"> Coming Soon </span>
                </span>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
                {{description}}
            </p>
        </div>
        <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path
                    d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
        </span>
    </div> -->
  `
})
export class ActionsListItemComponent implements OnInit {

    @Input() header: string;
    @Input() comingsoon: string;
    @Input() description: string;
    @Input() bgClass: string;
    @Input() iconClass: string;
    @Input() icon: string;
    @Output() onClick = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    click() {
        this.onClick.emit();
    }

}
