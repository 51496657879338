import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactSearchResultsDTO } from '../../../../../../../data/dtos/business/search/contact/ContactSearchResultsDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class ContactSearchService {

    constructor(
        private http: HttpService
    ) { }

    search(search: string, page: number, size: number): Observable<ContactSearchResultsDTO> {
        return this.http.get(`${environment.api}/business/search/contact?q=${search}&page=${page}&size=${size}`);
    }

}
