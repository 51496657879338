import { Component, OnInit } from '@angular/core';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { BusinessService } from '../../../services/business_service/business_service';
import { ExpressLinkComponent } from '../payouts/components/express_link_component';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  isAppUser: boolean = false;

  constructor(
    private businessService: BusinessService,
    private auth: AuthenticationService,
    private modalService: ModalService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
  }

  goToConfiguration() {
    this.navigation.navigate(WildNavigationType.BUSINESSCONFIGURATION)
  }

  goToLocation() {
    this.navigation.navigate(WildNavigationType.BUSINESSLOCATION)
  }

  goToBank() {
    var settings: ModalModel = {
      close: true,
      design: ModalDesign.COMPONENT,
      component: ExpressLinkComponent
    }
    this.modalService.show(settings);
  }

  goToArea() {
    this.navigation.navigate(WildNavigationType.BUSINESSAREA)
  }

  goToTeam() {
    this.navigation.navigate(WildNavigationType.TEAM)
  }

  goToChangeBusinessMode() {
    this.navigation.navigate(WildNavigationType.BUSINESSMODE)
  }

  goToTimeTables() {
    this.navigation.navigate(WildNavigationType.TIMETABLES)
  }

  goToBrand() {
    this.navigation.navigate(WildNavigationType.BRAND)
  }

  goToServices() {
    this.navigation.navigate(WildNavigationType.BUSINESSSERVICES)
  }

  goToWaivers() {
    this.navigation.navigate(WildNavigationType.WAIVERSCONFIG)
  }

  goToCategories() {
    this.navigation.navigate(WildNavigationType.BUSINESSCATEGORIES)
  }

  goToPlugins() {
    this.navigation.navigate(WildNavigationType.BUSINESSPLUGINS)
  }

  goToCalendar() {
    this.navigation.navigate(WildNavigationType.BUSINESSCALENDAR)
  }

  goToWelcomeEmail() {
    this.navigation.navigate(WildNavigationType.WELCOMEEMAIL)
  }

  goToDiscountCodes() {
    this.navigation.navigate(WildNavigationType.DISCOUNTCODESLIST)
  }
 
  goToSwimSpots() {
    this.navigation.navigate(WildNavigationType.BUSINESSSWIMSPOTS)
  }

  goToOnlineBookingSettings() {
    this.navigation.navigate(WildNavigationType.BUSINESSONLINEBOOKINGSETTINGS)
  }

  goToClassPasses() {
    this.navigation.navigate(WildNavigationType.CLASSPASSES)
  }

  goToMemberships() {
    this.navigation.navigate(WildNavigationType.MEMBERSHIPS)
  }

  goToStatusPage() {
    this.navigation.navigate(WildNavigationType.BUSINESSSTATUS)
  }

  isOwner() {
    return this.businessService.isInRole("owner")
  }

  isAdmin() {
    return this.businessService.isInRole("admin")
  }

}