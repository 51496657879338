import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from '../../../services/business_service/business_service';
import { ConfigurationService } from '../../../services/configuration/configuration_service';
import { Editor, Toolbar } from 'ngx-editor';
import { CurrentWaiverDTO } from '../../../../../../data/dtos/business/waiver/CurrentWaiverDTO';
import { UpdateWaiverStatusDTO } from '../../../../../../data/dtos/business/waiver/UpdateWaiverStatusDTO';
import { UpdateWaiverContentDTO } from '../../../../../../data/dtos/business/waiver/UpdateWaiverContentDTO';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalService } from 'src/shared/services/modal/modal_service';

@Component({
  selector: 'app-waivers',
  templateUrl: './waivers.component.html'
})
export class WaiversComponent implements OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    private notifyService: NotifyService,
    private modalService: ModalService,
    private navigation: NavigationService,
    private configurationService: ConfigurationService,
    private business: BusinessService) { }
  editor: Editor;
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    // ['link', 'image'],
    // ['text_color', 'background_color'],
    ['text_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear'],
  ];


  statusform!: UntypedFormGroup;
  contentform!: UntypedFormGroup;
  loading: boolean;


  public error: ErrorModel = null;
  waiver$: Observable<CurrentWaiverDTO>;

  ngOnInit(): void {
    this.contentform = this.fb.group({
      html: new UntypedFormControl('', [])
    });
    this.statusform = this.fb.group({
      enabled: new UntypedFormControl('', []),
      acknowledged: new UntypedFormControl('', []),
    });
    this.load();
    this.editor = new Editor();
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();

  }

  load() {
    this.waiver$ = null
    this.waiver$ = this.configurationService.getCurrentWaiver().pipe(
      take(1),
      tap((data) => {
        setTimeout(() => {
          this.editor.setContent(data.html);
        }, 200);
      }),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  updateContent() {
    if (this.loading == true)
      return;
    var html = this.contentform.controls["html"].value;
    if (html == undefined || html == "") {
      this.notifyService.notify("No Waiver Content", "Please include some content for your waiver!", NotifyType.ERROR);
      return;
    }
    this.loading = true;
    var dto: UpdateWaiverContentDTO = {
      html: html
    }
    this.configurationService.updateWaiverContent(dto)
      .then(() => {
        this.notifyService.notify("Updated Waiver Config", "Your settings have been updated!", NotifyType.SUCCESS);

        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  onSubmit(data) {
    if (this.loading == true)
      return;
    this.loading = true;
    var dto: UpdateWaiverStatusDTO = {
      acknowledged: data.acknowledged,
      enabled: data.enabled,
    }
    this.configurationService.updateWaiverStatus(dto)
      .then(() => {
        this.notifyService.notify("Updated Waiver Config", "Your settings have been updated!", NotifyType.SUCCESS);

        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  newversion() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to issue a new version of this waiver? All contacts will be forced to resign.",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processnewversion();
      }
    }
    this.modalService.show(settings);
  }

  processnewversion() {
    if (this.loading == true)
      return;
    this.loading = true;
    this.configurationService.newWaiverVersion()
      .then(() => {
        this.notifyService.notify("New Waiver Version Issued", "Contacts will now be asked to sign new version", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Update Waiver", message, NotifyType.ERROR);
  }

  resetForm() {
    this.loading = false;
  }


}