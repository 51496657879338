import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrderLineStatus } from '../../../../../../data/enums/OrderLineStatus';
import { OrderModel } from '../../../../../../data/models/order_model';
import { OrderStatus } from '../../../../../../data/enums/order_status';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { UserRefundOrderLineDTO } from '../../../../../../data/dtos/booking/UserRefundOrderLineDTO';
import { BusinessOrdersService } from 'src/wild/modules/business/services/businessorders_service/businessorders_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { OrdersSearchService } from '../services/orderssearch/orderssearch_service';
import { catchError, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { RefundType } from '../../../../../../data/enums/RefundType';
import { AppointmentDetailsComponent } from '../../appointments/components/appointmentdetails.component';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { BusinessContactModel } from '../../../../../../data/models/business_contact_model';
import { ContactdetailsComponent } from '../../contacts/components/contactdetails/contactdetails.component';
import { BusinessOrderDTO } from '../../../../../../data/dtos/business/orders/businessOrderDTO';
import { SourceDocumentType } from '../../../../../../data/enums/source_document_type';
import { ActivityStatus } from '../../../../../../data/enums/activity_status';
import { BusinessOrderLineDTO } from 'src/data/dtos/business/orders/businessOrderLineDTO';


enum OrderPages {
  SUMMARY,
  NOTES
}

@Component({
  selector: 'app-businessorderdetails',
  templateUrl: './businessorderdetails.component.html'
})

export class BusinessOrderDetailsComponent implements OnInit {

  @Input() id: string;
  RefundType = RefundType;
  feebreakdown: boolean = false;
  OrderPages = OrderPages;
  page: OrderPages = OrderPages.SUMMARY;
  SourceDocumentType = SourceDocumentType;
  order$: Observable<BusinessOrderDTO>;
  imagesUrl = environment.imagesUrl;
  OrderLineStatus = OrderLineStatus;
  OrderStatus = OrderStatus;
  error: ErrorModel;
  ActivityStatus = ActivityStatus;

  constructor(
    private orderSearchService: OrdersSearchService,
    private panelService: PanelService,
    private modalService: ModalService, private businessOrdersService: BusinessOrdersService, private notifyService: NotifyService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.order$ = null;
    this.order$ = this.orderSearchService.getOrder(this.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  noteCountUpdated(count: number, order: BusinessOrderDTO) {
    order.noteCount = count;
  }

  cancel(order: OrderModel, orderLine: BusinessOrderLineDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to refund this order line?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.procecssCancellation(order, orderLine);
      }
    }
    this.modalService.show(settings);
  }
  async procecssCancellation(order: OrderModel, orderLine: BusinessOrderLineDTO) {
    var settings: ModalModel = {
      header: "Refunding",
      description: "Please wait",
      design: ModalDesign.LOADING
    }

    this.modalService.show(settings);
    var dto: UserRefundOrderLineDTO = {
      businessId: order.businessId,
      orderId: order.id,
      orderLineId: orderLine.id
    }
    this.businessOrdersService.businessRefundOrderLine(dto)
      .then(async () => {
        this.notifyService.notify("Cancelled", "Order Line  has been refunded", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
      })
  }

  handlerError(message: string) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.ERROR,
      description: message,
      header: "Error",
      close: true,
      ctaonetext: "OK",
      hidectatwo: true,
    }
    this.modalService.show(settings);
  }

  openActivity(activityId: string) {
    var settings: PanelModel = {
      header: "Activity Details",
      component: AppointmentDetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: activityId
        },
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: () => {
            this.load();
          }
        }
      ]
    }
    this.panelService.show(settings);
  }
  openContact(contact: BusinessContactModel) {
    var settings: PanelModel = {
      header: "Contact Details",
      component: ContactdetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: contact.id
        },
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: () => {
            this.load();
          }
        }
      ]
    }
    this.panelService.show(settings);
  }



}
