<app-wrapper header="Area" paragraph="Configure the area you work in">
    <div class="relative mb-6">
        <form class="w-full flex md:ml-0" [formGroup]="form">
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div class=" absolute inset-y-0 left-2 flex items-center pointer-events-none">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <input autocomplete="off" formControlName="search" type="search" name="search" id="search"
                    style="border: 2px solid #031d44;"
                    class="block w-full h-full pl-8 pr-3 py-4 text-gray-900 placeholder-gray-500 focus:outline-none sm:text-sm rounded-md"
                    placeholder="Enter a Location or what3words address">
            </div>
        </form>
        <div *ngIf="showSearch" class="px-2 sm:px-4 w-full bg-white shadow-md z-50 max-h-96 absolute left-0 top-14">
            <div class="max-h-80 overflow-y-scroll pb-8">
                <div *ngIf="searchLoading">
                    <app-loader disablescreenheight="true"></app-loader>
                </div>
                <div *ngIf="!searchLoading">
                    <div (click)="selectSearchResult(result)" *ngFor="let result of searchResults?.results"
                        class="flex w-full items-center justify-start h-12 hover:bg-gray-50 cursor-pointer border-t-2 border-gray-50"
                        aria-label="Result">
                        <a class="cursor-pointer group inline-flex items-start py-4 px-1 ">
                            <fa-icon *ngIf="result.type == LocationResultType.PLACE"
                                [ngClass]="{'text-blue-500':false,'text-gray-400 group-hover:text-blue-500':true}"
                                class="font-medium text-sm mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                icon="map-marker-alt">
                            </fa-icon>
                            <span class="font-medium text-sm mr-2 h-5 w-5 text-red-500"
                                *ngIf="result.type == 3">///</span>
                            <div>
                                <div class="font-medium text-sm">
                                    {{result.name}}
                                </div>
                                <div class="text-xs">
                                    <span *ngIf="result.type == LocationResultType.PLACE">
                                        <span *ngIf="result.locationDescription">{{result.locationDescription}}</span>
                                    </span>
                                    <span *ngIf="result.type == LocationResultType.WHAT3WORDS">
                                        <span *ngIf="result.locationDescription">{{result.locationDescription}}</span>
                                    </span>
                                    <span *ngIf="result.type == LocationResultType.SWIMSPOT">
                                        <span *ngIf="result.swimSpotType == WaterwayType.COASTAL">Coastal Swim Spot
                                            in </span>
                                        <span *ngIf="result.swimSpotType == WaterwayType.LAKE">Lake in </span>
                                        <span *ngIf="result.swimSpotType == WaterwayType.RIVER">River in </span>
                                        {{result.region}}
                                    </span>
                                </div>
                            </div>

                        </a>
                    </div>
                </div>
            </div>
            <div class="absolute bottom-0 left-0 w-full h-6 bg-gray-100 flex items-center px-2 justify-between">
                <span class="wild-link text-xs sm:text-sm" (click)="clearSearch()">clear</span>
                <span class="font-medium text-xs sm:text-sm text-gray-400">
                    <span *ngIf="!showMinCharactersNotReached && searchResults">{{searchResults.results.length}}
                        results</span>
                    <span *ngIf="showMinCharactersNotReached">type {{minSearchCharacters - charactersTyped}} more
                        character{{(minSearchCharacters - charactersTyped) > 1 ? 's':''}}</span>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="mapsLoaded | async" class="w-full shadow-lg rounded-lg bg-blue-500 overflow-hidden "
        style="height:60vh">
        <google-map (mapClick)="updateMarker($event)" height="100%" width="100%" [options]="options">
            <map-marker (mapDragend)="updateMarker($event)" *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition" [options]="markerOptions"></map-marker>
        </google-map>
    </div>
    <div class="flex items-center justify-between w-full mt-3">
        <div>
            <span class="text-sm text-gray-400">{{latitude}}, {{longitude}}</span>
        </div>
        <div>
            <button (click)="save()" class="wild-btn-primary">Save</button>
        </div>

    </div>
</app-wrapper>