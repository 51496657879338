import { Component, Input, OnInit } from '@angular/core';

@Component({
selector: "app-searchresulthimmer",
    template: `  
    <div class="w-48 text-gray-300">
        <h1 class="w-1/6 my-4 h-6 animate-pulse bg-gray-200"></h1>
        <div class="flex flex-col">
                <div class="hidden sm:block h-48 w-48 rounded-md bg-gray-200"></div>
                <div>
                    <h2 class="bg-blue-200 animate-pulse h-4  w-1/5 mt-2"></h2>
                    <p class="bg-gray-200 animate-pulse h-2 w-3/4 mt-2 "></p>
                    <p class="bg-gray-200 animate-pulse h-2 w-2/4 mt-2 "></p>
                </div>
        </div>
    </div>
  `
})
export class SearchResultShimmer  {

}
