import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-todowrapper',
  template: `
  <div class="my-2">
    <ul role="list" class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
      <ng-content></ng-content>
    </ul>
  </div>
  `
})
export class ToDoWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
