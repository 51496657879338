import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserActivityDetailsDTO } from '../../../../../data/dtos/booking/UserActivityDetailsDTO';
import { BusinessServiceLocationType } from '../../../../../data/enums/business_service_location_type';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { ErrorModel } from '../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { UserActivityService } from '../../services/useractivity_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-useractivitydetailsshared',
  templateUrl: './useractivitydetailsshared.component.html'
})
export class UserActivityDetailsSharedComponent implements OnInit {

  @Input() activityId: string;
  @Input() userId: string;

  error: ErrorModel;
  activity$: Observable<UserActivityDetailsDTO>
  imagesUrl = environment.imagesUrl;
  BusinessServiceLocationType = BusinessServiceLocationType;
  contactId:string = "";

  constructor(
    private activityService: UserActivityService,
    private authService: AuthenticationService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.load();
  }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  load() {
    this.activity$ = null;
    if (this.userId != undefined && this.userId != "" && this.userId != null) {
      this.contactId = this.userId;
      this.activity$ = this.activityService.getPublic(this.activityId, this.userId).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    } else {
      this.contactId = this.authService.userId;
      this.activity$ = this.activityService.get(this.activityId).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    }
  }


}
