import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../../data/models/error_model';
import { OrderModel } from '../../../../../../../data/models/order_model';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { BusinessOrderDetailsComponent } from '../../../orders/components/businessorderdetails.component';

@Component({
  selector: 'app-contactorders',
  template: `
    <ng-container *ngIf="orders$ | async as orders; else loadingOrError">
      <div *ngIf="!orders || orders.length == 0; else list">
        <app-empty icon="shopping-bag" header="No Orders Yet" ></app-empty>
      </div>
      <ng-template #list>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 px-6">
          <div (click)="edit(order)" *ngFor="let order of orders" class="relative rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm hover:bg-gray-50 cursor-pointer" >
          <div class="flex-1 min-w-0">
              <span  class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p class="text-xs text-gray-300 truncate">
                  <span>{{order.reference}}</span>
                </p> 
                <p class="text-sm font-medium text-gray-900">
                  {{order.creation_date.toDate() | date:'MMM d, y'}}
                </p>
                <p class="text-sm text-gray-500 truncate">
                  <span>{{order.total | price:order.currency}}</span>
                </p>
              </span>
            </div>
          </div>
        </div>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  `
})
export class ContactOrdersComponent implements OnInit {

  constructor(
    private businessService: BusinessService,
    private panelService: PanelService,
    private contactsService: ContactsService) { }

  public error: ErrorModel = null;
  @Input() id: string;
  orders$: Observable<OrderModel[]>;

  ngOnInit(): void {
    this.load();
  }

  hasAccess(): boolean {
    return this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("orders")
  }

  load() {
    this.orders$ = null;
    this.orders$ = this.contactsService.getOrders(this.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  edit(order: OrderModel) {
    var settings: PanelModel = {
      header: "Order Details",
      component: BusinessOrderDetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: order.id
        }
      ]
    }
    this.panelService.show(settings);
  }

}
