<app-wrapper header="Team"
  paragraph="Take control of your team, invite organisers to help you manage your venue, company or group." icon="plus"
  ctatext="Invite" (onClick)="new()">
  <ng-container *ngIf="users$ | async as users; else loadingOrError">
    <div *ngIf="!users || users.length == 0; else userstable">
      no users found
    </div>
    <ng-template #userstable>
      <div class="overflow-hidden bg-white shadow sm:rounded-md">
        <ul role="list" class="divide-y divide-gray-200">
          <li *ngFor="let user of users">
            <a class="block ">
              <!-- hover:bg-gray-50 -->
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="flex min-w-0 flex-1 items-center">
                  <div class="flex-shrink-0">
                    <img class="h-12 w-12 rounded-full"
                      [src]="user.image != null ? imagesUrl + user.image.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                      alt="">
                  </div>
                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p [ngClass]="{'text-yellow-500':getName(user) == 'no name yet','wild-text-primary':getName(user) != 'no name yet'}"
                        class="truncate text-sm font-medium ">{{getName(user)}} <span
                          class="md:hidden">({{getStatusString(user.status)}})</span></p>
                      <p class="mt-0 flex items-center text-xs text-gray-500">
                        <!-- Heroicon name: mini/envelope -->
                        <svg class="mr-1.5 h-3 w-3 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path
                            d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                          <path
                            d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                        </svg>
                        <span class="truncate">{{user.email}}</span>
                      </p>
                      <div class=" mt-1 flex text-xs items-center justify-start ">
                        <span
                          class="flex justify-start items-center hover:underline text-gray-400 hover:text-blue-500 cursor-pointer"
                          (click)="editDetails(user)">
                          <fa-icon class="mr-1" icon="user-cog"></fa-icon>
                          <a class="mr-3 ">settings</a>
                        </span>
                        <span
                          class="flex justify-start items-center hover:underline text-gray-400 hover:text-blue-500 cursor-pointer"
                          (click)="editWorkingHours(user)">
                          <fa-icon class="mr-1" icon="calendar-week"></fa-icon>
                          <a class="mr-3 "><span class="hidden lg:inline-block">working&nbsp;</span>hours</a>
                        </span>
                        <span *ngIf="isEditable(user)"
                          class="flex justify-start items-center hover:underline text-gray-400 hover:text-blue-500 cursor-pointer"
                          (click)="edit(user)">
                          <fa-icon class="mr-1" icon="cog"></fa-icon>
                          <a class="mr-3 ">roles</a>
                        </span>
                        <span *ngIf="isInvited(user)"
                          class="flex justify-start items-center hover:underline text-gray-400 hover:text-blue-500 cursor-pointer"
                          (click)="resendModal(user)">
                          <fa-icon class="mr-1" icon="envelope"></fa-icon>
                          <a class="mr-3 ">resend<span class="hidden lg:inline-block">&nbsp;invite</span></a>
                        </span>
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <div>
                        <span [ngClass]="getStatusClasses(user.status)"
                          class="inline-flex items-center rounded-full  px-2.5 py-0.5 text-xs font-medium ">{{getStatusString(user.status)}}</span>
                        <p class="mt-0 flex items-center text-xs text-gray-500 p-1.5">
                          {{user.roles}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="retry()">
      </app-retry>
    </ng-container>
    <ng-template #loading>
      <app-loader></app-loader>
    </ng-template>
  </ng-template>
</app-wrapper>