import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-headersection',
  templateUrl: './headersection.component.html',
  styleUrls: ['./headersection.component.scss']
})
export class HeadersectionComponent implements OnInit {

  @Input() prefix:string;
  @Input() header:string;
  @Input() description:string;

  constructor() { }

  ngOnInit(): void {
  }

}
