export enum CountryCodes {
    //https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
    UNKNOWN,
    //UK
    GBGBR,
    //IRELAND
    IEIRL,
    //Australia
    AUAUS,
    //Austria
    ATAUT,
    //Belgium
    BEBEL,
    //Brazil
    BRBRA,
    //Bulgaria
    BGBGR,
    //Canada
    CACAN,
    //Croatia
    HRHRV,
    //Cyprus
    CYCYP,
    //Czech Republic
    CZCZE,
    //Denmark
    DKDNK,
    //Estonia
    EEEST,
    //Finland
    FIFIN,
    //France
    FRFRA,
    //Germany
    DEDEU,
    //Gibraltar
    GIGIB,
    //Greece
    GRGRC,
    //Hong Kong
    HKHKG,
    //Hungary
    HUHUN,
    //India
    ININD,
    //Indonesia
    IDIDN,
    //Italy
    ITITA,
    //Japan
    JPJPN,
    //Latvia
    LVLVA,
    //Liechtenstein
    LILIE,
    //Lithuania
    LTLTU,
    //Luxembourg
    LULUX,
    //Malaysia
    MYMYS,
    //Malta
    MTMLT,
    //Mexico
    MXMEX,
    //Netherlands
    NLNLD,
    //New Zealand
    NZNZL,
    //Norway
    NONOR,
    //Poland
    PLPOL,
    //Portugal
    PTPRT,
    //Romania
    ROROU,
    //Singapore
    SGSGP,
    //Slovakia
    SKSVK,
    //Slovenia
    SISVN,
    //Spain
    ESESP,
    //Sweden
    SESWE,
    //Switzerland
    CHCHE,
    //Thailand
    THTHA,
    //United Arab Emirates
    AEARE,
    // United States
    USUSA
}