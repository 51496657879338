import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { BreadCrumb } from './breadcrumb';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  @Input() fullwidth: boolean;
  @Input() header: string | undefined;
  @Input() paragraph: string | undefined;
  @Input() icon: string | undefined;
  @Input() breadcrumbs: BreadCrumb[];
  @Input() ctatext: string | undefined;
  @Output() onClick = new EventEmitter<any>();
  WildNavigationType = WildNavigationType;

  constructor(
    private navigation: NavigationService
  ) { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit();
  }

  breadCrumbClick(breadcrumb: BreadCrumb) {
    if (breadcrumb.page != WildNavigationType.UNKNOWN) {
      this.navigation.navigate(breadcrumb.page);
    }
  }

}