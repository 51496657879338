<app-wild-header [isBusinessPage]="true"></app-wild-header>
<div *ngFor="let component of components">
    <app-logocloud *ngIf="component.type == FeatureComponentType.LOGOCLOUD" [data]="component"></app-logocloud>
    <app-textandcircles *ngIf="component.type == FeatureComponentType.TEXTANDCIRCLES"
        [data]="component"></app-textandcircles>
    <app-textandinfo *ngIf="component.type == FeatureComponentType.TEXTANDINFO" [data]="component"></app-textandinfo>
    <app-animatedhero *ngIf="component.type == FeatureComponentType.ANIMATEDHERO" [data]="component"></app-animatedhero>
    <app-simplehero *ngIf="component.type == FeatureComponentType.SIMPLEHERO" [data]="component"></app-simplehero>
    <app-imagecloud *ngIf="component.type == FeatureComponentType.IMAGECLOUD" [data]="component"></app-imagecloud>
    <app-rightimagefeature *ngIf="component.type == FeatureComponentType.RIGHTIMAGEINFOSECTION"
        [data]="component"></app-rightimagefeature>
    <app-leftimagefeature *ngIf="component.type == FeatureComponentType.LEFTIMAGEINFOSECTION"
        [data]="component"></app-leftimagefeature>
    <app-faqs *ngIf="component.type == FeatureComponentType.FAQS" [data]="component"></app-faqs>
    <app-bigimagefeature *ngIf="component.type == FeatureComponentType.BIGIMAGEFEATURE"
        [data]="component"></app-bigimagefeature>
</div>
<app-footer></app-footer>