<app-registrationpagecontainer
    [src]="'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FBusiness%20register%20page%20(2).png?alt=media&token=8dfb17ad-07f9-4f75-8664-b387ccfb1774'">
    <div>
        <app-modaltopper [logo]="false" header="Setup Your Business"></app-modaltopper>
        <div *ngIf="!businessAdded; else businessAddedAnimation">
            <app-form [fullwidthbutton]="true" [form]="form" ctaText="Create Business" disableDivide="true"
                (onSubmit)="onSubmit($event)" [loading]="loading">
                <app-inputgroup>
                    <app-input validation="Firstname required" [form]="form" type="text" name="firstName"
                        description="First Name" [span]=6></app-input>
                    <app-input validation="Surname required" [form]="form" type="text" name="surname"
                        description="Surname" [span]=6></app-input>
                    <app-input validation="Between 4 and 70 characters - no special characters" [form]="form"
                        type="text" name="name" description="Business Name" [span]=6></app-input>
                    <app-input *ngIf="getBusinessName()"
                        validation="Between 4 and 70 characters - no special characters" prefix="wildopenwater.com/"
                        [form]="form" type="text" name="url" description="Business URL" [span]=6>
                    </app-input>
                </app-inputgroup>
                <app-inputgroup>
                    <app-select [options]="countryOptions" [form]="form" name="country" description="Country" [span]=6
                        [value]="CountryCodes.GBGBR">
                    </app-select>
                </app-inputgroup>
                <app-inputgroup>
                    <app-select [options]="timeZoneOptions" [form]="form" name="timezone" description="Time Zone"
                        [value]="TimeZone.GMT" [span]=6>
                    </app-select>
                </app-inputgroup>
            </app-form>
        </div>
        <ng-template #businessAddedAnimation>
            <div *ngIf="!businessAddedAnimationComplete; else confirmationPage">
                <app-animation width="200px" height="200px" file="confirmation" [fadeout]="true" (complete)="done()">
                </app-animation>
            </div>
            <!-- Skipped this section and go straigh to dashboard goToDashboard -->
            <ng-template #confirmationPage>
                <app-confirmation title="PERFECT!" header="Let's Get Started"
                    description="You're a few clicks away from launch" ctaText="Open Dashboard" (click)="done()">
                </app-confirmation>
            </ng-template>
        </ng-template>
    </div>
    <div *ngIf="!businessAdded">
        <div class="mt-6 text-center text-sm">
            Already have a business account? <a (click)="alreadyRegistered()"
                class="underline cursor-pointer font-medium">Click here</a>
        </div>
        <div *ngIf="isAppUser" class="mt-6 text-center text-sm"> <a (click)="backToApp()"
                class="underline cursor-pointer font-medium">Back to
                app</a>
        </div>
    </div>
</app-registrationpagecontainer>