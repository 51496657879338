import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { PhoneModel } from '../../../../../../../data/models/phone_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Update Medical Info" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
          <app-textarea [form]="form" type="text" name="medicalInfo" description="Medical Information" [span]=6></app-textarea>
      </app-inputgroup>
  </app-form> 
  `
})
export class ContactMedicalInfoFormComponent implements OnInit {

  @Input() contact: BusinessContactModel;

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      medicalInfo: new UntypedFormControl(this.contact?.emergency?.medicalInfo ?? '', [Validators.required]),
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    this.contactService.updateMedicalInfo(this.contact.id, data.medicalInfo)
      .then(() => {
        this.next(data.medicalInfo);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Medical Info", message, NotifyType.ERROR);
  }

  next(medicalInfo: string) {
    this.notifyService.notify("Medical Info Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.contact.emergency.medicalInfo = medicalInfo;
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
