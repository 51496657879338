import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { LoginComponent } from './login.component';

interface ForgottenFormData {
  email: string;
}

@Component({
  selector: 'app-forgotten',
  template: `
  <app-modaltopper [logo]="false" header="Reset your password" ctatext="login here" (onClick)="goToLogin()"></app-modaltopper>
  <div [ngClass]="{'p-4':!isPage}">
  <app-form [form]="form" fullwidthbutton="true" ctaText="Reset Password" disableDivide="true"
    (onSubmit)="onSubmit($event)" [loading]="loading">
    <app-inputgroup>
        <app-input [form]="form" type="email" name="email" description="Email" [span]=6></app-input>
    </app-inputgroup>
</app-form>
</div>
  `
})
export class ForgottenComponent implements OnInit {

  @Input() modallink: WildNavigationType;
  @Input() isPage: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private navigation: NavigationService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }


  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required]),
    });
  }

  onSubmit(data: ForgottenFormData) {
    this.loading = true;
    this.authenticationService.forgottenPassword(data.email)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Reset Password", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Reset Password", "You will shortly recieve an email to reset your password", NotifyType.SUCCESS);
    this.goToLogin();
  }

  resetForm() {
    this.loading = false;
  }

  goToLogin() {
    if (this.isPage) {
      this.navigation.navigateAndRetainUrlParams(WildNavigationType.LOGIN);
    } else {
      var settings: ModalModel = {
        disableBodyScroll: true,
        design: ModalDesign.COMPONENT,
        component: LoginComponent,
        componentInputs: [{
          inputName: "modallink",
          value: this.modallink
        }],
        componentOutputs: [],
        close: true,
        large: false
      }
      this.modalService.show(settings);
    }
  }

}
