<div class="px-4 pt-3 relative">
    <div *ngIf="loading" class="z-50 inset-x-0 inset-y-0 w-full absolute top-16 sm:ml-16">
        <app-loader [disablescreenheight]="true"></app-loader>
    </div>
    <div class="sm:hidden text-center mb-3">
        <p *ngIf="events" class="text-xs font-thin mb-1">
            <span *ngIf="loading">
                loading...
            </span>
            <span *ngIf="!loading">{{events.length}} <span *ngIf="events.length == 1">activity</span>
                <span *ngIf="events.length > 1 || events.length == 0">activities</span>
            </span>
        </p>
        <h3 class="text-xl wild-text-primary leading-none">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en':1 }}
        </h3>
    </div>
    <div class="flex-col-reverse flex sm:flex-row items-center sm:justify-between pb-2">
        <div *ngIf="!detailedTableViewEnabled" class="sm:flex w-full sm:w-auto items-center justify-center">
            <div class="flex w-full sm:w-auto sm:mr-2">
                <div class="btn-group w-full sm:w-auto">
                    <div class="wild-btn-light rounded-r-none w-1/3 sm:w-14 sm:text-xs" mwlCalendarPreviousView
                        [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        Prev
                    </div>
                    <div (click)="todayClick()" [ngClass]="{'wild-btn-primary':isToday,'wild-btn-light':!isToday}"
                        class=" rounded-none w-1/3 sm:w-14 sm:text-xs" mwlCalendarToday [(viewDate)]="viewDate">
                        Today
                    </div>
                    <div class="wild-btn-light rounded-l-none w-1/3 sm:w-14 sm:text-xs" mwlCalendarNextView
                        [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        Next
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center cursor-pointer w-full sm:w-auto mt-2 sm:mt-0"
                (click)="chooseTeamMembers()">
                <div *ngIf="teamMembers == null || teamMembers?.length == 0">
                    <button type="button" class="wild-btn-light w-full sm:w-auto text-xs">
                        <fa-icon class="mr-2" icon="users"></fa-icon>
                        All Team
                    </button>
                </div>
                <div *ngIf="teamMembers != null && teamMembers?.length > 0"
                    class="isolate flex -space-x-2 overflow-hidden">
                    <span *ngFor="let teamMember of teamMembers">
                        <span *ngIf="!teamMember.image"
                            class="inline-flex items-center justify-center h-7 w-7 rounded-full bg-gray-500">
                            <span
                                class="text-sm font-medium leading-none text-white">{{getInitials(teamMember?.firstName
                                ??
                                '?',teamMember?.surname ?? '?')}}</span>
                        </span>
                        <img *ngIf="teamMember.image"
                            class="relative z-30 inline-block h-7 w-7 rounded-full ring-2 ring-white"
                            [src]="imagesUrl + teamMember.image?.image + '_100x100.jpeg?alt=media'" alt="">
                    </span>

                </div>
            </div>
            <!-- service filters will go here -->
            <!-- <div>&nbsp;</div> -->
        </div>

        <div [ngClass]="{'w-full': detailedTableViewEnabled}"
            class="hidden sm:flex flex-col items-center justify-items-center text-center">
            <p *ngIf="events" class="text-xs font-thin leading-none">
                <span *ngIf="loading">
                    loading...
                </span>
                <span *ngIf="!loading">
                    {{events.length}} <span *ngIf="events.length == 1">activity</span>
                    <span *ngIf="events.length > 1 || events.length == 0">activities</span></span>
            </p>
            <h3 class="text-lg wild-text-primary leading-tight">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en':1}}
            </h3>
        </div>

        <div *ngIf="!detailedTableViewEnabled" class="flex mb-2 sm:mb-0 w-full sm:w-auto">
            <div class="btn-group w-full sm:w-auto">
                <div [ngClass]="{'wild-btn-primary':view == CalendarView.Month,'wild-btn-light':view != CalendarView.Month}"
                    class="rounded-r-none w-1/3 sm:w-14 sm:text-xs" (click)="setView(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    Month
                </div>
                <div [ngClass]="{'wild-btn-primary':view == CalendarView.Week,'wild-btn-light':view != CalendarView.Week}"
                    class="wild-btn-light rounded-none w-1/3 sm:w-14 sm:text-xs" (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    Week
                </div>
                <div [ngClass]="{'wild-btn-primary':view == CalendarView.Day,'wild-btn-light':view != CalendarView.Day}"
                    class="wild-btn-light rounded-l-none w-1/3 sm:w-14 sm:text-xs" (click)="setView(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day">
                    Day
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!detailedTableViewEnabled" [ngSwitch]="view">
        <div style="overflow-x:scroll">
            <div style="min-width:700px;">
                <mwl-calendar-month-view (beforeViewRender)="beforeViewRender($event,'month')"
                    *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                    [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                    (eventClicked)="eventClicked($event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-month-view>
            </div>
        </div>
        <div style="overflow-x:scroll">
            <div style="min-width:700px;">
                <mwl-calendar-week-view weekStartsOn="1" (beforeViewRender)="beforeViewRender($event,'week')"
                    *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                    (eventClicked)="eventClicked($event.event)" (hourSegmentClicked)="hourSegmentClicked($event)"
                    (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-week-view>
            </div>
        </div>
        <mwl-calendar-day-view (beforeViewRender)="beforeViewRender($event,'day')" *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="eventClicked($event.event)"
            (hourSegmentClicked)="hourSegmentClicked($event)" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-day-view>
    </div>
    <div class="mt-4" *ngIf="detailedTableViewEnabled">
        <div class="mb-3 overflow-hidden bg-white shadow sm:rounded-md"
            *ngFor="let detailedactivity of detailedactivities">
            <a (click)="openActivityFromDetailedView(detailedactivity)" class="block hover:bg-gray-50 cursor-pointer">
                <div [ngClass]="{'px-4 py-4 sm:px-6': !detailedTableViewCompact,'px-1 py-1': detailedTableViewCompact}"
                    class="flex items-center">
                    <div class="flex min-w-0 flex-1 items-center">
                        <div class="min-w-0 flex-1 px-4 ">
                            <div>
                                <p [ngClass]="{'text-sm': !detailedTableViewCompact,'text-xs': detailedTableViewCompact}"
                                    class="truncate  font-medium text-indigo-600">
                                    {{detailedactivity.activity.name}}</p>
                                <p [ngClass]="{'text-sm': !detailedTableViewCompact,'text-xs': detailedTableViewCompact}"
                                    class="truncate font-medium">
                                    {{detailedactivity.formattedStartDate}}<span class="hidden sm:inline"> -
                                        {{detailedactivity.formattedStartDate}}</span></p>
                                <div>
                                    <h3 *ngIf="detailedactivity.contacts && detailedactivity.contacts.length > 0 && !detailedTableViewCompact"
                                        class="text-sm mt-3 mb-2">Contacts</h3>
                                    <h3 *ngIf="!detailedactivity.contacts || detailedactivity.contacts.length == 0 && !detailedTableViewCompact"
                                        class="text-sm mt-3 mb-2">No Contacts</h3>
                                    <div [ngClass]="{'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1': !detailedTableViewCompact,'': detailedTableViewCompact}"
                                        class=" w-full">
                                        <li [ngClass]="{'py-2': !detailedTableViewCompact,'': detailedTableViewCompact}"
                                            *ngFor="let contact of detailedactivity.contacts" class="flex ">
                                            <span
                                                *ngIf="!contact.contact?.user_profile?.profilePicture && !detailedTableViewCompact"
                                                class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                                                <span
                                                    class="text-xs font-medium leading-none text-white">{{getInitials(contact.contact.personal.firstName,contact.contact.personal.surname)}}</span>
                                            </span>
                                            <img *ngIf="contact.contact?.user_profile?.profilePicture && !detailedTableViewCompact"
                                                class="h-8 w-8 rounded-full"
                                                [src]="imagesUrl + contact.contact.user_profile.profilePicture.image + '_100x100.jpeg?alt=media'"
                                                alt="">
                                            <div [ngClass]="{'ml-3': !detailedTableViewCompact,'': detailedTableViewCompact}"
                                                class="">
                                                <p [ngClass]="{'font-medium': !detailedTableViewCompact,'font-light': detailedTableViewCompact}"
                                                    class="text-xs  text-gray-900">
                                                    <span *ngIf="detailedTableViewCompact">- </span>
                                                    {{contact.contact.personal.firstName}}
                                                    {{contact.contact.personal.surname}}
                                                </p>
                                                <p *ngIf="!detailedTableViewCompact" class="text-xs text-gray-500">
                                                    {{contact.contact.email}}</p>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                                <div *ngIf="!detailedTableViewCompact">
                                    <h3 *ngIf="detailedactivity.team && detailedactivity.team.length > 0"
                                        class="text-sm mt-3 mb-2">Team Members</h3>
                                    <h3 *ngIf="!detailedactivity.team || detailedactivity.team.length == 0"
                                        class="text-sm mt-3 mb-2">No Team Members</h3>
                                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 w-full">
                                        <li *ngFor="let teamMember of detailedactivity.team"
                                            class="flex py-2 items-center">
                                            <span *ngIf="!teamMember.image"
                                                class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                                                <span
                                                    class="text-xs font-medium leading-none text-white">{{getInitials(teamMember.firstName,teamMember.surname)}}</span>
                                            </span>
                                            <img *ngIf="teamMember.image" class="h-8 w-8 rounded-full"
                                                [src]="imagesUrl + teamMember.image.image + '_100x100.jpeg?alt=media'"
                                                alt="">
                                            <div class="ml-3">
                                                <p class="text-xs font-medium text-gray-900">
                                                    {{teamMember.firstName}}
                                                    {{teamMember.surname}}</p>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
<div class="fixed bottom-4 right-4 flex">
    <div *ngIf="detailedTableViewEnabled && !detailedTableViewCompact" (click)="toggleDetailedCompactView()"
        class="h-12 w-12 rounded-full  bg-white flex items-center justify-center cursor-pointer shadow-lg md:hidden">
        <fa-icon class="ng-fa-icon wild-text-primary text-lg" icon="minimize"></fa-icon>
    </div>
    <div *ngIf="detailedTableViewEnabled && detailedTableViewCompact" (click)="toggleDetailedCompactView()"
        class="h-12 w-12 rounded-full  bg-white flex items-center justify-center cursor-pointer shadow-lg md:hidden">
        <fa-icon class="ng-fa-icon wild-text-primary text-lg" icon="maximize"></fa-icon>
    </div>
    <div *ngIf="view == CalendarView.Day" (click)="toggleDetailedTableView()"
        class="ml-3 h-12 w-12 rounded-full  bg-white flex items-center justify-center cursor-pointer shadow-lg md:hidden">
        <fa-icon class="ng-fa-icon wild-text-primary text-lg"
            [icon]="detailedTableViewEnabled == true ? 'calendar-alt':'table'"></fa-icon>
    </div>
    <div *ngIf="isAllowedToSchedule() && !detailedTableViewEnabled" (click)="new()"
        class="ml-3 h-12 w-12 rounded-full  wild-bg-primary flex items-center justify-center cursor-pointer shadow-lg md:hidden">
        <fa-icon class="ng-fa-icon text-white text-lg" icon="calendar-plus"></fa-icon>
    </div>
</div>

<div class="fixed bottom-4 right-4 w-32">
    <div *ngIf="detailedTableViewEnabled && !detailedTableViewCompact" (click)="toggleDetailedCompactView()"
        class="w-full rounded-md bg-white items-center justify-start cursor-pointer shadow-lg py-2 px-2 hidden md:flex">
        <fa-icon class=" wild-text-primary text-lg" icon="minimize"></fa-icon>
        <p class=" wild-text-primary text-lg ml-2">Compact</p>
    </div>
    <div *ngIf="detailedTableViewEnabled && detailedTableViewCompact" (click)="toggleDetailedCompactView()"
        class="w-full rounded-md bg-white items-center justify-start cursor-pointer shadow-lg py-2 px-2 hidden md:flex">
        <fa-icon class=" wild-text-primary text-lg" icon="maximize"></fa-icon>
        <p class=" wild-text-primary text-lg ml-2">Expand</p>
    </div>
    <div *ngIf="view == CalendarView.Day" (click)="toggleDetailedTableView()"
        class="mt-3 w-full rounded-md bg-white items-center justify-start cursor-pointer shadow-lg py-2 px-2 hidden md:flex">
        <fa-icon class=" wild-text-primary text-lg"
            [icon]="detailedTableViewEnabled == true ? 'calendar-alt':'table'"></fa-icon>
        <p class=" wild-text-primary text-lg ml-2">{{detailedTableViewEnabled == true ? 'Calendar':'Table'}}</p>
    </div>
    <div *ngIf="isAllowedToSchedule() && !detailedTableViewEnabled" (click)="new()"
        class="mt-3 w-full rounded-md wild-bg-primary items-center justify-start cursor-pointer shadow-lg py-2 px-2 hidden md:flex">
        <fa-icon class=" text-white text-lg" icon="calendar-plus"></fa-icon>
        <p class=" text-white text-lg ml-2">Schedule</p>
    </div>
</div>


<!-- </app-wrapper> -->