import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { TeamService } from 'src/wild/modules/business/services/team_service/team_service';
import { inviteTeamMemberDTO } from '../../../../../../../../data/dtos/team/InviteTeamMemberDTO';

@Component({
  selector: 'app-addteammemberform',
  templateUrl: './addteammemberform.component.html'
})
export class AddteammemberformComponent implements OnInit {

  form: UntypedFormGroup;
  loading: boolean = false;

  constructor(private fb: UntypedFormBuilder, private modalService: ModalService, private teamService: TeamService, private navigation: NavigationService, private notify: NotifyService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required]),
      admin: new UntypedFormControl('', []),
      contacts: new UntypedFormControl('', []),
      calendar: new UntypedFormControl('', []),
      orders: new UntypedFormControl('', []),
      finance: new UntypedFormControl('', []),
      safety: new UntypedFormControl('', []),
      workingHours: new UntypedFormControl('', []),
    });
  }

  async onSubmit(data: AddTeamMemberFormData) {
    var roles: string[] = [];
    if (data.admin == true)
      roles.push("admin");
    if (data.contacts == true)
      roles.push("contacts");
    if (data.calendar == true)
      roles.push("calendar");
    if (data.orders == true)
      roles.push("orders");
    if (data.finance == true)
      roles.push("finance");
    if (data.workingHours == true)
      roles.push("workingHours");
    if (data.safety == true)
      roles.push("safety");
    if (roles.length == 0) {
      this.notify.notify("Please choose a role", "Choose at least one role to invite a new team member", NotifyType.ERROR);
      return;
    }
    this.loading = true;

    var dto: inviteTeamMemberDTO = {
      email: data.email,
      roles: roles
    }
    this.teamService.addTeamMember(dto)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })

  }

  next() {
    this.navigation.refreshPage(WildNavigationType.TEAM);
    this.notify.notify("Team Member Invited", "They have been sent an email to join your team", NotifyType.SUCCESS);
  }

  handlerError(message: string) {
    this.notify.notify("Error", message, NotifyType.ERROR);
  }

  resetForm() {
    this.loading = false;
  }

  cancel() {
    this.modalService.hide();
  }

}

interface AddTeamMemberFormData {
  email: string,
  admin: boolean,
  contacts: boolean,
  calendar: boolean,
  orders: boolean,
  finance: boolean,
  safety: boolean,
  workingHours: boolean,
}