import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-wildhero',
  template: `
  <!-- background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover; -->
  <div #hero class="relative overflow-hidden wild-bg-primary bg-bottom bg-no-repeat bg-cover">
  <div class="relative pt-6 pb-16 sm:pb-32 lg:pb-48">
    <main class="px-4 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6 lg:mt-32">
      <div class="lg:grid lg:grid-cols-12 lg:gap-8">
        <div class="text-center col-span-12 md:max-w-2xl md:mx-auto">
          <div class="text-center md:max-w-2xl md:mx-auto lg:col-span-12 lg:text-center">
            <h1>
              <span
                class="mx-auto text-center mt-1 block text-4xl tracking-tight font-extrabold xl:text-5xl max-w-lg xl:max-w-3xl">
                <span class="block text-white">{{header}}</span>
              </span>
            </h1>
            <p class="mt-3 text-base text-gray-50 sm:mt-5 sm:text-xl lg:text-lg font-extralight">
              {{subline}}
            </p>
            <div *ngIf="ctaonetext" class="mt-8 mb-12 sm:max-w-lg mx-auto text-center ">
              <button (click)="click()" type="button"
                class="wild-text-primary mt-3 px-6 py-3 border border-transparent text-base font-medium rounded-md bg-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
                {{ctaonetext}}
              </button>
              <p class="cursor-pointer text-sm hover:underline mt-4 text-white" (click)="clickTwo()">{{ctatwotext}}
              </p>
            </div>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>`
})
export class WildHeroComponent implements OnInit {

  @Input() image: string;
  @Input() header: string;
  @Input() subline: string;
  @Input() ctaonetext: string;
  @Input() ctatwotext: string;
  @ViewChild('hero') hero;
  @Output() onClick = new EventEmitter();
  @Output() onClickTwo = new EventEmitter();
  constructor() { }
  ngOnInit(): void {

  }

  click() {
    this.onClick.emit();
  }

  clickTwo() {
    this.onClickTwo.emit();
  }

  ngAfterViewInit() {
    this.hero.nativeElement.style.backgroundImage = "url('" + this.image + "')"
  }

}
