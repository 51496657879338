import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SelectModel } from 'src/shared/components/select/select_model';
import { CountryCodes } from '../../../../../../data/enums/country_codes';
import { AddressModel } from '../../../../../../data/models/address_model';
import { BusinessPublicModel } from '../../../../../../data/models/business_public_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { AddressService } from '../../../services/address/address_service';
import { BusinessService } from '../../../services/business_service/business_service';
import { AddressFormComponent } from './addressform.component';

@Component({
  selector: 'app-location',
  template: `
  <app-wrapper header="Addresses" paragraph="Add one or more addresses below and we'll add them to your business page">
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
            <app-cardlistwrapper>
                <div *ngFor="let address of business.addresses">
                    <app-cardlistitem ctatwoicon="pen" ctatwotext="Edit" (onCtaTwoClick)="edit(address)"
                        ctaonetext="Delete" ctaoneicon="trash" [ctaOneRed]="true" (onCtaOneClick)="del(address)">
                        <div class="text-left">
                            <p *ngIf="address.lineone">{{address.lineone}}, </p>
                            <p *ngIf="address.linetwo">{{address.linetwo}}, </p>
                            <p *ngIf="address.city">{{address.city}}, </p>
                            <p *ngIf="address.county">{{address.county}}, </p>
                            <p *ngIf="address.postcode">{{address.postcode}}</p>
                        </div>
                    </app-cardlistitem>
                </div>
                <app-cardlistnew icon="plus" (onClick)="new()"></app-cardlistnew>
            </app-cardlistwrapper>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>
  `
})
export class LocationComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private addressService: AddressService,
    private notifyService: NotifyService,
    private business: BusinessService) { }

  countryOptions: SelectModel[] = [
    {
      name: "United Kingdom",
      value: CountryCodes.GBGBR
    }
  ]

  public error: ErrorModel = null;
  business$: Observable<BusinessPublicModel>;



  ngOnInit(): void {
    this.load();
  }

  load() {
    this.business$ = null
    this.business$ = this.business.getPublicBusiness().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  new() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AddressFormComponent,
      componentOutputs: [{
        outputName: "updated",
        func: async () => {
          this.load();
        }
      }]
    }
    this.modalService.show(settings);
  }
  edit(address: AddressModel) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AddressFormComponent,
      componentInputs: [{
        inputName: "address",
        value: address
      }],
      componentOutputs: [{
        outputName: "updated",
        func: async () => {
          this.load();
        }
      }]
    }
    this.modalService.show(settings);
  }


  del(address: AddressModel) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to delete this address?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processDel(address);
      }
    }
    this.modalService.show(settings);
  }


  async processDel(address: AddressModel) {
    var settings: ModalModel = {
      header: "Deleting",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    this.addressService.del(address.id)
      .then(async () => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      });
  }

  handlerError(message: string) {
    this.notifyService.notify("Address Deletion", message, NotifyType.ERROR);
    this.modalService.hide();
  }

  next() {
    this.notifyService.notify("Address Deleted", "", NotifyType.SUCCESS);
    this.modalService.hide();
    this.load();
  }


}