import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { WildNavigationType } from '../../../data/enums/navigation_type';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }


    getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    navigateWithReturnUrl(type: WildNavigationType, url: string = this.router.url) {
        this.navigate(type, { returnUrl: url });
    }

    navigateAndRetainUrlParams(type: WildNavigationType) {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
            this.navigate(type, params);
        });
    }

    navigateWithReturnUrlCheck(type: WildNavigationType) {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
            console.log("PARAMS", params);
            if (params.returnUrl != undefined && params.returnUrl != null && params.returnUrl != "") {
                this.router.navigateByUrl(params.returnUrl);
            } else {
                delete params.returnUrl;
                this.navigate(type, params);
            }
        });
    }

    refreshPage(type: WildNavigationType, id: String = "") {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.navigate(type, params, id);
            });
        });
    }

    navigate(type: WildNavigationType, params: Params = {}, id: String = "") {
        var route = [];
        switch (type) {
            case WildNavigationType.DASHBOARDHOME:
                route = ['', 'business'];
                break;
            case WildNavigationType.HOME:
                route = [''];
                break;
            case WildNavigationType.LOGIN:
                route = ['', 'login'];
                break;
            case WildNavigationType.BROCHURESWIMSPOT:
                route = ['', 'swimspot', id];
                break;
            case WildNavigationType.SEARCH:
                route = ['', 'search'];
                break;
            case WildNavigationType.ROADMAP:
                route = ['', 'roadmap'];
                break;
            case WildNavigationType.REGISTER:
                route = ['', 'register'];
                break;
            case WildNavigationType.CONTACT:
                route = ['', 'contact'];
                break;
            case WildNavigationType.ABOUT:
                route = ['', 'about'];
                break;
            case WildNavigationType.SOS:
                route = ['', 'safeoutdoorswimming'];
                break;
            case WildNavigationType.BECOMEANORGANISER:
                route = ['', 'forbusiness'];
                break;
            case WildNavigationType.DEMO:
                route = ['', 'demo'];
                break;
            case WildNavigationType.PRICING:
                route = ['', 'pricing'];
                break;
            case WildNavigationType.BOOKINGBASKET:
                route = ['', 'basket'];
                break;
            case WildNavigationType.BOOKINGUSERDETAILS:
                route = ['', 'details'];
                break;
            case WildNavigationType.BOOKERDETAILS:
                route = ['', 'bookerdetails'];
                break;
            case WildNavigationType.BOOKINGCHECKOUT:
                route = ['', 'checkout'];
                break;
            case WildNavigationType.SWIMSCHOOLS:
                route = ['', 'swim-school-management-software'];
                break;
            case WildNavigationType.SURFSCHOOLS:
                route = ['', 'surf-school-management-software'];
                break;
            case WildNavigationType.SUPSCHOOLS:
                route = ['', 'sup-school-management-software'];
                break;
            case WildNavigationType.SAILINGSCHOOL:
                route = ['', 'sailing-school-management-software'];
                break;
            case WildNavigationType.OPENWATERVENUE:
                route = ['', 'open-water-venue-management-software'];
                break;
            case WildNavigationType.OPENWATERCOACH:
                route = ['', 'open-water-coach-management-software'];
                break;
            case WildNavigationType.REALTIMESAFETYSYSTEM:
                route = ['', 'realtime-water-safety-system'];
                break;
            case WildNavigationType.WAIVERSPAGE:
                route = ['', 'water-safety-waivers-system'];
                break;
            case WildNavigationType.USERWAIVERSTOSIGNPAGE:
                route = ['', 'user', 'waivers'];
                break;
            case WildNavigationType.TEAMMPAGE:
                route = ['', 'team-management-for-water-sports-business'];
                break;
            case WildNavigationType.BUSINESSAPP:
                route = ['', 'business-app'];
                break;
            case WildNavigationType.BUSINESSCRM:
                route = ['', 'crm-customer-relationship-management-water-sports'];
                break;
            case WildNavigationType.COMMUNICATIONS:
                route = ['', 'communications-with-customers'];
                break;
            case WildNavigationType.ORDERSFINANCE:
                route = ['', 'managing-finance-and-orders-water-sports'];
                break;
            case WildNavigationType.REPORTINGBROCHURE:
                route = ['', 'reporting-water-sports'];
                break;
            case WildNavigationType.BOOKINGEXPERIENCE:
                route = ['', 'customer-booking-experience'];
                break;
            case WildNavigationType.SCHEDULINGBOOKINGENGINE:
                route = ['', 'scheduling-booking-system-for-water-sports'];
                break;
            case WildNavigationType.ALLFEATURES:
                route = ['', 'all-features'];
                break;
            case WildNavigationType.DIRECTORY:
                route = ['', 'directory'];
                break;
            case WildNavigationType.NEWSLETTER:
                route = ['', 'newsletter'];
                break;
            case WildNavigationType.PRIVACY:
                route = ['', 'privacy'];
                break;
            case WildNavigationType.TERMS:
                route = ['', 'terms'];
                break;
            case WildNavigationType.USERNAMEPAGE:
                route = ['', 'username'];
                break;
            case WildNavigationType.ORDERCONFIRMATION:
                route = ['', 'confirmation'];
                break;
            case WildNavigationType.NOTFOUND:
                route = ['', '404'];
                break;
            case WildNavigationType.USERDASHBOARD:
                route = ['', 'user'];
                break;
            case WildNavigationType.USERORDERS:
                route = ['', 'user', 'orders'];
                break;
            case WildNavigationType.MANAGEMEMBERSHIP:
                route = ['', 'user', 'membership'];
                break;
            case WildNavigationType.LINKEDACCOUNTS:
                route = ['', 'user', 'linkedaccounts'];
                break;
            case WildNavigationType.USERVOUCHERS:
                route = ['', 'user', 'vouchers'];
                break;
            case WildNavigationType.USERMEMBERSHIPS:
                route = ['', 'user', 'memberships'];
                break;
            case WildNavigationType.USERACTIVITIES:
                route = ['', 'user', 'activities'];
                break;
            case WildNavigationType.USERACTIVITYDETAILS:
                route = ['', 'user', 'activities', id];
                break;
            case WildNavigationType.FORGOTTONPASSWORD:
                route = ['', 'forgotten'];
                break;
            case WildNavigationType.BUSINESSPLUGINS:
                route = ['', 'business', 'integrations'];
                break;
            case WildNavigationType.BUSINESSROADMAP:
                route = ['', 'business', 'roadmap'];
                break;
            case WildNavigationType.GROUPS:
                route = ['', 'change'];
                break;
            case WildNavigationType.ONBOARDING:
                route = ['', 'onboarding'];
                break;
            case WildNavigationType.ONBOARDINGV2:
                route = ['', 'onboardingv2'];
                break;
            case WildNavigationType.INVITE:
                route = ['', 'invite'];
                break;
            case WildNavigationType.BUSINESSSWIMSPOTS:
                route = ['', 'business', 'settings', 'swimspots'];
                break;
            case WildNavigationType.DISCOUNTCODESLIST:
                route = ['', 'business', 'settings', 'discountcodes'];
                break;
            case WildNavigationType.DISCOUNTCODESRECORD:
                route = ['', 'business', 'settings', 'discountcodes',id];
                break;
            case WildNavigationType.BUSINESSSWIMSPOTDETAILS:
                route = ['', 'business', 'swimspots', id];
                break;
            case WildNavigationType.BUSINESSSERVICEDETAILS:
                route = ['', 'business', 'settings', "services", id];
                break;
            case WildNavigationType.WAIVERSCONFIG:
                route = ['', 'business', 'settings', "waivers"];
                break;
            case WildNavigationType.BUSINESSCONTACTS:
                route = ['', 'business', 'contacts'];
                break;
            case WildNavigationType.BUSINESSORDERS:
                route = ['', 'business', 'orders'];
                break;
            case WildNavigationType.INTEGRATIONS:
                route = ['', 'business', 'integrations'];
                break;
            case WildNavigationType.WIDGETINTEGRATION:
                route = ['', 'business', 'integrations', 'widget'];
                break;
            case WildNavigationType.IFRAMEINTEGRATION:
                route = ['', 'business', 'integrations', 'iframe'];
                break;
            case WildNavigationType.BUSINESSACTIVITIES:
                route = ['', 'business', 'activities'];
                break;
            case WildNavigationType.BUSINESSFINANCE:
                route = ['', 'business', 'finance'];
                break;
            case WildNavigationType.PORTALSWIMSPOTDETAILS:
                route = ['', 'swimspotsupdate', id];
                break;
            case WildNavigationType.BRAND:
                route = ['', 'business', 'settings', 'brand'];
                break;
            case WildNavigationType.BUSINESSLOCATION:
                route = ['', 'business', 'settings', 'location'];
                break;
            case WildNavigationType.BUSINESSAREA:
                route = ['', 'business', 'settings', 'area'];
                break;
            case WildNavigationType.CLASSPASSES:
                route = ['', 'business', 'settings', 'classpasses'];
                break;
            case WildNavigationType.CLASSPASSESEDIT:
                route = ['', 'business', 'settings', 'classpasses', id];
                break;
            case WildNavigationType.MEMBERSHIPS:
                route = ['', 'business', 'settings', 'memberships'];
                break;
            case WildNavigationType.MEMBERSHIPSEDIT:
                route = ['', 'business', 'settings', 'memberships', id];
                break;
            case WildNavigationType.BUSINESSMODE:
                route = ['', 'business', 'settings', 'mode'];
                break;
            case WildNavigationType.BUSINESSPAYOUTS:
                route = ['', 'business', 'payouts'];
                break;
            case WildNavigationType.BUSINESSBANK:
                route = ['', 'business', 'settings', 'bank'];
                break;
            case WildNavigationType.NEWSWIMSPOTFROMBUSINESS:
                route = ['', 'business', 'swimspots', 'new'];
                break;
            case WildNavigationType.SETTINGS:
                route = ['', 'business', 'settings'];
                break;
            case WildNavigationType.BUSINESSCONFIGURATION:
                route = ['', 'business', 'settings', 'configuration'];
                break;
            case WildNavigationType.BUSINESSONLINEBOOKINGSETTINGS:
                route = ['', 'business', 'settings', 'onlinebookings'];
                break;
            case WildNavigationType.TEAM:
                route = ['', 'business', 'settings', 'team'];
                break;
            case WildNavigationType.BUSINESSSTATUS:
                route = ['', 'business', 'settings', 'status'];
                break;
            case WildNavigationType.BUSINESSSERVICES:
                route = ['', 'business', 'settings', 'services'];
                break;
            case WildNavigationType.BUSINESSUSERSETTINGS:
                route = ['', 'business', 'usersettings'];
                break;
            case WildNavigationType.BUSINESSCALENDAR:
                route = ["", "business", "usersettings", "calendar", id];
                break;
            case WildNavigationType.BUSINESSUPDATEMYDETAILS:
                route = ["", "business", "usersettings", "details", id];
                break;
            case WildNavigationType.BUSINESSSEARCHRESULTS:
                route = ['', 'business', 'search'];
                break;
            case WildNavigationType.BUSINESSCATEGORIES:
                route = ['', 'business', 'categories'];
                break;
            case WildNavigationType.TIMETABLES:
                route = ['', 'business', 'timetables'];
                break;
            case WildNavigationType.TIMETABLESDETAILS:
                route = ['', 'business', 'timetables', 'details', id];
                break;
            case WildNavigationType.WELCOMEEMAIL:
                route = ['', 'business', 'settings', 'welcomeemail'];
                break;
            case WildNavigationType.USERSEARCHRESULTS:
                route = ['', 'user', 'search'];
                break;
            case WildNavigationType.USERPROFILEPAGE:
                route = ['', 'user', 'profile'];
                break;
            case WildNavigationType.USERNOTIFICATIONS:
                route = ['', 'user', 'notifications'];
                break;
            case WildNavigationType.FAVOURITES:
                route = ['', 'user', 'favourites'];
                break;
            case WildNavigationType.MYSWIMSPOTS:
                route = ['', 'user', 'myswimspots'];
                break;
            case WildNavigationType.USERADDSWIMSPOT:
                route = ['', 'addswimspot'];
                break;
            case WildNavigationType.USERSWIMSPOTDETAILS:
                route = ['', 'user', 'swimspots', id];
                break;
            case WildNavigationType.GARMININTEGRATION:
                route = ['', 'integrations', 'garmin'];
                break;
            case WildNavigationType.SUUNTOINTEGRATION:
                route = ['', 'integrations', 'suunto'];
                break;
            case WildNavigationType.FITBITINTEGRATION:
                route = ['', 'integrations', 'fitbit'];
                break;
            case WildNavigationType.WHERETO:
                route = ['', 'whereto'];
                break;
            default:
                break;
        }
        this.router.navigate(route, {
            queryParams: params,
            // replaceUrl: true,
            // skipLocationChange: true,


        });
    }

}
