<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }" class="py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div *ngIf="data.title || data.subtitle" class="mx-auto max-w-2xl lg:text-center">
      <app-titles [data]="data"></app-titles>
    </div>
    <div *ngIf="data.data && (data.data?.length ?? 0) > 0" class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl class="grid grid-cols-1 sm:grid-cols-2 gap-x-8 lg:max-w-none lg:grid-cols-3">
        <div *ngFor="let datarecord of data.data;let i=index">
          <div *ngIf="i<data.limit"class="flex flex-col mt-12">
            <img [ngClass]="{'cursor-pointer': datarecord.page}" (click)="click(datarecord)" *ngIf="datarecord.image" class="h-24 lg:h-48 w-24 lg:w-48 rounded-md mb-4" [src]="datarecord.image" alt="">
            <dt class="flex items-start gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <fa-icon [class]="brochureSiteHelperService.getTextColourClassBasedOnIndex(i)" *ngIf="datarecord.icon" class="h-5 w-5 flex-none" [icon]="datarecord.icon"></fa-icon>
              {{datarecord.title}} 
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">{{datarecord.subtitle}}</p>
              <p *ngIf="datarecord.page" class="mt-6">
                <a [class]="brochureSiteHelperService.getTextColourClassBasedOnIndex(i)" (click)="click(datarecord)" class="text-sm font-semibold leading-6 cursor-pointer">Learn more <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
        </div>
      </dl>
    </div>
    <div *ngIf="data.ctas" class="mt-4 sm:mt-8 md:mt-12">
      <app-ctas [data]="data"></app-ctas>
    </div>
  </div>
</div>
