<app-wrapper header="Online Booking Settings" paragraph="Adjust the booking configuration for your business">
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
            <app-detailssection>
                <div class="divide-y divide-gray-200">
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt class="text-sm font-medium text-gray-500">Time Slot Interval Is</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span class="flex-grow">{{getTimeSlotDescription(business)}}</span>
                            <span class="ml-4 flex-shrink-0">
                                <button (click)="editTimeSlotInterval(business)" type="button"
                                    class="rounded-md bg-white text-blue-500 text-xs hover:underline">change</button>
                            </span>
                        </dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt class="text-sm font-medium text-gray-500">Customers Can Cancel</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span class="flex-grow">{{getCancellationRuleDescription(business)}}</span>
                            <span class="ml-4 flex-shrink-0">
                                <button (click)="editRefundRule(business)" type="button"
                                    class="rounded-md bg-white text-blue-500 text-xs hover:underline">change</button>
                            </span>
                        </dd>
                    </div>

                    <!-- <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt class="text-sm font-medium text-gray-500">Time Slot Interval</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span class="flex-grow">
                                <span *ngIf="service.description">{{service.description}}</span>
                                <span *ngIf="!service.description" class='text-xs italic text-red-400'>required</span>
                            </span>
                            <span class="ml-4 flex-shrink-0">
                                <button (click)="editTimeSlotInterval(business)" type="button"
                                    class="rounded-md bg-white text-blue-500 text-xs hover:underline">Update</button>
                            </span>
                        </dd>
                    </div> -->

                </div>
            </app-detailssection>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>