<app-lightweightmenu *ngIf="!isAppUser"></app-lightweightmenu>
<ng-container *ngIf="businesses$ | async as businesses; else loadingOrError">
    <div *ngIf="!businesses || businesses.length == 0; else changebusiness">
        <app-empty ctatext="Get Started" icon="swimmer" header="No businesses yet" [showcta]='true'
            ctatext="Add Business" (onClick)="new()" description="Get Started with our quick onboarding"
            [ctatwotext]="ctatwotext" (onClickTwo)="backToApp()"></app-empty>
    </div>
    <ng-template #changebusiness>
        <app-changebusiness [businesses]="businesses"></app-changebusiness>
        <div class="my-4 flex items-center justify-center">
            <a *ngIf="isAppUser" (click)="backToApp()" class="font-medium text-md cursor-pointer">back to app</a>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-wrapper>
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="retry()">
            </app-retry>
        </app-wrapper>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</ng-template>