import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const financetab: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FFinance%20Image%20(1).png?alt=media&token=1d5a066a-94f0-404b-9a6e-8c751dd2751a",
  title: "Payments & Analysis",
  subtitle: "Get Paid Daily",
  paragraph: "We use Stripe & you'll get paid every day from Wild.",
  data:[
    {
      icon: "shopping-bag", title: "Payouts.", subtitle: "Daily payouts on a rolling bases."
    },
    {
      icon: "lock", title: "Verify.", subtitle: "Verify your identify & link your bank"
    },
    {
      icon: "line-chart", title: "Reports.", subtitle: "Stripe & dashboard reports."
    },
  ]
}