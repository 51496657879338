<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }" class="py-24 sm:py-32 hidden sm:block">
  <div class="relative z-30">
    <app-titles [data]="data"></app-titles>
    <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
      <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div class="col-span-1 flex justify-center lg:justify-start  md:col-span-2 lg:col-span-1">
          <img class="h-12" 
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20Garmin.png?alt=media&token=ae9c599d-3804-4757-8eaa-dc90996428c2"
            alt="Garmin">
        </div> 
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20Fitbit-2.png?alt=media&token=ab7ef053-870e-4d5a-a2ea-3e6535fd591f"
            alt="fitbit">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20w3w.png?alt=media&token=1ac38daf-187a-4c7c-99b4-ae37e7ea17c4"
            alt="w3w">
        </div>
        <div class="col-span-1 flex justify-center  md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20ocean%20gen.png?alt=media&token=4711ab5e-1d50-415a-983b-66b0a53fa0bd"
            alt="Ocean Generataion">
        </div>
        <div class="col-span-1 flex justify-center lg:justify-end md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Fogo%20cloud%20-%20suunto.png?alt=media&token=d01fc97c-50fc-4f23-925d-101cbb3e38d4"
            alt="Suunto">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20RNLI.png?alt=media&token=dff3ce80-2117-4bd0-aefa-912b209c65e7"
            alt="RNLI">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20SLS.png?alt=media&token=ae4600af-b460-49b0-92b8-d8d1611bec82"
            alt="SLS">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20wild%20swine%20store.png?alt=media&token=bfc7b16a-3bdf-456c-9b39-0d2b4780c928"
            alt="Wild Swim Store">
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20zoggs.png?alt=media&token=acda9f6c-0f71-4398-afad-fd5e77dda4f9"
            alt="Zoggs">
        </div>
        <div class="col-span-1 flex justify-center md:hidden lg:flex lg:col-span-1">
          <img class="h-12"
            src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/partnerlogosforlogocloud%2Flogo%20cloud%20-%20Stripe-2.png?alt=media&token=4b005c01-6233-4021-99e9-baf29ced0532"
            alt="Stripe">
        </div>
      </div>
      <div *ngIf="data.ctas" class="mt-12">
        <app-ctas [data]="data"></app-ctas>
      </div>
    </div>
  </div>
</div>