import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { UpdateActivityDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityDTO';
import { Activity } from '../../../../../../data/models/activity_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UtilService } from 'src/shared/services/util/util_service';
import { UpdateActivityService } from '../services/updateactivity_service';

@Component({
  template: `
  <app-modaltopper [header]="activity.name ?? 'Appointment'" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Submit" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="cancel()">
      <app-inputgroup>
       <app-formdatepicker [span]=6 [form]="form" name="startDateIso" description="Start Date" [value]="startDate" ></app-formdatepicker>
      </app-inputgroup>
      <fieldset class="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-2 mt-5">
          <app-checkbox [form]="form" label="All Day" name="allday" [value]="allday"></app-checkbox>
      </fieldset>
      <app-inputgroup [ngClass]="{'hidden': allday}">
        <app-select [options]="hourOptions" [form]="form" name="startHour" description="Hours" [span]=3 [value]="startHour">
        </app-select>
        <app-select [options]="minuteOptions" [form]="form" name="startMinutes" description="Minutes" [span]=3 [value]="startMinutes">
        </app-select>
      </app-inputgroup>
      <app-inputgroup>
       <app-formdatepicker [span]=6 [form]="form" name="endDateIso" description="End Date" [value]="endDate" ></app-formdatepicker>
      </app-inputgroup>
      <app-inputgroup [ngClass]="{'hidden': allday}">
        <app-select [options]="hourOptions" [form]="form" name="endHour" description="Hours" [span]=3 [value]="endHour">
        </app-select>
        <app-select [options]="minuteOptions" [form]="form" name="endMinutes" description="Minutes" [span]=3 [value]="endMinutes">
        </app-select>
      </app-inputgroup>
  </app-form> 
  `
})
export class AppointmentEditFormComponent implements OnInit {

  @Input() activity: Activity;
  @Input() newstart: Date;
  @Input() newend: Date;
  @Output() updated = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private utilService: UtilService,
    private updateActivityService: UpdateActivityService,
    private dateService: DatesService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  hourOptions: SelectModel[] = [];
  minuteOptions: SelectModel[] = [];
  startDate: string;
  endDate: string;
  startHour: number;
  startMinutes: number;
  endHour: number;
  endMinutes: number;
  allday: boolean = false;

  ngOnInit(): void {
    this.allday = this.activity?.allday ?? false;
    if (this.newstart == null) {
      this.startDate = this.activity.startDateIso;
      this.endDate = this.activity.endDateIso;
    } else {
      this.startDate = this.dateService.getIsoDateFromObjectNotInUTC(this.newstart);
      this.endDate = this.dateService.getIsoDateFromObjectNotInUTC(this.newend);
    }
    if (this.newstart == null) {
      this.startHour = this.dateService.getHour(this.activity.startDateIso);
      this.startMinutes = this.dateService.getMinute(this.activity.startDateIso);
    } else {
      this.startHour = this.dateService.getHourFromDate(this.newstart);
      this.startMinutes = this.dateService.getMinuteFromDate(this.newstart);
    }
    if (this.newend == null) {
      this.endHour = this.dateService.getHour(this.activity.endDateIso);
      this.endMinutes = this.dateService.getMinute(this.activity.endDateIso);
    } else {
      this.endHour = this.dateService.getHourFromDate(this.newend);
      this.endMinutes = this.dateService.getMinuteFromDate(this.newend);
    }
    this.hourOptions = this.utilService.getNumberOptions(24, 1);
    this.minuteOptions = this.utilService.getNumberOptions(12, 5);
    this.form = this.fb.group({
      startDateIso: new UntypedFormControl('', [Validators.required]),
      startHour: new UntypedFormControl('', [Validators.required]),
      allday: new UntypedFormControl(this.allday, [Validators.required]),
      startMinutes: new UntypedFormControl('', [Validators.required]),
      endDateIso: new UntypedFormControl('', [Validators.required]),
      endHour: new UntypedFormControl('', [Validators.required]),
      endMinutes: new UntypedFormControl('', [Validators.required]),
    });
    this.form.controls["allday"].valueChanges.pipe(
    ).subscribe((allday) => {
      this.allday = allday;
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    var dto: UpdateActivityDTO = {
      startDate: this.dateService.addDays(data.startDateIso, 0),
      endDate: this.dateService.addHours(data.endDateIso, 0),
      allday: data.allday,
      startHour: data.startHour,
      startMinutes: data.startMinutes,
      endHour: data.endHour,
      endMinutes: data.endMinutes
    }
    this.updateActivityService.updateDates(this.activity.id, dto)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Update", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  cancel() {
    this.modalService.hide();
    //This ensures the data is refreshed so it doesn't keep the resized calendar item in the view
    if (this.newend != null)
      this.updated.emit();
  }

  resetForm() {
    this.loading = false;
  }

}
