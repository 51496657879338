import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocationResultType } from '../../data/enums/location_result_type';
import { WildNavigationType } from '../../data/enums/navigation_type';
import { SearchResult } from '../../data/models/SearchResult';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  selector: "app-usersearchresult",
  template: `
 <li class="relative" [ngStyle]="{'width': fixed ? '200px' : 'auto' }">
                                            <div 
                                                class="group block w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                                <img [src]="result?.image != null && result?.image != '' ? imagesUrl + result.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                                                    alt=""
                                                    class="object-cover pointer-events-none group-hover:opacity-75">
                                                <button (click)="chooseResult(result)" type="button"
                                                    class="absolute inset-0 focus:outline-none">
                                                    <span class="sr-only">View details for {{result.name}}</span>
                                                </button>
                                            </div>
                                            <div class="mt-3 flex items-center justify-between">
                                                <div *ngIf="result.reviewaverage">
                                                    <div *ngIf="result.type == LocationResultType.SWIMSPOT"
                                                        class="flex items-center">
                                                        <svg [ngClass]="{'text-yellow-400':result.reviewaverage >= 1,'text-gray-300':!result?.reviewaverage || result.reviewaverage < 1 }"
                                                            class="flex-shrink-0 h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                            fill="currentColor" aria-hidden="true">
                                                            <path
                                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                        <svg [ngClass]="{'text-yellow-400':result.reviewaverage >= 2,'text-gray-300':!result?.reviewaverage || result.reviewaverage < 2}"
                                                            class="flex-shrink-0 h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                            fill="currentColor" aria-hidden="true">
                                                            <path
                                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                        <svg [ngClass]="{'text-yellow-400':result.reviewaverage >= 3,'text-gray-300':!result?.reviewaverage || result.reviewaverage < 3}"
                                                            class="flex-shrink-0 h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                            fill="currentColor" aria-hidden="true">
                                                            <path
                                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                        <svg [ngClass]="{'text-yellow-400':result.reviewaverage >= 4,'text-gray-300':!result?.reviewaverage || result.reviewaverage < 4}"
                                                            class="flex-shrink-0 h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                            fill="currentColor" aria-hidden="true">
                                                            <path
                                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                        <svg [ngClass]="{'text-yellow-400':result.reviewaverage >= 5,'text-gray-300':!result?.reviewaverage || result.reviewaverage < 5}"
                                                            class="flex-shrink-0 h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                            fill="currentColor" aria-hidden="true">
                                                            <path
                                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                        <!-- <p *ngIf="result?.reviewaverage && result?.reviewaverage > 0"
                                                            class="ml-2 text-sm text-gray-900">
                                                            ({{result?.reviewaverage | number: '1.0-1'}})
                                                        </p> -->
                                                    </div>
                                                </div>
                                                <div *ngIf="result.distance" class=" text-sm font-medium text-gray-700 pointer-events-none">
                                                    {{result.distance | number: '1.0-0'}}km
                                                </div>
                                            </div>
                                            <p
                                                class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none text-ellipsis">
                                                {{result.name}}</p>
                                            <p class="block text-xs font-medium text-gray-500 pointer-events-none">
                                                {{result.locationDescription}}</p>
                                        </li>
  `
})
export class SearchResultComponent implements OnInit {

  @Input() result: SearchResult;
  @Input() fixed: boolean;
  LocationResultType = LocationResultType;
  imagesUrl = environment.imagesUrl;
  @Output() onClick = new EventEmitter();

  constructor(
    private navigation: NavigationService,
    private router: Router) { }

  ngOnInit(): void {

  }

  chooseResult(result: SearchResult) {
    this.onClick.emit();
    if (result.type == LocationResultType.PLACE) {
      this.navigation.navigate(WildNavigationType.SEARCH, {
        query: result.name,
        latitude: result.latitude,
        longitude: result.longitude
      })
    } else if (result.type == LocationResultType.SWIMSPOT) {
      this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, result.canonical)
    } else if (result.type == LocationResultType.BUSINESS) {
      this.router.navigate([result.canonical]);
    }
  }


}
