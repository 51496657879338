<app-modaltopper header="Send Message" [parapraph]="header"></app-modaltopper>
<app-form [form]="form" [fullwidthbutton]="false" ctaText="Send" disableDivide="true" (onSubmit)="onSubmit($event)"
    [loading]="loading">
    <app-inputgroup>
        <app-input [form]="form" type="text" name="subject" description="Subject" [span]=6 validation="Between 10 and 100 characters">
        </app-input>
    </app-inputgroup>
    <small class="text-xs text-gray-500">Subject is used for email subject AND push notification subject</small>
    <app-inputgroup>
        <app-input [form]="form" type="text" name="title" description="Title" [span]=6 validation="Between 10 and 100 characters">
        </app-input>
    </app-inputgroup>
    <app-inputgroup>
        <app-textarea [form]="form" type="text" name="body" description="Body" [span]=6 validation="Between 25 and 2000 characters">
        </app-textarea>
    </app-inputgroup>
</app-form>