<div *ngIf="mapsLoaded | async" class="w-full shadow-lg rounded-lg bg-blue-500 overflow-hidden" style="height:60vh">
    <google-map (mapClick)="updateMarker($event)" height="100%" width="100%" [options]="options">
        <map-marker (mapDragend)="updateMarker($event)" *ngFor="let markerPosition of markerPositions"
            [position]="markerPosition" [options]="markerOptions"></map-marker>
    </google-map>
</div>
<div class="flex items-center justify-between w-full mt-3">
    <div>
        <span class="text-sm text-gray-400">{{lat}}, {{lng}}</span>
    </div>
    <div>
        <button (click)="goBack()" class="wild-btn-light mr-3">Back</button>
        <button (click)="locationConfirmed()" class="wild-btn-primary">Next</button>
    </div>

</div>