import { Component, OnInit } from '@angular/core';
import { SideMenuItems } from 'src/shared/components/sidemenu/sidemenu_items_model';
import { LocationResultType } from '../../../../../data/enums/location_result_type';
import { SearchResult } from '../../../../../data/models/SearchResult';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  userMenuItems: SideMenuItems[] = [
    {
      description: "Profile",
      route: ["/user/profile"],
      icon: "user-alt",
      visible: true
    },
    {
      description: "Booking Details",
      route: ["/user/details"],
      icon: "user-cog",
      visible: true
    },
    {
      description: "Linked Accounts",
      route: ["/user/linkedaccounts"],
      icon: "users",
      visible: true
    },
    {
      description: "Booked Activities",
      route: ["/user/activities"],
      icon: "calendar-alt",
      visible: true
    },
    {
      description: "Waivers to Sign",
      route: ["/user/waivers"],
      icon: "signature",
      visible: true
    },
    {
      description: "Orders",
      route: ["/user/orders"],
      icon: "shopping-bag",
      visible: true
    }, {
      description: "Favourites",
      route: ["/user/favourites"],
      icon: "heart",
      visible: true
    },
    {
      description: "My Spots",
      route: ["/user/myswimspots"],
      icon: "swimmer",
      visible: true
    },
    {
      description: "Wild Pro",
      route: ["/user/membership"],
      icon: "swimmer",
      visible: true
    },
  ]

  constructor(private navigation: NavigationService,) { }

  ngOnInit(): void {
  }

  addSwimSpot() {
    this.navigation.navigate(WildNavigationType.USERADDSWIMSPOT)
  }

  searchSelected(result: SearchResult) {
    if (result.type == LocationResultType.PLACE) {
      this.navigation.navigate(WildNavigationType.USERSEARCHRESULTS, { query: result.name, latitude: result.latitude, longitude: result.longitude });
    } else if (result.type == LocationResultType.SWIMSPOT) {
      this.navigation.navigate(WildNavigationType.USERSWIMSPOTDETAILS, {}, result.id);
    }
  }


}
