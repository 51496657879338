import { Component, Input, OnInit } from '@angular/core';

@Component({
selector: "app-searchresultsrowhimmer",
    template: `  
        <h1 class="w-32 mb-2 h-6 animate-pulse bg-gray-200"></h1> 
        <div class="flex w-full mb-3">
            <div class="mr-4" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]">
                <app-searchresulthimmer ></app-searchresulthimmer>
            </div>
        </div>
  `
})
export class SearchResultsRowShimmer  {

}
