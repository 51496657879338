import { Component,  OnInit } from '@angular/core';

@Component({
  selector: 'app-gettheapp',
  styleUrls: ['./gettheapp.component.scss'],
  templateUrl: './gettheapp.component.html',
})
export class GetTheAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
