<app-wrapper [fullwidth]="true" header="Subscription & Configuration"
    paragraph="Manage subscription and view business configuration">
    <ng-container *ngIf="configuration$ | async as configuration; else loadingOrError">
        <div *ngIf="!configuration; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
            <div class="flex flex-col md:flex-row">
                <div class="md:w-2/4 lg:w-3/5">
                    <div class="rounded-lg shadow bg-white p-4 mt-4">
                        <h2 class="text-sm font-medium  mb-1">Email Allowance</h2>
                        <button (click)="emailBundleLinks()" class="wild-btn-primary my-3">Buy More Emails</button>
                        <p class="text-sm  mb-3">Free Monthly Allowance: {{configuration.monthlyEmailAllowance}}</p>
                        <p class="text-sm  mb-3">Paid Email Credits: {{configuration.remainingEmailCredit}}</p>
                        <p class="text-sm  mb-3">Emails Sent this Month: {{configuration.monthlyEmailUsage}}</p>
                        <p class="text-sm  mb-3" [ngClass]="{'text-green-600 font-bold':(configuration.remainingEmailsToUseThisMonth > emailGreenThreshold),'text-red-600 font-bold':(configuration.remainingEmailsToUseThisMonth <= emailRedThreshold), 'text-yellow-600 font-bold':(configuration.remainingEmailsToUseThisMonth > emailRedThreshold && configuration.remainingEmailsToUseThisMonth < emailAmberThreshold )}">Remaining to use this month: {{configuration.remainingEmailsToUseThisMonth}}</p>
                        <div class="rounded-md bg-blue-50 p-4 mb-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div class="ml-3 flex-1 md:flex md:justify-between">
                                    <p class="text-sm text-blue-700">Order confirmations & refund emails, welcome emails and reminders are included free or charge. You only pay for additional messages you send to contacts.</p>
                                    <p class="mt-3 text-sm md:mt-0 md:ml-6"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:w-2/4 lg:w-2/5 md:pl-4 mt-4 md:mt-0">
                    <div class="rounded-lg shadow bg-white p-4">
                        <h2 class="text-sm font-medium text-gray-900 mb-4">My Plan</h2>
                        <p class="text-xs font-semibold text-gray-700">Country</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.country}}</p>
                        <p class="text-xs font-semibold text-gray-700">Time Zone</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.timezone}}</p>
                        <p class="text-xs font-semibold text-gray-700">Subscription</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.plan}}</p>
                        <div *ngIf="configuration.subscriptionActive == true">
                            <p class="text-xs font-semibold text-gray-700">
                                Subscription Active</p>
                            <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.subscriptionActive == true ?
                                'Yes':
                                'No'}}</p>
                        </div>
                        <div *ngIf="showstripefees">
                            <p class="text-xs font-semibold text-gray-700">Stripe Card Fees</p>
                            <p class="text-sm text-gray-600 mt-1 leading-none">{{configuration.stripeFee}}</p>
                            <a [href]="configuration.cardFeesLink" target="_blank"
                                class="block text-xs text-blue-500 mb-4 mt-1">read
                                more</a>
                            <p class="text-xs font-semibold text-gray-700">Stripe Payout Fees</p>
                            <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.payoutFee}}</p>
                            <p class="text-xs font-semibold text-gray-700">Cross Border Fee</p>
                            <p class="text-sm text-gray-600 mt-1 leading-none">{{configuration.crossBorderFee}}</p>
                            <a [href]="configuration.borderFeesLink" target="_blank"
                                class="block text-xs text-blue-500 mb-4 mt-1">read
                                more</a>
                        </div>
                        <div *ngIf="configuration.debitBalanceEnabled">

                        </div>
                        <p class="text-xs font-semibold text-gray-700">Negative Balance Debits</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.debitBalanceEnabled ? "enabled":"not
                            enabled"}}</p>
                        <p class="text-xs font-semibold text-gray-700">Negative Balance Limit</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.negativeBalanceLimit}}</p>
                        <p class="text-xs font-semibold text-gray-700">Payout Delay</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">
                            {{configuration.numberOfDaysFundsHeldOnForBeforePayout}} Days</p>
                        <p class="text-xs font-semibold text-gray-700">Payout Interval</p>
                        <p class="text-sm text-gray-600 mb-4 mt-1">{{configuration.intervalDescriptionOnPayout}}</p>
                        <a *ngIf="configuration.subscriptionActive" class="text-red-500 hover:underline text-xs cursor-pointer" (click)="cancelPlan()">cancel
                            plan</a>
                    </div>
                    <br />
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>