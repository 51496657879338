import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from 'src/shared/services/seo/seo.service';
import { Observable, throwError } from 'rxjs';
import { BeaconModel } from '../../../../../data/models/beacon_model';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../../../data/models/error_model';
import { BeaconRepositoryService } from '../../services/beacon_repository_service/beacon-repository.service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-beacon',
  templateUrl: './beacon.component.html',
})
export class BeaconComponent implements OnInit {

  beacons$: Observable<BeaconModel[]>
  error: ErrorModel;

  constructor(private router: Router, private route: ActivatedRoute, private beaconService: BeaconRepositoryService, private navigate: NavigationService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.beacons$ = null;
    var id = this.route.snapshot.paramMap.get('id');
    console.log("id", id)
    this.beacons$ = this.beaconService.get(id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );

  }

  home() {
    this.navigate.navigate(WildNavigationType.HOME);
  }

  openSwimSpot(beacon: BeaconModel) {
    this.navigate.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, beacon.swimSpotLight.canonical)
  }

}
