import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';
import { ForgottenComponent } from './forgotten.component';
import { RegisterComponent } from './register.component';
import { UsernameComponent } from './username.component';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

interface LoginFormData {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login', template: `
  <app-modaltopper [logo]="false" [header]="isBusiness == true ? 'Sign In for Business' : 'Sign In'" ctatext="Register" (onClick)="goToRegister()"></app-modaltopper>
<div [ngClass]="{'p-4':!isPage}">
  <app-form [form]="form" [fullwidthbutton]="true" ctaText="Sign In" disableDivide="true" (onSubmit)="onSubmit($event)"
    [loading]="loading">
    
    <app-inputgroup>
        <app-input [form]="form" type="email" name="email" description="Email" [span]=6></app-input>
    </app-inputgroup>
    <app-inputgroup>
        <app-input [form]="form" type="password" name="password" description="Password" [span]=6>
        </app-input>
    </app-inputgroup>
    <app-checkboxandlink linktext="Forgotten you password?" (onClick)="goToForgotten()"></app-checkboxandlink>
</app-form>
<button  (click)="facebookLogin()" class="fb wild-btn bg-blue-500 text-white my-2 w-full flex items-center justify-center"> 
  <span *ngIf="!loading"><fa-icon class="mr-2" [icon]="['fab', 'facebook']"></fa-icon>Sign In with Facebook</span>
  <div *ngIf="loading" class="loader"></div>
</button>
<button  (click)="googleLogin()" class="google wild-btn bg-red-500 text-white my-2 w-full flex items-center justify-center"> 
<span *ngIf="!loading"><fa-icon class="mr-2" [icon]="['fab', 'google']"></fa-icon>Sign In with Google</span>
  <div *ngIf="loading" class="loader"></div>
</button>
<button  (click)="appleLogin()" class="google wild-btn bg-black text-white my-2 w-full flex items-center justify-center"> 
<span *ngIf="!loading"><fa-icon class="mr-2" [icon]="['fab', 'apple']"></fa-icon>Sign In with Apple</span>
  <div *ngIf="loading" class="loader"></div>
</button>
<button *ngIf="!loading && isPage == true && isBusiness == true" (click)="switchToUser()" class="wild-btn-light w-full"> 
  <span><fa-icon class="md:mr-2" [icon]="swap"></fa-icon>Switch to User Mode</span>
</button>
<button *ngIf="!loading && isPage == true  && isBusiness != true" (click)="switchToBusiness()" class="wild-btn-light w-full"> 
  <span><fa-icon class="md:mr-2" [icon]="swap"></fa-icon>Switch to Business Mode</span>
</button>
</div>
  `
})
export class LoginComponent implements OnInit {

  @Input() modallink: WildNavigationType;
  @Input() isPage: boolean;
  @Input() isBusiness: boolean;
  nav: WildNavigationType = WildNavigationType.UNKNOWN;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private navigation: NavigationService) { }


  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
    });
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.nav != null && params?.nav != undefined && params?.nav != WildNavigationType.UNKNOWN)
        this.nav = parseInt(params.nav.toString());
    })
  }

  switchToBusiness() {
    this.isBusiness = true;
    this.navigation.navigate(WildNavigationType.LOGIN, { "business": 1, "nav": WildNavigationType.ONBOARDINGV2 });
    setTimeout(() => {
      window.location.href = window.location.href;
    }, 100);
  }

  switchToUser() {
    this.isBusiness = false;
    this.navigation.navigate(WildNavigationType.LOGIN, {});
    setTimeout(() => {
      window.location.href = window.location.href;
    }, 100);
  }

  onSubmit(data: LoginFormData) {
    this.loading = true;
    this.authenticationService.loginWithEmailAndPassword(data.email, data.password)
      .then(async () => {
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Login", message, NotifyType.ERROR);
  }

  async googleLogin() {
    await this.authenticationService.loginWithGoogle()
      .then(async () => {
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  async facebookLogin() {
    await this.authenticationService.loginWithFacebook()
      .then(async () => {
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }
  async appleLogin() {
    await this.authenticationService.loginWithApple()
      .then(async () => {
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }



  async next() {
    await this.authenticationService.getProfileAndSetImageAndUsername();
    // if (!this.authenticationService.hasUsername()) {
    //   var settings: ModalModel = {
    //     disableBodyScroll: true,
    //     design: ModalDesign.COMPONENT,
    //     component: UsernameComponent,
    //     componentInputs: [{
    //       inputName: "modallink",
    //       value: WildNavigationType.USERDASHBOARD
    //     }],
    //     componentOutputs: [],
    //     close: true,
    //     large: false
    //   }
    //   this.modalService.show(settings);
    // } else {
    if (this.isPage) {
      if (this.nav != WildNavigationType.UNKNOWN) {
        this.navigation.navigateWithReturnUrlCheck(this.nav);
      } else {
        this.navigation.navigateWithReturnUrlCheck(WildNavigationType.WHERETO);
      }
    } else {
      this.modalService.hide();
      this.notifyService.notify("Logged in", "You are now logged in to Wild", NotifyType.SUCCESS);
      if (this.modallink) {
        this.navigation.navigate(this.modallink);
      }
    }
    // }
  }

  goToRegister() {
    if (this.isPage) {
      this.navigation.navigateAndRetainUrlParams(WildNavigationType.REGISTER);
    } else {
      var settings: ModalModel = {
        disableBodyScroll: true,
        design: ModalDesign.COMPONENT,
        component: RegisterComponent,
        componentInputs: [{
          inputName: "modallink",
          value: this.modallink
        }],
        componentOutputs: [],
        close: true,
        large: false,
        sideimage: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FLogin%20Page%20for%20Modal.png?alt=media&token=0d0e7e99-20f0-4a8d-adbd-425905328c4d"
      }
      this.modalService.show(settings);
    }
  }

  goToForgotten() {
    if (this.isPage) {
      this.navigation.navigateAndRetainUrlParams(WildNavigationType.FORGOTTONPASSWORD);
    } else {
      var settings: ModalModel = {
        disableBodyScroll: true,
        design: ModalDesign.COMPONENT,
        component: ForgottenComponent,
        componentInputs: [{
          inputName: "modallink",
          value: this.modallink
        }],
        componentOutputs: [],
        close: true,
        large: false,
        sideimage: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FLogin%20Page%20for%20Modal.png?alt=media&token=0d0e7e99-20f0-4a8d-adbd-425905328c4d"
      }
      this.modalService.show(settings);
    }
  }

  resetForm() {
    this.loading = false;
  }

}
