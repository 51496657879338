export enum TideType {
    UNKNOWN,
    LOW,
    HIGH,
    //  CD	Chart Datum (Recommended)	Reference level for depths in navigational charts and heights in tide tables. The actual level depends on local customs. For historical reasons, this is not the default. However, we recommend most users use this by sending the &datum=CD option.
    CD,
    // LAT	Lowest Astronomical Tide	The lowest predicted astronomical tide.
    LAT,
    // MLLWS	Mean Lower Low Water Spring	The average of the lowest tide during spring tide range.
    MLLWS,
    // MLWS	Mean Low Water Spring	The average of the successive pair of lowest tides during spring tide range.
    MLWS,
    // MHLWS	Mean Higher Low Water Spring	The average of the higher of the successive pair of lowest tides during spring tide range.
    MHLWS,
    // MLLW	Mean Lower Low Water	The average of the lowest of the successive pair of low tides.
    MLLW,
    // MLW	Mean Low Water	The average of all low waters
    MLW,
    // MHLW	Mean Higher Low Water	The average of the highest of the successive pair of low tides.
    MHLW,
    // MLLWN	Mean Lower Low Water Neaps	The average of the lowest tide during neap tide range.
    MLLWN,
    // MLWN	Mean Low Water Neaps	The average of the successive pair of lowest tides during neap tide range.
    MLWN,
    // MHLWN	Mean Higher Low Water Neaps	The average of the higher of the successive pair of lowest tides during neap tide range.
    MHLWN,
    // MTL	Mean Tide Level	The average of all high and low tides.
    MTL,
    // MSL	Mean Sea Level
    MSL,
    // (Default)	The average sea level.
    // MLHWN	Mean Lower High Water Neaps	The average of the lower of the successive pair of highest tides during neap tide range.
    MLHWN,
    // MHWN	Mean High Water Neaps	The average of the successive pair of highest tides during neap tide range.
    MHWN,
    // MHHWN	Mean Higher High Water Neaps	The average of the highest tide during neap tide range.
    MHHWN,
    // MLHW	Mean Lower High Water	The average of the lowest of the successive pair of high tides.
    MLHW,
    // MHW	Mean High Water	The average of all high waters
    MHW,
    // MHHW	Mean Higher High Water	The average of the highest of the successive pair of high tides.
    MHHW,
    // MLHWS	Mean Lower High Water Spring	The average of the lower of the successive pair of highest tides during spring tide range.
    MLHWS,
    // MHWS	Mean High Water Spring	The average of the successive pair of highest tides during spring tide range.
    MHWS,
    // MHHWS	Mean Higher High Water Spring	The average of the highest tide during spring tide range.
    MHHWS,
    // HAT	Highest Astronomical Tide	The highest predicted astronomical tide.
    HAT
}