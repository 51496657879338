import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { FitbitIntegrationPageComponent } from './pages/fitbitintegration.component';
import { GarminIntegrationPageComponent } from './pages/garminintegration.component';
import { SuuntoIntegrationPageComponent } from './pages/suuntointegration.component';
import { UserIntegrationsWrapperPageComponent } from './pages/wearablereturnpage.component';
import { UserIntegrationsRoutingModule } from './userintegrations-routing.module';

@NgModule({
    declarations: [
        UserIntegrationsWrapperPageComponent,
        GarminIntegrationPageComponent,
        SuuntoIntegrationPageComponent,
        FitbitIntegrationPageComponent
    ],
    imports: [
        SharedModule,
        UserIntegrationsRoutingModule
    ]
})
export class UserIntegrationsModule { } 
