import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BusinessPublicModel } from '../../../../data/models/business_public_model';


@Component({
    selector: 'app-businesstags',
    template: `
 <div class="py-3">
    <span  *ngIf="business.swimming" class="wild-badge-primary">
        Swimming
    </span>
    <span  *ngIf="business.waterskiing" class="wild-badge-primary">
        Waterski
    </span>
    <span  *ngIf="business.wakeboarding" class="wild-badge-primary">
        Wakeboard
    </span>
    <span  *ngIf="business.kneeboarding" class="wild-badge-primary">
        Kneeboarding
    </span>
    <span  *ngIf="business.surfing" class="wild-badge-primary">
        Surfing
    </span>
    <span  *ngIf="business.bodyboarding" class="wild-badge-primary">
        Body Boarding
    </span>
    <span  *ngIf="business.bodysurfing" class="wild-badge-primary">
        Body Surfing
    </span>
    <span  *ngIf="business.canoeing" class="wild-badge-primary">
        Canoeing
    </span>
    <span  *ngIf="business.kayaking" class="wild-badge-primary">
        Kayaking
    </span>
    <span  *ngIf="business.windsurfing" class="wild-badge-primary">
        Windsurfing
    </span>
    <span  *ngIf="business.kiteboarding" class="wild-badge-primary">
        Kiteboarding
    </span>
    <span  *ngIf="business.sailing" class="wild-badge-primary">
        Sailing
    </span>
    <span  *ngIf="business.skimboarding" class="wild-badge-primary">
        Skim Boarding
    </span>
    <span  *ngIf="business.scubadiving" class="wild-badge-primary">
        Scuba Diving
    </span>
    <span  *ngIf="business.snorkeling" class="wild-badge-primary">
        Snorkeling
    </span>
    <span  *ngIf="business.whitewaterrafting" class="wild-badge-primary">
        Rafting
    </span>
    <span  *ngIf="business.freediving" class="wild-badge-primary">
        Free Diving
    </span>
    <span  *ngIf="business.canyoning" class="wild-badge-primary">
        Canyoning
    </span>
    <span  *ngIf="business.hydrofoil" class="wild-badge-primary">
        Hydrofoil
    </span>
    <span  *ngIf="business.yachting" class="wild-badge-primary">
        Yachting
    </span>
    <span  *ngIf="business.flyboarding" class="wild-badge-primary">
        Flyboarding
    </span>
    <span  *ngIf="business.rowing" class="wild-badge-primary">
        Rowing
    </span>
    <span  *ngIf="business.icediving" class="wild-badge-primary">
        Ice Diving
    </span>
    <span  *ngIf="business.triathlon" class="wild-badge-primary">
        Triathlon
    </span>
    <span  *ngIf="business.swimrun" class="wild-badge-primary">
        Swim Run
    </span>
    <span  *ngIf="business.walking" class="wild-badge-primary">
        Walking
    </span>
    <span  *ngIf="business.iceswimming" class="wild-badge-primary">
        Ice Swimming
    </span>
    <span  *ngIf="business.coasteering" class="wild-badge-primary">
       Coasteering
    </span>
    <span  *ngIf="business.lakes" class="wild-badge-primary">
       Lakes
    </span>
    <span  *ngIf="business.rivers" class="wild-badge-primary">
       Rivers
    </span>
    <span  *ngIf="business.coastal" class="wild-badge-primary">
       Coastal
    </span>
    <span  *ngIf="business.lido" class="wild-badge-primary">
       Lido
    </span>
    <span  *ngIf="business.pool" class="wild-badge-primary">
       Pool
    </span>
    <span  *ngIf="business.onetwoonecoaching" class="wild-badge-primary">
     121 Coaching
    </span>
    <span  *ngIf="business.groupcoaching" class="wild-badge-primary">
        Group Coaching
    </span>
    <span  *ngIf="business.pooltoopenwater" class="wild-badge-primary">
        Pool to Open Water
    </span>
    <span  *ngIf="business.beginner" class="wild-badge-primary">
        Beginner
    </span>
    <span  *ngIf="business.adults" class="wild-badge-primary">
        Adults
    </span>
    <span  *ngIf="business.children" class="wild-badge-primary">
        Children
    </span>
  </div>
  `,
})
export class BusinessTagsComponent {
    @Input() business: BusinessPublicModel;

}
