import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticatedGuard implements CanActivate {
  constructor(
    private auth: AuthenticationService,
    private navigation: NavigationService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    var loggedIn = this.auth.isLoggedIn();
    if (!loggedIn) {
      var url = this.navigation.getResolvedUrl(route);
      this.auth.logout();
      this.navigation.navigateWithReturnUrl(WildNavigationType.LOGIN, url);
      return false;
    } else {
      return true;
    }
  }

}
