import { Component, Input, OnInit } from '@angular/core';
import { AvailableCouponsCouponDTO } from '../../../../data/dtos/services/AvailableCouponsCouponDTO';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { ModalService } from 'src/shared/services/modal/modal_service';

@Component({
  template: ` 
    <app-modaltopper [header]="coupon.name"></app-modaltopper>
    <div class="w-full pb-4">
    <h2 class="wild-header-small mt-3">Description</h2>
    <p class="mt-1 text-sm text-gray-500">{{coupon.description}}</p>
    <h2 class="wild-header-small mt-3">Price</h2>
    <p class="mt-1 text-sm text-gray-500 ">{{coupon.formattedPrice}}</p>
    <h2 class="wild-header-small mt-3">How long you have to use it</h2>
    <p class="mt-1 text-sm text-gray-500 ">{{coupon.durationDescription}}</p>
    <div class="w-full flex justify-center">
          <button (click)="hide()" class="wild-btn-light mx-auto mt-5">Close</button>
    </div>
  `
})
export class CouponReadMoreComponent implements OnInit {

  constructor(
    private maps: MapsService,
    private modalService: ModalService) {
  }
  ngOnInit(): void {

  }

  @Input() coupon: AvailableCouponsCouponDTO;

  hide() {
    this.modalService.hide();
  }

}
