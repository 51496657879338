<ng-container *ngIf="order$ | async as order; else loadingOrError">
    <div *ngIf="!order; else loaded">
        <app-empty icon="shopping-bag" header="Order not found" description=""></app-empty>
    </div>
    <ng-template #loaded>
        <div class="divide-y divide-gray-200">
            <app-panelheader [header]="order?.contact?.personal?.firstName + ' ' + order?.contact?.personal?.surname"
                [subheading]="'#' + order.reference">
            </app-panelheader>
            <div class="h-full flex flex-col bg-white overflow-y-scroll">
                <div class="mt-5 border-b border-gray-200">
                    <div class="px-6">
                        <nav class="-mb-px flex space-x-6">
                            <span (click)="page = OrderPages.SUMMARY"
                                [ngClass]="{'border-blue-500 text-blue-600': page == OrderPages.SUMMARY, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != OrderPages.SUMMARY}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Summary</span>
                            <span (click)="page = OrderPages.NOTES"
                                [ngClass]="{'border-blue-500 text-blue-600': page == OrderPages.NOTES, 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': page != OrderPages.NOTES}"
                                class="cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Notes
                                ({{order?.noteCount ?? 0}})</span>
                        </nav>
                    </div>
                </div>
                <div *ngIf="page == OrderPages.NOTES">
                    <app-notes (count)="noteCountUpdated($event,order)" [sourceDocumentId]="order.id"
                        [sourceDocumentType]="SourceDocumentType.ORDER"></app-notes>
                </div>
                <div class="p-6" *ngIf="page == OrderPages.SUMMARY">
                    <p class="text-sm font-medium text-gray-900 truncate">Order Date</p>
                    <p class="text-sm text-gray-500 truncate my-1">{{order?.transaction_dateIsoDate| date : "MMM d,
                        y,
                        h:mm
                        a"}}
                    </p>
                    <p class="text-sm font-medium text-gray-900 truncate">Grand Total</p>
                    <p class="text-sm text-gray-500 truncate my-1">{{order.grandtotal | price:order.currency}}</p>
                    <p class="text-sm font-medium text-gray-900 truncate">Sub-Total</p>
                    <p class="text-sm text-gray-500 truncate my-1">{{order.total | price:order.currency}}</p>
                    <p class="text-sm font-medium text-gray-900 truncate">Transaction Fee</p>
                    <p class="text-sm text-gray-500 truncate mt-1 leading-tight">{{order.transactionFee |
                        price:order.currency}}
                    <p (click)="feebreakdown = !feebreakdown"
                        class="text-xs text-gray-400 hover:underline cursor-pointer">
                        <span *ngIf="feebreakdown">hide breakdown</span>
                        <span *ngIf="!feebreakdown">show breakdown</span>
                    </p>
                    <div *ngIf="feebreakdown">
                        <p class="text-xs text-gray-400 truncate my-1">platform fee: {{order.platformFee}}</p>
                        <p class="text-xs text-gray-400 truncate my-1">stripe card fee: {{order.stripeCardFee}}</p>
                        <p class="text-xs text-gray-400 truncate my-1">stripe payout fee: {{order.stripePayoutFee}}</p>
                        <p class="text-xs text-gray-400 truncate my-1">stripe cross border fee: {{order.crossBorderFee}}
                        </p>
                        <p class="text-xs text-gray-400 truncate my-1">NOTE: fee adjustments due to refunds will only be
                            reflected on Stripe express</p>
                    </div>
                    <p class="text-sm font-medium text-gray-900 truncate mt-2">Status</p>
                    <p class="text-sm text-gray-500 truncate my-1">
                        <span *ngIf="order.status == OrderStatus.PAID"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Paid
                        </span>
                        <span *ngIf="order.status == OrderStatus.FULLYREFUNDED"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Refunded
                        </span>
                        <span *ngIf="order.status == OrderStatus.PARTIALREFUND"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            Partial Refund
                        </span>
                    </p>
                    <p class="text-sm font-medium text-gray-900 truncate">Contact</p>
                    <p (click)="openContact(order.contact)"
                        class="text-sm text-gray-500 truncate my-1 cursor-pointer hover:underline">
                        {{order.contact?.personal?.firstName + ' ' +
                        order.contact?.personal?.surname}}<fa-icon class="ml-2" icon="external-link-alt"></fa-icon>
                    </p>

                    <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        <li *ngFor="let orderLine of order.orderlines">
                            <div class="relative group flex items-center my-4">
                                <span class="-m-1 flex-1 block p-1">
                                    <div class="absolute inset-0" aria-hidden="true"></div>
                                    <div class="flex-1 flex items-center min-w-0 relative">
                                        <span class="flex-shrink-0 inline-block relative">
                                            <img class="h-24 w-24 rounded-lg"
                                                [src]="orderLine?.image != null ? imagesUrl + orderLine?.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                                                alt="">
                                            <!-- Online: "bg-green-400", Offline: "bg-gray-300" -->
                                            <span *ngIf="orderLine.status == OrderLineStatus.PAID"
                                                class="bg-green-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                                aria-hidden="true"></span>
                                            <span *ngIf="orderLine.status == OrderLineStatus.REFUNDED"
                                                class="bg-red-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                                aria-hidden="true"></span>
                                            <span *ngIf="orderLine.status == OrderLineStatus.PARTIALREFUND"
                                                class="bg-yellow-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                                aria-hidden="true"></span>
                                        </span>
                                        <div class="ml-4 flex justify-between items-center w-full">
                                            <div>
                                                <p class="text-sm font-medium text-gray-900 truncate">
                                                    {{orderLine?.name}}
                                                </p>
                                                <div class="text-xs font-light text-gray-900 truncate mb-1"
                                                    *ngIf="orderLine.activityId">
                                                    <p>{{orderLine.readableLocalStartDateOnly}}
                                                        {{orderLine.readableLocalTimeRange}}</p>
                                                </div>
                                                <div *ngIf="orderLine.linkedAccount"
                                                    class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 my-1">
                                                    Booking for {{orderLine.linkedAccount?.firstName}}
                                                    {{orderLine.linkedAccount?.surname}}
                                                </div>
                                                <div>
                                                    <div *ngIf="orderLine.criteriaMetForMembershipDiscount" 
                                                    class="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800 mt-2">
                                                    Membership Discount Applied
                                                  </div>
                                                </div>  
                                                <p class="text-sm text-gray-500 truncate">
                                                    Grand Total - <span
                                                        [ngClass]="{'line-through': orderLine.status != OrderLineStatus.PAID}">{{orderLine.formattedGrandTotal}}</span>
                                                    -
                                                    <span *ngIf="orderLine.status == OrderLineStatus.PAID"
                                                        class="text-green-400">paid</span>
                                                    <span *ngIf="orderLine.status == OrderLineStatus.REFUNDED"
                                                        class="text-red-400">refunded</span>
                                                    <span *ngIf="orderLine.status == OrderLineStatus.PARTIALREFUND"
                                                        class="text-yellow-400">partial refund</span>
                                                </p>
                                                <p class="text-sm text-gray-500 truncate">
                                                    <span>Sub-Total - {{orderLine.formattedTotal}}</span>
                                                </p>
                                                <p *ngIf="!orderLine.formattedDiscount.includes('0.00')"
                                                    class="text-sm text-gray-500 truncate">
                                                    <span>Discount - {{orderLine.formattedDiscount}}</span>
                                                </p>
                                                <p *ngIf="!orderLine.formattedTotalRefunded.includes('0.00')"
                                                    class="text-sm text-gray-500 truncate">
                                                    <span>Refunded - {{orderLine.formattedTotalRefunded}}</span>
                                                </p>
                                                <p *ngIf="orderLine.activityId && orderLine.activityStatus == ActivityStatus.LIVE"
                                                    class="hover:underline text-sm text-gray-500 py-1 cursor-pointer"
                                                    (click)="openActivity(orderLine.activityId)">
                                                    open activity <fa-icon class="ml-2"
                                                        icon="external-link-alt"></fa-icon>
                                                </p>
                                                <div class="mb-2 mt-1" *ngFor="let coupon of orderLine.coupons">
                                                    <p
                                                        class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                                                        {{coupon?.name}} (-{{coupon?.formattedDiscountPrice}})
                                                    </p>
                                                </div>
                                                <div class="text-xs text-gray-500 truncate"
                                                    *ngFor="let refund of orderLine.refunds">
                                                    {{refund.date | date:'MMM d, y'}} - {{refund.total |
                                                    price:order.currency}}
                                                    <span *ngIf="refund.type == RefundType.BYUSER"> refunded by
                                                        user</span>
                                                    <span *ngIf="refund.type == RefundType.BYBUSINESS"> refunded
                                                        by
                                                        business to user</span>
                                                    <span *ngIf="refund.type == RefundType.BYWILD"> refunded by
                                                        Wild</span>
                                                    <span *ngIf="refund.type == RefundType.BUSINESSTRANSACTIONFEE">
                                                        fee
                                                        refunded to you</span>
                                                    <span
                                                        *ngIf="refund.type == RefundType.NOREFUNDREQUIREDJUSTCANCELLED">
                                                        no refund required</span>
                                                    <span
                                                        *ngIf="refund.type == RefundType.BUSINESSTRANSACTIONFEETOCOMPENSATEFORUSERFEES">
                                                        user booking fee deducted from your fees
                                                    </span>
                                                    <span *ngIf="refund.type == RefundType.USERTRANSACTIONFEE">
                                                        booking fee
                                                        refunded to user</span>
                                                </div>
                                            </div>
                                            <div *ngIf="orderLine.status == OrderLineStatus.PAID">
                                                <span (click)="cancel(order,orderLine)"
                                                    class="text-xs cursor-pointer text-red-500 hover:text-red-600 hover:underline">cancel
                                                    & refund</span>
                                            </div>
                                        </div>

                                    </div>
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <div class="p-6">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</ng-template>