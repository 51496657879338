import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BusinessContactSignedWaiversDTO } from '../../../../../../../data/dtos/waiver/BusinessContactSignedWaiversDTO';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { ContactsService } from '../../services/contacts_service/contacts_service';
@Component({
  template: `
  <app-modaltopper header="Send or Open Waiver" parapraph="Contact can sign using your device or theirs"></app-modaltopper>
  <div class="my-6" *ngIf="loading">
    <app-loader [disablescreenheight]="true"></app-loader>
  </div>
  <div *ngIf="viewqrcode" class="my-6 flex flex-col items-center justify-center">
    <qrcode [qrdata]="link" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    <div class="w-full py-3 text-sm font-semibold text-center">Scan 📱☝️</div>
    <button (click)="hideQRCode()" class="text-sm text-blue-500 text-center hover:underline mt-3">back</button>
  </div>
  <div class="my-6 flex flex-col items-center justify-center" *ngIf="!loading && !viewqrcode">
    <button (click)="processsendWaiver()" class="wild-btn-primary mb-3 w-40">Send by Email</button>
    <button (click)="showQRCode()" class="wild-btn-primary mb-3 w-40">QR Code</button>
    <button (click)="copy()" class="wild-btn-primary mb-3 w-40">Copy Link</button>
    <a (click)="close()" [href]="link" target="_blank" class="wild-btn-primary w-40 mb-3">Open Link</a>
    <button (click)="close()" class="wild-btn-light mb-3 w-40">Close</button>
  </div>
  `
})
export class ResendWaiverComponent implements OnInit {

  @Input() waiver: BusinessContactSignedWaiversDTO;
  @Output() closed = new EventEmitter();

  constructor(
    private contactsService: ContactsService,
    private notifyService: NotifyService,
    private businessService: BusinessService,
    private modalService: ModalService) { }

  form!: UntypedFormGroup;
  @Input() id: string;
  loading: boolean;
  link: string;
  viewqrcode: boolean;

  ngOnInit(): void {
    this.link = `${environment.domain}/signwaiver/${this.businessService.businessId}/${this.id}`;
  }

  copy() {
    navigator.clipboard.writeText(this.link);
    this.notifyService.notify("COPIED", "Waiver Link Copied", NotifyType.SUCCESS);
    this.close()
  }

  showQRCode() {
    this.viewqrcode = true;
  }

  hideQRCode() {
    this.viewqrcode = false;
  }

  processsendWaiver() {
    if (this.loading == true)
      return;
    this.loading = true;
    this.contactsService.sendWaiver(this.id)
      .then(() => {
        var settings: ModalModel = {
          disableBodyScroll: true,
          design: ModalDesign.CENTRAL,
          type: ModalType.INFO,
          description: `A waiver has been sent for signing. It will appear against their record when signed.`,
          header: "Waiver Sent!",
          close: true,
          hidectatwo: true,
          ctaonetext: "Great"
        }
        this.modalService.show(settings);
      })
      .catch((error) => {
        this.notifyService.notify("Waiver Error", error.message, NotifyType.ERROR);
      });
  }

  close() {
    //this is used to reopen the contact warnings on water safety
    this.closed.emit();
    this.modalService.hide();
  }

}
