import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SourceDocumentType } from '../../../data/enums/source_document_type';
import { ImageModel } from '../../../data/models/images_model';
import { FirestoreQueryType } from '../../../data/enums/firestore_query_type';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    constructor(private firestore: FirestoreService) { }
    getImages(sourceType: SourceDocumentType, sourceId: string): Observable<ImageModel> {
        return this.firestore.getListByQuery("images", [
            {
                property: "sourceDocumentImageType",
                operator: "==",
                value: sourceType,
                type: FirestoreQueryType.WHERE
            },
            {
                property: "sourceDocumentId",
                operator: "==",
                value: sourceId,
                type: FirestoreQueryType.WHERE
            },
            {
                property: "creation_date",
                operator: "==",
                value: "desc",
                type: FirestoreQueryType.ORDERBY
            }
        ]);
    }

    waitAndReload(event, image) {
        //Retry attempts set on view
        var timeBetweenRetries = 2000;
        var attempts = 0;
        const originalSrc = event.target.src;
        image.fetching = true;
        var placeholder = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media"
        if (parseInt(event.target.getAttribute('data-retry'), 10) !== parseInt(event.target.getAttribute('data-max-retry'), 10)) {
            event.target.setAttribute('data-retry', parseInt(event.target.getAttribute('data-retry'), 10) + 1);
            event.target.src = placeholder;
            setTimeout(function () {
                event.target.src = originalSrc;
                attempts++;
            }, timeBetweenRetries);
        } else {
            image.fetching = false;
            image.failedtoload = true;
            event.target.src = placeholder;
        }
    }

    imageLoaded(event, image) {
        if (event.target.src.includes(image.image))
            image.fetching = false;
    }

}
