import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BusinessUserStatus } from '../../../../../../data/enums/business_user_status';
import { BusinessUserModel } from '../../../../../../data/models/business_user_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { TeamService } from '../../../services/team_service/team_service';
import { AddteammemberformComponent } from './components/addteammemberform/addteammemberform.component';
import { EditteammemberformComponent } from './components/editteammemberform/editteammemberform.component';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html'
})
export class TeamComponent implements OnInit {

  constructor(
    private notifyService: NotifyService,
    private modalService: ModalService, private teamService: TeamService, private navigation: NavigationService) { }

  public error: ErrorModel = null;
  imagesUrl = environment.imagesUrl;
  users$ = this.teamService.getTeam().pipe(
    take(1),
    catchError(err => {
      this.error = err;
      return throwError(err);
    })
  );

  ngOnInit(): void {

  }

  isEditable(user: BusinessUserModel) {
    return user.status == BusinessUserStatus.ACTIVE && user.roles.some(r => r == 'owner') == false;
  }

  isInvited(user: BusinessUserModel) {
    return user.status == BusinessUserStatus.INVITED;
  }


  new() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: AddteammemberformComponent
    }
    this.modalService.show(settings);
  }

  getName(user: BusinessUserModel): string {
    if (user.firstName != undefined && user.firstName != "")
      return user.firstName + " " + user.surname;
    return "no name yet";
  }

  edit(user: BusinessUserModel) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: EditteammemberformComponent,
      componentInputs: [
        {
          inputName: "user",
          value: user
        }
      ]
    }
    this.modalService.show(settings);
  }

  resendModal(user: BusinessUserModel) {
    this.teamService.resendModal(user);
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  editDetails(user: BusinessUserModel) {
    this.navigation.navigate(WildNavigationType.BUSINESSUPDATEMYDETAILS, {}, user.id)
  }

  editWorkingHours(user: BusinessUserModel) {
    this.navigation.navigate(WildNavigationType.BUSINESSCALENDAR, {}, user.id)
  }

  retry() {
    this.navigation.refreshPage(WildNavigationType.TEAM);
  }

  getStatusString(status: BusinessUserStatus) {
    return this.teamService.getStatusString(status);
  }

  getStatusClasses(status: BusinessUserStatus) {
    return this.teamService.getStatusClasses(status);
  }

}
