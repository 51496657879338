import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  selector: 'app-useractivitydetails',
  templateUrl: './useractivitydetails.component.html'
})
export class UserActivityDetailsComponent implements OnInit {

  activityId: string;
  userId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((params: Params) => {
      this.activityId = params.activityId;
      this.userId = params.userId;
    });
  }

  seeAllActivities() {
    this.navigation.navigate(WildNavigationType.USERACTIVITIES)
  }

}
