import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UserModel } from '../../../../../../data/models/user_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UserService } from '../../../services/user_service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(
    private notifyService: NotifyService,
    private userService: UserService,
    private fb: UntypedFormBuilder) { }

  form!: UntypedFormGroup;
  loading: boolean;
  public error: ErrorModel = null;
  user$: Observable<UserModel>;

  ngOnInit(): void {
    this.form = this.fb.group({
      wild_general: new UntypedFormControl(false, []),
      swimspots_newalerts_push: new UntypedFormControl(false, []),
      swimspots_newalerts_email: new UntypedFormControl(false, []),
      swimspots_newposts_push: new UntypedFormControl(false, []),
      swimspots_newposts_email: new UntypedFormControl(false, []),
      swimspots_newreviews_push: new UntypedFormControl(false, []),
      swimspots_newreviews_email: new UntypedFormControl(false, []),
    });
    this.load();
  }

  load() {
    this.user$ = null
    this.user$ = this.userService.getUser().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  onSubmit(data: any) {
    this.loading = true;
    this.userService.updateCommsPreferences(data)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Notfications Preferences", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Preferences Updated", "Comms preferences updated", NotifyType.SUCCESS);
    this.load();
  }

  resetForm() {
    this.loading = false;
  }
}
