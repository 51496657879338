<span class="sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6"></span>
<div *ngIf="!hideHeaders" class="flex justify-between mb-1">
    <label [for]="name" class="block wild-label">
        {{description}}<span *ngIf="required">*</span>
    </label>
</div>
<div *ngIf="!dobmode" class="mt-1">
    <ngx-datepicker [(ngModel)]="value" (ngModelChange)="onChange($event)" [options]="options"></ngx-datepicker>
</div>
<form>
    <input readonly class="flex-1  block w-full min-w-0  rounded-md sm:text-sm border-gray-300 cursor-pointer"
        *ngIf="dobmode" #wilddatepicker type="text" placeholder="Select date" />
</form>