import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const bookingRecurring: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcustomerbooking%2Frecurringbooking.png?alt=media&token=243c4930-8f96-4794-957f-9342a428596c",
  title: "Group Bookings",
  subtitle: "Ad-Hoc or Recurring Events",
  data: [
    {
      icon: "infinity", title: "Recurring Events.", subtitle: "Daily, Weekly, Whenever."
    },
    {
      icon: "users", title: "Friends & Family.", subtitle: "Group orders."
    },
    {
      icon: "first-aid", title: "Safety Profiles.", subtitle: "For each booker."
    },
    {
      icon: "mobile", title: "App Ready.", subtitle: "For fast checkout."
    },
  ]
}