import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from 'src/shared/components/notfound/notfound.component';
import { CustomTokenResolver } from 'src/shared/resolvers/customtoken.resolver';
import { UserResolver } from 'src/shared/resolvers/user.resolver';
import { CustomTokenAuthAndRedirectComponent } from './pages/customtokenauth';
import { HomeComponent } from './pages/home/home.component';
import { LogoutComponent } from './pages/logout.component';

const routes: Routes = [
  {
    path: "",
    resolve: {
      user: UserResolver
    },
    component: HomeComponent,
  },
  {
    path: "customtokenauth",
    resolve: {
      user: CustomTokenResolver
    },
    component: CustomTokenAuthAndRedirectComponent,
  },
  { path: '404', component: NotfoundComponent },
  { path: 'logout', component: LogoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class WildRoutingModule { }
