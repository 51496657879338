import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { CTAFUNCTION, FeatureCTAsData } from "../../FeaturesData";

export const businessCTAs:FeatureCTAsData ={
    primary:{
      text:"Start For FREE",
      function:CTAFUNCTION.BUSINESSONBOARDING
    },
    secondary:{
      text:"Book Demo",
      page:WildNavigationType.DEMO
    },
    tertiary:{
      text:"Login",
      function:CTAFUNCTION.BUSINESSLOGIN
    }
  }