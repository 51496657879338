export enum BusinessTODO {
    UNKNOWN,
    UNVERIFIED,
    ADDSERVICE,
    SETTIMEZONE,
    SETCOUNTRY,
    SETPRICE,
    UPDATEPAYMENTDETAILS,
    SETCURRENCY,
    BIO,
    LOCATION,
    VERIFIEDBUSINESSBUTNEEDSTOPROVEIDENTITY,
    NEEDTOADDANAME,
    NEEDTOADDCALENDARBLOCKS,
    GOLIVE,
    BOOKINGURL,
    LINKTOSPOT,
    USERPROFILEPHOTO,
    WELCOMEEMAIL,
    BUSINESSIMAGE
}