import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { SeoService } from '../../../shared/services/seo/seo.service';
import { Observable, throwError } from 'rxjs';
import { userSearchService } from 'src/wild/services/search/search_service';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../data/models/error_model';
import { SearchResultsHome } from '../../../data/models/SearchResultsHome';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

interface SearchHelper {
  name: string,
  searchproperty: string,
  image: string
}


@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  form: UntypedFormGroup;
  socialUrl = "https://wildopenwater.com?1=1"
  helpers: SearchHelper[] = [{
    name: "Lakes",
    searchproperty: "lakes",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FLake.png?alt=media&token=1bd329f8-e63a-48b5-9c5e-7db7729708ea'
  },
  {
    name: "Beaches",
    searchproperty: "coastal",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FBeach.png?alt=media&token=7e0b1e0a-cea7-4af2-aa23-89b36bb4b56a'
  }, {
    name: "Rivers",
    searchproperty: "rivers",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FRivers.png?alt=media&token=1f1d00f8-eadb-4687-9d9b-9d5852b55add'
  },
  {
    name: "Dog Friendly",
    searchproperty: "dogs",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FDogs.png?alt=media&token=7ae200d4-ded7-4bb7-a78e-5eb8b25cdee9'
  },
  {
    name: "Long Swim",
    searchproperty: "longswim",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FLong%20Swim.png?alt=media&token=088036bf-6f61-4d3e-8c9b-5673838172aa'
  },
  {
    name: "Mountain Views",
    searchproperty: "mountainviews",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FMountains.png?alt=media&token=5fddfa7a-61db-4f93-ba8e-a6d868c5e866'
  },
  {
    name: "Great Sunsets",
    searchproperty: "sunset",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FSunsets.png?alt=media&token=1c115805-67af-4d7c-b734-64b882112b76'
  },
  {
    name: "SUP",
    searchproperty: "supping",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FSUP.png?alt=media&token=847936f4-0f92-4328-98dc-6a22802cf7e7'
  },
  {
    name: "Picnics",
    searchproperty: "picnic",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FPICNIC.png?alt=media&token=4b58bf73-339a-44e3-b1f9-0ff86be85d48'
  },
  {
    name: "Waterfalls",
    searchproperty: "waterfalls",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FWaterfalls.png?alt=media&token=5950a1b5-d6c3-4f42-846b-cf54a5f666fe'
  },
  {
    name: "Pubs",
    searchproperty: "pubs",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FPubs.png?alt=media&token=e87efae0-59ef-41c7-b938-b93391089817'
  },
  {
    name: "Bridges",
    searchproperty: "bridges",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FBridges.png?alt=media&token=887c86ff-9cd1-4ef0-94e4-1fe433da0997'
  },
  {
    name: "Cliffs",
    searchproperty: "cliffs",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FCliffs.png?alt=media&token=c31d30dc-7671-4945-8942-0be47317c575'
  },
  {
    name: "Quiet",
    searchproperty: "quietlocation",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FQuiet.png?alt=media&token=6221c95c-19ae-4528-b2d6-8f2771b0b2c0'
  },
  {
    name: "Skinny dip",
    searchproperty: "skinnydip",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FSkinny%20Dip.png?alt=media&token=e50d2a02-308f-47a7-843c-df8f9eaf7d84'
  },
  {
    name: "Surf",
    searchproperty: "surfing",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FSurf.png?alt=media&token=f4ad5c04-e002-4c8d-a6ff-3dfc8507cb0a'
  },
  {
    name: "Swing",
    searchproperty: "swing",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FSwings.png?alt=media&token=0a7b1f61-d839-4756-8047-f9a4fe559aba'
  },
  {
    name: "Kid Friendly",
    searchproperty: "suitableforkids",
    image: 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/InspirateButtons%2FKid%20Friendly.png?alt=media&token=aa8ffd35-afa0-427d-8cfc-a91c796b9675'
  }]
  socialTitle = "Wild Open Water";
  socialDescription = "Discover Blue Spaces and Join a community of people passionate about the joy of being in and around water";
  socialImage = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fwildlogo.png?alt=media&token=87b004dd-dd18-4815-a240-0f049dfc787b";
  constructor(
    private navigation: NavigationService,
    private auth: AuthenticationService,
    private storage: StorageService, private seo: SeoService, private router: Router, private fb: UntypedFormBuilder, private navigate: NavigationService, private searchService: userSearchService) { }
  ngOnInit(): void {
    this.seo.update(this.socialTitle, this.socialDescription, "swimming, open water, lake, river, beach", this.socialImage);
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    this.loadHome();
  }

  searchHome$: Observable<SearchResultsHome>;
  error: ErrorModel;

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  goToSearchPage() {
    this.navigate.navigate(WildNavigationType.SEARCH, {});
  }

  goToSearchPageForSpots() {
    var params = {};
    params["spots"] = 1;
    this.navigate.navigate(WildNavigationType.SEARCH, params);
  }

  goToSearchPageForCoastalSpots() {
    var params = {};
    params["coastal"] = 1;
    this.navigate.navigate(WildNavigationType.SEARCH, params);
  }

  goToSearchPageForLakeSpots() {
    var params = {};
    params["lakes"] = 1;
    this.navigate.navigate(WildNavigationType.SEARCH, params);
  }

  goToSearchPageForRiverSpots() {
    var params = {};
    params["rivers"] = 1;
    this.navigate.navigate(WildNavigationType.SEARCH, params);
  }

  goToSearchPageForBusinesses() {
    var params = {};
    params["business"] = 1;
    this.navigate.navigate(WildNavigationType.SEARCH, params);
  }

  goToAbout() {
    this.navigate.navigate(WildNavigationType.ABOUT, {});
  }

  register() {
    this.navigation.navigate(WildNavigationType.REGISTER);
  }


  goToSOS() {
    this.navigate.navigate(WildNavigationType.SOS, {});
  }

  becomeAnOrganiser() {
    this.navigate.navigate(WildNavigationType.BECOMEANORGANISER, {});
  }

  openHelper(helper: SearchHelper) {
    var params = {};
    params[helper.searchproperty] = 1;
    this.navigate.navigate(WildNavigationType.SEARCH, params);
  }

  loadHome() {
    this.searchHome$ = this.searchService.home().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


}
