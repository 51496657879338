import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: '[app-table-cell]',
  template: `<span class="" *ngIf="!cta" [ngClass]="colorClass == null ? '' : colorClass" >{{value}}</span>
             <a *ngIf="cta" (click)="click()" class=" wild-text-primary hover:font-bold cursor-pointer">{{value}}</a>`,
  styleUrls: ['./table.component.scss']
})
export class TableCellComponent implements OnInit {
  @Input() value: string;
  @Input() cta: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Input() colorClass: string = "";
  @HostBinding('class') class = 'px-6 py-4 whitespace-nowrap text-sm';
  // @Input() bold:boolean = false;
  //[ngClass]="{ 'font-medium text-gray-900':bold}" 

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit();
  }

}
