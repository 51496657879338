import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { take, catchError, timeout } from 'rxjs/operators';
import { BusinessWelcomeEmailDTO } from '../../../../../../data/dtos/business/BusinessWelcomeEmailDTO';
import { BusinessModel } from '../../../../../../data/models/business_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UtilService } from 'src/shared/services/util/util_service';
import { BusinessService } from '../../../services/business_service/business_service';
import { ref, Storage, uploadBytes } from '@angular/fire/storage';

@Component({
  template: `
<app-wrapper header="Welcome Email" paragraph="Add the content that you wish to be sent to any contacts that engage with your business for the first time">
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
          <app-form [form]="form" [fullwidthbutton]="false" ctaText="Save" disableDivide="true" (onSubmit)="onSubmit($event)"
                [loading]="loading">
                <app-inputgroup>
                    <app-textarea validation="Required field, Max of 1500 characters" [form]="form" type="text" name="welcomeemail" description="Email Content" [span]=6 [value]="business.welcomeemail"></app-textarea> 
                </app-inputgroup>
                <div class="text-right my-4" *ngIf="business.welcomeemailattachmentname">
                  {{business.welcomeemailattachmentname}} <span class="cursor-pointer hover:underline text-red-500" (click)="removeAttachmentEnable(business)">remove</span>
                </div>
                <div class="text-right my-4" *ngIf="fileForUpload">
                  {{fileForUpload.name}} {{fileForUpload.size}} <span class="cursor-pointer hover:underline text-red-500" (click)="removeAttachmentEnable()">remove</span>
                </div>
                <form class="my-4" *ngIf="!fileForUpload && !business.welcomeemailattachmentname">
                  <label class="block text-sm font-medium text-gray-700">
                      Add Email Attachment
                  </label>
                  <div
                      class="relative mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
                      <input [accept]="accept" multiple type="file" id="file" #userPhoto (change)="onFileSelected($event)"
                          name="image" autocomplete="off" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer" />
                      <div class="space-y-1 text-center">
                          <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                              aria-hidden="true">
                              <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <div class="flex text-sm text-gray-600">
                              <label for="file-upload"
                                  class="relative cursor-pointer bg-white rounded-md font-medium wild-text-primary hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                  <span *ngIf="limit == 1">Upload a file</span>
                                  <span *ngIf="limit > 1">Upload up to {{limit}} files</span>
                                  <input [accept]="accept" multiple id="file-upload" name="file-upload" type="file" #userPhoto
                                      (change)="onFileSelected($event)" class="sr-only">
                              </label>
                              <p class="pl-1">or drag and drop</p>
                          </div>
                          <p class="text-xs text-gray-500">
                              PDF up to 5MB
                          </p>
                      </div>
                  </div>
          </form>
        </app-form> 
        <div *ngIf="business.welcomeemail && !hideTestButton" class="my-3 flex items-end justify-end">
          <button class="wild-btn-light" (click)="test()">Send Test Email</button>
        </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>

  `
})
export class WelcomeEmailComponent implements OnInit {

  business$: Observable<BusinessModel>;
  error: ErrorModel;
  removeAttachment: boolean;
  hideTestButton: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private util: UtilService,
    private business: BusinessService,
    private storage: Storage,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  fileForUpload: any;

  removeAttachmentEnable(business: BusinessModel = null) {
    this.fileForUpload = null;
    this.removeAttachment = true;
    if (business != null)
      business.welcomeemailattachmentname = "";
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      welcomeemail: new UntypedFormControl('', [Validators.maxLength(1500)])
    });
    this.load();
  }

  onFileSelected(event) {
    if (event?.target?.files == null || event?.target?.files == undefined || event?.target?.files.length == 0) {
      return;
    }
    if (event?.target?.files.length > 1) {
      var message = "Please select only one file";
      this.notifyService.notify("Too many files", message, NotifyType.WARNING);
      return;
    }
    this.loadImagePreview(event.target.files[0]);
  }

  acceptArr: string[] = ['application/pdf'];
  maxfilesize: number = 5000000;

  loadImagePreview(file) {
    var size = file.size;
    var name = file.name;
    var type = file.type;
    if (!this.isSupportedFileType(type)) {
      this.notifyService.notify("Unsupported", "File type not supported", NotifyType.WARNING);
    } else if (size > this.maxfilesize) {
      this.notifyService.notify("Unsupported", "File too large", NotifyType.WARNING);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileForUpload = {
          base64: reader.result,
          src: URL.createObjectURL(file),
          name: name,
          type: type,
          size: this.util.formatBytes(size),
          file: file
        };
      };
    }
  };

  isSupportedFileType(type) {
    const acceptedImageTypes = this.acceptArr;
    return acceptedImageTypes.includes(type)
  }

  load() {
    this.business$ = null
    this.business$ = this.business.getBusiness().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  async onSubmit(data) {
    if (this.loading)
      return;
    this.loading = true;



    // var fileName = "";
    // var randomNumner = this.util.getRandomNumber()
    // fileName += "_" + randomNumner.toString();
    // var ext = this.fileForUpload.name.split('.').pop();
    // //It's VERY IMPORTANT TO INCLUDE AN EXTENSION HERE
    // //IF IMAGES DO NOT SHOW THEN IT"S BECASUE IT CANNOT GET THE EXTENSION
    // var locationToSaveFile = `welcomeemail/${fileName + "." + ext}`;
    // const fileRef = this.storage.app.name;// .(locationToSaveFile);
    // const task = this.storage.app. .upload(locationToSaveFile, this.fileForUpload.file);
    // await task.snapshotChanges().pipe(
    //   timeout(2000)
    // ).toPromise()
    //   .catch((error: any) => {
    //     this.handlerError(error);
    //   });
    // var downloadURL = await fileRef.getDownloadURL().toPromise()
    //   .catch((error: any) => {
    //     this.handlerError(error);
    //   });
    // var dto: BusinessWelcomeEmailDTO = {
    //   body: data.welcomeemail,
    //   file: locationToSaveFile,
    //   url: downloadURL,
    //   mimeType: this.fileForUpload.type,
    //   name: this.fileForUpload.name,
    //   removeAttachment: false
    // }

    if (this.fileForUpload) {
          const file = this.fileForUpload;
          var fileName = "";
          var randomNumner = this.util.getRandomNumber()
          fileName += "_" + randomNumner.toString();
          var ext = file.name.split('.').pop();
          //It's VERY IMPORTANT TO INCLUDE AN EXTENSION HERE
          var locationToSaveFile = `welcomeemail/${fileName + "." + ext}`;
          const fileRef = ref(this.storage, locationToSaveFile)
          const task = await uploadBytes(fileRef, file.file);
          var downloadURL = task.ref.fullPath;
      var dto: BusinessWelcomeEmailDTO = {
        body: data.welcomeemail,
        file: locationToSaveFile,
        url: downloadURL,
        mimeType: this.fileForUpload.type,
        name: this.fileForUpload.name,
        removeAttachment: false
      }
    } else {
      var dto: BusinessWelcomeEmailDTO = {
        body: data.welcomeemail,
        removeAttachment: this.removeAttachment
      }
    }

    this.business.welcomeEmailSettings(dto)
      .then(async () => {
        this.notifyService.notify("Email Updated", "Email settings updated", NotifyType.SUCCESS);

      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }
  async test() {
    this.hideTestButton = true;
    var settings: ModalModel = {
      header: "Sending",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    this.business.welcomeEmailTest()
      .then(async () => {
        this.notifyService.notify("TEST Email Sent to You", "You can send another test in 10 seconds", NotifyType.SUCCESS);

      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modalService.hide();
        setTimeout(() => {
          this.hideTestButton = false;
        }, 10000);
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Details", message, NotifyType.ERROR);
  }


  resetForm() {
    this.loading = false;
  }

}
