import { ElementRef, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {

  scrollableContainer:ElementRef<HTMLElement> = null;
  collapsed:boolean = false;
  active:boolean;
  constructor(

  ) { }

  get isCollapsed():boolean{
    return this.collapsed;
  }

  setScrollableContainer(element:ElementRef<HTMLElement>){
    this.scrollableContainer = element;
  }

  scrollTop(){
    // if(this.scrollableContainer != null)
      this.scrollableContainer.nativeElement.scrollTo(0,0);
  }

}
