<div class="pt-16 pb-12 flex flex-col">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 flex justify-center">
            <a href="/" class="inline-flex">
                <img class="h-12 w-auto"
                    src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/icons%2Fw-icon.png?alt=media&token=5eae8376-7952-4891-b550-11117b277543"
                    alt="">
            </a>
        </div>
        <div class="py-16">
            <div class="text-center">
                <p class="text-sm font-semibold wild-text-primary uppercase tracking-wide">{{title}}</p>
                <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{{header}}</h1>
                <p class="mt-2 text-base text-gray-500">{{description}}</p>
                <div class="mt-6">
                    <a class="wild-link">{{ctaText}}<span aria-hidden="true"> &rarr;</span></a>
                </div>
            </div>
        </div>
    </main>
</div>