import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BusinessType } from '../../../../../data/enums/business_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { BusinessService } from '../../services/business_service/business_service';
import { NewBusinessDTO } from '../../../../../data/dtos/newBusinessDTO';
import { CountryCodes } from '../../../../../data/enums/country_codes';
import { SelectModel } from 'src/shared/components/select/select_model';
import { UtilService } from 'src/shared/services/util/util_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { BusinessMode } from '../../../../../data/enums/business_mode';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionType } from '../../../../../data/enums/subscription_type';
import { TimeZoneOptionDTO } from '../../../../../data/dtos/timezone/TimeZoneOptionDTO';
import { TimeZone } from '../../../../../data/enums/timezone';

@Component({
  selector: 'app-onboardingv2',
  templateUrl: './onboardingv2.component.html'
})
export class OnboardingV2omponent implements OnInit {

  typeOptions: SelectModel[] = [
    {
      name: "Venue or Activity Centre",
      value: BusinessType.VENUE
    },
    {
      name: "Coach",
      value: BusinessType.COACH
    },
    {
      name: "Event Organiser",
      value: BusinessType.EVENTS
    }
  ]
  CountryCodes = CountryCodes;
  TimeZone = TimeZone;
  countryOptions: SelectModel[] = this.util.getCountries();
  modeOptions: SelectModel[] = [
    {
      name: "Booking",
      value: BusinessMode.SYSTEM
    },
    {
      name: "Free Listing",
      value: BusinessMode.ADVERTISE
    }
  ]
  timeZoneOptions: SelectModel[] = [
  ]

  form!: UntypedFormGroup;
  loading: boolean;
  businessAdded: boolean = false;
  businessAddedAnimationComplete: boolean = false;
  businessType: BusinessType = null;
  isAppUser: boolean = false;
  subscriptionType: SubscriptionType = SubscriptionType.UNKNOWN;

  constructor(
    private fb: UntypedFormBuilder,
    private navigation: NavigationService,
    private businessService: BusinessService,
    private auth: AuthenticationService,
    private util: UtilService,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private appComms: CommunitcateWithAppService,
  ) { }

  backToApp() {
    this.appComms.pop();
  }

  getBusinessName(): string {
    return this.form.controls['name'].value;
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.subscriptionType = parseInt(params.subscription ?? SubscriptionType.UNKNOWN);
        this.isAppUser = this.auth.isAppUser;
        this.form = this.fb.group({
          country: new UntypedFormControl('', [Validators.required]),
          timezone: new UntypedFormControl('', [Validators.required]),
          firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(70), this.util.noSpecialCharactersAllowSpaces]),
          surname: new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(70), this.util.noSpecialCharactersAllowSpaces]),
          name: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(70), this.util.noSpecialCharactersAllowSpaces]),
          url: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(70), this.util.noSpecialCharactersAllowSpaces])
        });
        this.form.controls["name"].valueChanges.pipe(
          debounceTime(100)
        ).subscribe((result) => {
          var name = this.form.controls["name"].value;
          var generatedUrl = this.businessService.getUrlFromName(name);
          this.form.controls["url"].setValue(generatedUrl);
        });
        this.form.controls["url"].valueChanges.pipe(
          debounceTime(400)
        ).subscribe((result) => {
          var url = this.form.controls["url"].value;
          var generatedUrl = this.businessService.getUrlFromName(url);
          this.form.controls["url"].setValue(generatedUrl);
        });
        this.form.controls["country"].valueChanges.pipe().subscribe((result) => {
          var country = this.form.controls["country"].value;
          var timezoneOptions = this.getTimeZoneOptionsForCountry(country);
          this.timeZoneOptions = [];
          this.timeZoneOptions.push({
            name: "",
            value: TimeZone.UNKNOWN
          })
          for (let i = 0; i < timezoneOptions.length; i++) {
            const timezone = timezoneOptions[i];
            this.timeZoneOptions.push({
              name: timezone.description,
              value: timezone.timezone
            })
          }
          this.form.controls["timezone"].setValue(null);
        });
      })
  }


  onSubmit(data: OnboardingFormData) {
    this.loading = true;
    if (data.timezone == TimeZone.UNKNOWN) {
      this.handlerError("Choose a Time Zone");
      return;
    }
    var onboardingData: NewBusinessDTO = {
      firstName: data.firstName,
      timezone: data.timezone,
      surname: data.surname,
      name: data.name,
      url: data.url,
      mode: BusinessMode.SYSTEM,
      businessType: BusinessType.COACH,
      countryCode: data.country
    }
    this.businessService.create(onboardingData)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  next() {
    this.businessAdded = true;
  }

  goToDashboard() {
    this.businessAddedAnimationComplete = true;
  }

  resetForm() {
    this.loading = false;
  }

  handlerError(message: string) {
    this.notifyService.notify("Creation Error", message, NotifyType.ERROR);
  }

  done() {
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME, { "mode": BusinessMode.SYSTEM, "subscription": this.subscriptionType });
  }

  alreadyRegistered() {
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME, { "mode": BusinessMode.SYSTEM, "subscription": this.subscriptionType });
  }

  getTimeZoneOptionsForCountry(countryCode: CountryCodes): TimeZoneOptionDTO[] {
    var timezones: TimeZoneOptionDTO[] = [];
    if (countryCode == CountryCodes.GBGBR || countryCode == CountryCodes.GIGIB) {
      //Gibraltar uses the same time zone as the United Kingdom, which is Greenwich Mean Time (GMT) during the winter months and British Summer Time (BST) during the summer months. Therefore, the IANA code for Gibraltar is the same as for the United Kingdom, which is "Europe/London".
      //UK
      timezones.push({
        timezone: TimeZone.GMT,
        description: "GMT - Europe/London"
      });

    } else if (countryCode == CountryCodes.IEIRL) {
      //Ireland
      timezones.push({
        timezone: TimeZone.IST,
        description: "IST - Europe/Dublin"
      });

    } else if (countryCode == CountryCodes.AUAUS) {
      //Australia
      timezones.push({
        timezone: TimeZone.AEST,
        description: "AEST - Australia/Brisbane"
      });
      timezones.push({
        timezone: TimeZone.ACST,
        description: "ACST - Australia/Darwin"
      });
      timezones.push({
        timezone: TimeZone.AWST,
        description: "AWST - Australia/Perth"
      });

    } else if (countryCode == CountryCodes.ATAUT) {
      //Austria
      timezones.push({
        timezone: TimeZone.ATCET,
        description: "CET - Europe/Vienna"
      });
    } else if (countryCode == CountryCodes.BEBEL) {
      //Belgium
      timezones.push({
        timezone: TimeZone.BECET,
        description: "CET - Europe/Brussels"
      });
    } else if (countryCode == CountryCodes.BRBRA) {
      //Brazil
      timezones.push({
        timezone: TimeZone.BRFNT,
        description: "FNT - America/Noronha"
      });
      timezones.push({
        timezone: TimeZone.BRBRT,
        description: "BRT - America/Sao_Paulo"
      });
      timezones.push({
        timezone: TimeZone.BRAMT,
        description: "AMT - America/Manaus"
      });
      timezones.push({
        timezone: TimeZone.BRACT,
        description: "ACT - America/Rio_Branco"
      });
    } else if (countryCode == CountryCodes.BGBGR) {
      //Bulgaria
      timezones.push({
        timezone: TimeZone.BGEET,
        description: "EET - Europe/Sofia"
      });
    } else if (countryCode == CountryCodes.CACAN) {
      //Canada
      timezones.push({
        timezone: TimeZone.CAPT,
        description: "PT - America/Vancouver"
      });
      timezones.push({
        timezone: TimeZone.CAMT,
        description: "MT - America/Edmonton"
      });
      timezones.push({
        timezone: TimeZone.CACT,
        description: "CT - America/Winnipeg"
      });
      timezones.push({
        timezone: TimeZone.CAET,
        description: "ET - America/Toronto"
      });
      timezones.push({
        timezone: TimeZone.CAAT,
        description: "AT - America/Halifax"
      });
      timezones.push({
        timezone: TimeZone.CANT,
        description: "NT - America/St_Johns"
      });
    } else if (countryCode == CountryCodes.HRHRV) {
      //Croatia
      timezones.push({
        timezone: TimeZone.HRCET,
        description: "CET - Europe/Zagreb"
      });
    } else if (countryCode == CountryCodes.CYCYP) {
      //Cyprus
      timezones.push({
        timezone: TimeZone.CYEET,
        description: "EET - Europe/Nicosia"
      });
    } else if (countryCode == CountryCodes.CZCZE) {
      //Czech Republic
      timezones.push({
        timezone: TimeZone.CZCET,
        description: "CET - Europe/Prague"
      });
    } else if (countryCode == CountryCodes.DKDNK) {
      //Denmark
      timezones.push({
        timezone: TimeZone.DKCET,
        description: "CET - Europe/Copenhagen"
      });
    } else if (countryCode == CountryCodes.EEEST) {
      //Estonia
      timezones.push({
        timezone: TimeZone.EEEET,
        description: "EET - Europe/Tallin"
      });
    } else if (countryCode == CountryCodes.FIFIN) {
      //Finland
      timezones.push({
        timezone: TimeZone.FICET,
        description: "CET - Europe/Helsinki"
      });
    } else if (countryCode == CountryCodes.FRFRA) {
      //France
      timezones.push({
        timezone: TimeZone.FRCET,
        description: "CET - Europe/Paris"
      });
    } else if (countryCode == CountryCodes.DEDEU) {
      //Germany
      timezones.push({
        timezone: TimeZone.DECET,
        description: "CET - Europe/Berlin"
      });
    } else if (countryCode == CountryCodes.GRGRC) {
      //Greece
      timezones.push({
        timezone: TimeZone.GREET,
        description: "EET - Europe/Athens"
      });
    } else if (countryCode == CountryCodes.HKHKG) {
      //Hong Kong
      timezones.push({
        timezone: TimeZone.HKHKT,
        description: "HKT - Asia/Hong_Kong"
      });
    } else if (countryCode == CountryCodes.HUHUN) {
      //Hungary
      timezones.push({
        timezone: TimeZone.HUCET,
        description: "CET - Europe/Budapest"
      });
    } else if (countryCode == CountryCodes.ININD) {
      //India
      timezones.push({
        timezone: TimeZone.INIST,
        description: "IST - Asia/Kolkata"
      });
    } else if (countryCode == CountryCodes.IDIDN) {
      //Indonesia
      timezones.push({
        timezone: TimeZone.IDWIB,
        description: "WIB - Asia/Jakarta"
      });
      timezones.push({
        timezone: TimeZone.IDWITA,
        description: "WITA - Asia/Makassar"
      });
      timezones.push({
        timezone: TimeZone.IDWIT,
        description: "WIT - Asia/Jayapura"
      });
    } else if (countryCode == CountryCodes.ITITA) {
      //Italy
      timezones.push({
        timezone: TimeZone.ITCET,
        description: "CET - Europe/Rome"
      });
    } else if (countryCode == CountryCodes.JPJPN) {
      //Japan
      timezones.push({
        timezone: TimeZone.JPJST,
        description: "JST - Asia/Tokyo"
      });
    } else if (countryCode == CountryCodes.LVLVA) {
      //Latvia
      timezones.push({
        timezone: TimeZone.LVEET,
        description: "EET - Europe/Riga"
      });
    } else if (countryCode == CountryCodes.LILIE) {
      //Liechtenstein
      timezones.push({
        timezone: TimeZone.LICET,
        description: "CET - Europe/Vaduz"
      });
    } else if (countryCode == CountryCodes.LTLTU) {
      //Lithuania
      timezones.push({
        timezone: TimeZone.LTEET,
        description: "EET - Europe/Vilnius"
      });
    } else if (countryCode == CountryCodes.LULUX) {
      //Luxembourg
      timezones.push({
        timezone: TimeZone.LUCET,
        description: "CET - Europe/Luxembourg"
      });
    } else if (countryCode == CountryCodes.MYMYS) {
      //Malaysia
      timezones.push({
        timezone: TimeZone.MYMST,
        description: "MST - Asia/Kuala_Lumpur"
      });
    } else if (countryCode == CountryCodes.MTMLT) {
      //Malta
      timezones.push({
        timezone: TimeZone.MTCET,
        description: "CET - Europe/Malta"
      });
    } else if (countryCode == CountryCodes.MXMEX) {
      //Mexico
      timezones.push({
        timezone: TimeZone.MXPT,
        description: "PT - America/Tijuana"
      });
      timezones.push({
        timezone: TimeZone.MXMT,
        description: "MT - America/Hermosillo"
      });
      timezones.push({
        timezone: TimeZone.MXCT,
        description: "CT - America/Mexico_City"
      });
      timezones.push({
        timezone: TimeZone.MXET,
        description: "ET - America/Cancun"
      });
    } else if (countryCode == CountryCodes.NLNLD) {
      //Netherlands
      timezones.push({
        timezone: TimeZone.NLCET,
        description: "CET - Europe/Amsterdam"
      });
    } else if (countryCode == CountryCodes.NZNZL) {
      //New Zealand
      timezones.push({
        timezone: TimeZone.NZNZST,
        description: "NZST - Pacific/Auckland"
      });
      timezones.push({
        timezone: TimeZone.NZCHAST,
        description: "CHAST - Pacific/Chatham"
      });
    } else if (countryCode == CountryCodes.NONOR) {
      //Norway
      timezones.push({
        timezone: TimeZone.NOCET,
        description: "CET - Europe/Oslo"
      });
    } else if (countryCode == CountryCodes.PLPOL) {
      //Poland
      timezones.push({
        timezone: TimeZone.PLCET,
        description: "CET - Europe/Warsaw"
      });
    } else if (countryCode == CountryCodes.PTPRT) {
      //Portugal
      timezones.push({
        timezone: TimeZone.PTCET,
        description: "CET - Europe/Lisbon"
      });
    } else if (countryCode == CountryCodes.ROROU) {
      //Romania
      timezones.push({
        timezone: TimeZone.ROEET,
        description: "EET - Europe/Bucharest"
      });
    } else if (countryCode == CountryCodes.SGSGP) {
      //Singapore
      timezones.push({
        timezone: TimeZone.SGSGT,
        description: "SGT - Asia/Singapore"
      });
    } else if (countryCode == CountryCodes.SKSVK) {
      //Slovakia
      timezones.push({
        timezone: TimeZone.SKCET,
        description: "CET - Europe/Bratislava"
      });
    } else if (countryCode == CountryCodes.SISVN) {
      //Slovenia
      timezones.push({
        timezone: TimeZone.SICET,
        description: "CET - Europe/Ljubljana"
      });
    } else if (countryCode == CountryCodes.ESESP) {
      //Spain
      timezones.push({
        timezone: TimeZone.ESCET,
        description: "CET - Europe/Madrid"
      });
    } else if (countryCode == CountryCodes.SESWE) {
      //Sweden
      timezones.push({
        timezone: TimeZone.SECET,
        description: "CET - Europe/Stockholm"
      });
    } else if (countryCode == CountryCodes.CHCHE) {
      //Switzerland
      timezones.push({
        timezone: TimeZone.CHCET,
        description: "CET - Europe/Zurich"
      });
    } else if (countryCode == CountryCodes.THTHA) {
      //Thailand
      timezones.push({
        timezone: TimeZone.THICT,
        description: "ICT - Asia/Bangkok"
      });
    } else if (countryCode == CountryCodes.AEARE) {
      //United Arab Emirates
      timezones.push({
        timezone: TimeZone.AEGST,
        description: "GST - Asia/Dubai"
      });
    } else if (countryCode == CountryCodes.USUSA) {
      //United States
      timezones.push({
        timezone: TimeZone.USEST,
        description: "EST - America/New_York"
      });
      timezones.push({
        timezone: TimeZone.USCST,
        description: "CST - America/Chicago"
      });
      timezones.push({
        timezone: TimeZone.USMST,
        description: "MST - America/Denver"
      });
      timezones.push({
        timezone: TimeZone.USPST,
        description: "PST - America/Los_Angeles"
      });
      timezones.push({
        timezone: TimeZone.USAKST,
        description: "AKST - America/Anchorage"
      });
      timezones.push({
        timezone: TimeZone.USHST,
        description: "HST - Pacific/Honolulu"
      });
    }
    return timezones;
  }

}

interface OnboardingFormData {
  name: string,
  firstName: string,
  surname: string,
  url: string,
  country: CountryCodes,
  timezone: TimeZone
}