import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PayoutBalanceDTO } from '../../../../../data/dtos/business/PayoutBalanceDTO';
import { PayoutStatus } from '../../../../../data/enums/payout_status';
import { PayoutModel } from '../../../../../data/models/payout_model';
import { FirestoreQueryType } from '../../../../../data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { BusinessService } from '../business_service/business_service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class PayoutsService {

    constructor(
        private http: HttpService,
        private firestore: FirestoreService,
        private businessService: BusinessService,
    ) { }

    list(): Observable<PayoutModel[]> {
        var businessId = this.businessService.getActiveBusinessId();
        return this.firestore.getListFromSubcollectionByQuery("business", businessId, "payout", [
            {
                type: FirestoreQueryType.WHERE,
                operator: "in",
                property: "status",
                value: [PayoutStatus.CANCELLED, PayoutStatus.FAILED, PayoutStatus.INTRANSIT, PayoutStatus.PAID, PayoutStatus.PENDING]
            },
            {
                type: FirestoreQueryType.ORDERBY,
                operator: "==",
                property: "updatedDate",
                value: "desc"
            }
        ])
    }

    getBalance(): Observable<PayoutBalanceDTO> {
        return this.http.get(`${environment.api}/business/finance/balance`)
    }
}

