import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-formtags',
  template: `
  <div *ngIf="processedOptions" class="mt-6 w-full">
  <div class="flex justify-between mb-2">
    <label [for]="name" class="block wild-label">
      {{description}}
    </label>
    <span *ngIf="!required && description" class="text-sm text-gray-500" id="email-optional">Optional</span>
  </div>
  <span (click)="selectOption(option)" *ngFor="let option of processedOptions" [ngClass]="
  {'wild-badge-light hover:bg-gray-100':!option.selected,
  'wild-badge-primary':option.selected
  }" class="cursor-pointer ">
    {{option.option}}
  </span>
</div>
<div *ngIf="form.get(name)?.errors && (form.get(name)?.dirty || form['submitted'] == true)"> 
      <div class="font-light text-xs text-red-600 py-1" *ngIf="form.get(name)?.errors">
          <span *ngIf="!validation"> {{description}} is required</span>
          <span *ngIf="validation"> {{validation}}</span>
      </div>
  </div>
  `
})



export class FormTagsComponent implements OnInit {

  @Input() form!: any;
  @Input() name!: string;
  @Input() validation!: string;
  @Input() description!: string;
  @Input() value!: string[];
  @Input() options!: string[];
  @Output() onSelect = new EventEmitter<string[]>();

  required: boolean;
  processedOptions: ProcessedOption[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.value)
      this.form.controls[this.name].setValue(this.value);
    const form_field = this.form.get(this.name);
    if (form_field.validator) {
      const validator = form_field.validator({} as AbstractControl);
      if (validator && validator.required)
        this.required = true;
    }
    this.processOptions();
  }

  processOptions() {
    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      var selected = false;
      if (this.value != null && this.value?.length > 0) {
        var found = this.value.find(s => s == option);
        if (found)
          selected = true;
      }
      var processedOption: ProcessedOption = {
        option: option,
        selected: selected
      }
      this.processedOptions.push(processedOption);
    }
  }

  selectOption(option: ProcessedOption) {
    option.selected = !option.selected;
    var output = [];
    for (let i = 0; i < this.processedOptions.length; i++) {
      const option = this.processedOptions[i];
      if (option.selected == true)
        output.push(option.option);
    }
    this.value = output;
    this.form.controls[this.name].setValue(this.value);
    this.onSelect.emit(output);
  }
}

interface ProcessedOption {
  option: string,
  selected: boolean
}