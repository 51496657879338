<app-wrapper header="Finance" paragraph="See your payouts and access detailed finance reports">
  <div class="mb-8">
    <app-businessstats></app-businessstats>
  </div>
  <ng-container *ngIf="payouts$ | async as payouts; else loadingOrError">
    <div *ngIf="!payouts || payouts.length == 0; else payoutstable">
      <app-empty ctatext="Shopping Bag" icon="coins" header="No payouts yet"
        description="Get Started by launching a service and sharing your brand"></app-empty>
    </div>
    <ng-template #payoutstable>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Updated
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estimated Payment Date
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr *ngFor="let payout of payouts">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{payout.updateDateIso | date : "MMM d, y, h:mm a"}}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{payout.arrival_date_iso | date : "MMM d, y"}}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span *ngIf="payout.status == PayoutStatus.PAID"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Paid
                      </span>
                      <span *ngIf="payout.status == PayoutStatus.PENDING"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                        Pending
                      </span>
                      <span *ngIf="payout.status == PayoutStatus.INTRANSIT"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                        In-Transit
                      </span>
                      <span *ngIf="payout.status == PayoutStatus.CANCELLED"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Cancelled
                      </span>
                      <span *ngIf="payout.status == PayoutStatus.FAILED"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Failed
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{payout.amount | price: payout.currencyCode}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br />
    </ng-template>
  </ng-container>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
      </app-retry>
    </ng-container>
    <ng-template #loading>
      <app-loader></app-loader>
    </ng-template>
  </ng-template>
</app-wrapper>