import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Favourite } from '../../../../../../data/models/favourites_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { FavouritesService } from 'src/wild/modules/user/services/favourites_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { environment } from 'src/environments/environment';
import { SwimspotsdataService } from 'src/portal/services/swimspotsdata.service';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit {

  favourites$: Observable<Favourite[]>;
  error: ErrorModel;
  imagesUrl = environment.imagesUrl;

  constructor(
    private swimspotDate: SwimspotsdataService,
    private modalService: ModalService,
    private favouritesService: FavouritesService,
    private navigation: NavigationService) { }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.favourites$ = null;
    this.favourites$ = this.favouritesService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  async openSwimSpot(id) {
    var settings: ModalModel = {
      header: "Opening",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var swimspot = await this.swimspotDate.read(id).toPromise();
    this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, swimspot.canonical);
    this.modalService.hide();
  }

}
