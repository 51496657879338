import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { environment } from 'src/environments/environment';
import { UserCouponsService } from '../../../services/usercoupons_service';
import { UserCouponsDTO } from '../../../../../../data/dtos/coupons/UserCouponsDTO';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  templateUrl: './usercoupons.component.html',
})
export class UserCouponsComponent implements OnInit {

  coupons$: Observable<UserCouponsDTO>;
  error: ErrorModel;
  isAppUser: boolean = false;
  imagesUrl = environment.imagesUrl;

  constructor(
    private userCouponsService: UserCouponsService,
    private auth: AuthenticationService,
    private navigation: NavigationService) { }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.load();
  }

  load() {
    this.coupons$ = null;
    this.coupons$ = this.userCouponsService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
