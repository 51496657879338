import { Component, OnInit } from '@angular/core';
import { TabModel } from 'src/shared/components/tabs/tab_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {

  constructor(private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  activeTabId: string = "paymentmethods";
  tabs: TabModel[] = [
    {
      id: "paymentmethods",
      name: "Payment Methods",
      icon: "credit-card",
      active: true
    }
  ] 

  updateActiveTabId(id: string) {
    this.activeTabId = id;
  }


}
