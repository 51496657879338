<div class="max-w-7xl mx-auto p-6 w-full flex flex-col text-center">
  <ng-container *ngIf="waiver$ | async as waiver; else loadingOrError">
    <div *ngIf="!waiver; else loaded">
      waiver not found
    </div>
    <ng-template #loaded>
      <img class="mx-auto mb-4 aspect-[2/2] w-40 flex-none rounded-2xl object-cover" [src]="waiver.image" alt="">
      <div *ngIf="signed">
        <h1 class="text-xl font-semibold">Waiver Signed</h1>
        <h2 class="text-lg mt-5 font-semibold">Enjoy your Activity! 🌊</h2>
        <a (click)="appdone()" [href]="waiver.businessURL" class="wild-btn-primary mt-6 w-60">Done</a>
      </div>
      <div *ngIf="!signed">
        <h1 class="text-xl font-semibold">{{waiver.header}}</h1>
        <h2 class="text-lg mt-5 font-semibold">{{waiver.signatureRequiredHeader}}</h2>
        <h4 class="text-md">{{waiver.signatureRequiredWhoFor}}</h4>
        <h2 class="text-lg mt-5 font-semibold">{{waiver.explanationHeader}}</h2>
        <h4 class="text-md">{{waiver.explanationText}}</h4>
        <div *ngIf="waiver.html" class="border border-gray-200 shadow-sm my-6 p-3 rounded-md" [innerHTML]="waiver.html">
        </div>
      </div>

      <!-- i
    status: ContactWaiverStatus,
-->

    </ng-template>
  </ng-container>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
      </app-retry>
    </ng-container>
    <ng-template #loading>
      <app-loader></app-loader>
    </ng-template>
  </ng-template>
  <div *ngIf="!signed" [ngClass]="{'hidden': !showSignatureArea}">
    <h2 class="text-lg mt-5 font-semibold">Sign & Print Name Below</h2>
    <div class="mt-4">
      <signature-pad #signature [options]="signaturePadOptions"></signature-pad>
      <div style="background-color: antiquewhite;" class="w-full py-3 text-sm font-semibold">Sign in the Box ☝️</div>
      <div class="flex items-center justify-center my-3">
        <button (click)="clearPad()" class="wild-btn-light">Clear Signature</button>
      </div>
    </div>
    <div class="mb-5" *ngIf="waiverform">
      <app-form [form]="waiverform" [fullwidthbutton]="false" ctaText="Sign Waiver" disableDivide="true"
        (onSubmit)="onSubmit($event)" [loading]="loading" [fullwidthbutton]="true">
        <app-inputgroup>
          <app-input [form]="waiverform" type="text" name="name" description="Print Your Name" [span]=6
            palceholder="Enter your Full Name Here"></app-input>
        </app-inputgroup>

      </app-form>
    </div>
  </div>
</div>