import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { debounceTime, take } from 'rxjs/operators';
import { LocationResultType } from '../../data/enums/location_result_type';
import { WaterwayType } from '../../data/enums/waterway_type';
import { SearchResult } from '../../data/models/SearchResult';
import { SearchResults } from '../../data/models/SearchResults';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SearchService } from 'src/shared/services/search/search_service';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../data/enums/navigation_type';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { FirestoreQueryType } from '../../data/enums/firestore_query_type';
import { OrderModel } from '../../data/models/order_model';
import { OrderStatus } from '../../data/enums/order_status';
import { SideMenuItems } from './sidemenu/sidemenu_items_model';
import { ModalType } from '../services/modal/modal_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';

@Component({
  selector: 'app-wild-header',
  template: `
  <div class="relative z-40">
  <!-- NOTIFICATIONS START -->
  <!-- showNotificationsAnimate -->
  <div *ngIf="showNotifications" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div class="fixed inset-0"></div>

  <div class="fixed inset-0 overflow-hidden">
    <div (click)="closeNotifications()" class="absolute inset-0 overflow-hidden" style="background-color:#0000006b">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div class="pointer-events-auto w-screen max-w-md">
          <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">Notifications</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button (click)="closeNotifications()" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close panel</span>
                    <!-- Heroicon name: outline/x-mark -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="relative mt-6 flex-1 px-4 sm:px-6">
              <app-notificationslist></app-notificationslist>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- NOTIFICATIONS  END -->
  <!-- SLIDE OVER START -->
  <div *ngIf="smallmenu" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0"></div>
    <div class="fixed inset-0 overflow-hidden">
      <div (click)="closeSmallMenu()" class="absolute inset-0 overflow-hidden" style="background-color:#0000006b">
        <div [ngClass]="{'translate-x-full': !smallmenu,'translate-x-0': smallmenu}" class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 transform transition ease-in-out duration-500 sm:duration-700">
          <div   class="pointer-events-auto w-screen max-w-md ">
            <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
              <div class="px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">Menu</h2>
                  <div class="ml-3 flex h-7 items-center">
                    <button (click)="closeSmallMenu()" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x-mark -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="isLoggedIn" (click)="organiser()" class="w-full mt-6 p-6 wild-bg-primary text-white text-md flex items-center hover:underline cursor-pointer">
                <div>
                  <fa-icon class="text-2xl text-white" icon="exchange-alt"></fa-icon>
                </div>
                <div class="ml-3">
                  Switch to business mode
                </div>
              </div>
              <div class="relative flex-1 px-4 sm:px-6">
              <div class="mt-6 flow-root">
                <div class="-my-6 divide-y divide-gray-500/10">
                    <div *ngIf="isLoggedIn" class="space-y-2 pt-3 pb-6">
                       <!-- LOGGED IN MENU ITEMS START -->
                          <a  *ngFor="let menu of userMenuItems" routerLinkActive="bg-gray-100 text-gray-900" [routerLink]="menu.route"  [routerLinkActiveOptions]="{ exact: true }" class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100 cursor-pointer">
                            {{menu.description}}
                          </a>
                          <a (click)="toggleNotifications()"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">
                          <div class="flex items-center justify-between">
                          <span>Notifications</span><div *ngIf="numberOfUnreadMessages > 0" class="bg-red-600 rounded-full h-3 w-3 ml-4"></div>
                          </div>
                        </a>
                       <!-- LOGGED IN MENU ITEMS END -->
                    </div>
                    <div *ngIf="isLoggedIn" class="py-6">
                      <a (click)="discover()"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Search Wild</a>  
                      <a href="https://blog.wildopenwater.com"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Blog</a>  
                      <a href="https://wild-19.teemill.com/"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Wild Shop</a>  
                      <a (click)="addSwimSpot()"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Add Spot</a>
                    </div>
                    <div *ngIf="!isLoggedIn" class="py-6">
                      <a (click)="register()"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Register</a>
                      <a (click)="login()" class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Log in</a>
                    </div>
                    <div class="py-6">
                      <a (click)="becomeOrganiser()" class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Wild for Business</a>
                      <a (click)="pricing()" class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Business Pricing</a>
                    </div>
                    <div class="py-6" *ngIf="isLoggedIn">
                      <a (click)="logout()" class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Sign Out</a>
                    </div>
                  </div>
                  <div class="mb-8">
                      <app-appdownloadlinks header="Get the app"></app-appdownloadlinks>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SLIDE OVER END -->
  <!-- NEW HEADER START -->
  <div *ngIf="!isAppUser && !isWidgetUser"  class="px-6 pt-6 lg:px-8">
    <div>
      <nav class="flex h-12 items-center justify-between mb-5" aria-label="Global">
        <div class="flex lg:min-w-0 lg:flex-1" aria-label="Global">
          <a [routerLink]="['']" class="cursor-pointer -m-1.5 p-1.5">
            <img class="h-10" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fwild%20logo%20small.png?alt=media&token=5db7aaeb-d57d-455b-bb0b-966738cfde41" alt="">
          </a>
        </div>
        <div class="flex items-center">
        <div *ngIf="!hidebusinesslinks && !isBusinessPage " class="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
          <a (click)="becomeOrganiser()"
            class="mr-3 cursor-pointer inline-block rounded-lg wild-bg-primary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-900 hover:bg-blue-900 hover:ring-blue-900">
            For Business
          </a>
        </div>
        <div *ngIf="isBusinessPage && !isLoggedIn" class="hidden lg:block mr-3">
          <a (click)="pricing()"
            class="text-gray-900 cursor-pointer hover:underline">
            Pricing
          </a>
        </div>
        <div *ngIf="isBusinessPage && isLoggedIn" class="hidden lg:block mr-3">
          <a (click)="organiser()"
            class="text-gray-900 cursor-pointer hover:underline">
            dashboard
          </a>
        </div>
        <div *ngIf="isBusinessPage && !isLoggedIn" class="hidden lg:block">
          <a (click)="businessRegister()"
            class="mr-3 cursor-pointer inline-block rounded-lg wild-bg-primary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-900 hover:bg-blue-900 hover:ring-blue-900">
            Start for free
          </a>
        </div>
        <div *ngIf="isBusinessPage && isLoggedIn" class="hidden lg:block">
          <a (click)="onboarding()"
            class="mr-3 cursor-pointer inline-block rounded-lg wild-bg-primary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-900 hover:bg-blue-900 hover:ring-blue-900">
            Start for free
          </a>
        </div>
        <!-- BASKET START -->
        <div  *ngIf="isLoggedIn" class="relative mr-3">
          <a (click)="goToBasket()" class="group flex items-center cursor-pointer">
          <span [ngClass]="{'wild-text-primary': numberOfOrderLines > 0,'text-gray-200': numberOfOrderLines == 0}"  class="mr-1 text-lg font-medium position: relative;  top: 6px;">{{numberOfOrderLines}}</span>
            <svg class="flex-shrink-0 h-9 w-9 wild-text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
          </a>
        </div>
        <!-- BASKET END -->
        <div #notifications #dropdown class="flex-shrink-0 relative">
          <!-- PROFILE PIC BUTTON START -->
          <div *ngIf="isLoggedIn">
              <button (click)="toggleSmallMenu()" type="button"
                class="bg-white border shadow-xl border-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <img class="h-10 w-10 rounded-full"
                  [src]="profilePic != null && profilePic != '' ? profilePic : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'"
                  alt="">
                  <div *ngIf="numberOfUnreadMessages > 0" class="absolute -top-2 -right-1 bg-white rounded-full h-4 w-4 flex items-center justify-center">
                    <div class="bg-red-600 rounded-full h-3 w-3"></div>
                  </div>
              </button>
            </div>
            <!-- PROFILE PIC BUTTON END -->
            <!-- LOGGED OUT BURGER MENU START -->
            <button *ngIf="!isLoggedIn" (click)="toggleSmallMenu()" type="button" class="relative p-1 rounded-full wild-text-primary">
              <fa-icon class="ng-fa-icon wild-text-primary text-4xl" icon="bars"></fa-icon>
            </button>
            <!-- LOGGED OUT BURGER MENU END -->
            </div>
       
        </div>
       
      </nav>
    </div>
  </div>
<!-- NEW HEADER END -->
</div>
  `
})
export class WildHeaderComponent implements OnInit, AfterViewInit {

  @Input() inverted: boolean;
  @Input() hidebusinesslinks: boolean;
  @Input() showSearchForm: boolean;
  @Input() isBusinessPage: boolean;

  userMenuItems: SideMenuItems[] = [
    {
      description: "Profile",
      route: ["/user/profile"],
      icon: "user-alt",
      visible: true
    },
    {
      description: "Booking Details",
      route: ["/user/details"],
      icon: "user-cog",
      visible: true
    },
    {
      description: "Linked Accounts",
      route: ["/user/linkedaccounts"],
      icon: "users",
      visible: true
    },
    {
      description: "Booked Activities",
      route: ["/user/activities"],
      icon: "calendar-alt",
      visible: true
    },
    {
      description: "Waivers to Sign",
      route: ["/user/waivers"],
      icon: "signature",
      visible: true
    },
    {
      description: "Orders",
      route: ["/user/orders"],
      icon: "shopping-bag",
      visible: true
    }, {
      description: "Favourites",
      route: ["/user/favourites"],
      icon: "heart",
      visible: true
    },
    {
      description: "My Spots",
      route: ["/user/myswimspots"],
      icon: "swimmer",
      visible: true
    },
    {
      description: "Wild Pro",
      route: ["/user/membership"],
      icon: "swimmer",
      visible: true
    },
  ]
  smallmenu: boolean = false;
  smallmenuAnimate: boolean = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('notifications') notifications: ElementRef;
  form: UntypedFormGroup;
  searchResults: SearchResults;
  showSearch: boolean;
  searchLoading: boolean;
  showNotifications: boolean = false;
  showNotificationsAnimate: boolean = false;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;
  minSearchCharacters = 3;
  showMinCharactersNotReached: boolean;
  charactersTyped = 0
  numberOfUnreadMessages: number = 0;
  numberOfOrderLines: number = 0;
  @ViewChild('search') searchElement: ElementRef;
  isAppUser: boolean = false;
  isWidgetUser: boolean = false;

  constructor(private auth: AuthenticationService,
    private modalService: ModalService,
    private navigation: NavigationService,
    private router: Router,
    private firestore: FirestoreService,
    private businessService: BusinessService,
    private storage: StorageService,
    private route: ActivatedRoute,
    private notify: NotifyService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private searchService: SearchService,
    private renderer: Renderer2) {
    // router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.closeSmallMenu();
    //   }
    // });
    // router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.closeSmallMenu();
    //   }
    // });
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   //In case the dropdown is hidden . e.g. logged out
    //   if (this.dropdown) {
    //     if (!this.dropdown.nativeElement.contains(e.target)) {
    //       this.closeSmallMenu();
    //     }
    //   }
    //   if (this.notifications) {
    //     if (!this.notifications.nativeElement.contains(e.target)) {
    //       this.closeNotifications();
    //     }
    //   }
    // });
  }
  ngAfterViewInit(): void {
    this.focusSearch();
  }

  listenForNotifications() {
    if (this.isLoggedIn) {
      this.userService.listener().subscribe((user) => {
        this.numberOfUnreadMessages = user?.numberOfUnreadMessages ?? 0;
      })
    }
  }

  listenForOrders() {
    var userId = this.auth.getLoggedInCookie();
    var now = new Date();
    this.firestore.listenerByQueryForCollection("orders", [
      {
        operator: "==",
        property: "contactId",
        type: FirestoreQueryType.WHERE,
        value: userId
      },
      {
        operator: ">=",
        property: "cartUpdated",
        type: FirestoreQueryType.WHERE,
        value: now
      },
      {
        operator: "==",
        property: "status",
        type: FirestoreQueryType.WHERE,
        value: OrderStatus.SHOPPINGCART
      }
    ]).subscribe(async (orders: OrderModel[]) => {
      var numberOfOrderLines = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        const orderLines = await this.firestore.getListFromSubcollectionByQuery("orders", order.id, "orderlines", []).toPromise();
        for (let y = 0; y < orderLines.length; y++) {
          // const orderLine = orderLines[y];
          numberOfOrderLines = numberOfOrderLines + 1;
        }
      }
      this.numberOfOrderLines = numberOfOrderLines;
    })
  }

  goToSearch() {
    this.navigation.navigate(WildNavigationType.BROCHURESEARCHRESULTS)
  }

  discover() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  focusSearch() {
    var homesearch = this.storage.getCookie("homesearch");
    if (homesearch != undefined && homesearch != "")
      setTimeout(() => {
        this.form.controls["search"].setValue(homesearch);
        this.storage.deleteCookie("homesearch");
        this.searchElement.nativeElement.focus();
      }, 20)
  }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.isWidgetUser = this.auth.isWidgetUser;
    this.listenForNotifications();
    this.listenForOrders();
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 2);
  }

  async search(value) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      if (value.search.length >= this.minSearchCharacters) {
        this.showMinCharactersNotReached = false;
        this.searchLoading = true;
        await this.searchService.search(value.search).pipe(take(1)).toPromise()
          .then((searchResults) => {
            this.searchResults = searchResults;
          })
          .catch((error) => {
            this.handlerError(error);
          })
          .finally(() => {
            this.searchFinished();
          })
      } else {
        this.charactersTyped = value.search.length;
        this.searchLoading = false;
        this.showMinCharactersNotReached = true;
      }
    }
  }

  handlerError(message: string) {
    this.notify.notify("Search Error", message, NotifyType.ERROR);
  }

  searchFinished() {
    this.searchLoading = false;
  }

  clearSearch() {
    this.form.controls["search"].setValue("");
    this.searchResults = null;
  }

  selectSearchResult(result: SearchResult) {
    if (result.type == LocationResultType.PLACE) {
      this.navigation.navigate(WildNavigationType.BROCHURESEARCHRESULTS, { query: result.name, latitude: result.latitude, longitude: result.longitude });
    } else if (result.type == LocationResultType.SWIMSPOT) {
      this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, result.canonical);
    }
    this.clearSearch();
  }


  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  login() {
    this.smallmenu = false;
    this.navigation.navigate(WildNavigationType.LOGIN);
  }

  addSwimSpot() {
    this.navigation.navigate(WildNavigationType.USERADDSWIMSPOT)
  }

  logout() {
    this.closeSmallMenu();
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to sign out?",
      header: "Sign Out?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: () => {
        this.processLogOut();
      }
    }
    this.modalService.show(settings);
  }

  async processLogOut() {
    this.auth.logout();
    setTimeout(() => {
      window.location.href = "./"
    }, 500)
  }

  goToBasket() {
    this.navigation.navigate(WildNavigationType.BOOKINGBASKET);
  }

  businessRegister() {
    this.navigation.navigate(WildNavigationType.REGISTER, { "business": 1, "nav": WildNavigationType.ONBOARDINGV2 });
  }
  businessLogin() {
    this.navigation.navigate(WildNavigationType.LOGIN, { "business": 1, "nav": WildNavigationType.DASHBOARDHOME });
  }

  register() {
    this.smallmenu = false;
    this.navigation.navigate(WildNavigationType.REGISTER);
  }

  becomeOrganiser() {
    if (this.businessService.getActiveBusinessId() != "") {
      this.navigation.navigate(WildNavigationType.DASHBOARDHOME, {})
    } else {
      this.navigation.navigate(WildNavigationType.BECOMEANORGANISER, {})
    }
  }
  pricing() {
    this.navigation.navigate(WildNavigationType.PRICING, {})
  }

  onboarding() {
    this.navigation.navigate(WildNavigationType.ONBOARDINGV2);

  }

  toggleSmallMenu() {
    if (!this.smallmenu) {
      this.openSmallMenu();
    } else {
      this.closeSmallMenu();
    }
  }

  openSmallMenu() {
    this.smallmenu = true;
    setTimeout(() => {
      this.smallmenuAnimate = true;
    }, 1)
  }

  closeSmallMenu() {
    this.smallmenuAnimate = false;
    setTimeout(() => {
      this.smallmenu = false;
    }, 300)
  }

  toggleNotifications() {
    this.closeSmallMenu();
    if (!this.showNotifications) {
      this.openNotifications();
    } else {
      this.closeNotifications();
    }
  }

  openNotifications() {
    this.showNotifications = true;
    setTimeout(() => {
      this.showNotificationsAnimate = true;
    }, 30)
  }

  closeNotifications() {
    this.showNotificationsAnimate = false;
    setTimeout(() => {
      this.showNotifications = false;
    }, 300)
  }

  swimmer() {
    this.navigation.navigate(WildNavigationType.USERDASHBOARD);
  }
  profile() {
    this.navigation.navigate(WildNavigationType.USERPROFILEPAGE);
  }
  favourites() {
    this.navigation.navigate(WildNavigationType.FAVOURITES);
  }
  swimspots() {
    this.navigation.navigate(WildNavigationType.MYSWIMSPOTS);
  }
  notificationsPage() {
    this.navigation.navigate(WildNavigationType.USERNOTIFICATIONS);
  }

  organiser() {
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
  }

  get profilePic(): string {
    return this.auth.getProfilePic();
  }

}
