import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ImageModel } from '../../../../../../data/models/images_model';
import { PostModel } from '../../../../../../data/models/post_model';
import { SwimSpot } from '../../../../../../data/models/swimspot_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { SwimspotsdataService } from '../../../services/swimspotdata_service/swimspotsdata.service';
import { environment } from 'src/environments/environment';
import { AppPromptService } from 'src/shared/services/appprompt/appprompt_service';


@Component({
  selector: '[app-swimspotposts]',
  template: `
<!-- POST LIST START -->
<div *ngIf="posts$">
    <ng-container *ngIf="posts$ | async as posts; else loadingOrError">
      <div *ngIf="!posts || posts.length == 0; else postslist">
        <app-empty icon="sticky-note" header="No posts yet" ctatext="Write Post" [showcta]="true" (click)="prompt()" ></app-empty>    
      </div>
      <ng-template #postslist>
        <div class="bg-white">
          <!-- LIST OF POSTS START -->
          <div class="py-8 sm:py-12 lg:max-w-7xl lg:gap-x-8">
            <div class="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-11">
              <div class="flow-root">
                <div class="-my-12 divide-y divide-gray-200">
                  <div *ngFor="let post of posts" class="py-12">
                    <div class="flex items-center">
                      <img [src]="post.profilePicture == null ? 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d' :
                      imageUrl + post.profilePicture.image + '_100x100.jpeg?alt=media' " alt="Profile" class="h-12 w-12 rounded-full">
                      <div class="ml-4">
                        <h4 class="text-sm font-bold text-gray-900">{{post?.username ?? 'processing...'}}</h4>
                        <p class="mt-1 text-xs text-gray-500">{{post?.creationDate.toDate() | date}}</p>
                      </div>
                    </div>
                    <div class="mt-4 text-base italic text-gray-600">
                      <p class="font-bold text-lg mb-2">{{post?.header ?? ""}}</p>
                      <p>{{post?.message ?? "No comment"}}</p>
                      <div *ngIf="post?.image"class="my-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-8 ">
                        <div class="relative group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100  overflow-hidden">
                          <img (load)="imageLoaded($event, post.image)" data-max-retry="5" data-retry="0" (error)="waitAndReload($event, post.image)" [src]="imageUrl + post?.image.image + '_640x640.jpeg?alt=media' | safe" alt="" class="object-cover pointer-events-none group-hover:opacity-75">
                        </div> 
                      </div>
                    </div>
                    <div class="mt-2" *ngIf="post.tags">
                      <span class="wild-badge-primary" *ngFor="let tag of post.tags">{{tag}}</span>
                    </div>
                    <div class="text-sm font-semibold" *ngIf="post?.likedBy && post?.likedBy?.length > 0">
                      <div *ngIf="post?.likedBy?.length == 1">
                        {{post?.likedByUsers[0]?.username}} likes this
                      </div>
                      <div *ngIf="post?.likedBy?.length == 2">
                        {{post?.likedByUsers[0]?.username}} and {{post?.likedByUsers[1]?.username}} like this
                      </div>
                      <div *ngIf="post?.likedBy?.length > 2">
                        {{post?.likedByUsers[0]?.username}} and {{post?.likedBy?.length - 1}} others like this
                      </div>
                    </div>
                    <app-socialbar></app-socialbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- LIST OF POSTS END -->
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <div class="px-8">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
            </app-retry>
        </div>
      </ng-container>
      <ng-template #loading>
          <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `,
})
export class SwimSpotPostsComponent implements OnInit {
  @Input() swimspot: SwimSpot;
  posts$: Observable<PostModel[]>;
  error: ErrorModel;
  image: ImageModel;
  form!: UntypedFormGroup;
  loading: boolean;
  imageUrl = environment.imagesUrl;

  constructor(
    private authService: AuthenticationService,
    private fb: UntypedFormBuilder,
    private promptService: AppPromptService,
    private swimspotService: SwimspotsdataService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      header: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required]),
      tags: new UntypedFormControl([], [])
    });
    this.load();
  }

  prompt() {
    this.promptService.prompt();
  }

  get userId(): string {
    return this.authService.getLoggedInCookie();
  }

  load() {
    this.posts$ = null;
    this.posts$ = this.swimspotService.posts(this.swimspot.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
