import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AddToCartDTO } from '../../../../data/dtos/booking/AddToCartDTO';
import { TeamMemberPublicDTO } from '../../../../data/dtos/serviceAvailability/teamMemberPublicDTO';
import { ServiceAvailabilityForDaySlotDTO } from '../../../../data/dtos/serviceAvailabilityForDay/ServiceAvailabilityForDaySlotDTO';
import { ServiceAndPricesDTO } from '../../../../data/dtos/services/ServiceAndPricesDTO';
import { ServicePriceDTO } from '../../../../data/dtos/services/ServicePriceDTO';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BookingService } from '../../../services/booking_service';

@Component({
  template: `
    <app-modaltopper *ngIf="step == 'team'" header="Select your Coach" [parapraph]="service.name"></app-modaltopper>
    <app-selectteammember *ngIf="step == 'team'" [team]="service.teamMembers" (selected)="teamMemberSelected($event)"></app-selectteammember>
    <app-selectdate *ngIf="step == 'date'" [teamMember]="selectedTeamMember" [service]="service" (onDateSelected)="dateSelected($event)"></app-selectdate>
    <app-serviceavailability *ngIf="step == 'date'" (slotSelected)="slotSelected($event)" [teamMember]="selectedTeamMember" [service]="service" [newDate]="newDate.asObservable()"></app-serviceavailability>
    <app-continueshopping *ngIf="step == 'continueshopping'" [businessId]="service.businessId"></app-continueshopping>
    <div *ngIf="step == 'date'">
      <div>
        <div class="text-right">
          <h1 class="font-bold text-lg">{{service.name}}</h1>
          <p class="text-sm text-gray-600 ml-2">{{service.formattedPrice}} - {{service.duration}}</p>
          <p class="text-sm text-gray-600 mt-2" *ngIf="selectedSlot">{{selectedSlot.formattedStartTime}}</p>
          <p class="text-xs wild-text-primary" *ngIf="selectedSlot">until</p>
          <p class="text-sm text-gray-600" *ngIf="selectedSlot">{{selectedSlot.formattedEndTime}}</p>
          <p class="text-sm text-gray-600" *ngIf="!selectedSlot"> Select a time above</p>
        </div>
      </div>
      <div  [ngClass]="{'opacity-50':!selectedSlot,'opacity-100':selectedSlot}" class="border-t border-gray-100 pt-4">
        <button (click)="addToCart()" class="wild-btn-primary w-full">
          <span *ngIf="!addingToCart && !selectedSlot">Select a time above</span>
          <span *ngIf="!addingToCart && selectedSlot">Add to Cart</span>
          <span *ngIf="addingToCart" class="loader"></span>
        </button>
      </div>
      <p class="text-center text-xs text-gray-500 font-semibold mt-3">You can adjust attendees on the next page</p>
    </div> 
  `
})
export class BookWrapperComponent implements OnInit {

  @Input() service: ServiceAndPricesDTO;
  newDate: Subject<string> = new Subject<string>();
  selectedPrice: ServicePriceDTO = null;
  selectedSlot: ServiceAvailabilityForDaySlotDTO;
  selectedTeamMember: TeamMemberPublicDTO;
  step = "team";
  addingToCart: boolean;

  constructor(
    private bookingService: BookingService,
    private navigate: NavigationService,
    private notifyService: NotifyService
  ) { }

  ngOnInit(): void {

  }

  teamMemberSelected(teamMember: TeamMemberPublicDTO) {
    this.selectedTeamMember = teamMember;
    this.step = 'date';
  }

  priceSelected(price: ServicePriceDTO) {
    this.selectedPrice = price;
    this.step = 'team';
  }

  dateSelected(isoDate: string) {
    this.newDate.next(isoDate);
  }

  slotSelected(slot: ServiceAvailabilityForDaySlotDTO) {
    this.selectedSlot = slot;
  }

  async addToCart() {
    if (!this.selectedSlot)
      return;
    if (this.addingToCart)
      return;
    this.addingToCart = true;
    var addToCart: AddToCartDTO = {
      quantity: 1,
      couponId: null,
      slot: this.selectedSlot,
      scheduled: null,
      businessId: this.selectedSlot.businessId
    }
    await this.bookingService.addToCart(addToCart)
      .then(() => {
        this.addedToCart();
      })
      .catch((error) => {
        this.handlerError(error.message);
      }).finally(() => {
        this.addingToCart = false;
      });
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  addedToCart() {
    this.step = "continueshopping"
  }

}
