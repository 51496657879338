import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../data/enums/navigation_type';

@Injectable({
  providedIn: 'root'
})
export class AlreadyAuthenticatedGuard implements CanActivate {
  constructor(
    private auth: AuthenticationService,
    private navigation: NavigationService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    var loggedIn = this.auth.isLoggedIn();
    var hasUsername = this.auth.hasUsername();
    if (loggedIn && hasUsername) {
      this.navigation.navigateWithReturnUrlCheck(WildNavigationType.USERDASHBOARD)
      return false;
    }
    return true;
  }

}
