import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../../FeaturesData";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { usecases } from "../../shared/usecases";
import { bookingExperienceListData } from "../bookingexperience/booking_experience_data_listdata";
import { crmListData } from "../customers/customer_management_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { waiversListData } from "./waivers_data_listdata";

export const waiversData: FeaturesData = {

  seoTitle: "One Click Waivers System",
  seoDescription: "Fully audited and versioned waivers system available at the click of a button. E-signaure enabled on the web, via email and in the Wild app.",
  seoImage: waiversListData.image,
  seoKeyWords: "",
  page: waiversListData.page,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: waiversListData.title,
      subtitle: waiversListData.subtitle,
      paragraph: "E-signatures, fully audited and versioned Waivers System",
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.BIGIMAGEFEATURE,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwaivers%2Fwaivers%20not%20signed-min.png?alt=media&token=f9dec16d-05c0-4dff-93e7-02b9164726be",
      title: "Fully Audited",
      subtitle: "Waivers Made Easy!",
      paragraph: "E-signature waivers and an audited record of all your signed documents",
      data: [
        {
          icon: "cog", title: "Configure.", subtitle: "Versioned Waivers."
        },
        {
          icon: "table", title: "Audited.", subtitle: "Date, doc & e-signature."
        },
        {
          icon: "mouse-pointer", title: "One Click.", subtitle: "To enable."
        },
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwaivers%2Fapp%20signing%20waiver-min.png?alt=media&token=554810ab-1f5e-43c5-a5cf-a9a56df6807b",
      title: "10 seconds",
      subtitle: "Fast Signing",
      paragraph: "Contacts can sign in seconds",
      data: [
        {
          icon: "mobile", title: "Mobile Ready.", subtitle: "Great on the go."
        },
        {
          icon: "stopwatch", title: "10 seconds.", subtitle: "To sign and save."
        },
        {
          icon: "table", title: "Versioned.", subtitle: "Issue a new waiver anytime."
        }
      ]
    },
    {
      colour: FeatureColour.GRAY,
      type: FeatureComponentType.LEFTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwaivers%2Fsend%20waiver-min.png?alt=media&token=b4ad10c7-a587-4ec5-82a0-fc833525a3c2",
      title: "Easy to Share",
      subtitle: "Seamless Experience",
      paragraph: "Contacts can sign on your device or theirs.",
      data: [
        {
          icon: "envelope", title: "Automically.", subtitle: "Sent on booking."
        },
        {
          icon: "envelope", title: "Resend.", subtitle: "Resend waivers for signing."
        },
        {
          icon: "wifi", title: "QR Code.", subtitle: "Contacts can scan your phone & sign."
        },
        {
          icon: "mobile", title: "Sign on your phone.", subtitle: "Quickly show contacts where to sign."
        },
      ]
    },
    {
      colour: FeatureColour.GRAY,
      type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fwaivers%2Fwaivers%20config-min.png?alt=media&token=a59d0440-e72e-40f8-bbee-6b3772f58974",
      title: "Simple Set-Up",
      subtitle: "Copy. Paste. Done.",
      paragraph: "Just add your waiver content and enable them.",
      data: [
        {
          icon: "code", title: "Rich Text.", subtitle: "Design your waiver easily."
        },
        {
          icon: "table", title: "New Year. New Waiver.", subtitle: "Just click force resign."
        },
        {
          icon: "table", title: "Audited.", subtitle: "At every step."
        }
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "Do waivers cost extra?",
          subtitle: "Nope. Waivers are included in the Wild business suite."
        },
        {
          title: "Can I update my waiver after it's been signed?",
          subtitle: "Yes, simply design your new waiver and issue it as a new version. As customers come back they will be asked to sign the latest copy. This is perfect if you update your terms and conditions regularly."
        },
        {
          title: "What if someone doesn't sign?",
          subtitle: "You can easily resend the waiver or pop it up on the Wild app or website for the contact to sign on the spot."
        },
        {
          title: "How can I tell if someone has signed a waiver?",
          subtitle: "As soon as you enable waivers, any contact that you open will have either a green banner to indicate they have signed or a red one to indicate they haven't"
        },
        {
          title: "What if they make a mistake. Can I void a waiver?",
          subtitle: "Of course, just open a contact and you can void their waiver and ask contacts them to sign again. "
        },
        {
          title: "How do I enable waivers?",
          subtitle: "You'll find waivers in your business settings. Simply choose waivers, fill in your waiver content and press enable."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "Check Out More Features",
      limit: 9,
      paragraph: "",
      data: [
        bookingExperienceListData,
        schedulingListDataShared,
        teamListDataShared,
        ordersFinanceListData,
        reportingListData,
        crmListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),
  ]

}