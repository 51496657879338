import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { OrderModel } from '../../../../../../data/models/order_model';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';
import { BusinessOrderDetailsComponent } from '../components/businessorderdetails.component';
import { OrderSearchResultsDTO } from '../../../../../../data/dtos/business/search/order/OrderSearchResultsDTO';
import { OrdersSearchService } from '../services/orderssearch/orderssearch_service';
import { UtilService } from 'src/shared/services/util/util_service';
import { DatesService } from 'src/shared/services/dates_service/dates_service';

@Component({
  template: `
   <app-wrapper header="Orders" paragraph="Manage your orders">
  <div *ngIf="orders$">
    <ng-container *ngIf="orders$ | async as orders; else loadingOrError">
      <div *ngIf="!search && (!orders || orders?.results?.length == 0); else ordersstable">
        <app-empty icon="shopping-bag" header="No Orders Yet"
          description="All your orders will appear here">
        </app-empty>
      </div>
      <ng-template #ordersstable>
        <div class="mb-6 max-w-2xl">
          <app-inlineform placeholder="Search by name, email, username or phone" (onSubmit)="submit($event)"
            [form]="form" inputname="search" ctaText="Search"></app-inlineform>
        </div>
        <app-empty *ngIf="orders?.results?.length == 0" icon="shopping-bag" header="No Results"
          description="Please refine your search">
        </app-empty>
        <app-table *ngIf="orders?.results?.length > 0" (pageChange)="pageChange($event)" [startingFrom]="orders.startingFrom" [currentPage]="orders.currentPage" [count]="orders.count"  [numberOfPages]="orders.numberOfPages" [size]="orders.size" [endingAt]="orders.endingAt"  [headers]="[
        {
          header:'Order Date'
        },
        {
          header:'Reference'
        }, 
        {
          header:'Order by'
        },
        {
          header:'Total'
        },
        {
          header:'edit'
        }]">
          <tr *ngFor="let orders of orders.results">
            <td app-table-cell [value]="getReadableDate(orders?.creationdate)"></td>
            <td app-table-cell [value]="orders?.orderreference"></td>
            <td app-table-cell [value]="orders?.firstName + ' ' + orders?.surname"></td>
            <td app-table-cell [value]="getFormattedPrice(orders?.ordergrandtotal,orders?.ordercurrency)"></td>
            <td app-table-cell [cta]="true" (onClick)="edit(orders)" value="edit"></td>
          </tr>
        </app-table>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </ng-template>
  </div>
</app-wrapper>
  `
})
export class BusinessOrdersComponent implements OnInit {

  constructor(
    private dateService: DatesService,
    private ordersearchService: OrdersSearchService,
    private _formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private util: UtilService,
    private router: Router,
    private panelService: PanelService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.load();
      }
    });
  }


  public error: ErrorModel = null;
  page: number;
  size: number;
  search: string;
  orders$: Observable<OrderSearchResultsDTO>;
  loading: boolean = false;
  form = this._formBuilder.group({
    search: new UntypedFormControl('', [])
  });

  ngOnInit(): void {
    this.load();
  }

  getFormattedPrice(total, currency) {
    return this.util.getFormattedPrice(total, currency);
  }

  submit(data: any) {
    this.navigation.navigate(WildNavigationType.BUSINESSORDERS, { q: data.search })
    this.load();
  }

  getReadableDate(date: string): string {
    return this.dateService.getReadableDateTimeFromISO(date)
  }

  load() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.orders$ = null;
      this.search = params.q?.toLowerCase() ?? "";
      this.page = params?.page ?? 1;
      this.size = params?.size ?? 10;
      this.form.controls["search"].setValue(this.search);
      this.orders$ = this.ordersearchService.search(this.search, this.page, this.size).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    })
  }

  pageChange(pageNumber: number) {
    this.navigation.navigate(WildNavigationType.BUSINESSORDERS, { q: this.search, page: pageNumber, size: this.size })
  }
  edit(order: OrderModel) {
    var settings: PanelModel = {
      header: "Order Details",
      component: BusinessOrderDetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: order.id
        }
      ]
    }
    this.panelService.show(settings);
  }

}
