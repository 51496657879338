import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { BusinessOrderDetailsComponent } from './components/businessorderdetails.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { BusinessOrdersComponent } from './pages/businessorders.component';


@NgModule({
    declarations: [
        //Pages
        BusinessOrdersComponent,
        //Components
        BusinessOrderDetailsComponent

    ],
    imports: [
        SharedModule,
        OrdersRoutingModule
    ]
})
export class OrdersModule { } 
