import { Injectable } from '@angular/core';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { AppPromptComponent } from './appcompt_component';

@Injectable({
    providedIn: 'root'
})
export class AppPromptService {

    constructor(private modal: ModalService) { }

    prompt() {
        var settings: ModalModel = {
            close: true,
            large: true,
            design: ModalDesign.COMPONENT,
            component: AppPromptComponent
        }
        this.modal.show(settings);
    }

}

