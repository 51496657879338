import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../../services/business_service/business_service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private businessService: BusinessService
  ) { }

  ngOnInit(): void {
  }

  canSeeCharts() {
    return this.businessService.isInRole("owner") || this.businessService.isInRole("admin") || this.businessService.isInRole("finance")
  }

  canSeeGoLiveList() {
    return this.businessService.isInRole("owner") || this.businessService.isInRole("admin")
  }

}
