import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SelectModel } from 'src/shared/components/select/select_model';
import { BusinessUserModel } from '../../../../../../data/models/business_user_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { TeamService } from '../../../services/team_service/team_service';
import { ActivityTeamService } from '../services/activityteam_service';

@Component({
  template: `
  <app-modaltopper [header]="'Add Team Member'"></app-modaltopper>
  <!-- <app-form [hidebuttons]="true" [form]="form" [fullwidthbutton]="false" disableDivide="true">
      <app-inputgroup>
        <app-input [disableAutoComplete]="true" [form]="form" type="search" name="search" description="Search" [span]=6 placeholder="Name, email, phone"></app-input>
      </app-inputgroup>
  </app-form>  -->
  <div *ngIf="team$">
    <ng-container *ngIf="team$ | async as team; else loadingOrError">
      <div *ngIf="!team || team.length == 0; else table">
        <app-empty icon="users" header="No Team Members"
          description="No team members found">
        </app-empty>
      </div>
      <ng-template #table>
        <div *ngIf="loading">
        <app-loader [disablescreenheight]="true"></app-loader>
      </div>
      <div *ngIf="!loading">
          <div class="w-full" style="max-height:350px;overflow-y:scroll">
            <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
              <app-listitem *ngFor="let teamMember of team" (onClick)="select(teamMember)"
                  [image]="teamMember?.image?.image"
                  [firstName]="teamMember?.firstName ?? 'Name not set'" [surname]="teamMember?.surname ?? ''"
                  [subline]="teamMember?.email"
                  >
              </app-listitem>
            </ul>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  </div>
  `
})
export class AppointmentAddTeamMemberComponent implements OnInit {

  @Input() id: string;
  @Output() updated = new EventEmitter();
  imagesUrl = environment.imagesUrl;

  constructor(
    private fb: UntypedFormBuilder,
    private activityTeamService: ActivityTeamService,
    private teamService: TeamService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  hourOptions: SelectModel[] = [];
  minuteOptions: SelectModel[] = [];
  startDate: string;
  endDate: string;
  startHour: number;
  startMinutes: number;
  team$: Observable<BusinessUserModel[]>;
  endHour: number;
  endMinutes: number;
  error: ErrorModel;

  ngOnInit(): void {
    this.load();
    // this.form = this.fb.group({
    //   search: new FormControl('', []),
    // });
    // setTimeout(() => {
    //   this.form.valueChanges.pipe(
    //     debounceTime(500)
    //   ).subscribe((result) => {
    //     this.search(this.form.value?.search);
    //   });
    // }, 1000);
  }

  select(teamMember: BusinessUserModel) {
    if (this.loading == true)
      return;
    this.loading = true;
    this.activityTeamService.add(this.id, teamMember.id)
      .then(() => {
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  load() {
    this.team$ = null;
    this.team$ = this.teamService.getActiveTeamMembers().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  handlerError(message: string) {
    this.notifyService.notify("Team Member", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Added Team Member", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  cancel() {
    this.modalService.hide();
  }

}
