import { Component, OnInit } from '@angular/core';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss']
})
export class UserDetailsComponent implements OnInit {

  error: ErrorModel;

  constructor(
    private navigation: NavigationService) { }

  ngOnInit(): void {
  }


}
