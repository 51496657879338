import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateActivityDescriptionDTO } from '../../../../../../data/dtos/business/activities/UpdateActivityDescriptionDTO';
import { Activity } from '../../../../../../data/models/activity_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UpdateActivityService } from '../services/updateactivity_service';

@Component({
  template: `
  <app-modaltopper header="Edit Description" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Submit" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="cancel()">
      <app-inputgroup>
        <app-textarea validation="Max 500 characters" [form]="form" type="text" name="description" description="Description" [span]=6 [value]="activity.description"></app-textarea> 
      </app-inputgroup>
  </app-form> 
  `
})
export class AppointmentEditDescriptionFormComponent implements OnInit {

  @Input() activity: Activity;
  @Input() newstart: Date;
  @Input() newend: Date;
  @Output() updated = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private updateActivityService: UpdateActivityService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      description: new UntypedFormControl('', [Validators.required, Validators.max(500)])
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    var dto: UpdateActivityDescriptionDTO = {
      description: data.description
    }
    this.updateActivityService.updateDescription(this.activity.id, dto)
      .then(() => {
        this.activity.description = data.description;
        this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Update", message, NotifyType.ERROR);
  }

  next() {
    this.notifyService.notify("Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.updated.emit();
  }

  cancel() {
    this.modalService.hide();
    //This ensures the data is refreshed so it doesn't keep the resized calendar item in the view
    if (this.newend != null)
      this.updated.emit();
  }

  resetForm() {
    this.loading = false;
  }

}
