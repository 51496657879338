<app-wild-header></app-wild-header>
<div class="max-w-7xl mx-auto min-h-screen flex flex-col md:flex-row md:px-9">
    <div class="hidden md:block md:w-1/4 lg:w-1/5">
        <div class="space-y-2 pt-3 pb-6">
            <!-- LOGGED IN MENU ITEMS START -->
            <a *ngFor="let menu of userMenuItems" routerLinkActive="bg-gray-100 text-gray-900" [routerLink]="menu.route"
                [routerLinkActiveOptions]="{ exact: true }"
                class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100 cursor-pointer">
                {{menu.description}}
            </a>
            <a (click)="addSwimSpot()"
                class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">Add
                Spot</a>

            <!-- <a (click)="toggleNotifications()"  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100 cursor-pointer">
                <div class="flex items-center justify-between">
                <span>Notifications</span><div *ngIf="numberOfUnreadMessages > 0" class="bg-red-600 rounded-full h-3 w-3 ml-4"></div>
                </div>
                </a> -->
            <!-- LOGGED IN MENU ITEMS END -->
        </div>
    </div>
    <div class="px-0 w-full md:w-3/4 lg:w-4/5 md:pl-4">
        <!-- Wild PRO Start    -->
        <div class="mx-auto px-4 sm:px-6 md:px-8" header="" paragraph="">
            <app-subscribe></app-subscribe>
        </div>
        <!-- Wild PRO End     -->
        <router-outlet></router-outlet>
        <br />
    </div>
</div>
<app-footer></app-footer>