import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserActivitiesDTO } from '../../../../data/dtos/booking/UserActivitiesDTO';
import { UserActivityDetailsDTO } from '../../../../data/dtos/booking/UserActivityDetailsDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(
    private http: HttpService,
  ) { }

  get(activityId: string): Observable<UserActivityDetailsDTO> {
    return this.http.get(`${environment.api}/user/booking/activities/${activityId}`);
  }
  getPublic(activityId: string, userId: string): Observable<UserActivityDetailsDTO> {
    return this.http.get(`${environment.api}/user/booking/activities/${activityId}/${userId}`, true);
  }

  list(when: string, linkedAccountId: string, startAfter: string): Observable<UserActivitiesDTO> {
    return this.http.get(`${environment.api}/user/booking/activities?when=${when}&linkedAccountId=${linkedAccountId}&startAfter=${startAfter}`);
  }

}

