import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessActivitiesService } from '../services/businessactivities_service';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { BusinessUserModel } from '../../../../../../data/models/business_user_model';
import { ActivityTeamService } from '../services/activityteam_service';


@Component({
  selector: 'app-appointmentteamlist',
  template: `
  <ng-container *ngIf="team$ | async as team; else loadingOrError">
    <div *ngIf="!team; else loaded"></div>
    <ng-template #loaded>
    <app-listitem *ngFor="let teamMember of team" [notclickable]="true"
        [image]="teamMember.image?.image"
        [firstName]="teamMember.firstName ?? 'Name not set'"
        [surname]="teamMember.surname ?? ''" [subline]="teamMember?.email"
        ctaonetext="Remove" ctaoneicon="trash" [object]="teamMember"
        (ctaoneclick)="removeTeamMember($event)" ctaoneclass="text-red-500">
    </app-listitem>
  </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <div class="p-6">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
    </ng-template>
</ng-template>
  `
})

export class AppointmentDetailsTeamListComponent implements OnInit {

  @Input() id: string;
  team$: Observable<BusinessUserModel[]>;
  error: ErrorModel;

  constructor(
    private modalService: ModalService,
    private activityTeamService: ActivityTeamService,
    private notifyService: NotifyService,
    private businessActivityService: BusinessActivitiesService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.team$ = null;
    this.team$ = this.businessActivityService.getActivityTeam(this.id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }


  removeTeamMember(teamMember: BusinessUserModel) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: `Are you sure you want to remove ${teamMember.firstName} ${teamMember.surname}?`,
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemoveTeamMember(teamMember);
      }
    }
    this.modalService.show(settings);
  }

  processRemoveTeamMember(teamMember: BusinessUserModel) {
    this.activityTeamService.del(this.id, teamMember.id)
      .then(() => {
        this.notifyService.notify("Removed Team Member", "Perfect!", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.notifyService.notify("Team Member Removal", error.message, NotifyType.ERROR);
      });
  }



} 
