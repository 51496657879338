<app-wrapper header="Go Live" paragraph="Adjust the status of your business.">
    <ng-container *ngIf="business$ | async as business; else loadingOrError">
        <div *ngIf="!business; else businessloaded">
            business not found
        </div>
        <ng-template #businessloaded>
            <p class="mb-6">Your business is currently
                <span class="text-green-600 font-semibold" *ngIf="business.status == BusinessStatus.LIVE">LIVE</span>
                <span class="font-semibold" *ngIf="business.status == BusinessStatus.DRAFT">IN DRAFT</span>
                <span class="" *ngIf="business.status == BusinessStatus.DRAFT">. When you go live and your business will
                    be
                    available for bookings.</span>
            </p>
            <div *ngIf="business.status == BusinessStatus.DRAFT">
                <button class="wild-btn-primary" (click)="goLive()">Go Live</button>
            </div>
            <div *ngIf="business.status == BusinessStatus.LIVE">
                <button class="wild-btn-primary" (click)="setToDraft()">Set to Draft</button>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    </ng-template>
</app-wrapper>