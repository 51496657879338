import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { TabModel } from '../tabs/tab_model';
import { SelectedSearchResult } from './selected_search_result';

@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss']
})
export class SearchresultsComponent implements OnInit {

  @Output() resultSelected = new EventEmitter<SelectedSearchResult>();

  tabs: TabModel[] = [
    // {
    //   id: "all",
    //   name: "All",
    //   icon: "swimmer",
    //   active: false
    // },
    {
      id: "swimspots",
      name: "Swim Spots",
      icon: "swimmer",
      active: true
    },
    // {
    //   id: "coach",
    //   name: "Coaches",
    //   icon: "swimmer",
    //   active: false
    // }
  ]

  activeTabId: string;
  emptyState;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.load();
      }
    });
  }

  ngOnInit(): void {
    this.updateActiveTabId('swimspots')
    this.load();
  }

  load() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      var query = params.query;
      if (query == null || query == undefined || query == "") {
        this.emptyState = true;
      } else {
        this.emptyState = false;
      }
    });
  }

  updateActiveTabId(id: string) {
    this.activeTabId = id;
  }

  selected($event) {
    this.resultSelected.emit($event);
  }

}
