import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BusinessUserStatus } from '../../../../../data/enums/business_user_status';
import { BusinessUserModel } from '../../../../../data/models/business_user_model';
import { InviteStatus } from '../../../../../data/models/invite_status';
import { FirestoreQueryType } from '../../../../../data/enums/firestore_query_type';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { BusinessService } from '../business_service/business_service';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { inviteTeamMemberDTO } from '../../../../../data/dtos/team/InviteTeamMemberDTO';
import { AcceptTeamInviteDTO } from '../../../../../data/dtos/team/AcceptTeamInviteDTO';
import { EditTeamMemberDTO } from '../../../../../data/dtos/team/EditTeamMemberDTO';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    constructor(
        private firestore: FirestoreService,
        private modalService: ModalService,
        private http: HttpService,
        private businessService: BusinessService,
        private notifyService: NotifyService,
        private authService: AuthenticationService
    ) { }

    getMyDetails(): Observable<BusinessUserModel> {
        var businessId = this.businessService.getActiveBusinessId();
        var userId = this.authService.getLoggedInCookie();
        return this.firestore.getSubCollectionDocumentById("business", businessId, "business_users", userId)
    }

    async needToAskForName(): Promise<boolean> {
        var businessId = this.businessService.getActiveBusinessId();
        var userId = this.authService.getLoggedInCookie();
        var user = await this.firestore.getSubCollectionDocumentById("business", businessId, "business_users_public", userId).toPromise();
        if (user == null || user?.firstName == null || user?.firstName == "" || user?.surname == null || user?.surname == "")
            return true;
        return false;
    }

    getActiveTeamMembers(): Observable<BusinessUserModel[]> {
        var businessId = this.businessService.getActiveBusinessId();
        const s1$ = this.firestore.getListFromSubcollectionByQuery("business", businessId, "business_users", [
            {
                property: "status",
                operator: "==",
                value: BusinessUserStatus.ACTIVE,
                type: FirestoreQueryType.WHERE
            }
        ]);
        return s1$;
    }

    getTeam(): Observable<BusinessUserModel[]> {
        return this.http.get(`${environment.api}/business/team`);
    }

    resendModal(user: BusinessUserModel) {
        var settings: ModalModel = {
            disableBodyScroll: true,
            design: ModalDesign.CENTRAL,
            type: ModalType.WARNING,
            description: `Are you sure you want to resend an invite to ${user.email}?`,
            header: "Are you sure?",
            close: true,
            ctaonetext: "Yes",
            ctatwotext: "No",
            ctaonetextclick: async () => {
                this.procecssResendModal(user);
            }
        }
        this.modalService.show(settings);
    }

    procecssResendModal(user: BusinessUserModel) {
        var settings: ModalModel = {
            header: "Sending",
            description: "Please wait",
            design: ModalDesign.LOADING
        }
        this.modalService.show(settings);
        this.resendInvite(user.id)
            .then(async () => {
                var settings: ModalModel = {
                    disableBodyScroll: true,
                    design: ModalDesign.CENTRAL,
                    type: ModalType.INFO,
                    description: `Invite has been sent to ${user.email}. Please ensure the invited user checks their junk mail if not received.`,
                    header: "Invite Sent",
                    close: true,
                    ctaonetext: "OK"
                }
                this.modalService.show(settings);
            })
            .catch((error) => {
                this.handlerError(error.message);
            })
            .finally(() => {
            })
    }

    handlerError(message: string) {
        this.notifyService.notify("Error", message, NotifyType.ERROR);
    }


    async resendInvite(id: string) {
        await this.http.post(`${environment.api}/business/team/inviteresend/${id}`, {}).toPromise();
    }

    async addTeamMember(dto: inviteTeamMemberDTO) {
        await this.http.post(`${environment.api}/business/team/invite`, dto).toPromise();
    }

    getInviteStatus(userId: string, businessId: string): Observable<InviteStatus> {
        return this.http.get(`${environment.api}/business/team/invite/status?businessId=${businessId}&userId=${userId}`);
    }

    async acceptInvite(userId: string, businessId: string) {
        var acceptDTO: AcceptTeamInviteDTO = {
            userIdOnInvite: userId,
            businessId: businessId
        }
        await this.http.post(`${environment.api}/business/team/invite/accept`, acceptDTO).toPromise();
    }

    async updateUser(userId: string, dto: EditTeamMemberDTO): Promise<void> {
        await this.http.post(`${environment.api}/business/team/update/${userId}`, dto).toPromise();
    }

    getStatusString(status: BusinessUserStatus) {
        var statusString = "Unknown";
        switch (status) {
            case BusinessUserStatus.ACTIVE:
                statusString = "Active";
                break;
            case BusinessUserStatus.INVITED:
                statusString = "Invited";
                break;
            case BusinessUserStatus.DEACTIVATED:
                statusString = "Deactivated";
                break;
            case BusinessUserStatus.UNKNOWN:
                statusString = "Unknown";
                break;
            default:
                break;
        }
        return statusString;
    }

    getStatusClasses(status: BusinessUserStatus) {
        var statusString = "";
        switch (status) {
            case BusinessUserStatus.ACTIVE:
                statusString = "text-green-800 bg-green-100";
                break;
            case BusinessUserStatus.INVITED:
                statusString = "text-yellow-800 bg-yellow-100";
                break;
            case BusinessUserStatus.DEACTIVATED:
                statusString = "text-red-800 bg-red-100";
                break;
            case BusinessUserStatus.UNKNOWN:
                statusString = "text-gray-800 bg-gray-100";
                break;
            default:
                break;
        }
        return statusString;
    }



}
