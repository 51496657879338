import { WildNavigationType } from "../../../../../../data/enums/navigation_type";
import { FeaturesData, FeatureColour, FeatureComponentType } from "../../FeaturesData";
import { businessCTAs } from "../../shared/ctas/business_ctas";
import { commonBusinesFAQs } from "../../shared/faqs/common_business_faqs";
import { injectSharedComponent } from "../../helpers/inject_shared_component";
import { realTimeSafetyListData } from "../realtimesafety/realtimesafety_data_listdata";
import { ordersFinanceListData } from "../finance/orders_finances_data_listdata";
import { reportingListData } from "../reporting/reporting_data_listdata";
import { schedulingListDataShared } from "../scheduling/scheduling_data_listdata";
import { teamListDataShared } from "../team/team_data_listdata";
import { organisedContacts } from "../../shared/features/organisedcontacts";
import { crmListData } from "./customer_management_listdata";
import { communicationsListData } from "../communications/communications_data_listdata";
import { usecases } from "../../shared/usecases";

export const crmData: FeaturesData = {
  seoTitle: "CRM | Intelligent Booking Platform for Watersports",
  seoDescription: "Advanced customer management system for water sports ",
  seoImage: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fteam%2Fteam.jpg?alt=media&token=0ebba2cf-622e-4a98-b50c-ee4d97ab33dc",
  seoKeyWords: "",
  page: WildNavigationType.BUSINESSCRM,
  components: [
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.SIMPLEHERO,
      ctas: businessCTAs,
      title: crmListData.title,
      subtitle: crmListData.subtitle,
      paragraph: "Manage Unlimited Contacts in our Simple CRM",
    },
    injectSharedComponent(organisedContacts, { colour:FeatureColour.WHITE  }),
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.LEFTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcrm%2Fcontact-profile.png?alt=media&token=35bc86bc-6acd-4103-b7f0-d4e643938d13",
      title: "Customer Profiles",
      subtitle: "Medical & Emergency Contact Info",
      paragraph: "Every booking made on Wild provides you with a detailed safety profile",
      data:[
        {
          icon: "mobile", title: "Contact.", subtitle: "Complete with contact information."
        },
        {
          icon: "first-aid", title: "Emergency.", subtitle: "Emergency contact information."
        },
        {
          icon: "first-aid", title: "Medical.", subtitle: "Important medical data."
        },

      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.LEFTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcrm%2Fcontacts-on-activities.png?alt=media&token=cc0450c0-15f3-49be-b9bf-2ac81c07d950",
      title: "Activities",
      subtitle: "Quick access to Customer Data",
      paragraph: "",
      data:[
        {
          icon: "mobile", title: "Contact.", subtitle: "Complete with contact information."
        },
        {
          icon: "first-aid", title: "Emergency.", subtitle: "Emergency contact information."
        },
        {
          icon: "first-aid", title: "Medical.", subtitle: "Important medical information."
        },
        {
          icon: "plus", title: "Book.", subtitle: "Add & remove contacts on Bookings  ."
        },
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.LEFTIMAGEINFOSECTION,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcrm%2Fnotes-on-contact.png?alt=media&token=0a6e9086-07d1-4c69-9dc5-2bd47388cb67",
      title: "Notes",
      subtitle: "Audited. Secure. Private.",
      paragraph: "",
      data:[
        {
          icon: "users", title: "Customers.", subtitle: "Record notes on customers."
        },
        {
          icon: "calendar", title: "Bookings.", subtitle: "Record notes on bookings."
        },
        {
          icon: "shopping-bag", title: "Orders.", subtitle: "Record notes on orders."
        },
      ]
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.BIGIMAGEFEATURE,
      image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcrm%2Fjoin.png?alt=media&token=530d2842-7195-4d60-9ed8-deda606c195c",
      title: "Walk-Ins",
      subtitle: "Customers Can Join You.",
      paragraph: "We know sometimes people just turn up. Customers can join your business without booking, keeping you paper free!",
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.FAQS,
      title: "",
      subtitle: "Frequently Asked Questions",
      data: [
        {
          title: "How many contacts can I have?",
          subtitle: "Simple, unlimited!"
        },
        {
          title: "How does auditing work on notes?",
          subtitle: "All notes are timestamped with creation and editing timestamps and by who! Editing is also locked down by roles."
        },
        {
          title: "How do I update customer information?",
          subtitle: "The beauty of the Wild platform is customer have their own account. They keep information up to date which saves you the admin. If you want to add additional information, that's what notes are for."
        },
        ...commonBusinesFAQs
      ],
      ctas: businessCTAs,
    },
    {
      colour: FeatureColour.WHITE,
      type: FeatureComponentType.TEXTANDINFO,
      title: "Explore More",
      subtitle: "Customers... ✅ But There's More...",
      limit: 9,
      paragraph: "",
      data: [
        schedulingListDataShared,
        teamListDataShared,
        ordersFinanceListData,
        communicationsListData,
        reportingListData,
        realTimeSafetyListData
      ],
      ctas: {
        secondary: {
          text: "See All",
          page: WildNavigationType.ALLFEATURES
        }
      }
    },
    injectSharedComponent(usecases, { colour: FeatureColour.WHITE }),      
  ]
}


