import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// Available Share:
// Facebook
// Twitter
// LinkedIn
// Pinterest
// WhatsApp
// Messenger
// Viber
// Reddit
// Tumblr
// Telegram
// Email
// SMS
// VK
// Mix
// Xing
// Line
// Copy link
// Print
@Component({
    selector: 'app-wildsharelinks',
    template: `
  <div class="flex flex-wrap">
        <button title="Facebook" shareButton="facebook" [url]="url" [title]="title ?? ''" [description]="description ?? ''" [image]="image">
            <fa-icon class="text-blue-800 text-3xl" [icon]="['fab', 'facebook']"></fa-icon>
        </button>
        <button title="Twitter" shareButton="twitter" [url]="url" [title]="title ?? ''" [description]="description ?? ''"  [image]="image">
            <fa-icon class="text-blue-300 text-3xl ml-4" [icon]="['fab', 'twitter']"></fa-icon>
        </button>
        <button title="Whatsapp" shareButton="whatsapp" [url]="url" [title]="title ?? ''" [description]="description ?? ''" [image]="image">
            <fa-icon class="text-green-500 text-3xl ml-4" [icon]="['fab', 'whatsapp']"></fa-icon>
        </button>
        <button title="Instagram" shareButton="pinterest" [url]="url" [title]="title ?? ''" [description]="description ?? ''" [image]="image">
            <fa-icon class="text-red-600 text-3xl ml-4" [icon]="['fab', 'pinterest']"></fa-icon>
        </button>
        <button title="Linked In" shareButton="linkedin" [url]="url" [title]="title ?? ''" [description]="description ?? ''" [image]="image">
            <fa-icon class="text-blue-600 text-3xl ml-4" [icon]="['fab', 'linkedin']"></fa-icon>
        </button>
        <button title="Telegram" shareButton="telegram" [url]="url" [title]="title ?? ''" [description]="description ?? ''" [image]="image">
            <fa-icon class="text-blue-600 text-3xl ml-4" [icon]="['fab', 'telegram']"></fa-icon>
        </button>
        <a [href]="'mailto:?subject='+ (title ?? '') + '&body=' + (description ?? '')"> 
            <fa-icon class="text-gray-500 text-3xl ml-4" [icon]="'envelope'"></fa-icon>
        </a>
    </div>
  `
})
export class WildShareLinksComponent implements OnInit {

    @Input() url: string;
    @Input() title: string;
    @Input() description: string;
    @Input() image: string;


    constructor() { }

    ngOnInit(): void {
    }

    encodedDescription(){
        return encodeURI(this.description)
    }

}
