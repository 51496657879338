import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ContactsService } from 'src/wild/modules/business/modules/contacts/services/contacts_service/contacts_service';

@Component({
  template: `
  <app-modaltopper header="Update Birthday" parapraph=""></app-modaltopper>
  <app-form [form]="form" [fullwidthbutton]="false" ctaText="Update" disableDivide="true" (onSubmit)="onSubmit($event)"
      [loading]="loading" cancelText="Cancel" (onCancel)="hide()">
      <app-inputgroup>
       <app-formdatepicker [span]=6 [form]="form" name="dob" description="Birthday"></app-formdatepicker>
      </app-inputgroup>
  </app-form> 
  `
})
export class ContactDOBFormComponent implements OnInit {

  @Input() contact: BusinessContactModel;

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      dob: new UntypedFormControl(this.contact?.dob ?? '', [Validators.required]),
    });
  }

  onSubmit(data: any) {
    this.loading = true;
    this.contactService.updateBirthday(this.contact.id, data.dob)
      .then(() => {
        this.next(data.gender);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Birthday", message, NotifyType.ERROR);
  }

  next(dob: string) {
    this.notifyService.notify("Birthday Updated", "Perfect!", NotifyType.SUCCESS);
    this.modalService.hide();
    this.contact.dob = dob;
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }

}
