import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { LocationResultType } from '../../../../../../../data/enums/location_result_type';
import { WaterwayType } from '../../../../../../../data/enums/waterway_type';
import { SearchResult } from '../../../../../../../data/models/SearchResult';
import { SearchResults } from '../../../../../../../data/models/SearchResults';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SearchService } from 'src/shared/services/search/search_service';
import { StorageService } from 'src/shared/services/storage/storage.service';

@Component({
  selector: 'app-swimspotarea',
  templateUrl: './swimspotarea.component.html',
  styleUrls: ['./swimspotarea.component.scss']
})
export class SwimspotAreaComponent implements OnInit {

  @Output() done = new EventEmitter<any>();
  form: UntypedFormGroup;
  latlngForm: UntypedFormGroup;
  searchResults: SearchResults;
  searchLoading: boolean;
  showMinCharactersNotReached: boolean;
  showSearch: boolean = false;
  minSearchCharacters: number = 3;
  charactersTyped: number = 0;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;
  useLatLng: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageService,
    private notify: NotifyService,
    private searchService: SearchService) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    this.latlngForm = this.fb.group({
      latitude: new UntypedFormControl('', [Validators.required,]),
      longitude: new UntypedFormControl('', [Validators.required]),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 1000);
  }

  onSubmitLatLng(data: any) {
    var latlng = data.latitude + "," + data.longitude;
    const regexExp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/gi;
    if (latlng.match(regexExp) != null) {
      this.storage.setCookie("swimspotlat", data.latitude, 1);
      this.storage.setCookie("swimspotlng", data.longitude, 1);
      this.done.emit();
    } else {
      this.notify.notify("Invalid Coordinates", "Please check your latitude and longitude", NotifyType.ERROR);
    }
  }

  backToSearchForm() {
    this.useLatLng = false;
  }

  enterLatLng() {
    this.useLatLng = true;
  }

  async search(value) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      if (value.search.length >= this.minSearchCharacters) {
        this.showMinCharactersNotReached = false;
        this.searchLoading = true;
        await this.searchService.swimspotSearch(value.search).pipe(take(1)).toPromise()
          .then((searchResults) => {
            this.searchResults = searchResults;
          })
          .catch((error) => {
            this.handlerError(error);
          })
          .finally(() => {
            this.searchFinished();
          })
      } else {
        this.charactersTyped = value.search.length;
        this.searchLoading = false;
        this.showMinCharactersNotReached = true;
      }
    }
  }

  searchFinished() {
    this.searchLoading = false;
  }

  handlerError(message: string) {
    this.notify.notify("Search Error", message, NotifyType.ERROR);
  }

  clearSearch() {
    this.form.controls["search"].setValue("");
    this.searchResults = null;
  }

  async selectSearchResult(result: SearchResult) {
    if (result.type == LocationResultType.PLACE) {
      this.storage.setCookie("swimspotlat", result.latitude, 1);
      this.storage.setCookie("swimspotlng", result.longitude, 1);
    }
    if (result.type == LocationResultType.WHAT3WORDS) {
      var coordinates = await this.searchService.getCoordinatesForW3w(result.name).toPromise();
      this.storage.setCookie("swimspotlat", coordinates.latitude, 1);
      this.storage.setCookie("swimspotlng", coordinates.longitude, 1);
    }
    this.clearSearch();
    this.done.emit();
  }

} 
