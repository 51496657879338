<nav class="md:hidden flex items-center justify-center mt-6 mb-9" aria-label="Progress">
    <p class="text-sm font-medium">Step {{getCurrentStepNumner()}} of {{steps.length}}</p>
    <ol role="list" class="ml-8 flex items-center space-x-5">
        <li *ngFor="let step of steps; let i = index;">
            <!-- Completed Step -->
            <a *ngIf="step.type == StepType.COMPLETE" class="block w-2.5 h-2.5 wild-bg-primary rounded-full ">
            </a>
            <!-- Current Step -->
            <a *ngIf="step.type == StepType.ACTIVE" class="relative flex items-center justify-center"
                aria-current="step">
                <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                    <span class="w-full h-full rounded-full bg-gray-200"></span>
                </span>
                <span class="relative block w-2.5 h-2.5 wild-bg-primary rounded-full" aria-hidden="true"></span>
                <span class="sr-only">Step 2</span>
            </a>
            <!-- Upcoming Step -->
            <a *ngIf="step.type == StepType.TODO" class="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                <span class="sr-only">Step 3</span>
            </a>
        </li>
    </ol>
</nav>
<nav class="hidden md:block mt-4 mb-8 bg-white" *ngIf="steps && steps.length > 0" aria-label="Progress">
    <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        <li *ngFor="let step of steps; let i = index;" class="relative md:flex-1 md:flex">
            <span class="group flex items-center w-full">
                <span class="px-6 py-4 flex items-center text-sm font-medium">
                    <!-- ADD THIS FOR LINKS  group-hover:bg-blue-800 -->
                    <span *ngIf="step.type == StepType.COMPLETE"
                        class="flex-shrink-0 w-10 h-10 flex items-center justify-center wild-bg-primary rounded-full ">
                        <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                    <span *ngIf="step.type == StepType.ACTIVE"
                        class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full wild-bg-primary">
                        <span class="text-white">0{{i + 1}}</span>
                    </span>
                    <!-- ADD THIS FOR LINKS group-hover:border-gray-400 -->
                    <span *ngIf="step.type == StepType.TODO"
                        class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full ">
                        <!-- ADD THIS FOR LINKS  group-hover:text-gray-900 -->
                        <span class="text-gray-500 ">0{{i + 1}}</span>
                    </span>

                    <span class="ml-4 wild-header-2">{{step.header}}</span>
                </span>
            </span>
            <!-- Arrow separator for lg screens and up -->
            <div *ngIf="(i + 1) < steps.length" class="hidden md:block absolute top-0 right-0 h-full w-5"
                aria-hidden="true">
                <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                        stroke-linejoin="round" />
                </svg>
            </div>
        </li>
    </ol>
</nav>