import { Component, OnInit } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, map, share, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AbandonCartDTO } from '../../../data/dtos/booking/AbandonCartDTO';
import { RemoveOrderLineDTO } from '../../../data/dtos/booking/RemoveOrderLineDTO';
import { BusinessPublicModel } from '../../../data/models/business_public_model';
import { OrderModel } from '../../../data/models/order_model';
import { ErrorModel } from '../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BookingService } from '../../services/booking_service';
import { UserShoppingCartDTO } from '../../../data/dtos/booking/UserShoppingCartDTO';
import { UserOrderLineDetailsDTO } from '../../../data/dtos/booking/UserOrderLineDetailsDTO';
import { UserOrderDetailsDTO } from '../../../data/dtos/booking/UserOrderDetailsDTO';
import { DatesService } from 'src/shared/services/dates_service/dates_service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
})
export class BasketComponent implements OnInit {

  business: BusinessPublicModel;
  cart$: Observable<UserShoppingCartDTO>;
  imagesUrl = environment.imagesUrl;
  error: ErrorModel;

  constructor(
    private bookingService: BookingService,
    private modalService: ModalService,
    private dateService: DatesService,
    private notifyService: NotifyService,
    private navigate: NavigationService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  startTimers(orders: UserShoppingCartDTO) {
    this.bookingService.startTimers(orders);
  }

  removeOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to remove this order line?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemoveOrderLine(order, orderLine);
      }
    }
    this.modalService.show(settings);
  }


  async processRemoveOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      header: "Removing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var removeOrderLineDTO: RemoveOrderLineDTO = {
      businessId: order.businessId,
      orderLineId: orderLine.id
    }
    this.bookingService.removeOrderLine(removeOrderLineDTO)
      .then(async () => {
        this.modalService.hide();
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      });
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
    this.modalService.hide();
  }

  goToCheckout(order: OrderModel) {
    this.navigate.navigate(WildNavigationType.BOOKINGUSERDETAILS, {
      businessId: order.businessId
    })
  }

  clearBasket(order: UserOrderDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to clear the basket?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processClearBasket(order);
      }
    }
    this.modalService.show(settings);
  }

  async processClearBasket(order: UserOrderDetailsDTO) {
    var settings: ModalModel = {
      header: "Clearing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var abandonCartDTO: AbandonCartDTO = {
      businessId: order.businessId
    }
    this.bookingService.abandonCart(abandonCartDTO)
      .then(async () => {
        this.modalService.hide();
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      });
  }

  load() {
    this.cart$ = null;
    this.cart$ = this.bookingService.getBasketOrders().pipe(
      take(1),
      tap(orders => this.startTimers(orders)),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  search() {
    this.navigate.navigate(WildNavigationType.SEARCH);
  }

}
