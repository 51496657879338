import { NgModule } from '@angular/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from 'src/shared/shared.module';
import { AppointmentsRoutingModule } from './appointments-routing.module';
import { AppointmentAddContactComponent } from './components/appointmentaddcontact.component';
import { AppointmentAddTeamMemberComponent } from './components/appointmentaddteammember.component';
import { AppointmentContactExportComponent } from './components/appointmentcontactexport.component';
import { AppointmentDetailsComponent } from './components/appointmentdetails.component';
import { AppointmentEditCapacityFormComponent } from './components/appointmenteditcapacityform.component';
import { AppointmentEditDescriptionFormComponent } from './components/appointmenteditdescriptionform.component';
import { AppointmentEditFormComponent } from './components/appointmenteditform.component';
import { AppointmentEditNameFormComponent } from './components/appointmenteditnameform.component';
import { CalendarTeamMemberSelection } from './components/calanedarteammemberselection.component';
import { NewAppointmentFormComponent } from './components/newappointmentform.component';
import { AppointmentsComponent } from './pages/appointments.component';
import { AppointmentDetailsContactListComponent } from './components/appointmentdetails.contactlist.component';
import { AppointmentDetailsTeamListComponent } from './components/appointmentdetails.teamlist.component';



@NgModule({
    declarations: [
        //Pages
        AppointmentsComponent,
        //Components
        AppointmentDetailsComponent,
        AppointmentDetailsContactListComponent,
        AppointmentEditFormComponent,
        AppointmentAddContactComponent,
        AppointmentAddTeamMemberComponent,
        AppointmentDetailsTeamListComponent,
        AppointmentContactExportComponent,
        NewAppointmentFormComponent,
        AppointmentEditNameFormComponent,
        AppointmentEditDescriptionFormComponent,
        CalendarTeamMemberSelection,
        AppointmentEditCapacityFormComponent,
    ],
    imports: [
        SharedModule,
        AppointmentsRoutingModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ]
})
export class AppointmentsModule { } 
