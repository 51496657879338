export enum TimeZone {
    //https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations
    //https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    UNKNOWN,
    //UK
    GMT,//UK - Europe/London
    UTC,//UNIVERSAL
    //Ireland
    IST,//Irish Standard Time - Europe/Dublin
    //Australia
    AEST,//Australian Eastern Standard Time - Australia/Brisbane
    ACST,//Australian Central Standard Time - Australia/Darwin
    AWST,//Australian Western Standard Time - Australia/Perth
    //Austria
    ATCET,//Central European Time - Austria - Europe/Vienna
    BECET,//Central European Time - Belgium - Europe/Brussels
    //Brazil
    BRFNT,//Fernando de Noronha Time (FNT): UTC-02:00 - America/Noronha
    BRBRT,//Brasília Time (BRT): UTC-03:00 - America/Sao_Paulo
    BRAMT,//Amazon Time (AMT): UTC-04:00 - America/Manaus
    BRACT,//Acre Time (ACT): UTC-05:00 - America/Rio_Branco
    //Bulgaria
    BGEET,// Eastern European Time (EET) - Bulgaria - Europe/Sofia
    //Canada
    CAPT,//Pacific Time Zone (PT) - IANA code: America/Vancouver
    CAMT,//Mountain Time Zone (MT) - IANA code: America/Edmonton
    CACT,//Central Time Zone(CT) - IANA code: America/Winnipeg
    CAET,//Eastern Time Zone(ET) - IANA code: America/Toronto
    CAAT,//Atlantic Time Zone(AT) - IANA code: America/Halifax
    CANT,//Newfoundland Time Zone(NT) - IANA code: America/St_Johns
    //Croatia
    HRCET,//Central European Time (CET) - IANA code: Europe/Zagreb
    //Cyprus
    CYEET,// Eastern European Time (EET) - Europe/Nicosia
    CZCET,// Central European Time (CET)  - Europe/Prague
    DKCET,// Central European Time (CET)  - Europe/Copenhagen
    EEEET,// Eastern European Time (EET) - Europe/Tallinn
    FICET,// Central European Time (CET)  - Europe/Helsinki
    FRCET,// Central European Time (CET)  - Europe/Paris
    DECET,// Central European Time (CET)  - Europe/Berlin
    GREET,// Eastern European Time (EET) - Europe/Athens
    HKHKT,// Hong Kong Time (HKT) - IANA code: Asia/Hong_Kong
    HUCET,// Central European Time (CET)  - Europe/Budapest
    INIST,// Indian Standard Time (IST) - IANA code: Asia/Kolkata
    IDWIB,// Western Indonesia Time Zone (WIB) - IANA code: Asia/Jakarta
    IDWITA,// Central Indonesia Time Zone (WITA) - IANA code: Asia/Makassar
    IDWIT,// Eastern Indonesia Time Zone (WIT) - IANA code: Asia/Jayapura
    ITCET,// Central European Time (CET)  - Europe/Rome
    JPJST,// Japan Standard Time (JST) - IANA code: Asia/Tokyo
    LVEET,// Eastern European Time (EET) - Europe/Riga
    LICET,// Central European Time (CET)  - Europe/Vaduz - Liechtenstein
    LTEET,// Eastern European Time (EET) - IANA code: Europe/Vilnius
    LUCET,// Central European Time (CET)  - Europe/Luxembourg
    MYMST,// The IANA code for Malaysian Standard Time (MST) is Asia/Kuala_Lumpur.
    MTCET,// Central European Time (CET)  - Europe/Malta
    MXPT,//  Mexico Pacific Time Zone - IANA code: America/Tijuana (PT)
    MXMT,// Mexico Mountain Time Zone - IANA code: America/Hermosillo (MT)
    MXCT,// Mexico Central Time Zone - IANA code: America/Mexico_City (CT)
    MXET,// Mexico Southeastern Time Zone - IANA code: America/Cancun (ET)
    NLCET,// Central European Time (CET)  - Europe/Amsterdam
    NZNZST,//  New Zealand Standard Time (NZST) - IANA code: Pacific/Auckland
    NZCHAST,//  Chatham Standard Time (CHAST) - IANA code: Pacific/Chatham
    NOCET,// Central European Time (CET)  - Europe/Oslo
    PLCET,// Central European Time (CET)  - Europe/Warsaw
    PTCET,// Central European Time (CET)  - Europe/Lisbon
    ROEET,// Eastern European Time (CET)  - Europe/Bucharest
    SGSGT,//Singapore uses only one timezone, which is Singapore Time (SGT). The IANA code for Singapore Time is "Asia/Singapore".
    SKCET,// Central European Time (CET)  - Europe/Bratislava - Slovakia
    SICET,// Central European Time (CET) - IANA code: Europe/Ljubljana, Abbreviation: CET
    ESCET,// Central European Time (CET) - IANA code: Europe/Madrid, Abbreviation: CET
    SECET,// Central European Time (CET) - IANA code: Europe/Stockholm, Abbreviation: CET
    CHCET,// Central European Time (CET) - IANA code: Europe/Zurich, Abbreviation: CET
    THICT,// Indochina Time (ICT) - IANA code: Asia/Bangkok
    AEGST,// Gulf Standard Time (GST) - IANA code: Asia/Dubai
    USEST,// Eastern Time Zone (ET) - IANA code: America/New_York, abbreviation: EST/EDT
    USCST,// Central Time Zone (CT) - IANA code: America/Chicago, abbreviation: CST/CDT
    USMST,// Central Time Zone (CT) - IANA code: America/Denver, abbreviation: MST/CDT
    USPST,// Pacific Time Zone (PT) - IANA code: America/Los_Angeles, abbreviation: PST/PDT
    USAKST,// Alaska Time Zone (AKT) - IANA code: America/Anchorage, abbreviation: AKST/AKDT
    USHST,// Hawaii-Aleutian Time Zone (HAT) - IANA code: Pacific/Honolulu, abbreviation: HST
}
