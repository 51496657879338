import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { GroupDetailsDTO } from '../../../../../data/dtos/group/GroupDetailsDTO';
import { groupsService } from '../../services/groups_service';
import { throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { ErrorModel } from '../../../../../data/models/error_model';

@Component({
  templateUrl: './groupinvite.component.html',
  styleUrls: ['./groupinvite.component.scss']
})
export class GroupInviteComponent implements OnInit {

  group$: Observable<GroupDetailsDTO>;
  error: ErrorModel;

  constructor(private groupsService: groupsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');
    this.load(id);
  }

  load(id: string) {
    this.group$ = this.groupsService.get(id).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

}
