import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from 'src/shared/resolvers/user.resolver';
import { NotAuthenticatedGuard } from '../auth/guards/not_authenticated.guard';
import { HasActiveBusinessGuard } from './guards/has_active_business_guard';
import { BrandComponent } from './pages/business_children/brand/brand.component';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessesComponent } from './pages/businesses/businesses.component';
import { DashboardComponent } from './pages/business_children/dashboard/dashboard.component';
import { TeamComponent } from './pages/business_children/team/team.component';
import { InviteComponent } from './pages/invite/invite.component';
import { SettingsComponent } from './pages/business_children/settings/settings.component';
import { BusinessswimspotsComponent } from './pages/business_children/businessswimspots/businessswimspots.component';
import { BusinesssearchresultsComponent } from './pages/business_children/businesssearchresults/businesssearchresults.component';
import { SwimspotdetailsComponent } from '../swimspots/components/swimspotdetails/swimspotdetails.component';
import { AreaComponent } from './pages/business_children/area/area.component';
import { LocationComponent } from './pages/business_children/location/location.component';
import { PayoutsComponent } from './pages/business_children/payouts/payouts.component';
import { ConfigurationComponent } from './pages/business_children/configuration/configuration.component';
import { StatusComponent } from './pages/business_children/status/status.component';
import { ContactsComponent } from './modules/contacts/pages/contacts.component';
import { AppointmentsComponent } from './modules/appointments/pages/appointments.component';
import { BusinessOrdersComponent } from './modules/orders/pages/businessorders.component';
import { CustomTokenResolver } from 'src/shared/resolvers/customtoken.resolver';
import { OnboardingV2omponent } from './pages/onboardingv2/onboardingv2.component';
import { WelcomeEmailComponent } from './pages/business_children/welcomeemail/welcomeemail.component';
import { BusinessOverrideComponent } from './pages/businessoverride.component';
import { OnlineBookingComponent } from './pages/business_children/onlinebookings/onlinebookings.component';
import { WaiversComponent } from './pages/business_children/waivers/waivers.component';
import { SupportComponent } from './pages/business_children/support/support.component';

const routes: Routes = [
  {
    path: "invite",
    component: InviteComponent,
    resolve: {
      user: UserResolver
    },
  },
  {
    path: "change",
    canActivate: [NotAuthenticatedGuard],
    component: BusinessesComponent,
    resolve: {
      user: UserResolver
    },
  },
  {
    path: "onboardingv2",
    canActivate: [NotAuthenticatedGuard],
    component: OnboardingV2omponent,
    resolve: {
      user: UserResolver
    },
  },
  {
    path: "businessoverride",
    canActivate: [NotAuthenticatedGuard],
    component: BusinessOverrideComponent,
    resolve: {
      user: UserResolver
    },
  },
  {
    path: "business",
    canActivate: [NotAuthenticatedGuard, HasActiveBusinessGuard],
    component: BusinessComponent,
    resolve: {
      customtoken: CustomTokenResolver,
    },
    children: [
      {
        path: "",
        component: DashboardComponent,
      },
      {
        path: "usersettings",
        loadChildren: () => import('./modules/usersettings/usersettings.module').then(m => m.UserSettingsModule)
      },
      {
        path: "categories",
        loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: "timetables",
        loadChildren: () => import('./modules/timetables/timetables.module').then(m => m.TimeTablesModule)
      },
      {
        path: "settings/services",
        loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule)
      },
      {
        path: "settings/discountcodes",
        loadChildren: () => import('./modules/discountcodes/discountcodes.module').then(m => m.DiscountCodesModule)
      },
      {
        path: "watersafety",
        loadChildren: () => import('./modules/watersafety/watersafety.module').then(m => m.WaterSafetyModule)
      },
      {
        path: "settings/classpasses",
        loadChildren: () => import('./modules/classpasses/classpasses.module').then(m => m.ClassPassModule)
      },
      {
        path: "settings/memberships",
        loadChildren: () => import('./modules/memberships/memberships.module').then(m => m.MembershipsModule)
      },
      {
        path: "integrations",
        loadChildren: () => import('./modules/integrations/integrations.module').then(m => m.IntegrationsModule)
      },
      {
        path: "settings/mode",
        loadChildren: () => import('./modules/mode/mode.module').then(m => m.ModeModule)
      },
      {
        path: "activities",
        component: AppointmentsComponent
      },
      {
        path: "orders",
        component: BusinessOrdersComponent
      },
      {
        path: "contacts",
        component: ContactsComponent,
      },
      {
        path: "search",
        component: BusinesssearchresultsComponent,
      },
      {
        path: "finance",
        component: PayoutsComponent,
      },
      {
        path: "swimspots/:id",
        component: SwimspotdetailsComponent,
      },
      {
        path: "settings",
        component: SettingsComponent,
      },
      {
        path: "support",
        component: SupportComponent,
      },
      {
        path: "settings/swimspots",
        component: BusinessswimspotsComponent,
      },
      {
        path: "settings/configuration",
        component: ConfigurationComponent,
      },
      {
        path: "settings/waivers",
        component: WaiversComponent,
      },
      {
        path: "settings/onlinebookings",
        component: OnlineBookingComponent,
      },
      {
        path: "settings/welcomeemail",
        component: WelcomeEmailComponent,
      },
      {
        path: "settings/location",
        component: LocationComponent,
      },
      {
        path: "settings/area",
        component: AreaComponent,
      },
      {
        path: "settings/brand",
        component: BrandComponent,
      },
      {
        path: "settings/status",
        component: StatusComponent,
      },
      {
        path: "settings/team",
        component: TeamComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BusinessRoutingModule { }
