import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services/auth/auth_service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {
  constructor(private auth: AuthenticationService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.auth.checkUser().pipe(first())
  }
}