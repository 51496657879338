import { Component, Input, OnInit } from '@angular/core';
import { Gender } from '../../../../../../../data/enums/gender_type';
import { RelationshipType } from '../../../../../../../data/enums/relationship_type';
import { BusinessContactModel } from '../../../../../../../data/models/business_contact_model';
import { PersonalModel } from '../../../../../../../data/models/personal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';
import { ContactsService } from '../../services/contacts_service/contacts_service';
import { ContactdetailsComponent } from './contactdetails.component';
import { ContactDOBFormComponent } from './contactdobform.component';
import { ContactEmailFormComponent } from './contactemailform.component';
import { ContactEmergencyFormComponent } from './contactemergencyform.component';
import { ContactGenderFormComponent } from './contactgenderform.component';
import { ContactMedicalInfoFormComponent } from './contactmedicalinfoform.component';
import { ContactNameFormComponent } from './contactnameform.component';
import { ContactPhoneNumberFormComponent } from './contactphonenumberform.component';

@Component({
  selector: 'app-contactprofile',
  template: `
  <app-panelrow description="Name" [hidebutton]="contact.wilduser" (onClick)="editName()">
      {{contact?.personal?.firstName}} {{contact?.personal?.surname}} <span
          *ngIf="contact.personal?.pronouns">({{contact.personal?.pronouns}})</span>
  </app-panelrow>
  <app-panelrow *ngIf="contact?.relationship && contact?.relationship != RelationshipType.UNKNOWN" description="Relationship" [hidebutton]="true">
    <span [ngSwitch]="contact?.relationship">
      <span *ngSwitchCase="RelationshipType.CHILD">Child</span>
      <span *ngSwitchCase="RelationshipType.FRIEND">Friend</span>
      <span *ngSwitchCase="RelationshipType.SIBLING">Sibling</span>
      <span *ngSwitchCase="RelationshipType.PARENT">Parent</span>
      <span *ngSwitchCase="RelationshipType.PARTNER">Partner</span>
    </span>
    <span> to <a class="text-blue-500 underline hover:text-blue-600 cursor-pointer" (click)="openParentAccount(contact?.parentUserId)">link</a></span>
  </app-panelrow>
  <app-panelrow description="Email" [hidebutton]="contact.wilduser" (onClick)="editEmail()">
      <a *ngIf="contact?.email" class="wild-text-primary underline"
          [href]="'mailto:' + contact?.email">{{contact?.email}}</a>
      <span *ngIf="!contact?.email">Unknown</span>
  </app-panelrow>
  <app-panelrow description="Phone" [hidebutton]="contact.wilduser" (onClick)="editPhoneNumber()">
      <span *ngIf="contact?.phone">({{contact?.phone?.code}}) <a class="wild-text-primary underline"
              [href]="'tel:' + contact?.phone?.number">{{contact?.phone?.number}}</a></span>
      <span *ngIf="!contact?.phone">Unknown</span>
  </app-panelrow>
  <app-panelrow description="Gender" [hidebutton]="contact.wilduser" (onClick)="editGender()">
      <span *ngIf="contact?.gender == Gender.FEMALE">Female</span>
      <span *ngIf="contact?.gender == Gender.MALE">Male</span>
      <span *ngIf="contact?.gender == Gender.RATHERNOTSAY">Rather not say</span>
      <span *ngIf="!contact?.gender">Unknown</span>
  </app-panelrow>
  <app-panelrow description="Birthday" [hidebutton]="contact.wilduser" (onClick)="editDOB()">
      <span *ngIf="contact?.dob">{{contact?.dob | date}}</span>
      <span *ngIf="!contact?.dob">Unknown</span>
  </app-panelrow>
  <app-panelrow description="Emergency Contact" [hidebutton]="contact.wilduser"
      (onClick)="editEmergencyInfo()">
      <div *ngIf="contact?.emergency">
          <p>{{contact?.emergency?.nextOfKin?.firstName}} {{contact?.emergency?.nextOfKin?.surname}}
          </p>
          <p><a class="wild-text-primary underline"
          [href]="'mailto:' + contact?.emergency?.nextOfKin?.email">{{contact?.emergency?.nextOfKin?.email}}</a></p>
          <p>({{contact?.emergency?.nextOfKinPhone?.code}}) <a class="wild-text-primary underline"
              [href]="'tel:' + contact?.emergency?.nextOfKinPhone?.number">{{contact?.emergency?.nextOfKinPhone?.number}}</a>

          </p>
      </div> 
      <span *ngIf="!contact?.emergency">Unknown</span>
  </app-panelrow>
  <app-panelrow description="Medical Info" [hidebutton]="contact.wilduser"
      (onClick)="editMedicalInfo()">
      <p *ngIf="contact?.emergency?.medicalInfo" class="wild-paragraph-prominent">
          {{contact?.emergency?.medicalInfo}}
      </p>
      <span *ngIf="!contact?.emergency?.medicalInfo">Unknown</span>
  </app-panelrow>
  <app-panelrow description="Band ID" [hidebutton]="true" >
      <span *ngIf="contact?.bandId">{{contact?.bandId}}</span>
      <span class="text-gray-400 text-xs" *ngIf="!contact?.bandId">not paired</span>
  </app-panelrow>
  `
})
export class ContactProfileComponent {

  Gender = Gender;

  constructor(
    private contactService: ContactsService,
    private panelService: PanelService,
    private modalService: ModalService) { }

  @Input() contact: BusinessContactModel;
  RelationshipType = RelationshipType;

  editName() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactNameFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }],
      componentOutputs: [
        {
          outputName: "onComplete",
          func: async (personal: PersonalModel) => {
            this.contact.personal.firstName = personal.firstName;
            this.contact.personal.surname = personal.surname;
            this.contact.personal.pronouns = personal.pronouns;
          }
        }
      ],
    }
    this.modalService.show(settings);
  }

  openParentAccount(id: string) {
    var settings: PanelModel = {
      header: "Contact",
      component: ContactdetailsComponent,
      componentInputs: [
        {
          inputName: "id",
          value: id
        }
      ]
    }
    this.panelService.show(settings);
  }


  editEmail() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactEmailFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }]
    }
    this.modalService.show(settings);
  }


  editPhoneNumber() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactPhoneNumberFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }]
    }
    this.modalService.show(settings);
  }


  editGender() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactGenderFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }]
    }
    this.modalService.show(settings);
  }

  editDOB() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactDOBFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }]
    }
    this.modalService.show(settings);
  }

  editEmergencyInfo() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactEmergencyFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }]
    }
    this.modalService.show(settings);
  }

  editMedicalInfo() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      component: ContactMedicalInfoFormComponent,
      componentInputs: [{
        inputName: "contact",
        value: this.contact
      }],
      large: true
    }
    this.modalService.show(settings);
  }
}
