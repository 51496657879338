<li [ngClass]="{'shadow': !flat,'border': flat}"
    class="col-span-1 flex flex-col text-center bg-white rounded-lg divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8">
        <img *ngIf="image" class="w-32 h-32 flex-shrink-0 mx-auto rounded-full" [src]="image" alt="">
        <h3 *ngIf="header" class="mt-6 text-gray-900 text-sm font-medium">{{header}}</h3>
        <dl *ngIf="description" class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-sm">{{description}}</dd>
            <dd *ngIf="pills && pills.length > 0" class="mt-3">
                <span *ngFor="let pill of pills;let i = index"
                    class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full uppercase"
                    [ngClass]="{'ml-2':i > 0}">{{pill}}</span>
            </dd>
        </dl>
        <ng-content></ng-content>
    </div>
    <div>
        <div *ngIf="ctaonetext" class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
                <a (click)="ctaOneClicked()" [ngClass]="
                {'text-green-300 hover:text-green-300': catOneGreen,'text-red-300 hover:text-red-400':
                ctaOneRed,'text-gray-700 hover:text-gray-500':!catOneGreen && !ctaOneRed}" class="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm  font-medium border
                    border-transparent rounded-bl-lg ">
                    <span class=" w-5 h-5 text-sm"
                        [ngClass]="{'text-green-300': catOneGreen,'text-red-300 ': ctaOneRed,'text-gray-400':!catOneGreen && !ctaOneRed}">
                        <fa-icon [icon]="ctaoneicon"></fa-icon>
                    </span>
                    <span class="ml-3">{{ctaonetext}}</span>
                </a>
            </div>
            <div *ngIf="ctatwotext" class="-ml-px w-0 flex-1 flex">
                <a (click)="ctaTwoClicked()" [ngClass]="
                {'text-green-300 hover:text-green-300': ctaTwoGreen,'text-red-300 hover:text-red-400':
                ctaTwoRed,'text-gray-700 hover:text-gray-500':!ctaTwoGreen && !ctaTwoRed}"
                    class="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm  font-medium border border-transparent rounded-br-lg ">
                    <span class="w-5 h-5 text-gray-400 text-sm"
                        [ngClass]="{'text-green-500': ctaTwoGreen,'text-red-500': ctaTwoRed,'text-gray-400':!ctaTwoGreen && !ctaTwoRed}">
                        <fa-icon [icon]="ctatwoicon"></fa-icon>
                    </span>
                    <span class="ml-3">{{ctatwotext}}</span>
                </a>
            </div>
        </div>
    </div>
</li>