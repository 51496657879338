
import { Injectable } from '@angular/core';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { SubscribeModalComponent } from '../components/subscribemodal.component';
import { SubscribeWelcomeModalComponent } from '../components/subscribewelcomemodal.component';
import { Observable } from 'rxjs';
import { ManageSubscriptionModal } from '../components/manageSubscriptionModal.component';

@Injectable({
    providedIn: 'root'
})
export class SubscribeService {

    constructor(
        private http: HttpService,
        private modalService: ModalService,
    ) { }

    getSubscribeURL(path:string): Promise<any> {
        return this.http.get(`${environment.api}/user/booking/getSubscribeUrl?path=${path}`).toPromise();
    }
    manageSubscriptionUrl(path:string): Promise<any> {
        return this.http.get(`${environment.api}/user/booking/manageSubscriptionUrl?path=${path}`).toPromise();
    }
    async isSubscribed(): Promise<any> {
        const isSubscribed = await this.http.get(`${environment.api}/user/booking/isSubscribed`).toPromise();
        return isSubscribed.subscribed;
    }

     isSubscribeObservable(): Observable<any> {
        return this.http.get(`${environment.api}/user/booking/isSubscribed`)
    }

   subscribeModal(){
        var settings: ModalModel = {
            close: true,
            large: true,
            design: ModalDesign.COMPONENT,
            component: SubscribeModalComponent,
            sideimage: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/subscribe.png?alt=media&token=a12726e2-a0af-490d-a575-2e2a0a274f6b&_gl=1*wy1orq*_ga*MTY4MzkzMzI2Ni4xNjk1ODk4NzIx*_ga_CW55HF8NVT*MTY5ODI0Mjc0MS4zOS4xLjE2OTgyNDI3NzEuMzAuMC4w",
            componentInputs: []
        }
        this.modalService.show(settings);
    }

   manageSubscriptionModal(){
        var settings: ModalModel = {
            close: true,
            large: true,
            design: ModalDesign.COMPONENT,
            component: ManageSubscriptionModal,
            componentInputs: []
        }
        this.modalService.show(settings);
    }

   welcomeToWildPro(){
        var settings: ModalModel = {
            close: true,
            large: true,
            design: ModalDesign.COMPONENT,
            component: SubscribeWelcomeModalComponent,
            componentInputs: []
        }
        this.modalService.show(settings);
    }
}



