import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BusinessTimeSlotType } from '../../../../../../data/enums/business_timeslot_type';
import { BusinessModel } from '../../../../../../data/models/business_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { BusinessService } from '../../../services/business_service/business_service';
import { CancellationRefundRulesComponent } from './components/refundpolicyform.component';
import { TimeSlotIntervalFormComponent } from './components/timeslotintervalform.component';

@Component({
  selector: 'app-onlinebookings',
  templateUrl: './onlinebookings.component.html'
})
export class OnlineBookingComponent implements OnInit {

  business$: Observable<BusinessModel>;
  error: ErrorModel;


  constructor(
    private modal: ModalService,
    private notifyService: NotifyService,
    private business: BusinessService) { }

  ngOnInit(): void {

    this.load();
  }

  load() {
    this.business$ = null
    this.business$ = this.business.getBusiness().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  getTimeSlotDescription(business: BusinessModel) {
    var description = "30 minutes";
    switch (business.timeSlotType) {
      case BusinessTimeSlotType.TENMINUTE:
        description = "Every 10 mins (max availabilty)";
        break;
      case BusinessTimeSlotType.FIFTEENMINUTE:
        description = "15 minutes";
        break;
      case BusinessTimeSlotType.TWENTYMINUTE:
        description = "20 minutes";
        break;
      case BusinessTimeSlotType.THIRTYMINUTE:
        description = "30 minutes";
        break;
      case BusinessTimeSlotType.HOURLY:
        description = "1 hour (low availabilty)";
        break;
      default:
        break;
    }
    return description;
  }

  getCancellationRuleDescription(business: BusinessModel) {
    var description = "Up to 3 days before start time";
    if (business.refundTime == undefined || business.refundTime == null)
      return description;
    switch (business.refundTime) {
      case 0.25:
        description = "Up to 15 minutes before start time (very flexible)";
        break;
      case 0.5:
        description = "Up to 30 minutes before start time";
        break;
      case 1:
        description = "Up to 1 hour before start time";
        break;
      case 2:
        description = "Up to 2 hours before start time";
        break;
      case 3:
        description = "Up to 3 hours before start time";
        break;
      case 4:
        description = "Up to 4 hours before start time";
        break;
      case 5:
        description = "Up to 5 hours before start time";
        break;
      case 6:
        description = "Up to 6 hours before start time";
        break;
      case 7:
        description = "Up to 7 hours before start time";
        break;
      case 8:
        description = "Up to 8 hours before start time";
        break;
      case 9:
        description = "Up to 9 hours before start time";
        break;
      case 10:
        description = "Up to 10 hours before start time";
        break;
      case 11:
        description = "Up to 11 hours before start time";
        break;
      case 12:
        description = "Up to 12 hours before start time";
        break;
      case 13:
        description = "Up to 13 hours before start time";
        break;
      case 14:
        description = "Up to 14 hours before start time";
        break;
      case 15:
        description = "Up to 15 hours before start time";
        break;
      case 16:
        description = "Up to 16 hours before start time";
        break;
      case 17:
        description = "Up to 17 hours before start time";
        break;
      case 18:
        description = "Up to 18 hours before start time";
        break;
      case 19:
        description = "Up to 19 hours before start time";
        break;
      case 20:
        description = "Up to 20 hours before start time";
        break;
      case 21:
        description = "Up to 21 hours before start time";
        break;
      case 22:
        description = "Up to 22 hours before start time";
        break;
      case 23:
        description = "Up to 23 hours before start time";
        break;
      case 24:
        description = "Up to 1 day before start time";
        break;
      case 48:
        description = "Up to 2 days before start time";
        break;
      case 72:
        description = "Up to 3 days before start time";
        break;
      case 96:
        description = "Up to 4 days before start time";
        break;
      case 120:
        description = "Up to 5 days before start time";
        break;
      case 144:
        description = "Up to 6 days before start time";
        break;
      case 168:
        description = "Up to 1 week before start time";
        break;
      case 192:
        description = "Up to 8 days before start time";
        break;
      case 216:
        description = "Up to 9 days before start time";
        break;
      case 240:
        description = "Up to 10 days before start time";
        break;
      case 264:
        description = "Up to 11 days before start time";
        break;
      case 288:
        description = "Up to 12 days before start time";
        break;
      case 312:
        description = "Up to 13 days before start time";
        break;
      case 336:
        description = "Up to 2 weeks before start time";
        break;
      case 999999:
        description = "Never - Customers have to contact us to cancel";
        break;
      default:
        break;
    }
    return description;
  }

  editRefundRule(business: BusinessModel) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      large: true,
      close: true,
      component: CancellationRefundRulesComponent,
      componentInputs: [{
        inputName: "business",
        value: business
      },
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: async () => {
            this.load();
          }
        }
      ]
    }
    this.modal.show(settings);
  }

  editTimeSlotInterval(business: BusinessModel) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      large: true,
      close: true,
      component: TimeSlotIntervalFormComponent,
      componentInputs: [{
        inputName: "business",
        value: business
      },
      ],
      componentOutputs: [
        {
          outputName: "updated",
          func: async () => {
            this.load();
          }
        }
      ]
    }
    this.modal.show(settings);
  }

}