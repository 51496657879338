import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BeaufortScale } from '../../../../../../../data/enums/beaufort_scale';
import { CardinalDirection } from '../../../../../../../data/enums/cardinal_direction';
import { TideType } from '../../../../../../../data/enums/tide_type';
import { WeatherClassification } from '../../../../../../../data/enums/weather_classification';
import { SwimSpot } from '../../../../../../../data/models/swimspot_model';
import { WeatherChunks } from '../../../../../../../data/models/weather_chunks';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';


@Component({
  selector: '[app-oceanforecast]',
  templateUrl: './oceanforecast.component.html',
})
export class OceanForecastComponent implements OnInit {
  @Input() swimspot: SwimSpot;
  BeaufortScale = BeaufortScale;
  WeatherClassification = WeatherClassification;
  TideType = TideType;
  CardinalDirection = CardinalDirection;
  showHourly: boolean = false;
  detailed: boolean = false;
  reallyDetailed: boolean = false;

  toggleHourly(chunk: WeatherChunks) {
    if (this.authService.isAuthedIfNotShowPopUp()) {
      chunk["showHourly"] = !chunk["showHourly"];
    }
  }

  toggleDetailed(chunk: WeatherChunks) {
    if (this.authService.isAuthedIfNotShowPopUp()) {
      if (chunk["detailed"] == true) {
        chunk["detailed"] = false;
        chunk["reallyDetailed"] = false;
      } else {
        chunk["detailed"] = true;
        chunk["reallyDetailed"] = false;
      }
    }
  }

  toggleReallyDetailed(chunk: WeatherChunks) {
    if (this.authService.isAuthedIfNotShowPopUp()) {
      if (chunk["reallyDetailed"] == true) {
        chunk["detailed"] = true;
        chunk["reallyDetailed"] = false;
      } else {
        chunk["detailed"] = true;
        chunk["reallyDetailed"] = true;
      }
    }
  }

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

}

