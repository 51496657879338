import { Component, Input, OnInit } from '@angular/core';
import { MyBusiness } from '../../../../../../../data/models/mybusiness';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../../data/enums/navigation_type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-changebusiness',
  templateUrl: './changebusiness.component.html',
  styleUrls: ['./changebusiness.component.scss']
})
export class ChangeBusinessComponent implements OnInit {

  @Input() businesses: MyBusiness[]
  imagesUrl = environment.imagesUrl;

  constructor(
    private modalService: ModalService,
    private businessService: BusinessService,
    private navigation: NavigationService,
    private notify: NotifyService
  ) { }
  ngOnInit(): void {
  }

  open(businessId) {
    var business = this.businesses.find(business => business.business.id == businessId);
    this.businessService.setActiveBusiness(
      businessId,
      business.business.name,
      business.business.uploadedprofilepic != null && (business?.business?.uploadedprofilepic as any) != '' ? this.imagesUrl + business.business.uploadedprofilepic.image + '_100x100.jpeg?alt=media' : "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media",
      business.roles,
      business.business.url);
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
  }

  deleteBusiness(businessId: string) {
    var settings: ModalModel = {
      header: "Are you sure?",
      description: "Are you sure you want to permanently delete this?",
      design: ModalDesign.RIGHTALIGNEDBUTTONS,
      type: ModalType.ERROR,
      ctaonetext: "Delete",
      ctaonetextclick: () => {
        this.delete(businessId);
      },
      ctatwotext: "Cancel",
      close: true
    }
    this.modalService.show(settings);
  }

  new() {
    this.navigation.navigate(WildNavigationType.ONBOARDINGV2);
  }

  delete(businessId: string) {
    this.businessService.delete(businessId)
      .then(() => {
        this.refresh();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
  }

  refresh() {
    this.navigation.refreshPage(WildNavigationType.GROUPS);
  }

  handlerError(error: string) {
    this.notify.notify("Error deleting record", error, NotifyType.ERROR);
  }

}
