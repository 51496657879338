import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalResultsDTO } from '../../../../../data/dtos/business/search/global/GlobalResultsDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class GlobalSearchService {

    constructor(
        private http: HttpService
    ) { }

    search(search: string): Observable<GlobalResultsDTO> {
        return this.http.get(`${environment.api}/business/search/global?q=${search}`);
    }

}
