import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { BusinessNoteDTO } from '../../../../data/dtos/business/BusinessNoteDTO';
import { SourceDocumentType } from '../../../../data/enums/source_document_type';
import { Activity } from '../../../../data/models/activity_model';
import { ErrorModel } from '../../../../data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NotesService } from '../services/notes_service/notes_service';
import { AddEditNoteComponent } from './addeditnote.component';

@Component({
  selector: 'app-notes',
  template: `
  <div class="flex items-center justify-end pt-5 pr-5">
            <button class="wild-btn-light" (click)="add()">Add</button>
        </div>
    <ng-container *ngIf="notes$ | async as notes; else loadingOrError">
      <div *ngIf="!notes || notes.length == 0; else list">
        <app-empty icon="edit" header="No notes Yet" ></app-empty>
      </div>
      <ng-template #list>
        <!-- <ul *ngIf="activity?.teamdetails?.length > 0" role="list"
            class="flex-1 divide-y divide-gray-200 overflow-y-auto">
            <app-listitem *ngFor="let team of activity.teamdetails" [notclickable]="true"
                [image]="team?.teamMember.image?.image"
                [firstName]="team.teamMember.firstName ?? 'Name not set'"
                [surname]="team.teamMember.surname ?? ''" [subline]="team?.teamMember?.email"
                ctaonetext="Remove" ctaoneicon="trash" [object]="team?.teamMember"
                (ctaoneclick)="removeTeamMember($event)" ctaoneclass="text-red-500">
            </app-listitem>
        </ul> -->
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 px-6 mt-4">
          <div *ngFor="let note of notes">
            <div (click)="edit(note)"  class="relative rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm hover:bg-gray-50 cursor-pointer" >
            <div  class="flex-1 min-w-0">
                <span  class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <p class="text-sm font-medium text-gray-700">
                    {{note.note}}
                  </p> 
                  <hr class="my-2 text-gray-200" />
                  <p *ngIf="note.createdBy" class="text-xs text-gray-300 truncate text-right">
                    {{note.createdBy}}
                  </p>
                  <p *ngIf="note.editedBy" class="text-xs text-gray-300 truncate text-right">
                    {{note.editedBy}}
                  </p>
                </span>
              </div>
            </div>
            <div class="text-right text-xs text-red-500 hover:underline mt-1 cursor-pointer" (click)="delete(note)">delete</div>
          </div>
        </div>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  `
})
export class NotesComponent implements OnInit {

  constructor(
    private notifyService: NotifyService,
    private modal: ModalService,
    private notesService: NotesService) { }

  public error: ErrorModel = null;
  @Input() sourceDocumentId: string;
  @Input() sourceDocumentType: SourceDocumentType;
  @Output() count = new EventEmitter<number>();
  notes$: Observable<BusinessNoteDTO[]>;

  ngOnInit(): void {
    this.load();
  }

  delete(note: BusinessNoteDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to delete this note?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.procecssDelete(note);
      }
    }
    this.modal.show(settings);
  }

  procecssDelete(note: BusinessNoteDTO) {
    this.notesService.remove(note.id)
      .then(async () => {
        this.notifyService.notify("Deleted", "Note has been deleted", NotifyType.SUCCESS);
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.modal.hide();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  add() {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      large: true,
      close: true,
      component: AddEditNoteComponent,
      componentInputs: [
        {
          inputName: "sourceDocumentId",
          value: this.sourceDocumentId
        },
        {
          inputName: "sourceDocumentType",
          value: this.sourceDocumentType
        },
      ],
      componentOutputs: [
        {
          outputName: "added",
          func: async () => {
            this.load();
          }
        }
      ]
    }
    this.modal.show(settings);
  }

  edit(note: BusinessNoteDTO) {
    var settings: ModalModel = {
      design: ModalDesign.COMPONENT,
      large: true,
      close: true,
      component: AddEditNoteComponent,
      componentInputs: [{
        inputName: "note",
        value: note
      },
      ],
      componentOutputs: [
        {
          outputName: "edited",
          func: async () => {
            this.load();
          }
        }
      ]
    }
    this.modal.show(settings);
  }


  load() {
    this.notes$ = null;
    this.notes$ = this.notesService.list(this.sourceDocumentType, this.sourceDocumentId).pipe(
      take(1),
      tap((notes) => this.count.emit(notes.length)),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  openActivity(activity: Activity) {
    // var settings: PanelModel = {
    //   header: "Activity Details",
    //   component: AppointmentDetailsComponent,
    //   componentInputs: [
    //     {
    //       inputName: "id",
    //       value: activity.id
    //     },
    //   ],
    //   componentOutputs: [
    //     {
    //       outputName: "updated",
    //       func: () => {
    //         this.load();
    //       }
    //     }
    //   ]
    // }
    // this.panelService.show(settings);
  }

}
