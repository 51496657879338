<div [ngClass]="{'bg-white': data.colour == FeatureColour.WHITE,'bg-gray-50': data.colour == FeatureColour.GRAY }">
  <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
    <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
      <app-titles [data]="data"></app-titles>
      <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
        <div *ngFor="let data of data.data" class="pt-6">
          <dt> 
            <!-- Expand/collapse question button -->
            <button (click)="data.open = !data.open" type="button" class="flex w-full items-start justify-between text-left text-gray-900" aria-controls="faq-0" aria-expanded="false">
              <span class="text-base font-semibold leading-7">{{data.title}}</span>
              <span class="ml-6 flex h-7 items-center">
                <svg [ngClass]="{'hidden':data.open}" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                </svg>
                <svg [ngClass]="{'hidden':!data.open}" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                </svg>
              </span>
            </button>
          </dt>
          <dd *ngIf="data?.open" class="mt-2 pr-12" id="faq-0">
            <p class="text-base leading-7 text-gray-600" [innerHtml]="data.subtitle"></p>
          </dd>
        </div>
      </dl>
    </div>
    <div *ngIf="data.ctas" class="mt-12">
      <app-ctas [data]="data"></app-ctas>
    </div>
  </div>
</div>
