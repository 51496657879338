<ng-container *ngIf="swimspots$ | async as swimspots; else loadingOrError">
    <div *ngIf="!swimspots || swimspots?.length == 0; else swimspotslist">

    </div>
    <ng-template #swimspotslist>
        <div class="lg:shadow-sm lg:rounded-xl lg:border lg:border-gray-100 lg:p-4 mb-4 lg:bg-gray-50">
            <h2 class="text-xs font-bold text-gray-700 truncate leading-tight mb-3">Where we operate</h2>
            <ul role="list"
                class="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-8">
                <li *ngFor="let swimspot of swimspots" [caption]="swimspot.name" [id]="swimspot.id"
                    (onClick)="openSwimSpot($event)" [src]="
                swimspot?.image?.image == null ?
                    'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839' :
                    imagesUrl + swimspot?.image?.image + '_640x640.jpeg?alt=media'" app-swimspotlistitem>
                </li>
            </ul>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
    </ng-container>
    <ng-template #loading>
    </ng-template>
</ng-template>