import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SelectModel } from 'src/shared/components/select/select_model';
import { BusinessUserModel } from '../../../../../../data/models/business_user_model';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { TeamService } from '../../../services/team_service/team_service';

@Component({
  template: `
  <app-modaltopper [header]="'Select Team Members'"></app-modaltopper>
          <div class="w-full" style="max-height:350px;overflow-y:scroll">
            <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
            <app-listitem (onClick)="selectAll()"
              [image]="null"
                  [firstName]="'All Team Members'"
                  [surname]="''"
                  [subline]="''"
                  [showSelectBox]="true"
                  [selected]="isAll()"
                  >
              </app-listitem>
              <app-listitem *ngFor="let teamMember of preLoadedTeamMembers" (onClick)="select(teamMember)"
                  [image]="teamMember?.image?.image"
                  [firstName]="teamMember?.firstName ?? '?'" [surname]="teamMember?.surname ?? '?'"
                  [subline]="teamMember?.email"
                  [showSelectBox]="true"
                  [selected]="isSelected(teamMember)"
                  >
              </app-listitem>
            </ul>
          </div>
          <div class="w-full flex items-center justify-center">
            <button class="wild-btn-primary mt-3" (click)="done()">Apply Filters</button>
          </div>
  `
})
export class CalendarTeamMemberSelection implements OnInit {

  @Input() inputTeamMembers: BusinessUserModel[];
  @Input() preLoadedTeamMembers: BusinessUserModel[];
  @Output() outputTeamMembers = new EventEmitter<BusinessUserModel[]>();

  constructor(
    private teamService: TeamService,
    private modalService: ModalService) { }

  loading: boolean;
  hourOptions: SelectModel[] = [];
  minuteOptions: SelectModel[] = [];
  startDate: string;
  endDate: string;
  startHour: number;
  startMinutes: number;
  team$: Observable<BusinessUserModel[]>;
  endHour: number;
  endMinutes: number;
  error: ErrorModel;

  ngOnInit(): void {
  }

  selectAll() {
    this.inputTeamMembers = [];
  }

  isAll(): boolean {
    if (this.inputTeamMembers == null)
      return true;
    return this.inputTeamMembers.length == 0;
  }

  isSelected(teamMember): boolean {
    if (this.inputTeamMembers == null)
      return false;
    return this.inputTeamMembers.some(itm => itm.id == teamMember.id);
  }

  select(teamMember: BusinessUserModel) {
    if (this.inputTeamMembers == null)
      this.inputTeamMembers = [];
    var index = this.inputTeamMembers.findIndex(itm => itm.id == teamMember.id);
    if (index == -1) {
      this.inputTeamMembers.push(teamMember);
    } else {
      this.inputTeamMembers.splice(index, 1);
    }
  }

  done() {
    this.modalService.hide();
    this.outputTeamMembers.emit(this.inputTeamMembers);
  }

  cancel() {
    this.modalService.hide();
  }

}
