import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { BrochureRoutingModule } from './brochure-routing.module';
import { BusinessFeaturesComponent } from './components/businessfeatures/businessfeatures.component';
import { BusinessHeroComponent } from './components/businesshero/businesshero.component';
import { BeaconComponent } from './pages/beacon/beacon.component';
import { BrochureSearchResultsComponent } from './pages/brochuresearch/brochuresearch.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoComponent } from './pages/demo/demo.component';
import { DirectoryComponent } from './pages/directory/directory.component';
import { FeaturesComponent } from './pages/features/features.component';
import { GroupInviteComponent } from './pages/groupinvite/groupinvite.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { OpenWaterJournalComponent } from './pages/openwaterjournal/openwaterjournal.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SearchComponent } from './pages/search/search.component';
import { SOSComponent } from './pages/sos/sos.component';
import { TermsComponent } from './pages/terms/terms.component';
import { TopSpotsInComponent } from './pages/topspotsin/topspotsin.component';
import { SignWaiverComponent } from './pages/signwaiver/signwaiver.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

@NgModule({
  declarations: [
    //pages
    NewsletterComponent,
    ContactComponent,
    NewsletterComponent,
    SignWaiverComponent,
    OpenWaterJournalComponent,
    PrivacyComponent,
    TermsComponent,
    BrochureSearchResultsComponent,
    BeaconComponent,
    SOSComponent,
    SearchComponent,
    PricingComponent,
    DirectoryComponent,
    DemoComponent,
    GroupInviteComponent,
    TopSpotsInComponent,
    FeaturesComponent,
    //components
    BusinessFeaturesComponent,
    BusinessHeroComponent,
  ],
  imports: [
    SharedModule,
    AngularSignaturePadModule,
    BrochureRoutingModule
  ]
})
export class BrochureModule { }
