import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from 'src/shared/resolvers/user.resolver';
import { FitbitIntegrationPageComponent } from './pages/fitbitintegration.component';
import { GarminIntegrationPageComponent } from './pages/garminintegration.component';
import { SuuntoIntegrationPageComponent } from './pages/suuntointegration.component';
import { UserIntegrationsWrapperPageComponent } from './pages/wearablereturnpage.component';

const routes: Routes = [
  {
    path: "integrations",
    resolve: {
      user: UserResolver
    },
    component: UserIntegrationsWrapperPageComponent,
    children: [
      {
        path: "garmin",
        component: GarminIntegrationPageComponent,
      },
      {
        path: "fitbit",
        component: FitbitIntegrationPageComponent,
      },
      {
        path: "suunto",
        component: SuuntoIntegrationPageComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class UserIntegrationsRoutingModule { }
