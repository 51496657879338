export enum RefundType {
    UNKNOWN,
    BYUSER,
    BYBUSINESS,
    BYWILD,
    BUSINESSTRANSACTIONFEE,
    BUSINESSTRANSACTIONFEETOCOMPENSATEFORUSERFEES,
    USERTRANSACTIONFEE,
    USERTRANSACTIONFEEALREADYPROCESSED,
    BUSINESSTRANSACTIONFEEALREADYREFUNDED,
    NOREFUNDREQUIREDJUSTCANCELLED
}