import { Injectable } from '@angular/core';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EmailBundleLinksDTO } from 'src/data/dtos/business/configuration/EmailBundleLinksDTO';

@Injectable({
    providedIn: 'root'
})
export class BusinessEmailBundleService {

    constructor(
        private http: HttpService,
    ) { }

    getEmailBundleLinks(): Observable<EmailBundleLinksDTO> {
        return this.http.get(`${environment.api}/business/configuration/emailbundleurls`);
    }
}
