import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, catchError, take, tap, throwError } from 'rxjs';
import { SignaturePadComponent, NgSignaturePadOptions } from '@almothafar/angular-signature-pad';
import { WaiverForSigningDTO } from '../../../../../data/dtos/waiver/WaiverForSigningDTO';
import { UserWaiverService } from '../../services/userwaiver/userwaiver_service';
import { ActivatedRoute } from '@angular/router';
import { ErrorModel } from '../../../../../data/models/error_model';
import { ContactWaiverStatus } from '../../../../../data/enums/waiver/ContactWaiverStatus';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SignedWaiverDetailsDTO } from '../../../../../data/dtos/waiver/SignedWaiverDetailsDTO';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';

@Component({
  templateUrl: './signwaiver.component.html',
})
export class SignWaiverComponent implements OnInit, AfterViewInit {

  waiver$: Observable<WaiverForSigningDTO>;
  contactId: string;
  ContactWaiverStatus = ContactWaiverStatus;
  businessId: string;
  signed: boolean;
  isAppUser: boolean;
  error: ErrorModel;
  showSignatureArea: boolean;
  loading: boolean = false;
  waiverform: UntypedFormGroup;
  @ViewChild('signature')
  signaturePad: SignaturePadComponent;
  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 220,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private auth:AuthenticationService,
    private modalService: ModalService,
    private appComms: CommunitcateWithAppService,
    private notify: NotifyService,
    private userWaiverService: UserWaiverService,
    private route: ActivatedRoute
  ) {
    // no-op
  }
  ngOnInit(): void {
    this.businessId = this.route.snapshot.paramMap.get('businessId');
    this.contactId = this.route.snapshot.paramMap.get('contactId');
    this.isAppUser = this.auth.isAppUser;
    this.load();
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    this.waiverform = this.fb.group({
      name: new UntypedFormControl('', [Validators.required])
    });
  }

  load() {
    this.waiver$ = null
    this.waiver$ = this.userWaiverService.getWaiverForSigning(this.businessId, this.contactId).pipe(
      take(1),
      tap((waiver) => {
        if (waiver.status == ContactWaiverStatus.NEEDSSIGNING)
          this.showSignatureArea = true;
      }),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  clearPad() {
    this.signaturePad.clear();
  }



  onSubmit(data) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: `I confirm that I am signing this waiver on my behalf or have the right to sign this on behalf of someone else.`,
      header: "Legal Confirmation",
      close: true,
      ctaonetext: "Confirm & Sign",
      ctatwotext: "Cancel",
      ctaonetextclick: async () => {
        this.onSubmitProcess(data);

      }
    }
    this.modalService.show(settings);
  }

  onSubmitProcess(data) {
    var signature = "";

    try {
      signature = this.signaturePad.toDataURL();
    } catch (error) {

    }
    if (this.signaturePad.isEmpty() == true) {
      this.notify.notify("Waiver Not Signed", "Please sign waiver to continue", NotifyType.ERROR);
      return;
    }
    this.loading = true;
    var dto: SignedWaiverDetailsDTO = {
      signature: signature,
      name: data.name
    }
    this.userWaiverService.signwaiver(this.businessId, this.contactId, dto)
      .then(async () => {
        this.notify.notify("Waiver Signed", "", NotifyType.SUCCESS);
        this.signed = true;
      })
      .catch((error) => {
        this.notify.notify("Waiver Error", error.message, NotifyType.ERROR);
      })
      .finally(() => {
        this.loading = false;
      })
  }

}
