import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { SourceDocumentType } from '../../../../data/enums/source_document_type';
import { ErrorModel } from '../../../../data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { SendMessageService } from './sendmessage/send_message_service';
import { MessageListItemDTO } from 'src/data/dtos/messages/MessageListItemDTO';

@Component({
  selector: 'app-sentmessageslist',
  template: `
    <ng-container *ngIf="messages$ | async as messages; else loadingOrError">
    <div *ngIf="!messages || messages.length == 0; else list">
        <app-empty icon="messages" header="No messages yet" ></app-empty>
      </div>
      <ng-template #list>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 px-6 mt-4">
          <div *ngFor="let message of messages">
            <div class="relative rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm" >
            <div  class="flex-1 min-w-0">
                <span>
                  <small class="text-xs font-light text-gray-500">Subject</small>
                  <p class="text-sm font-medium text-gray-700">
                    {{message.subject}}
                  </p> 
                  <small class="text-xs font-light text-gray-500">Title</small>
                  <p class="text-sm font-medium text-gray-700">
                    {{message.title}}
                  </p> 
                  <small class="text-xs font-light text-gray-500">Body</small>
                  <p [ngClass]="{'truncate':!message?.showFullMessage}" class="text-sm font-light text-gray-700" [class.line-clamp-2]="!message?.showFullMessage">
                    {{message.body}}
                  </p>
                  <button class="text-blue-500 hover:text-blue-700 text-sm" *ngIf="message.body.length > 100" (click)="message['showFullMessage'] = !message['showFullMessage']">
                    {{message['showFullMessage'] ? 'Show less' : 'Show more'}}
                  </button>
                  <hr class="my-2 text-gray-200" />
                  <p class="text-xs text-gray-300 truncate text-right">
                    {{message.name}} - {{message.date}}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
      </ng-template>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
      </ng-container>
      <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
      </ng-template>
    </ng-template>
  `
})


export class SentMessagesListComponent implements OnInit {

  constructor(
    private notifyService: NotifyService,
    private modal: ModalService,
    private sendMessageService: SendMessageService) { }

  public error: ErrorModel = null;
  @Input() sourceDocumentId: string;
  @Input() sourceDocumentType: SourceDocumentType;
  messages$: Observable<MessageListItemDTO[]>;

  ngOnInit(): void {
    this.load();
  }


  load() {
    this.messages$ = null;
    if(this.sourceDocumentType == SourceDocumentType.CONTACT){
      this.messages$ = this.sendMessageService.getContactMessages(this.sourceDocumentId).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    }else if(this.sourceDocumentType == SourceDocumentType.BOOKEDACTIVITY){
      this.messages$ = this.sendMessageService.getActivityMessages(this.sourceDocumentId).pipe(
        take(1),
        catchError(err => {
          this.error = err;
          return throwError(err);
        })
      );
    }else{
      alert("UNKNOWN SOURCE DOCUMENT TYPE OF MESSAGES")
    }
   
  }
}
