import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelPage } from '../panel_page_model';

@Component({
    selector: 'app-panelheader',
    template: `
  <div [ngClass]="{'pb-6':image}">
        <div class="wild-bg-primary h-24 sm:h-20 lg:h-28 flex flex-col items-left justify-center">
            <h3 *ngIf="!image" class="font-bold text-xl text-white sm:text-2xl px-6">{{header}}</h3>
            <p *ngIf="!image && subheading" class="text-sm text-white px-6">{{subheading}}</p>
        </div>
        <div *ngIf="image || ctaOneText || ctaTwoText" class="flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-15">
            <div *ngIf="image">
                <div class="-m-1 flex">
                    <div class="inline-flex rounded-lg overflow-hidden border-4 border-white">
                        <img class="flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48"
                            [src]="image"
                            alt="">
                    </div>
                </div>
            </div>
            <div [ngClass]="{'sm:ml-6':image}" class="mt-6  sm:flex-1">
                <div *ngIf="image">
                    <div class="flex items-center">
                        <h3 class="font-bold text-xl text-gray-900 sm:text-2xl">{{header}}
                        </h3>
                        <span *ngIf="showDot" class="ml-2.5 bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full">
                            <span class="sr-only">Online</span>
                        </span>
                    </div>
                    <p *ngIf="subheading" class="text-sm text-gray-500">{{subheading}}</p>
                    <p (click)="refreshPanel()" *ngIf="showrefreshbutton" class="text-sm text-blue-500 hover:underline cursor-pointer">reload</p>
                </div>
                <div class="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3 mb-3">
                    <button *ngIf="ctaOneText" (click)="clickCTAOne()" type="button"
                        class="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:flex-1">
                        {{ctaOneText}}
                    </button>
                    <button *ngIf="ctaTwoText" (click)="clickCTATwo()" type="button"
                        class="mb-4 flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        {{ctaTwoText}}
                    </button>
                    <span *ngIf="pages && pages?.length > 0" class="ml-3 inline-flex sm:ml-0">
                        <div class="relative inline-block text-left">
                            <button (click)="toggleMenu()" type="button"
                                class="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                id="options-menu-button" aria-expanded="false" aria-haspopup="true">
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                    fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                </svg>
                            </button>
                            <div [ngClass]="{
                                'opacity-0 scale-95 hidden':!menu,
                                'opacity-100 scale-100 block':menu
                            }" class="z-10 transform origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu" aria-orientation="vertical" aria-labelledby="options-menu-button"
                                tabindex="-1">
                                <div class="py-1" role="none">
                                <!-- [ngClass]="{'bg-gray-100 text-gray-900':page == ContactPages.PROFILE,'text-gray-700':page != ContactPages.PROFILE}" -->
                                    <span *ngFor="let page of pages"  (click)="changePage(page)"
                                        class="block px-4 py-2 text-sm cursor-pointer" role="menuitem" 
                                        id="options-menu-item-0">{{page.name}}</span>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
  `
})
export class PanelHeaderComponent {

    @Input() header: string;
    @Input() subheading: string;
    @Input() image: string;
    @Input() ctaOneText: string;
    @Input() ctaTwoText: string;
    @Input() showDot: boolean;
    @Input() showrefreshbutton: boolean;
    @Output() onrefresh = new EventEmitter();
    @Input() pages: PanelPage[];
    @Output() ctaOneClick = new EventEmitter();
    @Output() ctaTwoClick = new EventEmitter();
    @Output() pageChanged = new EventEmitter<PanelPage>();
    menu: boolean = false;
    menuAnimation: boolean = false;

    refreshPanel() {
        this.onrefresh.emit();
    }

    clickCTAOne() {
        this.ctaOneClick.emit();
    }
    clickCTATwo() {
        this.ctaTwoClick.emit();
    }

    changePage(page: PanelPage) {
        this.menu = false;
        this.menuAnimation = false;
        this.pageChanged.emit(page);
    }

    toggleMenu() {
        this.menu = !this.menu;
    }

}
