import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorModel } from '../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';
import { BusinessService } from '../../services/business_service/business_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.scss']
})
export class BusinessesComponent implements OnInit {

  constructor(
    private navigation: NavigationService,
    private appComms: CommunitcateWithAppService,
    private auth: AuthenticationService,
    private businessService: BusinessService) { }


  public error: ErrorModel = null;
  isAppUser: boolean = false;
  ctatwotext: string = "";
  businesses$ = this.businessService.getMyBusinesses().pipe(
    catchError(err => {
      this.error = err;
      return throwError(err);
    })
  );

  new() {
    this.navigation.navigate(WildNavigationType.ONBOARDINGV2);
  }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    if (this.isAppUser == true)
      this.ctatwotext = "Close";
  }

  retry() {
    this.navigation.refreshPage(WildNavigationType.GROUPS);
  }

  showUserNameForm() {

  }

  backToApp() {
    this.appComms.pop();
  }



}
