import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-userdashboard',
  templateUrl: './userdashboard.component.html',
  styleUrls: ['./userdashboard.component.scss']
})
export class UserdashboardComponent implements OnInit {

  constructor(private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH);
  }

  addSwimSpot() {
    this.navigation.navigate(WildNavigationType.USERADDSWIMSPOT);
  }

  app() {
    window.open("https://sharing.swimwild.app/6YLgrDhBtVDD3dAv6");
  }

  newsletter() {
    this.navigation.navigate(WildNavigationType.NEWSLETTER);
  }

}
