import { Component, OnInit, inject } from '@angular/core';
import { Analytics } from '@angular/fire/analytics';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { StorageService } from 'src/shared/services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './wild.component.html',
  styleUrls: ['./wild.component.scss']
})
export class WildComponent implements OnInit {
  isBrochureSite: string = "";
  isLoading: boolean = false;
  private analytics: Analytics = inject(Analytics);

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private storageService: StorageService) {
    this.storageService.deleteCookie("widgetuser");
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.checkMenu();
      }
    });
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isLoading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  ngOnInit(): void {
    this.checkMenu();
  }

  checkMenu() {
    var url = this.router.url.split('?')[0];
    if (
      url.includes("business") ||
      url.includes("groups") ||
      url.includes("welcome") ||
      url.includes("invite") ||
      url.includes("user") ||
      url.includes("login") ||
      url.includes("register") ||
      url.includes("testbookinghome")) {
      this.isBrochureSite = ""
    } else {
      this.isBrochureSite = "yes"
    }
  }
}
