<app-wild-header [showSearchForm]="true"></app-wild-header>
<div class="max-w-7xl mx-auto px-6 md:px-8 mt-4">
    <gallery [items]="images" [autoPlay]="true" [gestures]="true" imageSize="contain"></gallery>
    <div *ngIf="swimspotWrapper.carousel && swimspotWrapper.carousel?.length > 0"
        class="carousel-container w-full overflow-hidden">
        <div #textslide class="flex w-full">
            <p class="wild-bg-primary text-md md:text-lg xl:text-2xl text-center text-white min-w-full p-3 lg:p-5 xl:p-8"
                id="lastClone">
                {{swimspotWrapper.carousel[swimspotWrapper.carousel.length - 1]}}
            </p>
            <p class="wild-bg-primary text-md md:text-lg xl:text-2xl text-center text-white min-w-full p-3 lg:p-5 xl:p-8"
                *ngFor="let item of swimspotWrapper.carousel">
                {{item}}</p>
            <p class="wild-bg-primary text-md md:text-lg xl:text-2xl text-center text-white min-w-full p-3 lg:p-5 xl:p-8"
                id="firstClone">
                {{swimspotWrapper.carousel[0]}}</p>
        </div>
    </div>
</div>
<div class="max-w-7xl mx-auto px-6 md:px-8 mt-4">
    <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 ">{{swimspot.name}}</h1>
    <h2 *ngIf="swimspot.status == SwimSpotStatus.INREVIEW" class="wild-text-primary font-bold mb-1 ">In
        Review</h2>
    <p *ngIf="swimspot.status == SwimSpotStatus.INREVIEW" class="text-xs font-thin">Forecast data will start
        populating after approval</p>
    <p *ngIf="swimspot.location" class="wild-text-primary pb-3 text-sm">
        {{swimspotWrapper.location}}
    </p>
    <h2 *ngIf="swimspot.description" class="text-xs wild-text-primary  mb-1 mt-0 whitespace-pre-line">
        Description</h2>
    <p *ngIf="swimspot.description" class="text-md">
        {{swimspot.description}}</p>
    <h2 *ngIf="swimspot.gettingthere" class="text-xs wild-text-primary  mb-1 mt-2">
        Getting There</h2>
    <p *ngIf="swimspot.gettingthere" class="text-md">
        {{swimspot.gettingthere}}</p>
    <h2 *ngIf="swimspot.accessibilityinformation" class="text-xs wild-text-primary mb-1 mt-2">
        Accessibility</h2>
    <p *ngIf="swimspot.accessibilityinformation" class="text-md">
        {{swimspot.accessibilityinformation}}</p>
    <div class="mt-3" app-tags [swimspot]="swimspot"></div>
    <div app-swimspotmap [swimspot]="swimspot"></div>
    <!-- BUSINESSES START -->
    <div *ngIf="businesses$">
        <ng-container *ngIf="businesses$ | async as results; else loadingOrError">
            <div *ngIf="!results || results?.length == 0; else businessesResults">
                <!-- NO RESULTS - SHOW NOTHING -->
            </div>
            <ng-template #businessesResults>
                <div style="height: 320px;">
                    <app-homepageresults [results]="results" label="Businesses">
                    </app-homepageresults>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="businesses()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <!-- No loader on businesses -->
            </ng-template>
        </ng-template>
    </div>
    <!-- BUSINESSES END -->
    <!-- NEARBY START -->
    <div *ngIf="nearby$">
        <ng-container *ngIf="nearby$ | async as results; else loadingOrError">
            <div *ngIf="!results; else nearbyResults">
                <!-- NO RESULTS - SHOW NOTHING -->
            </div>
            <ng-template #nearbyResults>
                <div style="height: 400px;">
                    <app-homepageresults [results]="results.results" label="Nearby">
                    </app-homepageresults>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="nearby()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <div style="height: 350px;">
                    <app-loader [disablescreenheight]="true"></app-loader>
                </div>
            </ng-template>
        </ng-template>
    </div>
    <!-- NEARBY END -->
    <app-tabs tabsId="swimspot" (tabChanged)="updateActiveTabId($event)" [tabs]="tabs"></app-tabs>
    <div class="mb-3" style="min-height: 300px;">
        <div *ngIf="activeTabId =='reviews'" app-reviews [swimspot]="swimspot"></div>
        <div *ngIf="activeTabId =='posts'" app-swimspotposts [swimspot]="swimspot"></div>
        <div *ngIf="activeTabId =='alerts'" app-alerts [swimspot]="swimspot"></div>
        <div *ngIf="activeTabId =='oceanforecast'" app-oceanforecast [swimspot]="swimspot"></div>
        <div *ngIf="activeTabId =='tides'" app-tidetable [swimspot]="swimspot"></div>
    </div>
    <app-appdownloadlinks header="Discover more on the FREE Wild app"></app-appdownloadlinks>
</div>
<app-footer></app-footer>