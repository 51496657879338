import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-rotatingtext',
  styleUrls: ['./rotatingtext.component.scss'],
  template: `
  <div class="rotatingText fade-in">
      <p class="text-gray-500">{{prefix}}&nbsp;</p>
      <p *ngFor="let string of strings;let i =index">
        <span *ngIf="i == 0" class="word wisteria">{{string}}</span>
        <span *ngIf="i == 1" class="word belize">{{string}}</span>
        <span *ngIf="i == 2" class="word pomegranate">{{string}}</span>
        <span *ngIf="i == 3" class="word green">{{string}}</span>
        <span *ngIf="i == 4" class="word midnight">{{string}}</span>
        <span *ngIf="i == 5" class="word slime">{{string}}</span>
      </p>
  </div>
  `,
})
export class RotatingTextComponent implements AfterViewInit {
  @Input() prefix: string;
  @Input() strings: string[];
  ngAfterViewInit(): void {
    setTimeout(()=>{
      var words: any = document.getElementsByClassName('word');
      var wordArray = [];
      var currentWord = 0;
      words[currentWord].style.opacity = 1;
      for (var i = 0; i < words.length; i++) {
        splitLetters(words[i]);
      }
  
      function changeWord() {
        var cw = wordArray[currentWord];
        var nw = currentWord == words.length - 1 ? wordArray[0] : wordArray[currentWord + 1];
        for (var i = 0; i < cw.length; i++) {
          animateLetterOut(cw, i);
        }
  
        for (var i = 0; i < nw.length; i++) {
          nw[i].className = 'letter behind';
          nw[0].parentElement.style.opacity = 1;
          animateLetterIn(nw, i);
        }
  
        currentWord = (currentWord == wordArray.length - 1) ? 0 : currentWord + 1;
      }
  
      function animateLetterOut(cw, i) {
        setTimeout(function () {
          cw[i].className = 'letter out';
        }, i * 80);
      }
  
      function animateLetterIn(nw, i) {
        setTimeout(function () {
          nw[i].className = 'letter in';
        }, 340 + (i * 80));
      }
  
      function splitLetters(word) {
        var content = word.innerHTML;
        word.innerHTML = '';
        var letters = [];
        for (var i = 0; i < content.length; i++) {
          var letter = document.createElement('span');
          letter.className = 'letter';
          letter.innerHTML = content.charAt(i);
          word.appendChild(letter);
          letters.push(letter);
        }
  
        wordArray.push(letters);
      }
  
      changeWord();
      setInterval(changeWord, 3000);
    },100)
    
  }

}
