import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const bookingOnDemand: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fcustomerbooking%2Fbooking.png?alt=media&token=3e16908c-5644-426f-a869-e3daffe615a3",
  title: "Book Team Members",
  subtitle: "Choose a Team Member",
  paragraph: "Booking slots syncs with your teams availability.",
  data: [
    {
      icon: "users", title: "Pick Team Members.", subtitle: "Book anyone."
    },
    {
      icon: "stopwatch", title: "Time Slot Intervals.", subtitle: "1hr, 30m, 15m, 10m"
    },
    {
      icon: "users", title: "Book for kids.", subtitle: "With linked accounts."
    }, 
    {
      icon: "shopping-bag", title: "1 to many.", subtitle: "121's, 122's, 123's...."
    }, 
    {
      icon: "shopping-bag", title: "Book whole season.", subtitle: "In one order."
    }, 
  ]
}