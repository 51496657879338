import { Component, Input} from '@angular/core';
import {FeaturesComponentData } from 'src/wild/modules/brochure/feature_data/FeaturesData';

@Component({
  selector: 'app-titles',
  templateUrl: './titles.component.html'
})
export class TitlesComponent {
  @Input() left: boolean;
  @Input() data: FeaturesComponentData;
}
