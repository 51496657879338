<div *ngIf="emptyState">
    <div>
        <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div class="text-center">
            <h2 class="text-base font-semibold wild-text-primary tracking-wide uppercase">Search</h2>
            <p class="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Find a Swim Spot.</p>
            <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">Enter a Location or Swim Spot Name above.</p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!emptyState">
    <app-tabs tabsId="search" [tabs]="tabs" (tabChanged)="updateActiveTabId($event)"></app-tabs> 
    <div *ngIf="activeTabId == 'swimspots'">
        <app-searchresults-swimspots (resultSelected)="selected($event)"></app-searchresults-swimspots>
    </div>
</div>

