import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from '../../../../../data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { environment } from 'src/environments/environment';
import { BusinessPublicService } from 'src/booking/services/business_public_service';
import { BusinessPublicModel } from '../../../../../data/models/business_public_model';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';
import { WildNavigationType } from '../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-swimspotsforbusinesssmall',
  templateUrl: './swimspotsforbusinesssmall.component.html',
  styleUrls: ['./swimspotsforbusinesssmall.component.scss']
})
export class SwimSpotsForBusinessSmallComponent implements OnInit {

  public error: ErrorModel = null;
  @Input() business: BusinessPublicModel;
  swimspots$: Observable<any>;
  imagesUrl = environment.imagesUrl;

  constructor(
    private modalService: ModalService,
    private swimspotDate: SwimspotsdataService,
    private businessPublicService: BusinessPublicService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.swimspots$ = null;
    this.swimspots$ = this.businessPublicService.swimspots(this.business).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  async openSwimSpot(id) {
    var settings: ModalModel = {
      header: "Opening",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var swimspot = await this.swimspotDate.read(id).toPromise();
    this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, swimspot.canonical);
    this.modalService.hide();
  }

}
