<div [formGroup]="form">
    <div class="mt-1 flex">
        <div class="flex items-center justify-between w-full">
            <span class="flex-grow flex flex-col">
                <span class="wild-label" id="availability-label">{{description}}</span>
                <span *ngIf="notAnswered" class="text-sm text-gray-500" id="availability-description">Not yet
                    answered</span>
                <span *ngIf="explanation" class="text-sm text-gray-500"
                    id="availability-description">{{explanation}}</span>
            </span>
            <button (click)="toggle()" type="button"
                [ngClass]="{'wild-bg-primary':form.get(name)?.value,'bg-gray-400':!form.get(name)?.value}"
                class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                role="switch" aria-checked="false" aria-labelledby="availability-label"
                aria-describedby="availability-description">
                <span [ngClass]="{'translate-x-5':form.get(name)?.value,'translate-x-0':form.get(name)?.value}"
                    aria-hidden="true"
                    class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
        </div>
    </div>
    <div *ngIf="form.get(name)?.errors && form.get(name)?.touched">
        <div class="font-light text-xs text-red-600 py-1" *ngIf="form.get(name)?.errors?.required">
            {{description}} is required
        </div>
    </div>
</div>