import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreQueryType } from '../../../../data/enums/firestore_query_type';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { Activity } from '../../../../data/models/activity_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ActivityStatus } from '../../../../data/enums/activity_status';
import { BusinessService } from './business_service/business_service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        private firestore: FirestoreService,
        private dates: DatesService,
        private businessService: BusinessService,
        private auth: AuthenticationService
    ) { }

    upcoming(): Observable<Activity[]> {
        var startOfDay = this.dates.startOfToday();
        var userId = this.auth.getLoggedInCookie();
        var businessId = this.businessService.getActiveBusinessId();
        return this.firestore.getListByQuery("activities", [
            {
                type: FirestoreQueryType.WHERE,
                operator: "==",
                property: "businessId",
                value: businessId
            },
            {
                type: FirestoreQueryType.WHERE,
                operator: ">",
                property: "startDate",
                value: startOfDay
            },
            {
                type: FirestoreQueryType.WHERE,
                operator: "==",
                property: "status",
                value: ActivityStatus.LIVE
            },
            {
                type: FirestoreQueryType.WHERE,
                operator: "==",
                property: "status",
                value: ActivityStatus.LIVE
            },
            {
                type: FirestoreQueryType.ORDERBY,
                operator: "==",
                property: "startDate",
                value: "asc"
            },
            {
                type: FirestoreQueryType.WHERE,
                operator: "array-contains",
                property: "team",
                value: userId
            },
            {
                type: FirestoreQueryType.LIMIT,
                operator: "==",
                property: "startDate",
                value: 10
            }
        ])
    }

}

