import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorType } from '../../data/enums/error_type';
import { ErrorModel } from '../../data/models/error_model';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { SuuntoService } from '../services/suunto_service';
@Component({
  template: `
  
  <div class="max-w-3xl mx-auto px-4 sm:px-6 md:px-8 mt-4">
    <div>
      <h1 class="text-3xl">Suunto Integration</h1>
    </div>
    <div *ngIf="loading">
      <app-loader></app-loader>
    </div>
    <div *ngIf="success">
      <h1 class="text-3xl mt-12">Your Suunto Account is Now Linked with Wild.</h1>
      <p>Your future activites will now sync with your account</p>
      <button (click)="close()" class="wild-btn-primary mt-6">Close</button>
    </div>
    <div *ngIf="error">
      <app-retry [code]="error.code" [description]="error.description" [header]="error.message" [hideretry]="true">
      </app-retry>
      <button (click)="close()" class="wild-btn-primary mt-6">Close</button>
    </div>
  </div>
  `,
})
export class SuuntoIntegrationPageComponent implements OnInit {

  loading: boolean = false;
  success: boolean = false;
  error: ErrorModel = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private suuntoService: SuuntoService,
    private commWithApp: CommunitcateWithAppService
  ) {

  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      let oauth_code = params['code'];
      if (oauth_code === undefined) {
        this.loading = true;
        this.suuntoService.getUrl().then((url: string) => {
          window.location.href = url["url"];
        })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          })
      } else {
        //String checks on null are important here. Leave them alone!!!
        if (oauth_code == null || oauth_code == "null") {
          this.error = {
            code: ErrorType.USERDIDNOTGRANTACCESSTOSUUNTOONTHEFRONTEND,
            description: "If this was a mistake, please repeat the integration process and try again. ",
            message: "You did not grant Wild Access to Suunto"
          }
        } else {
          this.exchange(oauth_code);
        }
      }
    });
  }

  async exchange(oauth_token) {
    this.loading = true;
    this.suuntoService.exchange(oauth_token)
      .then(() => {
        this.success = true;
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      })
  }

  close() {
    this.commWithApp.pop();
  }

}
