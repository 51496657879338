<app-modaltopper header="Add Card"></app-modaltopper>
<!-- <app-form [form]="form" ctaText="Submit" disableDivide="true" (onSubmit)="onSubmit($event)" [loading]="loading">
    <app-inputgroup>
        <app-input [form]="form" type="text" name="firstName" description="First Name" [span]=6></app-input>
        <app-input [form]="form" type="text" name="surname" description="Surname" [span]=6></app-input>
    </app-inputgroup>
</app-form>  -->
 <app-form [form]="form" ctaText="Add Card" cancelText="Cancel" (onCancel)="cancel()" disableDivide="true" (onSubmit)="createToken($event)" [loading]="loading">
    <app-inputgroup>
      <app-input [form]="form" type="text" name="name" description="Name on card" [span]=6></app-input>
    </app-inputgroup>
      <app-label text="Card Details"></app-label>
      <div class="w-full border-2 rounded-r-md sm:text-sm border-gray-200 py-3 px-1 h-11">
        <ngx-stripe-card
        [options]="cardOptions"
        [elementsOptions]="elementsOptions"
      ></ngx-stripe-card>      
      </div>
</app-form> 