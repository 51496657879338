import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { UpdateTimeSlotTypeDTO } from '../../../../../../../data/dtos/business/configuration/UpdateTimeSlotTypeDTO';
import { BusinessTimeSlotType } from '../../../../../../../data/enums/business_timeslot_type';
import { BusinessModel } from '../../../../../../../data/models/business_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ConfigurationService } from 'src/wild/modules/business/services/configuration/configuration_service';

@Component({
  template: `
  <app-modaltopper header="Time Slot Interval" parapraph="Set the interval On-Demand time slots are presented in."></app-modaltopper>
      <app-form [form]="form" [fullwidthbutton]="false" ctaText="Change" disableDivide="true" (onSubmit)="onSubmit($event)"
          [loading]="loading" cancelText="Cancel" (onCancel)="hide()">              
          <app-inputgroup>
            <app-select [options]="timeslotOptions" [form]="form" name="type" description="Time slot Interval" [span]=6>
            </app-select>
          </app-inputgroup>
      </app-form> 
  `
})
export class TimeSlotIntervalFormComponent implements OnInit {

  @Output() updated = new EventEmitter();
  @Input() business: BusinessModel;

  constructor(
    private fb: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private notifyService: NotifyService) { }

  form!: UntypedFormGroup;
  loading: boolean;
  timeslotOptions: SelectModel[] = [
    {
      name: "Every 10 mins (max availabilty)",
      value: BusinessTimeSlotType.TENMINUTE
    },
    {
      name: "15 minutes",
      value: BusinessTimeSlotType.FIFTEENMINUTE
    },
    {
      name: "20 minutes",
      value: BusinessTimeSlotType.TWENTYMINUTE
    },
    {
      name: "30 minutes",
      value: BusinessTimeSlotType.THIRTYMINUTE
    },
    {
      name: "1 hour (low availability)",
      value: BusinessTimeSlotType.HOURLY
    },
  ]

  ngOnInit(): void {
    this.form = this.fb.group({
      type: new UntypedFormControl(this.business.timeSlotType ?? '', [Validators.required])
    });
  }

  onSubmit(data) {
    this.loading = true;
    var dto: UpdateTimeSlotTypeDTO = {
      type: data.type
    }
    this.configurationService.updateTimeSlot(dto)
      .then(async () => {
        await this.next(data.type);
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {
    this.notifyService.notify("Price", message, NotifyType.ERROR);
  }

  async next(type) {
    this.business.timeSlotType = type;
    this.notifyService.notify("Updated", "Time Slot Interval Configured", NotifyType.SUCCESS);
    this.updated.emit();
    this.modalService.hide();
  }

  hide() {
    this.modalService.hide();
  }

  resetForm() {
    this.loading = false;
  }


}
