import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class BusinessModeService {

    constructor(
        private http: HttpService
    ) { }

    async system() {
        await this.http.patch(`${environment.api}/business/onboarding/systemmode`, {}).toPromise();
    }

    async advertiser() {
        await this.http.patch(`${environment.api}/business/onboarding/advertisemode`, {}).toPromise();
    }

}
