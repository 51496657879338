import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { LocationResultType } from '../../../../../../data/enums/location_result_type';
import { WaterwayType } from '../../../../../../data/enums/waterway_type';
import { SearchResult } from '../../../../../../data/models/SearchResult';
import { SearchResults } from '../../../../../../data/models/SearchResults';
import { MapsService } from 'src/shared/services/maps/maps_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { SearchService } from 'src/shared/services/search/search_service';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { BusinessService } from '../../../services/business_service/business_service';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from '../../../../../../data/enums/navigation_type';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html'
})
export class AreaComponent implements OnInit {

  @Output() done = new EventEmitter<any>();
  form: UntypedFormGroup;
  latlngForm: UntypedFormGroup;
  searchResults: SearchResults;
  searchLoading: boolean;
  showMinCharactersNotReached: boolean;
  showSearch: boolean = false;
  minSearchCharacters: number = 3;
  charactersTyped: number = 0;
  LocationResultType = LocationResultType;
  WaterwayType = WaterwayType;
  useLatLng: boolean;


  mapsLoaded: Observable<boolean>;
  options!: google.maps.MapOptions;
  markerOptions!: google.maps.MarkerOptions;
  markerPositions!: google.maps.LatLngLiteral[];
  latitude: string;
  longitude: string;
  onboarding: boolean = false;


  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageService,
    private business: BusinessService,
    private notifyService: NotifyService,
    private notify: NotifyService,
    private navigate: NavigationService,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private maps: MapsService) {
    this.mapsLoaded = this.maps.loadMaps().pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => { if (params?.onboarding == 1) this.onboarding = true; })
    this.markerOptions = { draggable: true, };
    this.options = {
      center: {
        lat: Number.parseFloat("52.549736"),
        lng: Number.parseFloat("-1.851169")
      },
      zoom: 5,
    };
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });
    this.latlngForm = this.fb.group({
      latitude: new UntypedFormControl('', [Validators.required,]),
      longitude: new UntypedFormControl('', [Validators.required]),
    });
    setTimeout(() => {
      this.form.valueChanges.pipe(
        debounceTime(500)
      ).subscribe((result) => {
        this.search(this.form.value);
      });
    }, 1000);
    this.getExistingArea();
  }

  async getExistingArea() {
    var business = await this.business.getPublicBusiness().toPromise()
    if (business.latitude != null && business.latitude != undefined && business.longitude != null && business.longitude != undefined)
      this.updateLatLongOnMap(business.latitude, business.longitude);
  }

  onSubmitLatLng(data: any) {
    var latlng = data.latitude + "," + data.longitude;
    const regexExp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/gi;
    if (latlng.match(regexExp) != null) {
      this.storage.setCookie("swimspotlat", data.latitude, 1);
      this.storage.setCookie("swimspotlng", data.longitude, 1);
      this.done.emit();
    } else {
      this.notify.notify("Invalid Coordinates", "Please check your latitude and longitude", NotifyType.ERROR);
    }
  }

  backToSearchForm() {
    this.useLatLng = false;
  }

  enterLatLng() {
    this.useLatLng = true;
  }

  async search(value) {
    if (value.search == undefined || value.search == null || value.search == "") {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      if (value.search.length >= this.minSearchCharacters) {
        this.showMinCharactersNotReached = false;
        this.searchLoading = true;
        await this.searchService.swimspotSearch(value.search).pipe(take(1)).toPromise()
          .then((searchResults) => {
            this.searchResults = searchResults;
          })
          .catch((error) => {
            this.handlerError(error);
          })
          .finally(() => {
            this.searchFinished();
          })
      } else {
        this.charactersTyped = value.search.length;
        this.searchLoading = false;
        this.showMinCharactersNotReached = true;
      }
    }
  }

  searchFinished() {
    this.searchLoading = false;
  }

  handlerError(message: string) {
    this.notify.notify("Search Error", message, NotifyType.ERROR);
  }

  clearSearch() {
    this.form.controls["search"].setValue("");
    this.searchResults = null;
  }

  async selectSearchResult(result: SearchResult) {
    if (result.type == LocationResultType.PLACE) {
      this.latitude = result.latitude;
      this.longitude = result.longitude;
      this.updateLatLongOnMap(result.latitude, result.longitude);
    }
    if (result.type == LocationResultType.WHAT3WORDS) {
      var coordinates = await this.searchService.getCoordinatesForW3w(result.name).toPromise();
      this.latitude = coordinates.latitude;
      this.longitude = coordinates.longitude;
      this.updateLatLongOnMap(coordinates.latitude, coordinates.longitude);
    }
    this.clearSearch();
    this.done.emit();
  }

  //MAPSTUFF

  updateLatLongOnMap(latitude: string, longitude: string): void {

    this.options = {
      center: {
        lat: Number.parseFloat(latitude),
        lng: Number.parseFloat(longitude)
      },
      zoom: 12,
    };
    this.markerPositions = [{
      lat: Number.parseFloat(latitude),
      lng: Number.parseFloat(longitude)
    }];
  }

  updateMarker(event: google.maps.MapMouseEvent) {
    var latlng = event.latLng.toJSON();
    this.markerPositions = [event.latLng.toJSON()];
    this.latitude = latlng.lat.toString();
    this.longitude = latlng.lng.toString();
  }

  save() {
    this.business.updateArea(this.latitude, this.longitude)
      .then(async () => {
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
  }


  async next() {
    this.notifyService.notify("Updated", "Your area has been updated!", NotifyType.SUCCESS);
    if (this.onboarding == true) {
      this.navigate.navigate(WildNavigationType.DASHBOARDHOME);
    } else {
      this.navigate.navigate(WildNavigationType.SETTINGS)
    }
  }

}