import { Component, OnInit } from '@angular/core';
import { BlogPostDTO } from '../../../data/dtos/blog/BlogPostDTO';
import { FeatureColour, FeaturesComponentData, FeaturesComponentListData } from 'src/wild/modules/brochure/feature_data/FeaturesData';
import { RSSSerrice } from '../../modules/brochure/services/rss_service/rss_service';

@Component({
  selector: 'app-latestblog',
  templateUrl: './latestblog.component.html',
  styleUrls: ['./latestblog.component.scss']
})
export class LatestBlogComponent implements OnInit {
constructor(private rss:RSSSerrice){}
posts:BlogPostDTO[] = [];
data:FeaturesComponentData = {
  colour:FeatureColour.WHITE,
  title:"Latest",
  subtitle:"From the Blog"
};
  async ngOnInit(): Promise<void> {
    var list:FeaturesComponentListData[] = [];
   var posts = await this.rss.feed();
   posts.forEach((post:BlogPostDTO)=>{
    list.push({
      title:post.name,
      subtitle:post.description,
      image:post.image,
      link:post.link,
      paragraph:post.date
    })
   })
   this.data.title = "Latest News";
   this.data.data = list;
  }
}
