import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BusinessStatus } from '../../../../../data/enums/business_status';
import { AddressModel } from '../../../../../data/models/address_model';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(
        private http: HttpService
    ) { }

    async add(address: AddressModel) {
        await this.http.post(`${environment.api}/business/address`, address).toPromise();
    }

    async update(id: string, address: AddressModel) {
        await this.http.put(`${environment.api}/business/address/${id}`, address).toPromise();
    }

    async del(id: string) {
        await this.http.delete(`${environment.api}/business/address/${id}`).toPromise();
    }

}
