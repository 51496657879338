export enum MoonPhases {
    UNKNOWN,
    NEW,
    WAXINGCRESCENT,
    FIRSTQUARTER,
    WAXINGGIBBOUS,
    FULL,
    WANINGGIBBOUS,
    THIRDQUARTER,
    WANINGCRESCENT
}