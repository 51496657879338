import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorModel } from '../../../../../../data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { PayoutsService } from '../../../services/payouts_service/payouts_service';
import { PayoutModel } from '../../../../../../data/models/payout_model';
import { PayoutStatus } from '../../../../../../data/enums/payout_status';
import { FinanceDashboardService } from './services/finance_dashboard_service';

@Component({
  templateUrl: './payouts.component.html'
})
export class PayoutsComponent implements OnInit {

  constructor(
    private payoutService: PayoutsService,
    private financeDashboardService: FinanceDashboardService,
    private datesService: DatesService) { }

  public error: ErrorModel = null;
  payouts$: Observable<PayoutModel[]>;
  PayoutStatus = PayoutStatus;
  loading: boolean = false;

  ngOnInit(): void {
    this.load();
  }

  async topup() {
    await this.financeDashboardService.topup({
      amount: 1000
    })
  }

  getReadableDate(date: any): string {
    var date = date;
    try {
      date = date.toDate();
    } catch (error) {

    }
    return this.datesService.getFormattedDateFromObject(date);
  }

  load() {
    this.payouts$ = null;
    this.payouts$ = this.payoutService.list().pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  prev() {
    this.load();
  }

  next() {
    this.load();
  }

}
