import { FeatureColour, FeatureComponentType, FeaturesComponentData } from "../../FeaturesData";

export const teamManagement: FeaturesComponentData = {
  colour: FeatureColour.WHITE,
  type: FeatureComponentType.RIGHTIMAGEINFOSECTION,
  image: "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/v2%2Fimagesforbrochuresite%2Fteam%2Fteam-laptops.jpg?alt=media&token=0a469c22-60da-4901-a8c1-820070a9082e",
  title: "Team Management",
  subtitle: "Collaborate Like Pros",
  data: [
    {
      icon: "stopwatch", title: "Working Hours.", subtitle: "Per team member."
    },
    {
      icon: "cog", title: "Roles.", subtitle: "For all departments."
    },
    {
      icon: "shopping-bag", title: "Book with.", subtitle: "Specific team members."
    },
    {
      icon: "users", title: "Invite.", subtitle: "Unlimited team members."
    },
    {
      icon: "mobile", title: "App.", subtitle: "For your whole team."
    },
  ]
}